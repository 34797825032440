const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	btnReturn: {},
	spnTitle: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: '1.25rem',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontWeight: '500'
	}
})

export default styles
