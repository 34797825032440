import { PICK_SELECTED_FILTER_ITEM } from '../../component/reusable/filter/actions/filter'
import {
	CLEAR_SELECTED_FILTER_VALUES_BY_DATASET,
	SET_PLUGIN_FILTERS,
	SET_GLOBAL_SEARCH_TEXT,
	CLEAR_GLOBAL_SEARCH_TEXT,
	SET_FILTERS_IS_LOADING,
	SET_GLOBAL_LABELS,
	SET_GLOBAL_SELECTIVE_FILTERS,
	CLEAR_ALL_GLOBAL_FILTERS
} from '../../actions/filter/global'
import { CLEAN_ON_COMPANY_CHANGE } from '../../actions/company'

//TODO: Move this to the actions/filter/global file!
const INJECT_PRELOADED_FILTERS = 'INJECT_PRELOADED_FILTERS',
	SET_FIXED_FILTER = 'SET_FIXED_FILTER',
	CLEAR_FIXED_FILTERS = 'CLEAR_FIXED_FILTERS'

/**
 *
 * @description Created the constant
 * @author spindola
 * @version 1.0
 *
 * @description Changed field names
 * @author brunoteixeirasilva
 * @version 1.1
 */
const INITIAL_STATE = {
	fixed: [],
	isLoading: false,
	labels: {},
	pluginFilters: null,
	selectiveFilters: [],
	searchText: '',
	values: {}
}

/**
 * Controls the isLoading state of filters
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const setLoading = (state, action) =>
	Object.assign({}, state, {
		isLoading: action.isLoading
	})

/**
 * Sets the collection of loaded plugin-filters
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const setPluginFilters = (state, action) =>
	Object.assign({}, state, {
		pluginFilters: action.pluginFilters
	})

/**
 * Manages to create a **filter-values** array copy, with or without the passed option
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Managing prop-map removal by the use of js "delete"
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} values The current filter-values object of arrays
 * @param {Object} option The option (dispatched) with information on the filter being added
 */
const reuseValuesAndPushNew = (values, option) => {
	const valuesCopy = { ...values }

	//Filter might have already been set,
	//Then include one more option if not
	if (undefined === valuesCopy[option.key]) {
		//Creates the array at the position of the passed key
		valuesCopy[option.key] = []
	}

	// if (valuesCopy[option.key].includes(option.value)) {
	//When the value is already listed
	//Will remove it, since it should be now
	if (valuesCopy[option.key].includes(option.value)) {
		valuesCopy[option.key].splice(
			valuesCopy[option.key].indexOf(option.value),
			1
		)

		//Checking if the option.key is now an empty array
		//Will delete it, since we can't see it empty there
		if (valuesCopy[option.key].length === 0) delete valuesCopy[option.key]
	} else {
		//When the option is new for the given key
		//Adds it to the array
		valuesCopy[option.key].push(option.value)
	}

	return valuesCopy
}

/**
 * Manages to clear a specific filter prop
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Managing prop-map removal by the use of js "delete"
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const clearSpecificDataSet = (state, action) => {
	let nextValuesState = Object.assign({}, state.values)

	//Removing the data-set array-map by its ID
	delete nextValuesState[action.dataSetId]

	//Then returning the state
	return Object.assign({}, state, {
		values: { ...nextValuesState }
	})
}

/**
 * Manages to set a global search-text
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const setSearchText = (state, action) =>
	Object.assign({}, state, {
		searchText: action.text
	})

/**
 * Manages to CLEAR global-search-text
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const clearSearchText = (state) =>
	Object.assign({}, state, {
		searchText: INITIAL_STATE.searchText
	})

/**
 * Manages to receive a filter item to include in the set-filters (globalFiltersState.values)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const receiveFilterItem = (state, action) => {
	const newValues = reuseValuesAndPushNew(state.values, action)

	return Object.assign({}, state, {
		values: newValues
	})
}

/**
 * @author davispindola
 * @version 1.0.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type", with "data" map made of key and label mapped props
 */
const receiveGlobalLabel = (state, action) => {
	const { key, label } = action.data
	return Object.assign({}, state, {
		labels: Object.assign({}, state.labels, {
			[key]: label
		})
	})
}

/**
 * Manages to include a singular fixable filter (globalFiltersState.fixed)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const setFixed = (state, action) => {
	//Fixed filter already set
	if (state.fixed.includes(action.key)) return state

	return Object.assign({}, state, { fixed: [...state.fixed, action.key] })
}

/**
 * Manages to clear all fixed filters (globalFiltersState.fixed)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 */
const clearFixed = (state) =>
	Object.assign({}, state, { fixed: INITIAL_STATE.fixed })

/**
 * Manages to receive and inject default system-filter (globalFiltersState.values)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const injectPreloadedFilters = (state, action) => {
	const newValues = Object.assign({}, state.values, action.filters)

	return Object.assign({}, state, {
		values: newValues
	})
}

/**
 * Manages to clear all globally set system-filters (globalFiltersState.values)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const clearAllFilters = (state) =>
	Object.assign({}, state, {
		values: { ...INITIAL_STATE.values }
	})

/**
 * Manages to set selective global system-filters (globalFiltersState.selectiveFilters)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
const setSelectiveFilters = (state, action) =>
	Object.assign({}, state, {
		selectiveFilters: action.selectiveFilters
	})

/**
 * Manages global-filters-state layer
 *
 * @description Created the function
 * @author spindola
 * @version 1.0
 *
 * @description Added two new switch options for managing search-text values
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Changed file location, extracted functions to its own names
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @description Added constants to action names, exported them also
 * @author brunoteixeirasilva
 * @version 1.3
 *
 * @description Added method for setting is-loading
 * @author brunoteixeirasilva
 * @version 1.4
 *
 * @description Moved state generations to functions
 * @author brunoteixeirasilva
 * @version 1.5
 *
 * @param {Object} state Current app state
 * @param {Object} action Action with "type" and desired props
 */
function globalFiltersReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_PLUGIN_FILTERS: {
			return setPluginFilters(state, action)
		}
		case SET_FILTERS_IS_LOADING: {
			return setLoading(state, action)
		}
		case CLEAR_FIXED_FILTERS: {
			return clearFixed(state)
		}
		case SET_FIXED_FILTER: {
			return setFixed(state, action)
		}
		case CLEAR_GLOBAL_SEARCH_TEXT: {
			return clearSearchText(state)
		}
		case SET_GLOBAL_SEARCH_TEXT: {
			return setSearchText(state, action)
		}
		case CLEAR_SELECTED_FILTER_VALUES_BY_DATASET: {
			return clearSpecificDataSet(state, action)
		}
		case INJECT_PRELOADED_FILTERS: {
			return injectPreloadedFilters(state, action)
		}
		case SET_GLOBAL_SELECTIVE_FILTERS: {
			return setSelectiveFilters(state, action)
		}
		case SET_GLOBAL_LABELS: {
			return receiveGlobalLabel(state, action)
		}
		case PICK_SELECTED_FILTER_ITEM: {
			return receiveFilterItem(state, action)
		}
		case CLEAR_ALL_GLOBAL_FILTERS: {
			return clearAllFilters(state)
		}
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default globalFiltersReducer

export { INJECT_PRELOADED_FILTERS, SET_FIXED_FILTER, CLEAR_FIXED_FILTERS }
