export const MANAGE_MEASURER_READ_LOADING_STATE =
		'MANAGE_PERFORMED_AUDIT_LOADING_STATE',
	LOAD_MEASURER_READ_DATA = 'LOAD_MEASURER_READ_DATA',
	SET_MEASURER_READ_PERIOD = 'SET_MEASURER_READ_PERIOD'

export const setMeasurerReadsPeriod = (dateConfig, value, formType) => ({
	type: SET_MEASURER_READ_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadMeasurerReadsData = (measurerReads) => ({
	type: LOAD_MEASURER_READ_DATA,
	measurerReads
})

export const setLoading = (isLoading) => ({
	type: MANAGE_MEASURER_READ_LOADING_STATE,
	isLoading
})
