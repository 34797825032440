import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as User from '../../../firebase/user'
import { compose } from 'recompose'
import withAuthentication from '../withAuthentication'
import {
	setUserActiveCompany,
	setRequireActiveCompany,
	setUserActiveCompanyLoading,
} from '../../../actions/user/company'

/**
 * Will be HOC for users of "activeCompanyId" prop
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added a validation of "authUser.uid" at User.getActiveCompany invocation
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added the switch active company logic
 * @author davispindola
 * @version 1.2
 *
 * @description Changed the state location for user company relation
 * @author davispindola
 * @version 1.3
 *
 * @param {React.Component} Component A React component object
 */
const withActiveCompany = Component => {
	class WithActiveCompany extends React.Component {
		listener = null

		componentDidMount() {
			const { authUser } = this.props

			//AuthUser must be available for the company-check
			//props.authUser is available with UID info provided?
			//not yet loading
			if (!!authUser && undefined !== authUser.uid /** && !isLoading */) {
				this.checkCompany()
			}
		}

		checkCompany() {
			const {
					authUser,
					activeCompanyId,
					onSetActiveCompany,
					requireCompanyActivation,
					onSetRequireCompanyActivation,
					onSetActiveCompanyLoading,
				} = this.props,
				self = this
			if (!!activeCompanyId) return
			onSetActiveCompanyLoading(true)
				.then(() => User.findActiveCompanyId(authUser.uid))
				//Will query its last active company
				.then(foundActiveCompanyId => {
					if (!foundActiveCompanyId) {
						// console.log('GOt on this shit')
						//If the company activation is not yet active
						if (!requireCompanyActivation) {
							//Sets the company activation flag => true
							return onSetRequireCompanyActivation()
						}
					} else {
						// Subscribe component user activeCompanyId changes (firestore)
						//Any unmounting should remove the listener by simply invoking it
						self.listener = User.observeUserCompany(
							authUser.uid,
						).subscribe(foundActiveCompanyId)

						//If the active company has actually changed,
						//Dispatch these state changes
						if (activeCompanyId !== foundActiveCompanyId) {
							//User has already set the active company
							return onSetActiveCompany(foundActiveCompanyId)
						}

						return Promise.resolve(activeCompanyId)
					}
				})
				.then(() => onSetActiveCompanyLoading(false))
				.catch(error => {
					onSetActiveCompanyLoading(false)

					//Logs in dev env
					if (process.env.REACT_APP_NODE_ENV.trim() === 'development')
						console.error(error)

					throw error
				})
		}

		componentWillUnmount() {
			const { authUser } = this.props,
				self = this

			if (!!authUser && authUser.uid !== undefined)
				if (self.listener && typeof self.listener === 'function') {
					//Listener is valid, will unsubscribe from it invoking it
					self.listener()
				}
		}

		/**
		 * When user changes activeCompanyId, the propertie is setted to null and this update dispatch a function
		 * to require company activation.
		 *
		 * * Compared to null because before user select a active company, the property
		 * * assumes INITIAL STATE null.
		 *
		 * @version 1.2.0
		 */
		componentDidUpdate(prevProps) {
			const {
				activeCompanyId,
				requireCompanyActivation,
				// onSetActiveCompany,
				onSetRequireCompanyActivation,
			} = this.props
			// if (prevProps.activeCompanyId !== activeCompanyId) {
			// 	onSetRequireCompanyActivation()
			// }
			if (
				prevProps.activeCompanyId !== activeCompanyId &&
				activeCompanyId === null &&
				!requireCompanyActivation
			) {
				onSetRequireCompanyActivation()
			}

			// if (
			// 	activeCompanyId !== null &&
			// 	activeCompanyId !== prevProps.activeCompanyId
			// ) {
			// 	debugger
			// 	onSetActiveCompany(activeCompanyId)
			// }
		}

		render() {
			const { requireCompanyActivation } = this.props

			return !requireCompanyActivation && <Component {...this.props} />
		}
	}

	/**
	 * Will map the activeCompanyId property to the current component
	 *
	 * @param {Object} state The current (Redux) state of the app
	 */
	const mapStateToProps = state => ({
		requireCompanyActivation:
			state.userCompanyState.requireCompanyActivation,
		activeCompanyId: state.userCompanyState.activeCompanyId,
		isLoading: state.userCompanyState.activeCompanyLoading,
	})

	/**
	 * Will set the current user active company state-prop
	 *
	 * @param {Object} dispatch
	 */
	const mapDispatchToProps = dispatch => ({
		onSetActiveCompanyLoading: async isLoading =>
			dispatch(setUserActiveCompanyLoading(isLoading)),
		onSetRequireCompanyActivation: async () =>
			dispatch(setRequireActiveCompany()),
		onSetActiveCompany: async activeCompanyId =>
			dispatch(setUserActiveCompany(activeCompanyId)),
	})

	return compose(
		withRouter,
		withAuthentication,
		connect(mapStateToProps, mapDispatchToProps),
	)(WithActiveCompany)
}

export default withActiveCompany
