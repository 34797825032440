import {
    MANAGE_MONITORING_LOADING_STATE,
    LOAD_MONITORING_DATA,
    SET_MONITORING_PERIOD,
    SET_MONITORING_VALUE
} from '../../actions/monitoring'

const INITIAL_STATE = {
    isLoading: null,
    monitoring: null,
    dateConfig: {},
    update: false,
    value: null
}
/**
 * monitoring noises state reducer manager
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const monitoringReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_MONITORING_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case SET_MONITORING_VALUE: {
            return Object.assign({}, state, {
                value: action.text
            })
        }
        case LOAD_MONITORING_DATA: {
            return Object.assign({}, state, {
                monitoring: action.monitoring,
                isSelect: false
            })
        }
        case SET_MONITORING_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        default:
            return state
    }
}

export default monitoringReducer
