import { createSelector } from 'reselect'
import {
	filterByOptions,
	filtersApplied
} from '../../component/reusable/filter/functions'
import { filterByText } from '../../utils/string'
import {
	makeGetGlobalFilterKeys,
	makeGetGlobalSearchText,
	makeGetGlobalFilterValues
} from '../filter'

/**
 * Checks whether entries have a "logada" prop === 1 or 0 (on/off)
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @description Changed function location, name and functionality
 * @author brunoteixeirasilva
 * @version 1.1
 */
function filterTeamsByStatus(teams, status) {
	return teams.filter((v) => Boolean(v.logada) === status)
}

/**
 * Filters data by a comparer list
 *
 * @description Created the functions
 * @author daviwegner
 * @version 1.0
 *
 * @description Corrected namings convention
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Array} teams A team-list for usage in filtering
 * @param {Object} compararers The comparers object
 */
const filterByComparers = (teams, compararers = {}) => {
	let filteredTeams = teams
	let filtered = false
	if (teams.length > 0) {
		Object.keys(compararers).forEach((comparerKey) => {
			if (compararers[comparerKey].length > 0) {
				if (filtered) {
					filteredTeams = filteredTeams.filter((team) => {
						return compararers[comparerKey].some((comparer) => {
							switch (comparer) {
								case '>=':
									return team[comparerKey] >= 100
								case '<':
									return team[comparerKey] < 100
								default:
									return true
							}
						})
					})
				} else {
					filteredTeams = teams.filter((team) => {
						return compararers[comparerKey].some((comparator) => {
							switch (comparator) {
								case '>=':
									return team[comparerKey] >= 100
								case '<':
									return team[comparerKey] < 100
								default:
									return true
							}
						})
					})
					filtered = true
				}
			}
		})
	}
	return filteredTeams
}

/**
 * Makes/produces a team list getter-filter
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Updated for checking for logged or not logged teams
 * @author wegner
 * @version 1.1
 */
const makeGetVisibleTeams = () => {
	return createSelector(
		[
			makeGetGlobalFilterKeys(),
			makeGetGlobalFilterValues(),
			makeGetGlobalSearchText(),
			getOrderBy,
			getTeamListCollection,
			getTeamListStatus,
			getTeamListComparers
		],
		(
			filterKeys,
			filterValues,
			searchText,
			order,
			teams,
			status,
			comparer
		) => {
			let filteredTeams
			//Teams is not an array, but an object (probably error one)
			if (!(teams instanceof Array)) {
				//TODO: Check whether there will be more errors on user-listing
				return []
			}
			//Seggregate teams by their status
			filteredTeams = filterTeamsByStatus(teams, status)

			//Applies the text filtering
			filteredTeams = filterByText(
				filteredTeams,
				searchText,
				'equipe_nome'
			)

			//No filters applied!
			if (!filtersApplied(filterValues))
				return filterByComparers(filteredTeams, comparer)

			//Filters by filter options
			return filterByComparers(
				filterByOptions(filteredTeams, filterValues),
				comparer
			)
		}
	)
}

/**
 * Memoized team-list counter (won't count by status)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const makeGetTeamsCount = () => {
	return createSelector(
		[makeGetGlobalFilterValues(), getTeamListCollection],
		(filterValues, teams) => {
			let teamCount = teams.length

			//No filters applied!
			if (!filtersApplied(filterValues)) return teamCount

			//Filters by filter options
			teamCount = filterByOptions(teams, filterValues).length

			return teamCount
		}
	)
}

// const getFilterKeys = (state) => state.globalFiltersState.filters

// const getFilterValues = (state) => state.globalFiltersState.values

// const getSearchText = (state) => state.globalFiltersState.searchText

const getTeamListComparers = (state) => state.teamListState.comparers

const getOrderBy = (state) => state.listPaginationState.order

const getTeamListCollection = (state) => state.teamListState.teams

const getTeamListStatus = (state) => state.teamListFilterState.logged

export { makeGetVisibleTeams, makeGetTeamsCount, getTeamListStatus }
