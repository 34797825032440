import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles, Typography } from '@material-ui/core'

import * as UserNotification from '../../../firebase/user/notification'
import * as User from '../../../firebase/user'

import withAuthentication from '../../reusable/withAuthentication'
import NotificationList from './components/notification/NotificationList'

import { translate } from '../../../utils/lang'
import withActiveCompany from '../../reusable/withActiveCompany'
import { setNotificationAuthorInfo } from '../../../actions/user/notification'

const style = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: '30px',
		paddingLeft: '20px',
		paddingTop: '64px',
		height: '100%',
		backgroundColor: 'rgb(220 222 232)',
		// flexGrow: 1,
		// marginTop: theme.spacing.unit * 2
	},

	parametro: {
		margin: '30px',
	},
})
/**
 *	user notification page container
 * @description created the component
 * @author Davi Spindola
 * @version 1.0
 *
 * @description fixed the validation, when acessing this page as first page it would not load the user data, and would not load notifications
 * @author Davi Wegner
 * @version 1.1
 */
class UserNotificationsContainer extends React.Component {
	componentDidMount() {
		const {
			authUser,
			setLoadedNotifications,
			setAuthorInfo,
			isLoading,
			notifications,
			activeCompanyId,
		} = this.props
		if (!!authUser && undefined !== authUser.uid && !notifications) {
			isLoading(true)
			UserNotification.loadUserNotifications(
				authUser.uid,
				activeCompanyId,
			).then(snapshot => {
				if (!snapshot.empty) {
					const authorIds = []
					snapshot.forEach(item => {
						let data = item.data()
						setLoadedNotifications({
							[item.id]: data,
						})
						authorIds.push(data.author)

						// Notifications.loadNotificationData(
						// 	authUser.uid,
						// 	item.id
						// ).then((snap) => {
						// 	let data = snap.data()

						// 	return data.author
						// })
					})

					new Set(authorIds).forEach(authorId => {
						User.get(authorId).then(snapshot =>
							setAuthorInfo({ [authorId]: snapshot.data() }),
						)
					})
				}
			})
			isLoading(false)
		}
	}
	render() {
		const { classes } = this.props
		return (
			<div>
				<div className={classes.root}>
					<Typography variant="h6" gutterBottom>
						{translate('title/notifications')}
					</Typography>
					<NotificationList />
				</div>
				<div className={classes.parametro}></div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	notifications: state.notificationState.notifications,
	modalState: state.notificationNavigationState.showNotificationModal,
})
const mapDispatchToProps = dispatch => ({
	setLoadedNotifications: notifications =>
		dispatch({
			type: 'SET_LOADED_NOTIFICATIONS',
			notifications,
		}),
	setAuthorInfo: authorInfo =>
		dispatch(setNotificationAuthorInfo(authorInfo)),
	isLoading: isLoading =>
		dispatch({ type: 'SET_LOADING_STATE_NOTIFICATION', isLoading }),
})

export default compose(
	withAuthentication,
	withActiveCompany,
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(style),
)(UserNotificationsContainer)
