import { createSelector } from 'reselect'
import { translate } from '../../../utils/lang'
import { getColourSet } from '../../../utils/colour'

export const makeActionOverviewFormatter = () =>
	createSelector(
		[getActionListInfo],
		(actionListInfo) => {
			return {
				perc: [
					{
						label: translate('label/open-and-generated'),
						value: actionListInfo.generated.open,
						perc: (
							(actionListInfo.generated.open /
								actionListInfo.generated.count) *
							100
						).toFixed(2)
					},
					{
						label: translate('label/closed-and-generated'),
						value: actionListInfo.generated.closed,
						perc: (
							(actionListInfo.generated.closed /
								actionListInfo.generated.count) *
							100
						).toFixed(2)
					},
					{
						label: translate('label/open-and-received'),
						value: actionListInfo.received.open,
						perc: (
							(actionListInfo.received.open /
								actionListInfo.received.count) *
							100
						).toFixed(2)
					},
					{
						label: translate('label/closed-and-received'),
						value: actionListInfo.received.closed,
						perc: (
							(actionListInfo.received.closed /
								actionListInfo.received.count) *
							100
						).toFixed(2)
					}
				],
				// labels: [
				// 	translate('label/open-and-generated'),
				// 	translate('label/closed-and-generated'),
				// 	translate('label/open-and-received'),
				// 	translate('label/closed-and-received')
				// ],
				// datasets: [{ data: [5, 5, 10, 15] }]
				callbacks: {
					afterLabel: function(tooltipItems, data, prop) {
						return `${prop[tooltipItems.index].value.toLocaleString(
							'pt-br'
						)} ${data.labels[tooltipItems.index]}`
					},
					label: function(tooltipItems, data) {
						return data.datasets[0].data[tooltipItems.index] + ' %'
					}
				}
			}
		}
	)

const getActionListInfo = (state, ownProps) => ownProps.actionListInfo

const getActionList = (state) => state.actionsState.actionList
const getCardInfo = (state) => state.actionsState.cardInfo

export const makeActionCardOverviewFormatter = () =>
	createSelector(
		[getActionListInfo, getActionList, getCardInfo],
		(actionListInfo, actionList, cardInfo) => {
			if (!actionList) return
			if (!cardInfo) return
			if (!actionListInfo.filteredActionList) return
			let cardsAcao = {}
			actionListInfo.filteredActionList.forEach((key) => {
				if (!Object.keys(cardsAcao).includes(actionList[key].cardId)) {
					cardsAcao = Object.assign({}, cardsAcao, {
						[actionList[key].cardId]: 1
					})
				} else {
					cardsAcao[actionList[key].cardId] += 1
				}
			})

			let cardAcaoOrder = Object.keys(cardsAcao).sort((key, keyb) => {
				if (cardsAcao[key] > cardsAcao[keyb]) return -1
				if (cardsAcao[key] < cardsAcao[keyb]) return 1
				return 0
			})

			return {
				data: {
					labels: cardAcaoOrder.map((key) => cardInfo[key]),
					datasets: [
						{
							type: 'horizontalBar',
							backgroundColor: getColourSet(
								Object.keys(cardsAcao).length
							),
							data: cardAcaoOrder.map((key) => cardsAcao[key])
						}
					]
				},
				callbacks: {
					label: function(tooltipItems, data) {
						return ` ${translate('label/actions')} : ${
							data.datasets[0].data[tooltipItems.index]
						} `
					}
				}
			}
		}
	)
