import { getDesktopBreakpoint } from '../mobile'

/**
 * Generates an object with the width props for the standardisation of modals
 *
 * @description Created the factory
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {import("@material-ui/core/styles/MuiThemeProvider").MuiThemeProviderProps} theme The current theme object
 */
export const makeGetWidths = () => (theme) => ({
	width: '95%',
	...getDesktopBreakpoint(theme, {
		width: '75%'
	}),
	// [theme.breakpoints.up('sm')]: {
	// 	width: '75%'
	// },
	[theme.breakpoints.up('md')]: {
		width: '60%'
	},
	[theme.breakpoints.up('lg')]: {
		width: '700px'
	}
})
