const INITIAL_STATE = {
	name: '',
	isSuper: false,
	isAdmin: false
}

/**
 * Manages group form state
 *
 * @param {Object} state
 * @param {Object} action
 */
function groupFormEditReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_GROUP_FORM_INPUT': {
			const { key, value } = action

			return { ...state, [key]: value }
		}
		case 'SET_GROUP_FORM': {
			const { name, isSuper, isAdmin } = action.data

			return Object.assign({}, state, {
				name: name,
				isSuper: isSuper,
				isAdmin: isAdmin
			})
		}
		default:
			return state
	}
}

export default groupFormEditReducer
