/**
 * factory for memoize functions results
 * , if function is not run i will run and store the result in cache
 * , otherwise verifies if the value is cached and returns it
 *
 * @description Create the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Changed function to support multiparams memoizing
 * @author brunoteixeirasilva
 * @version 1.1
 */
const memoize = (fn) => {
	let cache = {}

	return (...args) => {
		//Getting stringified key from params

		const stringifiedArgs = JSON.stringify(args)
		// let n = args[0]
		//Tries and fiend the stringified arguments
		//Previously resolved
		if (stringifiedArgs in cache) {
			return cache[stringifiedArgs]
		} else {
			//Not found, will process the result

			let result = args.length === 1 && !args[0] ? fn(args[0]) : fn(args)
			//And cache by the params key
			cache[stringifiedArgs] = result

			return result
		}
	}
}

export default memoize
