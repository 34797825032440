import { fs } from '../../../firebase'

/**
 * Gets a specific company-dash-item step by its ID
 *
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Renamed the function, corrected comments formatting
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The current company ID
 * @param {string} dashItemId Parent dashItemId for the step to be found within
 * @param {string} stepId The current desired step ID
 */
export const getById = (companyId, dashItemId, stepId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('dashItems')
		.doc(dashItemId)
		.collection('steps')
		.doc(stepId)
		.get()
}

/**
 * Gets a company-dash-item steps collection.
 *
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Renamed function, corrected comments formatting
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The current company ID
 * @param {string} dashItemId The dashItem, parent of steps collection
 */
export const getAll = (companyId, dashItemId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('dashItems')
		.doc(dashItemId)
		.collection('steps')
		.get()
}

/**
 * function for getting own filters in realtime from steps
 *
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Renamed function, added error capture
 * @author brunoteixeirasilva
 * @version 1.1
 */
export const onGetOwnFilters = (companyId, dashItemId, stepId, thenFunc) => {
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('dashItems')
		.doc(dashItemId)
		.collection('steps')
		.doc(stepId)
		.collection('ownFilters')
		.where('active', '==', true)

	return ref.onSnapshot(
		(querySnapshot) => {
			querySnapshot.docChanges().forEach((change) => {
				if (!!change.type) {
					thenFunc({
						[change.doc.id]:
							change.type !== 'removed'
								? change.doc.data()
								: undefined
					})
				}
			})
		},
		(error) => {
			//TODO: Treat error on return of this function
			throw error
		}
	)
}
