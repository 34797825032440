export const MANAGE_PERFORMED_COVID_LOADING_STATE =
		'MANAGE_PERFORMED_COVID_LOADING_STATE',
	LOAD_PERFORMED_COVID_DATA = 'LOAD_PERFORMED_COVID_DATA',
	SET_PERFORMED_COVID_PERIOD = 'SET_PERFORMED_COVID_PERIOD',
	LOAD_COVID_DATA_FILTERS = 'LOAD_COVID_DATA_FILTERS'

export const setPerformedCovidPeriod = (dateConfig, value, formType) => ({
	type: SET_PERFORMED_COVID_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadPerformedCovidData = (performedCovid) => ({
	type: LOAD_PERFORMED_COVID_DATA,
	performedCovid
})

export const setLoading = (isLoading) => ({
	type: MANAGE_PERFORMED_COVID_LOADING_STATE,
	isLoading
})


export const loadCovidDataFiltersCovid = (mapCovidFilters) => ({
	type: LOAD_COVID_DATA_FILTERS,
	mapCovidFilters
})
