const INITIAL_STATE = {
	options: {},
	keys: null,
	filterName: '',
	selectedKey: '',
	selectedValues: [],
	fixed: false,
	preload: false,
	active: false,
	filterId: null
}

/**
 * Declares the Redux company filters form state manager
 * @author Davi Wegner
 * @param {Object} state
 * @param {Object} action
 * @version 1.0
 */
function companyFilterFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'ALL_COMPANY_OPTIONS_LOADED': {
			return Object.assign({}, state, {
				options: Object.assign({}, state.options, {
					[Object.keys(action.options)[0]]: Object.assign(
						{},
						state.options[Object.keys(action.options)[0]],
						action.options[Object.keys(action.options)[0]]
					)
				})
			})
		}
		case 'ALL_COMPANY_KEYS_LOADED': {
			return Object.assign({}, state, {
				keys: Object.assign({}, state.keys, action.keys)
			})
		}
		case 'SET_COMPANY_FORM_SAVING': {
			return Object.assign({}, state, { saving: action.saving })
		}
		case 'SET_SELECTED_KEY': {
			return Object.assign({}, state, { selectedKey: action.key })
		}
		case 'SET_SELECTED_VALUES': {
			return Object.assign({}, state, { selectedValues: action.values })
		}
		case 'CLEAR_SELECTED_VALUES': {
			return Object.assign({}, state, {
				selectedValues: INITIAL_STATE.selectedValues
			})
		}
		case 'MANAGE_SWITCH_VALUES': {
			return Object.assign({}, state, action.value)
		}
		case 'CHANGE_FILTER_NAME': {
			return Object.assign({}, state, action.value)
		}
		case 'CLEAR_FILTER_FORM_STATE': {
			return Object.assign({}, state, INITIAL_STATE)
		}
		case 'SET_FILTER_ID': {
			return Object.assign({}, state, { filterId: action.filterId })
		}
		default:
			return state
	}
}

export default companyFilterFormReducer
