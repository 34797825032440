import _ from 'lodash'

const MENU_ITEM_PROP_NAMES = {
	key: 'key',
	value: 'label'
}

/**
 * Will compare two objects' properties and return true when props have the same value
 *
 * @param {Object} object1 Object number 1 in comparison process
 * @param {Object} object2 Object number 1 in comparison process
 * @param {Array|object} props The property collection being compared in these objects
 */
export function areEqual(object1, object2, props) {
	let result = true

	//In case props object is an array
	if (props instanceof Array) {
		//Will test each property
		props.every((p) => {
			if (!objectPropIsEqual(object1, object2, p)) {
				return (result = false)
			}
		})
	} else {
		//In case props object is an object
		Object.keys(props).every((p) => {
			if (!objectPropIsEqual(object1, object2, p)) {
				return (result = false)
			}
		})
	}

	return result
}

/**
 * Will compare two objects, at a specific property and return true when objects have the same value.
 * Can be a deep comparison. Default is shallow, though.
 *
 * @param {Object} object1 Object number 1 in comparison process
 * @param {Object} object2 Object number 1 in comparison process
 * @param {string} propName The property being compared in these objects
 * @param {boolean} [deep] [optional] Default is false. Whether a deep comparison should be done.
 */
function objectPropIsEqual(object1, object2, propName, deep = false) {
	return (
		object1[propName] === object2[propName] &&
		(!deep ||
			JSON.stringify(object1[propName]) ===
				JSON.stringify(object2[propName]))
	)
}

/**
 * parse a object to query string
 *
 * @description Created the function
 * @author Davi Wegner, brunoteixeirasilva
 * @version 1.0
 *
 * @description added validation,case params doenst actualy come
 * @author Wegner
 * @version 1.0
 */
export const toQueryString = (params) => {
	if (!params) return ''
	return '?' + new URLSearchParams(params).toString()
}

/**
 * Maps unique incidences of prop-values, based on an object's prop
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} items The original object collection
 * @param {string} objectPropName The enrolled prop name, to be used as a reference from object
 */
export const mapUniqueItemsByProp = (items, objectPropName) =>
	_.uniqBy(items, objectPropName)

/**
 * Maps listed object to list/menu-items key-value-paired object collection
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} items The original object collection
 * @param {string} labelPropName Label prop name, to be used as a reference from object
 * @param {string} keyPropName [optional] Key prop name, to be used as a reference from object
 */
export const listToMenuItems = (items, labelPropName, keyPropName = null) =>
	items.map((item) => {
		return {
			[MENU_ITEM_PROP_NAMES.key]: !keyPropName
				? item[labelPropName]
				: item[keyPropName],
			[MENU_ITEM_PROP_NAMES.value]: item[labelPropName]
		}
	})
