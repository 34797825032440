import { fs } from './../../firebase'

/**
 * github user
 * @author davi.spindola
 * @version 1.0.0
 *
 * Responsible for load user notifications in firestore.
 *
 * @param {String} uid user identification
 */
export const loadUserNotifications = (uid, activeCompanyId) => {
	return fs
		.collection(`notifications`)
		.orderBy('date', 'desc')
		.where('companyId', '==', activeCompanyId)
		.where('receiver', '==', uid)
		.get()
}
/**
 * realtime listener for notifications
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 */
export const checkChanges = (uid, thenFunc, emitMessage, activeCompanyId) => {
	let isFirst = true
	let ref = fs
		.collection(`notifications`)
		.where('companyId', '==', activeCompanyId)
		.where('receiver', '==', uid)
		.orderBy('date', 'desc')

	let subscribe = ref.onSnapshot((querySnapshot) => {
		querySnapshot.docChanges().forEach((change) => {
			if (!!change.type) {
				if (!isFirst && change.type === 'added') emitMessage()

				thenFunc({
					[change.doc.id]: change.doc.data()
				})
			}
		})
		isFirst = false
	})
	return subscribe
}
/**
 * github user
 * @author davi.spindola
 * @version 1.0.0
 *
 * Responsible for set visualized to true.
 *
 * @param {String} uid user identification
 * @param {String} notificationId notification id
 */
export const setNotificationToVisualized = (uid, notificationId) => {
	return fs
		.collection(`notifications`)
		.doc(notificationId)
		.update({ active: false })
}
