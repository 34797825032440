import {
	SET_SAFETY_RATIOS_TYPES,
	SET_SAFETY_RATIOS_PERIODS,
	SET_SAFETY_RATIOS_ITEMS,
	// SET_SAFETY_RATIOS_SELECTED_TYPE,
	SET_SAFETY_RATIOS_SELECTED_PERIOD,
	SET_SAFETY_RATIOS_REGIONALS,
	SET_SAFETY_RATIOS_SELECTED_REGIONAL,
	SET_SAFETY_RATIOS_SELECTED_TYPE_PRIMARY,
	SET_SAFETY_RATIOS_SELECTED_TYPE_SECONDARY
} from '../../../actions/safetyRatios'

const INITIAL_STATE = {
	ratioItems: null,
	ratioRegionals: null,
	ratioTypes: null,
	ratioPeriods: null,
	selectedRatioPeriod: null,
	selectedRatioTypePrimary: null,
	selectedRatioTypeSecondary: null
}

/**
 * Applies a change to the ratio items collection
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applyRatioItems = (state, action) =>
	Object.assign(state, { ratioItems: action.ratioItems })

/**
 * Applies a change to the ratio regionals collection
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applyRatioRegionals = (state, action) =>
	Object.assign(state, { ratioRegionals: action.ratioRegionals })

/**
 * Applies a change to the ratio periods collection
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applyRatioPeriods = (state, action) =>
	Object.assign(state, { ratioPeriods: action.ratioPeriods })

/**
 * Applies a change to the ratio types collection
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applyRatioTypes = (state, action) =>
	Object.assign(state, { ratioTypes: action.ratioTypes })

/**
 * Applies a selected item of **ratio regional**
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applySelectedRatioRegional = (state, action) =>
	Object.assign(state, {
		selectedRatioRegional: action.selectedRatioRegional
	})

/**
 * Applies a selected item of **ratio type primary**
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applySelectedRatioTypePrimary = (state, action) =>
	Object.assign(state, {
		selectedRatioTypePrimary: action.selectedRatioTypePrimary
	})

/**
 * Applies a selected item of **ratio type secondary**
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applySelectedRatioTypeSecondary = (state, action) =>
	Object.assign(state, {
		selectedRatioTypeSecondary: action.selectedRatioTypeSecondary
	})

/**
 * Applies a selected item of **ratio period**
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current safety ratios state tree
 * @param {Object} action The action with "type" and desired affected props
 */
const applySelectedRatioPeriod = (state, action) =>
	Object.assign(state, { selectedRatioPeriod: action.selectedRatioPeriod })

/**
 * Manages the **safety ratios** container context
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
function safetyRatiosReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_SAFETY_RATIOS_ITEMS: {
			return applyRatioItems(state, action)
		}
		case SET_SAFETY_RATIOS_REGIONALS: {
			return applyRatioRegionals(state, action)
		}
		case SET_SAFETY_RATIOS_PERIODS: {
			return applyRatioPeriods(state, action)
		}
		case SET_SAFETY_RATIOS_TYPES: {
			return applyRatioTypes(state, action)
		}
		case SET_SAFETY_RATIOS_SELECTED_REGIONAL: {
			return applySelectedRatioRegional(state, action)
		}
		case SET_SAFETY_RATIOS_SELECTED_PERIOD: {
			return applySelectedRatioPeriod(state, action)
		}
		case SET_SAFETY_RATIOS_SELECTED_TYPE_PRIMARY: {
			return applySelectedRatioTypePrimary(state, action)
		}
		case SET_SAFETY_RATIOS_SELECTED_TYPE_SECONDARY: {
			return applySelectedRatioTypeSecondary(state, action)
		}
		default:
			return state
	}
}

export default safetyRatiosReducer
