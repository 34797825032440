/**
* @author Wegner
* @email wegner@arquia.com.br
* @created 29-06-2020
*/

export const INITIAL_STATE = {},
SET_LOG_DATA = 'SET_LOG_DATA',
logReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOG_DATA:
            return Object.assign({}, state, action.data)
        default:
            return state
    }
}

export default logReducer