import React from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core'
import withAuthorization from '../../reusable/withAuthorization'
import PluginList from '../../reusable/plugin/list/PluginList'
import styles from './styles'
import withActiveCompany from '../../reusable/withActiveCompany'
import ListDependencyRequire from '../../reusable/list/dependency/require'
import { translate } from '../../../utils/lang'

/**
 * Stands for a route under /plugin
 *
 * @description Created the document
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added box indicating the dashboard button
 * @author lopes
 * @version 1.1
 */
class PluginPage extends React.Component {
	render() {
		const { classes, activeCompanyId } = this.props

		return (
			<div className={classes.root}>
				{!activeCompanyId && (
					<ListDependencyRequire
						text={translate('label/select-a-company-first')}
					/>
				)}
				{!!activeCompanyId && <PluginList />}
			</div>
		)
	}
}

const authCondition = authUser => !!authUser

export default compose(
	withActiveCompany,
	withStyles(styles),
	withAuthorization(authCondition),
)(PluginPage)
