import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { deviceIsIos } from '../../../utils/env'
import styles from './styles'

/**
 *
 *
 * @description Created the component
 * @author lopes
 * @version 1.0
 */
class TitleBar extends React.Component {
	render() {
		const { classes, title, children, showButton } = this.props

		return (
			<div className={classes.root}>
				{deviceIsIos() && (
					<div className={classes.btnReturn}>
						{showButton && children}
					</div>
				)}
				{title && <span className={classes.spnTitle}>{title}</span>}
			</div>
		)
	}
}

TitleBar.propTypes = {
	classes: PropTypes.object,
	title: PropTypes.node,
	Children: PropTypes.node
}

export default withStyles(styles)(TitleBar)
