import * as Company from '../../company'
import * as CompanyGroup from '../../company/group'
import * as CompanyPermission from '../../company/permission'
import * as CompanyGroupPermission from '../../company/group/permission'

/**
 * Manages to search for all user-level-permissions
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} uid The current logged-in user ID
 * @param {string} activeCompanyId The active company ID
 */
export const get = (uid, activeCompanyId) => {
	const foundPermissions = []
	let allowedGroups = []

	return (
		Company.getGroups(activeCompanyId)
			.then(companyGroups => {
				// No groups found at the specified company
				if (companyGroups.empty) return false

				// Groups found but still need to resolve user within or not
				companyGroups.forEach(doc => {
					allowedGroups.push(
						CompanyGroup.containsUser(
							activeCompanyId,
							doc.id,
							uid,
						).then(userFoundWithin => !!userFoundWithin && doc),
					)
				})

				// Expects for each group to be resolved as user container or not
				return Promise.all(allowedGroups)
			})
			.then(companyGroups => {
				// No groups associated to the logged-in user
				if (!companyGroups) return []

				// Here we have all company-groups,
				// though, when the current object is a falsey, then the UID was not found within
				// Then, will remove falseys
				allowedGroups = companyGroups.filter(item => !!item)

				// Here we have all company-groups,
				// though, when the current object is a falsey, then the UID was not found within
				return allowedGroups.map(companyGroup =>
					CompanyGroupPermission.getAll(
						activeCompanyId,
						companyGroup.id,
					),
				)
			})
			// Here we reached a point where we found all user permissions
			// But we have to expect for all promises to be finished
			// Then find each company-permission object
			.then(promises => Promise.all(promises))
			.then(snapshots => {
				// For each snapshot, access its docs' collection
				// And run a query to find results
				snapshots.forEach(snapShot =>
					snapShot.docs.forEach(permissionDoc =>
						foundPermissions.push(
							CompanyPermission.get(
								activeCompanyId,
								permissionDoc.id,
							),
						),
					),
				)

				return foundPermissions
			})
			// Here we expect for all promises to be resolved prior to returning
			.then(promises => Promise.all(promises))
	)
}
