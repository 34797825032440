import React from 'react'
import PropTypes from 'prop-types'
import * as routes from '../../../../constants/routes'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as Plugins from '../../../../firebase/plugin'
import { generatePath } from 'react-router'
import { withRouter } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import Loader from '../../loader'
import PluginListFragment from './item/PluginListFragment'
import { translate } from '../../../../utils/lang'
import withActiveCompany from '../../withActiveCompany'
import withAuthentication from '../../withAuthentication'
import { RESET_PLUGIN_LIST_LOADING, CLEAR_LOADED_PLUGIN_LIST, PLUGIN_LIST_LOADING, PLUGIN_LIST_LOADED } from '../../../../reducers/plugin/list'
import { SET_ACTIVE_PLUGIN } from '../../../../reducers/plugin'
import { SET_CONFIGURING_PLUGIN } from '../../../../reducers/plugin/form'
import { clearAllGlobalFilters } from '../../../../actions/filter/global'
import { makeGetActivePlugin } from '../../../../selectors/plugin'

import CompanyHint from '../../hint/company'
import { setPluginList, clearPluginList, setPluginListLoading } from '../../../../actions/plugin/list'
import classNames from 'classnames'
import { deviceIsIos } from '../../../../utils/env'

/**
 * Manages/displays the active user-company (account) plugin listing
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed behaviours to respect new paradigms
 * @author brunoteixeirasilva
 * @version 2.0
 *
 * @description Added withPullDownToRefresh to the list
 * @author brunoteixeirasilva
 * @version 2.1
 *
 * @description Added CompanyHint component
 * @author brunoteixeirasilva
 * @version 2.2
 */
class PluginList extends React.PureComponent {
	state = {
		// title: translate('title/plugins')
		title: translate('title/sources'),
	}

	/**
	 * When the component has mounted,
	 * will seek all user plugins
	 * based on the current user active company
	 */
	componentDidMount() {
		const { pluginsLoading } = this.props

		//If an active company were specified
		if (!pluginsLoading) {
			//Will decide whether to load plugins
			// this.loadPlugins(activeCompanyId)
		}
	}

	componentDidUpdate(prevProps) {
		const { activeCompanyId } = this.props

		//Active company hasn't changed
		if (prevProps.activeCompanyId === activeCompanyId) return false

		//Will decide whether to load plugins
		//this.loadPlugins(activeCompanyId)
	}

	/**
	 * Literally loads plugins from the Firestore mechanism
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 */
	loadPlugins = activeCompanyId => {
		const { pluginsLoaded, includeInactive, onPluginsBeginLoad, resetLoading, authUser } = this.props
		//Firstly, clears all plugins already loaded
		//Sets loading also
		onPluginsBeginLoad()
			.then(() =>
				//Queries for found companies plugins
				Plugins.getAllByCompany(activeCompanyId, authUser.uid, includeInactive)
			)
			.then(dataSnapshots => {
				let plugs = {}

				//Adds each plugin to an object
				dataSnapshots.forEach(plugin => (plugs[plugin.id] = plugin.data()))

				//Notifies plugins are loaded
				//Resets loading state
				pluginsLoaded(plugs)
			})
			.catch(err => {
				//Resets loading state
				resetLoading()

				//Exposes error
				throw err
			})
	}

	/**
	 * TODO: Will add a plugin to the current company
	 */
	addPlugin = () => {
		//const { onAddPlugin } = this.props;
		//TODO: implement addition in here!
		// db.onAddPlugin(this.props)
		// 	.then((action) => {
		// 		//pluginsLoaded(state, action);
		// 		return addPlugin(state, action);
		// 	});
	}

	/**
	 * Handles plugin configuration mode
	 */
	handleConfig = async (event, plugin) => {
		const { history, onSetConfiguringPlugin } = this.props
		const pluginKey = Object.keys(plugin)[0]

		//Just dispatch to Redux to set active
		//Then redirects to the page, afterwards
		onSetConfiguringPlugin(plugin).then(dispatchedPlugin => {
			//Will build the correct route for editing

			history.push(
				generatePath(routes.SECURITY_PLUGIN_FORM, {
					pluginId: pluginKey,
				})
			)
		})

		//Event propagation is cancellable
		if (!!event.preventDefault) event.preventDefault()
	}

	/**
	 * Activates the current plugin and redirects to the dashboard page
	 */
	viewAction = async (event, plugin) => {
		const { history, activePlugin, onSetActivePlugin, onCleanGlobalFilters, onHandleChange, onClearDashSet, onClearDashItems } = this.props,
			pluginId = Object.keys(plugin)[0]

		//When there is an active plugin and it is different from
		//The next one (changing plugins)
		//Has to dispatch a global-filter-clearing action
		if (activePlugin && Object.keys(activePlugin)[0] !== pluginId) onCleanGlobalFilters()

		//Will set system-level active plugin
		//Then change location, afterwards
		onSetActivePlugin(plugin)
			//Then redirect to the view route
			.then(dispatchedPlugin => {
				//Will build the correct route for showing data
				onHandleChange(0)
				onClearDashSet()
				onClearDashItems()
				history.push(generatePath(routes.DASHBOARD, { pluginId: pluginId }))
			})
			.catch(error => {
				//Error logging when dev
				if (process.env.REACT_APP_NODE_ENV.trim() === 'development') console.error(error)

				return Promise.reject(error)
			})

		//Event propagation is cancellable
		if (!!event.preventDefault) event.preventDefault()
	}

	render() {
		const { classes, dense, hideTitle, plugins, pluginsLoading } = this.props
		//const { iconButtons } = this.state.list.item.action
		//let activeCompanyId = false

		return (
			<div
				className={classNames(!!hideTitle ? classes.rootNoMargin : classes.root, {
					ios: deviceIsIos(),
				})}>
				{!hideTitle && (
					<Typography className={classes.spacedTitle} variant="h6" gutterBottom>
						{this.state.title}
					</Typography>
				)}
				<CompanyHint gutters />
				{!!pluginsLoading && <Loader internal />}
				{!!plugins && !pluginsLoading && (
					<PluginListFragment dense={dense} plugins={plugins} handleConfig={this.handleConfig} viewAction={this.viewAction} />
				)}
			</div>
		)
	}
}

const styles = theme => ({
	root: {
		flexGrow: 1,
		'&.ios': {
			paddingBottom: '40px',
		},
		// marginTop: theme.spacing.unit * 2
	},
	rootNoMargin: {
		flexGrow: 1,
		'&.ios': {
			paddingBottom: '40px',
		},
	},
	spacedTitle: {
		// marginTop: theme.spacing.unit * 2
	},
})

PluginList.propTypes = {
	includeInactive: PropTypes.bool,
	dense: PropTypes.bool,
	hideTitle: PropTypes.bool,
	plugins: PropTypes.object,
}

const makeMapStateToProps = () => {
	const getActivePlugin = makeGetActivePlugin()

	const mapStateToProps = state => ({
		plugins: state.pluginListState.plugins,
		pluginsLoading: state.pluginListState.pluginsLoading,
		activePlugin: getActivePlugin(state),
	})

	return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
	onHandleChange: value => dispatch({ type: 'HANDLE_BOTTOM_NAVIGATION_CHANGE', value }),
	resetLoading: () => dispatch({ type: RESET_PLUGIN_LIST_LOADING }),
	clearLoadedPlugins: () => dispatch(clearPluginList()),
	onClearDashSet: () => dispatch({ type: 'CLEAR_DASH_SET' }),
	onClearDashItems: () => dispatch({ type: 'CLEAN_ON_COMPANY_CHANGE' }),
	onPluginsBeginLoad: async () => dispatch(setPluginListLoading()),
	pluginsLoaded: plugins => dispatch(setPluginList(plugins)),
	onSetActivePlugin: async plugin => dispatch({ type: SET_ACTIVE_PLUGIN, plugin }),
	onSetConfiguringPlugin: async plugin => dispatch({ type: SET_CONFIGURING_PLUGIN, plugin }),
	onCleanGlobalFilters: async () => dispatch(clearAllGlobalFilters()),
})

export default compose(
	withRouter,
	withAuthentication,
	withActiveCompany,
	connect(makeMapStateToProps(), mapDispatchToProps),
	withStyles(styles)
)(PluginList)
