import React, { memo } from 'react'

function TagActive({ active }) {
	if (active) {
		return <span className={`tagActive-${active}`}>Ativo</span>
	} else {
		return <span className={`tagActive-${active}`}>Inativo</span>
	}
}

export default memo(TagActive)
