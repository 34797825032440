const INITIAL_STATE = {
	notifications: null
}

/**
 * Provides access to cycle data
 *
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
function controlPanelUserReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_NOTIFICATIONS': {
			return Object.assign({}, state, { notifications: action.notifications })
		}
		default:
			return state
	}
}

export default controlPanelUserReducer
