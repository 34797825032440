import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
	withStyles,
	Select,
	Input,
	IconButton,
	Icon,
	MenuItem,
	InputAdornment,
	TextField
} from '@material-ui/core'

import { translate } from '../../../../utils/lang'
import { getGlobalFixedFilters } from '../../../../selectors/filter'
import { clearGlobalFilterValues } from '../../../../actions/filter/global'

/**
 * Manages to display a single select-box for filters (specifically)
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Resolved clear button disappearing
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description added textfield menu item, for filtering filter options by searching them
 * @author Davi Wegner
 * @version 1.2
 *
 * @description Added "normaliseForQuery" on search-text change
 * @author davispindola
 * @version 1.3
 *
 * @description Removed "normaliseForQuery" on search-text change, it's not the right thing to do here
 * @author brunoteixeirasilva
 * @version 1.4
 */
class FilterSelectBox extends React.PureComponent {
	/**
	 * Clears a filter
	 */
	clearFilter = (event) => {
		const { clearFilterUnit } = this.props

		//Will dispatch a filter clearer
		clearFilterUnit()

		if (!!event.preventDefault) event.preventDefault()
	}

	handleSearch = (e) => {
		const { onTypeChange } = this.props

		//Applies the onTypeChange at this filter-select-box data-list
		onTypeChange(e.target.value)
	}

	render() {
		const {
			classes,
			fixed,
			hideClearButton,
			dataSetId,
			options,
			onSelectItem,
			optionKey,
			optionValue,
			searchFieldValue,
			selectedValues
		} = this.props

		return (
			//TODO: define a limiter to data-length on this listings
			<Select
				disabled={fixed}
				multiple
				value={selectedValues}
				classes={{ select: classes.select }}
				endAdornment={
					!fixed &&
					!hideClearButton && (
						<InputAdornment position="end">
							<IconButton
								//className={classes.clearIcon}
								aria-label={translate(
									'label/clear-filter-unit'
								)}
								onClick={(event) => this.clearFilter(event)}
							>
								<Icon>clear</Icon>
							</IconButton>
						</InputAdornment>
					)
				}
				input={<Input id={`select-multiple-${dataSetId}`} />}
			>
				<MenuItem className={classes.searchBox}>
					<TextField
						label={translate('label/search')}
						value={searchFieldValue}
						onChange={this.handleSearch}
						variant="outlined"
						className={classes.searchBoxInput}
						fullWidth
					/>
				</MenuItem>
				{options.map((option, i) => {
					return (
						<MenuItem
							onClickCapture={() =>
								onSelectItem(option[optionKey])
							}
							key={i}
							value={option[optionKey]}
						>
							{option[optionValue]}
						</MenuItem>
					)
				})}
			</Select>
		)
	}
}

const styles = (theme) => ({
	loaderBox: {
		margin: `${theme.spacing.unit * 2}px 0 0 0`,
		padding: `0 0 0 0`,
		height: 32,
		width: '100%',
		display: 'flex',
		zIndex: 100,
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FFF',
		borderBottom: '1px solid #a2a2a2'
	},
	select: { whiteSpace: 'unset' },
	searchBox: {
		height: 'auto',
		paddingTop: 5,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	searchBoxInput: {
		'& > *': {
			borderColor: theme.palette.secondary.main
		},
		'&  label': {
			transform: 'translate(14px, 16px) scale(1)'
		},
		'& input': {
			padding: '12.5px 14px'
		}
	}
})

FilterSelectBox.propTypes = {
	dataSetId: PropTypes.string.isRequired,
	filterKey: PropTypes.string.isRequired,
	onSelectItem: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	optionKey: PropTypes.string.isRequired,
	optionValue: PropTypes.string.isRequired,
	selectedValues: PropTypes.array
}

const makeMapStateToProps = () => {
	const mapStateToProps = (state, ownProps) => {
		const hideClearButton =
				!ownProps.selectedValues ||
				ownProps.selectedValues.length === 0,
			fixedFilters = getGlobalFixedFilters(state)
		let fixed = false

		//Decides the filter is fixed or not
		//Based on a global-filters-state-layer fixed filters prop
		fixed = fixedFilters.includes(ownProps.dataSetId)

		return {
			fixed: fixed,
			hideClearButton: hideClearButton
		}
	}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch, { dataSetId, filterKey }) => ({
	clearFilterUnit: () =>
		dispatch(clearGlobalFilterValues(filterKey, dataSetId))
})

export default compose(
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	),
	withStyles(styles)
)(FilterSelectBox)
