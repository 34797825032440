import React from 'react'
import PropTypes from 'prop-types'

/**
 * Provides a list-item fake-block (colourised)
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Updated sizes of primary and secondary elements:
 * * primary => 24px total
 * * secondary => 21px total
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} param0 The props object
 */
const FakeBlock = ({ type = 'primary' }) => (
	<div
		style={{
			width: type === 'primary' ? 90 : type === 'secondary' ? 120 : 24,
			height: type === 'primary' ? 24 : type === 'secondary' ? 15 : 24,
			marginTop:
				type === 'primary' ? 0 : type === 'secondary' ? 6 : 'auto',
			backgroundColor: 'rgba(196, 196, 196, 0.55)'
		}}
	>
		&nbsp;
	</div>
)

FakeBlock.propTypes = {
	type: PropTypes.oneOf(['primary', 'secondary', 'icon'])
}

export default FakeBlock
