import {
	MANAGE_CHECK_BY_LOCATION_COVID_LOADING_STATE,
	LOAD_CHECK_BY_LOCATION_COVID_DATA,
	SET_CHECK_BY_LOCATION_COVID_PERIOD,
	LOAD_COVID_DATA_FILTERS
} from '../../actions/checkByLocationCovid'

const INITIAL_STATE = {
	isLoading: null,
	autoCheckCovid: null,
	mapCovidFilters: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		object: '',
		stats: '',
		form: '',
		colaborator: '',
		question_has_nc: '',		
		col_status: '',
		col_is_auditor: '',
		subGrupo: '',
		cargo: '',
		area: '',
		vip: '',
		diretoriaExecutiva: '',
		diretoria: '',
		gerenciaSenior: '',
		gerencia: '',
		coordenacao: '',
		supervisao: '',
		filial: ''

	},
	cardId: ''
}
/**
 * performed audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */
const checkByLocationCovid = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_CHECK_BY_LOCATION_COVID_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_CHECK_BY_LOCATION_COVID_DATA: {
			return Object.assign({}, state, {
				autoCheckCovid: action.autoCheckCovid,
				isLoading: false
			})
		}
		case LOAD_COVID_DATA_FILTERS: {
			return Object.assign({}, state, {
				mapCovidFilters: action.mapCovidFilters,
				isLoading: false
			})
		}
		case SET_CHECK_BY_LOCATION_COVID_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		case 'SET_CARD_ID': {
			return Object.assign({}, state, {
				cardId: action.cardId
			})
		}
		case 'SET_CHECK_BY_LOCATION_COVID_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_CHECK_BY_LOCATION_COVID_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_CHECK_BY_LOCATION_COVID_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		default:
			return state
	}
}

export default checkByLocationCovid
