import React from 'react'
import PropTypes from 'prop-types'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Slide
} from '@material-ui/core'

import { translate } from '../../../../../utils/lang'

export const Transition = (props) => <Slide direction="up" {...props} />

/**
 * Dialog for sysmte notifications like firebase insert operations that returns void but needs to send some notification for user.
 * Ex: rating context.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 */
const DialogTypeNotification = (props) => {
	const {
		isOpen,
		closeDialog,
		notificationTitle,
		notificationContentText,
		transtion,
		btnFunc
	} = props

	const handleClose = () => closeDialog()

	return (
		<Dialog
			TransitionComponent={transtion}
			open={isOpen}
			onClose={handleClose}
		>
			<DialogTitle>{notificationTitle}</DialogTitle>
			<DialogContent>
				<DialogContentText>{notificationContentText}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={btnFunc}>{translate('label/confirm')}</Button>
			</DialogActions>
		</Dialog>
	)
}

DialogTypeNotification.propTypes = {
	notificationTitle: PropTypes.string.isRequired,
	notificationContentText: PropTypes.string.isRequired,
	closeDialog: PropTypes.func.isRequired,
	transition: PropTypes.any,
	isOpen: PropTypes.bool.isRequired,
	btnFunc: PropTypes.func
}

export default DialogTypeNotification
