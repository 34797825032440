import { fs } from '../../firebase'
import { translate } from '../../../utils/lang'

/**
 * [Real-time] Seeks for a dataSource Id on a given company plugin
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active-company ID
 * @param {string} pluginId The plugin we're looking for
 * @param {Function} thenFunc The function for success case, receives a dataSourceId
 * @param {Function} errorFunc [optional] The function when an error occurred
 */
export const onGetDataSourceId = (
	companyId,
	pluginId,
	thenFunc,
	errorFunc = (error) => {
		throw error
	}
) => {
	//Creates a full ref of a doc, and then points to it
	const docRef = `companies/${companyId}/plugins/${pluginId}`,
		doc = fs.doc(docRef)

	//If then-function is invalid, stops execution
	if (!thenFunc || typeof thenFunc !== 'function') {
		return errorFunc(
			translate('error/function-type-invalid', {
				functionName: 'thenFunc'
			})
		)
	}

	//When the data-source has changed
	return doc.onSnapshot((snapshot) => {
		let dataSourceId

		//Checking document was not found
		if (!snapshot.exists) {
			//Not found
			return errorFunc(
				translate('error/document-not-found', { documentId: docRef })
			)
		}

		//Gets the data-source ID
		dataSourceId = snapshot.data().dataSource

		return thenFunc(dataSourceId)
	})
}

/**
 * [Once] Seeks for a dataSource Id on a given company plugin. You must provide the return then/erorrs
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active-company ID
 * @param {string} pluginId The plugin we're looking for
 */
export const getDataSourceId = (companyId, pluginId) => {
	//Creates a full ref of a doc, and then points to it
	const docRef = `companies/${companyId}/plugins/${pluginId}`,
		doc = fs.doc(docRef)

	//When the data-source has changed
	return doc.get().then((snapshot) => {
		let dataSourceId

		//Checking document was not found
		if (!snapshot.exists) {
			return Promise.reject('document-not-found')
		}

		//Gets the data-source ID
		dataSourceId = snapshot.data().dataSource

		return Promise.resolve(dataSourceId)
	})
}

/**
 * [Once] Seeks for a dataSource Id on a given company plugin. You must provide the return then/erorrs
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active-company ID
 * @param {string} pluginId The plugin we're looking for
 */
export const getById = async (companyId, pluginId) => {
	//Creates a full ref of a doc, and then points to it
	const docRef = `companies/${companyId}/plugins/${pluginId}`,
		doc = fs.doc(docRef)

	//When the data-source has changed
	return doc.get().then((snapshot) => {
		//Checking document was not found
		if (!snapshot.exists) {
			return Promise.reject('company-plugin-not-found')
		}

		//Returns the company plugin info by its id
		return Promise.resolve(snapshot.data())
	})
}
