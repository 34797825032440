export const OPEN_NOTIFICATION_MODAL = 'OPEN_NOTIFICATION_MODAL'
export const CLOSE_NOTIFICATION_MODAL = 'CLOSE_NOTIFICATION_MODAL'

export function openNotificationModal(notificationRef) {
	return {
		type: OPEN_NOTIFICATION_MODAL,
		showNotificationModal: true,
		notificationRef
	}
}

export function closeNotificationModal() {
	return {
		type: CLOSE_NOTIFICATION_MODAL
	}
}
