import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { translate } from '../../../../utils/lang'

/**
 * Component for standardizing _header-part_ of **system level lists**
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {{ filterPlaceholder: String, noFilter: Boolean, partialLength: Number, totalLength: Number }} props The props to be defining display details
 */
const ListHeader = (props) => {	
	const {
		classes,
		partialLength,
		totalLength,
	} = props


	return (
		<Grid
			container
			direction="row"
			alignItems="flex-end"
			justify="space-between"
			className={classes.root}
		>
			<Grid item xs={6}>
				<Typography variant="subtitle1">
					{translate('label/showing', {
						length0: partialLength,
						length1: totalLength
					})}
				</Typography>
			</Grid>
			{/* {!noFilter && (
				<Grid item xs={6}>
					<ListFilterBox
						onChange={onChange}						
						pushRight
						label={filterPlaceholder}
					/>
				</Grid>
			)} */}
		</Grid>
	)
}

const styles = (theme) => ({
	root: {
		marginBottom: theme.spacing.unit
	}
})

ListHeader.defaultProps = {
	noFilter: false,
	partialLength: 0,
	totalLength: 0
}

ListHeader.propTypes = {
	partialLength: PropTypes.number,
	totalLength: PropTypes.number,
	filterPlaceholder: PropTypes.string,
	noFilter: PropTypes.bool
}

export default withStyles(styles)(ListHeader)
