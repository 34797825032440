import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import AppBarIcon from './icon/AppBarIcon'
import { rightBarIcon } from './styles'

const mapStateToProps = (state) => ({
	icon: state.appConfigState.app.barRightIcon,
	action: state.appConfigState.app.barRightIconAction,
	ariaLabel: state.appConfigState.app.barRightIconAriaLabel,
	tooltip: state.appConfigState.app.barRightIconTooltip
})
/**
 * Right bar icon container, for injecting in app bar icon its props
 * @description created the component
 * @author Davi Wegner
 * @version 1.0
 */
class RightBarIcon extends Component {
	render() {
		return this.props.icon && <AppBarIcon {...this.props} />
	}
}

export default compose(
	withStyles(rightBarIcon),
	connect(mapStateToProps)
)(RightBarIcon)
