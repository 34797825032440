import React from 'react'
import { connect } from 'react-redux'
import { showSnackBar } from '../../../actions/snackbar'
import { doSignOut } from '../../../firebase/auth'

const withValidation = Component => {
	class WithValidation extends React.Component {
		componentDidMount() {
			this.verify()
		}
		verify = () => {
			const { userLogout, onNotify } = this.props

			if (!window.cordova) return
			window.IRoot.isRooted(isRoot => {
				if (isRoot)
					doSignOut()
						.then(() => userLogout())
						.then(
							() => {
								onNotify('Device is Rooted')
							},
							() => {},
						)
			})
			if (window.device.isVirtual)
				doSignOut()
					.then(() => userLogout())
					.then(() => {
						onNotify('Emulator identified')
					})
		}
		render() {
			return <Component {...this.props} />
		}
	}
	const mapDispatchToProps = dispatch => {
		return {
			userLogout: async () => dispatch({ type: 'USER_LOGOUT' }),
			onNotify: message => dispatch(showSnackBar(message)),
		}
	}
	return connect(state => ({}), mapDispatchToProps)(WithValidation)
}
export default withValidation
