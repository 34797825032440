import {
    MANAGE_CARDIV_LOADING_STATE,
    LOAD_CARDIV_DATA,
    SET_CARDIV_PERIOD
} from '../../actions/cardiv'

const INITIAL_STATE = {
	isLoading: null,
    localFilters: null,
	data: null,
	dateConfig: {},
	ownFilters: {},
    searchText: {},
    idLocalFilter: {
        equipments: null
    }
}

const clearAllLocalFilters = (state) => {
    let keys = Object.keys(state.idLocalFilter),
        clearFilters = {}

    keys.forEach((key) => {
        clearFilters[key] = null
    })

    return clearFilters
}

/**
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const cardivReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_CARDIV_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_CARDIV_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case SET_CARDIV_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        case 'SET_CARDIV_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_CARDIV_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_CARDIV_FILTER_VALUE': {
            
			return Object.assign({}, state, {
				ownFilters: {},
                idLocalFilter: clearAllLocalFilters(state)
			})
		}
        case 'SET_LOCAL_FILTER_CARDIV': {
			return Object.assign({}, state, {
				localFilters: action.localFilters
			})
		}
        case 'SET_SEARCH_TEXT_CARDIV': {
            let searchText = {}
            action.objectKeys.forEach((item) => {
                searchText[item] = ''
            })
            return Object.assign({}, state, {
                searchText: searchText
            })
        }
        case 'SET_ID_LOCAL_FILTERS_CARDIV': {
            return Object.assign({}, state, {
                idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter)
            })
        }
        default:
            return state
    }
}

export default cardivReducer
