import {
	MANAGE_MAP_AUDIT_LOADING_STATE,
	LOAD_MAP_AUDIT_DATA,
	SET_MAP_AUDIT_PERIOD,
} from '../../actions/mapAudit'

const INITIAL_STATE = {
	isLoading: null,
	mapAudit: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		type_autocheck: '',
		object: '',
		stats: '',
		form: '',
		colaborator: '',
		question_has_nc: '',
		audit_id: '',
		col_status: '',
		col_is_auditor: '',
	},
}
/**
 * ranking audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */

const mapAuditReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_MAP_AUDIT_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case LOAD_MAP_AUDIT_DATA: {
			return Object.assign({}, state, {
				mapAudit: action.mapAudit,
				isLoading: false,
			})
		}
		case 'SET_MAP_AUDIT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_MAP_AUDIT_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_MAP_AUDIT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		case SET_MAP_AUDIT_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value,
						},
					),
				},
				isLoading: true,
			})
		}
		default:
			return state
	}
}

export default mapAuditReducer
