export const response = {
	root: {
		padding: 8,
		backgroundColor: '#eceef7'
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '2px 0'
	},
	date: {
		textAlign: 'end'
	},
	observation: {
		border: '1px solid #dadada',
		padding: 10,
		borderRadius: 3,
		backgroundColor: 'whitesmoke'
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

export const list = {
	root: {
		padding: '0 10px',
		overflow: 'auto',
		height: 300
	},
	list: {
		'& > div': {
			margin: 8
		}
	}
}
