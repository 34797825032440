import { getDesktopBreakpoint } from '../../../../../utils/object/theme/mobile'

export const styles = (theme) => ({
	root: {
		cursor: 'pointer',
		margin: `${theme.spacing.unit}px 0`
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	actionId: {
		fontWeight: 500,
		marginTop: '7px'
	},
	cardTitle: {
		fontWeight: 'bold',
		color: theme.palette.primary.dark
	},
	cardTitleRead: {},
	contentRead: {
		background: '#fff',
		borderRadius: 10,
		boxShadow: '0 0 6px -3px #000',
		padding: 10
	},
	content: {
		background: '#fff',
		borderRadius: 10,
		boxShadow: '0 0 6px -3px #000',
		padding: 10,
		border: `0.5px solid ${theme.palette.primary.dark}`
	},
	iconContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	icons: {
		margin: 2
	},
	observation: {
		padding: '8px 0',
		color: '#9e9e9ede'
	},
	creation: {
		display: 'flex',
		alignItems: 'center'
	},
	chips: {
		height: '24px',
		marginBottom: '2px'
	},
	chipsMargin: {
		height: '24px',
		marginRight: '2px',
		marginBottom: '2px'
	},
	chipLabel: {
		margin: 'auto'
	}
})

export const imagePreviewStyles = (theme) => ({
	root: {},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing.unit,
		background: theme.palette.primary.light,
		color: theme.palette.primary.contrastText
	},
	ios:{
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: theme.spacing.unit * 2,
		paddingTop:theme.spacing.unit *4 ,
		// paddingLeft: 'env(safe-area-inset-left)',
		paddingRight: 0,
		// paddingRight:'env(safe-area-inset-right)',
		background: theme.palette.primary.light,
		color: theme.palette.primary.contrastText
		// paddingTop:`calc(${theme.spacing.unit * 1}px + env(safe-area-inset-top))`,
	},
	content: {
		// background: '#fff',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	contentZoom: {
		padding: theme.spacing.unit,
		...getDesktopBreakpoint(theme, {
			paddingTop: theme.spacing.unit * 1.5,
			paddingBottom: theme.spacing.unit * 1.5
		}),
		overflow: 'auto',
		height: '90vh',
		background: '#f2f2f2'
	},
	containerZoom: {
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: 10
	}
})

export const detailsStyles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit *
			2}px 0 ${theme.spacing.unit * 2}px `
	},
	container: {
		'&& > div': {
			marginBottom: '10px'
		}
	},
	chips: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: 10,
		'&& > div': {
			color: '#fff',
			background: 'blue',
			margin: 2
		}
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		marginBottom: 10
	},
	headerAuthor: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerCard: {
		display: 'flex',
		alignItems: 'center'
	},
	observation: {
		padding: '10px 5px',
		backgroundColor: '#f1f1f1',
		borderLeft: '2px solid blue'
	}
})
