import {
	Button,
	Checkbox,
	ExpansionPanel,
	ExpansionPanelSummary,
	Icon as IconMaterial,
	IconButton,
	InputBase,
	List,
	Paper,
	Switch,
	FormGroup, 
	FormControlLabel,
	Chip,
	Avatar
} from '@material-ui/core'
import {PersonAdd,Search} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useReducer, useState } from 'react'
import { CSVLink } from 'react-csv'
import {
	Card,
	Dimmer,
	Icon,
	Image,
	Loader,
	Modal,
	Segment,
	Table,
} from 'semantic-ui-react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { firebase } from '../../../firebase'
import { doPasswordReset } from '../../../firebase/auth'
import { fs, auth } from '../../../firebase/firebase'
import { findActiveCompanyId } from '../../../firebase/user'
import './style.css'
import TagActive from './TagActive'


export const useStyles = makeStyles(() => ({
	expanded: {
		margin: 0,
		'&content': {
			margin: 0,
		},
	},
	content: {
		margin: 0,
	},
	root: {
		'&$expanded': {
			margin: 0,
		},
		'&content': {
			margin: 0,
		},
	},
}))

function reducerInital(state, action) {
	switch (action.type) {
		case 'SET_NOTIFICATIONS': {
			return Object.assign({}, state, { notifications: action.notifications })
		}

		case 'CHANGE_SORT':
			if (state.column === action.column) {
				if (action.column === 'lastDate') {
					return {
						...state,
						data: _.orderBy(state.data, 'date', state.direction === 'ascending' ? 'desc' : 'asc'),
						direction: state.direction === 'ascending' ? 'descending' : 'ascending',
					}
				}
				return {
					...state,
					data: state.data.slice().reverse(),
					direction: state.direction === 'ascending' ? 'descending' : 'ascending',
				}
			}

			break

		case 'UPLOAD_DATA':
			if (action.payload) {
				return {
					...state,
					data: action.payload,
					direction: state.direction === 'ascending' ? 'descending' : 'ascending',
				}
			}

			return {
				column: action.column,
				data: _.sortBy(state.data, [action.column]),
				direction: 'ascending',
			}

		default:
			throw new Error()
	}
}

function InfoCompanyCard(infoBase, companyInfo, headers, usersCompanyWithoutArquiaMembers) {
	if (!!companyInfo) {
		return (
			<Card className="Container-company-info">
				<div className="container-img-logo">
					<img src={companyInfo?.logo} alt={'Logo empresa'} />
				</div>
				<Card.Content>
					<Card.Header>{companyInfo?.name}</Card.Header>
					<Card.Meta>
						<span className="date">{companyInfo?.abbrev}</span>
					</Card.Meta>
				</Card.Content>
				<Card.Content extra>
					<a>
						<Icon name="user" />
						{infoBase.length} Usuarios
					</a>
				</Card.Content>
				<div className="container-csv">
					<CSVLink filename={`Usuarios-${companyInfo.abbrev}.csv`} headers={headers} data={usersCompanyWithoutArquiaMembers}>
						<p>Lista de Usuarios</p>
					</CSVLink>
				</div>
			</Card>
		)
	}
}

function LoaderIndeterminate(user = false, searchUser = '') {
	return (
		<div>
			<Segment sty>
				<Dimmer active>
					<Loader indeterminate>{user && searchUser.length > 0 ? 'Usuário não encontrado' : 'Preparing Files'}</Loader>
				</Dimmer>

				<Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
			</Segment>
		</div>
	)
}

function ControlPanelUser() {
	const [findUser, setFindUser] = useState('')
	const [uid, setUid] = useState('')
	const [open, setOpen] = useState(false)
	const [editUser, setEditUser] = useState(null)
	const [plugins, setPlugins] = useState(null)
	const [dashSets, setDashSets] = useState(null)
	const [cards, setCards] = useState(null)
	const [companyData, setCompanyData] = useState(null)
	const [userCompanyState, setUserCompanyState] = useState('')
	const [userCompanyfirestore, setUserCompanyfirestore] = useState('')
	const [usersCompanyData, setUsersCompanyData] = useState([])
	const [usersCompanyDataBase, setUsersCompanyDataBase] = useState([])
	const [time, setTime] = useState(Date.now())
	const [usersCompanyWithoutArquiaMembers, setUsersCompanyWithoutArquiaMembers] = useState([])
	const [notifications, setNotifications] = useState(null)

	const MySwal = withReactContent(Swal)
	const classes = useStyles()
	// const [notifications, setNotifications] = useReducer( reducerInital, { notifications: null })
	const [state, dispatch] = useReducer(reducerInital, {
		column: null,
		data: usersCompanyData,
		direction: null,
	})

	const { column, data, direction } = state

	function handleCheckboxValue(infoData, type) {
		const user = editUser

		// console.log('infoData', infoData)

		switch (type) {
			case 'notify':
				setUserNotification(infoData)

				break

			case 'plugin':
				let pluginsByUserRef = fs
					.collection('companies')
					.doc(userCompanyState)
					.collection('users')
					.doc(user.id)
					.collection('plugins')
					.doc(infoData.id)

				pluginsByUserRef.get().then(result => {
					let verifyPluginNotExists = !!result.data() ? true : false

					if (verifyPluginNotExists === false) {
						// Se o usuario nao tiver o plugin cadastrado no firebase, aqui ele verifica e adiciona

						setPluginState(infoData)

						fs.collection('companies')
							.doc(userCompanyState)
							.collection('users')
							.doc(user.id)
							.collection('plugins')
							.doc(infoData.id)
							.set({
								active: infoData.active,
								dataSource: infoData.dataSource,
								lastUpdate: !!infoData.lastUpdate ? !!infoData.lastUpdate : null,
								name: infoData.name,
								type: infoData.type,
							})
							.catch(error => {
								console.error('Error writing document: ', error)
							})
					} else {
						console.log('esse outro', userCompanyState)
						console.log('esse user', user)
						setPluginState(infoData)

						fs.collection('companies')
							.doc(userCompanyState)
							.collection('users')
							.doc(user.id)
							.collection('plugins')
							.doc(infoData.id)
							.set(
								{
									active: infoData.active,
								},
								{ merge: true }
							)
							.catch(error => {
								console.error('Error writing document: ', error)
							})
					}
				})

				break
			case 'dashSet':
				// Se o usuario nao tiver o dashSet cadastrado no firebase, aqui ele verifica e adiciona
				fs.collection('companies')
					.doc(userCompanyState)
					.collection('users')
					.doc(user.id)
					.collection('plugins')
					.doc(infoData.idPlugin)
					.collection('dashSets')
					.doc(infoData.id)
					.get()
					.then(result => {
						if (!result.exists) {
							setDashsetState(infoData)

							fs.collection('companies')
								.doc(userCompanyState)
								.collection('users')
								.doc(user.id)
								.collection('plugins')
								.doc(infoData.idPlugin)
								.collection('dashSets')
								.doc(infoData.id)
								.set({
									active: infoData.active,
									order: infoData.order,
								})
						} else {
							setDashsetState(infoData)

							fs.collection('companies')
								.doc(userCompanyState)
								.collection('users')
								.doc(user.id)
								.collection('plugins')
								.doc(infoData.idPlugin)
								.collection('dashSets')
								.doc(infoData.id)
								.set(
									{
										active: infoData.active,
									},
									{ merge: true }
								)
						}
					})
				break
			case 'card':
				//Verifica se existe card
				fs.collection('companies')
					.doc(userCompanyState)
					.collection('users')
					.doc(user.id)
					.collection('plugins')
					.doc(infoData.idPlugin)
					.collection('dashSets')
					.doc(infoData.idDashSet)
					.collection('cards')
					.doc(infoData.id)
					.get()
					.then(result => {
						//Se o card nao existe, criamos ele
						if (!result.exists) {
							setDashItemState(infoData)

							fs.collection('companies')
								.doc(userCompanyState)
								.collection('users')
								.doc(user.id)
								.collection('plugins')
								.doc(infoData.idPlugin)
								.collection('dashSets')
								.doc(infoData.idDashSet)
								.collection('cards')
								.doc(infoData.id)
								.set({
									active: infoData.active,
								})
						} else {
							setDashItemState(infoData)

							fs.collection('companies')
								.doc(userCompanyState)
								.collection('users')
								.doc(user.id)
								.collection('plugins')
								.doc(infoData.idPlugin)
								.collection('dashSets')
								.doc(infoData.idDashSet)
								.collection('cards')
								.doc(infoData.id)
								.set(
									{
										active: infoData.active,
									},
									{ merge: true }
								)
						}
					})
				break
		}
	}

	function setPluginState(infoData) {
		let newPlugins = null

		plugins.forEach(item => {
			if (item.id === infoData.id) {
				item.active = !infoData.active
			}
		})

		newPlugins = plugins

		setPlugins(newPlugins)
	}

	function setDashsetState(infoData) {
		let newDashset = null

		dashSets.forEach(item => {
			if (item.id === infoData.id) {
				item.active = !infoData.active
			}
		})

		newDashset = dashSets

		setDashSets(newDashset)
	}

	function setDashItemState(infoData) {
		let newDashItem = null

		cards.forEach(item => {
			if (item.id === infoData.id) {
				item.active = !infoData.active
			}
		})

		newDashItem = cards

		setCards(newDashItem)
	}

	function setNotificationState(infoData, activated) {
		let newNotification = []

		notifications.forEach(item => {
			if (item.idNotification == infoData.idNotification) {
				newNotification.push(
					Object.assign({}, item, {
						activeNotification: activated,
					})
				)
			} else {
				newNotification.push(item)
			}
		})

		setNotifications(newNotification)
	}

	function setUserNotification(infoData) {
		let firebase = fs.collection('notificationPubSub').doc(infoData.idNotification).collection('users').doc(infoData.id)

		firebase.get().then(snap => {
			let doc = snap.data()

			if (doc === undefined) {
				firebase
					.set({
						active: true,
					})
					.then(() => {
						setNotificationState(infoData, true)
					})
			} else if (doc.active === true) {
				firebase
					.set({
						active: false,
					})
					.then(() => {
						setNotificationState(infoData, false)
					})
			} else {
				firebase
					.set({
						active: true,
					})
					.then(() => {
						setNotificationState(infoData, true)
					})
			}
		})
	}

	function initializeData() {
		const { uid, displayName, email } = firebase.auth.currentUser

		if (!!uid && !!email) {
			setUid(uid)
			findActiveCompanyId(uid)
				.then(company => setUserCompanyState(company))
				.catch(err => console.error(err))
		}
	}

	function userDashSetsCardsData(uid) {
		let companyId = userCompanyState
		// console.count('GERANDO TUDO DE NOVO')

		if (uid && companyId) {
			let pluginsArray = []
			let dashSetsArray = []
			let cardsArray = []

			const userPluginPath = fs.collection('companies').doc(userCompanyfirestore).collection('users').doc(uid).collection('plugins')

			const userDashSetPath = fs.collection('companies').doc(userCompanyfirestore).collection('users').doc(uid).collection('plugins')

			const dashSetsQuery = fs.collection('dashSets')

			const pluginsQuery = fs.collection('companies').doc(companyId).collection('plugins').get()

			const cardsQuery = fs.collection('companies').doc(companyId).collection('plugins')

			pluginsQuery
				.then(async querySnapshot => {
					querySnapshot.forEach(plugin => {
						pluginsArray.push(
							Object.assign(plugin.data(), {
								id: plugin.id,
								pluginActivePlaceHolder: false,
								active: false,
							})
						)
					})
					await Promise.all(pluginsArray).then(result => {
						userPluginPath.get().then(PlugDocs => {
							PlugDocs.docs.forEach(pluginUser => {
								result.forEach(CompanyPlugin => {
									if (pluginUser.id === CompanyPlugin.id) {
										// if (pluginUser.data().active === false) {

										let docPlugin = pluginUser.data()
										CompanyPlugin.active = !!docPlugin.active ? docPlugin.active : false
										// }
									}
								})
							})
						})
						setPlugins(result)
					})
				})
				.then(() => {
					pluginsArray.forEach(doc => {
						cardsQuery
							.doc(doc.id)
							.collection('dashSets')
							.get()
							.then(querySnapshot => {
								querySnapshot.forEach(dashSet => {
									dashSetsQuery
										.doc(dashSet.id)
										.get()
										.then(async dashSetDocRaiz => {
											dashSetsArray.push(
												Object.assign(dashSet.data(), {
													name: dashSetDocRaiz.data().name,
													id: dashSetDocRaiz.id,
													idPlugin: doc.id,
													dashSetActivePlaceholder: false,
													active: false,
												})
											)
											await Promise.all(dashSetsArray).then(result => {
												userDashSetPath
													.doc(doc.id)
													.collection('dashSets')
													.get()
													.then(docDashSet => {
														docDashSet.docs.forEach(dash => {
															result.forEach(CompanyDashSet => {
																if (dash.id === CompanyDashSet.id) {
																	// if (!dash.data().active) {
																	let docDashset = dash.data()
																	CompanyDashSet.active = !!docDashset.active ? docDashset.active : false
																	// }
																}
															})
														})
													})
												setDashSets(result)
											})
										})

									cardsQuery
										.doc(doc.id)
										.collection('dashSets')
										.doc(dashSet.id)
										.collection('cards')
										.get()
										.then(querySnapshot => {
											querySnapshot.forEach(docs => {

												let localEnv = process.env.REACT_APP_NODE_ENV === 'production' ?
													'active' :
													process.env.REACT_APP_NODE_ENV == 'stage' ?
													'activeStage' :
													'activeDev'

												fs.collection('dashItems')
													.doc(docs.id)
													.get()
													.then(async result => {
														if(result.data()[localEnv] === true) {
															// CARDS QUE ESTAO DENTRO DA EMPRESA
															cardsArray.push(
																Object.assign(docs.data(), {
																	id: docs.id,
																	title: result.data().title,
																	idDashSet: dashSet.id,
																	idPlugin: doc.id,
																	active: false,
																})
															)
														}
														await Promise.all(cardsArray).then(async arrayWithCards => {
															arrayWithCards.forEach(cardCompanie => {
																// CARDS QUE ESTAO NO USUARIO DENTRO DA EMPRESA
																fs.collection('companies')
																	.doc(userCompanyfirestore)
																	.collection('users')
																	.doc(uid)
																	.collection('plugins')
																	.doc(cardCompanie.idPlugin)
																	.collection('dashSets')
																	.doc(cardCompanie.idDashSet)
																	.collection('cards')
																	.doc(cardCompanie.id)
																	.get()
																	.then(cardUsers => {
																		if (cardCompanie.id === cardUsers.id) {
																			// if (!!cardUsers.data()) {
																			// if (cardCompanie.active && cardUsers.data().active) {
																			// 	cardCompanie.active = true
																			// } else {
																			let docCard = cardUsers.data()
																			cardCompanie.active = !!docCard.active ? docCard.active : false
																			// }
																			// }
																		}
																	})
																	.catch(error => {
																		console.error('Error getting document:', error)
																	})
															})
															await Promise.all(arrayWithCards).then(() => {
																setCards(arrayWithCards)
															})
														})
													}).catch((err) => {
														console.error('err', err)
													})
											})
										})
								})
							})
					})
				})
		}
	}

	function monitoringCompanyActiveUser(uid) {
		if (uid.length > 0) {
			fs.collection('users')
				.doc(uid)
				.onSnapshot(
					{
						// Listen for document metadata changes
						includeMetadataChanges: true,
					},
					doc => {
						if (!!doc.data().activeCompany) {
							setUserCompanyfirestore(doc.data().activeCompany)
						}
					}
				)
		}
	}

	function getUsersCompany(companyId) {
		if (companyId) {
			let users = {}
			let usersDb = fs.collection('users')
			let allUsers = []
			let companyUsers = fs.collection('companies').doc(companyId).collection('users').get()

			companyUsers
				.then(querySnapshot => {
					querySnapshot.forEach(doc => {
						allUsers.push(usersDb.doc(doc.id).get())
						users[doc.id] = {
							...doc.data(),
						}
					})
					return Promise.all(allUsers)
				})
				.then(data => {
					let companyAllUsers = data.map(user => {
						return {
							id: user.id,
							...user.data(),
							...users[user.id],
						}
					})
					let dataUsers = companyAllUsers.filter(user => user.name)
					setUsersCompanyData(dataUsers)
					setUsersCompanyDataBase(dataUsers)
				})
		}
	}

	function searchUserInList(event) {
		let user = event.target.value

		setFindUser(user)
		if (!!user && user.length > 0) {
			let newList = usersCompanyData.filter(
				users => users.name.toLowerCase().indexOf(user) > -1 || users.name.toUpperCase().indexOf(user) > -1 || users.name.indexOf(user) > -1
			)

			setUsersCompanyData(newList)
		} else {
			setUsersCompanyData(usersCompanyDataBase)
		}
	}

	function getCompanyData(companyId) {
		if (companyId) {
			let companyData = fs.collection('companies').doc(companyId).get()

			companyData.then(doc => {
				let companyData = {
					...doc.data(),
					id: doc.id,
				}
				setCompanyData(companyData)
			})
		}
	}

	function validateCompanyUsers(user) {
		if (!user.companies) {
			fs.collection('users').doc(user.id).set({
				active: user.active,
				verifyStatus: true
			}, { merge: true })
		}
	}

	function handleChangeEditUser(event, user) {
		const target = event.target.name

		const userDocRef = fs.collection('companies').doc(userCompanyState).collection('users').doc(user.id)

		switch (target) {
			case 'ativo':
				userDocRef.set({ active: !user.active }, { merge: true })
				setEditUser(Object.assign(user, { active: !user.active }))
				validateCompanyUsers(user)

				break

			case 'acao':
				userDocRef.set({ actions: !user.actions }, { merge: true })
				setEditUser(Object.assign(user, { actions: !user.actions }))

				break

			case 'notificacao':
				userDocRef.set({ notification: !user.notification }, { merge: true })
				setEditUser(Object.assign(user, { notification: !user.notification }))

				break

			default:
				break
		}
		getUsersCompany(userCompanyState)
	}

	function handleData(seconds) {
		if (!seconds) return 'Sem registro'

		const date = new Date(seconds * 1000).toISOString().split('T')[0]
		let newDate = new Date(seconds * 1000)

		const [year, month, day] = date.split('-')

		const result = `${day}/${month}/${year} - ${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`

		return result
	}

	function initializeListUsersMembers() {
		const listUsersArquia = [
			'iUKRh1hksSgm9V0rlJl8XFrANL52',
			'ew1zYzWFpvWWq7HQ7YnJVwcC8O02',
			'KmykDN5poDO43qxHGCgA4UvNbNJ2',
			'mUC33jVUJRRTemn1hhM6SFSGtw22',
			'CweZqvKuEXaSnHMkYVEZYIFLLDh1',
			'Iy7VLsXVznX2ndpcLxCehGqmzW83',
			'FjElLTRvVzfrHTNemaKrIiud0lR2',
			'bPjyOTOG57buNAkC8Spa7J0TrO32',
			'iDoHbdR0OeQdj8dMOuMtR6DjGEC2',
			'tLzknnKVaHhQlwJmuz5ycPP4ZzL2',
			'yfNeRBo2etfqktsPJnfU4GqjhHR2',
			'yG9VwrYpWcbRM2nK2NwzDng5Rre2',
			'w17F8Ff7Tvb8B2VAsycruwz1b3Y2',
			'iE3xVWZLhGbGftQqnQUf1AokBAZ2',
			'IWscYyv5k5T0N1O8NYoC2oVb01N2',
			'SKni4RVXBXhWZYDEmSPwiiu9FJJ3',
			'xDXLlV5CG5Qx1SbMQi6t1FwYV6v2',
			's17uXtRScgTEYPeRB3jeuB8F1Y53',
			'CiQRmPCJw7eeRdYldK0rOMNkTAk1',
		]

		// Remove os usuarios da arquia do csv ao baixar no painel de controle

		if (listUsersArquia.includes(uid)) {
			let usersCompanyAjustDateTime = usersCompanyData.map(user => {
				if (!!user.date && !!user.date.seconds) {
					user.dateUser = handleData(user.date.seconds)
					user.date = user.date.seconds
				} else {
					user.dateUser = handleData()
					user.date = 0
				}

				return user
			})
			let removeArquiaMembers = usersCompanyAjustDateTime.filter(user => !listUsersArquia.includes(user.id))
			setUsersCompanyWithoutArquiaMembers(removeArquiaMembers)

			dispatch({
				type: 'UPLOAD_DATA',
				payload: usersCompanyAjustDateTime,
			})
		} else {
			let usersCompanyAjustDateTime = usersCompanyData.map(user => {
				if (!!user.date && !!user.date.seconds) {
					user.dateUser = handleData(user.date.seconds)
					user.date = user.date.seconds
				} else {
					user.dateUser = handleData()
					user.date = 0
				}

				return user
			})

			let removeArquiaMembers = usersCompanyAjustDateTime.filter(user => !listUsersArquia.includes(user.id))

			setUsersCompanyWithoutArquiaMembers(removeArquiaMembers)

			dispatch({ type: 'UPLOAD_DATA', payload: removeArquiaMembers })
		}
	}

	function inputSearchTextWithCreateUser() {
		return (
			<Paper component="form" className="rootSearch">
				<IconButton className={'iconButton'} aria-label="search">
					<Search />
				</IconButton>
				<InputBase
					onChange={event => searchUserInList(event)}
					className={'input'}
					placeholder="Digite o nome do usuário"
					inputProps={{
						'aria-label': 'Digite o nome do usuário',
					}}
				/>
				<Button
					className="btn-create-user"
					onClick={() => {
						window.location.href = '#/form-create-user'
					}}>
					<PersonAdd />
					Criar Usuário
				</Button>
			</Paper>
		)
	}

	async function getUserNotification(user) {
		let firebase = fs.collection('notificationPubSub')

		let value = !!user ? user : editUser

		let allNotifications = []

		firebase
			.where('active', '==', true)
			.get()
			.then(snap => {
				snap.forEach(item => {
					let doc = item.data()

					firebase
						.doc(item.id)
						.collection('users')
						.doc(value.id)
						.get()
						.then(snapShot => {
							let docUser = snapShot.data()

							if (docUser === undefined) {
								allNotifications.push(
									Object.assign({}, doc, {
										idNotification: item.id,
										activeNotification: false,
									})
								)
							} else {
								allNotifications.push(
									Object.assign({}, doc, {
										idNotification: item.id,
										activeNotification: docUser.active,
									})
								)
							}
						})
				})
			})

		Promise.resolve(allNotifications).then(allNotifications => {
			setNotifications(allNotifications)
		})
	}

	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (!open && !!editUser) {
			setPlugins(null)
			setCards(null)
			setDashSets(null)
		}
	}, [open, editUser])

	useEffect(() => {
		if (data.length !== usersCompanyData.length) {
			initializeListUsersMembers()
		}
	}, [data, usersCompanyData])

	useEffect(() => {
		initializeData()
		monitoringCompanyActiveUser(uid)
	}, [])

	useEffect(() => {
		getUsersCompany(userCompanyfirestore)
		getCompanyData(userCompanyfirestore)
	}, [userCompanyState, userCompanyfirestore])

	useEffect(() => {
		initializeData()
		monitoringCompanyActiveUser(uid)
	}, [uid])

	useEffect(() => {
		if (userCompanyState !== userCompanyfirestore) {
			initializeData()
			setUserCompanyState(userCompanyfirestore)
			getUsersCompany(userCompanyfirestore)
		}
	}, [userCompanyState, userCompanyfirestore, usersCompanyData])

	const headers = [
		{ label: 'Nome', key: 'name' },
		{ label: 'Email', key: 'email' },
		{ label: 'DT Ultimo acesso', key: 'date' },
		{ label: 'Status', key: 'active' },
	]

	return (
		<>
			<div className="Container">
				{InfoCompanyCard(usersCompanyWithoutArquiaMembers, companyData, headers, usersCompanyWithoutArquiaMembers)}

				{usersCompanyData.length === 0 ? (
					<>
						{inputSearchTextWithCreateUser()}
						{LoaderIndeterminate(true, findUser)}
					</>
				) : (
					<>
						{inputSearchTextWithCreateUser()}

						<Table sortable celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Editar</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'name' ? direction : null}
										onClick={() =>
											dispatch({
												type: 'CHANGE_SORT',
												column: 'name',
											})
										}>
										Nome
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'email' ? direction : null}
										onClick={() =>
											dispatch({
												type: 'CHANGE_SORT',
												column: 'email',
											})
										}>
										Email
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'lastDate' ? direction : null}
										onClick={() =>
											dispatch({
												type: 'CHANGE_SORT',
												column: 'lastDate',
											})
										}>
										Último login
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'active' ? direction : null}
										onClick={() =>
											dispatch({
												type: 'CHANGE_SORT',
												column: 'active',
											})
										}>
										Status
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'actions' ? direction : null}
										onClick={() =>
											dispatch({
												type: 'CHANGE_SORT',
												column: 'actions',
											})
										}>
										Ações
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'notification' ? direction : null}
										onClick={() =>
											dispatch({
												type: 'CHANGE_SORT',
												column: 'notification',
											})
										}>
										Notificações
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'loginType' ? direction : null}
										onClick={() =>
											dispatch({
												type: 'CHANGE_SORT',
												column: 'loginType',
											})
										}>
										Tipo de login
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{data.map(users => {
									return (
										<Table.Row key={users.id}>
											<Table.Cell
												className="editor-cell"
												onClick={() => {
													setEditUser(users)
													userDashSetsCardsData(users.id)
													setOpen(true)
													getUserNotification(users)
												}}>
												<Icon name="edit" />
											</Table.Cell>
											<Table.Cell>{users.name}</Table.Cell>
											<Table.Cell>{users.email}</Table.Cell>
											<Table.Cell>{users.dateUser}</Table.Cell>
											<Table.Cell>
												<TagActive active={users.active} />
											</Table.Cell>
											<Table.Cell>
												<TagActive active={users.actions} />
											</Table.Cell>
											<Table.Cell>
												<TagActive active={users.notification} />
											</Table.Cell>
											<Table.Cell>{users.signInType}</Table.Cell>
										</Table.Row>
									)
								})}
							</Table.Body>
						</Table>
						<Modal style={{ width: '70%' }} open={open} onOpen={() => setOpen(true)}>
							<Modal.Header className="modal-header">
								{companyData?.abbrev}
								<Button
									color="primary"
									className="btn-reset-password"
									onClick={() => {
										MySwal.fire({
											title: 'Voce realmente quer resetar sua senha?',
											icon: 'warning',
											showCancelButton: true,
											showConfirmButton: true,
											confirmButtonColor: '#3085d6',
											cancelButtonColor: '#d33',
											confirmButtonText: `Resetar senha`,
											cancelButtonText: `Não`,
										}).then(result => {
											if (result.isConfirmed) {
												doPasswordReset(editUser.email)
												Swal.fire('Email de reset enviado!', '', 'success')
											} else {
												Swal.fire('Senha não foi resetada', '', 'info')
											}
										})
									}}>
									Resetar senha
								</Button>
							</Modal.Header>

							<Modal.Content image scrolling>
								{!!editUser && (
									<div
										style={{
											padding: '5px',
											textAlign: 'center',
										}}>
										<Image size="medium" src={companyData?.logo} wrapped />
										<h1>{editUser.name}</h1>
										<h3>{editUser.email}</h3>
										<FormGroup row>
											<FormControlLabel
												control={
													<Switch
														checked={editUser.active}
														onChange={e => handleChangeEditUser(e, editUser)}
														name="ativo"
													/>
												}
												label="Ativo"
											/>
											<FormControlLabel
												control={
													<Switch
														checked={editUser.actions}
														onChange={e => handleChangeEditUser(e, editUser)}
														name="acao"
													/>
												}
												label="Açao"
											/>
											<FormControlLabel
												control={
													<Switch
														checked={editUser.notification}
														onChange={e => handleChangeEditUser(e, editUser)}
														name="notificacao"
													/>
												}
												label="Notificaçao"
											/>
										</FormGroup>
									</div>
								)}
								{!!plugins && !!dashSets && !!cards ? (
									<Modal.Description style={{ display: 'flex' }}>
										<Modal.Description style={{ margin: '10px' }}>
											{/* {console.count('*')} */}

											{
												//GAMBIARRA

												<p style={{ display: 'none' }}>{time}</p>
											}
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}>
												<h2>Plugins</h2>
											</div>
											<List>
												{plugins.map((plugin, i) => {
													const labelId = `checkbox-list-label-${plugin.name}`

													return (
														<ExpansionPanel className="expansionPanelContainer" square key={plugin.id}>
															<ExpansionPanelSummary
																className={classNames(
																	'expansionPanelSummary',
																	`expansionPanelSummary-plugin-${plugin.active}`
																)}
																expandIcon={<IconMaterial>keyboard_arrow_down</IconMaterial>}>
																<Checkbox
																	edge="start"
																	checked={plugin.active}
																	tabIndex={-1}
																	disableRipple
																	inputProps={{
																		'aria-labelledby': labelId,
																	}}
																	onChange={() => {
																		handleCheckboxValue(plugin, 'plugin')
																	}}
																/>
																<p>{plugin.name}</p>
															</ExpansionPanelSummary>

															{dashSets.length > 0 &&
																dashSets.map(dashSet => {
																	if (plugin.id === dashSet.idPlugin) {
																		return (
																			<ExpansionPanel key={dashSet.id}>
																				<ExpansionPanelSummary
																					className={classNames(
																						'expansionPanelSummary',
																						'expansionPanelSummary-dashSet',
																						`expansionPanelSummary-dashSet-${dashSet.active}`
																					)}
																					expandIcon={<IconMaterial>keyboard_arrow_down</IconMaterial>}>
																					<Checkbox
																						edge="start"
																						checked={dashSet.active}
																						tabIndex={-1}
																						disableRipple
																						inputProps={{
																							'aria-labelledby': labelId,
																						}}
																						onChange={event => handleCheckboxValue(dashSet, 'dashSet')}
																					/>
																					<p>{dashSet.name}</p>
																				</ExpansionPanelSummary>
																				{cards.map(card => {
																					if (card.idDashSet === dashSet.id && !!card.title) {
																						return (
																							<ExpansionPanel classes={classes} key={card.id}>
																								<ExpansionPanelSummary
																									className={classNames(
																										'expansionPanelSummary',
																										`expansionPanelSummary-card`,
																										`expansionPanelSummary-card-${card.active}`
																									)}>
																									<Checkbox
																										edge="start"
																										checked={card.active}
																										tabIndex={-1}
																										disableRipple
																										inputProps={{
																											'aria-labelledby': labelId,
																										}}
																										onChange={() =>
																											handleCheckboxValue(card, 'card')
																										}
																									/>
																									<p>{card.title}</p>
																								</ExpansionPanelSummary>
																							</ExpansionPanel>
																						)
																					}
																				})}
																			</ExpansionPanel>
																		)
																	}
																})}
														</ExpansionPanel>
													)
												})}
											</List>
										</Modal.Description>
										<Modal.Description style={{ margin: '10px' }}>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}>
												<h2>Notificações</h2>
											</div>
											<List>
												{!!notifications &&
													notifications.map((notify, i) => {
														return (
															<ExpansionPanel className="expansionPanelContainer" square key={notify.id}>
																<ExpansionPanelSummary
																	className={classNames('expansionPanelSummary')}
																	expandIcon={<IconMaterial>keyboard_arrow_down</IconMaterial>}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'center',
																		}}>
																		<Checkbox
																			edge="start"
																			checked={notify.activeNotification}
																			tabIndex={-1}
																			disableRipple
																			onChange={() => {
																				handleCheckboxValue(Object.assign({}, editUser, notify), 'notify')
																			}}
																		/>
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				justifyContent: 'center',
																			}}>
																			<p style={{ margin: '20px' }}>{`${notify.title} ${notify.details}`}</p>
																			<Chip
																				// size='small'
																				// avatar={<Avatar>P</Avatar>}
																				label={notify.tag}
																				color="primary"
																				variant="outlined"
																			/>
																		</div>
																	</div>
																</ExpansionPanelSummary>
																{!!notify.message ? (
																	<ExpansionPanel className="expansionPanelContainer" square key={notify.id}>
																		<ExpansionPanelSummary>
																			<div>
																				<p style={{ margin: '10px' }}>Notificação: {notify.message}</p>
																			</div>
																		</ExpansionPanelSummary>
																	</ExpansionPanel>
																) : (
																	<></>
																)}
															</ExpansionPanel>
														)
													})}
											</List>
										</Modal.Description>
									</Modal.Description>
								) : (
									LoaderIndeterminate()
								)}
							</Modal.Content>
							<Modal.Actions>
								<Button className="btn-close-modal" onClick={() => setOpen(false)}>
									Fechar
								</Button>
							</Modal.Actions>
						</Modal>
					</>
				)}
			</div>
		</>
	)
}

export { ControlPanelUser }
