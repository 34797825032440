import evaluationUserActionsReducer from './userActions'
import evaluationDialogReducer from './dialog'
import evaluationPopupReducer from './popup'
import evaluationReasonsReducer from './reasons'

export {
	evaluationUserActionsReducer,
	evaluationDialogReducer,
	evaluationPopupReducer,
	evaluationReasonsReducer
}
