import React from 'react'
import { Icon, Typography, withStyles, Button } from '@material-ui/core'
import { translate } from '../../../../utils/lang'

/**
 * A standard implementation for system notifications related to email interactions
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 */
const EmailSent = ({ classes, handleClick }) => (
	<div className={classes.root}>
		<div className={classes.content}>
			<Icon>mail</Icon>
			<Typography>
				{translate('notification/a-link-was-sent-to-your-email')}
			</Typography>
		</div>
		<Button variant="text" fullWidth onClick={handleClick}>
			<Icon>keyboard_backspace</Icon>
			{translate('label/go-back-to', { to: translate('label/sign-in') })}
		</Button>
	</div>
)

const styles = (theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing.unit
		}
	},
	content: {
		padding: theme.spacing.unit * 2,
		border: '1px solid',
		borderRadius: 5,
		borderColor: theme.palette.signalGreen.main,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > span, p': {
			color: '#2f2f2f' //theme.palette.signalGreen.main
		},
		'& > p': {
			margin: '0 5px'
		}
	}
})

export default withStyles(styles)(EmailSent)
