import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import withActiveCompany from '../withActiveCompany'

import FilterList from '../filterList/FilterList'
import { resolveFilters } from '../../../services/filter'
import { makeGetGlobalPluginFilterKeys } from '../../../selectors/filter'
import {
	setFilterBarIsLoading,
	setFilterBarUnit
} from '../../../actions/filterBar'
import { resolveAllowedFilters } from './functions/resolveAllowedFilters'
import { makeGetFilterBarIsLoading } from '../../../selectors/filter/bar'
import { makeGetFilterBarItems } from './functions/makeGetFilterBarItems'

/**
 * Responsible for manage Filter List Container. Fetch for filter values direct from firebase.
 *
 * @author davispndola
 * @version 1.0.0
 * @description Created the container
 *
 * @description Implemented the preloaded filter logic
 * @author davispindola
 * @version 2.0
 *
 * @description Made chained responses on promises available, remodelled component
 * @author brunoteixeirasilva
 * @version 2.1
 *
 * !preloaded => keys => preserve id => options => values => set state
 */
class FilterBarContainer extends React.Component {
	componentDidMount() {
		const { isLoading } = this.props

		if (!isLoading) {
			this.loadFilterInfo()
		}
	}

	loadFilterInfo = async () => {
		const {
			// setGlobalLabels,
			// activeCompanyId,
			// preloadedFilters,
			allowedFilters,
			onSetFilterbarFiltersIsLoading
			// onReceiveFilterBarFilter
		} = this.props

		// verifies that the user has pre-loaded filters
		// executs only if user has preloaded filters
		// TODO: APPLY PRELOADED FILTERS AGAIN
		// if (this.userHasPreloadedFilters()) {
		// set filter is loading to true
		onSetFilterbarFiltersIsLoading(true)
			.then(
				// after searchs for filter keys, preserve the ids of used filters
				() => resolveFilters()
				// preserveFilterKeyIds(activeCompanyId, preloadedFilters)
			)
			//TODO: Find allowed filters
			.then((filters) => resolveAllowedFilters(allowedFilters, filters))
			// after complete each search, sets filter is loading to false
			.then((filters) => onSetFilterbarFiltersIsLoading(false))
			// On error, cancels the isLoading state
			.catch((error) => onSetFilterbarFiltersIsLoading(false))
		// }
	}

	userHasPreloadedFilters = () => {
		const { preloadedFilters } = this.props

		return Object.keys(preloadedFilters).length > 0
	}

	render() {
		const { isLoading, noMargin } = this.props

		return (
			!isLoading && (
				<div>
					<FilterList noMargin={noMargin} {...this.props} />
				</div>
			)
		)
	}
}

FilterBarContainer.defaultProps = {
	noMargin: false
}

FilterBarContainer.propTypes = {
	allowedFilters: PropTypes.array,
	noMargin: PropTypes.bool
}

const makeMapStateToProps = () => {
	const getPluginFiltersKeys = makeGetGlobalPluginFilterKeys(),
		getFilterBarIsLoading = makeGetFilterBarIsLoading(),
		getFilterBarItems = makeGetFilterBarItems()

	const mapStateToProps = (state, props) => {
		const globalFilterKeys = getPluginFiltersKeys(state),
			isLoading = getFilterBarIsLoading(state),
			resolvedAllowedFilters =
				!!props.allowedFilters && props.allowedFilters.length > 0
					? props.allowedFilters
					: globalFilterKeys,
			items = getFilterBarItems(state, resolvedAllowedFilters)

		return {
			allowedFilters: resolvedAllowedFilters,
			items: items,
			preloadedFilters: state.sessionState.preloaded.filters,
			preloadedFilterBarData: globalFilterKeys,
			isLoading: isLoading
		}
	}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	onSetFilterbarFiltersIsLoading: async (isLoading) =>
		dispatch(setFilterBarIsLoading(isLoading)),
	onReceiveFilterBarFilter: async (data) => dispatch(setFilterBarUnit(data))
	// setGlobalLabels: (data) => dispatch(setGlobalFilterLabels(data))
})

export default compose(
	withActiveCompany,
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(FilterBarContainer)
