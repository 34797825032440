import { createSelector } from 'reselect'
import { getActionById } from '../actions'
import { wasEvaluated } from '../wasEvaluated'

const getEvaluationRating = (state) => state.evaluationUserActionsState.rating

const makeGetEvaluationRating = () =>
	createSelector(
		[getEvaluationRating, getActionById],
		(rating, action) => {
			if (wasEvaluated(action, 'evaluate')) {
				return action.evaluate.rating
			}

			return rating
		}
	)

export default makeGetEvaluationRating
