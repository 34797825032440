import {
	SET_ACTION_PLAN_DATA,
	SET_ACTION_PLAN_LOADING_STATE,
	SET_ACTION_PLAN_DATE_CONFIG,
	CLEAR_ACTION_PLAN_DATA,
	SET_ACTION_PLAN_SWIPER
} from '../../actions/actionPlan'

const INITIAL_STATE = {
	data: null,
	isLoading: null,
	dateConfig: {},
	indexSwiper: 0,
	ownFilters: {},
	searchText: {
		subGrupo: '',
		cargo: '',
		area: '',
		vip: '',
		diretoriaExecutiva: '',
		diretoria: '',
		gerenciaSenior: '',
		gerencia: '',
		coordenacao: '',
		supervisao: '',
		filial: ''
	}
}

/**
 * Action Plan card state reducer
 * @description Created the function reducer
 * @author Davi Wegner
 * @version 1.0
 */
const actionPlanReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_ACTION_PLAN_SWIPER:
			return Object.assign({}, state, {
				indexSwiper: action.indexSwiper
			})
		case SET_ACTION_PLAN_DATA:
			return Object.assign({}, state, {
				data: action.data,
				isLoading: false
			})
		case SET_ACTION_PLAN_LOADING_STATE:
			return Object.assign({}, state, { 
				isLoading: action.isLoading
			})
		case SET_ACTION_PLAN_DATE_CONFIG:
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		case CLEAR_ACTION_PLAN_DATA:
			return Object.assign({}, state, {
				data: null
			})
		case 'SET_COVID_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_COVID_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_COVID_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		default:
			return state
	}
}

export default actionPlanReducer
