import React from 'react'
import {
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Button,
	FormGroup,
	TextField,
	withStyles
} from '@material-ui/core'
import { translate } from '../../../../../utils/lang'
import ActionFilterForm from './ActionFilterForm'
import { actionFilterDialog } from './styles'

/**
 * the action filter dialog also works as Action Filter Form container
 * , but it main functions is displaying the filters form in action page
 * @author Davi Wegner
 * @description created the component
 * @version 1.0
 */
class ActionFilterDialog extends React.Component {
	initiateFilters = () => {
		const {
			author,
			cardId,
			receiver_key,
			content_classification,
			content_type,
			receiver,
			cardIds,
			classification,
			type,
			active,
			selectableAuthors,
			handleCardChange,
			handleReceiverChange,
			handleAuthorChange,
			handleActiveChange,
			handleClassificationChange,
			handleTypeChange,
			authorInfo,
			cardInfo,
			activeInfo,
			typeInfo,
			classificationInfo,
			status
		} = this.props

		const filters = [
			{
				label: translate('label/card'),
				value: cardId,
				onSelect: handleCardChange,
				fields: cardIds,
				fieldInfo: cardInfo
			},
			{
				label: translate('label/receiver'),
				value: receiver_key,
				onSelect: handleReceiverChange,
				fields: receiver,
				fieldInfo: null
			},
			{
				label: translate('label/author'),
				value: author,
				onSelect: handleAuthorChange,
				fields: selectableAuthors,
				fieldInfo: authorInfo
			},
			{
				label: translate('label/status'),
				value: active,
				onSelect: handleActiveChange,
				fields: status,
				fieldInfo: activeInfo
			},
			{
				label: translate('label/classification'),
				value: content_classification,
				fields: classification,
				onSelect: handleClassificationChange,
				fieldInfo: classificationInfo
			},
			{
				label: translate('label/type'),
				value: content_type,
				onSelect: handleTypeChange,
				fields: type,
				fieldInfo: typeInfo
			}
		]
		return filters
	}
	render() {
		const {
			fromDate,
			untilDate,
			handleDelete,
			handleDateChange,
			handleState,
			isOpen,
			classes
		} = this.props

		return (
			<Dialog
				PaperProps={{
					className: classes.dialogPaper
				}}
				fullWidth
				onClose={() => handleState(false)}
				open={isOpen}
			>
				<DialogTitle>{translate('label/filter')}</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<FormGroup>
						{this.initiateFilters().map((filter) => (
							<ActionFilterForm
								key={filter.label}
								handleDelete={handleDelete}
								label={filter.label}
								onSelect={filter.onSelect}
								value={filter.value}
								fields={filter.fields}
								fieldInfo={filter.fieldInfo}
							/>
						))}
						<form className={classes.form} noValidate>
							<TextField
								id="date"
								name="from"
								onChange={(event, date) =>
									handleDateChange(event)
								}
								label={translate('label/from')}
								type="date"
								value={!!fromDate ? fromDate : ''}
								InputLabelProps={{
									shrink: true
								}}
							/>
							<TextField
								id="date"
								onChange={(event, date) =>
									handleDateChange(event)
								}
								label={translate('label/until')}
								type="date"
								name="until"
								value={!!untilDate ? untilDate : ''}
								InputLabelProps={{
									shrink: true
								}}
							/>
						</form>
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleState(false)}>
						{translate('label/confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withStyles(actionFilterDialog)(ActionFilterDialog)
