import React, { Suspense } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import {  withWidth } from '@material-ui/core'
import * as routes from '../../../constants/routes'
import { isWidthUp } from '@material-ui/core/withWidth'
import makeStepManager, {
	makeGetRootStepTitle,
	makeGetComponentPath
} from '../../../selectors/step'
import {
	setCurrentDashItem,
	setStepLoading,
	setOwnFilters,
	setUnsubscribe,
	setCompanyDashItemStep
} from '../../../actions/step/manager'
import Loader from '../loader'
import { getComponent } from '../../../lazyComponentImporter'
import withActiveCompany from '../withActiveCompany'
import memoize from '../../../utils/memoize'
import * as Step from '../../../firebase/step'
import * as CompanyDashItemStep from '../../../firebase/company/dashItem/step'
import { setRootDashItemSteps } from '../../../actions/dashItem/step'
import { parseQueryString } from '../../../utils/string'
import { getCompanyPluginDataSourceById } from '../../../selectors/company/plugin'
import * as CompanyPlugin from '../../../firebase/company/plugin'
import { setCompanyPlugin } from '../../../actions/company/plugin'
import { translate } from '../../../utils/lang'
import { makeGetCompanyDashItemStepById } from '../../../selectors/company/dashItem/step'
import AssistiveTouchButton from '../assistiveButton'
import TitleBar from '../titleBar/TitleBar'
import ReturnButton from '../titleBar/returnButton/ReturnButton'

/**
 * Step container, it will check the params, and will get the component that represents this step
 * @description Created the class
 * @author Davi Wegner
 * @version 1.0
 *
 * @description added  parse query string params
 * @author Davi Wegner
 * @version 1.1
 *
 * @description fixed  checking for any companyStep instead of current step on companyStep
 * @author Davi Wegner
 * @version 1.2
 *
 * @description added the possibilitie of loading custom company component instead of default step component
 * @author Wegner
 * @version 1.3
 *
 * @description added the possibilitie of return one step
 * @author lopes
 * @version 1.4
 */
class StepContainer extends React.Component {
	constructor(props) {
		super(props)
		this.cardRef = React.createRef()
		this.extendedRef = React.createRef()
	}
	componentDidMount() {
		this.fetchData()
		this.syncStepFilters()
	}

	componentWillUnmount() {
		if (this.props.unsubscribe instanceof Function) this.props.unsubscribe()
		this.props.setCurrentOwnFilters(null)
	}

	fetchData = () => {
		const {
			component,
			activeCompanyId,
			setCompanyDashItemStep,
			stepsLoaded,
			companyStep,
			onSetLoading,
			dataSourceId,
			onCompanyPluginLoaded,
			history
		} = this.props
		const { dashItemId, pluginId } = this.props.match.params

		CompanyPlugin.getDataSourceId(activeCompanyId, pluginId).catch(() => {
			return history.push(routes.PLUGIN)
		})

		if (!dataSourceId) {
			CompanyPlugin.getById(activeCompanyId, pluginId)
				//Dispatch it to its reducer
				.then((pluginObject) =>
					onCompanyPluginLoaded(pluginId, pluginObject)
				)
		}

		if (!component || !companyStep) {
			onSetLoading(true)
				.then(() => Step.getSteps(dashItemId))
				.then((steps) => {
					let dashItemSteps = {}
					//Steps available for this dashItem?
					if (!steps.empty) {
						//Puts each one at a separate object for dispatching
						steps.forEach((step) => {
							dashItemSteps = Object.assign({}, dashItemSteps, {
								[step.id]: step.data()
							})
						})

						//Dispatches to store
						stepsLoaded(dashItemId, dashItemSteps)
					}

					//Returns for next user
					return dashItemSteps
				})
				.then(() =>
					CompanyDashItemStep.getAll(activeCompanyId, dashItemId)
				)
				.then((companyDashItemSteps) => {
					let companySteps = {}
					companyDashItemSteps.forEach((companyStep) => {
						companySteps = Object.assign({}, companySteps, {
							[companyStep.id]: companyStep.data()
						})
					})
					return setCompanyDashItemStep(dashItemId, companySteps)
				})
				// setCurrentStep({ [step.id]: step.data() })

				.then(() => onSetLoading(false))
				.catch((error) => {
					//Stops the loader
					onSetLoading(false)

					//Throws the error upwards
					throw error
				})
		}
	}

	syncStepFilters = () => {
		const {
			activeCompanyId,
			setCurrentOwnFilters,
			setCurrentUnsubscribe,
			match
		} = this.props
		const { dashItemId, stepId } = match.params

		//? @daviwegner
		setCurrentUnsubscribe(
			CompanyDashItemStep.onGetOwnFilters(
				activeCompanyId,
				dashItemId,
				stepId,
				setCurrentOwnFilters
			)
		)
	}

	handleAction = () => {
		const { getJSON, getDomSnapshot, handleActionModal, data } = this.props
		// const self = this

		getJSON(data)
		getDomSnapshot(ReactDOM.findDOMNode(this.cardRef.current))
		handleActionModal()
	}

	render() {
		const {
			component,
			companyStep,
			stepManager,
			ownFilters,
			stepTitle,
			// stepWarnings,
			params,
			width,
			dataSourceId
		} = this.props

		return (
			<div>
				{isWidthUp('sm', width) ? (
					<Suspense fallback={<Loader />}>
						{!!component &&
							!!companyStep &&
							React.cloneElement(component, {
								params: params,
								stepManager,
								ownFilters,
								dataSourceId,
								...this.props.match.params,
								...companyStep,
								children: (
									<TitleBar
										title={
											!stepTitle
												? ''
												: translate(
														`label/${stepTitle}`
												  )
										}
										showButton={true}
									>
										<ReturnButton />
									</TitleBar>
								)
							})}
					</Suspense>
				) : (
					<Suspense fallback={<Loader />}>
						<AssistiveTouchButton>
							{!!component &&
								!!companyStep &&
								React.cloneElement(component, {
									params: params,
									stepManager,
									ownFilters,
									dataSourceId,
									mobile: true,
									...this.props.match.params,
									...companyStep,
									children: <ReturnButton />
								})}
						</AssistiveTouchButton>
					</Suspense>
				)}
			</div>
		)
	}
}

const makeStateToProps = () => {
	const getStepManager = makeStepManager(),
		getComp = memoize((path) => getComponent(path)),
		getComponentPath = makeGetComponentPath(),
		getCompanyDashItemStep = makeGetCompanyDashItemStepById(),
		getRootStepTitle = makeGetRootStepTitle()

	const mapStateToProps = (state, ownProps) => {
		const { dashItemId, stepId, pluginId } = ownProps.match.params,
			{ search } = ownProps.location,
			params = !!search ? parseQueryString(search) : null,
			stepTitle = getRootStepTitle(state, dashItemId, stepId),
			componentPath = getComponentPath(state, dashItemId, stepId)

		return {
			companyStep: getCompanyDashItemStep(state, dashItemId, stepId),
			component: !!componentPath ? getComp(componentPath) : null,
			stepId: stepId,
			dataSourceId: getCompanyPluginDataSourceById(state, pluginId),
			loading: state.companyDashItemStepState.isLoading,
			ownFilters: state.companyDashItemStepState.ownFilters,
			params: params,
			pluginId: pluginId,
			stepManager: getStepManager(state, ownProps),
			stepTitle: stepTitle,
			unsubscribe: state.companyDashItemStepState.unsubscribe
		}
	}

	return mapStateToProps
}
const mapDispatchToProps = (dispatch) => ({
	stepsLoaded: (step, dashItem) =>
		dispatch(setRootDashItemSteps(step, dashItem)),
	setCompanyDashItemStep: (dashItemId, step) =>
		dispatch(setCompanyDashItemStep(dashItemId, step)),
	setCurrentDashItem: (dashItem) => dispatch(setCurrentDashItem(dashItem)),
	setCurrentOwnFilters: (ownFilter) => dispatch(setOwnFilters(ownFilter)),
	setCurrentUnsubscribe: (unsubscribe) =>
		dispatch(setUnsubscribe(unsubscribe)),
	onSetLoading: async (isLoading) => dispatch(setStepLoading(isLoading)),
	onCompanyPluginLoaded: async (pluginId, plugin) =>
		dispatch(setCompanyPlugin(pluginId, plugin))
})

export default compose(
	withActiveCompany,
	withRouter,
	connect(
		makeStateToProps(),
		mapDispatchToProps
	),
	withWidth()
)(StepContainer)
