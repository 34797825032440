import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './styles'

/**
 * QuestionBody is simple component used in rating forms for manage the content visibility based os isVisible prop.
 *
 * Used for unshow content when the rating data doesn't contains an especific value.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Implemented classes
 * @author davispindola
 * @version 1.1
 *
 */
const QuestionBody = (props) => {
	const { isVisible, children, classes } = props

	return <div className={classes.root}>{isVisible && children}</div>
}

QuestionBody.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired
}

export default withStyles(styles)(QuestionBody)
