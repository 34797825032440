import {
	SET_NC_AUDITS_STATE_LOADING,
	SET_NC_AUDITS_DATA
} from '../../actions/ncAudits'

const INITIAL_STATE = {
	isLoading: false,
	data: null
}

const ncAuditsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_NC_AUDITS_STATE_LOADING:
			return Object.assign({}, state, { isLoading: action.isLoading })
		case SET_NC_AUDITS_DATA:
			return Object.assign({}, state, { data: action.data })
		default:
			return state
	}
}

export default ncAuditsReducer
