// import { apps, initializeApp } from 'firebase'
import { firebase } from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import '@firebase/functions'
import '@firebase/storage'
import '@firebase/analytics'
/*
 * TODO: Change this file to use environments
const prodConfig = {
	apiKey: YOUR_API_KEY,
	authDomain: YOUR_AUTH_DOMAIN,
	databaseURL: YOUR_DATABASE_URL,
	projectId: YOUR_PROJECT_ID,
	storageBucket: "",
	messagingSenderId: YOUR_MESSAGING_SENDER_ID
};

const devConfig = {
	apiKey: YOUR_API_KEY,
	authDomain: YOUR_AUTH_DOMAIN,
	databaseURL: YOUR_DATABASE_URL,
	projectId: YOUR_PROJECT_ID,
	storageBucket: "",
	messagingSenderId: YOUR_MESSAGING_SENDER_ID
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

*/

let appCurrent

// [CURRENT CONFIG]
//
const configFirestore = {
	apiKey: 'AIzaSyDhrN2Yu9kbO0BLvrebL3GPWuTaugDLgd8',
	authDomain: 'agildash-app-db.firebaseapp.com',
	databaseURL: 'https://agildash-app-db.firebaseio.com',
	projectId: 'agildash-app-db',
	storageBucket: 'agildash-app-db.appspot.com',
	messagingSenderId: '1040140252011',
	appId: '1:1040140252011:web:df260c8152005889',
}

//Will initialize both firestore and realtime db apps
if (!firebase.apps.length) {
	//FIXME: Remove app-config instance
	//appLegacy = initializeApp(config, 'legacy')
	appCurrent = firebase.initializeApp(configFirestore, 'current')
}

const fs = appCurrent.firestore()
fs.settings({
	cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
})
fs.enablePersistence({
	synchronizeTabs: true,
})

const config = {
	apiKey: 'AIzaSyDhrN2Yu9kbO0BLvrebL3GPWuTaugDLgd8',
	authDomain: 'agildash-app-db.firebaseapp.com',
	databaseURL: 'https://agildash-app-db.firebaseio.com',
	projectId: 'agildash-app-db',
	storageBucket: 'agildash-app-db.appspot.com',
	messagingSenderId: '1040140252011',
	appId: '1:1040140252011:web:df260c8152005889',
}

const app2 = firebase.initializeApp(config)

//const db = firebase.firestore();
export const auth = appCurrent.auth(),
	auth2 = app2.auth(),
	functions = appCurrent.functions(),
	newStorage = appCurrent.storage(),
	analytics = appCurrent.analytics()

export { fs }
