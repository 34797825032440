export const SET_GRAPH_LEGEND_DISPLAY_OPTION =
		'SET_GRAPH_LEGEND_DISPLAY_OPTION',
	SET_GRAPH_LEGEND_DISPLAY_TYPE = 'SET_GRAPH_LEGEND_DISPLAY_TYPE'

export const setDisplay = (dashItemId, stepId, isHidden) => {
	return {
		type: SET_GRAPH_LEGEND_DISPLAY_OPTION,
		dashItemId,
		isHidden,
		stepId
	}
}

export const setDisplayType = (mobile) => {
	return {
		type: SET_GRAPH_LEGEND_DISPLAY_TYPE,
		mobile
	}
}
