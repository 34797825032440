export const MANAGE_CHECKLIST_COMPANY_LOADING_STATE =
		'MANAGE_CHECKLIST_COMPANY_LOADING_STATE',
	LOAD_CHECKLIST_COMPANY_DATA = 'LOAD_CHECKLIST_COMPANY_DATA',
	SET_CHECKLIST_COMPANY_PERIOD = 'SET_CHECKLIST_COMPANY_PERIOD'

export const setChecklistCompanyPeriod = (dateConfig, value, formType) => ({
	type: SET_CHECKLIST_COMPANY_PERIOD,
	dateConfig,
	value,
	formType,
})

export const loadChecklistCompanyData = checklistCompany => ({
	type: LOAD_CHECKLIST_COMPANY_DATA,
	checklistCompany,
})

export const setLoading = isLoading => ({
	type: MANAGE_CHECKLIST_COMPANY_LOADING_STATE,
	isLoading,
})
