import {
	makeGetGlobalFilterValues,
	makeGetGlobalPluginFilterDataSetById
} from '../../../../selectors/filter'
import { makeGetGlobalFiltersDataSetItemsByKeySet } from '../../../../selectors/filter/data'
import { store } from '../../../../store'
import { createSelector } from 'reselect'

/**
 * [memoised] Selects the filter bar items collection
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetFilterBarItems = () =>
	createSelector(
		[makeGetGlobalFilterValues(), (_, allowedFilters) => allowedFilters],
		(itemKeys, allowedFilters) => {
			const state = store.getState(),
				getDataSetItemsByItemKeySet = makeGetGlobalFiltersDataSetItemsByKeySet(),
				getFilterDataSetName = makeGetGlobalPluginFilterDataSetById(),
				availableKeys = Object.keys(itemKeys)
			let items, dataSets

			//No items at all applied as filters
			if (availableKeys.length === 0) return []

			//Gets dataSets names' by allowed filters collection
			dataSets = allowedFilters.map((filter) =>
				getFilterDataSetName(state, filter)
			)

			//Creates an array of items which were filtered from
			//Global filter values
			items = dataSets.reduce((result, currentDataSet) => {
				let resolvedItems = getDataSetItemsByItemKeySet(
					state,
					currentDataSet,
					itemKeys[currentDataSet]
				)

				//No resolved items at all, returns only the result itself
				if (!resolvedItems || resolvedItems.length === 0) return result

				return [...result, ...resolvedItems]
			}, [])

			return items
		}
	)
