const INITIAL_STATE = {
	formData: {
		generatePassword: false,
		userEmail: '',
		userName: '',
		userPassword: '',
		systemGeneratedPassword: null
	},
	saving: false,
	askForUserConfig: false,
	dirty: false
}

/**
 * Manages new user (creation) state layer
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
function userFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_NEW_USER_SAVING': {
			return Object.assign({}, state, { saving: action.saving })
		}
		case 'SET_NEW_USER_ASK_FOR_CONFIG': {
			return Object.assign({}, state, {
				askForUserConfig: action.askForUserConfig
			})
		}
		case 'SET_NEW_USER_FORM_DIRTY': {
			return Object.assign({}, state, { dirty: action.dirty })
		}
		case 'STORE_NEW_USER_DATA': {
			return Object.assign({}, state, { formData: action.formData })
		}
		case 'CLEAR_NEW_USER_DATA': {
			return Object.assign({}, state, INITIAL_STATE)
		}
		default:
			return state
	}
}

export default userFormReducer
