// Begin: Compulsory imports

import { withStyles } from '@material-ui/core'
import React from 'react'
import withSuperUserAccess from '../../reusable/withSuperUserAccess'
import withActiveCompany from '../../reusable/withActiveCompany'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import styles from './styles'
import { fs } from '../../../firebase/firebase'

/**
 * Created for testing work-in-progress components, only super user can see this page
 *
 * @description Created the component
 * @author wegner
 * @version 1.0 8qWzMuf5Ln5phJfC369F
 */

/**
 * Pagina limpa para fazer testes
 *
 * @description Created the component
 * @author Lucas Lopes
 * @version 1.1
 */

class TestPage extends React.Component {
	state = {
		dashItems: [],
		dashItemsSteps: [],
		finishDashItems: null,
		finished: null,
		plugins: [],
		dashSets: [],
		cards: [],
		filters: [],
	}

	componentDidMount() {}

	componentDidUpdate() {
		// console.log(this.props)
	}

	copyBase = () => {
		const { plugins, dashSets, cards } = this.state
		const { authUser } = this.props
		const { uid } = authUser

		// Empresa base para cópia
		const initialCompany = '-UeOuO700DIllEivPPdG'
		// Empresa destino
		const targetCompany = '3wUR80TwIEoBYT7lBxY4'
		// Se precisar de algum plugin especifico
		const pluginTarget = 'appAudit'

		fs.collection('companies')
			.doc(initialCompany)
			.collection('plugins')
			.get()
			.then(doc => {
				doc.docs.map(documents => {
					plugins.push({ ...documents.data(), id: documents.id, companyId: targetCompany, author: uid, date: new Date() })
					this.setState({ plugins })
				})
			})
			.then(() => {
				if (plugins.length > 0) {
					plugins.map(plugin => {
						if (plugin.dataSource === pluginTarget) {
							fs.collection('companies')
								.doc(initialCompany)
								.collection('plugins')
								.doc(plugin.id)
								.collection('dashSets')
								.get()
								.then(dashSetDoc => {
									dashSetDoc.docs.map(dashSet => {
										dashSets.push({ ...dashSet.data(), id: dashSet.id, idPlugin: plugin.id, author: uid, date: new Date() })
										this.setState({ dashSets })
									})
								})
								.then(() => {
									if (dashSets.length > 0) {
										dashSets.map(dashSet => {
											fs.collection('companies')
												.doc(initialCompany)
												.collection('plugins')
												.doc(dashSet.idPlugin)
												.collection('dashSets')
												.doc(dashSet.id)
												.collection('cards')
												.get()
												.then(cardsDoc => {
													cardsDoc.docs.map(cardsDocument => {
														cards.push({
															...cardsDocument.data(),
															id: cardsDocument.id,
															idPlugin: dashSet.idPlugin,
															idDashSet: dashSet.id,
															author: uid,
															date: new Date(),
														})
														this.setState({ cards })
													})
												})
												.then(() => {
													console.log('AQUI')
													//Aqui depois de copiarmos todos os plugins, dashSets e cards, agora adicionamos na empresa nova
													fs.collection('companies')
														.doc(targetCompany)
														.collection('plugins')
														.get()
														.then(doc => {
															//Se nao tiver sido criado plugins ainda

															plugins.map(plugin => {
																// Algum caso especifico
																if (plugin.dataSource === pluginTarget) {
																	fs.collection('companies')
																		.doc(targetCompany)
																		.collection('plugins')
																		.doc(plugin.id)
																		.set({ ...plugin }, { merge: true })
																		.then(() => {
																			// Agora vamos criar os dashSets
																			fs.collection('companies')
																				.doc(targetCompany)
																				.collection('plugins')
																				.doc(plugin.id)
																				.collection('dashSets')
																				.get()
																				.then(() => {
																					dashSets.map(dashSet => {
																						fs.collection('companies')
																							.doc(targetCompany)
																							.collection('plugins')
																							.doc(plugin.id)
																							.collection('dashSets')
																							.doc(dashSet.id)
																							.set({ ...dashSet }, { merge: true })
																							.then(() => {
																								//Agora criamos os cards
																								fs.collection('companies')
																									.doc(targetCompany)
																									.collection('plugins')
																									.doc(plugin.id)
																									.collection('dashSets')
																									.doc(dashSet.id)
																									.collection('cards')
																									.get()
																									.then(() => {
																										cards.map(card => {
																											fs.collection('companies')
																												.doc(targetCompany)
																												.collection('plugins')
																												.doc(plugin.id)
																												.collection('dashSets')
																												.doc(dashSet.id)
																												.collection('cards')
																												.get()
																												.then(() => {
																													if (
																														card.idDashSet === dashSet.id
																													) {
																														fs.collection('companies')
																															.doc(targetCompany)
																															.collection('plugins')
																															.doc(plugin.id)
																															.collection('dashSets')
																															.doc(dashSet.id)
																															.collection('cards')
																															.doc(card.id)
																															.set(
																																{ ...card },
																																{
																																	merge: true,
																																}
																															)
																															.then(() => {
																																this.setState({
																																	finished: true,
																																})
																															})
																													}
																												})
																										})
																									})
																							})
																					})
																				})
																		})
																}
															})
														})
												})
										})
									}
								})
						}
					})
				}
			})
	}

	copyFiltersPlugins = () => {
		const { plugins, dashSets, cards, filters } = this.state
		const { authUser } = this.props
		const { uid } = authUser

		// Empresa base para cópia
		const initialCompany = '4ydHc5bfNqeFMpXIEK4T'
		// Empresa destino
		const targetCompany = '3wUR80TwIEoBYT7lBxY4'
		// Se precisar de algum plugin especifico
		const appAuditPlugin = '-JhLeOlGIEjaIOFHR0xd'

		fs.collection('companies')
			.doc(initialCompany)
			.collection('plugins')
			.doc(appAuditPlugin)
			.collection('filters')
			.get()
			.then(doc => {
				doc.docs.map(documents => {
					filters.push({ ...documents.data(), date: new Date(), id: documents.id })
					this.setState({ filters })
				})
			})
			.then(() => {
				filters.map(filter => {
					fs.collection('companies')
						.doc(targetCompany)
						.collection('plugins')
						.doc(appAuditPlugin)
						.collection('filters')
						.doc(filter.id)
						.set(filter, { merge: true })
				})
			})
	}

	something = () => {
		const { dashItems } = this.state

		//Alterar aqui a empresa base
		const initialCompany = '-UeOuO700DIllEivPPdG'

		fs.collection('companies')
			.doc(initialCompany)
			.collection('dashItems')
			.get()
			.then(doc => {
				doc.docs.map((documents, index) => {
					dashItems.push({ ...documents.data(), id: documents.id })
					console.log('dashItems',dashItems)
					this.setState({ dashItems })
				})
			})
	}

	lestNotDone = () => {
		const { dashItems, dashItemsSteps } = this.state

		//Alterar aqui a empresa base
		const initialCompany = '-UeOuO700DIllEivPPdG'

		if (dashItems.length > 0) {
			dashItems.forEach(item => {
				fs.collection('companies')
					.doc(initialCompany)
					.collection('dashItems')
					.doc(item.id)
					// .collection('steps')
					.collection('localFilters')
					.get()
					.then(internalDoc => {
						if (!internalDoc.empty) {
							internalDoc.docs.forEach(stepsDoc => {
								dashItemsSteps.push({
									...stepsDoc.data(),
									id: stepsDoc.id,
									idFather: item.id,
								})
								this.setState({ dashItemsSteps })
							})
						}
					})
			})
		}
	}

	done = () => {
		const { dashItems, dashItemsSteps } = this.state

		//Alterar aqui a empresa destino
		const companyTarget = '3wUR80TwIEoBYT7lBxY4'

		dashItems.forEach(item => {
			fs.collection('companies')
				.doc(companyTarget)
				.collection('dashItems')
				.get()
				.then(() => {
					dashItemsSteps.forEach(step => {
						if (item.id === step.idFather) {
							//Cria e adiciona tudo dentro do documento
							fs.collection('companies')
								.doc(companyTarget)
								.collection('dashItems')
								.doc(item.id)
								.set(item, { merge: true })
								.then(() => {
									//Cria colecao e adiciona tudo dentro do documento
									fs.collection('companies')
										.doc(companyTarget)
										.collection('dashItems')
										.doc(item.id)
										.collection('localFilters')
										// .collection('steps')
										.doc(step.id)
										.set(step, { merge: true })
								})
								.then(() => {
									this.setState({ finishDashItems: true })
								})
								.catch(err => {
									console.log(err)
								})
						}
					})
				})
		})
	}

	render() {
		const { dashItems, dashItemsSteps, finishDashItems, finished } = this.state
		return (
			<div style={{ paddingTop: '64px' }}>
				<fieldset>
					<h1>Copiar todos os dashItems e localFilters de uma empresa base para uma nova empresa</h1>
					<h2>Preparar </h2>
					<button onClick={this.something}>Botao para fazer algo, cuidado ao clicar!</button>
					{dashItems.length > 0 && (
						<>
							<h1>Apontar</h1>
							<button onClick={this.lestNotDone}>Preparando...</button>
						</>
					)}

					{dashItemsSteps.length > 0 && (
						<>
							<h1>Fogo</h1>
							<button onClick={this.done}>Com grandes poderes vem grandes responsabilidades</button>
						</>
					)}
					{!!finishDashItems && <h1>Acabou, vai la conferir a merda que tu fez</h1>}
				</fieldset>
				<fieldset>
					<h1>Copiar todos os plugins, dashSets e cards de uma empresa base para uma nova empresa</h1>

					<button onClick={this.copyBase}>Cuidado ao clicar</button>
					{!!finished && <h1>Acabou, vai la conferir a merda que tu fez</h1>}
				</fieldset>

				<fieldset>
					<h1>Copiar todos os filters dentro de plugins</h1>

					<button onClick={this.copyFiltersPlugins}>Cuidado ao clicar</button>
					{!!finished && <h1>Acabou, vai la conferir a merda que tu fez</h1>}
				</fieldset>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	dateConfig: state.actionPlanState.dateConfig,
})

const mapDispatchToProps = dispatch => ({})

TestPage.propTypes = {}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withActiveCompany, withSuperUserAccess)(TestPage)
