import { fs } from '../../firebase'

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 */
export const getActionReasons = (reasonCollection) =>
	fs
		.collection('app')
		.doc('actions')
		.collection(reasonCollection)
		.get()
