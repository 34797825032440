/**
 * Will resolve allowed filters, in case no valid list of allowed filters is identified.
 * Then, will use all global ones available for the plugin.
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} allowedFilters
 */
export const resolveAllowedFilters = async (allowedFilters, filters) => {
	let filterKeys = Object.keys(filters),
		allowed = []

	//No allowed filters specified
	if (
		!allowedFilters ||
		(allowedFilters instanceof Array && allowedFilters.length === 0)
	) {
		allowed = [...filterKeys]
	} else {
		//Some allowed filters specified
		allowed = [...allowedFilters]
	}

	return Promise.resolve(allowed)
}
