/**
 * Dialog reducer: responsible for manage dialog state.
 *
 * @author davispindola
 * @version 1.0.0 - simple implementation for open and close DialogTypeAlert
 *
 */

import {
	SET_DIALOG_TO_CLOSED,
	SET_DIALOG_TO_OPENED
} from '../../component/reusable/dialog/actions/alert'

const INITIAL_STATE = {
	dialogIsOpen: false
}

const dialogReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_DIALOG_TO_OPENED:
			return Object.assign({}, state, {
				dialogIsOpen: action.dialogIsOpen
			})
		case SET_DIALOG_TO_CLOSED:
			return Object.assign({}, state, {
				dialogIsOpen: INITIAL_STATE.dialogIsOpen
			})
		default:
			return state
	}
}

export default dialogReducer
