import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { translate } from '../../../../../utils/lang'

/**
 * Manages to display a notification-content
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description now the component is used, refactor for new notification format
 * @author Davi Wegner
 * @version 2.0
 *
 */
const NotificationContent = (props) => {
	const { classes, type, user, date, notification } = props

	return (
		<div className={classes.content}>
			<div className={classes.message}>
				{
					type == 'action-sent'
					?
					<div>
						<Typography
							component="span"
							variant="body2"
							align="left"
							className={classes.user}
						>
							{user}
						</Typography>
						<Typography variant="body2" component="span">
							{translate(`notification/${type}`)}
						</Typography>
					</div>
					:
					<div>
						<Typography
							component="span"
							variant="body2"
							align="left"
							className={classes.user}
						>
							{notification.title}
						</Typography>
						<Typography variant="body2" component="span">
							{notification.message}
						</Typography>
					</div>
				}
			</div>
			<div className={classes.date}>
				<Typography>
					{new Date(date * 1000).toLocaleDateString('pt-br')}
				</Typography>
			</div>
		</div>
	)
}

const styles = (theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1
	},
	content: {
		padding: '0 5px',
		width: '100%',
		'& > p': {
			color: '#848484'
		}
	},
	user: {
		fontWeight: 500
	},
	date: {
		display: 'flex',
		alignItems: 'center',
		'& > p': {
			width: 'max-content',
			fontSize: '0.775rem',
			color: '#3f51b5'
		}
	},
	message: {
		width: '100%',
		display: 'inline-block',
		'& > span': {
			display: 'inline',
			marginRight: theme.spacing.unit / 2
		}
	}
})

export default withStyles(styles)(NotificationContent)
