import domtoimage from 'dom-to-image'
import html2canvas from 'html2canvas'

/**
 * Casts HTML to a **png** snapshot
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Element} node The node which will be mapped to image
 * @param {Object} options [android-only] The options/modifiers for the generation of image
 */
export const htmlToPng = (
	node,
	options = {
		filter: (node) => {
			return node.tagName !== 'IMG'
		}
	}
) => environmentSafeHtmlToPng(node, options)

/**
 * [environment-safe|internal] Casts a ReactElement to a **png** snapshot
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Element} node The [Element] node which will be mapped to image
 * @param {Object} options [android-only] The options/modifiers for the generation of image
 */
const environmentSafeHtmlToPng = (node, options) =>
	!!window.cordova && window.cordova.platformId === 'ios'
		? html2canvas(node).then((canvas) => canvas.toDataURL('image/png'))
		: domtoimage.toPng(node, options)
