import React from 'react'
import { Bar } from 'react-chartjs-2'
import CommonChart from '../CommonChart'
import { CommonChartPropTypes } from '../propTypes'

/**
 * Manages to display a system-default bar-chart
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description Changed to a separate file
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Changed to a stateless reusable component file
 * @author brunoteixeirasilva
 * @version 1.2
 */

const BarChart = ({
	options,
	display,
	callbacks,
	component,
	...otherProps
}) => {
	return (
		<CommonChart
			Component={component ? component : Bar}
			{...otherProps}
			callbacks={callbacks}
			options={Object.assign(
				{},
				{
					legend: {
						display: !!display,
						position: 'bottom'
					}
				},
				options
			)}
		/>
	)
}

//Uses CommonChart propTypes definitions
BarChart.propTypes = CommonChartPropTypes

export default BarChart
