import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Icon } from '@material-ui/core'
import Tip from '../../tooltip'
import Pointer from '../../pointer'

/**
 * app bar icon component
 * @description created the component
 * @author Davi Wegner
 * @version 1.0
 */
const AppBarIcon = (props) => {
	return (
		<Tip tooltip={props.tooltip}>
			<IconButton
				className={props.classes.button}
				color="inherit"
				aria-label={props.ariaLabel}
				onClick={props.action}
			>
				<Pointer badge={props.badge} isAlert>
					<Icon>{props.icon}</Icon>
				</Pointer>
			</IconButton>
		</Tip>
	)
}

AppBarIcon.propTypes = {
	tooltip: PropTypes.string,
	icon: PropTypes.string,
	badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	action: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	ariaLabel: PropTypes.string.isRequired
}

AppBarIcon.defaultProps = {
	tooltip: null,
	icon: null,
	badge: null
}

export default AppBarIcon
