import React from 'react'
import { Typography } from '@material-ui/core'

/**
 * Question is a typography component used in rating forms
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @param {*} props
 */
const Question = (props) => {
	const { isPrimaryText, primaryText, secondaryText } = props

	return (
		<Typography
			gutterBottom
			children={Boolean(isPrimaryText) ? primaryText : secondaryText}
		/>
	)
}

export default Question
