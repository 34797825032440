import {
	MANAGE_CONSUMPTION_HISTORY_LOADING_STATE,
	LOAD_CONSUMPTION_HISTORY_DATA,
	SET_CONSUMPTION_HISTORY_PERIOD
} from '../../actions/consumption'

const INITIAL_STATE = {
	isLoading: null,
	consumptionHistory: null,
	dateConfig: {},
	update: false,
	id: null,
	shallUpdate: false
}
/**
 * performed audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */
const consumptionHistoryReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_CONSUMPTION_HISTORY_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_CONSUMPTION_HISTORY_DATA: {
			// console.log(action)
			return Object.assign({}, state, {
				consumptionHistory: action.consumptionHistory,
				isLoading: false
			})
		}
		case SET_CONSUMPTION_HISTORY_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				...(action.isLoading ? { isLoading: action.isLoading } : {})
			})
		}
		case 'SET_INSTAL_ID': {
			return Object.assign({}, state, { id: action.id })
		}
		case 'SET_UPDATE_STATE_CONSUMPTION': {
			return Object.assign({}, state, { shallUpdate: action.shallUpdate })
		}
		default:
			return state
	}
}

export default consumptionHistoryReducer
