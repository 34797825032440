/**
 * @author Ray
 * @email mauricio@arquia.com.br
 * @updated 07-12-2020
 */
const getDayBehind = () => {
	let date = new Date()
	date.setDate(date.getDate() - 1)
	return date.toISOString().split('.')[0]
}
export const INITIAL_STATE = {
		isLoading: false,
		filters: {},
		time: {
			from: getDayBehind(),
			to: new Date().toISOString().split('.')[0],
		},
		email: null,
		isDev: false,
		filterWithDev: false,
		pluginName: null,
		authorName: null,
		fileName: null,
		dashItemValue: null,
	},
	SET_LOGS_PAGE_LOADING = 'SET_LOGS_PAGE_LOADING',
	logHandlerReducer = (state = INITIAL_STATE, action) => {
		switch (action.type) {
			case SET_LOGS_PAGE_LOADING:
				return Object.assign({}, state, { isLoading: action.value })
			case 'SET_LOGS_PAGE_FILTER_VALUE':
				return Object.assign({}, state, {
					filters: Object.assign({}, state.filters, action.value),
				})
			case 'SET_LOGS_PAGE_FILTER_DATE':
				return Object.assign({}, state, {
					time: Object.assign({}, state.time, action.time),
				})
			case 'SET_EMAIL_NAME':
				return Object.assign({}, state, {
					email: action.email,
				})
			case 'SET_ISDEV':
				return Object.assign({}, state, {
					isDev: action.isDev,
				})

			case 'SET_FILTER_WITH_DEV':
				return Object.assign({}, state, {
					filterWithDev: action.filterWithDev,
				})
			case 'SET_AUTHOR_EMAIL_NAME':
				return Object.assign({}, state, {
					authorEmail: action.authorEmail,
				})
			case 'SET_PLUGIN_NAME':
				return Object.assign({}, state, {
					pluginName: action.pluginName,
				})
			case 'SET_FILE_NAME':
				return Object.assign({}, state, {
					fileName: action.fileName,
				})
			case 'SET_DASH_ITEM_VALUE':
				return Object.assign({}, state, {
					dashItemValue: action.dashItemValue,
				})
			default:
				return state
		}
	}
