const INITIAL_STATE = {
	open: false
}

/**
 * Manages to switch/toggle on/off at the filter-form state-layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current filterFormReducer state
 */
const invertOpen = (state) =>
	Object.assign({}, state, {
		open: undefined === state.open || !state.open
	})

/**
 * Manages to handle filter-form state-layer
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current filterFormReducer state
 * @param {action} action The action with "type" and desired props
 */
function filterModalReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'TOGGLE_FILTER_MODAL': {
			return invertOpen(state)
		}
		default:
			return state
	}
}

export default filterModalReducer
