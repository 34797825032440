import { createSelector } from 'reselect'
import * as Date from '../../../utils/date'

/**
 * commemorative dates supported by system
 */
const dates = {
    '21/12': 'ch',
    '22/12': 'ch',
    '23/12': 'ch',
    '24/12': 'ch',
    '25/12': 'ch',
    '26/12': 'ny',
    '27/12': 'ny',
    '28/12': 'ny',
    '29/12': 'ny',
    '30/12': 'ny',
    '31/12': 'ny',
    '01/01': 'ny'
}

/**
* valid if the current date is commemorative
*/
const validDate = () => {
    let currentDay, currentMonth, dayAndMonth

    currentDay = Date.getCurrentDayOfMonth()
    currentMonth = Date.getCurrentMonth() + 1 // month is an array [0, 1, 2, ...]

    dayAndMonth = `${currentDay}/${currentMonth}`

    return dayAndMonth
}

//Corrects user name to initials only
let initialsRegex = (name = '') =>
    name.replace(/([^A-Z]){1,}/g, '').substring(0, 2)


export const makeAvatarContent = () => {
    return createSelector([getUser], (user) => {
        const todayDate = validDate()

        if (!dates[todayDate]) {
            return ({
                isCommemorative: false,
                content: user && user.name ? initialsRegex(user.name) : initialsRegex()
            })
        }

        switch (dates[todayDate]) {
            case 'ch': {
                return {
                    isCommemorative: true,
                    content: 'https://image.flaticon.com/icons/svg/1302/1302470.svg'
                }
            }
            case 'ny': {
                return {
                    isCommemorative: true,
                    content: 'https://image.flaticon.com/icons/svg/1329/1329888.svg'
                }
            }
        }
    })
}

const getUser = (state) => state.userState.user