export const MANAGE_RANKING_CHECKLIST_LOADING_STATE = 'MANAGE_RANKING_CHECKLIST_LOADING_STATE',
	LOAD_RANKING_CHECKLIST_DATA = 'LOAD_RANKING_CHECKLIST_DATA',
	SET_RANKING_CHECKLIST_PERIOD = 'SET_RANKING_CHECKLIST_PERIOD'

export const setRankingChecklistPeriod = (dateConfig, value, formType) => ({
	type: SET_RANKING_CHECKLIST_PERIOD,
	dateConfig,
	value,
	formType,
})

export const loadRankingChecklistData = data => ({
	type: LOAD_RANKING_CHECKLIST_DATA,
	data,
})

export const setLoading = isLoading => ({
	type: MANAGE_RANKING_CHECKLIST_LOADING_STATE,
	isLoading,
})
