import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import * as UserNotification from '../../../firebase/user/notification'
import * as User from '../../../firebase/user'
import withAuthentication from '../withAuthentication'
import { makeNotificationCounter } from '../../../selectors/notification'
import { translate } from '../../../utils/lang'
import { showSnackBar } from '../../../actions/snackbar'
import withActiveCompany from '../withActiveCompany'
import { setNotificationAuthorInfo } from '../../../actions/user/notification'

/**
 * Hoc for injecting notification count in components
 *
 * @description created the component
 * @author Davi Wegner
 * @version 1.0
 */
const withNotification = (Component) => {
	class WithNotification extends React.Component {
		componentDidMount() {
			this.props.onCleanNotifications()
			this.fetchNotifications()
		}

		fetchNotifications = () => {
			const {
				authUser,
				unsubcribe,
				onSubscribe,
				onEmmitMessage,
				activeCompanyId
			} = this.props

			if (
				!!authUser &&
				!unsubcribe &&
				!!authUser.uid &&
				!!activeCompanyId
			) {
				let subscribe = UserNotification.checkChanges(
					authUser.uid,
					this.handleNotification,
					onEmmitMessage,
					activeCompanyId
				)
				onSubscribe(subscribe)
			}
		}

		handleNotification = (notifications) => {
			const { onUpdateNotification, setAuthorInfo } = this.props

			//An update has came into the pipeline
			onUpdateNotification(notifications)

			//Getting each notification ID - will find their authors
			const authorIds = Object.values(notifications).map(
				(notification) => {

					return notification.author
				}
			)

			//Won't repeat within a set
			//Iterating each author and setting author info
			new Set(authorIds).forEach((authorId) => {
				User.get(authorId).then((snapshot) => {
					return setAuthorInfo({ [authorId]: snapshot.data() })
				})
			})
		}

		componentWillUnmount() {
			const { unsubscribe } = this.props
			if (!!unsubscribe && typeof unsubscribe == Function) {
				unsubscribe()
			}
		}

		componentDidUpdate(prevProps) {
			const { unsubscribe } = this.props

			//Removing previous ones
			if (!this.props.activeCompanyId) this.props.onCleanNotifications()

			//Active company has changed!
			if (this.props.activeCompanyId !== prevProps.activeCompanyId) {
				//Stoping a listener
				if (!!unsubscribe && typeof unsubscribe == Function)
					unsubscribe()

				//Fetching new ones
				this.fetchNotifications()
			}
		}

		render() {
			return <Component {...this.props} />
		}
	}
	const makeStateToPropsMapper = (state) => {
		const counter = makeNotificationCounter()
		return {
			notificationCount: counter(state),
			unsubcribe: state.notificationNavigationState.unsubcribe
		}
	}

	const mapDispatchToProps = (dispatch) => ({
		onUpdateNotification: (notifications) =>
			dispatch({
				type: 'SET_LOADED_NOTIFICATIONS',
				notifications
			}),
		onSubscribe: (subscribe) =>
			dispatch({
				type: 'NAVIGATION_NOTIFICATION_ON_SUBSCRIBE',
				subscribe
			}),
		onEmmitMessage: () =>
			dispatch(
				showSnackBar(
					translate('notification/you-have-a-new-notification')
				)
			),
		onCleanNotifications: () =>
			dispatch({ type: 'CLEAR_NOTIFICATIONS_ON_COMPANY_CHANGE' }),
		setAuthorInfo: (authorInfo) =>
			dispatch(setNotificationAuthorInfo(authorInfo))
	})

	return compose(
		withActiveCompany,
		withAuthentication,
		connect(
			makeStateToPropsMapper,
			mapDispatchToProps
		)
	)(WithNotification)
}
export default withNotification
