import {
	MANAGE_TIME_DOWN_NC_LOADING_STATE,
	LOAD_TIME_DOWN_NC_DATA,
	SET_TIME_DOWN_NC_PERIOD
} from '../../actions/timeDownNc'

const INITIAL_STATE = {
	isLoading: null,
	timeDownNc: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		Auditor: '',
		NR_Auditoria: '',
		Resultado: '',
		Responsabilidade: '',
		Nome_Formulario: '',
		Nome_Objeto: '',
		Tipo_Autocheck: '',
		Pergunta: '',
		Status_Auditoria: '',
		Data_Deteccao: '',
		Data_Previsao: '',
		Data_Resolucao: '',
		Tipo_Auditor: '',
	},
}
/**
 * time down NC state reducer manager
 * @param {*} state
 * @param {*} action
 */
const timeDownNcReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_TIME_DOWN_NC_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case LOAD_TIME_DOWN_NC_DATA: {
			return Object.assign({}, state, {
				timeDownNc: action.timeDownNc,
				isLoading: false,
			})
		}
		case SET_TIME_DOWN_NC_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value,
						},
					),
				},
				isLoading: true,
			})
		}
		case 'SET_CARD_ID': {
			return Object.assign({}, state, {
				cardId: action.cardId,
			})
		}
		case 'SET_PERFORMED_TIME_DOWN_NC_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_PERFORMED_TIME_DOWN_NC_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_PERFORMED_TIME_DOWN_NC_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		default:
			return state
	}
}

export default timeDownNcReducer
