import { createSelector } from 'reselect'
import { getActionById } from '../actions'
import { wasEvaluated } from '../wasEvaluated'

const getAddedValue = (state) =>
	state.evaluationUserActionsState.containsAddedValue

const makeGetAddedValue = () => {
	return createSelector(
		[getActionById, getAddedValue],
		(action, addedValue) => {
			if (wasEvaluated(action, 'evaluate')) {
				return action.evaluate.containsAddedValue !== null
					? action.evaluate.containsAddedValue.toString()
					: (0).toString()
			}

			return addedValue
		}
	)
}

export default makeGetAddedValue
