import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'

const styles = (theme) => ({
	root: {
		flexGrow: 1
	},
	form: {
		marginTop: theme.spacing.unit * 2,
		[theme.breakpoints.up('xs')]: {
			height: '100%'
		},
		...getDesktopBreakpoint(theme, {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			width: '100%',
			height: '100vh',
			flex: '0 0 auto',
			marginTop: '0'
		})
	}
})

export default styles
