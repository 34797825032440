export const SET_EVALUATION_ACTION_ID = 'SET_EVALUATION_ACTION_ID',
	SET_EVALUATION_ACTION_SCORE = 'SET_EVALUATION_ACTION_SCORE',
	SET_COMPLIMENTS = 'SET_EVALUATION_ACTION_COMPLIMENTS',
	SET_IMPROVEMENTS = 'SET_EVALUATION_ACTION_IMPROVEMENTS',
	SET_COMMENT = 'SET_EVALUATION_ACTION_COMMENT',
	SET_RATING = 'SET_EVALUATION_ACTION_RATING',
	SET_ADDED_VALUE = 'SET_EVALUATION_ACTION_ADDEDD_VALUE',
	CLEAR_DATA = 'CLEAR_EVALUATION_ACTION_DATA'

const INITIAL_STATE = {
	compliments: [],
	improvements: [],
	comment: '',
	rating: null,
	containsAddedValue: null
}

// const setActionId = (state, action) =>
// 	Object.assign({}, state, {
// 		actionId: action.actionId
// 	})

const clearData = (state, action) =>
	Object.assign({}, state, { ...INITIAL_STATE })

/**
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setAddedValue = (state, action) =>
	Object.assign({}, state, {
		containsAddedValue: action.addedValue
	})

/**
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setRating = (state, action) =>
	Object.assign({}, state, {
		rating: action.rating
	})

/**
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setImprovements = (state, action) =>
	Object.assign({}, state, {
		improvements: action.improvements
	})

/**
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setCompliments = (state, action) =>
	Object.assign({}, state, {
		compliments: action.compliments
	})

/**
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setComment = (state, action) =>
	Object.assign({}, state, {
		comment: action.comment
	})

/**
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const evaluationUserActionsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_ADDED_VALUE:
			return setAddedValue(state, action)
		case SET_RATING:
			return setRating(state, action)
		case SET_COMPLIMENTS:
			return setCompliments(state, action)
		case SET_IMPROVEMENTS:
			return setImprovements(state, action)
		case SET_COMMENT:
			return setComment(state, action)
		case CLEAR_DATA:
			return clearData(state, action)
		default:
			return state
	}
}

export default evaluationUserActionsReducer
