import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'
import * as User from '../../../firebase/user'

import Loader from '../../reusable/loader'
import withAuthorization from '../../reusable/withAuthorization'
import AccountForm from '../../reusable/account/AccountForm'
import Avaliation from './Avaliation'

import { withStyles } from '@material-ui/core'
import { translate } from '../../../utils/lang'
import styles from './styles'

/**
 * Will display a page for own-user-information editing
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class AccountPage extends React.Component {
	state = {
		title: translate('title/my-account'),
	}

	componentDidMount() {
		const { authUser, notifyOnGetUser, setEditUser } = this.props

		if (!!authUser && authUser.uid !== null) {
			User.get(authUser.uid).then(snapshot => {
				notifyOnGetUser(snapshot.data())
				setEditUser({
					editUser: { [authUser.uid]: snapshot.data() },
					userKey: authUser.uid,
				})
			})
		}
	}

	render() {
		const { classes, authUser } = this.props
		return (
			<div className={classes.root}>
				{/* <Typography variant="h6">{this.state.title}</Typography> */}
				{/* {!editUser && <Loader />} */}
				{/* {!!editUser && <AccountForm />} */}
				{/* <Provider store={store}> */}
				<Avaliation userId={authUser.uid} />
				{/* </Provider> */}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	user: state.userState.user,
	editUser: state.userEditFormState.editUser,
	authUser: state.sessionState.authUser,
})

const mapDispatchToProps = dispatch => ({
	notifyOnGetUser: user => dispatch({ type: 'USER_LOADED', user }),
	setEditUser: user => dispatch({ type: 'SET_SELECTED_EDIT_USER', user }),
})

const authCondition = authUser => !!authUser

export default compose(
	withAuthorization(authCondition),
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
)(AccountPage)

export { Loader, AccountForm }
