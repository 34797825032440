import { fs, auth } from '../firebase'
import * as User from '../user'
import * as Author from '../../utils/security/author'
import * as ObjectState from '../../utils/object/state'

/**
 * [Firestore] Queries for all groups under a company => companies/${companyId}/groups
 * ! If you are looking for default system groups, look at firebase/group location!
 *
 * @description Created the function using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Function now using Firebase Firestore mechanism.
 * 				It doesn't handle the returning data anymore.
 * 				Name changed from [onceGetGroups] to [getGroups]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId the company identifier
 */
export const getGroups = (companyId) => {
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.where('active', '==', true)

	return ref.get()
}

/**
 * [Firestore] Will load all user-allowed companies.
 * This means: Own companies (FIXME: still have to load user-own) are loaded
 * as well as user participating companies
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} uid Logged user UID
 */
export const getUserAllowed = (uid) => {
	let ref = fs.collection('companies')

	return ref.get().then((companyDocs) => {
		let promises = []
		//let foundCompanies = []
		let companies = []

		//For each found company,
		//Tries to find the relation with the user
		companyDocs.forEach((companyDoc) => {
			//Keeps the company doc saved
			//foundCompanies.push(companyDoc)
			//Projects waiting for solution on this
			promises.push(
				ref
					.doc(companyDoc.id)
					.collection('users')
					.doc(uid)
					.get()
					.then((relation) => {
						if (relation.exists) companies.push(companyDoc)
					})
			)
		})

		return Promise.all(promises).then(() => {
			return companies
		})
	})
}

/**
 * @author Davi Wegner
 * @description Creates the company doc on 'companies' collection
 * @param { Object } companyInfo the company information object to be set on the document
 * @version 1.0
 */
export const doCreate = (companyInfo, usersObject) => {
	let ref = fs.collection('companies').doc()
	ref.set(
		Object.assign(
			{},
			companyInfo,
			Author.generateObject(auth.currentUser.uid)
		)
	)
	ref.collection('users')
		.doc(auth.currentUser.uid)
		.set(
			Object.assign(
				{},
				{ active: true },
				Author.generateObject(auth.currentUser.uid)
			)
		)

	if (companyInfo.owners instanceof Array)
		companyInfo.owners.map((key) =>
			ref
				.collection('users')
				.doc(key)
				.set(
					Object.assign(
						{},
						{ active: true },
						Author.generateObject(auth.currentUser.uid)
					)
				)
		)
	return ref.id
}

/**
 * @author Davi Wegner
 * @description Updates the company info
 * @version 1.0
 */
export const doUpdate = (companyId, companyInfo) => {
	fs.collection('companies')
		.doc(`${companyId}`)
		.update(companyInfo)
}

/**
 * [Firestore] Gets all companies from the collection
 *
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const getAll = () => {
	return fs.collection('companies').get()
}

/**
 * [Firestore] Queries for all (active) users under a company => companyUsers/${companyId}
 *
 * @description Created the function using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Function now using Firebase Firestore mechanism.
 * 				It doesn't handle the returning data anymore.
 * 				Name changed from [onceGetUserKeys] to [getUserKeys]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId the company identifier
 * @param {bool} includeInactive If true, will include inactive user accounts
 */
export const getUserKeys = (companyId, includeInactive = false) => {
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('users')

	//Will include inactive users
	if (!includeInactive) {
		ref = ref.where('active', '==', true)
	}

	return ref.get()
}

/**
 * [Firestore] Gets company user accounts
 *
 * @description Gets Firebase RTDB users by key individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now gets Firebase Firestore company-users.
 * 				Name changed from [onceGetUsers] to [getUsers]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The company identification
 */
export const getUsers = (companyId) => {
	//Looks for each company user and finds the user entry
	return getUserKeys(companyId).then((dataSnapshot) => {
		//When the company user list is valid
		if (!dataSnapshot.empty) {
			let userPromises = []
			dataSnapshot.forEach((snap) => {
				userPromises.push(User.get(snap.id))
			})

			return Promise.all(userPromises)
		}

		//No data found
		return Promise.resolve(dataSnapshot)
	})
}

/**
 * [Firestore] Queries for groups associated to a company ID - Looks by these group Ids
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * TODO: REVIEW method -> needs to add default groups to here
 * @param {string} companyId The current company hash ID
 */
export const getAllGroups = (companyId) => {
	return getGroups(companyId).then((dataSnapshot) => {
		let groups = {}

		dataSnapshot.forEach((groupRelated) => {
			groups[groupRelated.id] = groupRelated.data()
		})

		return groups
	})
}

/**
 * [Firestore] Adds an user (uid) to a company ID => entry at /companyUsers
 *
 * @description Adds Firebase RTDB user to a company by key, individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now adds to Firebase Firestore /companies/${companyId}/users/${uid}.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The active company ID
 * @param {string} uid The user being added to the company
 */
export const doAddUser = (companyId, uid) => {
	let companyUserObject = Object.assign(
		{},
		ObjectState.generateActive(),
		Author.generateObject(auth.currentUser.uid)
	)

	//Here "doc(uid)" is called because
	//Explicitly we require the UID to be a doc name
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.set(companyUserObject, { merge: true })
}

export const getCompany = (activeCompanyId) =>
	fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.get()
