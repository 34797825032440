import { getFrom } from '../../../../../services/cloud/functions/generic'
import { store } from '../../../../../store'
import {
	getGlobalFilterValues,
	getGlobalPluginFilters
} from '../../../../../selectors/filter'
import { setGlobalFilterData } from '../../../../../actions/filter/data'
import { getUserActiveCompanyId } from '../../../../../selectors/user/company'
import { getActivePluginId } from '../../../../../selectors/plugin'
import { getDataSourceId } from '../../../../../firebase/company/plugin'

/**
 * [action-creator] for global filters formatting prior to querying
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} filterKey The filter key, to be used as reference
 * @param {string} dataSetId The data-set id to be used
 */
export const fetchDataFrom = async (filterKey, dataSet) => {
	let params = {},
		data = null

	//Defining params to be used
	const state = store.getState(),
		userActiveCompany = getUserActiveCompanyId(state),
		activePluginId = getActivePluginId(state),
		pluginFilters = getGlobalPluginFilters(state),
		filterValues = getGlobalFilterValues(state),
		//TODO: get filter values from plugin filter keys
		currentFilter = pluginFilters[filterKey],
		filterDependencies =
			!!currentFilter && !!currentFilter.dependsOn
				? currentFilter.dependsOn
				: []

	//Maps dependencies from info provided by store
	params = mapDependenciesToObject(
		filterDependencies,
		pluginFilters,
		filterValues
	)

	//Pulling the result from the request and putting on action.data
	//This will be dispatched to redux store

	await getDataSourceId(userActiveCompany, activePluginId)
		.then((dataSourceId) => {
			return getFrom(dataSourceId, dataSet, params)
		})
		.then((result) => {
			data = result.data
		})

	//TODO: Replace the data-set by filterKey
	return setGlobalFilterData(dataSet, data)
}

/**
 * Will map the filter dependencies to an object useful in queries,
 * useful for company-plugin-filter units, which has dataSets isolated
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} dependencies Collection of filter dependencies
 * @param {Object} filters Object of found filters at state
 * @param {Object} values Object with filter keys to seek info inside
 */
const mapDependenciesToObject = (dependencies, filters, values) => {
	let dependencyDataSets = []

	//For each dependency, applies an object property with values
	dependencies.forEach((dependency) => {
		//Getting dataSetName from pluginFilter
		// ? FIXME: has to work with filter names ?
		dependencyDataSets.push(filters[dependency].dataSet)
	})

	//Returns invoking the mapper itself
	return mapDependenciesToPlainObject(dependencyDataSets, values)
}

/**
 * Will map the data-set dependency names to an object useful in queries to [SourceEngine]
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} dependencyDataSets Collection of dependency datas-set names
 * @param {Object} values Object with filter keys to seek info inside
 */
export const mapDependenciesToPlainObject = (dependencyDataSets, values) => {
	let paramsObject = { key: null }
	

	//No dependencies at all, nothing to do
	if (!dependencyDataSets) return paramsObject

	//For each dependency, applies an object property with values
	dependencyDataSets.forEach((dependencyDataSet) => {
		//Getting dataSetName from pluginFilter
		// ? FIXME: has to work with filter names ?
		// let dependencyDataSetName = filters[dependency].dataSet

		//Creating the empty prop at params object
		//This should be null, when no values are set
		paramsObject[dependencyDataSet] = null

		//Values were selected and should be ported to the params object for sending on along
		//With the query
		if (
			!!values[dependencyDataSet] &&
			values[dependencyDataSet].length > 0
		) {
			//TODO: get from filter key,
			//TODO: now is getting from dataSet name-ref
			//Copies refs from collection to use on a query
			paramsObject[dependencyDataSet] = [...values[dependencyDataSet]]
		}
	})

	return paramsObject
}

//TODO: Check whether all were implemented
// /**
//  * [action-creator] for global filter data loading flag **setting**
//  *
//  * @description Created the action creator
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {string} dataSetId The data-set id to be rooted with the text
//  * @param {boolean} isLoading If the data is loading or not
//  */
// export const filterDataLoading = (dataSetId, isLoading) => ({
// 	type: SET_GLOBAL_FILTER_DATA_LOADING,
// 	dataSetId,
// 	isLoading
// })

// /**
//  * [action-creator] for global filter data **setting**
//  *
//  * @description Created the action creator
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {string} dataSetId The data-set id to be rooted with the text
//  * @param {Object|Array} data The data to be stored
//  */
// export const setGlobalFilterData = (dataSetId, data) => ({
// 	type: SET_GLOBAL_FILTER_DATA,
// 	dataSetId,
// 	data
// })

// /**
//  * [action-creator] for global search-text **setting**
//  *
//  * @description Created the action creator
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {string} dataSetId The data-set id to be rooted with the text
//  * @param {string} text The text to be stored
//  */
// export const setGlobalFilterSearchText = (dataSetId, text) => ({
// 	type: SET_GLOBAL_FILTER_SEARCH_TEXT,
// 	dataSetId,
// 	text
// })

// /**
//  * [action-creator] for global search-text **clearing**
//  *
//  * @description Created the action creator
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {string} dataSetId The data-set id to be rooted with the text
//  */
// export const clearGlobalFilterSearchText = (dataSetId) => ({
// 	type: CLEAR_GLOBAL_FILTER_SEARCH_TEXT,
// 	dataSetId
// })
