import { CLEAR_FORGOT_PASSWORD_STATE } from '../../../../actions/user/forgotPassword'

export const SET_FORGOT_PASSWORD_EMAIL = 'SET_FORGOT_PASSWORD_EMAIL',
	SET_FORGOT_PASSWORD_WAS_SENT = 'SET_FORGOT_PASSWORD_WAS_SENT',
	SET_FORGOT_PASSWORD_IS_LOADING = 'SET_FORGOT_PASSWORD_IS_LOADING',
	SET_FORGOT_PASSWORD_ERROR = 'SET_FORGOT_PASSWORD_ERROR'

const INITIAL_STATE = {
	email: '',
	wasSent: false,
	isLoading: false,
	error: null
}

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setEmail = (state, action) =>
	Object.assign({}, state, {
		email: action.email
	})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setError = (state, action) =>
	Object.assign({}, state, { error: action.error })

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setWasSent = (state, action) =>
	Object.assign({}, state, {
		wasSent: true
	})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setIsLoading = (state, action) =>
	Object.assign({}, state, {
		isLoading: action.isLoading
	})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const forgotPasswordReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_FORGOT_PASSWORD_EMAIL: {
			return setEmail(state, action)
		}
		case SET_FORGOT_PASSWORD_WAS_SENT: {
			return setWasSent(state, action)
		}
		case SET_FORGOT_PASSWORD_IS_LOADING: {
			return setIsLoading(state, action)
		}
		case CLEAR_FORGOT_PASSWORD_STATE: {
			return { ...state, ...INITIAL_STATE }
		}
		case SET_FORGOT_PASSWORD_ERROR: {
			return setError(state, action)
		}
		default:
			return state
	}
}

export default forgotPasswordReducer
