import {
	PRODUCTIVITY_LOSS_TOTALIZER_DATA_LOADED,
	PRODUCTIVITY_LOSS_DATA_LOADED,
	PRODUCTIVITY_LOSS_LOADING,
	PRODUCTIVITY_LOSS_SET_PERIOD,
	CLEAR_PRODUCTIVITY_LOSS_DATA,
	CLEAR_PRODUCTIVITY_LOSS_TOTALIZER_DATA,
} from '../../../actions/productivityLoss'

const INITIAL_STATE = {
	data: null,
	totalizerData: null,
	isLoading: false,
	dateConfig: {},
	ownFilters: {},
	searchText: {
		equipeTipo: '',
		nomeCiclo: '',
		localidade: '',
		cliente: '',
		processo: '',
		supervisor: '',
	},
}

/**
 * Sets a date period config at the state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state-portion which holds data for this reducer
 * @param {Object} action The action with "type" and the desired props
 */
const setPeriod = (state, action) => {
	return Object.assign({}, state, {
		dateConfig: {
			...action.dateConfig,
			selectedValues: Object.assign({}, state.dateConfig.selectedValues, {
				[action.formType]: action.value,
			}),
		},
	})
}

/**
 * Sets the loading state-portion related to productivity loss data
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state-portion which holds data for this reducer
 * @param {Object} action The action with "type" and the desired props
 */
const setLoading = (state, action) => {
	return Object.assign({}, state, { isLoading: action.isLoading })
}

/**
 * Sets data at the state-portion related to productivity loss context
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state-portion which holds data for this reducer
 * @param {Object} action The action with "type" and the desired props
 */
const dataLoaded = (state, action) => {
	return Object.assign({}, state, { data: action.data })
}

/**
 * Clears the data at the state-portion related to productivity loss context
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state-portion which holds data for this reducer
 * @param {Object} action The action with "type" and the desired props
 */
const clearData = (state, action) => {
	return Object.assign({}, state, {
		data: INITIAL_STATE.data,
	})
}

/**
 * Clears the totalizer respective data
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const clearTotalizerData = (state, action) =>
	Object.assign({}, state, {
		totalizerData: INITIAL_STATE.totalizerData,
	})

/**
 * Set the totalizer respective data when received from request
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const totalizerDataLoaded = (state, action) =>
	Object.assign({}, state, {
		totalizerData: action.totalizerData,
	})

/**
 * Manages the state-portion related to productivity loss context
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Implemented the actions for totalizer data
 * @author davispindola
 * @version 2.0
 *
 * @description Changed some constants' locations
 * @author brunoteixeirasilva
 * @version 2.1
 *
 * @description added filter values
 * @author jeffersonguilhermemachadobarreto
 * @version 2.2
 *
 * @param {Object} state The current state-portion which holds data for this reducer
 * @param {Object} action The action with "type" and the desired props
 */
export default function productivityLossReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case PRODUCTIVITY_LOSS_SET_PERIOD: {
			return setPeriod(state, action)
		}
		case PRODUCTIVITY_LOSS_LOADING: {
			return setLoading(state, action)
		}
		case CLEAR_PRODUCTIVITY_LOSS_DATA: {
			return clearData(state, action)
		}
		case PRODUCTIVITY_LOSS_DATA_LOADED: {
			return dataLoaded(state, action)
		}
		case PRODUCTIVITY_LOSS_TOTALIZER_DATA_LOADED: {
			return totalizerDataLoaded(state, action)
		}
		case CLEAR_PRODUCTIVITY_LOSS_TOTALIZER_DATA: {
			return clearTotalizerData(state, action)
		}
		case 'SET_PRODUCTIVITY_LOSS_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_PRODUCTIVITY_LOSS_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_PRODUCTIVITY_LOSS_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		default:
			return state
	}
}
