import padStart from './string.padStart'

/**
 * import all the functions that must be applied, added a object in array, and put the function on the function key
 * @description created the functions array
 * @author Davi Wegner
 * @version 1.0
 */
const functions = [{ function: padStart }]
export default functions
