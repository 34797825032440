import React from 'react'
import { connect } from 'react-redux'
import { IconButton, withStyles, Icon, Tooltip } from '@material-ui/core'
import { compose } from 'recompose'
import { styles } from './styles'
import { makeGetVisibleActionFilter } from '../../../../../selectors/action/filter'
import ActionFilterDialog from './ActionFilterDialog'
import { translate } from '../../../../../utils/lang'

const types = {
	0: 'tipo não definido',
	1: 'alerta',
	2: 'risco',
	3: 'elogio'
}

const classifications = {
	0: 'não classificada',
	1: 'Risco Alto',
	2: 'Risco Médio',
	3: 'Risco Baixo',
	4: 'Oportunidade de Melhora'
}
const activeInfo = {
	false: 'Fechada',
	true: 'Aberta'
}
/**
 *	Action filter dialog, and selects functions container
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 */
class ActionFilterContainer extends React.Component {
	handleCardChange = (event) => {
		this.props.setFilterValue({ cardId: event.target.value })
	}
	handleReceiverChange = (event) => {
		this.props.setFilterValue({ receiver_key: event.target.value })
	}
	handleAuthorChange = (event) => {
		this.props.setFilterValue({ author: event.target.value })
	}
	handleClassificationChange = (event) => {
		this.props.setFilterValue({
			content_classification: event.target.value
		})
	}
	handleTypeChange = (event) => {
		this.props.setFilterValue({
			content_type: event.target.value
		})
	}
	handleActiveChange = (event) => {
		this.props.setFilterValue({
			active: event.target.value
		})
	}
	handleDateChange = (event) => {
		this.props.setFilterValue({
			[event.target.name]: event.target.value
		})
	}
	handleActionFilterDialog = () => {
		this.props.handleActionFilterDialog(true)
	}
	handleDelete = (event) => {
		switch (event) {
			case translate('label/card'):
				this.props.setFilterValue({ cardId: [] })
				break
			case translate('label/receiver'):
				this.props.setFilterValue({ receiver_key: [] })
				break
			case translate('label/status'):
				this.props.setFilterValue({ active: [] })
				break
			case translate('label/classification'):
				this.props.setFilterValue({ content_classification: [] })
				break
			case translate('label/type'):
				this.props.setFilterValue({ content_type: [] })
				break
			case translate('label/author'):
				this.props.setFilterValue({ author: [] })
				break
			default:
				return false
		}
	}
	render() {
		const {
			cardInfo,
			selected_values,
			filters,
			classes,
			authorInfo,
			isOpen
		} = this.props

		return (
			<div className={classes.root}>
				{!!filters && isOpen && (
					<ActionFilterDialog
						isOpen={isOpen}
						handleState={this.props.handleActionFilterDialog}
						author={selected_values.author}
						cardId={selected_values.cardId}
						receiver_key={selected_values.receiver_key}
						content_classification={
							selected_values.content_classification
						}
						active={selected_values.active}
						content_type={selected_values.content_type}
						status={filters.active}
						receiver={filters.receiver}
						cardIds={filters.cardId}
						classification={filters.classification}
						type={filters.type}
						selectableAuthors={filters.author}
						handleCardChange={this.handleCardChange}
						handleReceiverChange={this.handleReceiverChange}
						handleAuthorChange={this.handleAuthorChange}
						handleActiveChange={this.handleActiveChange}
						handleClassificationChange={
							this.handleClassificationChange
						}
						handleDelete={this.handleDelete}
						handleDateChange={this.handleDateChange}
						handleTypeChange={this.handleTypeChange}
						authorInfo={authorInfo}
						cardInfo={cardInfo}
						activeInfo={activeInfo}
						typeInfo={types}
						classificationInfo={classifications}
						fromDate={selected_values.from}
						untilDate={selected_values.until}
					/>
				)}
				<Tooltip title={translate('label/filter')}>
					<IconButton
						aria-label="Filtros"
						onClick={this.handleActionFilterDialog}
					>
						<Icon style={{ color: 'white' }}>filter_list</Icon>
					</IconButton>
				</Tooltip>
			</div>
		)
	}
}

const makeMapStateToProps = () => {
	const getActionFilters = makeGetVisibleActionFilter()

	const mapStateToProps = (state, { actionListInfo }) => ({
		filters: getActionFilters(state, actionListInfo),
		cardInfo: state.actionsState.cardInfo,
		selected_values: state.actionFilterState.selectedValues,
		authorInfo: state.actionFilterState.authorInfo,
		isOpen: state.actionFilterState.isOpen,
		byAuthor: state.actionsStateByAuthor.byAuthor
	})
	return mapStateToProps
}
const mapDispatchToProps = (dispatch) => ({
	setFilterValue: (value) =>
		dispatch({ type: 'UPDATE_ACTION_FILTER_VALUES', value }),
	setFilterCard: (cardInfo) =>
		dispatch({ type: 'LOAD_ACTION_FILTER_PROPS', cardInfo }),
	handleActionFilterDialog: (value) =>
		dispatch({ type: 'HANDLE_ACTION_FILTER_DIALOG', value })
})
export default compose(
	withStyles(styles),
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(ActionFilterContainer)
