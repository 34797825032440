export const MANAGE_CHECK_BY_LOCATION_LOADING_STATE =
		'MANAGE_CHECK_BY_LOCATION_LOADING_STATE',
	LOAD_CHECK_BY_LOCATION_DATA = 'LOAD_CHECK_BY_LOCATION_DATA',
	SET_CHECK_BY_LOCATION_PERIOD = 'SET_CHECK_BY_LOCATION_PERIOD',
	LOAD_COVID_DATA_FILTERS = 'LOAD_COVID_DATA_FILTERS'

export const setCheckByLocationPeriod = (dateConfig, value, formType) => ({
	type: SET_CHECK_BY_LOCATION_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadCovidDataFilters = (mapCovidFilters) => ({
	type: LOAD_COVID_DATA_FILTERS,
	mapCovidFilters
})

export const loadCheckByLocationData = (autoCheck) => (
	{
	type: LOAD_CHECK_BY_LOCATION_DATA,
	autoCheck
})

export const setLoading = (isLoading) => ({
	type: MANAGE_CHECK_BY_LOCATION_LOADING_STATE,
	isLoading
})
