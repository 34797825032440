import {
	Button,
	Card,
	CardContent,
	FormControl,
	FormHelperText,
	Icon,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core'
import Axios from 'axios'
import React from 'react'
import { compose } from 'recompose'
import { fs } from '../../../firebase/firebase'
import { translate } from '../../../utils/lang'
import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'
import ResetLink from '../../reusable/account/forgotPassword/ResetLink'
import { CompanyLogo } from '../../reusable/image'
import Loader from '../../reusable/loader'

const INITIAL_CONFIG = {
	logo: {
		src: require('../../img/speedometer.svg'),
		alt: 'Arquia Dashboards Logo',
		title: translate('app/title'),
	},
}

/**
 * @author Ray
 * @email ray@arquia.com.br
 * @updated 09-10-2019
 */

/**
 * @author Lucas Lopes
 * @email lucaslopes116@hotmail.com
 * @updated 18-03-2022
 *
 * Ajuste na validacao do regex
 */
class ResetPassPage extends React.PureComponent {
	state = {
		validPass: false,
		pass: '',
		showPass: false,
		invalidToken: false,
		isLoading: false,
		status: false,
		done: false,
		...INITIAL_CONFIG,
	}
	componentDidMount() {
		this.checkToken()
	}

	checkToken = () => {
		const { match } = this.props
		fs.collection('resetPassToken')
			.doc(match.params.requireId)
			.get()
			.then(item => {
				this.setState({ ...item.data() })
			})
			.catch(() => {
				this.setState({ active: false, invalidToken: true })
			})
	}
	handleChange = event => {
		let regex = new RegExp(
			'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
		)

		if (regex.test(event.currentTarget.value)) {
			this.setState({ validPass: true })
		} else {
			this.setState({ validPass: false })
		}

		this.setState({ pass: event.currentTarget.value })
	}
	handleClickShowPassword = () => {
		this.setState({ showPass: !this.state.showPass })
	}
	goToLoginPage = () => {
		window.location.href = '#/signin'
	}

	handleSubmit = () => {
		const { match } = this.props
		const { pass } = this.state
		this.setState({ isLoading: true })
		fs.collection('resetPassToken')
			.doc(match.params.requireId)
			.update({ pass })
			.then(() =>
				Axios.post(
					'https://us-central1-agildash-app-db.cloudfunctions.net/neuralyzer/',
					{ tokenId: match.params.requireId },
				)
					.then(() => {
						this.setState({
							isLoading: false,
							done: true,
							status: true,
						})
					})
					.catch(err => {
						console.log(err)
						this.setState({
							isLoading: false,
							done: true,
							status: false,
						})
					}),
			)
	}
	render() {
		const {
			active,
			pass,
			showPass,
			validPass,
			invalidToken,
			isLoading,
			done,
			onboarding,
			status,
		} = this.state
		const { classes } = this.props
		const { logo } = this.state
		return (
			<div className={classes.root}>
				<div className={classes.form}>
					<Card>
						<CardContent className={classes.content}>
							<CompanyLogo {...logo} />
							<Typography
								variant="h6"
								align="center"
								style={{ color: 'rgba(84,192,235,1)' }}>
								{logo.title}
							</Typography>
							{done ? (
								<div>
									<Typography>
										{status
											? translate(
													'label/password-changed-successfully',
											  )
											: translate(
													'label/password-error-on-change',
											  )}
									</Typography>
									<Button
										variant="text"
										fullWidth
										onClick={this.goToLoginPage}
										style={{ color: 'rgba(103,58,183,1)' }}>
										{translate('label/go-back-to', {
											to: translate('label/sign-in'),
										})}
									</Button>
								</div>
							) : isLoading ? (
								<Loader internal />
							) : active || (!!onboarding && !invalidToken) ? (
								<FormControl required margin="normal">
									<InputLabel htmlFor="password">
										{translate(
											'label/type-in-the-new-password',
										)}
									</InputLabel>
									<Input
										id="password"
										type={showPass ? 'text' : 'password'}
										value={pass}
										onChange={this.handleChange}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={
														this
															.handleClickShowPassword
													}>
													<Tooltip
														title={translate(
															'label/change-show-password',
														)}
														placement="top">
														<Icon>
															{showPass
																? 'visibility'
																: 'visibility_off'}
														</Icon>
													</Tooltip>
												</IconButton>
											</InputAdornment>
										}
									/>
									<FormHelperText error={!validPass}>
										{translate(
											'validation/invalid-password',
											{ passwordMinimumLength: 8 },
										)}
									</FormHelperText>
									<Button
										disabled={!validPass}
										onClick={this.handleSubmit}>
										{translate('label/confirm')}
									</Button>
								</FormControl>
							) : (
								<card>
									<Typography
										style={{
											marginTop: 5,
											textAlign: 'center',
										}}>
										{translate('label/invalid-token')}
									</Typography>
									<div
										style={{
											marginTop: 5,
											textAlign: 'center',
										}}>
										<Typography>
											{translate(
												'label/invalid-token-message',
											)}
										</Typography>
									</div>
									<div
										className={classes.forgotPassword}
										style={{
											marginTop: 5,
											textAlign: 'center',
										}}>
										<ResetLink />
									</div>
								</card>
							)}
						</CardContent>
					</Card>
				</div>
			</div>
		)
	}
}

export default compose(
	// withRouter,
	withStyles(theme => ({
		root: {
			flexGrow: 1,
		},
		form: {
			marginTop: theme.spacing.unit * 2,
			[theme.breakpoints.up('xs')]: {
				height: '100%',
			},
			...getDesktopBreakpoint(theme, {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				width: '100%',
				height: '100vh',
				flex: '0 0 auto',
				marginTop: '0',
			}),
		},
		content: {
			paddingBottom: '16px !important',
		},
	})),
)(ResetPassPage)
