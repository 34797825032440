import { fs, auth } from '../firebase'
import * as Author from '../../utils/security/author'
import * as ObjectState from '../../utils/object/state'

/**
 * Queries for a [DEFAULT] permission specifically
 * ! Only useful for [DEFAULT/root] permissions
 *
 * @param {string} permissionId The permission being queried
 */
export const get = (permissionId) => {
	return fs
		.collection('permissions')
		.doc(permissionId)
		.get()
}

/**
 * Queries for all [DEFAULT][active] permissions specifically
 * ! Only useful for [DEFAULT/root] permissions
 *
 */
export const getAll = () => {
	return fs
		.collection('permissions')
		.where('active', '==', true)
		.get()
}

/**
 * Saves a new [DEFAULT/root] permission specifically
 * ! Only useful for [DEFAULT/root] permissions
 *
 */
export const doCreate = (data) => {
	const dataWithAuthor = Object.assign(
		{},
		Object.assign(
			{},
			ObjectState.generateActive(data.active),
			Author.generateObject(auth.currentUser.uid)
		),
		data
	)

	return fs
		.collection('permissions')
		.doc()
		.set(dataWithAuthor)
}

/**
 * Saves (UPDATE) a new [DEFAULT/root] permission specifically
 * ! Only useful for [DEFAULT/root] permissions
 *
 */
export const doUpdate = (permissionKey, data) => {
	const dataWithAuthor = Object.assign(
		{},
		data,
		Object.assign(
			{},
			ObjectState.generateActive(),
			Author.generateObject(auth.currentUser.uid)
		)
	)

	return fs
		.collection('permissions')
		.doc(permissionKey)
		.update(dataWithAuthor)
}

export const onGetSuperUserAcess = (authUser, thenFunc) => {
	fs.collection('groups')
		.where('isSuper', '==', true)
		.get()
		.then((groups) => {
			groups.forEach((group) => {
				fs.collection('groups')
					.doc(group.id)
					.collection('users')
					.onSnapshot((querySnapshot) => {
						querySnapshot.docChanges().forEach((userDoc) => {
							if (userDoc.doc.id === authUser) {
								switch (userDoc.type) {
									case 'added':
										thenFunc(true)
										break
									case 'removed':
										thenFunc(false)
										break
									default:
										thenFunc(true)
								}
							}
						})
					})
			})
		})
}
