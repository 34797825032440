import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withRouter } from 'react-router-dom'
import DashSet from '../DashSet'
import DashSetMobile from '../DashSetMobile'
import * as CompanyPlugin from '../../../../firebase/company/plugin'
import * as CompanyPluginFilters from '../../../../firebase/company/plugin/filters'
import * as DashSets from '../../../../firebase/dashSet'
import * as PluginDashSets from '../../../../firebase/plugin/dashSets'
import * as DashItemFunctions from '../../../../firebase/dashItem'
import * as routes from '../../../../constants/routes'
import { fs } from '../../../../firebase/firebase'
import { onSetGlobalPluginFilters } from '../../filter/functions/onSetGlobalPluginFilters'
import { setCompanyPlugin } from '../../../../actions/company/plugin'
import { makeGetActivePlugin, makeGetActivePluginId } from '../../../../selectors/plugin'
import { makeGetUserActiveCompanyId } from '../../../../selectors/user/company'
import { DASH_SETS_LOADED, CLEAR_DASH_SET, SET_DASH_SETS_IS_LOADING } from '../../../../reducers/dashSet'
import { DASH_ITEMS_LOADED } from '../../../../reducers/dashItem'
import AssistiveTouchButton from '../../../reusable/assistiveButton'
import withFilterButton from '../../withFilterButton'
import { SET_ACTIVE_DASH_ITEM, SET_ACTIVE_DASH_SET, HANDLE_DASH_ITEM_RELOAD } from '../../../../reducers/dashItem/view'
import withAuthentication from '../../withAuthentication'
import _ from 'lodash'
/**
 * The main app instance manager class
 *
 *  A conditional has been created to know which type of dashset has to be shown,
 *  one for desktop version another for mobile with swipe option between the cards.
 *
 * @description Created the component
 * @author lopes
 * @version 1.0
 *
 * @description Adjustment in filter explanded
 * @author jeffersonguilhermemachadobarreto
 * @version 1.2
 *
 * @description Implement method permissions on card visualization
 * @author jeffersonguilhermemachadobarreto
 * @version 1.3
 */
class DashSetContainer extends React.PureComponent {
	filterListener = null

	// state = {
	// 	userCrr: null
	// }

	componentDidMount() {
		const { dashSetsLoading, dashSets } = this.props

		// this.takeActivePluguins()

		//No dashsets loading yet and none at all
		if (!dashSetsLoading && !dashSets) {
			// this.loadData()
			this.loadDataDesktop()
		}
	}

	componentWillUnmount() {
		const { onClearDashSet } = this.props

		//Will clear dashSet on unmount
		onClearDashSet()

		//If the query listener was already created
		//Unsubscribe from it
		if (!!this.filterListener && this.filterListener instanceof Function) {
			this.filterListener()
		}
	}

	loadDataDesktop = () => {
		const {
			onClearDashSet,
			onDashItemsLoaded,
			onDashSetsLoaded,
			onCompanyPluginLoaded,
			activePluginId,
			activeCompanyId,
			onSetLoading,
			authUser,
			userCrr,
		} = this.props

		onSetLoading(true)
			.then(() => onClearDashSet())
			.then(() => CompanyPlugin.getById(activeCompanyId, activePluginId))
			.then(pluginObject => onCompanyPluginLoaded(activePluginId, pluginObject))
			.then(() => {
				this.filterListener = CompanyPluginFilters.onGet(
					activeCompanyId,
					activePluginId,
					filters => onSetGlobalPluginFilters(filters),
					error => {
						if (process.env.REACT_APP_NODE_ENV === 'development') {
							console.error(error)
						}
						throw error
					}
				)
				return Promise.resolve(this.filterListener)
			})
			.then(() => PluginDashSets.getByPlugin(activeCompanyId, activePluginId, authUser.uid))
			.then(async dataSnapshot => {
				if (!dataSnapshot.empty) {
					let dashSets = {},
						dashSetsPromises = []

					dataSnapshot.forEach(dashSet => {
						//Queries each dashset individially
						dashSetsPromises.push(DashSets.get(dashSet.id))
						//Will find all allowed dashItems
					})
					await Promise.all(dashSetsPromises).then(snapDashSets => {
						snapDashSets.forEach(snapDashSet => {
							dashSets = Object.assign({}, dashSets, {
								[snapDashSet.id]: snapDashSet.data(),
							})
						})
					})

					onDashSetsLoaded(dashSets)
					return dataSnapshot
				}

				return Promise.reject('empty-snapshot')
			})
			.then(dataSnapshot => {
				let dashSets = []

				dataSnapshot.forEach(async dashSet => {
					dashSets.push(
						DashItemFunctions.getByDashSet(dashSet.id).then(dashItems => {
							return {
								[dashSet.id]: dashItems.map(dashItem => dashItem.id),
							}
						})
					)
				})
				return Promise.all(dashSets).then(array => {
					return array.reduce((acc, crr) => {
						acc = { ...acc, ...crr }
						return acc
					}, {})
				})
			})
			.then(dashSetsWithDashItems => {
				let dashEverything = Object.keys(dashSetsWithDashItems).reduce((acc, crr) => {
					let dashSets = Promise.all(
						dashSetsWithDashItems[crr].map(dashItem =>
							DashItemFunctions.getActiveDashItem(dashItem, {
								dashItemId: dashItem,
								activeCompanyId,
								userCrr: userCrr,
								plugin: activePluginId,
								dashSet: crr,
							}).then(dashItemByUser => {
								if (!!dashItemByUser) {
									return fs
										.collection('dashItems')
										.doc(dashItemByUser.id)
										.get()
										.then(doc => {
											return {
												...doc.data(),
												...dashItemByUser.data(),
												id: dashItemByUser.id,
												favorite: dashItemByUser.data().favorite ?? false,
											}
										})
								}
								return
							})
						)
					).then(result => {
						return {
							[crr]: result.reduce((acc, crr) => {
								if (!!crr) {
									acc = { ...acc, [crr.id]: crr }
								}
								return acc
							}, {}),
						}
					})
					acc.push(dashSets)
					return acc
				}, [])
				return new Promise((resolve, reject) => {
					Promise.all(dashEverything).then(dashes => {
						let fullDashes = dashes.reduce((acc, crr) => {
							acc = { ...acc, ...crr }
							return acc
						}, {})
						resolve(fullDashes)
					})
				})
			})
			.then(fullDashes => {
				onDashItemsLoaded(fullDashes)
				onSetLoading(false)
			})
	}

	// loadData = async () => {
	// 	const {
	// 		history,
	// 		onClearDashSet,
	// 		onDashItemsLoaded,
	// 		onDashSetsLoaded,
	// 		onCompanyPluginLoaded,
	// 		activePluginId,
	// 		activeCompanyId,
	// 		onSetLoading,
	// 		authUser,
	// 		userCrr,
	// 	} = this.props
	// 	// const {
	// 	// 	userCrr
	// 	// } = this.state
	// 	const self = this

	// 	//Removes any previously set dashSets
	// 	// Gets all dashsets related to this plugin
	// 	onSetLoading(true)
	// 		.then(() => onClearDashSet())
	// 		.then(() => CompanyPlugin.getById(activeCompanyId, activePluginId))
	// 		//Dispatch it to its reducer
	// 		.then(pluginObject =>
	// 			onCompanyPluginLoaded(activePluginId, pluginObject),
	// 		)
	// 		.then(() => {
	// 			//Gets all plugin-wide-global filters
	// 			//Configuration set, useful for each
	// 			//Dashitem self-querying
	// 			this.filterListener = CompanyPluginFilters.onGet(
	// 				activeCompanyId,
	// 				activePluginId,
	// 				filters => onSetGlobalPluginFilters(filters),
	// 				error => {
	// 					if (
	// 						process.env.REACT_APP_NODE_ENV.trim() ===
	// 						'development'
	// 					) {
	// 						console.error(error)
	// 					}
	// 					throw error
	// 				},
	// 			)

	// 			return Promise.resolve(this.filterListener)
	// 		})
	// 		.then(filterListener =>
	// 			PluginDashSets.getByPlugin(
	// 				activeCompanyId,
	// 				activePluginId,
	// 				authUser.uid,
	// 			),
	// 		)
	// 		.then(dataSnapshot => {
	// 			//Keys are present!
	// 			if (!dataSnapshot.empty) {
	// 				return dataSnapshot
	// 			}

	// 			//Will reject, stop loading if not config was found
	// 			return Promise.reject('empty-snapshot')
	// 		})
	// 		.then(async dataSnapshot => {
	// 			let dashSets = {},
	// 				dashSetsPromises = []

	// 			dataSnapshot.forEach(dashSet => {
	// 				//Queries each dashset individially
	// 				dashSetsPromises.push(DashSets.get(dashSet.id))
	// 				//Will find all allowed dashItems
	// 			})
	// 			await Promise.all(dashSetsPromises).then(snapDashSets => {
	// 				snapDashSets.forEach(snapDashSet => {
	// 					dashSets = Object.assign({}, dashSets, {
	// 						[snapDashSet.id]: snapDashSet.data(),
	// 					})
	// 				})
	// 			})

	// 			onDashSetsLoaded(dashSets)
	// 			return dataSnapshot
	// 			// return Promise.resolve('done')
	// 		})
	// 		.then(dataSnapshot => {
	// 			return new Promise((resolve, reject) => {
	// 				let dashItems = {}
	// 				dataSnapshot.forEach(async dashSet => {
	// 					let dashItemPromises = []

	// 					await DashItemFunctions.getByDashSet(dashSet.id).then(
	// 						async snapDashItem => {
	// 							snapDashItem.forEach(dashItem => {
	// 								//TODO: check whether we need dashItem info here!
	// 								dashItemPromises.push(
	// 									DashItemFunctions.getActiveDashItem(
	// 										dashItem.id,
	// 										{
	// 											dashItemId: dashItem.id,
	// 											activeCompanyId,
	// 											userCrr: userCrr,
	// 											plugin: activePluginId,
	// 											dashSet: dashSet.id,
	// 										},
	// 									),
	// 								)
	// 							})
	// 							await Promise.all(dashItemPromises)
	// 								.then(dashItemsSnap => {
	// 									dashItemsSnap.forEach(dashItemSnap => {
	// 										if (!!dashItemSnap) {
	// 											let dashItem =
	// 												dashItemSnap.data()

	// 											if (!dashItem.active) return
	// 											else
	// 												dashItems = Object.assign(
	// 													{},
	// 													dashItems,
	// 													{
	// 														[dashSet.id]:
	// 															Object.assign(
	// 																{},
	// 																dashItems[
	// 																	dashSet
	// 																		.id
	// 																],
	// 																{
	// 																	[dashItemSnap.id]:
	// 																		dashItem,
	// 																},
	// 															),
	// 													},
	// 												)
	// 										}
	// 									})
	// 								})
	// 								.catch(err => {
	// 									reject(err)
	// 								})
	// 						},
	// 					)

	// 					onDashItemsLoaded(dashItems)
	// 				})

	// 				resolve()
	// 			})
	// 		})
	// 		.then(() => onSetLoading(false))
	// 		.catch(error => {
	// 			onSetLoading(false)

	// 			//Treating possibilities
	// 			switch (error) {
	// 				//When the company has changed, but the plugin couldn't be found
	// 				//At it. Redirects the user to the main/default page
	// 				case 'company-plugin-not-found': {
	// 					return history.push(routes.PLUGIN)
	// 				}
	// 				case 'empty-snapshot': {
	// 					return false
	// 				}
	// 				default:
	// 					throw error
	// 			}
	// 		})

	// 	// /**
	// 	//  * Requests the collection of dashitem configuration
	// 	//  */
	// 	// dashItem.onGet(async (dataSnapshot) => {
	// 	// 	await dashItemsLoaded(dataSnapshot.val())
	// 	// })
	// }
	componentDidUpdate(prevProps) {
		const { history } = this.props

		if (prevProps.activePluginId !== this.props.activePluginId) {
			// this.loadData()
			this.loadDataDesktop()
		}

		if (prevProps.activeCompanyId !== this.props.activeCompanyId) {
			history.push(routes.PLUGIN)
		}
	}

	render() {
		const { width, activePlugin, ...otherProps } = this.props

		return (
			<React.Fragment>
				{isWidthUp('sm', width) ? (
					<DashSet activePlugin={activePlugin[otherProps.activePluginId]} {...otherProps} />
				) : (
					<AssistiveTouchButton>
						<DashSetMobile activePlugin={activePlugin[otherProps.activePluginId]} {...otherProps} {...this.props} />
					</AssistiveTouchButton>
				)}
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	onClearDashSet: async () => dispatch({ type: CLEAR_DASH_SET }),
	onSetLoading: async isLoading => dispatch({ type: SET_DASH_SETS_IS_LOADING, isLoading }),
	onDashSetsLoaded: async dashSets => dispatch({ type: DASH_SETS_LOADED, dashSets }),
	onDashItemsLoaded: async dashItems => dispatch({ type: DASH_ITEMS_LOADED, dashItems }),
	onCompanyPluginLoaded: async (pluginId, plugin) => dispatch(setCompanyPlugin(pluginId, plugin)),
	onSwitchDashItem: (dashSetKey, activeDashItem) => dispatch({ type: SET_ACTIVE_DASH_ITEM, dashSetKey, activeDashItem }),
	onSwitchDashSet: activeDashSet => dispatch({ type: SET_ACTIVE_DASH_SET, activeDashSet }),
	onSetComponentRemount: (dashItemKey, value) => dispatch({ type: HANDLE_DASH_ITEM_RELOAD, dashItemKey, value }),
})

const makeMapStateToProps = () => {
	const getActivePlugin = makeGetActivePlugin(),
		getActivePluginId = makeGetActivePluginId(),
		getActiveCompany = makeGetUserActiveCompanyId(),
		getDashItems = (dashItems, width) => {
			if (window.cordova) {
				return dashItems
			}
			if (!!dashItems) {
				let collection = Object.keys(dashItems).reduce((acc, item) => {
					acc = [
						...acc,
						...Object.keys(dashItems[item]).map(dashItemId => {
							return {
								dashItemId,
								...dashItems[item][dashItemId],
								dashSet: item,
							}
						}),
					]
					return acc
				}, [])

				collection = _.orderBy(collection, 'favorite', 'desc')

				return collection
			}
		}

	const mapStateToProps = (state, props) => {
		const dashSets = state.dashSetState.dashSets,
			dashSetKeys = state.dashSetState.isLoading ? null : !!dashSets ? Object.keys(dashSets) : null,
			dashItems = state.dashItemState.dashItems,
			dashItemByDashSetKeys = state.dashSetState.isLoading ? null : !!dashItems ? Object.keys(dashItems) : [],
			activeDashSet = state.dashItemViewState.activeDashSet,
			activeDashItem = state.dashItemViewState.activeDashItem

		return {
			dashSets: dashSets,
			dashSetKeys: dashSetKeys,
			dashSetsLoading: state.dashSetState.isLoading,
			dashItemsLoading: state.dashItemState.isLoading,
			dashItems: getDashItems(dashItems, props.width),
			language: state.accountConfigState.language,
			dashItemByDashSetKeys: dashItemByDashSetKeys,
			steps: state.dashItemStepState,
			userState: state.userState,
			activeCompanyId: getActiveCompany(state),
			activePlugin: getActivePlugin(state),
			activePluginId: getActivePluginId(state),
			remount: state.dashItemViewState.remount,
			showSwipe: state.dashItemViewState.showSwipe,
			activeDashSet: activeDashSet,
			activeDashItem: !!activeDashSet && !!activeDashItem ? activeDashItem[activeDashSet] : null,
		}
	}

	return mapStateToProps
}

export default compose(
	withRouter,
	withWidth(),
	connect(makeMapStateToProps(), mapDispatchToProps),
	withAuthentication,
	withFilterButton
)(DashSetContainer)
