import { store } from '../../../../store'
import { setGlobalPluginFilters } from '../../../../actions/filter/global'

/**
 * Processes global filters loading state
 *
 * @description Created the shared function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {firebase.firestore.QuerySnapshot} filters The resultant query documents
 */
export const onSetGlobalPluginFilters = (filters) => {
	//Builds the function which manages filter engagement
	const onCompanyPluginFiltersLoaded = async (filtersByPlugin) =>
		store.dispatch(setGlobalPluginFilters(filtersByPlugin))

	//Receives the plugin filters-set
	return onCompanyPluginFiltersLoaded(
		filters.reduce(
			(result, currentDoc) => ({
				...result,
				[currentDoc.id]: currentDoc.data()
			}),
			{}
		)
	)
}
