const INITIAL_STATE = {
	selectedFilters: null,
	selectableFilters: null,
	saving: false
}

/**
 * @description Manages permission filters form state
 * @author Davi Wegner
 * @param {Object} state
 * @param {Object} action
 */
function permissionFiltersFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_PERMISSION_FILTERS_FORM_FILTERS': {
			return Object.assign({}, state, {
				selectedFilters: action.selectedFilters
			})
		}
		case 'PERMISSION_FILTERS_FORM_SELECTABLE_FILTERS_LOADED': {
			return Object.assign({}, state, {
				selectableFilters: Object.assign(
					{},
					state.selectableFilters,
					action.selectableFilters
				)
			})
		}
		case 'ADD_PERMISSION_FILTER': {
			return Object.assign({}, state, {
				selectedFilters: Object.assign(
					{},
					state.selectedFilters,
					action.selectedFilters
				)
			})
		}
		case 'SET_PERMISSION_FILTERS_FORM_SAVING': {
			return Object.assign({}, state, { saving: action.saving })
		}
		default:
			return state
	}
}

export default permissionFiltersFormReducer
