export const MANAGE_PAINEL_CHECKLIST_LOADING_STATE =
		'MANAGE_PAINEL_CHECKLIST_LOADING_STATE',
	LOAD_PAINEL_CHECKLIST_DATA = 'LOAD_PAINEL_CHECKLIST_DATA',
	SET_PAINEL_CHECKLIST_PERIOD = 'SET_PAINEL_CHECKLIST_PERIOD',
	LOAD_PAINEL_CHECKLIST_DATA_FILTERS = 'LOAD_PAINEL_CHECKLIST_DATA_FILTERS'

export const setPainelChecklistPeriod = (dateConfig, value, formType) => ({
	type: SET_PAINEL_CHECKLIST_PERIOD,
	dateConfig,
	value,
	formType,
})

export const loadPainelChecklistData = painelChecklist => ({
	type: LOAD_PAINEL_CHECKLIST_DATA,
	painelChecklist,
})

export const setLoading = isLoading => ({
	type: MANAGE_PAINEL_CHECKLIST_LOADING_STATE,
	isLoading,
})

export const loadPainelChecklistDataFilters = painelChecklistFilters => ({
	type: LOAD_PAINEL_CHECKLIST_DATA_FILTERS,
	painelChecklistFilters,
})
