import { createSelector } from 'reselect'

/**
 * selector for sorting notifications by date
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 */
const makeNotificationSorter = () => {
	return createSelector(
		[getNotifications],
		(notifications) => {
			if (!notifications) return null
			let sortedNotifications
			sortedNotifications = Object.keys(notifications).sort(
				(notificationA, notificationB) => {
					//see array native sort function
					// if return is less than 0, sort notificationA to an index lower than notificationB
					//(in this case, if notificationA is newer than b in date.seconds returns -1 else returns 1)
					if (
						notifications[notificationA].date.seconds >
						notifications[notificationB].date.seconds
					) {
						return -1
					} else {
						return 1
					}
				}
			)
			return sortedNotifications
		}
	)
}

const getNotifications = (state) => state.notificationState.notifications

export { makeNotificationSorter }
