export const PICK_SELECTED_FILTER_ITEM = 'PICK_SELECTED_FILTER_ITEM'

/**
 * Picks the current selected filter-item and maps to the values list
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @description Changed function location
 * @author brunoteixeirasilva
 * @version 2.0
 *
 * @param {Object} dataSetId The data-set name/ID
 * @param {Array} filterKey The current filter-key for redux-saga watcher
 * @param {Array} filterData The data to apply at the filter entry
 */
export const pickSelectedFilterItem = (dataSetId, filterKey, filterData) => ({
	type: PICK_SELECTED_FILTER_ITEM,
	filterKey: filterKey,
	key: dataSetId,
	value: filterData
})
