import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withAuthentication from '../../../reusable/withAuthentication'
import { fs } from '../../../../firebase/firebase'
import {
	SET_PORTAL_DOWNLOAD_LOADING_STATE,
	SET_PORTAL_DOWNLOAD_STATE,
	CLEAN_PORTAL_DOWNLOAD_DATA,
	SET_ISREADY_STATE
} from '../../../../reducers/portalDownload'
import {
	makeGetUserActiveCompany,
	makeGetUserActiveCompanyId,
} from '../../../../selectors/user/company'
import {
	withStyles,
	TextField,
	Typography
} from '@material-ui/core'
import { format, compareAsc }  from 'date-fns'
import CsvDownload from 'react-json-to-csv'
import classNames from "classnames";
import { getDesktopBreakpoint } from '../../../../utils/object/theme/mobile'
import { addDownloadLogs } from '../../../../firebase/log'

class PortalDownload extends React.Component {
	state = {
		userAtualId: null,
		userAtual: null,
		actions: [],
		ids: [],
		dtInicio: format(new Date(),'yyyy-LL-dd'),
		dtFinal: format(new Date(),'yyyy-LL-dd'),	
	
	}

	componentDidMount() {
		const { authUser } = this.props

		this.getActions()
	

		this.setState({
			userAtualId: authUser.uid,
			userAtual: authUser.displayName,
		})
	}

	componentDidUpdate(prevProps, prevState) {	
		const { dtFinal, dtInicio }	= this.state		

		if(prevState.dtFinal !== dtFinal || prevState.dtInicio !== dtInicio){		
			
			this.getActions()

		}
	}

	createDownloadLog = (fileName) => {
		const { activeCompanyId, authUser } = this.props
		
		if (fileName) {
			try {
				addDownloadLogs(fileName, authUser.uid, activeCompanyId)
			} catch (e) {
				console.error('Cant create download log message ', e)
			}
		}	
	
	}

	formatDate = (date) => {
		let data
		let dia
		let mes
		let ano		
		let dataFinal
		let aux

		if(date){
			 data = date.split(' ')[0]
			 aux = data.split('/')
			 dia = aux[0] < 10 ? `0${aux[0]}` : aux[0]
			 mes = aux[1] < 10 ? `0${aux[1]}` : aux[1]
			 ano = [aux[2]]
			 dataFinal = `${ano}-${mes}-${dia}`

		}

		return dataFinal

	}

	getActions  = () => {
		const { activeCompanyId } = this.props
		const { dtInicio, dtFinal } = this.state
		let date,
		ids = [],
		actions = [],
		instalacao,
		instalacao_lat,
		instalacao_lng,
		prioridade,
		report,
		segmento,
		email,
		observation,	
		dateCloseInspection,
		dateinspection_lat,
		dateinspection_lng,
		date_initial,
		detalhes,
		resultado,
		apointment

		fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('actions')
		.onSnapshot((snapshot) => {
			if (snapshot.empty) {
				console.log('No matching documents.')
				return
			}	
			snapshot.forEach((doc) => {
				
					date_initial = doc.data().datasInspection ? doc.data().datasInspection.date : null
					date = this.formatDate(doc.data().dateCloseInspection)
					instalacao = doc.data().datasInspection ? doc.data().datasInspection.instalation : null
					instalacao_lat = doc.data().datasInspection ? doc.data().datasInspection.position : null
					instalacao_lng = doc.data().datasInspection ? doc.data().datasInspection.position : null
					prioridade = doc.data().datasInspection ? doc.data().datasInspection.priority	: null
					report = doc.data().datasInspection ? doc.data().datasInspection.report : null
					segmento = doc.data().datasInspection ? doc.data().datasInspection.segment : null
					email = doc.data().datasInspection ? doc.data().datasInspection.email : null
					observation = doc.data().datasInspection ? doc.data().datasInspection.observation : null
					dateCloseInspection = doc.data().dateCloseInspection
					dateinspection_lat = doc.data().coords ? doc.data().coords.latitude : null
					dateinspection_lng = doc.data().coords ? doc.data().coords.longitude : null
					detalhes = doc.data().resultContent ? doc.data().resultContent.Detalhes.label : null
					resultado = doc.data().resultContent ? doc.data().resultContent.resultado.label : null
					apointment = doc.data().datasInspection ? doc.data().datasInspection.apointment	: null


					if(date && date >= dtInicio && date <= dtFinal){

						if(!ids.includes(doc.id)){
							ids.push(doc.id)
							actions.push({							
								'instalacao': instalacao,
								'apontamento': apointment,
								'dt_da_analise': date_initial,
								'instalacao_lat': instalacao_lat ? instalacao_lat[0] : null,
								'instalacao_lng': instalacao_lat ? instalacao_lng[1] : null,
								'prioridade': prioridade,
								'laudo': report,
								'observation': observation,
								'segmento': segmento,
								'usuario_inspecao': email,
								'resultado': resultado,
								'detalhes': detalhes,
								'dateCloseInspection': dateCloseInspection,
								'dateinspection_lat': dateinspection_lat,
								'dateinspection_lng': dateinspection_lng,

							})
							
						}
					}					
					return this.setState({
						actions,
						ids,								
				})
			})
		
		},
		(err) => {
			console.log('Error getting documents', err)
		})
	}

	handleChangeInicio = (e) => {
		const { dtInicio, dtFinal} = this.state

		this.setState({
			dtInicio: e.target.value
		})

		this.compareDate(dtInicio,dtFinal)

	}

	handleChangeFim = (e) => {
		const { dtInicio, dtFinal} = this.state	

		this.setState({
			dtFinal: e.target.value,			
		})

		this.compareDate(dtInicio,dtFinal)
		
	}

	compareDate = (inicio, fim) => {
		const { onSetIsReadyState } = this.props

		let result = compareAsc(new Date(inicio),new Date(fim))		
	
		onSetIsReadyState(result)
	}	

	render() {
		const { dtInicio, dtFinal, actions } = this.state
		const { classes, isReady } = this.props
	
	

		return( 
			<div className="container">
				<form  noValidate>
					<div className={classNames(classes.containerIntoForm)}>
						<div className={classes.containerTitle}>
							<Typography variant="h6">Download de registros de plano de trabalho</Typography>
						</div>
						<div className={classes.containerAllFields}>
							<div className={classes.containerTextField}>
								<TextField
									id="dtInicio"
									label="Inicio"
									type="date"
									defaultValue={dtInicio}
									className={classes.dtInicioField}
									onChange={this.handleChangeInicio}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<TextField
									id="dtFinal"
									label="Final"
									type="date"						
									defaultValue={dtFinal}
									className={classes.textField}
									onChange={this.handleChangeFim}
									InputLabelProps={{
										shrink: true,
									}}						
								/>
							</div>
							<div className={classes.btnContainer}>						
								{actions && actions.length !== 0 ?
									<CsvDownload							
										data={actions}
										filename={`${format(new Date(),'dd_LL_yyyy')}_Plano_de_Trabalho.csv`}
										style={{
											boxShadow:"0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",							
											backgroundColor:"#673ab7",
											borderRadius:"10px",
											border:"1px solid #673ab7",
											display:"inline-block",
											cursor:"pointer","color":"#ffffff",
											fontSize:"0.875rem",
											fontWeight:"500",
											padding:"10px",
											textTransform:"uppercase",
											boxSizing: "border-box",
											lineHeight: "1.75"			
											}}
										onClick={() => {
											let fileName = `${format(new Date(),'dd_LL_yyyy')}_Plano_de_Trabalho.csv`
											this.createDownloadLog(fileName)
											console.log("You click the link"); // 👍🏻 Your click handling logic
										}}
									>
											Download
									</CsvDownload>
								:					
									<Typography style={{color: 'red'}} variant= "subtitle2">Não há registros nesse período</Typography>
								}
						</div>
					</div>
								{!!isReady && isReady > 0 && <Typography style={{color: 'red'}} variant= "body2">Data inválida</Typography>}
					</div>					
				</form>				
				</div>
		)
	}
}

const makeMapperStateToProps = () => {
	const	getActiveCompanyId = makeGetUserActiveCompanyId(),
		getActiveCompany = makeGetUserActiveCompany()
	const mapStateToProps = (state, ownProps) => ({
		isLoading: state.portalDownloadState.isLoading,
		isReady: state.portalDownloadState.isReady,
		activeCompany: getActiveCompany(state),
		activeCompanyId: getActiveCompanyId(state),	
	})
	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	onSetLoading: async (isLoading) =>
		dispatch({ type: SET_PORTAL_DOWNLOAD_LOADING_STATE, isLoading }),	
	onSetDownloadState: (downloadState) =>
		dispatch({ type: SET_PORTAL_DOWNLOAD_STATE, downloadState }),
	onSetIsReadyState: (isReady) =>
		dispatch({ type: SET_ISREADY_STATE, isReady }),	
	onCleanPortalData: () => dispatch({ type: CLEAN_PORTAL_DOWNLOAD_DATA }),
})

const styles = (theme) => ({

	containerAllFields:{		
		flexDirection:'column',
		alignItems:'center',
		...getDesktopBreakpoint(theme, {
			display: 'flex',
			width: '100%',
			alignItems:'center',
			flexDirection:'row'
		}),
	},	

	containerTitle: {
		display: 'flex',
		justifyContent:'flex-start',
		width:'100%'
	},

	containerTextField: {
		display: 'flex',
		justifyContent:'flex-start',
		marginTop: '10px',		
		...getDesktopBreakpoint(theme, {
			display: 'flex',
			justifyContent:'flex-start',
			marginTop: '10px',
			marginRight:'10px'
		}),	
	},

	containerIntoForm: {	
		display: 'flex',
		flexDirection: 'column',
		margin: "10px",		
		boxShadow:
			'0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
		backgroundColor: '#FFF',		
		padding: '10px',
		borderRadius: '10px',	
		...getDesktopBreakpoint(theme, {
			display: 'flex',		
			boxShadow:
				'0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
			backgroundColor: '#FFF',
			margin: '10px',
			padding: '10px',
			borderRadius: '10px',
			alignItems: 'center',
			flexDirection: 'column',
			justifyContent:'center',
			width:'600px'
		}),
	},

	btnContainer:{
		display: 'flex',
		justifyContent: 'center',
		width: '100%'
	},

	btnDownload:{
		margin: '10px',
		padding: '10px'
	},
	
	dtInicioField:{
		marginBottom: '5px',
		marginRight: '5px'
	}
})

export default compose(withStyles(styles),
withRouter,
withAuthentication,
connect(makeMapperStateToProps,
	mapDispatchToProps))
	(PortalDownload);
