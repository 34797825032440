export const MANAGE_CARDIFP_LOADING_STATE = 'MANAGE_CARDIFP_LOADING_STATE',
    LOAD_CARDIFP_DATA = 'LOAD_CARDIFP_DATA',
    SET_CARDIFP_PERIOD = 'SET_CARDIFP_PERIOD'

export const setDefaultPeriod = (dateConfig, value, formType) => ({
    type: SET_CARDIFP_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadDefaultData = (data) => ({
    type: LOAD_CARDIFP_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_CARDIFP_LOADING_STATE,
    isLoading
})