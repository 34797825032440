const SET_EVALUATION_DIALOG_OPEN = 'SET_EVALUATION_DIALOG_OPEN'
const SET_EVALUATION_DIALOG_CLOSE = 'SET_EVALUATION_DIALOG_CLOSE'

const INITIAL_STATE = {
	isOpen: false
}

export const setEvaluationDialogOpen = () => ({
	type: SET_EVALUATION_DIALOG_OPEN
})

export const setEvaluationDialogClose = () => ({
	type: SET_EVALUATION_DIALOG_CLOSE
})

const evaluationDialogReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_EVALUATION_DIALOG_OPEN:
			return Object.assign({}, state, {
				isOpen: true
			})
		case SET_EVALUATION_DIALOG_CLOSE:
			return Object.assign({}, state, {
				isOpen: INITIAL_STATE.isOpen
			})
		default:
			return state
	}
}

export default evaluationDialogReducer
