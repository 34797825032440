export const MANAGE_TOP_NC_RANKING_LOADING_STATE = 'MANAGE_TOP_NC_RANKING_LOADING_STATE',
    LOAD_TOP_NC_RANKING_DATA = 'LOAD_TOP_NC_RANKING_DATA',
    SET_TOP_NC_RANKING_PERIOD = 'SET_TOP_NC_RANKING_PERIOD'

export const setTopNcRankingPeriod = (dateConfig, value, formType) => ({
    type: SET_TOP_NC_RANKING_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadTopNcRankingData = (data) => ({
    type: LOAD_TOP_NC_RANKING_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_TOP_NC_RANKING_LOADING_STATE,
    isLoading
})
