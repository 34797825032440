import React from 'react'
import { connect } from 'react-redux'
import { firebase } from '../../../firebase'
import { AUTH_USER_SET } from '../../../reducers/session'

/**
 * This HOC will make the prop "authUser" available at the wrapped component.
 * Will make the prop available as soon as the auth state was checked.
 *
 * @description
 * @author brunoteixeirasilva
 * @
 *
 * @param {React.Component} Component The component which will have the prop injected
 */
const withAuthentication = Component => {
	class WithAuthentication extends React.Component {
		componentDidMount() {
			// May check auth state and populate the prop
			// Or leave it null
			// console.log('GOtIN')
			this.checkAuth()
		}

		checkAuth() {
			const { notifyOnAuthUserSet } = this.props

			firebase.auth.onAuthStateChanged(authUser => {
				if (authUser) {
					// console.log('Teste')
					notifyOnAuthUserSet(authUser)
				} else {
					// console.log(authUser)
					notifyOnAuthUserSet(null)
				}
			})
		}

		render() {
			return <Component {...this.props} />
		}
	}

	/**
	 * Redux state mappers
	 *
	 * @param {Object} state The Redux state
	 */
	const mapStateToProps = state => ({
		authUser: state.sessionState.authUser,
	})

	/**
	 * Redux dispatchers
	 *
	 * @param {Object} dispatch The Redux dispatch
	 */
	const mapDispatchToProps = dispatch => ({
		notifyOnAuthUserSet: authUser =>
			dispatch({ type: AUTH_USER_SET, authUser }),
	})

	return connect(mapStateToProps, mapDispatchToProps)(WithAuthentication)
}

export default withAuthentication
