import React from 'react'
import * as routes from '../../../constants/routes'
import { CompanyLogo } from '../../reusable/image'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styles from './styles'
import {
	Card,
	CardHeader,
	CardContent,
	withStyles,
	Typography,
} from '@material-ui/core'

import Loader from '../../reusable/loader'
import EmailSent from '../../reusable/notification/email/EmailSent'
import ForgotPasswordContainer from '../../reusable/account/forgotPassword/ForgotPasswordContainer'
import { translate } from '../../../utils/lang'
import { clearForgotPasswordData } from '../../../actions/user/forgotPassword'

const INITIAL_CONFIG = {
	logo: {
		src: require('../../img/speedometer.svg'),
		alt: 'Arquia Dashboards Logo',
		title: translate('app/title'),
	},
}

/**
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Refactored the component
 * @author davispindola
 * @version 2.0
 */
class PasswordResetPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_CONFIG }
	}

	componentWillUnmount() {
		const { clearState } = this.props
		clearState()
	}

	handleClick = (event) => {
		const { history } = this.props

		history.push(routes.SIGN_IN)
	}

	render() {
		const { wasSent, isLoading, classes } = this.props
		const { logo } = this.state
		return (
			<div className={classes.root}>
				<div className={classes.form}>
					<Card>
						<CompanyLogo {...logo} />
						<Typography
							variant="h6"
							align="center"
							style={{ color: 'rgba(84,192,235,1)' }}>
							{logo.title}
						</Typography>
						<CardHeader
							title={translate('title/password-assistant')}
							subheader={translate(
								'label/set-email-for-password-reset',
							)}
						/>
						<CardContent>
							{!wasSent && !isLoading && (
								<ForgotPasswordContainer />
							)}
							{isLoading && <Loader internal />}
							{!!wasSent && !isLoading && (
								<EmailSent handleClick={this.handleClick} />
							)}
						</CardContent>
					</Card>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	wasSent: state.forgotPasswordState.wasSent,
	isLoading: state.forgotPasswordState.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
	clearState: () => dispatch(clearForgotPasswordData()),
})

export default compose(
	// withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
)(PasswordResetPage)
