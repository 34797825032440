import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AccountPage from '../component/app/account/AccountPage'
import { ControlPanelUser } from '../component/app/controlPanelUser'
import CreateUser from '../component/app/createUser/CreateUser'
import FormCreateUser from '../component/app/createUser/FormCreateUser'
import PrivacyPolicyPage from '../component/app/docs/PrivacyPolicyPage'
import LandingPage from '../component/app/landing'
import LogsPage from '../component/app/logs'
import PluginPage from '../component/app/plugin/PluginPage'
import Portal from '../component/app/portal'
import PortalDownload from '../component/app/portal/download'
import PortalDevelop from '../component/app/portal/upload-develop'
import TeamListPage from '../component/app/team/list'
import TestPage from '../component/app/test/TestPage'
import AboutArquia from '../component/reusable/about'
import ErrorPage from '../component/reusable/error'
import StepContainer from '../component/reusable/step'
import { firebase } from '../firebase'
import { analyticsEvents } from '../firebase/analytics'
import { analytics, fs } from '../firebase/firebase'
import DashboardPage from './../component/app/dashboard'
import UserActionsPage from './../component/app/userActions/index'
import UserNotificationsContainer from './../component/app/userNotifications/index'
import * as routes from './routes'
/**
 * Manages to deliver specific containers, based on the route.
 * ? This component treats first-level routes, e.g. /any-route (or) /dashboard.
 * ! Any second-level route should be managed by its own context.
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Implement conditions to admin visualization and normal user
 * @author jeffersonguilhermemachadobarreto
 * @version 1.1
 */
class Pages extends React.Component {
	state = {
		userIsAdmin: false,
		pagesValidate: null,
		userCurrent: null,
		activeLog: false,
		activeActions: false,
		activeDownload: false,
		activeUpload: false,
		activeNotification: false,
		ticket: null,
		location: null,
		pages: [
			{
				isExact: true,
				route: routes.LANDING,
				component: <LandingPage />,
			},
			{
				isExact: true,
				route: routes.DASHBOARD,
				component: <DashboardPage />,
			},
			{
				isExact: true,
				route: routes.PRIVACY_POLICY,
				component: <PrivacyPolicyPage />,
			},
			{
				isExact: true,
				route: routes.ABOUT_ARQUIA,
				type: 'about',
				component: <AboutArquia />,
			},
			{
				isExact: true,
				type: 'log',
				route: '/logs',
				component: <LogsPage />,
			},
			{ isExact: true, route: routes.PLUGIN, component: <PluginPage /> },
			{
				isExact: true,
				route: routes.ACCOUNT,
				component: <AccountPage />,
			},
			{
				isExact: true,
				type: 'needAdminPermission',
				route: routes.CONTROL_PANEL_USER,
				component: <ControlPanelUser />,
			},
			{
				isExact: true,
				type: 'needAdminPermission',
				route: routes.CREATE_USER,
				component: <CreateUser />,
			},
			{
				isExact: true,
				type: 'needAdminPermission',
				route: routes.FORM_CREATE_USER,
				component: <FormCreateUser />,
			},
			{
				isExact: true,
				route: routes.TEAM_LIST,
				component: <TeamListPage />,
			},
			{
				isExact: true,
				type: 'notification',
				route: routes.USER_NOTIFICATIONS,
				component: <UserNotificationsContainer />,
			},
			{
				isExact: true,
				type: 'actions',
				route: routes.USER_ACTIONS,
				component: <UserActionsPage />,
			},
			{
				isExact: true,
				route: routes.TESTING,
				component: <TestPage />,
			},
			{
				isExact: true,
				route: routes.ERROR_PAGE,
				component: <ErrorPage />,
			},
			{
				isExact: false,
				route: routes.STEP_ROUTE,
				component: <StepContainer />,
			},
			{
				isExact: true,
				type: 'upload',
				route: routes.PORTAL,
				component: <Portal />,
			},
			{
				isExact: true,
				type: 'download',
				route: routes.PORTAL_DOWNLOAD,
				component: <PortalDownload />,
			},
			{
				isExact: true,
				type: 'upload',
				route: routes.PORTAL_DEVELOP,
				component: <PortalDevelop />,
			},
		],
	}

	componentDidMount() {
		firebase.auth.onAuthStateChanged(authUser => {
			if (!!authUser) {
				this.setState({
					userCurrent: authUser.uid,
				})
			}
		})
	}

	routerListener = () => {
		const location = window.location
		const { displayName, uid } = this.props.authUser

		analytics.setCurrentScreen(`${location.hash}`)

		analytics.setUserId(uid)
		analytics.setUserProperties({ username: `${displayName}` })

		analyticsEvents(`Informaçoes de acesso`, {
			userCurrent: displayName,
			dateToAccess: new Date().toLocaleString('pt-br', {
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				day: '2-digit',
				month: 'long',
				year: 'numeric',
			}),
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			userCurrent,
			pagesValidate,
			userIsAdmin,
			ticket,
			location
		} = this.state

		const { activeCompanyId } = this.props
		
		let docs, docsStatus

		if (location !== prevProps.location.hash) {
			this.routerListener()
			this.setState({ location: prevProps.location.hash })
		}

		if (prevState.ticket !== ticket) {
			this.permissionAdmin()
		}

		if (
			activeCompanyId !== prevProps.activeCompanyId &&
			!!activeCompanyId &&
			!!userCurrent
		) {
			fs.collection('companies')
				.doc(activeCompanyId)
				.get()
				.then(snap => {
					this.setState({
						ticket: snap.data().tickets
							? snap.data().tickets
							: null,
					})
				})

			fs.collection('companies')
				.doc(activeCompanyId)
				.collection('users')
				.doc(userCurrent)
				.get()
				.then(snapShot => {
					docsStatus = snapShot.data()

					this.setState({
						activeLog: docsStatus.log,
						activeActions: docsStatus.actions,
						activeDownload: docsStatus.download,
						activeUpload: docsStatus.upload,
						activeNotification: docsStatus.notification,
					})
					this.permissionAdmin()
				})
				.catch(err => {
					console.error('Error - ', err)
				})

			fs.collection('companies')
				.doc(activeCompanyId)
				.collection('usersAdmins')
				.get()
				.then(snapShot => {
					snapShot.forEach(snap => {
						docs = snap.data()

						if (snap.id === userCurrent && !!docs.active) {
							this.setState({
								userIsAdmin: true,
							})
						}
					})
				})
				.catch(err => {
					console.error('Error - ', err)
				})
		}

		if (!pagesValidate || userIsAdmin !== prevState.userIsAdmin)
			this.permissionAdmin()
	}

	permissionAdmin = () => {
		const {
			pages,
			userIsAdmin,
			activeLog,
			activeActions,
			activeDownload,
			activeUpload,
			activeNotification,
			ticket,
		} = this.state
		let newPages = []

		pages.forEach(page => {
			if (!!page.type) {
				switch (page.type) {
					case 'needAdminPermission':
						if (!!userIsAdmin) {
							newPages.push(page)
						}
						break
					case 'log':
						if (!!activeLog) {
							newPages.push(page)
						}
						break
					case 'download':
						if (!!activeDownload) {
							newPages.push(page)
						}
						break
					case 'upload':
						if (!!activeUpload) {
							newPages.push(page)
						}
						break
					case 'actions':
						if (!!activeActions) {
							newPages.push(page)
						}
						break
					case 'notification':
						if (!!activeNotification) {
							newPages.push(page)
						}
						break
					case 'about':
						if (!!ticket) {
							newPages.push(
								Object.assign(page, {
									component: <AboutArquia ticket={ticket} />,
								}),
							)
						} else {
							newPages.push(page)
						}
						break
					default:
						break
				}
			} else {
				newPages.push(page)
			}
		})

		this.setState({
			pagesValidate: newPages,
		})
	}

	render() {
		const { pagesValidate } = this.state

		return (
			<Switch>
				{!!pagesValidate &&
					pagesValidate.map(item => (
						<Route
							exact={item.isExact}
							key={item.route}
							path={item.route}
							render={() => item.component}
						/>
					))}
			</Switch>
		)
	}
}

export default Pages
