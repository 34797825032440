import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Icon, Typography } from '@material-ui/core'

/**
 * Manages to display a requirer message (object-list that depends on something/object)
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class ListDependencyRequire extends React.Component {
	render() {
		const { classes, text, icon } = this.props

		return (
			<div className={classes.root}>
				<Icon className={classes.icon}>{icon || 'info'}</Icon>
				<Typography
					variant="subtitle1"
					align="center"
					className={classes.text}>
					{text}
				</Typography>
			</div>
		)
	}
}

const styles = theme => ({
	root: {
		paddingTop: '8px',
		paddingBottom: '8px',
		marginTop: '8px',
		marginBottom: '8px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	icon: {
		fontSize: '2.4em',
		color: `${theme.palette.secondary.light} !important`,
		marginBottom: '8px',
		order: 1,
		flex: '0 1 auto',
	},
	text: {
		fontWeight: 400,
		order: 2,
		textTransform: 'lowercase',
		// FIXME: First letter upper "capitalize" (better standard)
		'&:first-letter': { textTransform: 'uppercase' },
		color: `${theme.palette.secondary.light} !important`,
	},
})

ListDependencyRequire.propTypes = {
	text: PropTypes.string.isRequired,
}

export default withStyles(styles)(ListDependencyRequire)
