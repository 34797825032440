import { CLEAN_ON_COMPANY_CHANGE } from '../../actions/company/index.js'

const DASH_SETS_LOADED = 'DASH_SETS_LOADED',
	CLEAR_DASH_SET = 'CLEAR_DASH_SET',
	SET_DASH_SETS_IS_LOADING = 'SET_DASH_SETS_IS_LOADING'

const INITIAL_STATE = {
	dashSets: null,
	isLoading: false
}

/**
 * Sets a loading state for this state-portion
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {object} action The action with "type" and desired props
 */
const setLoading = (state, action) =>
	Object.assign({}, state, { isLoading: action.isLoading })

/**
 * Removes any previously set dashsets for a clean and new load
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state
 */
// const clearDashSet = (state) => {
// 	return Object.assign({}, state, { dashSets: null })
// }

/**
 * Manages dashSets state layer
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Included setLoading method and switch
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} state The current state
 * @param {Object} action The action object with "type" and "[property]"
 */
function dashSetReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_DASH_SETS_IS_LOADING: {
			return setLoading(state, action)
		}
		case CLEAR_DASH_SET: {
			return INITIAL_STATE
		}
		case DASH_SETS_LOADED: {
			return Object.assign({}, state, { dashSets: action.dashSets })
		}
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default dashSetReducer

export { DASH_SETS_LOADED, CLEAR_DASH_SET, SET_DASH_SETS_IS_LOADING }
