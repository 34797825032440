const INITIAL_STATE = {
	formData: {
		userEmail: ''
	},
	selectedGroups: null,
	groupSuggestions: null,
	saving: false,
	dirty: false,
	loadingSuggestions: false
}

/**
 * Manages an user configuration form state layer
 *
 * @param {Object} state The current state layer of the app
 * @param {Object} action The action object with "type" and the desired prop
 */
function userConfigurationFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_USER_CONFIGURATION_FORM_LOADING_SUGGESTIONS': {
			return Object.assign({}, state, {
				loadingSuggestions: action.loadingSuggestions
			})
		}
		case 'USER_CONFIGURATION_FORM_GROUPS_LOADED': {
			return Object.assign({}, state, {
				groupSuggestions: action.groupSuggestions
			})
		}
		case 'SET_USER_CONFIGURATION_FORM_SELECTED_GROUPS': {
			return Object.assign({}, state, { selectedGroups: action.groups })
		}
		case 'SET_USER_CONFIGURATION_FORM_SAVING': {
			return Object.assign({}, state, { saving: action.saving })
		}
		case 'SET_USER_CONFIGURATION_FORM_DIRTY': {
			return Object.assign({}, state, { dirty: action.dirty })
		}
		case 'STORE_USER_CONFIGURATION_FORM_DATA': {
			return Object.assign({}, state, { formData: action.formData })
		}
		case 'CLEAR_USER_CONFIGURATION_FORM_DATA': {
			return Object.assign({}, state, INITIAL_STATE)
		}
		default:
			return state
	}
}

export default userConfigurationFormReducer
