import {
	MANAGE_GOAL_TRACKING_LOADING_STATE,
	LOAD_GOAL_TRACKING_DATA,
	SET_GOAL_TRACKING_PERIOD
} from '../../actions/goalTracking'

const INITIAL_STATE = {
	isLoading: null,
	goalTracking: null,
	dateConfig: {},
	update: false
}
/**
 * GoalTracking state reducer manager
 * @param {*} state
 * @param {*} action
 */

const goalTrackingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_GOAL_TRACKING_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_GOAL_TRACKING_DATA: {
			return Object.assign({}, state, {
				goalTracking: action.goalTracking,
				isLoading: false
			})
		}
		case SET_GOAL_TRACKING_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default goalTrackingReducer
