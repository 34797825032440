import React from 'react'
import { withStyles, Icon } from '@material-ui/core'
import { placeholder } from './styles'

const Placeholder = ({ classes }) => {
	return (
		<div className={classes.container}>
			<div className={classes.content} />
			<Icon className={classes.icon}>trending_up</Icon>
		</div>
	)
}

export default withStyles(placeholder)(Placeholder)
