import {
	SET_DIALOG_CLOSE,
	SET_DIALOG_OPEN,
	SET_DIALOG_NOTIFICATION_CONTENT
} from '../../../../../reducers/dialog/notification'
import { translate } from '../../../../../utils/lang'

/**
 * @description Created the action creator
 * @author davispindola
 * @version 1.0
 */
export const setDialogNotificationClose = () => ({
	type: SET_DIALOG_CLOSE
})

/**
 * @description Created the action creator
 * @author davispindola
 * @version 1.0
 */
export const setDialogNotificationOpen = () => ({
	type: SET_DIALOG_OPEN
})

/**
 * @description Created the action creator
 * @author davispindola
 * @version 1.0
 * 
 * @description alter params
 * @author jeffersonGuilhermemachadobarreto
 * @version 1.1
 *
 * @param {String} message
 */
export const setNotificationContent = (message) => ({
	type: SET_DIALOG_NOTIFICATION_CONTENT,
	// title: translate(title),
	message: translate(message)
})
