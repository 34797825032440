import React from 'react'
import { Icon, Typography, withStyles, IconButton } from '@material-ui/core'
import { translate } from '../../../../../utils/lang'
import { imagePreviewStyles } from './styles'
import { deviceIsIos } from '../../../../../utils/env';

function getStyle() {
	return {
		width: '100vw'
	}
}

const Header = (props) => {
	const { zoom, handleZoomOff, classes } = props

	return (
		zoom && (
			<header className={ !deviceIsIos() ? classes.header :  classes.ios}>
				<Typography variant="h6" style={{ color: 'white ' }}>
					{translate('label/action-image-visualization')}
				</Typography>
				<IconButton onClick={handleZoomOff} color="primary">
					<Icon>close</Icon>
				</IconButton>
			</header>
		)
	)
}

const ImageContent = (props) => {
	const { zoom, handleZoom, path, classes } = props
	const title = translate('label/image-preview')

	return (
		<div
			className={zoom ? classes.contentZoom : classes.content}
			style={zoom ? getStyle() : { width: 100 }}
			onClick={handleZoom}
		>
			<img
				title={title}
				alt={title}
				style={{
					maxWidth: '100%',
					maxHeight: '100%'
					//  : 'inherit',
					// height: zoom ? 'auto' : 100
				}}
				className={classes.image}
				src={path}
			/>
		</div>
	)
}

/**
 * @author davispindola
 * @version 1.0.0
 */
class ImagePreview extends React.Component {
	state = {
		zoom: false
	}

	componentWillUnmount() {
		this.setState({ zoom: false })
	}

	handleZoom = () =>
		this.setState({
			zoom: true
		})

	handleZoomOff = () => this.setState({ zoom: false })

	render() {
		const { path, classes } = this.props
		const { zoom } = this.state

		return (
			<div className={classes.root}>
				<div
					className={zoom ? classes.containerZoom : classes.container}
				>
					<Header
						zoom={zoom}
						handleZoomOff={this.handleZoomOff}
						classes={classes}
					/>
					{path && (
						<ImageContent
							zoom={zoom}
							handleZoom={this.handleZoom}
							classes={classes}
							path={path}
						/>
					)}
				</div>
			</div>
		)
	}
}

export default withStyles(imagePreviewStyles)(ImagePreview)
