import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

/**
 * Manage Slick lists with react-slick lib.
 * * docs: https://react-slick.neostack.com/docs/example/multiple-items
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0.0
 */
class SlickList extends React.Component {
	render() {
		const { settings, children } = this.props

		return <Slider {...settings}>{children}</Slider>
	}
}

SlickList.defaultProps = {
	settings: {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	}
}

SlickList.propTypes = {
	settings: PropTypes.object.isRequired,
	children: PropTypes.node
}

export default SlickList
