import {
	SET_COMMENT,
	SET_COMPLIMENTS,
	SET_IMPROVEMENTS,
	SET_RATING,
	SET_ADDED_VALUE,
	CLEAR_DATA
} from '../../../../../reducers/systemEvaluation/userActions'

/**
 * @description Created the function
 * @author daivpindola
 * @version 1.0
 */
export const clearData = () => ({
	type: CLEAR_DATA
})

/**
 * @description Created the function
 * @author daivpindola
 * @version 1.0
 *
 * @param {String} comment string message
 */
export const setActionEvaluationComment = (comment) => ({
	type: SET_COMMENT,
	comment
})

/**
 * @description Created the function
 * @author daivpindola
 * @version 1.0
 *
 * @param {Array} compliments list of compliments
 */
export const setActionEvaluationCompliments = (compliments) => ({
	type: SET_COMPLIMENTS,
	compliments
})

/**
 * @description Created the function
 * @author daivpindola
 * @version 1.0
 *
 * @param {Array} improvements list of improvements
 */
export const setActionEvaluationImprovements = (improvements) => ({
	type: SET_IMPROVEMENTS,
	improvements
})

/**
 * @description Created the function
 * @author daivpindola
 * @version 1.0
 *
 * @param {Number} rating rating value (numeric value)
 */
export const setActionEvaluationRating = (rating) => ({
	type: SET_RATING,
	rating
})

/**
 * @description Created the function
 * @author daivpindola
 * @version 1.0
 *
 * @param {Boolean} addedValue
 */
export const setActionEvaluationAddedValue = (addedValue) => ({
	type: SET_ADDED_VALUE,
	addedValue
})
