import React from 'react'
import { connect } from 'react-redux'
import { translate } from '../../../utils/lang'
import FilterModal from '../filter/modal/FilterModal'
import {
	setAppBarRightIcon,
	setAppBarRightIconAction,
	setAppBarRightIconAriaLabel,
	setAppBarRightIconTooltip
} from '../../../actions/app/config'

/**
 * Manages to add an app-bar top-filter-button
 * ! will also add the filter-modal (hidden) at the bottom
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed mapDispatchToProps fashion
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {React.Component|React.PureComponent} Component The component which will use the top filter-button
 */
const withFilterButton = (Component) => {
	class WithFilterButton extends React.Component {
		state = {
			icon: 'filter_list'
		}

		handleClose = (event) => {
			const { onToggleModal } = this.props

			//Will toggle modal on/off show/hide
			onToggleModal()
		}

		componentWillUnmount() {
			const { onResetRightIcon } = this.props

			//Will clear the right-icon-button
			onResetRightIcon()
		}

		componentDidMount() {
			const {
				onSetRightIcon,
				onSetRightIconAction,
				onSetRightIconAriaLabel,
				onSetRightIconTooltip,
				onToggleModal
			} = this.props

			//Adds the top-filter-button and its behaviour
			onSetRightIcon(this.state.icon)
			onSetRightIconAction((event) => onToggleModal())
			onSetRightIconAriaLabel(translate('label/filter-results'))
			onSetRightIconTooltip(translate('label/apply-filters'))
		}

		render() {
			const { open } = this.props

			return (
				<div>
					<Component {...this.props} />
					<FilterModal
						open={open}
						onClose={this.handleClose}
						fullWidth
					/>
				</div>
			)
		}
	}

	const mapStateToProps = (state) => ({
		open: state.filterModalState.open
	})

	const mapDispatchToProps = (dispatch) => ({
		onToggleModal: () => dispatch({ type: 'TOGGLE_FILTER_MODAL' }),
		//TODO: @brunoteixeirasilva remove all manual object dispatching from here
		onResetRightIcon: () => {
			dispatch(setAppBarRightIcon(null))
			dispatch(setAppBarRightIconAction(null))
			dispatch(setAppBarRightIconAriaLabel(null))
			dispatch(setAppBarRightIconTooltip(null))
		},
		onSetRightIcon: (icon) => dispatch(setAppBarRightIcon(icon)),
		onSetRightIconAction: (action) =>
			dispatch(setAppBarRightIconAction(action)),
		onSetRightIconAriaLabel: (label) =>
			dispatch(setAppBarRightIconAriaLabel(label)),
		onSetRightIconTooltip: (tooltip) =>
			dispatch(setAppBarRightIconTooltip(tooltip))
	})

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(WithFilterButton)
}

export default withFilterButton
