import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import generalAppSaga from './../sagas'
//TODO: implement support for VS Code devTools
// import { composeWithDevTools } from 'remote-redux-devtools'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import rootReducer from '../reducers'

const persistConfig = {
	key: 'root',
	storage: storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		'appConfigState',
		'clientState',
		'dateConfigState',
		'pluginState',
		'productivityState',
		'teamListFilterState',
		'userState',
		'sessionState',
		'userCompanyState',
		'actionsStateByAuthor',
	],
	// blacklist: ['userCompanyState'],
}

const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const composeEnhancer =
// 	process.env.NODE_ENV === 'development' && composeWithDevTools
// 		? composeWithDevTools({
// 				realtime: true,
// 				//name: ,
// 				host: 'http://localhost',
// 				port: 3000 // the port your remotedev server is running at
// 		  })
// 		: compose

const composeEnhancer =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose

//Applying store persistor as well as redux-devtools enhancer
export const store = createStore(
	persistedReducer,
	composeEnhancer(applyMiddleware(sagaMiddleware))
)

//Runs saga middleware
sagaMiddleware.run(generalAppSaga)

export const persistor = persistStore(store)
