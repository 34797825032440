export const SET_GLOBAL_FILTER_DATA_LOADING = 'SET_GLOBAL_FILTER_DATA_LOADING',
	SET_GLOBAL_FILTER_DATA = 'SET_GLOBAL_FILTER_DATA'

/**
 * [action-creator] for global filter data loading flag **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 * @param {boolean} isLoading If the data is loading or not
 */
export const filterDataLoading = (dataSetId, isLoading) => ({
	type: SET_GLOBAL_FILTER_DATA_LOADING,
	dataSetId,
	isLoading
})

/**
 * [action-creator] for global filter data **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 * @param {Object|Array} data The data to be stored
 */
export const setGlobalFilterData = (dataSetId, data) => ({
	type: SET_GLOBAL_FILTER_DATA,
	dataSetId,
	data
})
