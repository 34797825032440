import { store } from '../../../../../store'
import {
	setUserActiveCompany,
	setRequireActiveCompany
} from '../../../../../actions/user/company'
import { showSnackBar } from '../../../../../actions/snackbar'
import {
	makeGetCompaniesList,
	makeGetCompaniesListKeys
} from '../../../../../selectors/company/list'
import { translate } from '../../../../../utils/lang'
import * as User from '../../../../../firebase/user'
import { makeGetAuthUserID } from '../../../../../selectors/session'

/**
 * Will set the /users/${uid}/activeCompanyId entry
 * as well as the redux active user company
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The company being set active
 */
const changeActiveCompany = async (companyId) => {
	const state = store.getState(),
		onSetActiveCompany = async (activeCompanyId) =>
			store.dispatch(setUserActiveCompany(activeCompanyId)),
		onSetRequiredCompany = async (require) =>
			store.dispatch(setRequireActiveCompany(require)),
		emitMessage = (notification) =>
			store.dispatch(showSnackBar(notification)),
		getAuthUserID = makeGetAuthUserID(),
		authUserID = getAuthUserID(state),
		getCompanies = makeGetCompaniesList(),
		getCompaniesKeys = makeGetCompaniesListKeys(),
		companies = getCompanies(state),
		companiesKeys = getCompaniesKeys(state)

	//Authuser loaded successfully
	if (!authUserID) {
		return Promise.reject('unlogged-user')
	}

	//Dispatches the changes to listeners
	//As well as stops requiring active company
	return onSetActiveCompany(companyId)
		.then(() => {
			User.doSetActiveCompany(authUserID, companyId)
			onSetRequiredCompany(false)
		})
		.then(() => {
			//Will set the firebase activeCompanyId
			//Notifies the user he's changed active company
			return emitMessage(
				translate('success/active-company-changed', {
					companyName:
						companiesKeys.length > 0 &&
						!!companies[companyId].abbrev
							? companies[companyId].abbrev
							: companies[companyId].name
				})
			)
		})
}

export default changeActiveCompany
