import React from 'react'
import { compose } from 'recompose'
import {
	FormControl,
	InputLabel,
	Input,
	MenuItem,
	Select,
	Icon,
	IconButton,
	InputAdornment,
	withStyles
} from '@material-ui/core'
import { translate } from '../../../../../utils/lang'
import { selectFilterForm } from './styles'

/**
 * Filter form components for action page
 * @author Davi Wegner
 * @description created the component
 * @version 1.0
 */
class ActionFilterForm extends React.Component {
	render() {
		const {
			label,
			onSelect,
			value,
			fields,
			fieldInfo,
			handleDelete,
			classes
		} = this.props

		return !fields ? null : fields.length > 0 ? (
			<FormControl fullWidth>
				<InputLabel htmlFor="select-multiple">{label}</InputLabel>
				<Select
					classes={{
						select: classes.select
					}}
					multiple
					value={value}
					onChange={onSelect}
					endAdornment={
						undefined !== value &&
						value !== null &&
						(!(value instanceof Array) || value.length > 0) && (
							<InputAdornment>
								<IconButton
									onClick={() => handleDelete(label)}
									aria-label={translate('label/remove')}
								>
									<Icon>clear</Icon>
								</IconButton>
							</InputAdornment>
						)
					}
					input={<Input id="select-multiple" />}
				>
					{!!fields &&
						fields.map((field) => (
							<MenuItem
								key={fieldInfo === null ? field.key : field}
								value={fieldInfo === null ? field.key : field}
							>
								{fieldInfo === null
									? field.label
									: label === 'Autor'
									? fieldInfo[field].name
									: label === 'Data'
									? field
									: fieldInfo[field]}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		) : null
	}
}

export default compose(withStyles(selectFilterForm))(ActionFilterForm)
