export const MANAGE_TRAINING_LOADING_STATE = 'MANAGE_TRAINING_LOADING_STATE',
	LOAD_TRAINING_DATA = 'LOAD_TRAINING_DATA',
	SET_TRAINING_PERIOD = 'SET_TRAINING_PERIOD'

export const setTrainingPeriod = (dateConfig, value, formType) => ({
	type: SET_TRAINING_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadTrainingData = (training) => ({
	type: LOAD_TRAINING_DATA,
	training
})

export const setLoading = (isLoading) => ({
	type: MANAGE_TRAINING_LOADING_STATE,
	isLoading
})
