import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	SET_PORTAL_LOADING_STATE,
	SET_PORTAL_DASHITEMS_LOADED,
	SET_PORTAL_SELECTED_FILE,
	SET_PORTAL_UPLOAD_STATE,
	SET_PORTAL_SELECTED_DASH_ITEM,
	CLEAN_PORTAL_DATA,
} from '../../../reducers/portal'
import { getByCompanySheetsOnly } from '../../../firebase/dashItem'
import Loader from '../../reusable/loader'
import makeDashItemsFormatter from '../../../selectors/portal'
import {
	Card,
	CardHeader,
	CardActions,
	Icon,
	IconButton,
	Button,
	LinearProgress,
	withStyles,
	Grid,
	Dialog,
	DialogContent,
	DialogActions,
	Typography,
	Fade,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl
} from '@material-ui/core'
import axios from 'axios'
import { newStorage, fs } from '../../../firebase/firebase'
import withAuthentication from '../../reusable/withAuthentication'
import {
	makeGetUserActiveCompany,
	makeGetUserActiveCompanyId,
} from '../../../selectors/user/company'
import { translate } from '../../../utils/lang'
import fromUnixTime from 'date-fns/fromUnixTime'
import { format } from 'date-fns'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import withLogger from '../../reusable/withLogger'

/**
 * @author Wegner
 * @email wegner@arquia.com.br
 * @created 08-11-2019
 *
 * @author lucas lopes
 * @email lopes@arquia.com.br
 *
 */
class Portal extends React.Component {
	state = {
		uploads: [],
		progress: null,
		progressStats: {},
		backup: false,
		start: false,
		userAtualId: null,
		userAtual: null,
		backupSelected: null,
		bool: null,
		boolCards: null,
		cards: [],
		value: '',
		choiceReady: false,
		isBackup: false,
		fileSelected: false,
		successBackup: false,
		registros: [],
	}

	componentDidMount() {
		const { authUser } = this.props
		
		this.loadDashItems()
		this.checkLastFile()
		this.checkRegistro()
		this.carregarCards()
		this.setState({
			userAtualId: authUser.uid,
			userAtual: authUser.displayName,
		})
	}
	componentDidUpdate(prevProps) {
		const { activeCompanyId } = this.props

		if (prevProps.activeCompanyId !== activeCompanyId) {
			this.checkLastFile()
			this.carregarCards()
			this.checkRegistro()
		}
	}
	loadDashItems = () => {
		const {
			activeCompanyId,
			onSetLoading,
			onSetLoadedDashItems,
		} = this.props
		onSetLoading(true)
			.then(() => getByCompanySheetsOnly(activeCompanyId))
			.then((dashItems) => {
				let collection = {}

				dashItems.forEach((dashItem) => {
					collection[dashItem.id] = dashItem.data()
				})

				return onSetLoadedDashItems(collection)
			})
			.then(() => {
				onSetLoading(false)
			})
	}
	timeConverter = (time) => {
		let dateBruta = fromUnixTime(time)
		let date = format(dateBruta, "dd/MM/yyyy'-'HH:mm:ss")
		return date
	}
	checkRegistro = () => {
		const { activeCompanyId } = this.props

		let ids = [],
			registros = []

		fs.collection('uploads')
			.where('companyId', '==', activeCompanyId)
			.onSnapshot(
				(snapshot) => {
					if (snapshot.empty) {
						console.log('No matching documents.')
						return
					}
					snapshot.forEach((doc) => {
						if (ids.includes(doc.id)) {
							return
						} else if (doc.data().isReady) {
							ids.push(doc.id)
							registros.push({
								[doc.id]: doc.data().qtdRegistros,
							})

							return this.setState({
								registros,
							})
						}
					})
				},
				(err) => {
					console.log('Error getting documents', err)
				},
			)
	}
	checkLastFile = () => {
		const { activeCompanyId } = this.props

		let docs = []
		let ids = []
		let cards = []

		fs.collection('companies')
			.doc(activeCompanyId)
			.collection('dashItems')
			.onSnapshot(
				(snapshot) => {
					if (snapshot.empty) {
						console.log('No matching documents.')
						return
					}
					snapshot.forEach((doc) => {
						cards.push({
							[doc.id]: doc.data(),
						})

						return this.setState({
							cards,
						})
					})
				},
				(err) => {
					console.log('Error getting documents', err)
				},
			)

		fs.collection('uploads')
			.where('companyId', '==', activeCompanyId)
			.onSnapshot(
				(snapshot) => {
					if (snapshot.empty) {
						console.log('No matching documents.')
						return
					}
					snapshot.forEach((doc) => {
						if (ids.includes(doc.id)) {
							return
						} else if (doc.data().isReady) {
							ids.push(doc.id)
							docs.push(doc.data())

							return this.setState({
								uploads: docs,
							})
						}
					})
				},
				(err) => {
					console.log('Error getting documents', err)
				},
			)
	}

	carregarCards = () => {
		const { activeCompanyId } = this.props
		let cards = []

		fs.collection('companies')
			.doc(activeCompanyId)
			.collection('dashItems')
			.onSnapshot(
				(snapshot) => {
					if (snapshot.empty) {
						console.log('No matching documents.')
						return
					}
					snapshot.forEach((doc) => {
						cards.push({
							[doc.id]: doc.data(),
						})

						return this.setState({
							cards,
						})
					})
				},
				(err) => {
					console.log('Error getting documents', err)
				},
			)
	}

	handleSelectedCard = (key) => {
		const { onSetSelectedDashItem, activeCompanyId, authUser } = this.props

		onSetSelectedDashItem(key)
		this.isUploading(key, activeCompanyId, authUser.uid)
	}

	checkPermission = () => {
		const { activeCompany, history, authUser } = this.props
		if (
			!(
				activeCompany &&
				activeCompany.uploadAllowed &&
				activeCompany.uploadAllowed.includes(authUser.uid)
			)
		)
			history.push('/plugin')
	}
	componentWillUnmount() {
		this.props.onCleanPortalData()
	}
	handleSelectedFile = (event) => {
		const { onSetSelectedFile } = this.props

		let file = event.target.files[0]

		if (file.type === 'text/csv' || file.name.includes('.csv')) {
			onSetSelectedFile(file)

			this.setState({
				successFile: 'Arquivo válido.',
				errorFile: null,
				fileSelected: true,
			})
		} else {
			event.target.value = null
			this.setState({
				errorFile: 'Erro: Arquivo inválido.',
				successFile: null,
				fileSelected: false,
			})
			console.log('invalid file type')
		}
	}

	upBackup = (item) => {
		const { activeCompanyId } = this.props

		const { userAtual, userAtualId, value } = this.state

		let typeUpload = {
			type_upload: value ? value : item.type_upload,
		}

		fs.collection('profileConfs')
			.doc(item.uploadProfile)
			.update(typeUpload)

		let type_upload = value ? value : item.type_upload

		let doc = fs.collection('uploads').doc()

		let data = {
			nameFile: item.nameFile,
			nameOriginal: item.nameOriginal,
			date: new Date(),
			size: item.size,
			path: item.path,
			companyId: activeCompanyId,
			fileType: item.fileType,
			pathPart: item.pathPart,
			uploadProfile: item.uploadProfile,
			uid: userAtualId,
			displayName: userAtual,
			email: item.email,
			docId: doc.id,
			type_upload,
		}

		// .then(() => {
		let { progressStats } = this.state
		progressStats['1'] = true
		this.setState({ progress: 2, progressStats })
		doc.set(data)

		axios
			.post(
				// 'https://agildash-app-db.appspot.com/',
				'https://us-central1-agildash-app.cloudfunctions.net/heavenGuardian',
				{
					uploadId: data.docId,
				},
				{
					timeout: 1000000,
				},
			)
			.then((value) => {
				let { progressStats } = this.state
				progressStats['2'] = true
				this.setState({ progress: 3, progressStats })

				axios.post(
					'https://us-central1-agildash-app.cloudfunctions.net/sourcerer',
					{
						uploadId: data.docId,
					},
					{ timeout: 600000 },
				)
			})
			.then((result) => {
				let { progressStats } = this.state
				progressStats['3'] = true

				this.setState({
					progress: 5,
					progressStats,
					successBackup: true,
				})
				doc.update({ isReady: true })
			})
			.catch((err) => {
				let { progressStats, progress } = this.state
				progressStats[progress] = false

				console.log(err)
				this.setState({ progressStats })
				this.setState({ progress: 'error', successBackup: false })
			})
		// })

		this.isUploading(item.dashItem, activeCompanyId, userAtualId)
	}

	handleUpload = () => {
		const {
			selectedDashItem,
			activeCompanyId,
			selectedFile,
			authUser,
			createLog,
		} = this.props

		const { value } = this.state

		let typeUpload = {
			type_upload: value,
		}

		if (value) {
			fs.collection('profileConfs')
				.doc(selectedDashItem)
				.update(typeUpload)
		}

		this.setState({
			start: true,
		})

		let doc = fs.collection('uploads').doc()

		let pathPart = `companies/${activeCompanyId}/uploadProfiles/${selectedDashItem}`
		let ref = newStorage.ref(
				`companies/${activeCompanyId}/uploadProfiles/${selectedDashItem}`,
			),
			fileName = selectedFile.name.split('.').join(
				` ${new Date()
					.toISOString()
					.replace('T', ' ')
					.slice(0, 16)}.`,
			)
		ref = ref.child(fileName)
		let data = {
			nameFile: fileName,
			nameOriginal: selectedFile.name,
			date: new Date(),
			size: selectedFile.size,
			path: ref.fullPath,
			companyId: activeCompanyId,
			pathPart,
			fileType: selectedFile.name.endsWith('.csv')
				? 'text/csv'
				: selectedFile.type,
			uploadProfile: selectedDashItem,
			uid: authUser.uid,
			displayName: authUser.displayName,
			email: authUser.email,
			docId: doc.id,
			type_upload: value,
		}

		ref.put(selectedFile).then(() => {
			let { progressStats } = this.state
			progressStats['1'] = true
			this.setState({ progress: 2, progressStats })
			doc.set(data)

			axios
				.post(
					'https://us-central1-agildash-app.cloudfunctions.net/heavenGuardian',
					{
						uploadId: data.docId,
					},
					{
						timeout: 1000000,
					},
				)
				.then((value) => {
					let { progressStats } = this.state
					progressStats['2'] = true
					this.setState({ progress: 3, progressStats })

					axios.post(
						'https://us-central1-agildash-app.cloudfunctions.net/sourcerer',
						{
							uploadId: data.docId,
						},
						{ timeout: 600000 },
					)
				})
				.then((result) => {
					let { progressStats } = this.state
					progressStats['3'] = true

					this.setState({ progress: 5, progressStats })
					doc.update({ isReady: true })
					createLog('upload', { profileId: selectedDashItem })
				})
				.catch((err) => {
					let { progressStats, progress } = this.state
					progressStats[progress] = false
					console.log(err)
					this.setState({ progressStats })
					this.setState({ progress: 'error' })
				})
		})

		this.isUploading(selectedDashItem, activeCompanyId, authUser.uid)
		this.setState({
			start: false,
		})
	}

	isUploadingCard = (item, activeCompanyId) => {
		fs.collection('companies')
			.doc(activeCompanyId)
			.collection('dashItems')
			.onSnapshot(
				(snapshot) => {
					if (snapshot.empty) {
						console.log('No matching documents.')
						return
					}
					snapshot.forEach((doc) => {
						if (doc.id === item.dashItem) {
							this.setState({
								boolCards: doc.data().isUploading,
							})
						}
					})
				},
				(err) => {
					console.log('Error getting documents', err)
				},
			)

		return this.state.boolCards
	}

	isUploading = (selectedDashItem, activeCompanyId, user) => {
		// const { selectedDashItem, activeCompanyId } = this.props

		fs.collection('companies')
			.doc(activeCompanyId)
			.collection('dashItems')
			.onSnapshot(
				(snapshot) => {
					if (snapshot.empty) {
						console.log('No matching documents.')
						return
					}
					snapshot.forEach((doc) => {
						if (doc.id === selectedDashItem) {
							this.setState({
								bool: doc.data().isUploading,
							})
						}
					})
				},
				(err) => {
					console.log('Error getting documents', err)
				},
			)
		// this.addUser(selectedDashItem, activeCompanyId, user)

		return this.state.bool
	}

	handleClose = () => {
		this.props.onSetSelectedDashItem(null)
		this.setState({
			progress: null,
			isBackup: false,
			errorFile: null,
			successFile: null,
			start: false,
			progressStats: {},
			fileSelected: false,
		})
		this.props.onSetSelectedFile(null)
		this.checkRegistro()
	}

	orderUploads = (uploads) => {
		uploads.sort((a, b) => {
			if (a.date.seconds > b.date.seconds) {
				return -1
			} else if (a.date.seconds < b.date.seconds) {
				return 1
			} else {
				return 0
			}
		})

		return uploads
	}

	nameCard = (item) => {
		let name = ''
		const { cards } = this.state

		cards.forEach((obj) => {
			Object.keys(obj).map((key) => {
				if (key === item.uploadProfile) {
					name = obj[key].title
				}
			})
		})

		return name || 'Ambiente de teste'
	}

	handleRadioChange = (event) => {
		this.setState({
			value: event.target.value,
			choiceReady: true,
		})
	}

	backupProcess = (dadosBackup) => {
		this.setState({
			isBackup: true,
			backupSelected: dadosBackup,
		})
	}

	render() {
		const { isLoading, options, selectedDashItem, classes } = this.props

		const {
			progress,
			progressStats,
			uploads,
			isBackup,
			start,
			bool,
			value,
			choiceReady,
			errorFile,
			successFile,
			backupSelected,
			fileSelected,
			successBackup,
			registros,
		} = this.state

		const MySwal = withReactContent(Swal)

		return (
			<div className={classes.page}>
				{isLoading && <Loader internal />}
				<Grid container spacing={8}>
					{options &&
						options.map((option, index) => (
							<Grid item xs={12} sm={6} md={6} lg={4} key={index}>
								<DashItemCard
									onClick={this.handleSelectedCard}
									{...option}
								/>
							</Grid>
						))}
				</Grid>

				<Dialog
					open={!!selectedDashItem || isBackup}
					onClose={this.handleClose}
					disableBackdropClick={true}>
					<DialogContent>
						<div>
							{bool ? (
								<div>
									Esta ocorrendo um upload neste momento
								</div>
							) : !isBackup ? (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}>
									<p
										style={{
											marginBottom: '20px',
											fontWeight: 500,
										}}>
										1 - Selecione o arquivo:
									</p>
									<input
										onChange={this.handleSelectedFile}
										type="file"
									/>
									{!!errorFile && (
										<p
											style={{
												color: 'red',
												marginTop: '10px',
												fontWeight: 400,
											}}>
											{errorFile}
										</p>
									)}
									{!!successFile && (
										<p
											style={{
												color: 'green',
												marginTop: '10px',
												fontWeight: 400,
											}}>
											{successFile}
										</p>
									)}
								</div>
							) : (
								<p
									style={{
										fontSize: '20px',
										fontWeight: 500,
									}}>
									Modo backup
								</p>
							)}

							{fileSelected || isBackup ? (
								<form className={classes.formContainer}>
									<p
										style={{
											marginBottom: '5px',
											marginTop: '20px',
											fontWeight: 500,
										}}>
										{isBackup
											? '1 - Selecionar o tipo do backup'
											: '2 - Selecionar o tipo do upload'}
									</p>
									<FormControl
										component="fieldset"
										className={classes.formControl}>
										<RadioGroup
											name="quiz"
											defaultValue="default"
											onChange={this.handleRadioChange}>
											<FormControlLabel
												value="default"
												control={<Radio />}
												label="Selecione uma das alternativas"
											/>
											<FormControlLabel
												value="DROP"
												control={<Radio />}
												label="Apagar os registros anteriores e incluir os registros do arquivo."
											/>
											<FormControlLabel
												value="update"
												control={<Radio />}
												label="Acrescentar os registros do arquivo e manter os registros anteriores."
											/>
										</RadioGroup>
									</FormControl>
								</form>
							) : null}
							{fileSelected || (isBackup && choiceReady) ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}>
									<Button
										className={classes.btnInciar}
										disabled={
											!!progress ||
											start ||
											value === 'default'
										}
										variant="outlined"
										color="primary"
										onClick={() => {
											if (value !== 'default')
												isBackup
													? this.upBackup(
															backupSelected,
													  )
													: this.handleUpload()
											this.setState({ start: true })
										}}>
										Iniciar
									</Button>
								</div>
							) : null}
							<div className={classes.loaderContainer}>
								{!progress && start && <Loader internal />}

								{progress &&
									[
										{
											value: 1,
											label: 'Upload do arquivo',
										},
										{
											value: 2,
											label: 'Processando arquivo',
										},
										{
											value: 3,
											label: 'Inserindo no banco',
										},
									].map((item) => (
										<div
											key={item.value}
											className={classes.innerContainer}>
											<div className={classes.stepLoader}>
												<p className={classes.label}>
													{item.label}
												</p>
												<LinearProgress
													{...(progress === item.value
														? {}
														: {
																value: 100,
																valueBuffer: 100,
																variant:
																	'determinate',
																color: progressStats[
																	item.value
																]
																	? item.value >
																	  progress
																		? 'secondary'
																		: 'primary'
																	: progress ===
																	  'error'
																	? 'secondary'
																	: 'secondary',
														  })}
												/>
											</div>
											<Fade
												in={progressStats.hasOwnProperty(
													item.value,
												)}>
												<div className={classes.icon}>
													<Icon
														color={
															progressStats[
																item.value
															]
																? 'primary'
																: 'error'
														}>
														{progressStats[
															item.value
														]
															? 'check_circle_outline'
															: 'error_outline'}
													</Icon>
												</div>
											</Fade>
										</div>
									))}
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>
							{translate('label/close')}
						</Button>
					</DialogActions>
				</Dialog>
				<div
					style={{
						margin: '10px',
						display: 'flex',
					}}>
					<Typography>Logs</Typography>
				</div>

				<div className={classes.logsContainerGrid}>
					<>
						<p className={classes.tdHeader}>Nome do arquivo</p>

						<p className={classes.tdHeader}>Data</p>
						<p className={classes.tdHeader}>Nome do card</p>
						<p className={classes.tdHeader}>Tipo de upload</p>

						<p className={classes.tdHeader}>
							Quantidade de registros
						</p>
						<p className={classes.tdHeader}>Tamanho em Kb</p>
						<p className={classes.tdHeader}>Nome do usuario</p>
						<p className={classes.tdHeader}>Restaurar</p>
					</>

					{uploads.length > 0 &&
						this.orderUploads(uploads).map((item) => {
							return (
								<>
									<p className={classes.tdRoll}>
										{item.nameOriginal}
									</p>

									<p className={classes.tdRoll}>
										{this.timeConverter(item.date.seconds)}
									</p>
									<p className={classes.tdRoll}>
										<p>{this.nameCard(item)}</p>
									</p>
									<p className={classes.tdRoll}>
										<p>
											{item.type_upload ??
												'Carregando...'}
										</p>
									</p>
									<p className={classes.tdRoll}>
										{registros.length > 0 &&
											registros.map((registro) => {
												if (
													Object.keys(registro)[0] ===
													item.docId
												)
													return Object.values(
														registro,
													)[0]
											})}
									</p>
									<p className={classes.tdRoll}>
										{item.size}
									</p>
									<p className={classes.tdRoll}>
										{item.displayName}
									</p>
									{
										<Button
											onClick={() => {
												MySwal.fire({
													title: 'Você tem certeza?',
													text:
														'Você irá restaurar um backup de dados, isso pode apagar dados indesejados',
													icon: 'warning',
													showCancelButton: true,
													confirmButtonColor:
														'#3085d6',
													cancelButtonColor: '#d33',
													confirmButtonText:
														'Sim, pode restaurar!',
												}).then((result) => {
													if (result.value) {
														this.backupProcess(item)
														{
															successBackup
																? Swal.fire({
																		title:
																			'Restaurado!',
																		html:
																			'Você restaurou o banco de dados',
																		icon:
																			'success',
																		timer: 4500,
																  })
																: Swal.fire({
																		title:
																			'Operação cancelada!',
																		html:
																			'Tente novamente',
																		icon:
																			'error',
																		timer: 4500,
																  })
														}
													}
												})
											}}
											disabled={bool === true}
											style={{
												cursor: 'pointer',
												textDecorationLine: 'underline',
												color: 'darkblue',
											}}
											className={classes.tdRoll}>
											{bool
												? 'Upload em andamento...'
												: 'Iniciar'}
										</Button>
									}
								</>
							)
						})}
				</div>
			</div>
		)
	}
}

const DashItemCard = (props) => {
	return (
		<Card>
			<CardHeader title={props.title} />
			<CardActions>
				<IconButton onClick={() => props.onClick(props.id)}>
					<Icon>cloud_upload</Icon>
				</IconButton>
			</CardActions>
		</Card>
	)
}
const makeMapperStateToProps = () => {
	const getDashItemOptions = makeDashItemsFormatter(),
		getActiveCompanyId = makeGetUserActiveCompanyId(),
		getActiveCompany = makeGetUserActiveCompany()
	const mapStateToProps = (state, ownProps) => ({
		isLoading: state.portalState.isLoading,
		activeCompany: getActiveCompany(state),
		activeCompanyId: getActiveCompanyId(state),
		selectedDashItem: state.portalState.selectedDashItem,
		selectedFile: state.portalState.selectedFile,
		options: getDashItemOptions(state, ownProps),
	})
	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	onSetLoading: async (isLoading) =>
		dispatch({ type: SET_PORTAL_LOADING_STATE, isLoading }),
	onSetLoadedDashItems: async (dashItems) =>
		dispatch({ type: SET_PORTAL_DASHITEMS_LOADED, dashItems }),
	onSetSelectedFile: (selectedFile) =>
		dispatch({ type: SET_PORTAL_SELECTED_FILE, selectedFile }),
	onSetUploadState: (uploadState) =>
		dispatch({ type: SET_PORTAL_UPLOAD_STATE, uploadState }),
	onSetSelectedDashItem: (selectedDashItem) =>
		dispatch({ type: SET_PORTAL_SELECTED_DASH_ITEM, selectedDashItem }),
	onCleanPortalData: () => dispatch({ type: CLEAN_PORTAL_DATA }),
})
const styles = () => ({
	btnRadio: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
		marginBottom: '10px',
	},

	formContainer: {
		marginTop: '10px',
	},

	logsContainer: {
		boxShadow:
			'0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
		backgroundColor: '#FFF',
		margin: '10px',
		padding: '10px',
		borderRadius: '10px',
	},

	logsContainerGrid: {
		borderRadius: '10px',
		margin: '10px',
		overflow: 'auto',
		border: 'solid 1px #e1e1e147',
		display: 'grid',
		gridTemplateColumns: 'repeat(8,1fr)',
		backgroundColor: '#FFF',
		boxShadow:
			'0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
	},

	tdHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '5px',
		textAlign: 'center',
		border: 'solid 1px #e1e1e147',
		fontSize: '18px',
	},

	tdRoll: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		padding: '5px',
		border: 'solid 1px #e1e1e147',
		fontSize: '14px',
	},

	logsContent: {},

	logsContentItems: {
		marginTop: '5px',
		fontSize: '16px',
		fontWeight: '500',
	},

	logsContentItemsTitle: {
		fontWeight: '400',
		color: 'dimgrey',
		marginRight: '5px',
	},

	stepLoader: {
		width: '100%',
		margin: '0px 1px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		textAlign: 'center',
	},
	loaderContainer: {
		margin: '8px',
	},
	page: {
		margin: '4px',
	},
	label: {
		fontSize: '14px',
		margin: '4px',
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		margin: '4px',
	},
})
export default compose(
	withStyles(styles),
	withRouter,
	// withActiveCompany,
	withLogger('upload', false),
	withAuthentication,
	connect(makeMapperStateToProps, mapDispatchToProps),
)(Portal)
