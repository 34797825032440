const styles = (theme) => ({
	//TODO: remove from here
	cardWrapper: {
		padding: theme.spacing.unit,
		cursor: 'pointer',
		height: '100%'
	},
	cardHeader: {
		padding: `0 0 ${theme.spacing.unit / 2}px 0`,
		color: '#003349',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		'& *': {
			flex: '0 0 auto'
		}
	},
	cardHeaderSource: {
		marginRight: theme.spacing.unit / 2,
		height: '24px'
	},
	cardHeaderDescription: {
		maxWidth: '70%',
		wordBreak: 'break-word'
	},
	teamReach: {
		marginLeft: 'auto',
		boxSizing: 'border-box',
		// borderRadius: '50%',
		// height: '40px',
		// width: '40px',
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
		// color: theme.palette.primary,
		'& > hr': {
			border: '4px solid',
			borderRadius: 20
		}
	},
	cardBody: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '8px 0',
		'& ul li': {
			marginTop: theme.spacing.unit
		}
	},
	alignRight: {
		textAlign: 'right'
	},
	inactive: {
		color: 'rgba(140,140,140, 0.2)',
		fontSize: '14px'
	},
	active: {
		color: theme.palette.primary.dark,
		fontSize: '14px'
	}
})

export default styles
