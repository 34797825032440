export const placeholder = (theme) => ({
	container: {
		display: 'flex',
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
		height: '180px',
		width: '90%',
		margin: '10px auto auto auto',
		borderRadius: '5px'
	},
	'@keyframes placeHolderShimmer': {
		'0%': {
			backgroundPosition: '200% 0%'
		},
		'100%': {
			backgroundPosition: '100% 0%'
		}
	},
	content: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		backgroundSize: '200% 200%',
		animationDuration: '3s',
		animationFillMode: 'forwards',
		animationIterationCount: 'infinite',
		animationName: 'placeHolderShimmer',
		animationTimingFunction: 'linear',
		background: `linear-gradient(
			to right,
			rgba(160,160,160, 0.1),
			rgba(60,60,60, 0.2)	
		  )`,
		borderRadius: '5px'
	},
	icon: {
		color: 'rgba(148, 148, 148, 0.58)',
		fontSize: '180px'
	}

	// .animated-background {

	// 	background: #f6f7f8;
	// 	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	// 	background-size: 800px 104px;
	// 	height: 96px;
	// 	position: relative;
	// }
})
