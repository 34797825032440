import sourceEngine from '../../sourceEngine'

/**
 * Queries for filter data
 * at a (still-default) sigs data-source
 *
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @param {Object} params The sourceEngine params to be passed on to the query
 * @param {string} dataSourceId The dataSource from which data-access config will be required
 * @param {string} dataSetId The dataSet from which data will be queried
 */
const defaultFilterGet = (
	params = {},
	dataSourceId = 'appAudit',
	dataSetId = 'classification'
) => sourceEngine().postProvisory(dataSourceId, dataSetId, params)

export default defaultFilterGet
