import { fs } from '../firebase'

export const getAll = (activeCompanyId) =>
	fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('keys')
		.get()

/**
 * Responsible for search filter content by especific key
 *
 * @description Created the method
 * @author davispindola
 * @version 1.0
 *
 * @param {*} activeCompanyId
 * @param {*} key
 */
export const getByKey = (activeCompanyId, key) =>
	fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('keys')
		.doc(key)
		.collection('options')
		.get()
