export const SET_LOADED_OUTLAY_DATA = 'SET_LOADED_OUTLAY_DATA',
	SET_OUTLAY_IS_LOADING_STATE = 'SET_OUTLAY_IS_LOADING_STATE',
	SET_OUTLAY_DATE_CONFIG = 'SET_OUTLAY_DATE_CONFIG'

/**
 * [action-creator] For setting the outlay-loaded **date config** for charting
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} dateConfig The date configration to be set
 */
export const setOutlayAvoidedDateConfig = (dateConfig, value, formType) => ({
	type: SET_OUTLAY_DATE_CONFIG,
	dateConfig,
	value,
	formType
})

/**
 * [action-creator] For setting the outlay-loaded **is-loading** for charting
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} isLoading The loading state to be set
 */
export const setOutlayAvoidedLoading = (isLoading) => ({
	type: SET_OUTLAY_IS_LOADING_STATE,
	isLoading
})

/**
 * [action-creator] For setting the outlay-loaded **data** for charting
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} data The loaded data to be set
 */
export const setOutlayAvoidedData = (data) => ({
	type: SET_LOADED_OUTLAY_DATA,
	data
})
