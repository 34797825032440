import React from 'react'
import { Icon, Avatar } from '@material-ui/core'

/**
 * reusable notification icon avatar
 * @description Created the component
 * @author Davi Wegner
 * @version 1.0
 */
const NotificationIconAvatar = () => (
	<Avatar style={{ backgroundColor: '#5361b1' }}>
		<Icon>record_voice_over</Icon>
	</Avatar>
)
export { NotificationIconAvatar }
