import {
    MANAGE_NC_STAGE_LOADING_STATE,
    LOAD_NC_STAGE_DATA,
    SET_NC_STAGE_PERIOD
} from '../../actions/ncStage'

const INITIAL_STATE = {
	isLoading: null,
	ncStage: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
        objeto: '',
        formulario: ''
	}
}
/**
 * NC stage state reducer manage
 * @description created the card NC for stage
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const ncStageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_NC_STAGE_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_NC_STAGE_DATA: {
            return Object.assign({}, state, {
                ncStage: action.ncStage,
                isLoading: false
            })
        }
        case 'SET_NC_STAGE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_NC_STAGE_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_NC_STAGE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
        case SET_NC_STAGE_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        default:
            return state
    }
}

export default ncStageReducer
