const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		margin: theme.spacing.unit / 2
	},
	chip: {
		margin: theme.spacing.unit / 2
	},
	button: {
		flex: '0 0 auto',
		color: '#000'
	},
	['@keyframes normalize']: {
		'0%': {
			'-moz-transform': 'scaleY(-1)',
			'-o-transform': 'scaleY(-1)',
			'-webkit-transform': 'scaleY(-1)',
			transform: 'scaleY(-1)'
		},
		'25%': {
			'-moz-transform': 'scaleY(-.75)',
			'-o-transform': 'scaleY(-.75)',
			'-webkit-transform': 'scaleY(-.75)',
			transform: 'scaleY(-.75)'
		},
		'50%': {
			'-moz-transform': 'scaleY(-.5)',
			'-o-transform': 'scaleY(-.5)',
			'-webkit-transform': 'scaleY(-.5)',
			transform: 'scaleY(-.5)'
		},
		'75%': {
			'-moz-transform': 'scaleY(-.25)',
			'-o-transform': 'scaleY(-.25)',
			'-webkit-transform': 'scaleY(-.25)',
			transform: 'scaleY(-.25)'
		},
		'100%': {
			'-moz-transform': 'scaleY(0)',
			'-o-transform': 'scaleY(0)',
			'-webkit-transform': 'scaleY(0)',
			transform: 'scaleY(0)'
		}
	},
	['@keyframes invert']: {
		'0%': {
			'-moz-transform': 'scaleY(0)',
			'-o-transform': 'scaleY(0)',
			'-webkit-transform': 'scaleY(0)',
			transform: 'scaleY(0)'
		},
		'25%': {
			'-moz-transform': 'scaleY(-.25)',
			'-o-transform': 'scaleY(-.25)',
			'-webkit-transform': 'scaleY(-.25)',
			transform: 'scaleY(-.25)'
		},
		'50%': {
			'-moz-transform': 'scaleY(-.5)',
			'-o-transform': 'scaleY(-.5)',
			'-webkit-transform': 'scaleY(-.5)',
			transform: 'scaleY(-.5)'
		},
		'75%': {
			'-moz-transform': 'scaleY(-.75)',
			'-o-transform': 'scaleY(-.75)',
			'-webkit-transform': 'scaleY(-.75)',
			transform: 'scaleY(-.75)'
		},
		'100%': {
			'-moz-transform': 'scaleY(-1)',
			'-o-transform': 'scaleY(-1)',
			'-webkit-transform': 'scaleY(-1)',
			transform: 'scaleY(-1)'
		}
	},
	iconInvert: {
		'animation-name': 'invert',
		'animation-duration': '0.3s',
		'animation-timing-function': 'ease-in',
		'-o-transform': 'scaleY(-1)',
		'-webkit-transform': 'scaleY(-1)',
		transform: 'scaleY(-1)',
		filter: 'FlipV',
		'-ms-filter': 'FlipV'
	},
	icon: {
		'animation-name': 'normalize',
		'animation-duration': '0.3s',
		'animation-timing-function': 'ease-in'
		// '-moz-transform': 'scaleY(0)',
		// '-o-transform': 'scaleY(0)',
		// '-webkit-transform': 'scaleY(0)',
		// transform: 'scaleY(0)'
	}
})

export default styles
