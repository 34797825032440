export const SET_NC_AUDITS_STATE_LOADING = 'SET_NC_AUDITS_STATE_LOADING',
	SET_NC_AUDITS_DATA = 'SET_NC_AUDITS_DATA'

export const setLoading = (isLoading) => ({
	type: SET_NC_AUDITS_STATE_LOADING,
	isLoading
})

export const setData = (data) => ({
	type: SET_NC_AUDITS_DATA,
	data
})
