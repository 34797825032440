import functions from './functions'

/**
 * function for applying polyfill, it will import the functions array from ./functions/index.js,
 *  it will iterate and execute the item function
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 */
const applyPolyfill = () => {
	functions.forEach((item) => {
		if (item.function instanceof Function) return item.function()
	})
}

export default applyPolyfill
