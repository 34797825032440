import React from 'react'
import ResponseList from './ResponseList'

/**
 * @author davi spindola
 * @version 1.0
 *
 * @author Davi Wegner
 * @description added close action button as requested
 * @version 1.1
 * @param {*} props
 *
 * @author Davi Wegner
 * @description removed close action button (moved to details container)
 * @version 1.2
 * @param {*} props
 */
const View = (props) => {
	return (
		<div>
			<ResponseList />
		</div>
	)
}

export default View
