const INITIAL_STATE = {
	formData: {},
	saving: false
}

/**
 * Manages group form state
 *
 * @param {Object} state
 * @param {Object} action
 */
function groupFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_GROUP_FORM_SAVING': {
			return Object.assign({}, state, { saving: action.saving })
		}
		default:
			return state
	}
}

export default groupFormReducer
