import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Recaptcha from 'react-recaptcha'

import * as routes from '../../../constants/routes'
import { auth } from '../../../firebase'

import {
	withStyles,
	TextField,
	Button,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	Icon,
	Tooltip,
} from '@material-ui/core'
import { byPropKey } from '../utils'
import { compose } from 'recompose'
import { translate } from '../../../utils/lang'
import { AUTH_USER_SET } from '../../../reducers/session'
import { showSnackBar } from '../../../actions/snackbar'
import { deviceIsAndroid, deviceIsIos } from '../../../utils/env'
const CryptoJS = require('crypto-js')
require('dotenv').config()
/**
 * Manages sign in form data
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class SignInForm extends React.Component {
	state = {
		email: '',
		password: '',
		showPass: false,
		error: null,
		isVerified: false,
		fingerprint: false,
	}

	componentDidMount() {
		const { formData } = this.props
		let storage = window.localStorage

		let enableFingerprint = storage.getItem('fingerPrint')

		this.setState({ fingerprint: enableFingerprint })

		//When the form is refreshing from a trial
		if (!!formData && formData.email !== '') {
			return this.setState({ ...formData })
		}
	}

	enableFingerprint = () => {
		const { Fingerprint, localStorage } = window
		let storage = localStorage

		let emailEncrypted = storage.getItem('email')
		let passwordEncrypted = storage.getItem('password')
		let hexEmail = storage.getItem('@he')
		let hexPassword = storage.getItem('@hp')

		let bytesEmail = CryptoJS.AES.decrypt(emailEncrypted, hexEmail)
		let bytesPass = CryptoJS.AES.decrypt(passwordEncrypted, hexPassword)

		let emailDecrypted = bytesEmail.toString(CryptoJS.enc.Utf8)
		let passwordDecrypted = bytesPass.toString(CryptoJS.enc.Utf8)

		Fingerprint.show(
			{
				description: 'Autenticar com biometria',
			},
			success => {
				this.loginWithFirebase(emailDecrypted, passwordDecrypted)
			},
			error => {
				console.log('Authentication invalid ' + error.message)
			},
		)
	}

	loginWithFirebase = (email, password) => {
		const { history, notifyOnAuthUserSet, notifyOnError, clearSignInData } =
			this.props
		const { localStorage } = window
		let storage = localStorage

		auth.doSignInWithEmailAndPassword(email, password)
			.then(credential => {
				//When developing the system
				//Will print login data

				let hashEmail = CryptoJS.SHA3(email, { outputLength: 256 })
				let hashPassword = CryptoJS.SHA3(password, {
					outputLength: 256,
				})

				let hashHexEmail = hashEmail.toString(CryptoJS.enc.Hex)
				let hashHexPassword = hashPassword.toString(CryptoJS.enc.Hex)

				let emailEncrypted = CryptoJS.AES.encrypt(
					email,
					hashHexEmail,
				).toString()

				let passEncrypted = CryptoJS.AES.encrypt(
					password,
					hashHexPassword,
				).toString()

				storage.setItem('@he', hashHexEmail)
				storage.setItem('@hp', hashHexPassword)
				storage.setItem('email', emailEncrypted)
				storage.setItem('password', passEncrypted)

				if (process.env.REACT_APP_NODE_ENV === 'development') {
					console.log(
						'SignInForm:onSubmit:doSignInWithEmailAndPassword:user:uid {',
					)
					console.log('------------------------------------------')
					console.log('credential.user =>', credential.user)
					console.log('credential =>', credential)
					console.log('}')
					console.log('------------------------------------------')
				}
				//this.setState(() => ({ ...INITIAL_STATE }));
				notifyOnAuthUserSet(credential.user).then(() => {
					history.push(routes.PLUGIN)
					clearSignInData()
				})
			})
			.catch(error => {
				return notifyOnError(translate(error.code))
			})
	}

	onSubmit = event => {
		const { email, password, isVerified } = this.state
		const { storeSignInData } = this.props

		//Keeps login data for next trials
		storeSignInData({ email })

		// if (!isVerified && !this.isDevices()) return

		this.loginWithFirebase(email, password)

		event.preventDefault()
	}

	recaptchaLoaded = () => {
		console.log('captcha successfully loades')
	}

	verifyCallback = response => {
		if (response) {
			this.setState({
				isVerified: true,
			})
		}
	}

	handleClickShowPassword = () => {
		this.setState({ showPass: !this.state.showPass })
	}

	handleChange = name => event => {
		this.setState(byPropKey(name, event.target.value))
	}

	isDevices = () => {
		return deviceIsIos() || deviceIsAndroid()
	}

	render() {
		const { email, password, error, showPass, fingerprint } = this.state
		const { classes } = this.props
		const isInvalid = password === '' || email === ''

		return (
			<>
				<form
					onSubmit={this.onSubmit}
					className={classes.root}
					autoComplete="off">
					{error && <div>{error.message}</div>}
					<TextField
						id="email"
						label={translate('label/username')}
						value={email}
						type="email"
						onChange={this.handleChange('email')}
						fullWidth
						required
						className={classes.textField}
						margin="normal"
					/>
					<FormControl
						className={classes.textField}
						required
						margin="normal">
						<InputLabel htmlFor="password">
							{translate('label/password')}
						</InputLabel>
						<Input
							id="password"
							type={showPass ? 'text' : 'password'}
							value={password}
							onChange={this.handleChange('password')}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="Toggle password visibility"
										onClick={this.handleClickShowPassword}>
										<Tooltip
											title={translate(
												'label/change-show-password',
											)}
											placement="top">
											<Icon>
												{showPass
													? 'visibility'
													: 'visibility_off'}
											</Icon>
										</Tooltip>
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>

					{!isInvalid && !this.isDevices() && (
						<div className={classes.recaptcha}>
							<Recaptcha
								sitekey="6LcIv74UAAAAAKOI9Uc9w3nMD-lwJy0jw6x7bCED"
								verifyCallback={this.verifyCallback}
							/>
						</div>
					)}
					<div className={classes.signInButtons}>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							disabled={isInvalid}
							className={classes.button}
							type="submit">
							{translate('label/start')}
						</Button>
					</div>
				</form>
				{!!window.cordova && fingerprint && (
					<div
						onClick={this.enableFingerprint}
						className={classes.container}>
						<span
							style={{ fontSize: '40px', cursor: 'pointer' }}
							className="material-icons">
							fingerprint
						</span>
					</div>
				)}
			</>
		)
	}
}

const styles = theme => ({
	container: {
		margin: '20px 0',
		display: 'flex',
		justifyContent: 'center',
	},
	root: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
	},
	textField: {
		width: '100%',
	},
	signInButtons: {
		marginTop: '2em',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		width: '100%',
	},
	recaptcha: {
		display: 'flex',
		justifyContent: 'center',
	},
})

const mapStateToProps = state => ({
	formData: state.signInFormState.formData,
})

const mapDispatchToProps = dispatch => ({
	storeSignInData: formData =>
		dispatch({ type: 'STORE_SIGN_IN_DATA', formData }),
	clearSignInData: () => dispatch({ type: 'CLEAR_SIGN_IN_DATA' }),
	notifyOnAuthUserSet: async authUser =>
		dispatch({ type: AUTH_USER_SET, authUser }),
	notifyOnError: notification => dispatch(showSnackBar(notification)),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	withStyles(styles),
)(SignInForm)
