import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import SigInPage from '../signIn/SignInPage'
import PasswordResetPage from '../passwordReset/PasswordResetPage'
import ResetPass from '../resetPass'
import { SnackbarContainer } from '../../reusable/snackbar'
import ErrorBoundary from '../../reusable/error/ErrorBoundary'

const Redirect = (props) => {
	props.history.push(routes.SIGN_IN)
	return null
}
const OutsidePages = (props) => {
	return (
		<>
			<Router>
				<ErrorBoundary>
					<Switch>
						<Route
							exact={true}
							path={'/'}
							render={(history) => <Redirect {...history} />}
						/>
						<Route
							exact={true}
							path={routes.SIGN_IN}
							render={() => <SigInPage />}
						/>
						<Route
							exact={true}
							path={routes.PASSWORD_RESET}
							render={(history) => (
								<PasswordResetPage {...history} />
							)}
						/>
						<Route
							exact={true}
							path={routes.PASSWORD_RESET_LINK}
							render={(history) => <ResetPass {...history} />}
						/>
					</Switch>
				</ErrorBoundary>
			</Router>
			<SnackbarContainer />
		</>
	)
}

export default OutsidePages
