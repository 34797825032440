import {
	SET_FILTERBAR_IS_LOADING,
	RECEIVE_FILTERBAR_UNIT
} from '../../../actions/filterBar'

const INITIAL_STATE = {
	isLoading: false,
	filters: {}
}

//TODO: remove it or keep it
// export const CLEAR_FILTERBAR_FILTERS = 'CLEAR_FILTERBAR_FILTERS'

/**
 * Responsible for manage the isLoading to true
 *
 * @description Created the method
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 */
const setFilterbarFiltersIsLoading = (state, { isLoading }) =>
	Object.assign({}, state, {
		isLoading: isLoading
	})

//TODO: remove it or keep it
// /**
//  * Valid if object filters contains existent filter key
//  *
//  * @description Created the method
//  * @author davispindola
//  * @version 1.0
//  *
//  * @param {Object} state
//  * @param {Object} action
//  */
// const filterExists = (state, action) => {
// 	const { filterKey } = action,
// 		{ filters } = state

// 	return (
// 		Object.keys(filters).includes(filterKey) &&
// 		_.uniqWith(filters[filterKey], _.isEqual)
// 	)
// }

/**
 * Manage filters propertie inserting received values
 *
 * @description Created the method
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const receiveFilterBarUnit = (state, action) => {
	// IF VALUE EXIST WILL RETURN NOTHING
	// if (filterExists(state, { filterKey })) return

	return Object.assign({}, state, {
		filters: Object.assign({}, state.filters, {
			[action.data.filterKey]: action.data.values
		})
	})
}

//TODO: remove it or keep it?
// /**
//  * Reset filters to initial state
//  *
//  * @description Created the method
//  * @author davispindola
//  * @version 1.0
//  *
//  * @param {Object} state
//  */
// const clearFilterbarFilters = (state) =>
// 	Object.assign({}, state, {
// 		filters: INITIAL_STATE.filters
// 	})

/**
 * Filterbar state layer
 *
 * @description Created the reducer
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const filterBarReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_FILTERBAR_IS_LOADING:
			return setFilterbarFiltersIsLoading(state, action)
		case RECEIVE_FILTERBAR_UNIT:
			return receiveFilterBarUnit(state, action)
		// case CLEAR_FILTERBAR_FILTERS:
		// 	return clearFilterbarFilters(state)
		default:
			return state
	}
}

export default filterBarReducer
