export const PRODUCTIVITY_ITEM_LIST_LOADED = 'PRODUCTIVITY_ITEM_LIST_LOADED',
	SET_PRODUCTIVITY_ITEM_LIST_LOADING = 'SET_PRODUCTIVITY_ITEM_LIST_LOADING',
	PRODUCTIVITY_CONTRACTS_LIST_LOADED = 'PRODUCTIVITY_CONTRACTS_LIST_LOADED'

/**
 * [action-creator] for dispatching loaded **productivity data (items)**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} isLoading The items collection loading state
 */
export const setProductivityItemsLoading = (isLoading) => ({
	type: SET_PRODUCTIVITY_ITEM_LIST_LOADING,
	isLoading
})

/**
 * [action-creator] for dispatching loaded **productivity data (items)**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} items The items collection
 */
export const setProductivityItems = (items) => ({
	type: PRODUCTIVITY_ITEM_LIST_LOADED,
	items
})

/**
 * [action-creator] for dispatching loaded **productivity data (items)**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} items The items collection
 */
export const setProductivityContractsLoaded = (contracts) => ({
	type: PRODUCTIVITY_CONTRACTS_LIST_LOADED,
	contracts: contracts
})
