import { SET_USER_ACTION_SNAPSHOT } from '../../../actions/action/form'

const SET_ACTION_FORM_IS_SENDING = 'SET_ACTION_FORM_IS_SENDING',
	SET_ACTION_FORM_CARD_ID = 'SET_ACTION_FORM_CARD_ID',
	CLEAR_ACTION_FORM = 'CLEAR_ACTION_FORM',
	SET_ACTION_FORM_FETCHED_USERS = 'SET_ACTION_FORM_FETCHED_USERS',
	SET_ACTION_FORM_CC_RECEIVERS = 'SET_ACTION_FORM_CC_RECEIVERS',
	SET_ACTION_FORM_RECEIVER = 'SET_ACTION_FORM_RECEIVER',
	SET_ACTION_FORM_TYPE = 'SET_ACTION_FORM_TYPE',
	SET_ACTION_FORM_CLASSIFICATION = 'SET_ACTION_FORM_CLASSIFICATION',
	SET_ACTION_FORM_OBSERVATION = 'SET_ACTION_FORM_OBSERVATION',
	SET_ACTION_FORM_USERS_FETCHING = 'SET_ACTION_FORM_USERS_FETCHING',
	SET_ACTION_FORM_EXPECTED_DATE = 'SET_ACTION_FORM_EXPECTED_DATE',
	SET_CLOSE_INSPECTION_FORM = 'SET_CLOSE_INSPECTION_FORM',
	CLEAR_ELEMENTS_FORM = 'CLEAR_ELEMENTS_FORM'

const INITIAL_STATE = {
	cardId: null,
	isSending: false,
	isFetching: false,
	type: 0,
	classification: 0,
	fetchedUsers: [],
	observation: '',
	ccReceivers: null,
	receiver: null,
	expectedDate: '',
	snapshot: null,
	closeInspectionForm: false,
	isLoading: true
}

/**
 * Applies changes to the app state tree, adding a snapshot blob to it
 *
 * @description Created the function
 * @version 1.0
 * @author brunoteixeirasilva
 *
 * @param {Object} state The current app state tree
 * @param {Object} action The action with "type" and desired props
 */
const applyUserActionSnapshot = (state, action) =>
	Object.assign({}, state, { snapshot: action.snapshot })

/**
 * Manages the action-form state-fragment
 *
 * @description Created this reducer. Stolen docs. haha
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The state-fragment managed at this reducer
 * @param {Object} action The current action being performed
 * 
 * @description added point to close inspection from the light card
 * @author jeffersonguilhermemachadobarreto
 * @version 1.1
 *
 */
function actionFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CLEAR_ELEMENTS_FORM: {
			return Object.assign({}, state, {
				// observation: action.observation,
				// type: action.type,
				// classification: action.classification
				observation: '',
				type: 0,
				classification: 0
			})
		}
		case CLEAR_ACTION_FORM: {
			return Object.assign({}, state, INITIAL_STATE)
		}
		case SET_USER_ACTION_SNAPSHOT: {
			return applyUserActionSnapshot(state, action)
		}
		case SET_ACTION_FORM_CLASSIFICATION: {
			return Object.assign({}, state, {
				classification: action.classification
			})
		}
		case SET_ACTION_FORM_USERS_FETCHING: {
			return Object.assign({}, state, { isFetching: action.isFetching })
		}
		case SET_ACTION_FORM_FETCHED_USERS: {
			return Object.assign({}, state, {
				fetchedUsers: action.fetchedUsers
			})
		}
		case SET_ACTION_FORM_CC_RECEIVERS: {
			return Object.assign({}, state, {
				ccReceivers: action.ccReceivers
			})
		}
		case SET_ACTION_FORM_OBSERVATION: {
			return Object.assign({}, state, {
				observation: action.observation
			})
		}
		case SET_ACTION_FORM_RECEIVER: {
			return Object.assign({}, state, {
				receiver: action.receiver
			})
		}
		case SET_ACTION_FORM_TYPE: {
			return Object.assign({}, state, {
				type: action.formType
			})
		}
		case SET_ACTION_FORM_IS_SENDING: {
			return Object.assign({}, state, { isSending: action.isSending })
		}
		case SET_ACTION_FORM_CARD_ID: {
			return Object.assign({}, state, {
				cardId: action.cardId
			})
		}
		case SET_ACTION_FORM_EXPECTED_DATE: {
			return Object.assign({}, state, { expectedDate: action.date })
		}
		case SET_CLOSE_INSPECTION_FORM: {
			return Object.assign({}, state, {
				closeInspectionForm: action.closeInspectionForm
			})
		}
		case 'SET_LOADER': {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		default:
			return state
	}
}

export default actionFormReducer

export {
	CLEAR_ACTION_FORM,
	SET_ACTION_FORM_CARD_ID,
	SET_ACTION_FORM_CC_RECEIVERS,
	SET_ACTION_FORM_CLASSIFICATION,
	SET_ACTION_FORM_FETCHED_USERS,
	SET_ACTION_FORM_IS_SENDING,
	SET_ACTION_FORM_OBSERVATION,
	SET_ACTION_FORM_RECEIVER,
	SET_ACTION_FORM_TYPE,
	SET_ACTION_FORM_USERS_FETCHING,
	SET_ACTION_FORM_EXPECTED_DATE,
	SET_CLOSE_INSPECTION_FORM,
	CLEAR_ELEMENTS_FORM
}
