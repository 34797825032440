export const SET_OUTDATED_NON_COMPLIANCES_LOADING_STATE = 'SET_OUTDATED_NON_COMPLIANCES_LOADING_STATE',
	SET_OUTDATED_NON_COMPLIANCES_DATA = 'SET_OUTDATED_NON_COMPLIANCES_DATA',
	SET_OUTDATED_NON_COMPLIANCES_SELECTED_OBJECT = 'SET_OUTDATED_NON_COMPLIANCES_SELECTED_OBJECT',
	SET_OUTDATED_NON_COMPLIANCES_FILTER_DATA = 'SET_OUTDATED_NON_COMPLIANCES_FILTER_DATA',
	SET_OUTDATED_NON_COMPLIANCES_SEARCH_TEXT = 'SET_OUTDATED_NON_COMPLIANCES_SEARCH_TEXT',
	SET_OUTDATED_NON_COMPLIANCES_FILTER_VALUE = 'SET_OUTDATED_NON_COMPLIANCES_FILTER_VALUE',
	SET_FILTER_LOCAL_DATA = 'SET_FILTER_LOCAL_DATA'

export const setLoading = (isLoading) => ({
	type: SET_OUTDATED_NON_COMPLIANCES_LOADING_STATE,
	isLoading
})

export const setData = (data) => ({
	type: SET_OUTDATED_NON_COMPLIANCES_DATA,
	data
})

export const setSelectedObject = (id) => ({
	type: SET_OUTDATED_NON_COMPLIANCES_SELECTED_OBJECT,
	id
})

export const setFilterData = (key, filterData) => ({
	type: SET_OUTDATED_NON_COMPLIANCES_FILTER_DATA,
	filterData,
	key
})

export const setFilterLocalData = (key, filterData) => ({
	type: SET_FILTER_LOCAL_DATA,
	filterData,
	key
})

export const setFilterValue = (key, value) => ({
	type: SET_OUTDATED_NON_COMPLIANCES_FILTER_VALUE,
	key,
	value
})

export const setSearchText = (value) => ({
	type: SET_OUTDATED_NON_COMPLIANCES_SEARCH_TEXT,
	value
})
