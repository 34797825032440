import { SET_ROOT_DASH_ITEM_LOADING } from '../../actions/dashItem'
import { CLEAN_ON_COMPANY_CHANGE } from '../../actions/company/index.js'

//TODO: remove this from here, should be under action creators
const SINGLE_DASH_ITEM_LOADED = 'SINGLE_DASH_ITEM_LOADED',
	DASH_ITEMS_LOADED = 'DASH_ITEMS_LOADED'
const INITIAL_STATE = {
	dashItems: null,
	isLoading: null,
	reloadDashItemData: false,
	beforeIsExpanded: false
}

/**
 * Manages to set a dash-item loading, with an specific root **dashItemId**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 * @param {Object} action The action with "type" and desired props
 */
const setDashItemLoading = (state, action) =>
	Object.assign({}, state, {
		[action.dashItemId]: Object.assign({}, state[action.dashItemId], action.isLoading),
	})

// /**
//  * Manages to include data for an specific root **dashItemId**
//  *
//  * @description Created the function
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {Object} state The current app state tree
//  * @param {Object} action The action with "type" and desired props
//  */
// const dashItemLoaded = (state, action) =>
// 	Object.assign({}, state, {
// 		[action.dashItemId]: Object.assign(
// 			{},
// 			state[action.dashItemId],
// 			action.dashItem
// 		)
// 	})

/**
 * Adds a single dash Item at a time for less interruptions
 *
 * @param {Object} state
 * @param {action} action The action object with data
 */
const addSingleDashItem = (state, action) => {
	const dashSetKey = Object.keys(action.dashItem)[0]
	//Initially, kept items is the reference to the brought object
	let nextState,
		keptItems = action.dashItem

	//Manages to keep all previously defined dashItems at a specific dashset
	if (state.dashItems && Object.keys(state.dashItems).indexOf(dashSetKey) > -1) {
		keptItems = {
			[dashSetKey]: Object.assign({}, state.dashItems[dashSetKey], action.dashItem[dashSetKey]),
		}
	}

	nextState = Object.assign({}, state, {
		dashItems: Object.assign({}, state.dashItems, keptItems),
	})

	return nextState
}

/**
 * Manages dashItems state layer
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with a "type" and a "[property]" goal
 */
function dashItemReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_ROOT_DASH_ITEM_LOADING: {
			return setDashItemLoading(state, action)
		}
		//TODO: replace lower one with this
		// case SET_ROOT_DASH_ITEM: {
		// 	return dashItemLoaded(state, action)
		// }
		case DASH_ITEMS_LOADED: {
			return Object.assign({}, state, { dashItems: action.dashItems })
		}

		case 'RELOAD_DASHITEM_DATA': {
			return Object.assign({}, state, { reloadDashItemData: true })
		}

		case 'INITIAL_RELOAD_DASHITEM_DATA': {
			return Object.assign({}, state, { reloadDashItemData: false })
		}
		case 'BEFORE_IS_EXPANDED': {
			return Object.assign({}, state, { beforeIsExpanded: action.beforeIsExpanded })
		}
		//TODO: Rename this to the upper one
		case SINGLE_DASH_ITEM_LOADED: {
			return addSingleDashItem(state, action)
		}
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default dashItemReducer

export { SINGLE_DASH_ITEM_LOADED, DASH_ITEMS_LOADED }
