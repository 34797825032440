const INITIAL_STATE = {
	userKeys: []
}

/**
 * Adds an INDIVIDUAL company-user-key to the array of company-user-keys
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 */
const clearCompanyUserKeys = (state) =>
	Object.assign({}, state, { userKeys: INITIAL_STATE.userKeys })

/**
 * Adds an INDIVIDUAL company-user-key to the array of company-user-keys
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and desired props
 */
const companyUserKeyLoaded = (state, action) =>
	Object.assign({}, state, { userKeys: [...state.userKeys, action.userKey] })

/**
 * Manages company-user state layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state (can be INITIAL_STATE)
 * @param {Object} action The action with "type" and desired props
 */
function companyUserReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CLEAR_COMPANY_USER_KEYS': {
			return clearCompanyUserKeys(state)
		}
		case 'COMPANY_USER_KEY_LOADED': {
			return companyUserKeyLoaded(state, action)
		}
		default:
			return state
	}
}

export default companyUserReducer
