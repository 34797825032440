import React from 'react'
import { Tooltip, Card, CardContent, CardHeader, Icon, withTheme } from '@material-ui/core'
import classNames from 'classnames'
import { translate } from '../../../utils/lang'
import DashBoardIconButton from './icon/DashBoardIconButton'
import { deviceIsIos } from '../../../utils/env'
import { fs } from '../../../firebase/firebase'

/**
 * Arranges a pack of components for displaying a dash-item formatted card style
 *
 * @description Created the component
 * @author ??
 * @version 1.0
 *
 * @description Changed naming conventions
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} props
 *
 *
 * @description Created a conditional to render the icon in the mobile version and the desktop version
 * @author lopes
 * @version 1.2
 */
class DashboardCard extends React.Component {
	state = {
		isFavorite: false,
	}
	constructor(props) {
		super(props)
	}
	componentDidMount() {
		const { activeCompanyId, dashItemId, authUser, activePluginId, dashSet } = this.props
		if (!!authUser && !!activeCompanyId && !!dashItemId && !!activePluginId && !!dashSet) {
			this.monitoringFavoritesCard()
		}
	}

	monitoringFavoritesCard = () => {
		const { activeCompanyId, dashItemId, authUser, activePluginId, dashSet } = this.props

		const cardRef = fs
			.collection('companies')
			.doc(activeCompanyId)
			.collection('users')
			.doc(authUser)
			.collection('plugins')
			.doc(activePluginId)
			.collection('dashSets')
			.doc(dashSet)
			.collection('cards')
			.doc(dashItemId)

		cardRef.onSnapshot(doc => {
			this.setState({ isFavorite: doc.data().favorite ?? false })
		})
	}

	render() {
		const {
			//TODO: use or remove dashItemId from here
			//dashItemId,
			classes,
			title,
			handleOpen,
			handleTooltipOpen,
			handleTooltipClose,
			extendTooltip,
			theme,
			mobile,
			isSelected,
			handleFavoriteCard,
		} = this.props

		const { isFavorite } = this.state
		return (
			<Card
				{...this.props}
				className={classNames(classes.card, {
					mobile: isSelected,
					ios: deviceIsIos(),
				})} // className={)}
				elevation={0}>
				<CardHeader
					title={title}
					classes={{
						root: classes.title,
						action: classes.tooltipOpener,
					}}
					className={classes.cardHeader}
					titleTypographyProps={{
						variant: 'button',
						style: {
							fontSize: '.9em',
							color: theme.palette.primary.main,
						},
					}}
					action={
						//TODO: CHANGE THIS TO A ACTUAL COMPONENT, REALLYYYYY IMPORTANT!!!, LOOK AT THIS
						!mobile && (
							<>
								<Tooltip
									id="tooltip-controlled"
									enterDelay={0}
									leaveDelay={300}
									enterTouchDelay={100}
									leaveTouchDelay={300}
									placement="bottom"
									title={'Favorito'}>
									{isFavorite ? (
										<Icon style={{ color: 'gold' }} className={classes.tooltipIcon} onClick={handleFavoriteCard}>
											star
										</Icon>
									) : (
										<Icon className={classes.tooltipIcon} onClick={handleFavoriteCard}>
											star_border
										</Icon>
									)}
								</Tooltip>
								<Tooltip
									id="tooltip-controlled"
									enterDelay={0}
									leaveDelay={300}
									enterTouchDelay={100}
									leaveTouchDelay={300}
									// onClose={() =>
									// 	handleTooltipClose('infoTooltip')
									// }
									// onOpen={() => handleTooltipOpen('infoTooltip')}
									// open={infoTooltip}
									placement="bottom"
									title={translate('label/what-am-i-seeing')}>
									<Icon className={classes.tooltipIcon} onClick={() => handleOpen('info')}>
										help_outline
									</Icon>
								</Tooltip>
							</>
						)
					}
				/>

				<CardContent
					className={classNames(classes.cardContent, {
						mobile: mobile,
						ios: deviceIsIos(),
					})}>
					{this.props.children}
				</CardContent>
				{!mobile && (
					<div className={classes.cardFooter}>
						<Tooltip
							enterDelay={0}
							id="tooltip-controlled"
							leaveDelay={300}
							enterTouchDelay={100}
							leaveTouchDelay={300}
							onClose={() => handleTooltipClose('extendTooltip')}
							onOpen={() => handleTooltipOpen('extendTooltip')}
							open={extendTooltip}
							placement="bottom"
							title={translate('label/expand')}>
							<Icon className={classes.extendedIcon} onClick={() => handleOpen('extend', true)}>
								fullscreen
							</Icon>
						</Tooltip>
					</div>
				)}
			</Card>
		)
	}
}

export default withTheme()(DashboardCard)
