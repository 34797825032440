import React from 'react'
import { connect } from 'react-redux'

import { translate } from '../../../../../utils/lang'
import { DialogTypeList } from '../../../dialog/type/list'
import { actionOpenPopup } from '../../../../../reducers/systemEvaluation/popup'
import { setEvaluationDialogOpen } from '../../../../../reducers/systemEvaluation/dialog'
import { compose } from 'recompose'

/**
 * Popup used for rating actions quality after use close an action
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 */
class EvaluationPopup extends React.Component {
	onClose = () => {
		const { closeEvaluationPopup } = this.props
		closeEvaluationPopup(false)
	}

	handleOpenEvaluationDialog = () => {
		const { openEvaluationDialog, closeEvaluationPopup } = this.props

		closeEvaluationPopup(false).then(() => openEvaluationDialog())
	}

	render() {
		const { isOpen } = this.props

		return (
			<div>
				<DialogTypeList
					open={isOpen}
					title={translate('title/evaluate-action') + '?'}
					list={[
						{
							icon: 'thumb_up',
							optionKey: 'accept-evaluation',
							optionValue: translate('label/yes'),
							onClick: this.handleOpenEvaluationDialog
						},
						{
							icon: 'schedule',
							optionKey: 'reject-evaluation',
							optionValue: translate('label/later-on'),
							onClick: this.onClose
						}
					]}
					keyProp="optionKey"
					valueProp="optionValue"
					onClose={this.onClose}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	isOpen: state.evaluationPopupState.isOpen
	// actionId: state.actionsState.actionId
})

const mapDispatchToProps = (dispatch) => ({
	openEvaluationDialog: () => dispatch(setEvaluationDialogOpen()),
	closeEvaluationPopup: async (isOpen) => dispatch(actionOpenPopup(isOpen))
})

export default compose(
	// withSuperUserAccess,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(EvaluationPopup)
