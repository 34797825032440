import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
	withStyles,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core'

import { setDialogToClosed, setDialogToOpened } from '../../actions/alert'
import { translate } from '../../../../../utils/lang'

/**
 * Dialog Alert is responsible for notify user when occur problems in application and this problems can't be solved.
 * The user has the option of send an email to suporte email.
 *
 * @author davispindola
 * @version 1.0.0
 *
 * @description Changed error description
 * @author brunoteixeirasilva
 * @version 1.1
 */
class DialogTypeAlert extends React.Component {
	componentDidMount() {
		const { handleOpen } = this.props
		handleOpen()
	}

	render() {
		const { dialogIsOpen, handleClose } = this.props

		return (
			<Dialog onClose={handleClose} open={dialogIsOpen}>
				<DialogTitle>{translate('title/system-error')}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{translate('description/error-caught-email-sent')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleClose}>
						{translate('label/close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = theme => ({})

const mapStateToProps = state => ({
	dialogIsOpen: state.dialogAlertState.dialogIsOpen,
})

const mapDispatchToProps = dispatch => ({
	handleClose: () => dispatch(setDialogToClosed()),
	handleOpen: () => dispatch(setDialogToOpened()),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
)(DialogTypeAlert)
