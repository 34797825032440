import * as CompanyPluginFilters from '../../firebase/company/plugin/filters'
import { store } from '../../store'
import {
	makeGetGlobalPluginFilters,
	makeGetGlobalFilterValues
} from '../../selectors/filter'
import { makeGetUserActiveCompanyId } from '../../selectors/user/company'
import { makeGetActivePluginId } from '../../selectors/plugin'
import { onSetGlobalPluginFilters } from '../../component/reusable/filter/functions/onSetGlobalPluginFilters'
import { getFrom } from '../cloud/functions/generic'
import { makeGetCompanyPluginDataSourceById } from '../../selectors/company/plugin'
import { getDataSourceId } from '../../firebase/company/plugin'

/**
 * Finds the filters sequence from state,
 * if not yet present, queries it
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const resolveFilters = async () => {
	const getPluginFilters = makeGetGlobalPluginFilters(),
		getCompanyId = makeGetUserActiveCompanyId(),
		getPluginId = makeGetActivePluginId()

	let state = store.getState(),
		companyId = getCompanyId(state),
		pluginId = getPluginId(state),
		pluginFilters = getPluginFilters(state)

	//Filters already present, use it directly
	if (!!pluginFilters) return pluginFilters

	//Have to query, haven't found 'em
	return CompanyPluginFilters.get(companyId, pluginId)
		.then((snapshot) => onSetGlobalPluginFilters(snapshot.docs))
		.then(() => {
			//Updates the state tree copy
			state = store.getState()

			return Promise.resolve(getPluginFilters(state))
		})
}

// export const resolveFilters = (allowedFilters) => {
// 	const getPluginFilters = makeGetGlobalPluginFilters(),
// 		getCompanyId = makeGetUserActiveCompanyId(),
// 		getPluginId = makeGetActivePluginId()

// 	let state = store.getState(),
// 	companyId = getCompanyId(state),
// 	pluginId = getPluginId(state)

// 	return CompanyPluginFilters.get(companyId, pluginId).then((snapshot) =>
// 		onSetGlobalPluginFilters(snapshot.docs)
// 	)
// }

/**
 * Search for filters in firebase and preserve ids based in preloaded keys
 *
 * @description Created the method
 * @author davispindola
 * @version 1.0
 *
 * @param {String} allowedFilters
 * @param {Object} preloadedFilters
 * @param {Function} callback
 */
// export const preserveFilterKeyIds = async (
// 	activeCompanyId,
// 	preloadedFilters,
// 	callback
// ) => {
// 	// get a list of keys based on preloaded filters
// 	const preloadedKeys = Object.keys(preloadedFilters).map((key) => {
// 		return preloadedFilters[key]['key']
// 	})

// 	let filterKeys = []

// 	// get all keys from firebase
// 	await Filters.getAll(activeCompanyId).then((snapshot) => {
// 		let keys = []
// 		snapshot.forEach((filter) => {
// 			const { key } = filter.data()
// 			keys.push({ key, id: filter.id })
// 		})

// 		return (filterKeys = keys)
// 	})

// 	// filter list for each key in filterKeys, preserve the ids of keys included in preloaded filters
// 	const list = filterKeys.filter(({ key, id }) => {
// 		if (preloadedKeys.includes(key)) {
// 			if (process.env.NODE_ENV === 'development') {
// 				console.log(id)
// 			}
// 			return { key, id }
// 		}
// 	})

// 	return list
// }

/**
 * Responsible for search options of especified keys in firebase.
 *
 * @description Created the method
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} filter
 * @param {String} allowedFilters
 * @param {Function} callback
 */
export const searchForOptions = async (filterKey, filter, callback) => {
	let receivedOptions = [],
		filterUnitValues,
		pluginId,
		dataSource

	console.log('===================================')
	console.log('searching for options (filters)')
	console.log('filterKey: ' + filterKey)
	console.log('===================================')

	const state = store.getState(),
		getFilterValues = makeGetGlobalFilterValues(),
		getPluginId = makeGetActivePluginId(),
		getDataSourceId = makeGetCompanyPluginDataSourceById(),
		filterValues = getFilterValues(state),
		filterValuesKeys = Object.keys(filterValues)

	if (
		filterValuesKeys.length === 0 ||
		!filterValuesKeys.includes(filterKey)
	) {
		return callback({ filterKey: filterKey, values: [] })
	}

	// await Filters.getByKey(activeCompanyId, key.id).then((snapshot) => {
	// 	snapshot.forEach((item) => {
	// 		receivedOptions.push(item.data())
	// 	})
	// })

	//Getting query crucial info
	pluginId = getPluginId(state)
	dataSource = getDataSourceId(state, pluginId)

	//Picks the filter unit to be used
	filterUnitValues = filterValues[filterKey]

	//Will get data from the filter data-source and data-set using key values
	return getFrom(dataSource, filter.dataSet, { key: filterUnitValues }).then(
		(res) => callback({ filterKey: filterKey, values: res.data })
	)
}
