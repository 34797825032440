/**
 * Defines a default dateConfig factory class
 *
 * @description Created the class
 * @author brunoteixeirasilva
 * @version 1.0
 */
class DateConfig {
	// /**
	//  * Stores a "component" information at the instance
	//  *
	//  * @type {React.Component}
	//  */
	// component = null
	// /**
	//  * Stores a date "from" information at the instance
	//  *
	//  * @type {string}
	//  */
	// from = null
	// /**
	//  * Stores a date "to" information at the instance
	//  *
	//  * @type {string}
	//  */
	// to = null
	// /**
	//  * Stores a days count difference information at the instance
	//  *
	//  * @type {number}
	//  */
	// intervalDays = null
	// /**
	//  * Stores a string "selected" information at the instance
	//  *
	//  * @type {string}
	//  */
	// selected = null
	// /**
	//  * Creates the instance of the DateConfig object
	//  *
	//  * @description Created the constructor
	//  * @author brunoteixeirasilva
	//  * @version 1.0
	//  *
	//  * @param {string} component Used for dialog - The component which has 2 range pickers
	//  * @param {string|Date} from ISO or Date "from" for range
	//  * @param {string|Date} to ISO or Date "to" for range
	//  * @param {string} selected The selected label name
	//  * @param {number} intervalDays The days count of the interval
	//  */
	// constructor(
	// 	component = null,
	// 	from = new Date(),
	// 	to = new Date(),
	// 	selected = translate('label/today')
	// ) {
	// 	//No supper class, no super invokes
	// 	//super(this)
	// 	//Sets each object variable
	// 	this.component = component
	// 	this.from = from instanceof Date ? Dates.getISODate(from) : from
	// 	this.to = to instanceof Date ? Dates.getISODate(to, true) : to
	// 	this.selected = selected
	// 	this.intervalDays = 0
	// 	//When range is available
	// 	if (!!this.from && !!this.to) {
	// 		//Calculates the difference in days
	// 		this.intervalDays = Math.floor(
	// 			Dates.getDaysDifferenceFromMiliseconds(
	// 				new Date(this.from).getTime(),
	// 				new Date(this.to).getTime()
	// 			)
	// 		)
	// 	}
	// }
	// /**
	//  * Provides access to "component" information at the instance
	//  */
	// getComponent = () => this.component
	// /**
	//  * Provides access to "from" information at the instance
	//  */
	// getFrom = () => this.from
	// /**
	//  * Provides access to "to" information at the instance
	//  */
	// getTo = () => this.to
	// /**
	//  * Provides access to "intervalDays" information at the instance
	//  */
	// getIntervalDays = () => this.intervalDays
	// /**
	//  * Provides access to "selected" information at the instance
	//  */
	// getSelected = () => this.selected
	// /**
	//  * Sets a "component" information at the instance
	//  *
	//  * @param {React.Component} component The component reference to be set
	//  */
	// setComponent = (component) => {
	// 	this.component = component
	// }
	// /**
	//  * Sets a "from" information at the instance
	//  *
	//  * @param {string} to The date "from" reference to be set
	//  */
	// setFrom = (from) => {
	// 	this.from = from
	// }
	// /**
	//  * Sets a "to" information at the instance
	//  *
	//  * @param {string} to The date "to" reference to be set
	//  */
	// setTo = (to) => {
	// 	this.to = to
	// }
	// /**
	//  * Sets a "intervalDays" information at the instance
	//  *
	//  * @param {string} intervalDays The interval days count reference to be set
	//  */
	// setIntervalDays = (intervalDays) => {
	// 	this.intervalDays = intervalDays
	// }
	// /**
	//  * Sets a "selected" information at the instance
	//  *
	//  * @param {string} selected The string "selected" reference to be set
	//  */
	// setSelected = (selected) => {
	// 	this.selected = selected
	// }
}

export default DateConfig
