import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, Toolbar, AppBar, Tabs } from '@material-ui/core'

import { compose } from 'recompose'
import { deviceIsIos } from '../../../utils/env'
// import memoize from '../../../utils/memoize'
// import { defaultMemoize } from 'reselect'
// import AgilTab from './unit/AgilTab'
import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'

const styles = (theme) => ({
	appBar: {
		top: 0,
		...getDesktopBreakpoint(theme, {
			top: theme.spacing.unit * 8
		}),
		zIndex: 1099,
		//FIXME: remove this old setting backgroundColor: '#5c9fdb',
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrastText,
		display: 'flex',
		flexDirection: 'row',
		'& > .tabsLeft': {
			justifySelf: 'space-between'
		},
		'& > .toolbarRight': {
			justifySelf: 'space-between'
		}
	},
	appBarIos: {
		top: 0,
		...getDesktopBreakpoint(theme, {
			top: `calc(${theme.spacing.unit * 8}px + env(safe-area-inset-top))`
		}),
		// paddingLeft: 0,
		paddingTop: `calc(${theme.spacing.unit *
			1}px + env(safe-area-inset-top))`,
		paddingLeft: 'env(safe-area-inset-left)',
		// paddingRight: 0,
		paddingRight: 'env(safe-area-inset-right)'
		// top: `calc(${theme.spacing.unit * 7}px + env(safe-area-inset-top))`,
		// [theme.breakpoints.up('sm')]: {
		// 	top: `calc(${theme.spacing.unit * 8}px + env(safe-area-inset-top))`
		// }
		// [theme.breakpoints.up('sm')]: {
		// 	top: `calc(${theme.spacing.unit * 8}px + env(safe-area-inset-top))`
		// }
	},
	tabsLeft: {
		flex: '0 0 auto'
		// alignSelf: 'flex-start'
	},
	toolbarRight: {
		flex: '0 0 auto',
		minHeight: 'auto',
		marginLeft: 'auto',
		paddingRight: theme.spacing.unit
		// alignSelf: 'flex-start'
	}
})
/**
 * Renders a system-wide default tab-system
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed location and namings
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added support for iOS sceneries
 * @author brunoteixeirasilva
 * @version 1.2
 */
class AgilTabs extends React.Component {
	render() {
		const {
			classes,
			handleChange,
			activeTabIndex,
			tabs,
			buttons
		} = this.props

		return (
			<AppBar
				position="fixed"
				className={classNames(classes.appBar, {
					[classes.appBarIos]: deviceIsIos()
				})}
			>
				{!!tabs && tabs.length > 0 && (
					<Tabs
						value={activeTabIndex}
						onChange={handleChange}
						className={classes.tabsLeft}
					>
						{tabs.map((Tb, index) =>
							React.cloneElement(Tb, { key: index })
						)}
					</Tabs>
				)}
				{!!buttons && buttons.length > 0 && (
					<Toolbar className={classes.toolbarRight}>
						{buttons.map((Btn, index) =>
							React.cloneElement(Btn, { key: index })
						)}
					</Toolbar>
				)}
			</AppBar>
		)
	}
}

AgilTabs.propTypes = {
	classes: PropTypes.object.isRequired,
	activeTabIndex: PropTypes.number.isRequired,
	handleChange: PropTypes.func,
	tabs: PropTypes.array,
	buttons: PropTypes.array
}

// const makeMapProps = () => {
// 	const mapByFilter = defaultMemoize((list, filterPredicate) =>
// 			list.filter(filterPredicate)
// 		),
// 		mapProps = (ownProps) => {
// 			const tabs = mapByFilter(ownProps.children, (item) => {
// 					return item instanceof Tab
// 				}),
// 				nonTabs = mapByFilter(
// 					ownProps.children,
// 					(item) => item instanceof Button
// 				)

// 			return {
// 				nonTabs: nonTabs,
// 				tabs: tabs,
// 				hasManyTypes: nonTabs.length > 0 && tabs.length > 0
// 			}
// 		}

// 	return mapProps
// }

export default compose(
	withStyles(styles)
	// withProps(makeMapProps())
)(AgilTabs)
