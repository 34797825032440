import {
    MANAGE_CARDIFP_LOADING_STATE,
    LOAD_CARDIFP_DATA,
    SET_CARDIFP_PERIOD
} from '../../actions/cardifp'

const INITIAL_STATE = {
	isLoading: null,
    localFilters: null,
	data: null,
	dateConfig: {},
	ownFilters: {},
    searchText: {},
    idLocalFilter: {
        equipments: null
    }
}

const clearAllLocalFilters = (state) => {
    let keys = Object.keys(state.idLocalFilter),
        clearFilters = {}

    keys.forEach((key) => {
        clearFilters[key] = null
    })

    return clearFilters
}

/**
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const cardifpReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_CARDIFP_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_CARDIFP_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case SET_CARDIFP_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        case 'SET_CARDIFP_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_CARDIFP_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_CARDIFP_FILTER_VALUE': {
            
			return Object.assign({}, state, {
				ownFilters: {},
                idLocalFilter: clearAllLocalFilters(state)
			})
		}
        case 'SET_LOCAL_FILTER_CARDIFP': {
			return Object.assign({}, state, {
				localFilters: action.localFilters
			})
		}
        case 'SET_SEARCH_TEXT_CARDIFP': {
            let searchText = {}
            action.objectKeys.forEach((item) => {
                searchText[item] = ''
            })
            return Object.assign({}, state, {
                searchText: searchText
            })
        }
        case 'SET_ID_LOCAL_FILTERS_CARDIFP': {
            return Object.assign({}, state, {
                idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter)
            })
        }
        default:
            return state
    }
}

export default cardifpReducer
