export const MANAGE_RESPONSIBLE_NC_LOADING_STATE =
    'MANAGE_RESPONSIBLE_NC_LOADING_STATE',
    LOAD_RESPONSIBLE_NC_DATA = 'LOAD_RESPONSIBLE_NC_DATA',
    SET_RESPONSIBLE_NC_PERIOD = 'SET_RESPONSIBLE_NC_PERIOD'

export const setResponsibleNcPeriod = (dateConfig, value, formType) => ({
    type: SET_RESPONSIBLE_NC_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadResponsibleNcData = (responsibleNc) => ({
    type: LOAD_RESPONSIBLE_NC_DATA,
    responsibleNc
})

export const setLoading = (isLoading) => ({
    type: MANAGE_RESPONSIBLE_NC_LOADING_STATE,
    isLoading
})
