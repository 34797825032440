export const MANAGE_RESULT_CHECK_LIST_PLAN_LOADING_STATE = 'MANAGE_RESULT_CHECK_LIST_PLAN_LOADING_STATE',
    LOAD_RESULT_CHECK_LIST_PLAN_DATA = 'LOAD_RESULT_CHECK_LIST_PLAN_DATA',
    SET_RESULT_CHECK_LIST_PLAN_PERIOD = 'SET_RESULT_CHECK_LIST_PLAN_PERIOD'

export const setResultChecklistPlanPeriod = (dateConfig, value, formType) => ({
    type: SET_RESULT_CHECK_LIST_PLAN_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadResultChecklistPlanData = (data) => ({
    type: LOAD_RESULT_CHECK_LIST_PLAN_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_RESULT_CHECK_LIST_PLAN_LOADING_STATE,
    isLoading
})
