import React from 'react'
import PropTypes from 'prop-types'
import AgilBadge from '../agilBadge/AgilBadge'

/**
 * Managing badge rendering
 *
 * @description created the component
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Added support for newly added AgilBadge
 * @author brunoteixeirasilva
 * @version 1.1
 */
const Pointer = ({ isAlert, badge, color, children }) => {
	return badge ? (
		<AgilBadge
			isAlert={isAlert}
			color={color}
			badgeContent={badge}
			children={children}
		/>
	) : (
		children
	)
}

Pointer.defaultProps = {
	color: 'primary',
	badge: '',
	isAlert: false
}

Pointer.propTypes = {
	badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	classes: PropTypes.object,
	color: PropTypes.string,
	isAlert: PropTypes.bool
}

export default Pointer
