import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import withWidth from '@material-ui/core/withWidth'
import { Grid, List } from '@material-ui/core'
import { compose } from 'recompose'

/**
 * Manages to display an infinite (Buzz Light-Year) list
 * which is an scrollable on-demand items loader (performance requirement)
 *
 * ? Can display a Grid or List component, based on "grid" prop (default is true)
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added support for Grid or List components
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added withPullDownToRefresh to the list
 * @author daviwegner
 * @version 1.2
 */
class BuzzList extends React.Component {
	state = {
		currentLength: 0
	}
	widthBasedLength = {
		grid: {
			xs: 6,
			sm: 8,
			md: 10,
			lg: 15,
			xl: 24
		},
		list: {
			xs: 15,
			sm: 20,
			md: 20,
			lg: 25,
			xl: 25
		}
	}

	componentDidMount() {
		const { items, width, grid } = this.props,
			{ currentLength } = this.state,
			type = !grid ? 'list' : 'grid'		

		//When the current length is still zero,
		//Will set the first occurence
		if (currentLength === 0) {
			this.setState({
				currentLength:
					items.length >= this.widthBasedLength[type][width]
						? this.widthBasedLength[type][width]
						: items.length
			})
		}
	}

	next = () => {
		const { items, width, grid } = this.props,
			{ currentLength } = this.state,
			type = !grid ? 'list' : 'grid'

		//Next size will be:
		//Current-length, in case it's already at maximum load
		//Or current-length + increment, in case it's not yet maximised
		let nextSize =
			currentLength >= items.length
				? currentLength
				: currentLength + this.widthBasedLength[type][width]

		//Changes the current list-size
		this.setState({ currentLength: nextSize })
	}

	render() {
		const {
				grid,
				items,
				mapper,
				style,
				threshold,
				theme,
				width,
				children,
				disablePadding
			} = this.props,
			{ currentLength } = this.state,
			type = !grid ? 'list' : 'grid',
			dataLength =
				currentLength > 0 &&
				currentLength > this.widthBasedLength[type][width]
					? currentLength
					: this.widthBasedLength[type][width]

		return (
			<InfiniteScroll
				style={style}
				dataLength={dataLength}
				// dataLength={isWidthUp('xs', width) ? 16 : limiteMax}
				next={this.next}
				// hasMore={true}
				hasMore={dataLength < items.length}
				scrollThreshold={threshold}
			>
				{!!children && (
					//Will expose a Grid component
					<React.Fragment>
						{this.props.children}
						{/* {items.slice(0, dataLength).map(mapper)} */}
					</React.Fragment>
				)}
				{!children && !!grid && (
					//Will expose a Grid component
					<Grid container spacing={theme.spacing.unit}>
						{items.slice(0, dataLength).map(mapper)}
					</Grid>
				)}
				{!children && !grid && (
					//Will expose a List component
					<List disablePadding={disablePadding}>

						{items.slice(0, dataLength).map(mapper)}
					</List>
				)}
			</InfiniteScroll>
		)
	}
}

BuzzList.defaultProps = {
	// currentLength: 0,
	disablePadding: undefined,
	grid: true,
	items: [],
	// mapper: (item, index) => item,
	// maxLength: 0,
	// more: null,
	style: {
		overflow: 'visible'
	},
	threshold: 0.86
}

BuzzList.propTypes = {
	disablePadding: PropTypes.bool,
	grid: PropTypes.bool,
	items: PropTypes.array.isRequired,
	mapper: PropTypes.func.isRequired
}

export default compose(withWidth({ withTheme: true }))(BuzzList)
