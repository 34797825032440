export const SET_EVALUATION_REASONS = 'SET_EVALUATION_REASONS',
	CLEAR_EVALUATION_REASONS = 'CLEAR_EVALUATION_REASONS',
	PREAPRE_EVALUATION_REASON_REQUEST = 'PREAPRE_EVALUATION_REASON_REQUEST',
	RECEIVE_EVALUATION_REASON_RESPONSE = 'RECEIVE_EVALUATION_REASON_RESPONSE',
	REMOVE_EVALUATION_REASON_IS_LOADING = 'REMOVE_EVALUATION_REASON_IS_LOADING'

const INITIAL_STATE = {
	reasons: {},
	isLoading: []
}

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {string} reasonType
 */
export const prepareReasonRequestAction = (reasonType) => ({
	type: PREAPRE_EVALUATION_REASON_REQUEST,
	reasonType
})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {string} reasonType
 */
export const removeReasonRequestAction = (reasonType) => ({
	type: REMOVE_EVALUATION_REASON_IS_LOADING,
	reasonType
})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {string} key
 * @param {Array} reasons
 */
export const receiveEvaluationReasonsAction = (key, reasons = []) => ({
	type: RECEIVE_EVALUATION_REASON_RESPONSE,
	key,
	reasons
})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const prepareRequest = (state, action) => ({
	...state,
	isLoading: [...state.isLoading, action.reasonType]
})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const removeRequest = (state, action) => ({
	...state,
	isLoading: state.isLoading.filter((item) => item !== action.reasonType)
})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const receiveReasons = (state, action) =>
	Object.assign({}, state, {
		reasons: Object.assign({}, state.reasons, {
			[action.key]: action.reasons
		})
	})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const evaluationReasonsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PREAPRE_EVALUATION_REASON_REQUEST: {
			return prepareRequest(state, action)
		}
		case REMOVE_EVALUATION_REASON_IS_LOADING: {
			return removeRequest(state, action)
		}
		case RECEIVE_EVALUATION_REASON_RESPONSE: {
			return receiveReasons(state, action)
		}
		default: {
			return state
		}
	}
}

export default evaluationReasonsReducer
