import DateConfig from '../../models/date/config'

const INITIAL_STATE = new DateConfig()

/**
 * 	Clean date config
 */
const dateCleared = (state, action) => ({
	...state,
	...INITIAL_STATE
})

const setDateConfig = (state, action) => {
	return Object.assign({}, state, action.dateConfig)
}

/**
 * 	sets the date period that has to been shown on app, with from and to properties
 * 	@author Wegner
 */
function dateReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_DATE_PERIOD': {
			return setDateConfig(state, action)
		}
		case 'CLEAR_DATE_PERIOD': {
			return dateCleared(state, action)
		}
		default:
			return state
	}
}
export default dateReducer
