import { fs } from '../../firebase'

/**
 * github user
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible for set snpashot url path in action for better image src search.
 *
 * @param {Strin} activeCompanyId active company id
 * @param {String} actionId action id
 * @param {String} url snapshot image url in cloud storage
 */
export const setActionSnapshotUrl = (
	activeCompanyId,
	actionId,
	snapshotURL
) => {
	const action = fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.doc(actionId)

	return action.set({ snapshotURL: snapshotURL }, { merge: true })
}

/**
 * @author Davi Wegner
 */

export const setViewActionState = (activeCompanyId, actionId, type, value) => {
	fs.collection('companies')
		.doc(activeCompanyId)
		.collection('actions')
		.doc(actionId)
		.update({ [type]: value })
}
