import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'

const StyleAvaliation = theme => ({
	digital: {
		padding: '8px 0',
	},
	container: {
		paddingTop: '64px',
	},
	containerCard: {
		marginLeft: '	5px',
		marginRight: '5px',
		boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
		textAlign: 'center',
		border: '1px solid #FFF',
		paddingLeft: '10px',
		paddingRight: '10px',
		borderRadius: '10px',
		maxWidth: '700px',
		backgroundColor: '#FFF',

		...getDesktopBreakpoint(theme, {
			boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
			textAlign: 'center',
			border: '1px solid #FFF',
			paddingLeft: '10px',
			paddingRight: '10px',
			borderRadius: '10px',
			margin: '10px auto',
			maxWidth: '700px',
			backgroundColor: '#FFF',
		}),
	},

	badges: {
		margin: '12px 8px 12px 8px',
	},

	botaoBadge: {
		padding: '0px',
	},
	nome: {
		marginTop: '5px',
		fontSize: '24px',
		fontWeight: 'bold',
	},
	nomeEmail: {
		color: '#716969',
		fontSize: '18px',
	},

	// hr: {
	// 	margin: '13px 0',
	// },

	badge: {
		transform: 'scale(1) translate(-30%, 30%)',
		backgroundImage: 'linear-gradient(to bottom, #ce93d8, #4a148c)',
	},

	sliderMelhoria: {
		alignContent: 'center',
		opacity: '3',
		width: '100%',
	},

	sliderElogio: {
		alignContent: 'center',
		opacity: '3',
		width: '100%',
	},

	icone: {
		width: '24px',
		height: '24px',
		margin: '0px 5px -3px 5px',
	},

	comentarios: {
		fontFamily: 'cursive',
	},

	cardComentario: {
		padding: '10px',
		minHeight: '100px',
		display: 'grid',
		borderLeftWidth: 'thick',
		borderStyle: 'outset',
		borderColor: '#eeeeee',
	},

	headerAccount: {
		padding: '8px 0px',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},

	avaliacao: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: '1.25rem',
		fontWeight: '900',
		lineHeight: '1.6',
		margin: '0',
	},

	infosExtras: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: '1.25rem',
		fontWeight: '900',
		lineHeight: '1.6',
		marginBottom: '10px',
	},

	avatar: {
		width: '70px',
		height: '70px',
		borderRadius: '50%',
		margin: '6px 15px',
	},

	h1: {
		width: '100%',
		fontSize: '135%',
	},

	h3: {
		width: '100%',
		fontSize: '115%',
	},

	hr: {
		margin: '13px 0',
		border: 'none',
		borderBottom: '1px solid #cdcdcd',
		width: '100%',
	},
})

export default StyleAvaliation
