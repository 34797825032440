/**
 * Checks whether the current enviroment is Cordova based (Cordova at window)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const isCordovaEnvironment = () =>
	!!window.cordova && !!window.cordova.platformId

/**
 * Checks whether the current enviroment is iOS based (Cordova required)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const deviceIsIos = () =>
	!!isCordovaEnvironment() && window.cordova.platformId === 'ios'

/**
 * Checks whether the current enviroment is Android based (Cordova required)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const deviceIsAndroid = () =>
	!!isCordovaEnvironment() && window.cordova.platformId === 'android'

/**
 * Checks whether a connection is available or not, by pinging
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const ping = ({ url, timeout }) => {
	return new Promise((resolve) => {
		const isOnline = () => resolve(true)
		const isOffline = () => resolve(false)
		const xhr = new XMLHttpRequest()
		xhr.onerror = isOffline
		xhr.ontimeout = isOffline
		xhr.onreadystatechange = () => {
			if (xhr.readyState === xhr.HEADERS_RECEIVED) {
				if (xhr.status >= 200 && xhr.status < 400) {
					isOnline()
				} else {
					isOffline()
				}
			}
		}
		xhr.open('HEAD', url)
		xhr.timeout = timeout
		xhr.send()
	})
}
