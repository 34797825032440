import { createSelector } from 'reselect'

const getRootDashItemsSteps = (state, dashItemId) =>
	!!state.dashItemStepState.steps &&
	state.dashItemStepState.steps.hasOwnProperty(dashItemId)
		? state.dashItemStepState.steps[dashItemId]
		: null

export const makeGetRootDashItemSteps = () =>
	createSelector(
		getRootDashItemsSteps,
		(stepsObject) => stepsObject
	)
