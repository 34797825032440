import { fs } from './../firebase'

/**
 * @author Davi Spindola
 * !@version 1.0.0
 * !Responsible for dispatch actions to firebase database
 *
 * @version 2.0.0
 * Responsibe for dispatch actions to firebase firestore
 *
 * @description Changed function name
 * @author brunoteixeirasilva
 * @version 2.1.0
 *
 * TODO: prevent duplicated insertion when exist duplicated ccReceivers
 *
 * @param {Object} action
 * @param {String} activeCompanyId
 */
export const create = async (action, activeCompanyId) => {
	const newAction = fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.doc()

	return newAction.set(action, { merge: false }).then(() => {
		return newAction.id
	})
}

/**
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible for dispatch responses.
 *
 * @param {String} activeCompanyId current company
 * @param {String} actionId action id
 * @param {Object} response the response data
 */
export const setActionResponse = async (
	activeCompanyId,
	actionId,
	response
) => {
	let order = 0

	await fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.doc(actionId)
		.collection(`responses`)
		.get()
		.then((snapshot) => {
			if (!snapshot.empty) {
				return (order = snapshot.size)
			}
		})

	fs.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.doc(actionId)
		.collection(`responses`)
		.doc(`${order}`)
		.set(response, { merge: true })

	return fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.doc(actionId)
		.update({ responsesCount: order + 1 })
}

/**
 * @author davispindola
 * @version 1.0.0
 *
 * @param {String} activeCompanyId
 * @param {String} actionId
 */
export const loadActionResponses = (activeCompanyId, actionId) => {
	return fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection('actions')
		.doc(actionId)
		.collection('responses')
		.get()
}
