import React from 'react'
import PropTypes from 'prop-types'
import { Badge, withStyles } from '@material-ui/core'

/**
 * Manages to control the visible elements of a system-wide Badge
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
const AgilBadge = ({ children, classes, isAlert, color, badgeContent }) => (
	<Badge
		classes={{ badge: isAlert ? classes.alertBadge : undefined }}
		color={color}
		badgeContent={badgeContent}
	>
		{children}
	</Badge>
)

AgilBadge.defaultProps = {
	color: 'primary',
	badgeContent: '',
	isAlert: false
}

AgilBadge.propTypes = {
	badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.node.isRequired, // ! required object to be wrapped
	classes: PropTypes.object,
	color: PropTypes.oneOf(['primary', 'secondary']),
	isAlert: PropTypes.bool
}

export default withStyles({
	alertBadge: {
		color: 'white',
		backgroundColor: 'rgb(255,0,0)'
	}
})(AgilBadge)
