const hadReasons = (reasons = null) => {
	if (reasons !== null && reasons instanceof Array && reasons.length) {
		return reasons
	}

	if (
		reasons !== null &&
		reasons instanceof Object &&
		Object.keys(reasons).length
	) {
		return reasons
	}

	return null
}

export default hadReasons
