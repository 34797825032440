import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { firebase } from '../../../firebase'
import * as routes from '../../../constants/routes'
import { AUTH_USER_SET } from '../../../reducers/session'
import { makeGetAuthUser } from '../../../selectors/session'

/**
 * Will render/not render a component,
 * based on the condition passed on its params
 *
 * @param {Function} authCondition Function - The authentication condition to be applied
 */
const withAuthorization = authCondition => Component => {
	class WithAuthorization extends React.Component {
		componentDidMount() {
			const { notifyOnSetAuthUser, history } = this.props

			firebase.auth.onAuthStateChanged(authUserFirebase => {
				// Dispatches the notification of available authUser
				notifyOnSetAuthUser(authUserFirebase)

				// Authentication error, directs the user to the sign in page
				if (!authCondition(authUserFirebase)) {
					history.push(routes.SIGN_IN)
				}
			})
		}

		render() {
			return this.props.authUser ? <Component {...this.props} /> : null
		}
	}

	/**
	 * Redux dispatchers mapping
	 *
	 * @param {Object} state
	 */
	const mapDispatchToProps = dispatch => ({
		notifyOnSetAuthUser: authUser =>
			dispatch({ type: AUTH_USER_SET, authUser }),
	})

	/**
	 * Redux state mappers
	 *
	 * @param {Object} state
	 */
	const makeMapStateToProps = () => {
		const getAuthUser = makeGetAuthUser()
		const mapStateToProps = state => ({
			authUser: getAuthUser(state),
		})

		return mapStateToProps
	}

	return compose(
		connect(makeMapStateToProps(), mapDispatchToProps),
		withRouter,
	)(WithAuthorization)
}

export default withAuthorization
