import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, IconButton, Icon, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import { translate } from '../../../../../../utils/lang'

/**
 * Will expose the list-item beautifully, dense version (for config list)
 *
 * @author brunoteixeirasilva
 * @version 1.0
 */
class PluginListItemDense extends React.Component {
	render() {
		const { classes, item, handleConfig, name, type, active, pluginKey } = this.props

		return (
			pluginKey && (
				<ListItem className={classes.root} key={pluginKey}>
					<ListItemIcon>
						{active ? (
							<Icon className={classes.colorPrimary} color="primary">
								extension
							</Icon>
						) : (
							<Icon className={classes.colorDisabled} color="disabled">
								extension
							</Icon>
						)}
					</ListItemIcon>
					<ListItemText primary={name} secondary={type} />
					<ListItemSecondaryAction>
						<IconButton onClick={event => handleConfig(event, item)} aria-label={translate('label/configure')} color="default">
							<Icon>settings</Icon>
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			)
		)
	}
}

const styles = theme => ({
	root: {
		borderBottom: `1px solid #CCC`,
		'&:nth-last-child()': {
			borderBottom: `1px solid transparent`,
		},
	},
	colorPrimary: { color: '#1dc138' },
	colorDisabled: { color: '#9b0000' },
})

PluginListItemDense.propTypes = {
	item: PropTypes.object,
	handleConfig: PropTypes.func.isRequired,
}

export default withStyles(styles)(PluginListItemDense)
