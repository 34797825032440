export const SET_DIALOG_OPEN = 'SET_DIALOG_NOTIFICATION_IS_OPEN',
	SET_DIALOG_CLOSE = 'SET_DIALOG_NOTIFICATION_IS_CLOSE',
	SET_DIALOG_NOTIFICATION_CONTENT = 'SET_DIALOG_NOTIFICATION_CONTENT'

const INITIAL_STATE = {
	isOpen: false,
	message: '',
	title: ''
}

/**
 * @description Created the method
 * @author davispindola
 * @version 1.0
 */
const setOpen = (state, action) =>
	Object.assign({}, state, {
		isOpen: true
	})

/**
 * @description Created the method
 * @author davispindola
 * @version 1.0
 */
const setClose = (state, action) =>
	Object.assign({}, state, { ...INITIAL_STATE })

/**
 * @description Created the method
 * @author davispindola
 * @version 1.0
 */
const setContent = (state, { title, message }) =>
	Object.assign({}, state, {
		title: title,
		message: message
	})

/**
 * @description Created the reducer
 * @author davispindola
 * @version 1.0
 */
const dialogNotificationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_DIALOG_OPEN:
			return setOpen(state, action)

		case SET_DIALOG_CLOSE:
			return setClose(state, action)

		case SET_DIALOG_NOTIFICATION_CONTENT:
			return setContent(state, action)

		default:
			return state
	}
}

export default dialogNotificationReducer
