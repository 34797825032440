import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'

import ReactDOM from 'react-dom'
// import * as htmlToImage from 'html-to-image'
import {
	WhatsappShareButton,
} from 'react-share'

/**
 * Renders a context menu option
 */
class ContextMenuOption extends React.Component {
	// state = {
	// 	responseImage : null
	// }

	onClick = () => {
		const { onClose, option } = this.props

		if (option.action instanceof Function) option.action()

		// htmlToImage.toPng(ReactDOM.findDOMNode(this)).then((dataUrl) => {
		// 	let img = new Image()

		// 	img.src = dataUrl

		// 	document.body.appendChild(img)

		// 	console.log('img', img)

		// 	this.setState({
		// 		responseImage: img
		// 	})

		// }).catch((error) => {
		// 	console.error('oops, something went wrong!', error)
		// })

		onClose()
	}

	render() {
		const { option, selected } = this.props

		return !!option.image && option.key === 'email' ? (
			<MenuItem
				disabled={!!option.disabled}
				key={option.key}
				selected={selected}
				onClick={this.onClick}>
				{/* <EmailShareButton subject='Agildash' openWindow={true} url='https://mui.com/pt/' separator='https://mui.com/pt/' body='https://mui.com/pt/' > */}
				{option.label}
				{/* </EmailShareButton> */}
			</MenuItem>
		) : !!option.image && option.key === 'whatsapp' ? (
			<MenuItem
				disabled={!!option.disabled}
				key={option.key}
				selected={selected}
				onClick={this.onClick}>
				<WhatsappShareButton url={option.image} title="image">
					{option.label}
				</WhatsappShareButton>
			</MenuItem>
		) : (
			!option.hide && (
				<MenuItem
					disabled={!!option.disabled}
					key={option.key}
					selected={selected}
					onClick={this.onClick}>
					{option.label}
				</MenuItem>
			)
		)
	}
}

ContextMenuOption.propTypes = {
	option: PropTypes.shape({
		disabled: PropTypes.bool,
		hide: PropTypes.bool,
		key: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		action: PropTypes.func.isRequired,
	}).isRequired,
}

export default ContextMenuOption
