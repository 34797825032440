import {
	MANAGE_MEASURER_READ_LOADING_STATE,
	LOAD_MEASURER_READ_DATA,
	SET_MEASURER_READ_PERIOD
} from '../../actions/read'

const INITIAL_STATE = {
	isLoading: null,
	measurerReads: null,
	dateConfig: {},
	update: false
}
/**
 * performed audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */
const measurerReadsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_MEASURER_READ_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_MEASURER_READ_DATA: {
			return Object.assign({}, state, {
				measurerReads: action.measurerReads,
				isLoading: false
			})
		}
		case SET_MEASURER_READ_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default measurerReadsReducer
