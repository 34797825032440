const INITIAL_STATE = {
	users: null,
	companyUsers: null
}

// const removeGroupUser = (state, action) => {
// 	return Object.assign({}, state, {
// 		users: Object.keys(state.users).reduce((acc, val) => {
// 			if (action.uid !== val) {
// 				acc[val] = state.users[val]
// 			}
// 			return acc
// 		}, {})
// 	})
// }

/**
 * Manages group users form state
 *
 * @param {Object} state
 * @param {Object} action
 */
function groupUserFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_GROUP_USER_FORM_USERS': {
			//Creates a new version of the state - adding an item to the array
			return Object.assign({}, state, {
				users: action.users
			})
		}
		case 'GROUP_USER_FORM_COMPANY_USERS_LOADED': {
			//Creates a new version of the state - adding an item to the array
			return Object.assign({}, state, {
				companyUsers: action.companyUsers
			})
		}
		// case 'REMOVE_GROUP_USER': {
		// 	return removeGroupUser(state, action)
		// }
		// case 'REMOVE_GROUP_USER': {
		// 	//Creates a new version of the state - adding an item to the array
		// 	throw Error('Not implemented')
		// 	// return Object.assign({}, state, {
		// 	// 	users: Object.assign({}, state.users, action.user)
		// 	// })
		// }
		case 'ADD_GROUP_USER': {
			//Creates a new version of the state - adding an item to the array
			return Object.assign({}, state, {
				users: Object.assign({}, state.users, action.user)
			})
		}
		case 'CLEAN_GROUP_FORM_USERS': {
			return Object.assign({}, state, {
				users: INITIAL_STATE.users
			})
		}
		default:
			return state
	}
}

export default groupUserFormReducer
