import { createSelector } from 'reselect'
import { getActionById } from '../actions'
import { wasEvaluated } from '../wasEvaluated'
import hadReasons from '../hadReasons'

const makeGetActionImprovements = () => {
	return createSelector(
		[getActionById],
		(action) => {
			if (wasEvaluated(action, 'evaluate')) {
				return hadReasons(action.evaluate.improvements)
			}
			return null
		}
	)
}

export default makeGetActionImprovements
