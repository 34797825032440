import { fs } from '../firebase'

// Firebase "dashItem" API
/**
 * [Firestore] Will find a dashItem by its ID
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 */
export const get = dashItemId => {
	let ref = fs.collection('dashItems').doc(dashItemId)

	return ref.get()
}

/**
 * @description Increment function to validade active dashItem
 * @author jeffersonguilhermemachadobarreto
 * @version 1.2
 */
export const getActiveDashItem = async (dashItemId, datas) => {
	let docs
		// dashItemIsActive = false
	const newRef = fs
		.collection('companies')
		.doc(datas.activeCompanyId)
		.collection('users')
		.doc(datas.userCrr)
		.collection('plugins')
		.doc(datas.plugin)
		.collection('dashSets')
		.doc(datas.dashSet)
		.collection('cards')
		.doc(datas.dashItemId)

	return await fs
		.collection('companies')
		.doc(datas.activeCompanyId)
		.collection('users')
		.doc(datas.userCrr)
		.collection('plugins')
		.doc(datas.plugin)
		.collection('dashSets')
		.doc(datas.dashSet)
		.collection('cards')
		.doc(datas.dashItemId)
		.get()
		.then(snap => {
			docs = snap.data()

			if (!!docs && !!docs.active) {
				// dashItemIsActive = true

				return newRef.get()
			} 
			// else {
			// 	dashItemIsActive = false
			// }
		})
}

/**
 * [Firestore] Will find dashSet card IDs by a dashSet ID
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description dev-active support added to this kind of objects
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @param {string} dashSetId The dashset identification
 */
export const getByDashSet = async dashSetId => {
	let ref = fs.collection('dashSets').doc(dashSetId).collection('cards'),
		active = ref.where('active', '==', true),
		secondary,
		collection = []

	//TODO: CHECK, BECAUSE THIS IS PROVISORY
	// if (process.env.REACT_APP_NODE_ENV.trim() === 'production') {
	// 	 = ref.where('active', '==', true)
	// }
	if (process.env.REACT_APP_NODE_ENV === 'stage') {
		active.where('activeStage', '==', false)
		secondary = ref
			.where('activeStage', '==', true)
			.where('active', '==', false)
	}
	if (process.env.REACT_APP_NODE_ENV.trim() === 'development') {
		active.where('activeDev', '==', false)
		secondary = ref
			.where('activeDev', '==', true)
			.where('active', '==', false)
	}
	await Promise.all([
		active.get(),
		...(process.env.REACT_APP_NODE_ENV !== 'production'
			? [secondary.get()]
			: []),
	]).then(value => {
		value.forEach(querySnapshot => {
			querySnapshot.forEach(item => collection.push(item))
		})
	})
	return collection
}

/**
 * get dashItems by user and company
 * @description created the function
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const getDashItemByUser = async datas => {
	let docs,
		dashItemIsActive = false

	return await fs
		.collection('companies')
		.doc(datas.activeCompanyId)
		.collection('users')
		.doc(datas.userCrr)
		.collection('plugins')
		.doc(datas.plugin)
		.collection('dashSets')
		.doc(datas.dashSet)
		.collection('cards')
		.doc(datas.dashItemId)
		.get()
		.then(snap => {
			docs = snap.data()
			if (!!docs && !!docs.active) {
				dashItemIsActive = true
				return dashItemIsActive
			} else {
				dashItemIsActive = false
				return dashItemIsActive
			}
		})
}

/**
 * get dashItems by company
 * @description created the function
 * @author Wegner
 * @version 1.0
 */
export const getByCompany = async companyId => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('dashItems')
		.where('active', '==', true)
		.get()
		.then(async dashItems => {
			if (!dashItems.empty) {
				let dashItemPromises = [],
					collection = []

				dashItems.forEach(dashItem =>
					dashItemPromises.push(get(dashItem.id, true)),
				)

				await Promise.all(dashItemPromises).then(dashItem => {
					collection = dashItem
				})
				return collection
			}
		})
}

export const getByCompanySheetsOnly = async companyId => {
	return fs
		.collection('uploadProfiles')
		.where('active', '==', true)
		.where('type', '==', 'sheets')
		.where('companyId', '==', companyId)
		.get()
		.then(async dashItems => {
			if (!dashItems.empty) {
				let dashItemPromises = [],
					collection = []

				dashItems.forEach(dashItem =>
					dashItemPromises.push(get(dashItem.id, true)),
				)

				await Promise.all(dashItemPromises).then(dashItem => {
					collection = dashItem
				})
				return collection
			}
		})
}
/**
 * get all dashItems
 * @description created the function
 * @author Wegner
 * @version 1.0
 */
export const getAll = active => {
	let ref = fs.collection('dashItems')
	if (active) ref.where('active', '==', true)
	return ref.get()
}
/**
 * create dashItem
 * @description created the function
 * @author Wegner
 * @version 1.0
 */
export const create = dashItemData => {
	return fs.collection('dashItems').add(dashItemData)
}

/**
 * update dashItem
 * @description created the function
 * @author Wegner
 * @version 1.0
 */
export const update = (dashItemId, dashItemData) => {
	return fs.collection('dashItems').doc(dashItemId).update(dashItemData)
}
