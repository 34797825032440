import {
	SET_CURRENT_STEP,
	IS_STEP_CONTAINER_LOADING,
	SET_CURRENT_STEP_OWN_FILTERS,
	SET_CURRENT_UNSUBSCRIBE,
	SET_COMPANY_DASH_ITEM_STEP,
	CLEAR_COMPANY_DASH_ITEM_STEPS
} from '../../../../actions/step/manager'
import { CLEAN_ON_COMPANY_CHANGE } from '../../../../actions/company/index.js'

const INITIAL_STATE = {
	steps: {},
	step: null, //TODO: remove when transformed to steps
	isLoading: null,
	ownFilters: null,
	unsubscribe: null
}

/**
 * Company DashItem step reducer for use in step container
 * @description Created the funcion
 * @author Davi Wegner
 * @version 1.0
 */
const companyDashItemStepReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_CURRENT_STEP: {
			return Object.assign({}, state, { step: action.step })
		}
		case SET_COMPANY_DASH_ITEM_STEP: {
			return Object.assign({}, state, {
				steps: Object.assign({}, state.steps, {
					[action.dashItemId]: Object.assign(
						{},
						state[action.dashItemId],
						action.step
					)
				})
			})
		}
		case IS_STEP_CONTAINER_LOADING: {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		case SET_CURRENT_STEP_OWN_FILTERS: {
			return Object.assign({}, state, {
				ownFilters: Object.assign(
					{},
					state.ownFilters,
					action.ownFilter
				)
			})
		}
		case SET_CURRENT_UNSUBSCRIBE: {
			return Object.assign({}, state, { unsubscribe: action.unsubscribe })
		}
		case CLEAR_COMPANY_DASH_ITEM_STEPS: {
			return Object.assign({}, state, { steps: INITIAL_STATE.steps })
		}
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default companyDashItemStepReducer
