export const SET_CURRENT_DASH_ITEM = 'SET_CURRENT_DASH_ITEM',
	SET_CURRENT_STEP = 'SET_CURRENT_STEP',
	IS_STEP_CONTAINER_LOADING = 'IS_STEP_CONTAINER_LOADING',
	SET_CURRENT_STEP_OWN_FILTERS = 'SET_CURRENT_STEP_OWN_FILTERS',
	SET_CURRENT_UNSUBSCRIBE = 'SET_CURRENT_UNSUBSCRIBE',
	SET_COMPANY_DASH_ITEM_STEP = 'SET_COMPANY_DASH_ITEM_STEP',
	CLEAR_COMPANY_DASH_ITEM_STEPS = 'CLEAR_COMPANY_DASH_ITEM_STEP'

export const setCurrentStep = (step) => ({
	type: SET_CURRENT_STEP,
	step
})

export const setCurrentDashItem = (dashItem) => ({
	type: SET_CURRENT_DASH_ITEM,
	dashItem
})

export const setCompanyDashItemStep = (dashItemId, step) => ({
	type: SET_COMPANY_DASH_ITEM_STEP,
	dashItemId,
	step: step
})

export const setStepLoading = (isLoading) => ({
	type: IS_STEP_CONTAINER_LOADING,
	isLoading
})

export const setOwnFilters = (ownFilter) => ({
	type: SET_CURRENT_STEP_OWN_FILTERS,
	ownFilter
})

export const setUnsubscribe = (unsubscribe) => ({
	type: SET_CURRENT_UNSUBSCRIBE,
	unsubscribe
})

export const clearCompanyDashItemSteps = () => ({
	type: CLEAR_COMPANY_DASH_ITEM_STEPS
})
