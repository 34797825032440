import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		marginTop: '2rem',
		paddingTop: '0',
		[theme.breakpoints.up('xs')]: {
			height: '100%'
		},
		...getDesktopBreakpoint(theme, {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			width: '100%',
			height: '100vh',
			flex: '0 0 auto',
			marginTop: '0'
		})
	},
	title: {
		textAlign: 'center',
		color: 'rgba(84,192,235,1)'
	},
	forgotPassword: {
		textAlign: 'center',
		padding: theme.spacing.unit * 2
	}
})

export default styles
