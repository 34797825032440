import { call, put } from 'redux-saga/effects'
import { store } from '../../../store'
import { setAppTitle } from '../../../actions/app/config'
import { makeGetAppDefaultTitle } from '../../../selectors/app/config'
import { onSagaError } from '../..'

//Creating the memoised app title getter
const getAppDefaultTitle = makeGetAppDefaultTitle()

/**
 * Manages to dispatch a system title resetter
 *
 * @description Created the generator function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} action The action object (from where action came from)
 */
export function* resetAppTitle(action) {
	try {
		//Getting app title from store (has the default)
		const defaultAppTitle = yield call(getAppDefaultTitle, store.getState())

		//Restoring app's default title
		yield put(setAppTitle(defaultAppTitle))
	} catch (ex) {
		yield onSagaError(ex)
	}
}
