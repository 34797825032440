import React from 'react'
import {
	List,
	ListItem,
	ListItemIcon,
	Icon,
	withStyles
} from '@material-ui/core'
import styles from './styles'

const Rating = (props) => {
	const {
		options,
		handleSelectedValue,
		listOrientation,
		keyProp,
		currentValue,
		classes,
	} = props

	return (
		<div>
			<List
				className={
					listOrientation === 'horizontal'
						? classes.horizontalList
						: ''
				}
			>
				{options.map((item) => {
					return (
						<ListItem
							className={classes.item}
							key={item[keyProp]}
							onClick={() => {
								handleSelectedValue({
									label: item.label,
									value: item.value,
								})
								if (item.secondaryAction)
									item.secondaryAction()
							}
							}
						>
							<ListItemIcon className={classes.icon}>
								<Icon>
									{item.value <= currentValue
										? item.activeIcon
										: item.icon}
								</Icon>
							</ListItemIcon>
						</ListItem>
					)
				})}
			</List>
		</div>
	)
}

export default withStyles(styles)(Rating)
