export const PERFORMANCE_DATA_LOADED = 'PERFORMANCE_DATA_LOADED',
	PERFORMANCE_SUMMARY_DATA_LOADED = 'PERFORMANCE_SUMMARY_DATA_LOADED',
	SET_PERFORMANCE_DATA_LOADING = 'SET_PERFORMANCE_DATA_LOADING'

/**
 * [action-creator] Dispatches changes to performance graph data
 *
 * @description Created the action-creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array<Object>} data The summary performance data
 */
export const setOperationalPerformanceData = (data) => ({
	type: PERFORMANCE_DATA_LOADED,
	data
})

/**
 * [action-creator] Dispatches changes to performance summary data
 *
 * @description Created the action-creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array<Object>} summary The summary returned data
 */
export const setOperationalPerformanceSummaryData = (summary) => ({
	type: PERFORMANCE_SUMMARY_DATA_LOADED,
	summary
})

/**
 * [action-creator] Dispatches changes to _operational performance_ **loading** state
 *
 * @description Created the action-creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array<Object>} isLoading Whether the data is loading or not
 */
export const setOperationalPerformanceLoading = (isLoading) => ({
	type: SET_PERFORMANCE_DATA_LOADING,
	isLoading
})
