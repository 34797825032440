export const MANAGE_MAP_AUDIT_LOADING_STATE = 'MANAGE_MAP_AUDIT_LOADING_STATE',
	LOAD_MAP_AUDIT_DATA = 'LOAD_MAP_AUDIT_DATA',
	SET_MAP_AUDIT_PERIOD = 'SET_MAP_AUDIT_PERIOD'

export const setMapAuditPeriod = (dateConfig, value, formType) => ({
	type: SET_MAP_AUDIT_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadMapAuditData = (mapAudit) => ({
	type: LOAD_MAP_AUDIT_DATA,
	mapAudit
})

export const setLoading = (isLoading) => ({
	type: MANAGE_MAP_AUDIT_LOADING_STATE,
	isLoading
})
