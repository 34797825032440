export const MANAGE_RANKING_NC_CLIENT_LOADING_STATE =
    'MANAGE_RANKING_NC_CLIENT_LOADING_STATE',
    LOAD_RANKING_NC_CLIENT_DATA = 'LOAD_RANKING_NC_CLIENT_DATA',
    SET_RANKING_NC_CLIENT_PERIOD = 'SET_RANKING_NC_CLIENT_PERIOD'

export const setRankingNcClientPeriod = (dateConfig, value, formType) => ({
    type: SET_RANKING_NC_CLIENT_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadRankingNcClientData = (rankingNcClient) => ({
    type: LOAD_RANKING_NC_CLIENT_DATA,
    rankingNcClient
})

export const setLoading = (isLoading) => ({
    type: MANAGE_RANKING_NC_CLIENT_LOADING_STATE,
    isLoading
})
