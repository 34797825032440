import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { generatePath } from 'react-router'
import * as routes from '../../../../../constants/routes'
import { NotificationIconAvatar } from '../../../../reusable/icon/notification'
import NotificationContent from './NotificationContent'

/**
 * @description created the component
 * @author Davi Spindola
 * @version 1.0
 *
 * @description updated component for new notification format
 * @author Davi Wegner
 * @version 2.0
 *
 * @description added opacity for non active notifications
 * @author Davi Wegner
 * @version 2.1
 */
class NotificationItem extends React.Component {
	render() {
		const {
			classes,
			notification,
			authorInfo,
			history,
			setActionId,
			openActionModal,
			handleActive,
			notificationId
		} = this.props
		return (
			<div
				className={classes.root}
				onClick={() => {
					if(notification.type == 'action-sent') {
						handleActive(notificationId)
						history.push(
							generatePath(routes.USER_ACTIONS, {
								companyId: notification.companyId,
								actionId: notification.actionId
							})
						)
						setActionId(notification.actionId)
						openActionModal()
					} else {
						handleActive(notificationId)
						setActionId(notification.actionId)
						openActionModal()
					}
				}}
			>
				{notification !== null && (
					<div
						className={
							!!notification.active
								? classes.active
								: classes.notActive
						}
					>
						{authorInfo && <NotificationIconAvatar />}
						<NotificationContent
							type={notification.type}
							date={notification.date.seconds}
							notification={notification}
							{...(authorInfo ? { user: authorInfo.name } : null)}
						/>
					</div>
				)}
			</div>
		)
	}
}

NotificationItem.propTypes = {
	classes: PropTypes.object.isRequired
}

const styles = {
	root: {
		alignItems: 'center',
		backgroundColor: '#fff',
		boxShadow: '0 0 10px -5px #000',
		display: 'flex',
		justifyContent: 'space-between',
		margin: '5px 0',
		padding: 10,
		width: '100%',
		borderRadius: 10,
		cursor: 'pointer'
	},
	active: {
		display: 'flex',
		alignItems: 'left',
		flex: 2
	},
	notActive: {
		display: 'flex',
		alignItems: 'left',
		flex: 2,
		opacity: 0.6
	},
	content: {
		padding: '0 5px',
		'& > p': {
			color: '#848484'
		}
	},
	flex: {
		display: 'flex',
		alignItems: 'center'
	},
	date: {
		display: 'flex',
		alignItems: 'center',
		'& > p': {
			width: 'max-content',
			fontSize: '0.775rem',
			color: '#3f51b5'
		}
	},
	wrapper: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex'
	}
}

export default withStyles(styles)(NotificationItem)
