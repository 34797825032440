import { createSelector } from 'reselect'
import { translate } from '../../../../utils/lang'
import { wasEvaluated } from '../wasEvaluated'
import { getActionById } from '../actions'

const getEvaluationReasons = (state, props) =>
	state.evaluationReasonsState.reasons[props.reasonType]

const getReasonType = (state, props) => props.reasonType

const makeGetEvaluationReasons = () =>
	createSelector(
		[getEvaluationReasons, getActionById, getReasonType],
		(reasons, action, reasonType) => {
			let evaluationReasons

			if (
				reasons === undefined ||
				reasons === [] ||
				reasons.length === 0
			) {
				return null
			}

			if (wasEvaluated(action, 'evaluate')) {
				return formatReasonsFromEvaluation(action.evaluate[reasonType])
			}

			if (reasons) evaluationReasons = formatReasons(reasons)

			return evaluationReasons
		}
	)

const formatReasons = (reasons = []) =>
	reasons.map((item) => ({ key: item, label: translate(`label/${item}`) }))

const formatReasonsFromEvaluation = (reasons = []) =>
	reasons.map(({ classification }) => ({
		key: classification,
		label: translate(`label/${classification}`)
	}))

export default makeGetEvaluationReasons
