export const MANAGE_CONTRIBUTOR_LIST_LOADING_STATE =
		'MANAGE_CONTRIBUTOR_LIST_LOADING_STATE',
	LOAD_CONTRIBUTOR_LIST_DATA = 'LOAD_CONTRIBUTOR_LIST_DATA',
	SET_CONTRIBUTOR_LIST_PERIOD = 'SET_CONTRIBUTOR_LIST_PERIOD'

export const setContributorListPeriod = (dateConfig, value, formType) => ({
	type: SET_CONTRIBUTOR_LIST_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadContributorListData = (contributorList) => ({
	type: LOAD_CONTRIBUTOR_LIST_DATA,
	contributorList
})

export const setLoading = (isLoading) => ({
	type: MANAGE_CONTRIBUTOR_LIST_LOADING_STATE,
	isLoading
})
