export const SET_AUDITED_OBJECTS_DATA_LOADING =
		'SET_AUDITED_OBJECTS_DATA_LOADING',
	SET_AUDITED_OBJECTS_DATA = 'SET_AUDITED_OBJECTS_DATA',
	CLEAR_AUDITED_DATA = 'CLEAR_AUDITED_DATA'

export const auditedDataLoading = (isLoading) => ({
	type: SET_AUDITED_OBJECTS_DATA_LOADING,
	isLoading
})

export const auditedDataLoaded = (items) => ({
	type: SET_AUDITED_OBJECTS_DATA,
	items
})

export const auditedDataCleared = () => ({
	type: CLEAR_AUDITED_DATA
})
