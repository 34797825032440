import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import classNames from 'classnames'
import * as User from '../../../../firebase/user'
import { Avatar, withStyles } from '@material-ui/core'
import { translate } from '../../../../utils/lang'
import { makeAvatarContent } from '../../../../selectors/user/avatar'
import { deviceIsIos } from '../../../../utils/env'
import LanguageItem from '../languageItem'

/**
 * Manages to display a ball-user-avatar with user-name initials
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed location of the component
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Implementing commemorative avatar
 * @author davispindola
 * @version 1.2
 *
 * @description Added support for iOS device inset/notches top (orientation portrait)
 * @author brunoteixeirasilva
 * @version 1.3
 */
class NavigationAvatar extends Component {
	componentDidMount() {
		const { authUser, notifyOnGetUser } = this.props

		if (!!authUser) {
			User.get(authUser.uid).then((user) => {
				notifyOnGetUser(user.data())
			})
		}
	}

	render() {
		const { classes } = this.props
		let { user, userAvatar } = this.props

		if (!user) user = { name: translate('placeholder/anonymous-da-silva') }

		return (
			<div
				className={classNames(classes.avatarContainer, {
					[classes.avatarContainerIos]: deviceIsIos()
				})}
			>
				<div className={classes.avatarBox}>
					{userAvatar && userAvatar.isCommemorative ? (
						<Avatar src={userAvatar.content} />
					) : (
						<Avatar className={classes.avatarIcon}>
							{!!user && userAvatar.content}
						</Avatar>
					)}
				</div>
				<span className={classes.avatarText}>{user.name}</span>
				<LanguageItem />
			</div>
		)
	}
}

const styles = (theme) => ({
	avatarContainer: {
		height: '80px',
		background: theme.palette.primary.light,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	avatarContainerIos: {
		height: 'calc(80px + env(safe-area-inset-top))',
		paddingTop: 'env(safe-area-inset-top)'
	},
	avatarIcon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText
	},
	avatarBox: {
		margin: '0 10px'
	},
	avatarText: {
		color: theme.palette.primary.contrastText,
		fontSize: '.9em'
	}
})

const mapStateToProps = (state) => {
	const makeAvatar = makeAvatarContent()

	return {
		authUser: state.sessionState.authUser,
		user: state.userState.user,
		userAvatar: makeAvatar(state)
	}
}

const mapDispatchToProps = (dispatch) => ({
	notifyOnGetUser: (user) => dispatch({ type: 'USER_LOADED', user })
})

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)(NavigationAvatar)
