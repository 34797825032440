import {
	MANAGE_PRODUCTIVITY_CLIENTS_LOADING_STATE,
	LOAD_PRODUCTIVITY_CLIENTS_DATA,
	SET_PRODUCTIVITY_CLIENTS_PERIOD,
	SET_PRODUCTIVITY_CLIENT,
	SET_PRODUCTIVITY_CYCLE,
	CLEAR_PRODUCTIVITY_CYCLE,
	CLEAR_PRODUCTIVITY_CLIENT
} from '../../actions/productivityClients'

const INITIAL_STATE = {
	isLoading: null,
	productivityClients: null,
	dateConfig: {},
	currentClient: null,
	currentCycle: null,
	contracts: null,
	update: false,
	ownFilters: {},
	searchText: {
		ciclo_nome: '',
		status_apontamento: '',
		cliente_nome: '',
		equipe_nome: '',
		pr_descricao: '',
		base: '',
		localidade: '',
		supervisor: ''
	}
}
/**
 * ranking audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */

const productivityClientsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_PRODUCTIVITY_CLIENTS_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_PRODUCTIVITY_CLIENTS_DATA: {
			return Object.assign({}, state, {
				productivityClients: action.productivityClients,
				isLoading: false
			})
		}
		case 'SET_PRODUCTIVITY_CLIENTS_DATA': {
			return Object.assign({}, state, {
				productivityClients: action.productivityClients
			})
		}
		case 'SET_PRODUCTIVITY_CLIENTS_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_PRODUCTIVITY_CLIENTS_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_PRODUCTIVITY_CLIENTS_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_PRODUCTIVITY_CLIENTS_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		case SET_PRODUCTIVITY_CLIENT: {
			return Object.assign({}, state, { currentClient: action.item })
		}
		case CLEAR_PRODUCTIVITY_CLIENT: {
			return Object.assign({}, state, {
				currentClient: INITIAL_STATE.currentClient
			})
		}
		case SET_PRODUCTIVITY_CYCLE: {
			return Object.assign({}, state, {
				currentCycle: action.currentCycle
			})
		}
		case CLEAR_PRODUCTIVITY_CYCLE: {
			return Object.assign({}, state, {
				currentCycle: INITIAL_STATE.currentCycle
			})
		}
		default:
			return state
	}
}

export default productivityClientsReducer
