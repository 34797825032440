const INITIAL_STATE = {
	groupPermissions: null,
	isLoading: false
}

/**
 * Clears group permissions listed previously
 *
 * @param {Object} state The current system state
 */
const clearGroupPermissions = (state) => {
	return Object.assign({}, state, {
		groupPermissions: INITIAL_STATE.groupPermissions
	})
}

/**
 * Adds a group permission singularly to the group
 *
 * @param {Object} state The current system state
 * @param {Object} action The current action object with a prop "groupPermission"
 */
const groupPermissionsLoaded = (state, action) => {
	return Object.assign({}, state, {
		groupPermissions: Object.assign(
			{},
			state.groupPermissions,
			action.groupPermission
		)
	})
}

/**
 * Manages a group permissions state
 *
 * @param {Object} state
 * @param {Object} action
 */
function groupPermissionReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CLEAR_GROUP_PERMISSIONS': {
			return clearGroupPermissions(state)
		}
		case 'GROUP_PERMISSION_LOADED': {
			return groupPermissionsLoaded(state, action)
		}
		case 'SET_GROUP_PERMISSION_IS_LOADING':
			return { ...state, isLoading: action.isLoading }
		default:
			return state
	}
}

export default groupPermissionReducer
