import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, withStyles, IconButton } from '@material-ui/core'
import { compose } from 'recompose'
import { rightBarIcon } from './styles'
import Tip from '../tooltip'

/**
 * Right bar icon container
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
class RightDashboard extends Component {
    
    openModal = () => {
        const { onSetActiveModal } = this.props

        onSetActiveModal(true)
    }

    render() {
        const { icon, classes } = this.props

		return (
            icon &&
            <div>
                <Tip tooltip='Visão Mosaico'>
                    <IconButton
                        className={classes.button}
                        color="inherit"
                        onClick={(() => this.openModal())}
                    >
                        <Icon>dashboard</Icon>
                    </IconButton>
                </Tip>
            </div>
        )
	}
}

const mapDispatchToProps = dispatch => ({
	onSetActiveModal: async openModal => dispatch({ type: 'SET_MODAL_DASH_SET', openModal }),
})

const mapStateToProps = (state) => ({
	icon: state.appConfigState.app.barRightIcon,
})

export default compose(
	withStyles(rightBarIcon),
	connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(RightDashboard)
