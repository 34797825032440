import { createSelector } from 'reselect'
import { makeGetGlobalFiltersSearchText } from '../search'
import { normaliseForQuery } from '../../../utils/string'

/**
 * Gets the **global** filters-data _data_ collections from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getGlobalFiltersDataList = (state) => state.globalFiltersDataState.data

/**
 * Gets the **global** filters-data _data_ collections from state, at an specific dataSetId
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getGlobalFiltersDataSetDataList = (state, dataSetId) => {
	const filtersData = getGlobalFiltersDataList(state),
		dataSetData = !!filtersData && !!filtersData[dataSetId] ? filtersData[dataSetId] : []

	return dataSetData
}

/**
 * [Memoised] Gets the **global** filters-data _data_ collections from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFiltersDataList = () =>
	createSelector(
		getGlobalFiltersDataSetDataList,
		makeGetGlobalFiltersSearchText(),
		(data, searchText) => {
			return !data || data.length === 0
				? [] //TODO: should return null, I think
				: data.filter(
						(item) =>
							item.label
								.toLowerCase()
								.indexOf(normaliseForQuery(searchText)) !== -1
				  )
		}
	)

/**
 * Gets the **global** filters-data _loading_ state, of collections
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getGlobalFiltersDataLoading = (state) =>
	state.globalFiltersDataState.isLoading

/**
 * Gets the **global** filters-data _loading_ state, of collections, at an specific dataSetId
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getGlobalFiltersDataSetDataLoading = (state, dataSetId) => {
	const isLoading = getGlobalFiltersDataLoading(state)

	return !!isLoading && !!isLoading[dataSetId] ? isLoading[dataSetId] : false
}

/**
 * [Memoised] Gets the **global** filters-data _data_ collections from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFiltersDataLoading = () =>
	createSelector(
		getGlobalFiltersDataSetDataLoading,
		(isLoading) => isLoading
	)

/**
 * [memoised] Selects the collection of labels from the filter data
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFiltersDataSetItemLabels = () =>
	createSelector(
		getGlobalFiltersDataSetItemsByKeySet,
		(items) =>
			!!items && items.length > 0 ? items.map((item) => item.label) : []
	)

/**
 * Selects the collection of **items** from the filter-data, based on an _item-set_
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The application redux state-tree
 * @param {string} dataSetId The dataset to be searched ID
 * @param {Array<Number>} itemKeys The item keys collection
 */
const getGlobalFiltersDataSetItemsByKeySet = (state, dataSetId, itemKeys) => {
	const dataSet = getGlobalFiltersDataSetDataList(state, dataSetId)
	let orderedItemKeys

	//No dataset found to the specified dataSetId or no items selected
	//Empty return
	if (!dataSet || !itemKeys || itemKeys.length === 0) return []

	//Orders the array for speeding up the search below
	orderedItemKeys = itemKeys.sort()

	//Returns items which are selected by itemKeys, then gets only value prop from it
	return dataSet.filter((item) => orderedItemKeys.includes(item.key))
}

/**
 * [memoised] Selects the collection of **items** from the filter-data, based on an _item-set_
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFiltersDataSetItemsByKeySet = () =>
	createSelector(
		getGlobalFiltersDataSetItemsByKeySet,
		(items) => items
	)
