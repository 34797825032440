import { createSelector } from 'reselect'
import { makeGetAuthUserID } from '../session'

/**
 * Selector for action list, on action pages, filtering, ordering, counting, all should be done here
 * @author Davi Wegner
 * @description Created the function
 * @version 1.0
 */

// const filterByAuthor = () => {
// TODO externalize the filter as function for filtering by author
// }

// const orderByDate = () => {
// TODO externalize the sort as function for ordering by date
// }
/**
 * function created for applying filters on action list
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 * @param {*} actionIds
 * @param {*} actionList
 * @param {*} selectedValues
 * @param {*} uid
 */
const applyActionFilters = (
	actionIds = [],
	actionList,
	selectedValues,
	uid
) => {
	let filteredIds = []
	let isFiltered = false
	Object.keys(selectedValues).map((key) => {
		if (
			key !== 'until' &&
			key !== 'from' &&
			selectedValues[key].length !== 0
		) {
			//date filtering has its own functions, so we cant filter here
			//if the key has no value, we do not filter
			if (filteredIds.length === 0 && !isFiltered) {
				//if the actions wasnt filtered and filtered actions length is 0, we must filter from the initial value
				filteredIds = actionIds.filter((actionId) => {
					if (actionList[actionId].author === uid) {
						//check if action belongs to logged user
						if (key !== 'author') {
							//if the action belongs to the logged user and the key for filtering is not author, we must filter
							return selectedValues[key].includes(
								key.split('_').reduce(
									(a, b) => a[b],
									actionList[actionId] // this acess depper the actionObject, the a is the acummulator(the current object), the b the next key to be acessed
								)
							)
						} else {
							//if the action belongs to the logged user and the key for filtering is author, we must not filter
							return true
						}
					} else {
						if (key !== 'receiver_key') {
							//if the action is not from the logged user, and the key is not receiver_key, we must filter
							return selectedValues[key].includes(
								key
									.split('_')
									.reduce(
										(a, b) => a[b],
										actionList[actionId]
									) // this acess depper the actionObject, the a is the acummulator(the current object), the b the next key to be acessed
							)
						} else {
							//if the action does not belong to the logged user, and the key is receiver_key, we must not filter
							return true
						}
					}
				})
			} else {
				//if there is already a filter applied we must filter on the filtered result
				filteredIds = filteredIds.filter((actionId) => {
					if (actionList[actionId].author === uid) {
						if (key !== 'author') {
							//if the action belongs to the logged user and the key for filtering is not author, we must filter
							return selectedValues[key].includes(
								key
									.split('_')
									.reduce(
										(a, b) => a[b],
										actionList[actionId]
									) // this acess depper the actionObject, the a is the acummulator(the current object), the b the next key to be acessed
							)
						} else {
							//if the action belongs to the logged user and the key for filtering is author, we must not filter
							return true
						}
					} else {
						if (key !== 'receiver_key') {
							//if the action is not from the logged user, and the key is not receiver_key, we must filter
							return selectedValues[key].includes(
								key
									.split('_')
									.reduce(
										(a, b) => a[b],
										actionList[actionId]
									) // this acess depper the actionObject, the a is the acummulator(the current object), the b the next key to be acessed
							)
						} else {
							//if the action does not belong to the logged user, and the key is receiver_key, we must not filter
							return true
						}
					}
				})
			}
			isFiltered = true
		}
	})
	if (isFiltered) {
		return applyDateFilter(
			actionList,
			Array.from(new Set(filteredIds)),
			selectedValues
		)
	}

	return applyDateFilter(actionList, actionIds, selectedValues)
}
const applyDateFilter = (actionList, actionIds, selectedValues) => {
	let isFiltered = false
	let filteredIds = []
	let fromDate = Date.parse(selectedValues.from + 'T00:00:00Z')
	let untilDate = Date.parse(selectedValues.until + 'T23:59:59Z')
	if (fromDate.toString() !== 'NaN') {
		filteredIds = actionIds.filter(
			(actionId) => fromDate < actionList[actionId].date.seconds * 1000
		)
		isFiltered = true
	}
	if (untilDate.toString() !== 'NaN') {
		filteredIds = (isFiltered ? filteredIds : actionIds).filter(
			(actionId) => untilDate > actionList[actionId].date.seconds * 1000
		)
		isFiltered = true
	}
	return isFiltered ? filteredIds : actionIds
}
const makeGetVisibleActions = () => {
	return createSelector(
		[getActions, getAuthor, makeGetAuthUserID(), getFilterValeus],
		(actionList, byAuthor, uid, selectedValues) => {
			
			// if (!!actionList) {
			// 	let actionListObj = {
			// 		generated: {
			// 			count: 0,
			// 			open: 0,
			// 			closed: 0
			// 		},
			// 		received: {
			// 			count: 0,
			// 			open: 0,
			// 			closed: 0
			// 		},
			// 		// total: Object.keys(actionList).length,
			// 		total: 0,
			// 		filteredActionList: null
			// 	}

			if (!!actionList) {
				let actionListObj = {
						generated: {
							count: 0,
							open: 0,
							closed: 0
						},
						received: {
							count: 0,
							open: 0,
							closed: 0
						},
						total: 0,
						filteredActionList: null,
						newActionList: [],
						actionsId: []
					}
				let object = [],
					actionsId = []

				Object.values(actionList).forEach((item, index) => {
					if (item.receiver !== null &
						item.receiver !== undefined) {
							actionListObj.total += 1

							object.push(item)

							Object.keys(actionList).forEach((it, i) => {
								if (index === i)
									actionsId.push(it)
							})
						}
				})
				actionListObj.newActionList.push(...object)
				actionListObj.actionsId.push(...actionsId)

				actionListObj.filteredActionList = applyActionFilters(
					//apply filters on ids
					// Object.keys(actionList),
					actionListObj.actionsId,
					actionList,
					selectedValues,
					uid
				) //iterate through  actionList Id's

				actionListObj.filteredActionList
					.filter((actionId) => {
						let validate = actionList[actionId].author === uid //filtering by context

						actionListObj[
							validate ? 'generated' : 'received'
						].count += 1
						if (actionList[actionId].active)
							actionListObj[
								validate ? 'generated' : 'received'
							].open += 1
						else
							actionListObj[
								validate ? 'generated' : 'received'
							].closed += 1

						return (byAuthor === 0) === validate
					})
					//function for ordering by date
					.sort((actionIdA, actionIdB) => {
						//see array native sort function
						// if return is less than 0, sort actionIdA to an index lower than actionIdB
						//(in this case, if actionIdA is newer than b in date.seconds returns -1 else returns 1)
						if (
							actionList[actionIdA].date.seconds >
							actionList[actionIdB].date.seconds
						) {
							return -1
						} else {
							return 1
						}
					})
					.forEach((actionId) => {
						//create actionIds objects in order and filtered and returns it
						actionListObj.actionList = Object.assign(
							{},
							actionListObj.actionList,

							{
								[actionId]: actionList[actionId]
							}
						)
					})
				return actionListObj
			}
		}
	)
}

export const getActions = (state) => state.actionsState.actionList
// const getAuthUserId = (state) => state.sessionState.authUser.uid
const getAuthor = (state) => state.actionsStateByAuthor.byAuthor
const getFilterValeus = (state) => state.actionFilterState.selectedValues
export const getReaderType = (state, ownProps) => {
	return state.actionsStateByAuthor.byAuthor != null
		? state.actionsStateByAuthor.byAuthor === 0
			? 'authorOpen'
			: 'receiverOpen'
		: 'authorOpen'
}
export { makeGetVisibleActions }
