const style = (theme) => ({
	button: {
		color: theme.palette.primary.contrastText
	},
	container: {
		margin: theme.spacing.unit / 2
	},
	sortButton: {
		margin: theme.spacing.unit / 2
	}
})

export default style
