import React from 'react'
import PropTypes from 'prop-types'
import { ListItemText, withStyles } from '@material-ui/core'
import { translate } from '../../../utils/lang'

/**
 * Shows a Hint list-item-text
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added default labels
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added gutters support
 * @author brunoteixeirasilva
 * @version 1.2
 */
const Hint = ({ gutters, secondary, primary, classes }) => (
	<ListItemText
		className={!gutters ? classes.root : classes.rootWithGutters}
		primary={primary}
		secondary={secondary}
	/>
)

const styles = (theme) => ({
	root: {
		marginTop: theme.spacing.unit,
		borderBottom: `1px dashed ${theme.palette.secondary.light}`
	},
	rootWithGutters: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
		borderBottom: `1px dashed ${theme.palette.secondary.light}`
	}
})

Hint.defaultProps = {
	gutters: false,
	secondary: translate('label/company-hint-default-secondary'),
	primary: translate('label/company-hint-default-primary')
}

Hint.propTypes = {
	gutters: PropTypes.bool
}

export default withStyles(styles)(Hint)
