export const MANAGE_AUTOCHECK_LOADING_STATE = 'MANAGE_AUTOCHECK_LOADING_STATE',
    LOAD_AUTOCHECK_DATA = 'LOAD_AUTOCHECK_DATA',
    SET_AUTOCHECK_PERIOD = 'SET_AUTOCHECK_PERIOD'

export const setAutocheckPeriod = (dateConfig, value, formType) => ({
    type: SET_AUTOCHECK_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadAutocheckData = (data) => ({
    type: LOAD_AUTOCHECK_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_AUTOCHECK_LOADING_STATE,
    isLoading
})
