import { store } from '../../../../store'
import { makeGetRootDashItemSteps } from '../../../../selectors/dashItem/step'
import { getGlobalFilterValues } from '../../../../selectors/filter'
import memoize from '../../../../utils/memoize'

/**
 * Checks whether passed data-set is dependent on something
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The data-set id to have its datasets accessed
 * @param {string} stepId The step which we are referring to
 * @param {string} dataSetName The data-set being accessed
 */
export const dataSetHasDependencies = (dashItemId, stepId, dataSetName) => {
	const getDataSetDependencies = makeGetDataSetDependencies(),
		dependencies = getDataSetDependencies(dashItemId, stepId, dataSetName)

	//If the step is valid,
	//The dataset has configs
	//has dependencies mapped
	return !!dependencies && dependencies.length > 0
}

/**
 * [memoised] Gets a passed data-set configs
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The data-set id to have its datasets accessed
 * @param {string} stepId The step which we are referring to
 * @param {string} dataSetName The data-set being accessed
 */
export const makeGetDataSetConfigs = () =>
	memoize((dashItemId, stepId, dataSetName) => {
		const state = store.getState(),
			getDashItemSteps = makeGetRootDashItemSteps(),
			steps = getDashItemSteps(state, dashItemId),
			currentStep = !!steps && !!steps[stepId] ? steps[stepId] : false,
			dataSetConfigs =
				currentStep &&
				currentStep.dataSetConfigs &&
				currentStep.dataSetConfigs[dataSetName]
					? currentStep.dataSetConfigs[dataSetName]
					: false

		//If the step is valid,
		//The dataset has configs
		//has dependencies mapped
		return dataSetConfigs
	})

/**
 * [memoised] Gets a passed data-set dependencies
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The data-set id to have its datasets accessed
 * @param {string} stepId The step which we are referring to
 * @param {string} dataSetName The data-set being accessed
 */
export const makeGetDataSetDependencies = () => {
	const getDataSetConfigs = makeGetDataSetConfigs()

	return memoize((dashItemId, stepId, dataSetName) => {
		const dataSetConfigs = getDataSetConfigs(
			dashItemId,
			stepId,
			dataSetName
		)

		//If the configuration set is valid,
		//has dependencies mapped? blows it
		return !!dataSetConfigs && !!dataSetConfigs.dependsOn
			? dataSetConfigs.dependsOn
			: null
	})
}

/**
 * Checks whether passed data-set should be fetching or not,
 * based on premises defined by rules like:
 * * Dependencies
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The data-set id to have its datasets accessed
 * @param {string} stepId The step which we are referring to
 * @param {string} dataSetName The data-set being accessed
 */
export const dataSetShouldFetch = (dashItemId, stepId, dataSetName) => {
	const hasDeps = dataSetHasDependencies(dashItemId, stepId, dataSetName),
		filtersAvail = checkDependencyFiltersAvailable(
			dashItemId,
			stepId,
			dataSetName
		)

	return !hasDeps || (hasDeps && filtersAvail) //&& !filtersAvail
}

/**
 * Checks whether passed data-set filters are all available, returns true if all available, false if some not
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The data-set id to have its datasets accessed
 * @param {string} stepId The step which we are referring to
 * @param {string} dataSetName The data-set being accessed
 */
const checkDependencyFiltersAvailable = (dashItemId, stepId, dataSetName) => {
	const state = store.getState(),
		getDataSetConfigs = makeGetDataSetConfigs(),
		configs = getDataSetConfigs(dashItemId, stepId, dataSetName),
		filterValuesByKey = getGlobalFilterValues(state),
		filterKeys = Object.keys(filterValuesByKey)

	//Checking all filters which are dependency are available
	return (
		!!configs &&
		!!configs.dependsOn &&
		configs.dependsOn.every((dependency) => dependency in filterKeys)
	)
}
