import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import { tryTranslate } from '../../../utils/lang'
import * as routes from '../../../constants/routes'
import { showSnackBar } from '../../../actions/snackbar'
import withAuthentication from '../withAuthentication'

/**
 * Component for track error in application
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Implementing redirect
 * @author davispindola
 * @version 1.1
 *
 * @description Remove Raven
 * @author Lucas Lopes
 * @version 1.2
 */

class ErrorBoundary extends React.Component {
	state = {
		hasError: false,
	}

	// static getDerivedStateFromError(error) {
	// 	this.setState({ hasError: true })
	// }

	/**
	 * @author brunoteixeira
	 *
	 * @param {*} originalError
	 * @param {*} info
	 *
	 */
	componentDidCatch(originalError, info) {
		const { emitMessage, authUser } = this.props

		//Will try and translate the error firstly
		let translatedError = tryTranslate(originalError.message)

		//Debugging mode
		if (!!this.props.debug && process.env.REACT_APP_NODE_ENV.trim() === 'development') {
			console.error('An error occurred within the App.')
			console.error(originalError)
			console.error(info)
		} else if (process.env.REACT_APP_NODE_ENV === 'production') {
			//When in production env, will set user info at the error
		}
		emitMessage(!translatedError ? originalError.message : translatedError)

		this.setState({ hasError: true })

		//Notifies the system an error has occurred
	}

	render() {
		const { children, location } = this.props
		const { hasError } = this.state

		return !!hasError ? <Redirect from={location.pathname} to={routes.ERROR_PAGE} /> : children
	}
}

const mapDispatchToProps = dispatch => ({
	emitMessage: notification => dispatch(showSnackBar(notification)),
})

export default compose(withRouter, withAuthentication, connect(null, mapDispatchToProps))(ErrorBoundary)
