import React from 'react'
import PropTypes from 'prop-types'
import CompanySelectFormContainer from './form/CompanySelectFormContainer'
import { Dialog, DialogTitle, withStyles } from '@material-ui/core'
import { translate } from '../../../../utils/lang'

/**
 * Manages to display a screen dialog for user-company-activation
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed to render a differente message, in case the user has no companies.
 * 				Also, now the component has a totally separate state layer. Data won't be
 * 				mutated inside the render() method. Currently, components should have props
 * 				injected ready for usage.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Moved responsibilities to a higher-order component. This cares for displaying the base dialog.
 * @author brunoteixeirasilva
 * @version 1.2
 */
const CompanySelect = (props) => {
	const { classes, companyKeys, companiesLoading, fullWidth } = props
	return (
		<Dialog open aria-labelledby="require-company-select">
			{!companiesLoading && (
				<DialogTitle id="require-company-select">
					{companyKeys.length > 0
						? translate('label/select-a-company')
						: translate('tip/welcome-request-access-to-company')}
				</DialogTitle>
			)}
			<div className={classes.fieldCenterer}>
				<CompanySelectFormContainer
					classes={classes}
					fullWidth={fullWidth}
				/>
			</div>
		</Dialog>
	)
}
const styles = (theme) => ({
	fieldCenterer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	}
})

CompanySelect.propTypes = {
	classes: PropTypes.object,
	companyKeys: PropTypes.array.isRequired,
	companiesLoading: PropTypes.bool.isRequired,
	fullWidth: PropTypes.bool
}

export default withStyles(styles)(CompanySelect)
