import React from 'react'
import { withStyles, FormLabel, Chip } from '@material-ui/core'
import defaultFilterGet from '../../../../../services/cloud/functions/sigs/filter'

const styles = (theme) => ({
	optionsContainer: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		margin: theme.spacing.unit / 2
	},
	formChipSelected: {
		margin: theme.spacing.unit / 2,
		color: '#fff'
	},
	formChip: {
		margin: theme.spacing.unit / 2
	},
	container: {
		margin: theme.spacing.unit / 2
	}
})
class ChipFormSelect extends React.Component {
	componentDidMount() {
		this.fetchFilterData()
	}
	fetchFilterData = () => {
		const { dataSet, loadData, dataSourceId } = this.props
		if (!dataSet) return

		defaultFilterGet(null, dataSourceId, dataSet).then((result) => {
			loadData(dataSet, result.data)
		})
		// .catch((err) => console.log(err))
	}
	render() {
		const {
			options,
			label,
			classes,
			onSelect,
			dataSet,
			filterId,
			selectedValues
		} = this.props
		return (
			<div className={classes.container}>
				<FormLabel>{label}</FormLabel>
				<div className={classes.optionsContainer}>
					{!!options &&
						Object.keys(options).map((option) => {
							return (
								<Chip
									key={option}
									onClick={(event) =>
										onSelect(
											event,
											options[option].key,
											dataSet,
											filterId,
											options[option]
										)
									}
									color={
										!!selectedValues &&
										!!selectedValues.includes(
											options[option].key
										)
											? 'primary'
											: 'default'
									}
									className={classes.formChip}
									label={options[option].label}
								/>
							)
						})}
				</div>
			</div>
		)
	}
}

ChipFormSelect.propTypes = {}

export default withStyles(styles)(ChipFormSelect)
