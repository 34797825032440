import { createSelector } from 'reselect'
import {
	makeGetGlobalFilterValues,
	makeGetGlobalPluginFilters,
} from '../../filter'
import { getCompanyDashItemStepAllowedFilters } from './step'

/**
 * Gets the **company-dash-item-set** data from redux state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getCompanyDashItem = (state, dashItemId) => {
	const dashItems = state.companyDashItemState.dashItems

	return !!dashItems && !!dashItems[dashItemId] ? dashItems[dashItemId] : null
}

/**
 * [Memoised] Factory for the **company-dash-item-set** data from redux state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompanyDashItem = () =>
	createSelector(getCompanyDashItem, dashItem => dashItem)

/**
 * [Memoised] Factory for the **company-dash-item-set** data from redux state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompanyDashItemFilterValues = () =>
	createSelector(
		[
			getCompanyDashItemStepAllowedFilters,
			makeGetGlobalPluginFilters(),
			makeGetGlobalFilterValues(),
		],
		(allowedFilters, pluginFilters, filterValues) => {
			//Allowed filters is empty or
			//Is an array but empty
			if (
				!allowedFilters ||
				(allowedFilters instanceof Array && allowedFilters.length === 0)
			)
				return filterValues

			//When no pluginFilters were loaded yet (considers all are valid)
			if (!pluginFilters) return filterValues

			//TODO: solve the multiple calculations for this method
			//console.log(allowedFilters)

			//Reducing each data item with allowed filters
			let result = allowedFilters
				.map(singleFilterKey => pluginFilters[singleFilterKey])
				.reduce((resultantFilters, allowedFilterObject) => {
					//FIXME: check whether bug-1208
					if (
						allowedFilterObject === undefined ||
						allowedFilterObject === null
					)
						return resultantFilters

					//Using the allowedFilter object, gets a dataSet name
					let filterDataSet = allowedFilterObject.dataSet

					//If the allowed filter prop is available at filter values
					//Clones the array adding it to the resultant ones for
					//dashItem
					if (
						filterValues.hasOwnProperty(filterDataSet) &&
						filterValues[filterDataSet] instanceof Array
					) {
						resultantFilters[filterDataSet] = [
							...filterValues[filterDataSet],
						]
					}

					//Then returns the resultant object
					return resultantFilters
				}, {})

			return result
		},
	)

// /**
//  * Gets the _company-dash-item_ **allowed-filters** data from redux state
//  *
//  * @description Created the function
//  * @author brunoteixeirasilva
//  * @version 1.0
//  */
// const getCompanyDashItemAllowedFilters = (state, dashItemId) => {
// 	const dashItem = getCompanyDashItem(state, dashItemId)

// 	return !!dashItem && dashItem.allowedFilters
// 		? dashItem.allowedFilters
// 		: null
// }

// /**
//  * [Memoised] Factory/selector for the _company-dash-item_ **allowed-filters** data from redux state
//  *
//  * @description Created the function
//  * @author brunoteixeirasilva
//  * @version 1.0
//  */
// export const makeGetCompanyDashItemAllowedFilters = () =>
// 	createSelector(
// 		getCompanyDashItemAllowedFilters,
// 		(allowedFilters) => allowedFilters
// 	)

/**
 * Gets the **company-dash-item-is-loading-set** data from redux state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getCompanyDashItemsLoading = (state, dashItemId) => {
	const isLoading = state.companyDashItemState.isLoading

	return !!isLoading && !!isLoading[dashItemId] ? isLoading[dashItemId] : null
}

/**
 * [Memoised] Factory for the **company-dash-item-loading** data from redux state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompanyDashItemsLoading = () =>
	createSelector(getCompanyDashItemsLoading, isLoading => isLoading)

/**
 * Gets the **company-dash-item-data** data from redux state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const getCompanyDashItemData = (state, dashItemId) => {
	const data = state.companyDashItemState.data
	return !!data && !!data[dashItemId] ? data[dashItemId] : null
}

/**
 * [Memoised] Factory for the **company-dash-item-data** data from redux state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompanyDashItemData = () =>
	createSelector(
		getCompanyDashItemData,
		data => data,
		// TODO: make it work with immutable (data) => (!!Map.isMap(data) ? data.toObject() : data)
	)
