const INITIAL_STATE = {
	companies: null
}

/**
 * Declares the Redux company state manager
 * @author Davi Wegner
 * @param {Object} state
 * @param {Object} action
 * @version 1.0
 */
function companyReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'ALL_COMPANIES_LOADED': {
			return Object.assign({}, state, {
				companies: Object.assign({}, state.companies, action.companies)
			})
		}
		case 'CLEAR_LIST_COMPANIES': {
			return Object.assign({}, state, INITIAL_STATE)
		}
		default:
			return state
	}
}

export default companyReducer
