import {
    MANAGE_AUTOCHECK_LOADING_STATE,
    LOAD_AUTOCHECK_DATA,
    SET_AUTOCHECK_PERIOD
} from '../../actions/autocheck'

const INITIAL_STATE = {
	isLoading: null,
    // firstMount: true,
	data: null,
	dateConfig: {},
	update: false,
    localFilters: null,
	ownFilters: {},
	searchText: {},
    defaultTypeDatePicker: 'day',
    idLocalFilter: {
        localBases: null,
        localClients: null,
        localLocalities: null,
        collaborators: null,
        justifyType: null,
        localTypeObjects: null,
        objects: null,
        statusAutocheck: null
    }
}

/**
 * @description clear all local filters
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
const clearAllLocalFilters = (state) => {
    let keys = Object.keys(state.idLocalFilter),
        clearFilters = {}

    keys.forEach((key) => {
        clearFilters[key] = null
    })

    return clearFilters
}

/**
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
const autocheckReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_AUTOCHECK_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_AUTOCHECK_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case 'SET_AUTOCHECK_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_AUTOCHECK_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_AUTOCHECK_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
                idLocalFilter: clearAllLocalFilters(state)
			})
		}
        case 'SET_DATE_CONFIG': {
			return Object.assign({}, state, {
				dateConfig: action.dateConfig
			})
		}
        case 'SET_LOCAL_FILTER_AUTOCHECK': {
            return Object.assign({}, state, {
				localFilters: action.localFilters
			})
        }
        case 'SET_SEARCH_TEXT_AUTOCHECK': {
            let searchText = {}
            action.objectKeys.forEach((item) => {
                searchText[item] = ''
            })
            return Object.assign({}, state, {
                searchText: searchText
            })
        }
        case 'SET_ID_LOCAL_FILTERS_AUTOCHECK': {
            return Object.assign({}, state, {
                idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter)
            })
        }
        case SET_AUTOCHECK_PERIOD: {

            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        case 'SET_DEFAULT_TYPE_DATE_CONFIG': {
			return Object.assign({}, state, {
				defaultTypeDatePicker: action.defaultTypeDatePicker
			})
		}
        default:
            return state
    }
}

export default autocheckReducer
