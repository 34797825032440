import {
	createSelector
} from 'reselect'

const makeDashItemsFormatter = () =>
	createSelector(
		[getDashItems],
		(dashItems) => {
			if (!dashItems) return null
			if (!(dashItems instanceof Object)) return null	

			return Object.keys(dashItems).map((dashItem) => ({
				id: dashItem,
				...dashItems[dashItem]
			}))
		}
	)

const getDashItems = (state) => state.portalDevelopState.dashItems


export default makeDashItemsFormatter