import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles, Typography, Chip } from '@material-ui/core'
import Loader from './../../../../reusable/loader'
import withActiveCompany from '../../../../reusable/withActiveCompany'
import ImagePreview from './ImagePreview'
import ListOfReceivers from './ListOfReceivers'
import { showSnackBar } from '../../../../../actions/snackbar'
import { detailsStyles } from './styles'

const types = {
	0: 'tipo não definido',
	1: 'alerta',
	2: 'risco',
	3: 'elogio'
}

const classification = {
	0: 'não classificada',
	1: 'Risco Alto',
	2: 'Risco Médio',
	3: 'Risco Baixo',
	4: 'Oportunidade de Melhora'
}

const ActionChips = ({ activeAction, classes }) => (
	<div className={classes.chips}>
		<Chip label={types[activeAction.content.type]} />
		<Chip label={classification[activeAction.content.classification]} />
	</div>
)

const ActionContent = ({ activeAction, classes }) => (
	<div className={classes.observation}>
		<Typography>{activeAction.content.observation}</Typography>
	</div>
)

/**
 * @author davispindola
 * @version 1.0.0
 *
 * @author Davi Wegner
 * @description added close action button as requested
 * @version 1.1
 *
 *  @description removed close action button (moved to details container)
 *	@author Davi Wegner
 *	@version 1.2

 *  @description added set view action state function,
 *  for updating view state when rendered for actions that werent open
 *	@author Davi Wegner
 *	@version 1.3
 */
class Details extends React.Component {
	state = {
		ccReceivers: [],
		snapshotPath: ''
	}

	render() {
		const { classes, activeAction, author } = this.props

		return (
			<div className={classes.root}>
				{author !== null ? (
					<div className={classes.container}>
						<ActionChips
							activeAction={activeAction}
							classes={classes}
						/>
						<ActionContent
							activeAction={activeAction}
							classes={classes}
						/>
						<ImagePreview path={activeAction.snapshotURL} />
						<ListOfReceivers action={activeAction} />
					</div>
				) : (
					<Loader internal />
				)}
			</div>
		)
	}
}

Details.propTypes = {
	classes: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		// actionId: state.actionsState.actionId
	}
}

const mapDispatchToProps = (dispatch) => ({
	emitMessage: (notification) => dispatch(showSnackBar(notification))
})

export default compose(
	withActiveCompany,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(detailsStyles)
)(Details)
