export const MANAGE_HISTORY_WIRE_CHARGE_LOADING_STATE =
		'MANAGE_HISTORY_WIRE_CHARGE_LOADING_STATE',
	LOAD_HISTORY_WIRE_CHARGE_DATA = 'LOAD_HISTORY_WIRE_CHARGE_DATA',
	SET_HISTORY_WIRE_CHARGE_PERIOD = 'SET_HISTORY_WIRE_CHARGE_PERIOD'

export const setHistoryWireChargePeriod = (dateConfig, value, formType) => ({
	type: SET_HISTORY_WIRE_CHARGE_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadHistoryWireChargeData = (historyWireCharge) => ({
	type: LOAD_HISTORY_WIRE_CHARGE_DATA,
	historyWireCharge
})

export const setLoading = (isLoading) => ({
	type: MANAGE_HISTORY_WIRE_CHARGE_LOADING_STATE,
	isLoading
})
