import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Icon, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { styles } from './styles'
import { translate } from '../../../../../utils/lang'
import ActionFooter from './ActionFooterContainer'
import makeActionItemFormater from '../../../../../selectors/action/item'
/**
 * ActionItem UI class
 *
 * @description Created the component
 * @author Davi Spindola
 * @version 1.0
 *
 * @description Added suport for translate
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description refactor, card name now on redux, for no memory leaking error
 * @author Davi Wegner
 * @version 1.2
 *
 * @description refactor, action item now does not load anything any more, was it was causing slowness on page
 * , since for each action a request was made to get card ID, and now is a func component, not a class anymore
 * , and does not connect to redux
 * @author Davi Wegner
 * @version 1.3
 *
 * @description deleted updated view function
 * , now the view state of action is done
 *  when rendering the modal details component
 * @author Davi Wegner
 * @version 1.4
 *
 * @description added connect to redux, container is no longer needed
 * @author Davi Wegner
 * @version 1.5
 */
const ActionItem = (props) => {
	const {
		classes,
		handleClick,
		actionId,
		date,
		title,
		classification,
		observation,
		status,
		type,
		wasRead,
		hasAnswer,
		receiverRead,
		userInfo,
		readerType,
		setActiveActionId
	} = props

	return (
		<div className={classes.root}>
			<div
				className={!wasRead ? classes.content : classes.contentRead}
				// style={{
				// 	borderLeft: `2px solid ${!wasRead ? 'blue' : 'grey'}`
				// }}
			>
				<div onClick={() => handleClick(actionId)}>
					<div className={classes.header}>
						<Typography
							className={
								!wasRead
									? classes.cardTitle
									: classes.cardTitleRead
							}
							// style={{
							// 	fontWeight: 'bold',
							// 	color: `${!wasRead ? 'blue' : 'grey'}`
							// }}
							children={title}
						/>
						<Typography
							component="div"
							children={
								<div className={classes.creation}>
									<Tooltip
										title={translate('label/creation')}
									>
										<Icon>today</Icon>
									</Tooltip>
									{date}
								</div>
							}
						/>
					</div>
					<Typography variant="body2" children={userInfo} />
					<Typography
						noWrap
						className={classes.observation}
						children={observation}
					/>
				</div>
				<ActionFooter
					actionId={actionId}
					type={type}
					classification={classification}
					status={status}
					hasAnswer={hasAnswer}
					receiverRead={receiverRead}
					readerType={readerType}
					classes={classes}
					handleClick={setActiveActionId}
				/>
			</div>
		</div>
	)
}

const makeMapStateToProps = () => {
	const getActionItem = makeActionItemFormater()
	const mapStateToProps = (state, ownProps) => {
		return {
			...getActionItem(state, ownProps)
		}
	}
	return mapStateToProps
}

ActionItem.propTypes = {
	cardTitle: PropTypes.string, //.isRequired, cards dont have title anymore
	classes: PropTypes.object.isRequired
}

ActionItem.defaultProps = {
	cardTitle: ''
}

export default compose(
	connect(makeMapStateToProps()),
	withStyles(styles)
)(ActionItem)
