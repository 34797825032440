import {
	MANAGE_ACCUMULATED_VOLUME_LOADING_STATE,
	LOAD_ACCUMULATED_VOLUME_DATA,
	SET_ACCUMULATED_VOLUME_PERIOD
} from '../../actions/accumulatedVolume'

const INITIAL_STATE = {
	isLoading: null,
	accumulatedVolume: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {}
}
/**
 * accumulatedVolume state reducer manager
 * @param {*} state
 * @param {*} action
 */

const accumulatedVolumeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_ACCUMULATED_VOLUME_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_ACCUMULATED_VOLUME_DATA: {
			return Object.assign({}, state, {
				accumulatedVolume: action.accumulatedVolume,
				isLoading: false
			})
		}
		case 'SET_ACCUMULATED_VOLUME_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_ACCUMULATED_VOLUME_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_ACCUMULATED_VOLUME_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_ACCUMULATED_VOLUME_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default accumulatedVolumeReducer
