import { createSelector } from 'reselect'
import { getActionById } from '../actions'
import { wasEvaluated } from '../wasEvaluated'

const getComment = (state) => state.evaluationUserActionsState.comment

const makeGetEvaluationComment = () => {
	return createSelector(
		[getActionById, getComment],
		(action, comment) => {
			if (wasEvaluated(action, 'evaluate')) {
				return action.evaluate.comment
			}

			return comment
		}
	)
}

export default makeGetEvaluationComment
