export const actionDone = {
	0: 'label/select',
	1: 'label/meeting',
	2: 'label/cost-reduction',
	3: 'label/communication-sending',
	4: 'label/registration-correction',
	5: 'label/demission',
	6: 'label/advertence',
	7: 'label/goal-review',
	8: 'label/correction-of-pointing',
	9: 'label/email-sending'
}

export const actionDoneIn = {
	0: 'label/select',
	1: 'label/client',
	2: 'label/team',
	3: 'label/leader',
	4: 'label/administrative',
	5: 'label/corporative',
	6: 'label/autocheck'
}
