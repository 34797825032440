import {
    MANAGE_RESULT_CHECK_LIST_LOADING_STATE,
    LOAD_RESULT_CHECK_LIST_DATA,
    SET_RESULT_CHECK_LIST_PERIOD
} from '../../actions/resultCheckList'

const INITIAL_STATE = {
    isLoading: null,
    data: null,
    dateConfig: {},
    update: false,
    dataSetObject: null,
    ownFilters: {},
	searchText: {
        statusAudit: '',
        status: '',
        object: '',
        form: '',
        colName: '',
        statusCol: '',
        nmrAudit: '',
        autocheck: ''
    }
}
/**
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */

const resultCheckListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_RESULT_CHECK_LIST_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_RESULT_CHECK_LIST_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case 'SET_RESULT_CHECK_LIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_RESULT_CHECK_LIST_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_RESULT_CHECK_LIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
        case 'SET_DATA_SET_OBJECT_VALUE': {
			return Object.assign({}, state, {
				dataSetObject: action.dataSetObject
			})
		}
        case SET_RESULT_CHECK_LIST_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        default:
            return state
    }
}

export default resultCheckListReducer
