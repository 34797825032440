const styles = (theme) => ({
	horizontalList: {
		display: 'flex'
	},
	root: {
		height: '100%',
		width: '100%'
		// ...getWidths(theme)
		// [theme.breakpoints.up('xs')]: {
		// },
		// [theme.breakpoints.up('sm')]: {
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	justifyContent: 'center',
		// 	flexDirection: 'column',
		// 	width: '100%',
		// 	flex: '0 0 auto',
		// 	marginTop: '0'
		// }
	},
	form: {
		'& > div': {
			paddingTop: 10,
			paddingBottom: 10
		}
	},
	rating: {
		textAlign: 'center',
		'& > p': {
			paddingBottom: 10,
			paddingTop: 20,
			fontWeight: 500
		}
	},
	'was-evaluated': {
		'& div > *, span': {
			color: 'grey !important',
			borderColor: 'grey'
		},
		'& div > span, p, li': {
			color: 'grey !important',
			borderColor: 'grey'
		}
	}
})

export default styles
