export const MANAGE_GOAL_TRACKING_LOADING_STATE =
		'MANAGE_GOAL_TRACKING_LOADING_STATE',
	LOAD_GOAL_TRACKING_DATA = 'LOAD_GOAL_TRACKING_DATA',
	SET_GOAL_TRACKING_PERIOD = 'SET_GOAL_TRACKING_PERIOD'

export const setGoalTrackingPeriod = (dateConfig, value, formType) => ({
	type: SET_GOAL_TRACKING_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadGoalTrackingData = (goalTracking) => ({
	type: LOAD_GOAL_TRACKING_DATA,
	goalTracking
})

export const setLoading = (isLoading) => ({
	type: MANAGE_GOAL_TRACKING_LOADING_STATE,
	isLoading
})
