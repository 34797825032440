const styles = (theme) => ({
	root: {
		'& > hr': {
			marginTop: 10,
			marginBottom: 10
		}
	}
})

export default styles
