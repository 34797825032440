const INITIAL_STATE = {
	permissionFilters: null
}

/**
 * Clears permission filters listed previously
 * @author Davi Wegner
 * @param {Object} state The current system state
 */
const clearPermissionFilters = (state) => {
	return Object.assign({}, state, {
		permissionFilters: INITIAL_STATE.permissionFilters
	})
}

/**
 * Adds a group permission filter singularly to the group
 * @author Davi Wegner
 * @param {Object} state The current system state
 * @param {Object} action The current action object with a prop "groupPermission"
 */
const permissionFiltersLoaded = (state, action) => {
	return Object.assign({}, state, {
		permissionFilters: Object.assign(
			{},
			state.permissionFilters,
			action.permissionFilters
		)
	})
}

/**
 * Manages the permission filters state
 * @author Davi Wegner
 * @param {Object} state
 * @param {Object} action
 */
function permissionFilterReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CLEAR_PERMISSION_FILTERS': {
			return clearPermissionFilters(state)
		}
		case 'PERMISSION_FILTERS_LOADED': {
			return permissionFiltersLoaded(state, action)
		}
		default:
			return state
	}
}

export default permissionFilterReducer
