//import Raven from 'raven-js'
import { createBrowserHistory } from 'history'
import 'react-app-polyfill/ie9' // ? Support for previous IE9-11 browsers
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import { MuiThemeProvider } from '@material-ui/core'
import axios from 'axios'
import { store, persistor } from './store/index'
import * as serviceWorker from './serviceWorker'
import { translate } from './utils/lang'
import * as Theme from './utils/object/theme'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import requestConfig from './utils/service/requestConfig'
import applyPolyfill from './utils/polyfill'
import Root from './component/app/Root'

//react-clear-browser-cache
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'

//import package json from release
import Json from '../package.json'

//SENTRY
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

//Store
import engine from 'store/src/store-engine'
import allStorages from 'store/storages/all'
import Loader from './component/reusable/loader'

// import { StatusBar } from 'cordova-plugin-statusbar'
// import cordova from 'cordova'

/**
 * Sentry Configuration
 *
 * @description created Sentry
 * @author Lucas Lopes
 * @version 1.0
 */

const history = createBrowserHistory()

Sentry.init({
	dsn: 'https://a9418c926f8a4321aa4f248ab9f59c9c@o174740.ingest.sentry.io/1197824',
	release: `Sentry version ${Json.version}`,
	ignoreErrors: [
		// Random plugins/extensions
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		"Can't find variable: ZiteReader",
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage',
	],
	denyUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	],
	beforeSend(event) {
		// Modify the event here
		console.log('Sentry verify error =>', event)

		return event
	},
	integrations: [
		new Sentry.Integrations.Breadcrumbs({
			console: process.env.REACT_APP_NODE_ENV !== 'production' ? true : false,
		}),
		new BrowserTracing({
			tracingOrigins: ['localhost', 'https://app.agildash.com.br/', 'https://stage.agildash.com.br/', /^\//],
			// Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
		}),
	],
	environment: process.env.REACT_APP_NODE_ENV,
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
	normalizeDepth: 10, // Or however deep you want your state context to be.
	whitelistUrls: ['https://app.agildash.com.br/static/js', 'https://stage.agildash.com.br/static/js'],
})

const FallbackComponent = () => {
	return <div> Ocorreu um erro </div>
}

/**
 * call applyPolyfill function on start of application
 *
 * @description created function call
 * @author Davi Wegner
 * @version 1.0
 */
applyPolyfill()

/**
 * All initial application config
 */
const INITIAL_APP_CONFIG = {
	title: translate('app/title'),
	debug: process.env.REACT_APP_NODE_ENV === 'development',
	barLeftIcon: 'menu',
	barLeftIconAction: null,
	barLeftIconAriaLabel: translate('label/open-menu'),
	request: {
		timeout: 120000,
	},
}

requestConfig(axios)

/**
 * Front-end monitorer => activates in "production" === NODE_ENV
 * Based on a global => process.env.NODE_ENV
 */
if (process.env.REACT_APP_NODE_ENV === 'production') {
	console.warn('Activating monitorer...')

	if (INITIAL_APP_CONFIG.debug) {
		console.warn('Monitorer activated. May you have a nice trip.')
	}
} else {
	// Dev or any other mode

	console.warn(`NODE_ENV: ${process.env.REACT_APP_NODE_ENV}`)
	console.warn(`DEBUG MODE: ${INITIAL_APP_CONFIG.debug ? 'ON' : 'OFF'}`)
	console.warn("Hey! Sentry monitorer won't start when NODE_ENV === 'development'.")
}

// create store
const storeBrowser = engine.createStore(allStorages, [])

const debug = data => {
	process.env.REACT_APP_NODE_ENV !== 'production' && console.log('*DEBUG MODE SENTRY* latestVersion =>', data.state.latestVersion)
}

/**
 * Add in storage version package.json Agildash
 *
 */

localStorage.setItem('APP_VERSION', `${Json.version}`)

/**
 * Add in storage version package.json Agildash
 *
 */

localStorage.setItem('APP_VERSION', `${Json.version}`)

/**
 * Gets an instance of theming object
 * ? Refer to the generator, to access client/company colours
 */
const theme = Theme.generate()

/**
 * Literally starts the app when invoked
 */
const startApp = () => {
	/**
	 * Main App renderer function
	 * Initializes provider, persistor, theme, and then, app.
	 */
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<MuiThemeProvider theme={theme}>
					<Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
						<ClearBrowserCacheBoundary
							storageKey="APP_VERSION"
							// filename="../public/meta.json"
							debug={debug}
							storage={storeBrowser}
							fallback={<Loader />}
							duration={5000}>
							<Root config={INITIAL_APP_CONFIG} />
						</ClearBrowserCacheBoundary>
					</Sentry.ErrorBoundary>
				</MuiThemeProvider>
			</PersistGate>
		</Provider>,
		document.getElementById('root')
	)

	/**
	 * Removes the previous SW installed
	 *
	 * @description Creates a service worker
	 * @author brunoteixeirasilva
	 * @version 1.0
	 *
	 * @description Changed service-worker pattern
	 * @author brunoteixeirasilva
	 * @version 2.0
	 */
	serviceWorker.unregister()
	// serviceWorker.register()
	// registerServiceWorker()
}

/**
 * When running under cordova environment,
 * Starts the app only when the things are "ready" for it
 *
 * @description Created the app starter
 * @author brunoteixeirasilva
 * @version 1.0
 */
if (window.cordova) {
	document.addEventListener(
		'deviceready',
		() => {
			//TODO: configure any globally necessary plugins here
			//Platform is iOS?
			//Status bar is available?
			//Will hide it kkkkk

			if (window.cordova.platformId === 'ios') {
				window.FirebasePlugin.grantPermission()
				if (!!window.StatusBar && typeof window.StatusBar.hide === 'function') {
					window.StatusBar.hide()
				}
			}

			startApp()
		},
		false
	)
} else {
	startApp()
}
