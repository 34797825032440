import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { generatePath } from 'react-router'
import * as routes from '../../../../../constants/routes'
import ActionList from './ActionList'
import withActiveCompany from '../../../../reusable/withActiveCompany'
import EmptyListItem from '../../../../reusable/list/empty/item'
import Loader from '../../../../reusable/loader'
import EvaluationPopup from '../../../../reusable/systemEvaluation/components/popup'
import AfterRatingNotification from '../../../../reusable/systemEvaluation/CardAction/AfterRatingNotification'
import EvaluationDialogContainer from '../../../../reusable/systemEvaluation/components/dialog'
import { ActionEvaluateContainer } from '../../../../reusable/systemEvaluation/CardAction'
import { getReaderType } from '../../../../../selectors/action'
import { SET_ACTION_ID } from '../../../../../reducers/action'
import { openActionModal } from '../../../../../reducers/action/navigation'

/**
 *
 * @description manage the action list data
 * @author davispindola
 * @version 1.0.0
 *
 * @description removed withAuthentication, as is not needed to reload any data (it was used before for getting auth user id)
 * , removed dispatch functions
 * @author Davi Wegner
 * @version 1.1
 *
 * @description getting action keys on mapStateToProps for avoiding any unexpected re render
 * @author Davi Wegner
 * @version 1.2
 *
 * @description deleted view component and added the evaluation elements here
 * @author Davi Wegner
 * @version 1.3
 */
class ActionListContainer extends React.Component {
	state = {
		isLoading: true
	}
	componentWillUnmount() {
		this.props.setNewActions()
	}
	// componentDidMount() {
	// 	if (
	// 		!this.props.actionId &&
	// 		this.props.match.params.actionId !== 'list'
	// 	) {
	// 		this.setActiveActionId(this.props.match.params.actionId)
	// 	}
	// }
	handleClick = (actionId) => {
		const { onOpenActionModal } = this.props

		this.setActiveActionId(actionId)

		onOpenActionModal()
	}
	setActiveActionId = async (actionId) => {
		const { setActionId, history, activeCompanyId } = this.props
		history.push(
			generatePath(routes.USER_ACTIONS, {
				companyId: activeCompanyId,
				actionId
			})
		)
		return setActionId(actionId)
	}
	componentDidUpdate() {
		// if (
		// 	this.props.match.params.actionId === 'list' &&
		// 	this.props.actionId !== null
		// ) {
		// 	this.props.setActionId(null)
		// }
		// if (
		// 	!this.props.actionId &&
		// 	this.props.match.params.actionId !== 'list'
		// ) {
		// 	this.setActiveActionId(this.props.match.params.actionId)
		// }
	}
	render() {
		const {
			actionList,
			isLoading,
			actionKeys,
			readerType,
			newActions,
			evaluationWillRender,
			byAuthor,
			actionId
		} = this.props

		return (
			byAuthor !== 2 && (
				<React.Fragment>
					<EvaluationPopup actionId={actionId} />

					{evaluationWillRender && (
						<EvaluationDialogContainer
							withScroll
							actionId={actionId}
							setActiveActionId={this.setActiveActionId}
						>
							<ActionEvaluateContainer
								actionId={actionId}
								listOrientation="horizontal"
							/>
						</EvaluationDialogContainer>
					)}
					<AfterRatingNotification />

					{actionList ? (
						isLoading ? (
							<Loader />
						) : (
							<ActionList
								setActiveActionId={this.setActiveActionId}
								handleClick={this.handleClick}
								newActions={newActions}
								actionList={actionList}
								actionKeys={actionKeys}
								readerType={readerType}
							/>
						)
					) : (
						<EmptyListItem
							customTranslateRef="placeholder/no-action-created"
							icon="inbox"
						/>
					)}
				</React.Fragment>
			)
		)
	}
}

const mapStateToProps = (state, { actionList }) => {
	const readerType = getReaderType(state)

	let keys
	if (actionList) {
		keys = Object.keys(actionList)
	}
	return {
		readerType: readerType,
		isLoading: state.actionsState.isLoading,
		actionKeys: keys
	}
}
const mapDispatchToProps = (dispatch) => ({
	setNewActions: (actionIds) =>
		dispatch({ type: 'SET_NEW_ACTION_IDS', actionIds }),
	setActionId: (actionId) => dispatch({ type: SET_ACTION_ID, actionId }),
	onOpenActionModal: () => dispatch(openActionModal())
})
export default compose(
	withActiveCompany,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ActionListContainer)
