export const SET_COMPANY_PLUGIN_LOADING = 'SET_COMPANY_PLUGIN_LOADING',
	SET_COMPANY_PLUGIN = 'SET_COMPANY_PLUGIN'

/**
 * [action-creator] For company plugin loading status
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} pluginId The plugin being loaded (ID)
 * @param {boolean} isLoading The loading state to be set at a plugin-key
 */
export const setCompanyPluginLoading = (pluginId, isLoading) => ({
	type: SET_COMPANY_PLUGIN_LOADING,
	pluginId,
	isLoading
})

/**
 * [action-creator] For company plugin data-setting
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} pluginId The plugin being loaded (ID)
 * @param {boolean} isLoading The loading state to be set at a plugin-key
 */
export const setCompanyPlugin = (pluginId, plugin) => ({
	type: SET_COMPANY_PLUGIN,
	pluginId,
	plugin
})
