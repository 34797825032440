const INITIAL_STATE = {}

export const ADD_FORM_VALUE = 'ADD_FORM_VALUE'
export const CLEAN_FORM_VALUE = 'CLEAN_FORM_VALUE'

/**
 * function for handling value management on form
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 */
const handleFormValue = (state, action) => {
	//if there is a key, the value will be added inside the form, on the key
	//@TODO make programmaticaly acess deeper objects, and make it work for multiple keys
	if (!!action.key) {
		return Object.assign({}, state, {
			[action.form]: Object.assign({}, state[action.form], {
				[action.key]: Object.assign(
					{},
					state[action.form][action.key],
					action.value
				)
			})
		})
	} else {
		return Object.assign({}, state, {
			[action.form]: action.value
		})
	}
}
/**
 * function for handling the cleaning of the form
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 */
const handleCleanForm = (state, action) => {
	//if a key is passed its value will be transformed to null, if not, then all the portion will be cleared
	//, and will stay as empty object {} @TODO make possible define the initial state of the portion
	if (!!action.key) {
		return Object.assign({}, state, {
			[action.form]: Object.assign({}, state[action.form], {
				[action.key]: null
			})
		})
	} else {
		return Object.assign({}, state, {
			[action.form]: INITIAL_STATE
		})
	}
}
/**
 * Reducer for stantardize the form common functions and acess to state
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 */
const formStateReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_FORM_VALUE: {
			return handleFormValue(state, action)
		}
		case CLEAN_FORM_VALUE: {
			return handleCleanForm(state, action)
		}
		case 'CLEAR_WHOLE_FORM': {
			return INITIAL_STATE
		}
		case 'SET_FORM_MODEL': {
			return Object.assign({}, state, {
				...action.model,
				model: action.model
			})
		}
		default:
			return state
	}
}

export default formStateReducer
