import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SelectWithChildren from './SelectWithChildren'
import { translate } from '../../../utils/lang'
import * as filters from '../../../constants/filters'
import * as CompanyPluginFilters from '../../../firebase/company/plugin/filters'
import { compose } from 'recompose'
import withActiveCompany from '../withActiveCompany'
import withActivePlugin from '../withActivePlugin'
import Loader from '../loader'
import {
	makeGetGlobalPluginFilters,
	makeGetGlobalFilterIsLoading,
	makeGetGlobalSelectiveFilters
} from '../../../selectors/filter'
import OptionsSetContainer from './options/OptionsSetContainer'
import {
	setGlobalPluginFilters,
	setGlobalFiltersLoading
} from '../../../actions/filter/global'
import { onSetGlobalPluginFilters } from './functions/onSetGlobalPluginFilters'

/**
 * Manages the filter list using global-filter-values
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description Changed to remove handle-change-methods
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Changed to deep map state to props
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @description Optimise the use of show-all
 * @author brunoteixeirasilva
 * @version 1.3
 *
 * @description Removed withStyles and class application
 * @author brunoteixeirasilva
 * @version 1.4
 */
class FiltersContainer extends React.Component {
	queryListener = null

	componentDidMount() {
		const { isLoading } = this.props

		if (!isLoading) this.query()
	}

	query = () => {
		const { activeCompanyId, activePluginId, setLoading } = this.props,
			self = this

		//Will set the loading flag, whenever the query wasn't run yet
		setLoading(true).then(() => {
			self.queryListener = CompanyPluginFilters.onGet(
				activeCompanyId,
				activePluginId,
				(filters) =>
					onSetGlobalPluginFilters(filters).then(() =>
						//Cancels loading state
						setLoading(false)
					),
				(error) => {
					//Cancels loading
					setLoading(false)

					//rethrows the error
					throw error
				}
			)
		})
	}

	componentWillUnmount() {
		//Unsubscribe from the query listener
		if (typeof this.queryListener === 'function') this.queryListener()
	}

	render() {
		const {
			isLoading,
			pluginFilters,
			pluginFilterKeys,
			hasPluginKeys
		} = this.props

		//TODO: generate all fields based on queried items from Firestore for active plugin
		//TODO: get names and else from translate

		return (
			<div>
				{!!isLoading && !hasPluginKeys && <Loader internal />}
				{hasPluginKeys &&
					pluginFilterKeys.map((filterKey) => (
						<SelectWithChildren
							key={pluginFilters[filterKey].dataSet}
							label={translate(
								`label/${pluginFilters[filterKey].label}`
							)}
						>
							<OptionsSetContainer
								filterKey={filterKey}
								filter={pluginFilters[filterKey]}
							/>
						</SelectWithChildren>
					))}
			</div>
		)
	}
}

FiltersContainer.defaultProps = {
	showAll: false
}

FiltersContainer.propTypes = {
	showAll: PropTypes.bool
}

const mapDispatchToProps = (dispatch) => ({
	setLoading: async (isLoading) =>
		dispatch(setGlobalFiltersLoading(isLoading)),
	onCompanyPluginFiltersLoaded: async (pluginFilters) =>
		dispatch(setGlobalPluginFilters(pluginFilters))
})

const makeMapStateToProps = () => {
	const getPluginFilters = makeGetGlobalPluginFilters(),
		getFiltersIsLoading = makeGetGlobalFilterIsLoading(),
		getSelectiveFilters = makeGetGlobalSelectiveFilters(),
		mapStateToProps = (state, ownProps) => {
			//Maps access to selective filters
			//As well as show-all access
			const plugin = ownProps.activePlugin,
				pluginFilters = getPluginFilters(state),
				hasPluginKeys = !!pluginFilters,
				pluginFilterKeys = hasPluginKeys
					? Object.keys(pluginFilters)
					: [],
				activePluginName = !!plugin
					? plugin[Object.keys(plugin)[0]].name
					: null,
				selectiveFilters = getSelectiveFilters(state),
				showAll = selectiveFilters.includes(filters.ALL),
				isLoading = getFiltersIsLoading(state)

			return {
				isLoading: isLoading,
				pluginFilters: pluginFilters,
				hasPluginKeys: hasPluginKeys,
				pluginFilterKeys: pluginFilterKeys,
				showAll: showAll,
				selectiveFilters: selectiveFilters,
				plugin: activePluginName
			}
		}

	return mapStateToProps
}

export default compose(
	withActiveCompany,
	withActivePlugin,
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(FiltersContainer)
