export const SET_PRODUCTIVITY_CYCLES_LOADING =
		'SET_PRODUCTIVITY_CYCLES_LOADING',
	SET_PRODUCTIVITY_CYCLES = 'SET_PRODUCTIVITY_CYCLES',
	SET_PRODUCTIVITY_CYCLE = 'SET_PRODUCTIVITY_CYCLE',
	CLEAR_PRODUCTIVITY_CYCLE = 'CLEAR_PRODUCTIVITY_CYCLE',
	CLEAR_PRODUCTIVITY_CYCLES = 'CLEAR_PRODUCTIVITY_CYCLES'

/**
 * [ActionCreator] for dispatching productivity-cycles-loading-state
 *
 * @description Created the action creator
 * @author brunoteixerasilva
 * @version 1.0
 *
 * @param {Array<Object>} items The collection of productivity cycles to be dispatched
 */
export const setProductivityCyclesLoading = (isLoading) => ({
	type: SET_PRODUCTIVITY_CYCLES_LOADING,
	isLoading
})

/**
 * [ActionCreator] for dispatching productivity-cycles-clearing
 *
 * @description Created the action creator
 * @author brunoteixerasilva
 * @version 1.0
 */
export const clearProductivityCycles = () => ({
	type: CLEAR_PRODUCTIVITY_CYCLES
})

/**
 * [ActionCreator] for dispatching productivity-cycles-data
 *
 * @description Created the action creator
 * @author brunoteixerasilva
 * @version 1.0
 *
 * @param {Array<Object>} items The collection of productivity cycles to be dispatched
 */
export const setProductivityCycles = (items) => ({
	type: SET_PRODUCTIVITY_CYCLES,
	items
})

/**
 * [ActionCreator] for dispatching productivity-cycles-data
 *
 * @description Created the action creator
 * @author brunoteixerasilva
 * @version 1.0
 *
 * @param {Array<Object>} productivityCycles The collection of productivity cycles to be dispatched
 */
export const setProductivityCycle = (currentCycle) => ({
	type: SET_PRODUCTIVITY_CYCLE,
	currentCycle
})

/**
 * [ActionCreator] for dispatching productivity-cycles-data
 *
 * @description Created the action creator
 * @author brunoteixerasilva
 * @version 1.0
 *
 * @param {Array<Object>} productivityCycles The collection of productivity cycles to be dispatched
 */
export const clearProductivityCycle = () => ({
	type: CLEAR_PRODUCTIVITY_CYCLE
})
