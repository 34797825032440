import { fs } from '../firebase'

/**
 * @author Davi Wegner
 */
// export const getStepsByCompanyDashItem = (companieId, dashItemId, stepId) => {
//     return fs.ref(companies)
// }
/**
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 * @param {*} companieId
 * @param {*} dashItemId
 * @param {*} stepId
 */
export const getStep = (dashItemId, stepId) => {
	return fs
		.collection('dashItems')
		.doc(dashItemId)
		.collection('steps')
		.doc(stepId)
		.get()
}

/**
 * Gets **root** dash-items' steps collection
 *
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @description added order by order field, asc
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} dashItemId The dash-item ID to get steps information
 */
export const getSteps = (dashItemId) => {
	return fs
		.collection('dashItems')
		.doc(dashItemId)
		.collection('steps')
		.orderBy('order', 'asc')
		.get()
}
