import React from 'react'
import PropTypes from 'prop-types'
import {
	ListItem,
	ListItemIcon,
	Icon,
	ListItemText,
	withStyles,
	Typography
} from '@material-ui/core'
import { translate } from '../../../../../utils/lang'

/**
 * Displays an empty list item for educational purposes
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class EmptyListItem extends React.Component {
	state = {
		defaultIcon: 'report'
	}

	render() {
		const {
			classes,
			objectName,
			icon,
			feminine,
			customTranslateRef,
			gutters
		} = this.props

		const { defaultIcon } = this.state

		return (
			<ListItem
				className={!gutters ? classes.root : classes.rootWithGutters}
			>
				<ListItemIcon>
					<Icon className={classes.icon}>
						{!icon ? defaultIcon : icon}
					</Icon>
				</ListItemIcon>
				{objectName !== null && (
					<ListItemText
						className={classes.textWrapper}
						primary={
							<Typography className={classes.text}>
								{!!customTranslateRef &&
									translate(customTranslateRef, {
										objectName
									})}
								{!customTranslateRef &&
									translate(
										`placeholder/empty-object-list${
											!!feminine ? '-fem' : ''
										}`,
										{
											objectName
										}
									)}
							</Typography>
						}
					/>
				)}
			</ListItem>
		)
	}
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		border: `1px solid #CCC`,
		borderRadius: theme.shape.borderRadius
	},
	rootWithGutters: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		border: `1px solid #CCC`,
		borderRadius: theme.shape.borderRadius,
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit
	},
	icon: {
		fontSize: '2.4em',
		color: `${theme.palette.secondary.light} !important`,
		order: 1,
		flex: '0 1 auto'
	},
	textWrapper: {
		order: 2,
		marginTop: theme.spacing.unit,
		flex: '0 1 auto'
	},
	text: {
		fontWeight: 400,
		textTransform: 'lowercase',
		//FIXME: First letter upper "capitalize" (better standard)
		'&:first-letter': { textTransform: 'uppercase' },
		color: `${theme.palette.secondary.light} !important`
	}
})

EmptyListItem.defaultProps = {
	feminine: false,
	gutters: false
}

EmptyListItem.propTypes = {
	customTranslateRef: PropTypes.string,
	feminine: PropTypes.bool,
	gutters: PropTypes.bool,
	objectName: PropTypes.string,
	icon: PropTypes.string
}

export default withStyles(styles)(EmptyListItem)
