import React from 'react'
import { withRouter } from 'react-router-dom'
// import { connect } from 'react-redux'
import { compose } from 'recompose'
import * as routes from '../../../constants/routes'
// import { withStyles, Typography, Button } from '@material-ui/core'
// import { CompanyLogo } from '../../reusable/image'
import withAuthentication from '../../reusable/withAuthentication'
import { Redirect } from 'react-router'
// import { translate } from '../../../utils/lang'
// import styles from './styles'

// const INITIAL_CONFIG = {
// 	logo: {
// 		src: require('../../img/speedometer.svg'),
// 		alt: 'Arquia Dashboards Logo',
// 		title: translate('app/title')
// 	},
// 	callToAction: {
// 		text: translate('label/proceed')
// 	}
// }

/**
 * Declares the system landing page
 */
class LandingPage extends React.Component {
	// state = { ...INITIAL_CONFIG }

	componentDidMount() {
		const { authUser, history } = this.props

		if (!!authUser) {
			history.push(routes.DEFAULT)
		}
	}

	callToAction = (event) => {
		event.preventDefault()

		//const { history } = this.props;
		this.props.history.push(routes.SIGN_IN)
	}

	render() {
		// const { classes } = this.props
		// const { logo, callToAction } = this.state

		return (
			<Redirect to={routes.SIGN_IN} />
			// <div className={classes.root}>
			// 	<CompanyLogo {...logo} />
			// 	<Typography
			// 		variant="h6"
			// 		align="center"
			// 		className={classes.title}
			// 	>
			// 		{logo.title}
			// 	</Typography>
			// 	<div className={classes.callToActionWrapper}>
			// 		<Button
			// 			component="a"
			// 			color="primary"
			// 			className={classes.callToAction}
			// 			onClick={this.callToAction}
			// 		>
			// 			{callToAction.text}
			// 		</Button>
			// 	</div>
			// </div>
		)
	}
}

// const mapStateToProps = (state) => ({
// 	authUser: state.sessionState.authUser
// })

export default compose(
	// connect(mapStateToProps),
	withAuthentication,
	withRouter
	// withStyles(styles)
)(LandingPage)
