export const MANAGE_HISTORY_DAILY_LOAD_WIRE_CHARGE_LOADING_STATE =
		'MANAGE_HISTORY_DAILY_LOAD_WIRE_CHARGE_LOADING_STATE',
	LOAD_HISTORY_DAILY_LOAD_WIRE_CHARGE_DATA =
		'LOAD_HISTORY_DAILY_LOAD_WIRE_CHARGE_DATA',
	SET_HISTORY_DAILY_LOAD_WIRE_CHARGE_PERIOD =
		'SET_HISTORY_DAILY_LOAD_WIRE_CHARGE_PERIOD'

export const setHistoryDailyLoadWireChargePeriod = (
	dateConfig,
	value,
	formType
) => ({
	type: SET_HISTORY_DAILY_LOAD_WIRE_CHARGE_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadHistoryDailyLoadWireChargeData = (
	historyDailyLoadWireCharge
) => ({
	type: LOAD_HISTORY_DAILY_LOAD_WIRE_CHARGE_DATA,
	historyDailyLoadWireCharge
})

export const setLoading = (isLoading) => ({
	type: MANAGE_HISTORY_DAILY_LOAD_WIRE_CHARGE_LOADING_STATE,
	isLoading
})
