const styles = (theme) => ({
	slideContainer: {
		position: 'relative',
		height: '100%',
		// overflow: 'visible !important',
		width: '100%'
	},
	arrowSwiper: {
		color: theme.palette.primary.light,
		position: 'fixed',
		index: '1',
		top: 'calc(50% - (48px/2))'
	},
	arrowRight: {
		right: 0
	},
	slideClassName: {
		height: '100%',
		overflow: 'hidden !important'
	},
	stepper: {
		backgroundColor: 'transparent',
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		padding: '0px'
	}
})

export default styles
