/**
 * Will check and return whether a filter was applied or not.
 * Checks how many object keys containing something inside.
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} keys Key collection
 * @param {Array} values Values collection
 */
const filtersApplied = (values) => {
	return getOnlyKeysWithValue(values).length > 0
}

/**
 * Manages to get only keys, from an object, that contains something inside.
 * Example: The following object.
 *
 * *	{
 * *		key0: [],
 * *		key1: [],
 * *		key2: [ 8, 10 ],
 * *		key3: [ 140 ],
 * * 	}
 *
 * When going thru this method, this will return an string-array with this signature:
 * * [ 'key2', 'key3' ]
 *
 *
 * @description Created the function
 * @author ?
 * @version 1.0
 *
 * @description Moved to this file
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Separate keys for checking in a constant
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @param {Array} list a list to filter
 */
const getOnlyKeysWithValue = (objectWithArrayKeys) => {
	const keys = Object.keys(objectWithArrayKeys),
		resolution =
			keys.length > 0
				? keys.filter(
						(k) =>
							!!objectWithArrayKeys[k] &&
							objectWithArrayKeys[k].length > 0,
				  )
				: []

	return resolution
}

/**
 * FIlters a collection by global filter keys. If no keys are passed on, returns the list itself
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0.0
 *
 * @description Checking keys set prior to filtering
 * @author brunoteixeirasilva
 * @version 1.1.0
 *
 * @param {Object} keys the keys from globalFilter values
 * @param {Object} options the globalFilter object values
 * @param {Array} values array to be filtered
 * @param {Array} acceptProps Acceptable props. String array
 */
let filterByKeys = (keys, options, values, acceptProps = null) => {
	let filtered = [],
		props = acceptProps

	//Checking keys availability
	//Not available? Returns the list itself (not filtered)
	if (undefined === keys || null === keys || keys.length === 0) {
		return values
	}

	//Decides whether to use accept-props over keys
	//But if using acceptProps, will use only those with values
	props = !props ? keys : props.filter((prop) => keys.includes(prop))

	// props.forEach((k) => {
	values.forEach((objectUnit) => {
		//Object has no prop named "k"
		//Item has already filtered
		if (filtered.includes(objectUnit)) {
			return
		}

		//Comparing objects or checking array contains
		if (
			props.every(
				(prop) =>
					!!objectUnit[prop] &&
					(objectUnit[prop] === options[prop] ||
						options[prop].includes(objectUnit[prop])),
			)
		) {
			filtered.push(objectUnit)
		}
	})
	// })

	return filtered
}

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0.0
 *
 * @description Better code organisation
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Array} list List of items which will have filters applied
 * @param {Object} options List of filters to be applied
 * @param {Array} acceptProps Accepted props
 */
function filterByOptions(list, options, acceptProps = null) {
	let keysWithValue = getOnlyKeysWithValue(options)

	// if (!(typeof keysWithValue.includes === 'function')) debugger

	//No filters applied!
	if (keysWithValue.length === 0) {
		return list
	}

	return filterByKeys(keysWithValue, options, list, acceptProps)
}

/**
 *
 * @description necessary to format the filters that should be filterByOptions.
 * @author davispindola
 *
 * @param {*} filterValues from globalState.
 * @param {*} selectiveFilters from globalState - abled filter-boxes per context.
 */
const fixValuesBySelectiveFilters = (filterValues, selectiveFilters) => {
	let filters = {}
	Object.keys(filterValues).forEach((k) => {
		//Current filter-key-item (e.g. clients) contained within selective?
		if (selectiveFilters.includes(k.toLowerCase()))
			filters[k] = [...filterValues[k]]
	})

	return filters
}

export { filterByOptions, filtersApplied, fixValuesBySelectiveFilters }
