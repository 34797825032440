import { CLEAN_ON_COMPANY_CHANGE } from '../../../actions/company'

const INITIAL_STATE = {
	permissions: null
}

/**
 * Will clear the permissions list
 * @author Davi Wegner
 * @param {Object} state The current app state
 * @version 1.0
 */
const clearPermissions = (state) =>
	Object.assign({}, state, { permissions: INITIAL_STATE.permissions })

/**
 * Will add items to the permissions list
 * @author Davi Wegner
 * @param {Object} state The current app state
 * @param {Object} action The data being set at the current state
 * @version 1.0
 */
const permissionsLoaded = (state, action) =>
	Object.assign({}, state, {
		permissions: Object.assign({}, state.permissions, action.permissions)
	})

/**
 * Handles a company permissions list
 * @author Davi Wegner
 * @param {Object} state The current state of the app
 * @param {Object} action The action object
 * @version 1.0
 */
function companyPermissionReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CLEAR_COMPANY_PERMISSIONS': {
			return clearPermissions(state)
		}
		case 'COMPANY_PERMISSIONS_LOADED': {
			return permissionsLoaded(state, action)
		}
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default companyPermissionReducer
