import React from 'react'
import PropTypes from 'prop-types'
import {
	ListItem,
	ListItemIcon,
	Icon,
	ListItemText,
	Tooltip
} from '@material-ui/core'

/**
 * This button can assume one interface between:
 * * ListItem (default);
 * * Component (passed in props) - if is Component, the props are inject in declaration '{...props}';
 *
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0.0
 *
 * @param {Object} props Component properties
 *
 * @description Added a ternary to check icon size to determine which image to render
 * @author lopes
 * @version 1.0.1
 */
const MetamorphicButton = (props) => {
	const { Component, parentFunction, icon, label, tooltipTitle } = props

	return !!Component && Component instanceof React.Component ? (
		<Component {...props} />
	) : (
		<Tooltip title={tooltipTitle}>
			<ListItem button onClick={parentFunction}>
				<ListItemIcon>
					{icon.length > 50 ? (
						<img
							style={{ width: '40px', height: '40px' }}
							src={icon}
							alt={'logo da empresa'}
						/>
					) : (
						<Icon>{icon}</Icon>
					)}
				</ListItemIcon>
				<ListItemText>{label}</ListItemText>
			</ListItem>
		</Tooltip>
	)
}

MetamorphicButton.defaultProps = {
	parentFunction: null,
	icon: '',
	label: ''
}

MetamorphicButton.propTypes = {
	parentFunction: PropTypes.func,
	icon: PropTypes.string,
	label: PropTypes.string
}

export default MetamorphicButton
