import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Grid, Modal, Typography, withStyles, withWidth, Icon, Tooltip } from '@material-ui/core'
import DashItem from '../dashItem'
import Loader from '../loader'
import { deviceIsIos } from '../../../utils/env'
import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'
import _ from 'lodash'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import './carousel.css'
import { onGetLocalFilters, onGetFiltersDashItem } from './controllers/controllers'
import defaultLocalFilterGet from '../../../services/cloud/functions/localFilters'
import { makeGetActivePlugin } from '../../../selectors/plugin'
import { makeGetWidths } from '../../../utils/object/theme/modal'
import { fs } from '../../../firebase/firebase'
import ModalDashSet from './modalDashSet'

const getWidths = makeGetWidths()

/**
 * Will create an instance of each dashSet by their types
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Major refactor on behaviour of this component. Less re-renders
 * @author brunoteixeirasilva
 * @version 2.0
 *
 * @description added cleaning dashSet on component unmount
 * @author Davi Wegner
 * @version 2.1
 *
 * @description Added redirect to plugin selector route, when company-plugin was not found
 * @author brunoteixeirasilva
 * @version 2.2
 *
 * @description Added withPullDownToRefresh to the list
 * @author daviwegner
 * @version 2.3
 *
 * @description Added iOS bottom padding for escaping virtual nav keyboard
 * @author brunoteixeirasilva
 * @version 2.4
 *
 * @description Deleted card holder and implemented dinamic load of components in dashItem
 * @author Wegner
 * @version 2.5
 *
 * @description The load function of this component has been removed,
 *  thus leaving this component only with the rendering function.
 * @author lopes
 * @version 2.6
 *
 * @description Implement function to get local filters
 * @author jeffersonguilhermemachadobarreto
 * @version 2.7
 */
class DashSet extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			currentIndex: 0,
			minValue: 0,
			maxValue: 3,
			arrayData: [],
			group: [],
			slide: 0,
			item: 0,
			extend: null,
			beforeExtend: null
		}
	}

	componentDidMount() {
		const { dashItems, onSetLocalFilters, activeCompanyId, pluginId, onSetCardLocalFilter } = this.props
		
		if (!!dashItems) {
			let group = _.keys(_.groupBy(dashItems, 'dashSet'))
			this.collectDashSet(group, dashItems.map((item) => item.dashSet))
			this.setState({
				arrayData: dashItems
			})
		}

		onGetFiltersDashItem(activeCompanyId, onSetCardLocalFilter)

		onGetLocalFilters(onSetLocalFilters, defaultLocalFilterGet, {
			pluginId: pluginId,
			companyId: activeCompanyId,
			process: process.env.REACT_APP_NODE_ENV.trim(),
		})
	}

	resetState = () => {
		this.setState({
			extend: null,
			beforeExtend: null
		})
	}

	componentDidUpdate() {
		const { dashItems } = this.props
		const { arrayData } = this.state

		if (!!dashItems && dashItems.length !== arrayData.length) {
			let group = _.keys(_.groupBy(dashItems, 'dashSet'))
			this.collectDashSet(group, dashItems.map((item) => item.dashSet))
			this.setState({
				arrayData: dashItems
			})
		}
	}

	collectDashSet = async (group, dashItems) => {

		let dashSets = []

		group.forEach(async (dashSet) => {
			await fs.collection('dashSets').doc(dashSet).get().then((snap) => {
				dashSets.push(Object.assign({}, snap.data(), {
					id: snap.id,
					active: true
				}))
			})
		})

		await Promise.resolve(dashSets).then((result) => {
			this.setState({
				group: result
			})
		}).catch((err) => {
			console.error('err', err)
		})
	}

	onSlideChanged = e => {
		const { arrayData, maxValue, slide, item } = this.state

		if (e.isNextSlideDisabled) {
			this.setState({
				maxValue: arrayData.length,
				currentIndex: e.item,
				slide: e.slide,
				item: e.item,
			})
		} else if (e.item > item) {
			if (e.slide > slide) {
				if (arrayData.length >= e.slide * 3) {
					this.setState({
						maxValue: maxValue + e.slide * 3,
						currentIndex: e.item,
						slide: e.slide,
						item: e.item,
					})
				}
			} else if (arrayData.length >= maxValue + 1) {
				this.setState({
					maxValue: maxValue + 1,
					currentIndex: e.item,
					item: e.item,
					slide: e.slide,
				})
			}
		} else {
			this.setState({
				currentIndex: e.item,
			})
		}
	}

	onCloseModal = () => {
		const { onSetActiveModal } = this.props

		onSetActiveModal(false)
	}

	selectCard = (data, index, infoModal) => {

		const { onSetInfoModal } = this.props
		const { extend } = this.state

		this.setState({
			maxValue: index + 3,
			currentIndex: index,
			slide: (index / 2.9).toFixed() * 1,
			item: index,
			beforeExtend: extend,
			extend: data.dashItemId
		})

		this.onCloseModal()
		if(!!infoModal) onSetInfoModal(true)
	}

	handleFavoriteCard = (params) => {
		const { activeCompanyId, authUser, activePluginId } = this.props
		const { arrayData } = this.state

		const cardRef = fs
			.collection('companies')
			.doc(activeCompanyId)
			.collection('users')
			.doc(authUser.uid)
			.collection('plugins')
			.doc(activePluginId)
			.collection('dashSets')
			.doc(params.dashSet)
			.collection('cards')
			.doc(params.dashItemId)

		cardRef.get().then(card => {
			if (card.exists) {
				if (!!card.data().favorite) {
					cardRef.set(
						{
							favorite: false,
						},
						{ merge: true }
					)
				} else {
					cardRef.set(
						{
							favorite: true,
						},
						{ merge: true }
					)
				}
			}
		})

		let newData = arrayData.map((item) => {
			if(item.dashItemId === params.dashItemId) {
				return Object.assign({}, item, {
					favorite: !!item.favorite ? false : true
				})
			} else {
				return item
			}
		})

		this.setState({
			arrayData: newData
		})
	}

	setGroup = (dashSet) => {
		const { group } = this.state

		let newGrouped = []

		group.forEach((item) => {
			if(item.id === dashSet.id) {
				newGrouped.push(Object.assign({}, item, {
					active: !item.active
				}))
			} else {
				newGrouped.push(item)
			}
		})

		this.setState({
			group: newGrouped
		})
	}

	render() {
		const { classes, dashItems, dashSetsLoading, activePluginId, openModal } = this.props

		const { arrayData, maxValue, currentIndex, group, extend, beforeExtend } = this.state

		const responsive = {
			0: { items: 1 },
			568: { items: 2 },
			700: { items: 3 },
			1980: { items: 4 },
		}

		return (
			<div
				className={classNames(classes.root, {
					[classes.rootIos]: deviceIsIos(),
				})}>
				{!dashItems && !dashSetsLoading && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							textAlign: 'center',
							width: '100%',
						}}>
						<p
							style={{
								fontSize: '25px',
								marginBottom: '20px',
								marginTop: '20px',
							}}>
							Carregando Cards ...
						</p>

						<Loader internal />
					</div>
				)}
				{!dashItems && !!dashSetsLoading && <Loader internal />}
				{
					!!group && !!arrayData && arrayData.length > 0 &&
					<ModalDashSet
						openModal={openModal}
						arrayData={arrayData}
						group={group}
						onCloseModal={this.onCloseModal}
						selectCard={this.selectCard}
						handleFavoriteCard={this.handleFavoriteCard}
						setGroup={this.setGroup}
					/>
				}
				<Grid className={classes.dashItemsContainer} container>
					{!!dashItems && (
						<AliceCarousel
							className="container"
							responsive={responsive}
							onSlideChanged={this.onSlideChanged}
							activeIndex={currentIndex}
							onResizeEvent={true}
							keyboardNavigation
							touchMoveDefaultEvents
							preservePosition
							items={!!arrayData &&
								arrayData.length >= 0 &&
								arrayData.map((dashItem, i) => {
									if (i < maxValue) {
										return (
											<Grid className={classNames(classes.gridItem, 'fade-in-image')} key={i} item>
												<DashItem
													{...(dashItem.version === 3
														? {
																componentPath: 'component/reusable/dashItem/DashItemNew',
														  }
														: {})}
													dashItemId={dashItem.dashItemId}
													dashItem={dashItem}
													dashSet={dashItem.dashSet}
													activePluginId={activePluginId}
													beforeExtend={beforeExtend}
													resetState={this.resetState}
													extend={extend === dashItem.dashItemId ? true : false}
												/>
											</Grid>
										)
									}
								})}
							mouseTracking>
						</AliceCarousel>
					)}
				</Grid>
			</div>
		)
	}
}

export const styles = theme => ({
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	content: {
		width: '90%',
		maxHeight: '80%',
		overflowY: 'scroll',
		'& > #head, h1': {
			padding: '2px',
			backgroundColor: '#5731AC',
			flexDirection: 'row',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& > *': {
				flex: '0 0 auto'
			},
			'& > span': {
				cursor: 'pointer'
			},
			borderRadius: '6px 6px 0px 0px'
		},
		'& > #body, h1': {
			padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
			backgroundColor: '#FFFFFF',
			display: 'flex',
        	flexWrap: 'wrap'
		}
	},
	dashSetTitle: {
		padding: '3px',
		margin: '5px',

	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		fontFamily: 'roboto',
		fontSize: '15px'
	},
	cards: {
		margin: '5px',
		padding: '5px',
		display: 'grid',
		flex: '1 1 200px',
		justifyItems: 'center',
		background: '#5731AC',
		borderRadius: '6px',
		borderTop: '',
		'&:hover': {
			color: theme.palette.primary.light,
			transform: 'scale(1.1)',
			cursor: 'pointer',
		},
	},
	headColor: {
		width: '100%',
		borderRadius: '6px 6px 0px 0px'
	},
	icon: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	tooltipIcon: {
		paddingRight: theme.spacing.unit,
		fontSize: '16px',
		'&:hover': {
			color: theme.palette.primary.light,
			transform: 'scale(1.3)',
			cursor: 'pointer',
		},
	},
	Titledescription: {
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 'large'
	},
	description: {
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	root: {
		flexGrow: 1,
		backgroundColor: 'transparent', // theme.palette.background.paper
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		paddingTop: '64px',
		...getDesktopBreakpoint(theme, {
			alignItems: 'flex-start',
			flexDirection: 'row',
			justifyContent: 'space-between',
		}),
		[theme.breakpoints.up('lg')]: {
			// justifyContent: 'flex-start'
		},
	},
	gridItem: {
		border: '1px solid white',
		boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)',
		borderRadius: '10px',
		height: 'calc(100vh - 146px)'
	},
	rootIos: {
		paddingBottom: 'env(safe-area-inset-bottom)',
	},
	dashItemsContainer: {
		margin: '0px',
		padding: '6px',
	},
	dashSetFullWidth: {
		flex: '0 0 auto',
		width: '100%',
	},
	dashSet: {
		flex: '0 0 auto',
		width: '100%',
		...getDesktopBreakpoint(theme, {
			width: '49.5%',
		}),
		[theme.breakpoints.up('lg')]: {
			width: '49.5%',
			'&:nth-last-child()': {
				marginRight: 0,
			},
		},
	},

	headline: {
		color: 'rgba(74, 36, 135, 0.56)',
	},
	headlineDivider: {
		marginBottom: '.8em',
		marginLeft: `-${theme.spacing.unit * 2}px`,
		marginRight: `-${theme.spacing.unit * 2}px`,
		...getDesktopBreakpoint(theme, {
			marginLeft: 0,
			marginRight: 0,
		}),
	},
	paper: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
	},
	row: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
	bolderText: {
		fontWeight: '500 !important',
	},
	location: {
		textAlign: 'left',
		width: '100%',
		paddingTop: '5px',
	},
	locationText: {
		color: 'rgba(149, 117, 205,0.63)',
		fontWeight: '500 ',
	},
})

DashSet.propTypes = {
	classes: PropTypes.object.isRequired,
}

const makeMapStateToProps = () => {
	const getActivePlugin = makeGetActivePlugin()

	const mapStateToProps = state => ({
		activePlugin: getActivePlugin(state),
		pluginId: Object.keys(state.pluginState.activePlugin)[0],
		openModal: state.dashBoardState.openModal
	})

	return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
	onClearLocalFilters: async () => dispatch({ type: 'CLEAR_FILTER_LOCAL_DATA' }),
	onSetLocalFilters: async (key, filterLocalData) => dispatch({ type: 'SET_FILTER_LOCAL_DATA', key, filterLocalData }),
	onSetCardLocalFilter: async cardFilter => dispatch({ type: 'SET_CARD_FILTER', cardFilter }),
	onSetActiveModal: async openModal => dispatch({ type: 'SET_MODAL_DASH_SET', openModal }),
	onSetInfoModal: async infoModal => dispatch({ type: 'SET_INFO_MODAL', infoModal }),
	onSetActiveDashboard: async activeDashboard => dispatch({ type: 'SET_ACTIVE_DASHBOARD', activeDashboard })
})

export default compose(
	withWidth(),
	withStyles(styles),
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(DashSet)
