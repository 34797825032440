export const MANAGE_RANKING_AUDITS_LOADING_STATE =
	'MANAGE_RANKING_AUDITS_LOADING_STATE',
	LOAD_RANKING_AUDITS_DATA = 'LOAD_RANKING_AUDITS_DATA',
	SET_RANKING_AUDITS_PERIOD = 'SET_RANKING_AUDITS_PERIOD'

export const setRankingAuditsPeriod = (dateConfig, value, formType) => ({
	type: SET_RANKING_AUDITS_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadRankingAuditsData = (rankingAudits) => ({
	type: LOAD_RANKING_AUDITS_DATA,
	rankingAudits
})

export const setLoading = (isLoading) => ({
	type: MANAGE_RANKING_AUDITS_LOADING_STATE,
	isLoading
})
