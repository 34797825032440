/**
 * Provides utility configuration defaults for service connections
 *
 * @description Created the object
 * @author brunoteixeirasilva
 * @version 1.0
 */
export default {
	requestTimeout: 2 * 60 * 1000 //2 minute request timeout (60000s)
}
