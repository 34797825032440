import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles, List } from '@material-ui/core'
import withAuthentication from '../../../../reusable/withAuthentication'

import EmptyListItem from '../../../../reusable/list/empty/item'
import Response from './Response'
import * as Style from './Style'
import Loader from '../../../../reusable/loader'

/**
 * Manages to display an action-response-list
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description Added a key to the list
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description changed map method, now if the actionDone === 0 get first actionDone before it that is not 0
 * passing authorInfo for fixing memory leak
 * @author Davi Wegner
 * @version 1.2
 */
class ResponseList extends React.Component {
	render() {
		const { classes, responses, authUser, authorInfo } = this.props

		return (
			<div className={classes.root}>
				{responses === null && <Loader internal />}
				{responses !== null && (
					<List className={classes.list}>
						{authorInfo &&
							responses.map((response, index) => {
								if (
									response.actionDone === 0 &&
									response.author !== authUser.uid
								) {
									let lastResponse = responses
										.filter((value, i) => {
											return (
												value.actionDone !== 0 &&
												i < index
											)
										})
										.pop()
									return (
										<Response
											key={index}
											authUser={authUser}
											actionObservation={
												response.actionObservation
											}
											authorName={
												authorInfo[response.author].name
											}
											date={response.date}
											author={response.author}
											actionDone={
												!!lastResponse
													? lastResponse.actionDone
													: 0
											}
											actionDoneIn={
												!!lastResponse
													? lastResponse.actionDoneIn
													: 0
											}
										/>
									)
								} else {
									return (
										<Response
											key={index}
											authUser={authUser}
											actionObservation={
												response.actionObservation
											}
											authorName={
												authorInfo[response.author].name
											}
											date={response.date}
											author={response.author}
											actionDone={response.actionDone}
											actionDoneIn={response.actionDoneIn}
										/>
									)
								}
							})}
					</List>
				)}
				{responses !== null &&
					Object.values(responses).length === 0 && (
						<EmptyListItem
							customTranslateRef="label/no-responses-action"
							icon="warning"
						/>
					)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authorInfo: state.actionFilterState.authorInfo,
	responses: !!state.actionsState.responses
		? Object.values(state.actionsState.responses)
		: null
})

export default compose(
	withAuthentication,
	connect(mapStateToProps),
	withStyles(Style.list)
)(ResponseList)
