import React from 'react'
import { connect } from 'react-redux'

import { getActionReasons } from '../../../../../firebase/actions/reasons'
import {
	prepareReasonRequestAction,
	removeReasonRequestAction,
	receiveEvaluationReasonsAction
} from '../../../../../reducers/systemEvaluation/reasons'
import makeGetEvaluationReasons from '../../../../../selectors/action/evaluation/reasons'

/**
 * Responsible for mount a simple select with improvement options
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 */
class ReasonsContainer extends React.Component {
	componentDidMount() {
		const { reasonType, isLoading } = this.props

		if (!isLoading.includes(reasonType)) {
			this.queryData()
		}
	}

	queryData = () => {
		const {
			prepareRequest,
			finalizeRequest,
			reasonType,
			receiveReasons
		} = this.props

		prepareRequest()
			.then(() => getActionReasons(reasonType))
			.then((data) => {
				let payload = { reasons: [] }
				payload['key'] = reasonType

				data.forEach((data) => {
					payload.reasons.push(data.id)
				})

				return Promise.resolve(payload)
			})
			.then((data) => receiveReasons(data))
			.then(() => finalizeRequest())
	}

	render() {
		const { reasonType, render, reasons } = this.props

		return <React.Fragment>{render(reasons, reasonType)}</React.Fragment>
	}
}

const makeMapStateToProps = () => {
	const getReasons = makeGetEvaluationReasons()

	// FIXME: use selector to verify is request is opened
	const mapStateToProps = (state, props) => {
		return {
			isLoading: state.evaluationReasonsState.isLoading,
			reasons: getReasons(state, props)
		}
	}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch, props) => {
	const { reasonType } = props

	return {
		prepareRequest: async () =>
			dispatch(prepareReasonRequestAction(reasonType)),
		finalizeRequest: () => dispatch(removeReasonRequestAction(reasonType)),
		receiveReasons: async ({ key, reasons }) =>
			dispatch(receiveEvaluationReasonsAction(key, reasons))
	}
}

export default connect(
	makeMapStateToProps(),
	mapDispatchToProps
)(ReasonsContainer)
