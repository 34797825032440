import { translate } from '../../utils/lang'

/**
 * in this case is null because is needed to clear the state of this reducer
 */
const INITIAL_STATE = {}

/**
 * Will manage to set an "isLoading" flag => true and set data to undefined
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state of the reducer
 * @param {Object} action The action with "type" and a "card" prop with card-data by ID
 */
const cardInfoLoading = (state, action) => {
	let nextState

	if (undefined === action.cardId) {
		throw new Error(
			translate('validation/property-is-required', {
				propertyName: 'cardId'
			})
		)
	}

	//Will build an empty/loading card object
	nextState = Object.assign({}, state, {
		[action.cardId]: { isLoading: true }
	})

	return nextState
}

/**
 * Will manage to place card-data into its specific state layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state of the reducer
 * @param {Object} action The action with "type" and a "card" prop with card-data by ID
 */
const cardInfoLoaded = (state, action) => {
	let nextState, cardId

	if (undefined === action.card) {
		throw new Error(
			translate('validation/property-is-required', {
				propertyName: 'card'
			})
		)
	}

	//Gets the card-object-ID
	cardId = Object.keys(action.card)[0]

	//Will build a populated card object (flag isLoading => false)
	nextState = Object.assign({}, state, {
		[cardId]: Object.assign({}, state[cardId], action.card[cardId], {
			isLoading: false
		})
	})

	return nextState
}

/**
 * Sets a card loaded info.
 * All card props will be returned within the card Id property
 * at the next state.
 * @author Davi Wegner
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
function cardReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CARD_INFO_LOADING': {
			return cardInfoLoading(state, action)
		}
		case 'CARD_INFO_LOADED': {
			return cardInfoLoaded(state, action)
		}
		default:
			return state
	}
}

export default cardReducer
