import { translate } from '../../utils/lang'
import { SET_USER_COMPANIES } from '../../actions/user/company'

const INITIAL_STATE = {
	companies: [],
	hasSuperUserAccess: false,
	lastAddedUser: null,
	superUserResolving: false,
	user: null,
	userGroupIds: null
}

/**
 * Sets/unsets a super-user access for the next state of the app,
 * also, resets "superUserResolving" flag to "false"
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "hasSuperUserAccess" property with the next active company
 */
const setSuperUserAccess = (state, action) => {
	if (undefined === action.hasSuperUserAccess) {
		throw new Error(
			translate('validation/property-is-required', {
				propertyName: 'hasSuperUserAccess'
			})
		)
	}

	return Object.assign({}, state, {
		hasSuperUserAccess: action.hasSuperUserAccess,
		superUserResolving: INITIAL_STATE.superUserResolving
	})
}

/**
 * Keeps an added user for the next state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "lastAddedUser" property with user list
 */
const keepLastAddedUser = (state, action) => {
	if (undefined === action.lastAddedUser || !action.lastAddedUser) {
		throw new Error(
			translate('validation/property-is-required', {
				propertyName: 'lastAddedUser'
			})
		)
	}

	return Object.assign({}, state, { lastAddedUser: action.lastAddedUser })
}

/**
 * Clears an added user for the next state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 */
const clearLastAddedUser = (state) =>
	Object.assign({}, state, { lastAddedUser: INITIAL_STATE.lastAddedUser })

/**
 * Apply user info to the current state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "user" property with user data
 */
const applySetUser = (state, action) =>
	Object.assign({}, state, { user: action.user })

/**
 * Apply user info to the current state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "user" property with user data
 */
const applySetUserCompanies = (state, action) =>
	Object.assign({}, state, { companies: action.companies })

/**
 * Returns the app initial state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {INITIAL_STATE} state object with master state
 */
const applyUserLogOut = (state) =>
	Object.assign({}, state, { ...INITIAL_STATE })

/**
 * Sets a super-user resolving flag to "true" (avoid many Firestore accesses at once)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 */
const setSuperUserResolving = (state) =>
	Object.assign({}, state, { superUserResolving: true })

//  /**
//  * Resets a super-user resolving flag to "false" (avoid many Firestore accesses at once)
// ? In this function, we reset the state of a flag which is reset by another method
//  *
//  * @description Created the function
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {Object} state The current app state
//  */
// const clearSuperUserResolving = (state) =>
// 	Object.assign({}, state, {
// 		superUserResolving: INITIAL_STATE.superUserResolving
// 	})

/**
 * Manages the user-account state layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added loading support
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Removed user-list management from here
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" prop and desired ones
 */
function userReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_SUPER_USER_RESOLVING': {
			return setSuperUserResolving(state)
		}
		case 'SET_SUPER_USER_ACCESS': {
			return setSuperUserAccess(state, action)
		}
		case 'SET_LAST_ADDED_USER': {
			return keepLastAddedUser(state, action)
		}
		case 'CLEAR_LAST_ADDED_USER': {
			return clearLastAddedUser(state)
		}
		case 'USER_LOADED': {
			return applySetUser(state, action)
		}
		case 'USER_EDITED': {
			//"action" has to contain: uid, user info
			//if (!action.user) return state;
			return applySetUser(state, action)
		}
		case SET_USER_COMPANIES: {
			//debugger;
			return applySetUserCompanies(state, action)
		}
		case 'USER_LOG_OUT': {
			return applyUserLogOut(state)
		}
		default:
			return state
	}
}

export default userReducer
