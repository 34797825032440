const countTotal = (data, filtered, prefix, sufix) => {
	const values = data
	let total = 0
	filtered.forEach((value, index) => {
		if (!value.hidden) {
			total += values[index]
		}
	})
	return `${prefix || ''}` + total.toLocaleString('pt-Br') + `${sufix || ''}`
}
const beforeDraw = (chart, data, prefix, sufix) => {
	var ctx = chart.chart.ctx
	chart.config.options.elements.center.text = countTotal(
		data,
		chart.config.options.legend.labels.generateLabels(chart),
		prefix,
		sufix
	)

	//Get options from the center object in options
	var centerConfig = chart.config.options.elements.center
	var fontStyle = centerConfig.fontStyle || 'Arial'
	var txt = centerConfig.text
	var color = centerConfig.color || '#000'
	var sidePadding = centerConfig.sidePadding || 20
	var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

	//Start with a base font of 30px
	ctx.font = '35px ' + fontStyle

	//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
	var stringWidth = ctx.measureText(txt).width
	var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

	// Find out how much the font can grow in width.
	var widthRatio = elementWidth / stringWidth
	var newFontSize = Math.floor(30 * widthRatio)
	var elementHeight = chart.innerRadius * 2

	// Pick a new font size so it will not be larger than the height of label.
	var fontSizeToUse = Math.min(newFontSize, elementHeight)

	//Set font settings to draw it correctly.
	ctx.textAlign = 'center'
	ctx.textBaseline = 'middle'
	var centerX = (chart.chartArea.left + chart.chartArea.right) / 2
	var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
	ctx.font = fontSizeToUse + 'px ' + fontStyle
	ctx.fillStyle = color

	//Draw text in center
	ctx.fillText(txt, centerX, centerY)
}

export { beforeDraw }
