import { getDesktopBreakpoint } from '../../../../utils/object/theme/mobile'

const styles = (theme) => ({
	button: {
		position: 'fixed',
		right: 16,
		bottom: theme.spacing.unit * 7 + 16,
		backgroundColor: theme.palette.secondary.main,
		color: '#fff',
		'&.ios': {
			bottom: theme.spacing.unit * 7 + 32
		},
		...getDesktopBreakpoint(theme, {
			bottom: 16
		})
	}
})

export default styles
