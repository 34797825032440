import {
	SET_GRAPH_LEGEND_DISPLAY_OPTION,
	SET_GRAPH_LEGEND_DISPLAY_TYPE
} from '../../actions/graphContainer'

const INITIAL_STATE = {
	isHidden: {},
	mobile: false
}

const graphContainerReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_GRAPH_LEGEND_DISPLAY_OPTION:
			return Object.assign({}, state, {
				isHidden: Object.assign({}, state.isHidden, {
					[action.dashItemId]: {
						[action.stepId]: action.isHidden
					}
				})
			})
		case SET_GRAPH_LEGEND_DISPLAY_TYPE:
			return Object.assign({}, state, { mobile: action.mobile })
		default:
			return state
	}
}

export default graphContainerReducer
