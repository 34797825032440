import { Base64 } from 'js-base64'
import * as Service from '../defaults'

export default (...args) => {
	const [axios] = args

	/**
	 * Configures axios default timeout to 120s (120000ms or 2min)
	 * @author brunoteixeirasilva
	 * @version 1.0
	 */
	axios.defaults.timeout = Service.default.requestTimeout
	let currentUrlEncoded = '',
		slowNetworkTimeout = {}

	/**
	 * Creates a base64 version of an url and strips it to the last 10 chars
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 *
	 * @param {string} url The url to be base-64 encoded
	 */
	const prepareUrlEncoded = (url) => {
		const encoded = Base64.encode(url)

		return encoded.substring(encoded.length - 11)
	}

	/**
	 * Removes a setTimeout from the dictionary of these ones
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 *
	 * @param {string} url The url to be base-64 encoded
	 */
	const removeFromTimeouts = async (url) => {
		//Error request object is present
		if (!!url) {
			//Removes it from the slow network timeouts
			currentUrlEncoded = await prepareUrlEncoded(url)
			//Clears the timeout object
			clearTimeout(slowNetworkTimeout[currentUrlEncoded])
			//Removes the prop from the object
			delete slowNetworkTimeout[currentUrlEncoded]
		}
	}

	/**
	 * Configures request management
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 */
	//TODO: apply correction, reduce frequency
	// axios.interceptors.request
	// 	.use
	// (config) => {
	// 	//Prepares encoded request url for setTimeout separation
	// 	currentUrlEncoded = prepareUrlEncoded(config.url)

	// 	//Sets the timeout at the specific URL name
	// 	slowNetworkTimeout[currentUrlEncoded] = setTimeout(
	// 		async function() {
	// 			store.dispatch(
	// 				showSnackBar(
	// 					translate('notification/slow-network-connection')
	// 				)
	// 			)
	// 		},
	// 		slowNetworkTime
	// 	)

	// 	return config
	// },
	// (error) => {
	// 	//Error request object is present
	// 	if (!!error.request) {
	// 		//Removes it from the slow network timeouts
	// 		removeFromTimeouts(error.config.url)
	// 	}

	// 	return Promise.reject(error)
	// }
	// ()

	/**
	 * Configures response management
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 */
	axios.interceptors.response.use(
		(response) => {
			//Response config object is defined
			if (!!response.config) {
				//Removes the timeout at the specific URL name
				removeFromTimeouts(response.config.url)
			}

			return response
		},
		(error) => {
			//Error request object is present
			if (!!error.request) {
				//Removes it from the slow network timeouts
				removeFromTimeouts(error.config.url)
			}

			return Promise.reject(error)
		}
	)
	return axios
}
