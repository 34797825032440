import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Spinner from 'react-spinkit'

/**
 * Manages to display a box over a component as a faker/loading spinner
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added prop-types. Changed styles to a better organisation, separate styles file
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} param0 Containing classes to stylise the object
 */
const LoadingFilter = ({ classes }) => (
	<div className={classes.loaderBox}>
		<Spinner name="ball-beat" />
	</div>
)

const styles = (theme) => ({
	loaderBox: {
		margin: `${theme.spacing.unit * 2}px 0 0 0`,
		padding: `0 0 0 0`,
		height: 32,
		width: '100%',
		display: 'flex',
		zIndex: 100,
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FFF',
		borderBottom: '1px solid #a2a2a2'
	},
	select: { whiteSpace: 'unset' },
	searchBox: {
		height: 'auto',
		paddingTop: 5,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	searchBoxInput: {
		'& > *': {
			borderColor: theme.palette.secondary.main
		},
		'&  label': {
			transform: 'translate(14px, 16px) scale(1)'
		},
		'& input': {
			padding: '12.5px 14px'
		}
	}
})

LoadingFilter.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LoadingFilter)
