import React from 'react'
import { deviceIsIos } from '../../../utils/env'
import PlayStore from '../../../utils/assets/google-play-badge.png'
import Instagram from '../../../utils/assets/instagram.png'
import Linkedin from '../../../utils/assets/linkedin.png'
import Facebook from '../../../utils/assets/facebook.png'
import AppStore from '../../../utils/assets/appStore.png'
import { translate } from '../../../utils/lang'
import { withStyles, Icon, Card, Typography } from '@material-ui/core'
import Json from '../../../../package.json'
import classNames from 'classnames'
import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'
// import PropTypes from 'prop-types'

class AboutArquia extends React.Component {
	render() {
		const { classes, ticket } = this.props

		return (
			<div
				className={classNames(classes.container, {
					[classes.containerIos]: deviceIsIos(),
				})}>
				<Card className={classes.cardContainer}>
					<div className={classes.cardContent}>
						<h3
							className={classNames(
								classes.title,
								classes.titleContact,
							)}>
							<Typography variant="h5">
								{translate('label/contact-us')}
							</Typography>
						</h3>
						<div className={classes.data}>
							<div
								className={classNames(
									classes.optionsInfo,
									classes.options,
								)}>
								<Icon className={classes.iconInfo}>call</Icon>
								<p>+55 51 3591-4815</p>
							</div>
							<div
								className={classNames(
									classes.optionsInfo,
									classes.options,
								)}>
								<Icon className={classes.iconInfo}>mail</Icon>
								<a
									className={classes.titleLink}
									href="mailto:contato@arquia.com.br">
									contato@arquia.com.br
								</a>
							</div>
							<div
								className={classNames(
									classes.optionsInfo,
									classes.options,
								)}>
								<Icon className={classes.iconInfo}>work</Icon>
								<a
									className={classes.titleLink}
									href="https://arquia.com.br/"
									target="_blank"
									rel="noopener noreferrer">
									https://arquia.com.br/
								</a>
							</div>
							<div
								className={classNames(
									classes.optionsInfo,
									classes.options,
								)}>
								<Icon className={classes.iconInfo}>work</Icon>
								<a
									className={classes.titleLink}
									href="https://agildash.com.br/"
									target="_blank"
									rel="noopener noreferrer">
									https://agildash.com.br/
								</a>
							</div>
						</div>
					</div>
				</Card>

				<Card className={classes.cardContainer}>
					<div className={classes.cardContent}>
						<h3 className={classes.title}>
							<Typography variant="h5">
								{translate('Agildash-Overview')}
							</Typography>
						</h3>
						<div className={classes.data}>
							<div className={classes.optionsVideo}>
								<Icon>ondemand_video</Icon>

								<a
									target="_blank"
									href="https://www.youtube.com/watch?v=5oI12SBspxc"
									rel="noopener noreferrer">
									<span
										className={classNames(
											classes.titleLink,
										)}>
										{translate(
											'label/watch-a-product-demo',
										)}
									</span>
								</a>
							</div>
						</div>
					</div>
				</Card>

				<Card className={classes.cardContainer}>
					<div className={classes.cardContent}>
						<h3 className={classes.title}>
							<Typography variant="h5">
								{translate('label/social-networks')}
							</Typography>
						</h3>
						<div className={classes.data}>
							<div className={classes.options}>
								<div className={classes.options}>
									<a
										className={classes.ancor}
										href="https://www.facebook.com/arquiasolucoesempresariais/"
										target="_blank"
										rel="noopener noreferrer">
										<img
											className={classes.imgFix}
											src={Facebook}
											alt="Imagem do facebook"
										/>
									</a>
								</div>
								<div
									className={classNames(
										classes.optionsSocialMedia,
										classes.options,
									)}>
									<a
										className={classes.ancor}
										href="https://www.linkedin.com/company/arquia-solu%C3%A7%C3%B5es-empresariais/"
										target="_blank"
										rel="noopener noreferrer">
										<img
											className={classes.imgFix}
											src={Linkedin}
											alt="Imagem do linkedin"
										/>
									</a>
								</div>
								<div className={classes.options}>
									<a
										className={classes.ancor}
										href="https://www.instagram.com/arquia.tecnologia/"
										target="_blank"
										rel="noopener noreferrer">
										<img
											className={classes.imgFix}
											src={Instagram}
											alt="Imagem do instagram"
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</Card>
				<Card className={classes.cardContainer}>
					<div className={classes.cardContentApp}>
						<h3 className={classes.title}>
							<Typography variant="h5">
								{translate('label/download-the-app')}
							</Typography>
						</h3>

						<div className={classes.apps}>
							<a
								className={classes.linkStore}
								target="_blank"
								rel="noopener noreferrer"
								href="https://play.google.com/store/apps/details?id=com.arquia.agildash.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
								<img
									className={classes.storeImgAndroid}
									alt="Disponível no Google Play"
									src={PlayStore}
								/>
							</a>
							<a
								className={classes.linkStore}
								target="_blank"
								rel="noopener noreferrer"
								href="https://itunes.apple.com/br/app/agildash/id1455489686?mt=8">
								<img
									className={classes.storeImgIos}
									src={AppStore}
									alt="Disponível na App Store"
								/>
							</a>
						</div>
					</div>
				</Card>
				{!!ticket && (
					<Card className={classes.cardContainer}>
						<div className={classes.cardContent}>
							<h3 className={classes.title}>
								<Typography variant="h5">
									{'Equipe de Suporte'}
								</Typography>
							</h3>
							<div className={classes.data}>
								<div
									className={classNames(
										classes.optionsVersion,
										classes.options,
									)}>
									<Icon>add</Icon>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={ticket}>
										<span
											className={classNames(
												classes.titleLink,
											)}>
											{'Abrir Ticket'}
										</span>
									</a>
								</div>
							</div>
						</div>
					</Card>
				)}
				<Card className={classes.cardContainer}>
					<div className={classes.cardContent}>
						<h3 className={classes.title}>
							<Typography variant="h5">
								{translate('label/version')}
							</Typography>
						</h3>
						<div className={classes.data}>
							<div
								className={classNames(
									classes.optionsVersion,
									classes.options,
								)}>
								<p>{Json.version}</p>
							</div>
						</div>
					</div>
				</Card>
			</div>
		)
	}
}

export const styles = theme => ({
	cardContainer: {
		padding: theme.spacing.unit,
		width: '100%',
		position: 'relative',
		minHeight: '100px',
		margin: '0 5px',
	},
	...getDesktopBreakpoint(theme, {
		cardContainer: {
			paddingTop: theme.spacing.unit * 2,
			maxWidth: '400px',
			height: '200px',
			margin: theme.spacing.unit,
		},
	}),
	container: {
		display: 'flex',
		gap: '10px',
		justifyContent: 'center',
		flexWrap: 'wrap',
		alignItems: 'center',
		paddingTop: theme.spacing.unit * 8,
		paddingBottom: theme.spacing.unit * 6,
		backgroundColor: 'rgb(220 222 232)',
	},
	containerIos: {
		paddingBottom: theme.spacing.unit * 8,
	},
	cardContent: {
		width: '100%',
		height: '100%',
	},

	title: {
		display: 'flex',
		justifyContent: 'center',
		height: '20%',
		marginBottom: theme.spacing.unit,
	},
	titleLink: {
		textDecoration: 'underline',
		color: '#4c78e0',
	},
	titleContact: {
		marginBottom: theme.spacing.unit * 3,
	},
	data: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		height: '70%',
	},
	dataApp: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '70%',
	},
	options: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
	},
	optionsInfo: {
		alignItems: 'center',
		marginBottom: theme.spacing.unit,
	},
	optionsVideo: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		flexDirection: 'column',
	},
	optionsVersion: {
		alignItems: 'center',
	},
	optionsSocialMedia: {
		alignItems: 'center',
	},
	iconInfo: {
		marginRight: theme.spacing.unit,
	},
	ancor: {
		textDecoration: 'none',
	},

	storeImgAndroid: {
		width: '100%',
	},
	storeImgIos: {
		width: '90%',
	},
	cardContentApp: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	linkStore: {
		width: '50%',
	},
	apps: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	},
	imgFix: {
		maxWidth: '48px',
	},
})

AboutArquia.propTypes = {}

export default withStyles(styles)(AboutArquia)
