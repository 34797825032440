const INITIAL_STATE = {
	companies: null,
	companyId: null,
	isLoading: true,
	dataSourceTypes: null,
	dataSourceType: null,
	dataSources: null,
	dataSource: null,
	dashboards: null,
	dashboard: null
}

const onboardingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_ONBOARDING_COMPANIES_LOADED':
			return Object.assign({}, state, {
				companies: Object.assign({}, state.companies, action.company)
			})
		case 'SET_ONBOARDING_LOADING_INFO':
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		case 'SET_ONBOARDING_SELECTED_COMPANY':
			return Object.assign({}, state, { companyId: action.companyId })
		case 'SET_ONBOARDING_DASHBOARD_LOADED':
			return Object.assign({}, state, {
				dashboards: Object.assign(
					{},
					state.dashboards,
					action.dashboard
				)
			})
		case 'SET_ONBOARDING_DATA_SOURCE_TYPES_LOADED':
			return Object.assign({}, state, {
				dataSourceTypes: Object.assign(
					{},
					state.dataSourceTypes,
					action.dataSourceType
				)
			})
		case 'SET_ONBOARDING_COLLECTION_LOADED':
			return Object.assign({}, state, {
				[action.collectionKey]: Object.assign(
					{},
					state[action.collectionKey],
					action.value
				)
			})
		case 'SET_ONBOARDING_SELECTED_VALUE':
			return Object.assign({}, state, { [action.key]: action.value })
		default:
			return state
	}
}

export default onboardingReducer
