import { createSelector } from 'reselect'
import { getAuthUserID } from '../../session'

const makeActionDetailFormater = () =>
	createSelector(
		[getActiveAction, getDisableAnswer, getAuthor, getCard, getAuthUserID],
		(activeAction, disabledAnswer, author, card, authUser) => {
			return {
				activeAction,
				actionActive: !!activeAction ? activeAction.active : null,
				isAuthor: !!activeAction && activeAction.author === authUser,
				disabledAnswer,
				author,
				card
			}
		}
	)

export const getActiveAction = (state, ownProps) => {
	let actionList = getActionList(state),
		actionId = ownProps.actionId
	return !!actionList && actionList[actionId] ? actionList[actionId] : null
}

export const getActionList = (state, ownProps) =>
	!!state.actionsState.actionList ? state.actionsState.actionList : null

export const getDetailReaderType = (state, ownProps) => {
	const authUser = getAuthUserID(state),
		activeAction = getActiveAction(state, ownProps)
	if (
		!!activeAction &&
		activeAction.ccReceivers &&
		activeAction.ccReceivers.includes(authUser)
	)
		return 'ccReceiver'
	return !!activeAction && activeAction.author === authUser
		? 'authorOpen'
		: 'receiverOpen'
}
const getDisableAnswer = (state, ownProps) => {
	const activeAction = getActiveAction(state, ownProps),
		actionActive = activeAction
			? getActiveAction(state, ownProps).active
			: false,
		authUser = ownProps.authUser.uid

	return (
		(activeAction &&
			activeAction.ccReceivers &&
			activeAction.ccReceivers.includes(authUser) &&
			activeAction.receiver.key !== authUser) ||
		!actionActive
	)
}

const getAuthor = (state, ownProps) => {
	const activeAction = getActiveAction(state, ownProps)
	return !!state.actionFilterState.authorInfo && activeAction
		? state.actionFilterState.authorInfo[activeAction.author]
		: null
}
const getCard = (state, ownProps) => {
	const activeAction = getActiveAction(state, ownProps)
	return !!state.actionsState.cardInfo && activeAction
		? state.actionsState.cardInfo[activeAction.cardId]
		: null
}
export default makeActionDetailFormater
