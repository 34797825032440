import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import withMappedDom from '../../withMappedDom/index'
import MobileIcon from './MobileIcon'
import { openActionModal } from '../../../../reducers/action/navigation'
import DialogModal from '../../../app/userActions/components/DialogModal'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core'

/**
 * Manages to display an IconButton for only mobile (down from "sm")
 *
 * @description Created the component
 * @author lopes
 * @version 1.0
 *
 *
 */
class DashBoardIconBotton extends React.Component {
	processAction() {
		const { data, getJSON, getDomSnapshot, handleActionModal } = this.props
		const self = this //maintaining the right reference
		//handle functions for generating actions
		getJSON(data)
		getDomSnapshot(ReactDOM.findDOMNode(self))
		handleActionModal()
	}

	generateMobileButton = () => {
		const self = this //maintaining the right reference

		return <MobileIcon onClick={async () => self.processAction()} />
	}

	render() {
		const { width, cardId, classes, children } = this.props

		if (isWidthUp('sm', width)) {
			return null
		}

		return (
			<React.Fragment>
				<div className={classes.contentContainer}>{children}</div>
				<DialogModal cardId={cardId} />
				{!isWidthUp('sm', width) && this.generateMobileButton()}
			</React.Fragment>
		)
	}
}

const styles = (theme) => ({
	root: {
		paddingRight: '5px',
		fontSize: '24px',
		color: 'rgba(120,120,120,0.8)',
		'&:hover': {
			color: 'rgba(0,0,0,1)',
			transform: 'scale(1.3)'
		}
	},
	contentContainer: {
		margin: theme.spacing.unit
	}
})

const makeMapStateToProps = () => {
	const mapStateToProps = (state) => ({
		actionModalState: state.actionsState.showActionModal,
		filterModalState: state.filterModalState.open
	})

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	handleFilterModal: () => dispatch({ type: 'TOGGLE_FILTER_MODAL' }),
	handleActionModal: () => dispatch(openActionModal())
})

export default compose(
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	),
	withMappedDom,
	withWidth(),
	withStyles(styles)
)(DashBoardIconBotton)
