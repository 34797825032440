import { fetchDataFrom } from '../../component/reusable/filter/options/functions/options'
import { store } from '../../store'
import { getGlobalPluginFilters } from '../../selectors/filter'
import { onSagaError } from '..'

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
export function* fetchFreshData(action) {
	try {
		//TODO: Get active plugin, dataSet, and all necessary data to query new items
		//TODO: Get the data

		const state = store.getState()
		const pluginFilters = getGlobalPluginFilters(state)
		const dependantsKeys = !!pluginFilters
			? Object.keys(pluginFilters).filter(
					(pluginFilterKey) =>
						!!pluginFilters[pluginFilterKey].dependsOn &&
						pluginFilters[pluginFilterKey].dependsOn.includes(
							action.filterKey
						)
			  )
			: []

		//Separating dataset for usage
		let dependantDataSet = null

		//Iterates through each dependant for updating their data
		dependantsKeys.forEach((dependantKey) => {
			dependantDataSet =
				!!pluginFilters[dependantKey] &&
				!!pluginFilters[dependantKey].dataSet
					? pluginFilters[dependantKey].dataSet
					: null

			fetchDataFrom(dependantKey, dependantDataSet).then(
				function(createdEffect) {
					store.dispatch(createdEffect)
				}
			)
		})
		
	} catch (ex) {
		yield onSagaError(ex)
	}
}
