import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import MetamorphicButton from './MetamorphicButton'
import * as User from '../../../firebase/user'
import withAuthentication from '../withAuthentication'
import withActiveCompany from '../withActiveCompany'
import * as Company from '../../../firebase/company'
import { translate } from '../../../utils/lang'
import CompanyHint from '../hint/company'
import {
	setUserCompanies,
	clearUserActiveCompany
} from '../../../actions/user/company'

/**
 * Container component for switching companies. The user can change the activeCompanyId in navigation bar.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added "menuMode" at hint instance, removed unused "cleanup" prop
 * @author brunoteixeirasilva
 * @version 1.1
 */
class SwitchCompany extends React.PureComponent {
	componentDidMount() {
		const { authUser, onSetCompanies } = this.props

		if (!!authUser && authUser.uid !== undefined)
			Company.getUserAllowed(authUser.uid).then((snapshot) => {
				const companies = []
				snapshot.forEach((e) => companies.push(this.mergeId(e)))
				return onSetCompanies(companies)
			})
	}

	mergeId = (object) =>
		Object.assign({}, object.data(), {
			id: object.id,
			...object.data()
		})

	// Set the user active company in firestore and in userState
	handleClick = () => {
		const { resetInitialState } = this.props

		this.handleActiveCompany()
		resetInitialState()
	}

	// Set the user activeCompanyId to null
	handleActiveCompany = () => {
		const { authUser, onClearActiveCompany } = this.props

		//Will manage to clear the active company and dispatch it to redux-store
		return onClearActiveCompany().then(() =>
			User.doSetActiveCompany(authUser.uid, null)
		)
	}

	getActiveCompanyLabel = () => {
		const { activeCompanyId, companies } = this.props

		let companie = companies.find((item) => item.id === activeCompanyId)

		return companie ? companie.abbrev : ''
	}

	getActiveCompanyLogo = () => {
		const { activeCompanyId, companies } = this.props

		let companieLogo = companies.find((item) => item.id === activeCompanyId)

		return companieLogo && companieLogo.logo
			? companieLogo.logo.length > 0
				? companieLogo.logo
				: 'import_export'
			: 'import_export'
	}

	render() {
		const { isRendered, companies } = this.props

		return (
			<React.Fragment>
				{!!isRendered && companies.length === 1 && (
					<CompanyHint
						icon={this.getActiveCompanyLogo()}
						label={this.getActiveCompanyLabel()}
						menuMode
					/>
				)}
				{!!isRendered && companies.length > 1 && (
					<MetamorphicButton
						parentFunction={this.handleClick}
						tooltipTitle={translate('label/switch-company')}
						icon={this.getActiveCompanyLogo()}
						label={this.getActiveCompanyLabel()}
					/>
				)}
			</React.Fragment>
		)
	}
}

SwitchCompany.defaultProps = {
	isRendered: true
}

SwitchCompany.propTypes = {
	isRendered: PropTypes.bool
}

const mapStateToProps = (state) => {
	return {
		companies: state.userCompanyState.companies
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onClearActiveCompany: async () => dispatch(clearUserActiveCompany()),
		onSetCompanies: async (companies) =>
			dispatch(setUserCompanies(companies)),
		resetInitialState: () => dispatch({ type: 'RESET_INITIAL_STATE' })
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withActiveCompany,
	withAuthentication
)(SwitchCompany)
