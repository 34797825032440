import { createSelector } from 'reselect'

const getActionState = (state, { actionId }) =>
	state.actionsState.actionList[actionId]

const makeGetEvaluationButtonState = () =>
	createSelector(
		[getActionState],
		(action) => {
			return action && action.evaluate
		}
	)

export default makeGetEvaluationButtonState
