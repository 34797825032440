import React from 'react'
import PropTypes from 'prop-types'
import {
	withStyles,
	Dialog,
	DialogTitle,
	List,
	ListItem,
	ListItemIcon,
	Icon,
	ListItemText
} from '@material-ui/core'

/**
 * Displays a simple-dialog based on this Material UI component:
 * https://material-ui.com/demos/dialogs/#simple-dialogs
 */
class DialogTypeList extends React.Component {
	/**
	 * Invokes the parent function, passed by prop
	 */
	handleClose = () => {
		this.props.onClose(this.props.selectedValue)
	}

	/**
	 * Each option has its own action. It will be different by the param value
	 *
	 * @param {Object} value the item value, which will differentiate the action taken
	 */
	handleListItemClick = (value) => {
		this.props.onClose(value)
	}

	render() {
		const {
			title,
			onClose,
			selectedValue,
			list,
			keyProp,
			valueProp,
			...other
		} = this.props

		return (
			<Dialog
				onClose={this.handleClose}
				aria-labelledby="dialog-type-list-title"
				{...other}
			>
				<DialogTitle id="dialog-type-list-title">{title}</DialogTitle>
				<div>
					<List>
						{list.map((item) => (
							<ListItem
								button
								onClick={
									!!item.onClick
										? item.onClick
										: () => this.handleListItemClick(item)
								}
								key={item[keyProp]}
							>
								<ListItemIcon>
									<Icon>{item.icon}</Icon>
								</ListItemIcon>
								{/* <ListItemAvatar>
									<Avatar className={classes.avatar}>
										<PersonIcon />
									</Avatar>
								</ListItemAvatar> */}
								<ListItemText primary={item[valueProp]} />
							</ListItem>
						))}
					</List>
				</div>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	// avatar: {
	//   backgroundColor: theme.palette.primary.main,
	//   color: theme.palette.primary.dark,
	// },
})

DialogTypeList.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	selectedValue: PropTypes.string
}

export default withStyles(styles)(DialogTypeList)
