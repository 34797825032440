import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
	TextField,
	Typography,
	Divider,
	withStyles,
	Button
} from '@material-ui/core'

import {
	setActionEvaluationComment,
	setActionEvaluationAddedValue,
	setActionEvaluationRating,
	setActionEvaluationCompliments,
	setActionEvaluationImprovements,
	clearData
} from '../actions'

import styles from './styles'
import Rating from '../../components/rating'
import OptionList from '../../../list/optionList'
import {
	setNotificationContent,
	setDialogNotificationOpen
} from '../../../dialog/type/notification/actions'
import { translate } from '../../../../../utils/lang'
import ReasonsContainer from '../../components/reasons'
import { Checkbox } from '../../../form/control/checkbox'
import withActiveCompany from '../../../withActiveCompany'
import withAuthentication from '../../../withAuthentication'
import { Question, QuestionBody } from '../../components/question'
import getStateColour from '../../../../../utils/colour/getStateColour'
import { setActionEvaluate } from '../../../../../firebase/actions/evaluate'
import makeGetAddedValue from '../../../../../selectors/action/evaluation/addedValue'
import makeGetEvaluationRating from '../../../../../selectors/action/evaluation/rating'
import makeGetActionCompliments from '../../../../../selectors/action/evaluation/reason'
import makeGetEvaluationComment from '../../../../../selectors/action/evaluation/comment'
import makeGetActionWasEvaluated from '../../../../../selectors/action/evaluation/action'
import { setEvaluationDialogClose } from '../../../../../reducers/systemEvaluation/dialog'
import makeGetActionImprovements from '../../../../../selectors/action/evaluation/improvement'
import { showSnackBar } from '../../../../../actions/snackbar'

import badSong from '../actions/song/badSong.ogg'
import congratulationSong from '../actions/song/congratulationSong.ogg'

/**
 * Manages the action evaluation system
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 */
class ActionEvaluateContainer extends React.Component {
	handleSelectedValue = (value) => {
		const { setRating } = this.props
		setRating(value)
	}

	handleCheckboxValue = ({ target }) => {
		const { setAddedValue } = this.props
		setAddedValue(parseInt(target.value))
	}

	handleCompliments = (value) => {
		const { setCompliments } = this.props
		setCompliments(value)
	}

	handleImprovements = (value) => {
		const { setImprovements } = this.props
		setImprovements(value)
	}

	handleComment = ({ target }) => {
		const { setComment } = this.props
		setComment(target.value)
	}

	playBadSong = () => {
		var audio1 = new Audio()
		audio1.src = badSong
		audio1.play()
	}

	playCongratulationSong = () => {
		var audio2 = new Audio()
		audio2.src = congratulationSong
		audio2.play()
	}

	handleEvaluate = () => {
		const {
			activeCompanyId,
			actionId,
			evaluate,
			authUser,
			handleNotification,
			handleOpen,
			handleCloseDialog
		} = this.props

		if ((!!authUser && authUser.uid !== undefined) || authUser.uid !== null)
			this.isValid()
				.then(() =>
					setActionEvaluate(
						activeCompanyId,
						actionId,
						authUser.uid,
						evaluate
					)
				)
				.then(() =>
					handleNotification(
						'notification/thanks-for-your-avaliation'
					)
				)
				.then(() => handleOpen())
				.then(() => handleCloseDialog())
				.catch((error) => {
					if (error && error.fun) error.fun()
				})
	}

	isValid = async () => {
		const { addedValue, rating, handleInvalidInput } = this.props

		if (addedValue !== null && rating !== null) return Promise.resolve(true)

		if (addedValue === null) {
			return Promise.reject({
				fun: () =>
					handleInvalidInput(
						translate('notification/required-field', {
							field: translate('label/action-taken-add-value')
						})
					)
			})
		}

		if (rating === null) {
			return Promise.reject({
				fun: () =>
					handleInvalidInput(
						translate('notification/required-field', {
							field: translate(
								'label/would-you-rate-action-taken'
							)
						})
					)
			})
		}

		return Promise.reject(true)
	}

	componentWillUnmount() {
		const { clearData } = this.props

		clearData()
	}

	render() {
		const {
			listOrientation,
			addedValue,
			rating,
			wasEvaluated,
			comment,
			classes,
			compliments,
			improvements,
			actionId
		} = this.props

		return (
			<div className={classes.root}>
				<div
					className={`${classes.form} ${
						wasEvaluated ? classes['was-evaluated'] : ''
					} `}
				>
					<div>
						<Question
							isPrimaryText={!wasEvaluated}
							primaryText={translate(
								'label/action-taken-add-value'
							)}
							secondaryText={translate(
								'label/contains-added-value'
							)}
						/>
						<Checkbox
							value={addedValue}
							keyProp="key"
							keyValue="optionValue"
							onChange={this.handleCheckboxValue}
							options={[
								{
									optionValue: '1',
									key: 'yes',
									label: translate('label/yes'),
									labelPlacement: 'end'
								},
								{
									optionValue: '0',
									key: 'no',
									label: translate('label/no'),
									labelPlacement: 'end'
								}
							]}
						/>
					</div>

					<Divider />

					<div>
						<Question
							isPrimaryText={!wasEvaluated}
							primaryText={translate(
								'label/would-you-rate-action-taken'
							)}
							secondaryText={translate(
								'label/generated-action-was-evaluated-as'
							)}
						/>
						<div className={classes.rating}>
							{rating && (
								<Typography
									style={{
										color: getStateColour(
											rating.value - 1,
											{
												colors: [
													'#D30000',
													'#F47200',
													'#ADADAD',
													'#BAB709',
													'#08D316'
												]
											}
										)
									}}
								>
									{rating.label}
								</Typography>
							)}
							<Rating
								currentValue={rating && rating.value}
								listOrientation={listOrientation}
								handleSelectedValue={this.handleSelectedValue}
								keyProp="optionKey"
								options={[
									{
										value: 1,
										optionKey: 'very_dissatisfied',
										icon: 'star_border',
										activeIcon: 'star',
										label: translate('label/terrible'),
										secondaryAction: this.playBadSong
									},
									{
										value: 2,
										optionKey: 'dissatisfied',
										icon: 'star_border',
										activeIcon: 'star',
										label: translate('label/bad'),
										secondaryAction: this.playBadSong
									},
									{
										value: 3,
										optionKey: 'neutro',
										icon: 'star_border',
										activeIcon: 'star',
										label: translate('label/regular'),
										secondaryAction: this.playBadSong
									},
									{
										value: 4,
										optionKey: 'satisfied',
										icon: 'star_border',
										activeIcon: 'star',
										label: translate('label/good'),
										secondaryAction: this
											.playCongratulationSong
									},
									{
										value: 5,
										optionKey: 'satisfied_alt',
										icon: 'star_border',
										activeIcon: 'star',
										label: translate('label/greate'),
										secondaryAction: this
											.playCongratulationSong
									}
								]}
							/>
						</div>
					</div>

					<div>
						<QuestionBody
							isVisible={Boolean(
								!wasEvaluated || (wasEvaluated && compliments)
							)}
						>
							<Divider />
							<Question
								isPrimaryText={!wasEvaluated}
								primaryText={translate(
									'label/praise-action-taken'
								)}
								secondaryText={translate('label/compliments')}
							/>
							<ReasonsContainer
								actionId={actionId}
								reasonType="compliments"
								render={(reasons, reasonType) => (
									<OptionList
										active={!wasEvaluated}
										optionType={reasonType}
										onSelect={this.handleCompliments}
										options={reasons}
									/>
								)}
							/>
						</QuestionBody>
					</div>

					<div>
						<QuestionBody
							isVisible={Boolean(
								!wasEvaluated || (wasEvaluated && improvements)
							)}
						>
							<Divider />
							<Question
								isPrimaryText={!wasEvaluated}
								primaryText={translate(
									'label/suggest-improvement'
								)}
								secondaryText={translate('label/improvements')}
							/>
							<ReasonsContainer
								actionId={actionId}
								reasonType="improvements"
								render={(reasons, reasonType) => (
									<OptionList
										active={!wasEvaluated}
										optionType={reasonType}
										onSelect={this.handleImprovements}
										options={reasons}
									/>
								)}
							/>
						</QuestionBody>
					</div>

					<div>
						<QuestionBody
							isVisible={Boolean(
								!wasEvaluated || (wasEvaluated && comment)
							)}
						>
							<Divider />
							<Question
								isPrimaryText={!wasEvaluated}
								primaryText={translate('label/leave-comment')}
								secondaryText={translate('label/comment')}
							/>
							<TextField
								value={comment}
								onChange={this.handleComment}
								variant="outlined"
								placeholder={translate(
									'placeholder/leave-a-comment'
								)}
								fullWidth
								multiline
							/>
						</QuestionBody>
					</div>

					{!wasEvaluated && (
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={this.handleEvaluate}
							disabled={addedValue === null || rating === null}
						>
							{translate('label/send')}
						</Button>
					)}
				</div>
			</div>
		)
	}
}

const makeMapStateToProps = () => {
	const getRating = makeGetEvaluationRating()
	const getComment = makeGetEvaluationComment()
	const getAddedValue = makeGetAddedValue()
	const getWasEvaluted = makeGetActionWasEvaluated()
	const getCompliments = makeGetActionCompliments()
	const getImprovements = makeGetActionImprovements()

	const mapStateToProps = (state, ownProps) => {
		return {
			// actionId: state.actionsState.actionId, // FIXME: refactor to use selector
			evaluate: state.evaluationUserActionsState, // FIXME: refactor to use selector
			addedValue: getAddedValue(state, ownProps),
			rating: getRating(state, ownProps),
			comment: getComment(state, ownProps),
			wasEvaluated: getWasEvaluted(state, ownProps),
			compliments: getCompliments(state, ownProps),
			improvements: getImprovements(state, ownProps)
		}
	}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAddedValue: (addedValue) =>
			dispatch(setActionEvaluationAddedValue(addedValue)),
		setRating: (rating) => dispatch(setActionEvaluationRating(rating)),
		setCompliments: (compliments) =>
			dispatch(setActionEvaluationCompliments(compliments)),
		setImprovements: (improvements) =>
			dispatch(setActionEvaluationImprovements(improvements)),
		setComment: (comment) => dispatch(setActionEvaluationComment(comment)),
		clearData: () => dispatch(clearData()),
		handleNotification: async (message) =>
			dispatch(setNotificationContent(message)), // handleClose: () => dispatch(setDialogNotificationClose()),
		handleOpen: async () => dispatch(setDialogNotificationOpen()),
		handleCloseDialog: () => dispatch(setEvaluationDialogClose()),
		handleInvalidInput: (notification) =>
			dispatch(showSnackBar(notification))
	}
}

export default compose(
	withStyles(styles),
	withAuthentication,
	withActiveCompany,
	// withSuperUserAccess,
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(ActionEvaluateContainer)
