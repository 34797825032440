import { createSelector } from 'reselect'
import { filterByOptions } from '../../component/reusable/filter/functions'

/**
 * @author Ray
 * @email mauricio@arquia.com.br
 * @created 10-11-2020
 */
const BASE_SPLICE = 500

const getStateLogs = state => state.logsState

const getLogFilterValues = state => state.logHandler.filters

const getFilterWithDev = state => state.logHandler.filterWithDev

const getTimeFilters = state => state.logHandler.time

const getInitialLogs = createSelector([getStateLogs], logs => logs)

const verifyIsDev = (results) => {
	if (results.length === 0) return 0

	if (results.length > 0) {
		let data = {
			arrayData: results.filter(log => !log.isDev),
			isHaveLogs:
				results.filter(log => !log.isDev).length > 0 ? true : false,
			logsLenght: !!results.filter(log => !log.isDev).length
				? results.filter(log => !log.isDev).length
				: 0,
		}
		return data
	}
}

const getLogsList = createSelector(
	[getInitialLogs, getLogFilterValues, getTimeFilters, getFilterWithDev],
	(logs, filterValues, date, logsIsDev) => {
		let results = []
		let isLoading = true
		let haveLogs = false

		let logsList = Object.keys(logs).map(logId => ({
			logId,
			...logs[logId],
		}))

		let fromDate = new Date(date.from)
		let toDate = new Date(date.to)

		let logsFiltered = filterByOptions(
			logsList.filter(item => {
				let time = item.time.seconds * 1000
				return time < toDate.getTime() && time > fromDate.getTime()
			}),
			filterValues,
		)

		if (logsFiltered.length === 0) {
			results = logsFiltered
			isLoading = false
			haveLogs = false
		} else if (logsFiltered.length <= BASE_SPLICE) {
			results = logsFiltered
			isLoading = false
			haveLogs = true
		} else {
			//results = splitArray(logsFiltered, BASE_SPLICE)
			results = logsFiltered
			isLoading = false
			haveLogs = true
		}

		const data = verifyIsDev(logsFiltered, logsIsDev)

		const { arrayData, isHaveLogs, logsLenght } = data

		return {
			isLoading: isLoading,
			results: arrayData,
			haveLogs: isHaveLogs,
			qtdLogs: logsLenght,
		}
	},
)

// const splitArray = (base, max) => {
// 	var res = []

// 	for (var i = 0; i < base.length; i = i + (max - 1)) {
// 		res.push(base.slice(i, i + max))
// 	}

// 	res[res.length - 1].push(base[0])
// 	return res
// }

export default getLogsList
