const INITIAL_STATE = {
	formData: {
		name: '',
		active: false
	},
	global: false,
	formQuerying: false,
	permissionId: null
}

const setFormQuerying = (state, action) => {
	return Object.assign({}, state, { formQuerying: action.formQuerying })
}

const setGlobal = (state, action) => {
	return Object.assign({}, state, { global: action.global })
}

/**
 * Will get to clear a [GLOBAL] permission form set data
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 */
const clearFormData = (state) => {
	return Object.assign({}, state, INITIAL_STATE)
}

/**
 * Stores a [GLOBAL] permission form set data
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current state of the app
 * @param {Object} action The action with "type" and other desired properties
 */
const storeFormData = (state, action) => {
	return Object.assign({}, state, {
		formData: Object.assign({}, state.formData, action.formData)
	})
}

/**
 * Will set a form object ID on the next state
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const setFormId = (state, action) => {
	return Object.assign({}, state, { permissionId: action.id })
}

/**
 * Manages [GLOBAL] permission set state layer
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current state of the app
 * @param {Object} action The action with "type" and other desired properties
 */
function permissionFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_PERMISSION_FORM_GLOBAL': {
			return setGlobal(state, action)
		}
		case 'SET_PERMISSION_FORM_QUERYING': {
			return setFormQuerying(state, action)
		}
		case 'SET_PERMISSION_FORM_ID': {
			return setFormId(state, action)
		}
		case 'CLEAR_PERMISSION_FORM_DATA': {
			return clearFormData(state)
		}
		case 'STORE_PERMISSION_FORM_DATA': {
			return storeFormData(state, action)
		}
		default:
			return state
	}
}

export default permissionFormReducer
