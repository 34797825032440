import React from 'react'
import withActiveCompany from '../../reusable/withActiveCompany'
import { fs } from '../../../firebase/firebase'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import styleAvaliation from './styleAvaliation'
import { translate } from '../../../utils/lang'
import { purple } from '@material-ui/core/colors'
import {
	withStyles,
	Tooltip,
	Typography,
	Button,
	Card,
	Badge,
	FormControlLabel,
	Switch,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import Carousel from 'nuka-carousel'
import Slider from 'react-slick'
import Moment from 'react-moment'

import assertivo from './imagens/assertivo.png'
import boaComunicacao from './imagens/boaComunicacao.png'
import criativo from './imagens/criativo.png'
import demorouResponder from './imagens/demorouResolver.png'
import melhorarComunicacao from './imagens/melhorarComunicacao.png'
import melhorouResultado from './imagens/melhorouResultado.png'
import naoMelhorou from './imagens/naoMelhorou.png'
import rapidez from './imagens/rapidez.png'
import star1 from './imagens/star1.png'
import Swal from 'sweetalert2'
import './styles.css'

const PurpleSwitch = withStyles({
	switchBase: {
		color: purple[300],
		'&$checked': {
			color: purple[500],
		},
		'&$checked + $track': {
			backgroundColor: purple[900],
		},
	},
	checked: {},
	track: {},
})(Switch)

class Avaliation extends React.Component {
	state = {
		checked: false,
		isNewFingerUser: false,
	}

	async componentDidMount() {
		const { userId, authUser, activeCompanyId } = this.props
		let storage = window.localStorage
		let isEnabledFingerprint

		let userRef = fs.collection('users').doc(authUser.uid)

		await userRef.get().then(user => {
			if (!!user.data().fingerPrint) {
				isEnabledFingerprint = user.data().fingerPrint.length > 1
				storage.setItem('fingerPrint', isEnabledFingerprint)

				this.setState({ checked: isEnabledFingerprint })
			} else {
				this.setState({
					checked: false,
					isNewFingerUser: true,
				})
			}
		})

		this.setState({ userRef })

		let meuDoc = ''

		let estrela = 0
		let acoes = 0
		let mediaEstrela = 0
		let assertiveness = 0
		let creativeness = 0
		let goodCommunication = 0
		let improvedOutCome = 0
		let quickness = 0
		let delayedSolution = 0
		let improveCommunications = 0
		let resultsNotImproved = 0
		let elogios = 0
		let melhorias = 0
		let comentario = []
		let dateEvaluate = 0
		let miliSeconds = 0
		let data = 0

		//Autenticação para nome e email

		const auth = this.props
		var email

		if (auth != null) {
			const { onSetName, onSetEmail } = this.props
			fs.collection('users')
				.doc(auth.authUser.uid)
				.get()
				.then(user => {
					onSetName(user.data().name)
				})

			// name = auth.authUser.displayName
			email = auth.authUser.email

			onSetEmail(email)
		}

		/*
		Busca quantia de avaliações por estrelas e faz a média
		Busca indicadores da avaliação do usuário
	*/

		await fs
			.collection('companies')
			.doc(activeCompanyId)
			.collection('actions')
			.where('active', '==', false)
			.get()
			.then(snap => {
				const { onSetElogios, onSetMelhorias } = this.props
				snap.forEach(doc => {
					meuDoc = doc.data()

					if (userId === meuDoc.receiver.key) {
						if (meuDoc.evaluate == null) {
							return null
						} else {
							if (meuDoc.evaluate.rating.value != null) {
								acoes = acoes + 1
								estrela = estrela + meuDoc.evaluate.rating.value
							}

							if (meuDoc.evaluate.compliments != null) {
								const {
									onSetAssertiveness,
									onSetCreativeness,
									onSetGoodCommunication,
									onSetImprovedOutCome,
									onSetQuickness,
								} = this.props
								let arrayCompliments =
									meuDoc.evaluate.compliments
								arrayCompliments.forEach(classification => {
									if (
										classification.classification ===
										'assertiveness'
									) {
										assertiveness = assertiveness + 1
										onSetAssertiveness(assertiveness)
									}

									if (
										classification.classification ===
										'creativeness'
									) {
										creativeness = creativeness + 1
										onSetCreativeness(creativeness)
									}

									if (
										classification.classification ===
										'good-communication'
									) {
										goodCommunication =
											goodCommunication + 1
										onSetGoodCommunication(
											goodCommunication,
										)
									}

									if (
										classification.classification ===
										'improved-outcome'
									) {
										improvedOutCome = improvedOutCome + 1
										onSetImprovedOutCome(improvedOutCome)
									}

									if (
										classification.classification ===
										'quickness'
									) {
										quickness = quickness + 1
										onSetQuickness(quickness)
									}
								})
							}

							if (meuDoc.evaluate.improvements != null) {
								const {
									onSetDelayedSolution,
									onSetImproveCommunications,
									onSetResultsNotImproved,
								} = this.props
								let arrayImprovements =
									meuDoc.evaluate.improvements

								arrayImprovements.forEach(classification => {
									if (
										classification.classification ===
										'delayed-solution'
									) {
										delayedSolution = delayedSolution + 1
										onSetDelayedSolution(delayedSolution)
									}

									if (
										classification.classification ===
										'improve-communications'
									) {
										improveCommunications =
											improveCommunications + 1
										onSetImproveCommunications(
											improveCommunications,
										)
									}

									if (
										classification.classification ===
										'results-not-improved'
									) {
										resultsNotImproved =
											resultsNotImproved + 1
										onSetResultsNotImproved(
											resultsNotImproved,
										)
									}
								})
							}

							if (meuDoc.evaluate.comment != null) {
								const { onSetComentarios } = this.props

								comentario.push(meuDoc.evaluate.comment)
								onSetComentarios(comentario)
							}
						}
					}

					mediaEstrela = estrela / acoes
					const { onSetStar, onSetAcoes } = this.props

					if (mediaEstrela > 0) {
						onSetStar(mediaEstrela)
					}
					if (mediaEstrela > 0) {
						onSetAcoes(acoes)
					}
				})

				elogios =
					assertiveness +
					creativeness +
					goodCommunication +
					improvedOutCome +
					quickness
				onSetElogios(elogios)

				melhorias =
					delayedSolution + improveCommunications + resultsNotImproved
				onSetMelhorias(melhorias)
			})
			.catch(err => {
				console.log(
					'erro ao buscar avaliação do usuário, indicadores e comentários',
					err,
				)
			})

		//Busca data dos comentários

		await fs
			.collection('companies')
			.doc(activeCompanyId)
			.collection('actions')
			.where('active', '==', false)
			.get()
			.then(snap => {
				const { onSetDataFinal } = this.props
				snap.forEach(doc => {
					meuDoc = doc.data()

					if (userId === meuDoc.receiver.key) {
						if (meuDoc.evaluate != null) {
							dateEvaluate = meuDoc.evaluate.date.seconds
							miliSeconds = dateEvaluate * 1000
							data = new Date(miliSeconds)
							onSetDataFinal(data)
						}
					}
				})
			})
			.catch(err => {
				console.log('erro ao buscar Comentários', err)
			})
	}

	//Nome do indicador ao clicar

	clickOpen = event => {
		this.setState({
			[event]: true,
		})
	}
	clickClose = event => {
		this.setState({
			[event]: false,
		})
	}

	//Cria elogios

	funcao1 = onGetAssertiveness => {
		const { classes } = this.props

		if (onGetAssertiveness > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(translate('label/assertiveness'))
						}
						open={!!this.state[translate('label/assertiveness')]}
						disableHoverListener
						title={translate('label/assertiveness')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(translate('label/assertiveness'))
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetAssertiveness}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={assertivo}
									alt={translate('label/assertiveness')}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}
	funcao2 = onGetCreativeness => {
		const { classes } = this.props

		if (onGetCreativeness > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(translate('label/creativeness'))
						}
						open={!!this.state[translate('label/creativeness')]}
						disableHoverListener
						title={translate('label/creativeness')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(translate('label/creativeness'))
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetCreativeness}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={criativo}
									alt={translate('label/creativeness')}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}
	funcao3 = onGetGoodCommunication => {
		const { classes } = this.props

		if (onGetGoodCommunication > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(
								translate('label/good-communication'),
							)
						}
						open={
							!!this.state[translate('label/good-communication')]
						}
						disableHoverListener
						title={translate('label/good-communication')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(
									translate('label/good-communication'),
								)
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetGoodCommunication}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={boaComunicacao}
									alt={translate('label/good-communication')}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}
	funcao4 = onGetImprovedOutCome => {
		const { classes } = this.props

		if (onGetImprovedOutCome > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(translate('label/improved-outcome'))
						}
						open={!!this.state[translate('label/improved-outcome')]}
						disableHoverListener
						title={translate('label/improved-outcome')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(
									translate('label/improved-outcome'),
								)
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetImprovedOutCome}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={melhorouResultado}
									alt={translate('label/improved-outcome')}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}
	funcao5 = onGetQuickness => {
		const { classes } = this.props

		if (onGetQuickness > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(translate('label/quickness'))
						}
						open={!!this.state[translate('label/quickness')]}
						disableHoverListener
						title={translate('label/quickness')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(translate('label/quickness'))
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetQuickness}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={rapidez}
									alt={translate('label/quickness')}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}

	//Cria melhorias

	funcao6 = onGetDelayedSolution => {
		const { classes } = this.props

		if (onGetDelayedSolution > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(translate('label/delayed-solution'))
						}
						open={!!this.state[translate('label/delayed-solution')]}
						disableHoverListener
						title={translate('label/delayed-solution')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(
									translate('label/delayed-solution'),
								)
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetDelayedSolution}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={demorouResponder}
									alt={translate('label/delayed-solution')}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}
	funcao7 = onGetImproveCommunications => {
		const { classes } = this.props

		if (onGetImproveCommunications > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(
								translate('label/improve-communications'),
							)
						}
						open={
							!!this.state[
								translate('label/improve-communications')
							]
						}
						disableHoverListener
						title={translate('label/improve-communications')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(
									translate('label/improve-communications'),
								)
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetImproveCommunications}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={melhorarComunicacao}
									alt={translate(
										'label/improve-communications',
									)}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}
	funcao8 = onGetResultsNotImproved => {
		const { classes } = this.props

		if (onGetResultsNotImproved > 0) {
			return (
				<div>
					<Tooltip
						onClose={() =>
							this.clickClose(
								translate('label/results-not-improved'),
							)
						}
						open={
							!!this.state[
								translate('label/results-not-improved')
							]
						}
						disableHoverListener
						title={translate('label/results-not-improved')}>
						<Button
							className={classes.botaoBadge}
							onClick={() =>
								this.clickOpen(
									translate('label/results-not-improved'),
								)
							}>
							<Badge
								className={classes.badges}
								classes={{ badge: classes.badge }}
								badgeContent={onGetResultsNotImproved}
								color={'primary'}>
								<img
									className={classes.avatar}
									src={naoMelhorou}
									alt={translate(
										'label/results-not-improved',
									)}
								/>
							</Badge>
						</Button>
					</Tooltip>
				</div>
			)
		}
	}

	criaElogios = () => {
		const {
			classes,
			onGetAssertiveness,
			onGetCreativeness,
			onGetGoodCommunication,
			onGetImprovedOutCome,
			onGetQuickness,
		} = this.props

		const elogio = {
			infinite: false,
			speed: 500,
			slidesToShow: isWidthUp('sm', this.props.width) ? 5 : 3,
			slidesToScroll: isWidthUp('sm', this.props.width) ? 1 : 2,
			arrows: false,
		}

		return (
			<Slider {...elogio} className={classes.sliderElogio}>
				{this.funcao1(onGetAssertiveness)}
				{this.funcao2(onGetCreativeness)}
				{this.funcao3(onGetGoodCommunication)}
				{this.funcao4(onGetImprovedOutCome)}
				{this.funcao5(onGetQuickness)}
			</Slider>
		)
	}

	criaMelhorias = () => {
		const {
			classes,
			onGetDelayedSolution,
			onGetImproveCommunications,
			onGetResultsNotImproved,
		} = this.props
		const melhoria = {
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
		}

		return (
			<Slider {...melhoria} className={classes.sliderElogio}>
				{this.funcao6(onGetDelayedSolution)}
				{this.funcao7(onGetImproveCommunications)}
				{this.funcao8(onGetResultsNotImproved)}
			</Slider>
		)
	}

	//Cria slider e adiciona os comentários nele

	criaSlider = () => {
		const { classes, onGetComentarios, onGetDataFinal } = this.props

		return (
			<Carousel withoutControls>
				{onGetComentarios.map(comentario => {
					if (comentario) {
						return (
							<div>
								<Card className={classes.cardComentario}>
									<div className={classes.comentarios}>
										"{comentario}"
									</div>
									<div>
										<Moment
											className={classes.comentarios}
											format={'DD/MM/YYYY'}>
											{onGetDataFinal}
										</Moment>
									</div>
								</Card>
							</div>
						)
					} else {
						return null
					}
				})}
			</Carousel>
		)
	}

	toggleChecked = () => {
		const { checked, userRef, isNewFingerUser } = this.state
		const { Fingerprint } = window

		if (!checked) {
			Fingerprint.isAvailable(
				result => {
					Swal.fire({
						title: 'Biometria habilitada!',
						icon: 'success',
						showCancelButton: false,
						showCloseButton: false,
						timer: 1500,
					})
					if (isNewFingerUser) {
						userRef.set(
							{
								fingerPrint: result,
							},
							{ merge: true },
						)
					}
					userRef.update({
						fingerPrint: result,
					})
					this.setState({ checked: true })
				},
				error => {
					Swal.fire({
						title: 'Biometria não habilitada!',
						text: 'Verifique as configuraçoes do seu celular',
						icon: 'error',
						showCancelButton: false,
						showCloseButton: false,
						timer: 2000,
					})
					console.log(error.message)
				},
			)
		} else {
			userRef.update({
				fingerPrint: '',
			})

			this.setState({ checked: false })
		}
	}

	render() {
		const {
			classes,
			onGetName,
			onGetEmail,
			onGetElogios,
			onGetMelhorias,
			onGetStar,
			onGetAcoes,
			onGetComentarios,
		} = this.props
		const { checked } = this.state

		return (
			<div className={classes.container}>
				<div className={classes.containerCard}>
					<div className={classes.headerAccount}>
						<h3 className={classes.avaliacao}>
							{translate('title/my-account')}
						</h3>

						<h1 className={classes.avaliacao}>
							{onGetStar.toFixed(2)}
							<img
								className={classes.icone}
								src={star1}
								alt="Estrela"
							/>
							{onGetAcoes}
						</h1>
					</div>
					<form>
						{/* <label className={classes.nomeEmail}>
							{translate('label/name')}:
						</label> */}

						<Typography className={classes.nome}>
							{onGetName === '' ? 'Carregando...' : onGetName}
						</Typography>

						<hr className={classes.hr} />

						{/* <label className={classes.nomeEmail}>
							{translate('label/email')}:
						</label> */}
						<Typography className={classes.nomeEmail}>
							{onGetEmail === '' ? 'Carregando...' : onGetEmail}
						</Typography>
						<hr className={classes.hr} />
					</form>

					{!!onGetElogios && onGetElogios > 0 ? (
						<React.Fragment>
							<h3 className={classes.infosExtras}>
								{translate('label/compliments')}
							</h3>
							<React.Fragment>
								{this.criaElogios()}
							</React.Fragment>
						</React.Fragment>
					) : (
						<h3 className={classes.infosExtras}>
							{translate('label/no-compliments')}
						</h3>
					)}
					{!!onGetMelhorias && onGetMelhorias > 0 ? (
						<React.Fragment>
							<h3 className={classes.infosExtras}>
								{translate('label/suggest-improvement')}
							</h3>
							<React.Fragment>
								{' '}
								{this.criaMelhorias()}{' '}
							</React.Fragment>
						</React.Fragment>
					) : (
						<h3 className={classes.infosExtras}>
							{translate('label/no-sugestions-improvement')}
						</h3>
					)}
					{!!onGetComentarios &&
					Object.values(onGetComentarios).length !== 0 ? (
						<React.Fragment>
							<h3 className={classes.infosExtras}>
								{translate('label/comment')}
							</h3>
							<React.Fragment>
								{' '}
								{this.criaSlider()}{' '}
							</React.Fragment>
						</React.Fragment>
					) : (
						<h3 className={classes.infosExtras}>
							{translate('label/no-comments')}
						</h3>
					)}
					{!!window.cordova && (
						<div className={classes.digital}>
							<h3>Habilitar entrar com digital</h3>
							<FormControlLabel
								className={classes.switch}
								control={
									<PurpleSwitch
										checked={checked}
										onChange={this.toggleChecked}
									/>
								}
								label={`${
									checked ? 'Habilitado' : 'Desabilitado'
								}`}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	onGetEmail: state.avaliationsMyAccountState.email,
	onGetName: state.avaliationsMyAccountState.nome,
	onGetIdUser: state.avaliationsMyAccountState.idUser,
	onGetStar: state.avaliationsMyAccountState.star,
	onGetAcoes: state.avaliationsMyAccountState.acoes,
	onGetAssertiveness: state.avaliationsMyAccountState.assertiveness,
	onGetCreativeness: state.avaliationsMyAccountState.creativeness,
	onGetGoodCommunication: state.avaliationsMyAccountState.goodCommunication,
	onGetImprovedOutCome: state.avaliationsMyAccountState.improvedOutCome,
	onGetQuickness: state.avaliationsMyAccountState.quickness,
	onGetDelayedSolution: state.avaliationsMyAccountState.delayedSolution,
	onGetImproveCommunications:
		state.avaliationsMyAccountState.improveCommunications,
	onGetResultsNotImproved: state.avaliationsMyAccountState.resultsNotImproved,
	onGetElogios: state.avaliationsMyAccountState.elogios,
	onGetMelhorias: state.avaliationsMyAccountState.melhorias,
	onGetComentarios: state.avaliationsMyAccountState.comentarios,
	onGetDataFinal: state.avaliationsMyAccountState.dataFinal,
})

const mapDispatchToProps = dispatch => ({
	onSetEmail: text => dispatch({ type: 'SET_EMAIL', text }),
	onSetName: text => dispatch({ type: 'SET_NAME', text }),
	onSetIdUser: text => dispatch({ type: 'SET_ID_USER', text }),
	onSetStar: number => dispatch({ type: 'SET_STAR', number }),
	onSetAcoes: number => dispatch({ type: 'SET_ACOES', number }),
	onSetAssertiveness: number =>
		dispatch({ type: 'SET_ASSERTIVENESS', number }),
	onSetCreativeness: number => dispatch({ type: 'SET_CREATIVENESS', number }),
	onSetGoodCommunication: number =>
		dispatch({ type: 'SET_GOOD_COMMUNICATION', number }),
	onSetImprovedOutCome: number =>
		dispatch({ type: 'SET_IMPROVED_OUT_COME', number }),
	onSetQuickness: number => dispatch({ type: 'SET_QUICKNESS', number }),
	onSetDelayedSolution: number =>
		dispatch({ type: 'SET_DELAYED_SOLUTION', number }),
	onSetImproveCommunications: number =>
		dispatch({ type: 'SET_IMPROVE_COMMUNICATIONS', number }),
	onSetResultsNotImproved: number =>
		dispatch({ type: 'SET_RESULTS_NOT_IMPROVED', number }),
	onSetElogios: number => dispatch({ type: 'SET_ELOGIOS', number }),
	onSetMelhorias: number => dispatch({ type: 'SET_MELHORIAS', number }),
	onSetComentarios: text => dispatch({ type: 'SET_COMENTARIOS', text }),
	onSetDataFinal: data => dispatch({ type: 'SET_DATA_FINAL', data }),
})

export default compose(
	withWidth(),
	withActiveCompany,
	withStyles(styleAvaliation),
	connect(mapStateToProps, mapDispatchToProps),
)(Avaliation)
