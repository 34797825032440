// TODO: mudar o nome do arquivo para filters, separar as actions por component

export const FILTER_BY_AUTHOR_TYPE = 'FILTER_BY_AUTHOR_TYPE'
export const FILTER_BY_AUTHOR_TYPE_ACTIONS = 'FILTER_BY_AUTHOR_TYPE_ACTIONS'
export const CLEAN_FILTER_TYPE = 'CLEAN_FILTER_TYPE'
export const SET_ACTION = 'SET_ACTION'
export const SET_SNAPSHOT = 'SET_SNAPSHOT'
export const SET_ACTION_RESPONSES = 'SET_ACTION_RESPONSES'
export const SET_ACTION_RESPONSES_TO_INITITAL_STATE =
	'SET_ACTION_RESPONSES_TO_INITITAL_STATE'

export const filterByAuthorType = (value) => {
	return {
		type: FILTER_BY_AUTHOR_TYPE,
		value
	}
}

export const filterByAuthorTypeActions = (value) => {
	return {
		type: FILTER_BY_AUTHOR_TYPE_ACTIONS,
		value
	}
}

export const cleanFilterType = () => {
	return {
		type: CLEAN_FILTER_TYPE
	}
}

export const setAction = (action) => {
	return {
		type: SET_ACTION,
		action
	}
}

export const setSnapshot = (blob) => {
	return {
		type: SET_SNAPSHOT,
		blob
	}
}

export const setActionResponses = (responses) => {
	return {
		type: SET_ACTION_RESPONSES,
		responses
	}
}

export const setActionResponsesToInitialState = () => {
	return {
		type: SET_ACTION_RESPONSES_TO_INITITAL_STATE
	}
}
