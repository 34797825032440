import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
	updateUserData,
	requestUserCompany,
	takeAcess,
	doCreatePermissions,
	doAlterConfigUser,
	identifyComponentsInCompany
} from './ControllerCreateUser'
import { doPasswordReset } from '../../../firebase/auth'
import { firebase } from '../../../firebase'
import { fs } from '../../../firebase/firebase'
import {Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Card,Modal,Switch,Checkbox,
	InputBase,IconButton,Button,TextField, Typography,Drawer,Divider,List,ListItem,ListItemIcon,ListItemText,FormControl
} from '@material-ui/core'
import Loader from '../../reusable/loader'
import { withStyles } from '@material-ui/core/styles'
import {Search, ArrowDropDown, ArrowDropUp, Edit, Save, Visibility, VisibilityOff,PersonAdd,ArrowRight} from '@material-ui/icons'
import clsx from 'clsx'

const styles = theme => ({
	containerTable: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: '64px',
	},
	root: {
		width: '100%',
		height: '100%',
		overflow: 'auto',
		backgroundColor: 'rgb(220 222 232)',
	},
	rootSearch: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		marginBottom: '5px',
	},
	input: {
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
	buttonSearch: {
		background: 'linear-gradient(to bottom, #4527a0, #673ab7)',
		border: 0,
		borderRadius: 5,
		color: 'white',
		height: 40,
		padding: '0 30px',
	},
	buttonAlteration: {
		marginTop: '20px',
		background: '#ABB4C0',
		padding: '10px',
		width: '300px',
	},
	buttonResetPass: {
		background: '#ABB4C0',
		padding: '10px',
		// width: '300px'
	},
	icons: {
		padding: '5px',
	},
	list: {
		width: '700px',
		zIndex: 9999,
	},
	fullList: {
		width: 'auto',
	},
	formControl: {
		margin: '5px',
	},
	alert: {
		color: 'red',
	},
	alertCreatedUser: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'grid',
		background: '#4CAF50',
	},
	switch: {
		display: '-webkit-inline-box',
	},
	itemsSwitch: {
		margin: '20px',
		alignItems: 'center',
	},
	rootForm: {
		width: 'auto',
	},
	pluginsCard: {
		width: 'auto',
		padding: '5px',
		margin: '5px',
		borderStyle: 'outset',
	},
	dashSets: {
		borderStyle: 'outset',
		margin: '5px',
	},
	buttonModal: {
		padding: '5px',
		display: 'flex',
		background: 'linear-gradient(to bottom, #4527a0, #673ab7)',
		justifyContent: 'center',
		alignItems: 'center',
	},
	iconButtonModal: {
		color: 'white',
		marginRight: '5px',
		fontSize: '15px',
	},
	paper: {
		position: 'absolute',
		width: '400px',
		border: '2px solid #000',
	},
	modalUpdade: {
		display: 'flex',
		alignItems: 'baseline',
		justifyContent: 'flex-end',
		margin: '10px',
	},
	itemsModalUpdate: {
		width: '400px',
		height: '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#4CAF50',
	}
})

class UsersCompany extends React.Component {
	state = {
		modalResetPass: false,
		takeLogs: false,
		isAdmin: null,
		modalUpdade: false,
		idSelectedUser: null,
		userCurrent: null,
		newName: '',
		name: '',
		email: '',
		errorName: false,
		errorEmail: false,
		plugins: null,
		notification: false,
		actions: false,
		loginType: '',
		status: false,
		log: false,
		download: false,
		upload: false,
		page: 0,
		rowsPerPage: 10,
		userFilter: null,
		validUser: false,
		openModal: [],
		upDate: '',
		reloadUsers: false,
		columns: [
			{
				id: 'name',
				label: 'Name',
				minWidth: 450,
				order: 'asc',
			},
			{
				id: 'email',
				label: 'Email',
				minWidth: 300,
				order: '',
			},
			{
				id: 'lastDate',
				label: 'Último login',
				minWidth: 300,
				order: '',
			},
			{
				id: 'status',
				label: 'Status',
				minWidth: 'auto',
				order: '',
			},
			{
				id: 'actions',
				label: 'Ações',
				minWidth: 'auto',
				align: 'right',
				order: '',
			},
			{
				id: 'notification',
				label: 'Notificações',
				minWidth: 'auto',
				align: 'right',
				order: '',
			},
			{
				id: 'loginType',
				label: 'Tipo de login',
				minWidth: 200,
				align: 'right',
				order: '',
			},
		],
	}

	componentDidMount() {
		const {
			usersProps,
			activeCompanyId,
			setUsersInReducer,
			setPermissionOrder,
			setComponentsPermissions,
			setWordSearch,
			setLoader,
		} = this.props

		setLoader(true)

		requestUserCompany(
			activeCompanyId,
			setUsersInReducer,
			setPermissionOrder,
		).then(() => {
			this.setState({
				name: '',
				email: '',
			})
			this.orderUsers(usersProps, 'name', 'asc')
			setPermissionOrder(false)
			setWordSearch(null)
		})
		setPermissionOrder(false)

		this.orderUsers(usersProps, 'name', 'asc')

		this.setState({
			rowsPerPage: 25,
		})

		firebase.auth.onAuthStateChanged(authUser => {
			if (!!authUser)
				this.setState({
					userCurrent: authUser.uid,
				})
		})

		identifyComponentsInCompany(activeCompanyId, setComponentsPermissions)
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			wordSearch,
			usersProps,
			userFilter,
			activeCompanyId,
			pluginUser,
			setPermissionOrder,
			orderPermission,
			componentsPermission,
			setUserReducer,
			setLoader,
			isLoadingMenu,
			setLoaderMenu,
		} = this.props

		const {
			columns,
			plugins,
			modalUpdade,
			userCurrent,
			isAdmin,
			modalResetPass,
		} = this.state

		let docs

		if (!!activeCompanyId && !!userCurrent && isAdmin === null) {
			fs.collection('companies')
				.doc(activeCompanyId)
				.collection('usersAdmins')
				.doc(userCurrent)
				.get()
				.then(snapShot => {
					docs = snapShot.data()

					this.setState({
						isAdmin: docs.active,
					})
				})
				.catch(err => {
					console.error('Error - ', err)
				})
		}

		if (userFilter.length > 0 && prevProps.userFilter.length === 0) {
			setLoader(false)
		}

		if (prevProps.wordSearch !== wordSearch && wordSearch != null)
			this.validFilter(usersProps)

		if (
			(!!usersProps && !userFilter) ||
			(usersProps.length > 0 && userFilter.length === 0) ||
			!userFilter ||
			prevProps.usersProps !== usersProps
		) {
			this.orderUsers(usersProps, 'name', 'asc')
		}

		if (prevProps.usersProps.length !== usersProps.length) {
			this.orderUsers(usersProps, 'name', 'asc')
		}

		prevState.columns.forEach((item, index) => {
			if (item.order !== columns[index].order) {
				if (columns[index].id === 'lastDate') {
					let userWithLogin = [],
						userWithoutLogin = []

					usersProps.forEach(item => {
						if (item.lastDate !== 'Sem Login') {
							userWithLogin.push(item)
						} else {
							userWithoutLogin.push(item)
						}
					})

					let newOrderUsers = Object.values(
						_.orderBy(userWithLogin, 'date', columns[index].order),
					)

					newOrderUsers.push(userWithoutLogin)

					setUserReducer(newOrderUsers)
				} else {
					this.orderUsers(
						usersProps,
						columns[index].id,
						columns[index].order,
					)
				}
			}
		})

		if (!plugins && !!prevProps.pluginUser) {
			this.setState({
				plugins: pluginUser,
			})
		}

		if (!!orderPermission && prevProps.orderPermission !== orderPermission) {
			this.validFilter(userFilter)
			setPermissionOrder(false)
		}

		if (
			!!componentsPermission &&
			prevProps.componentsPermission !== componentsPermission
		) {
			let newColumns = []

			columns.forEach(column => {
				newColumns.push(column)
			})

			componentsPermission.forEach(column => {
				newColumns.push(column)
			})

			this.setState({
				columns: newColumns,
			})
		}

		if (!!modalUpdade && prevState.modalUpdade !== modalUpdade) {
			setTimeout(this.handleCloseModal, 3000)
		}

		if (!!modalResetPass && prevState.modalResetPass !== modalResetPass) {
			setTimeout(this.closeModalResetPass, 3000)
		}

		if (!!isLoadingMenu && !!pluginUser) {
			setTimeout(setLoaderMenu(false), 3000)
		}
	}

	handleChangePage = (event, newPage) => {
		this.setState({
			page: newPage,
		})
	}

	handleChangeRowsPerPage = event => {
		this.setState({
			page: 0,
			rowsPerPage: +event.target.value,
		})
	}

	handleChange = event => {
		const { setWordSearch } = this.props

		setWordSearch(event.target.value)
	}

	validFilter = users => {
		const { wordSearch } = this.props

		let userFiltered = []

		users.forEach(item => {
			if (!!wordSearch) {
				let name = item.name.toLowerCase()
				if (name.indexOf(wordSearch.toLowerCase()) !== -1)
					userFiltered.push(item)
			}
		})

		this.orderUsers(userFiltered, 'name', 'asc')
	}

	orderUsers = (user, param, order) => {
		const { setUserReducer } = this.props

		setUserReducer(Object.values(_.orderBy(user, param, order)))
	}

	alterOrder = column => {
		const { columns } = this.state

		let newColumns = []

		columns.forEach(item => {
			if (item.id === column.id) {
				newColumns.push({
					id: column.id,
					label: column.label,
					minWidth: column.minWidth,
					order:
						column.order === ''
							? 'asc'
							: column.order === 'asc'
							? 'desc'
							: column.order === 'desc'
							? 'asc'
							: '',
				})
			} else {
				newColumns.push(item)
			}
		})

		this.setState({
			columns: newColumns,
		})
	}

	editUser = (index, type) => {
		this.setState({
			openModal: {
				type: type,
				index: index,
			},
		})
	}

	editUserInFirebase = (user, type) => {
		const {
			activeCompanyId,
			author,
			setUsersInReducer,
			setPermissionOrder,
		} = this.props
		const { upDate, errorName, newName, isAdmin } = this.state

		if (!!isAdmin) {
			if (type === 'email') {
				updateUserData(upDate, user, type, activeCompanyId, author)
			} else if (type === 'name' && !errorName) {
				updateUserData(newName, user, type, activeCompanyId, author)
			} else if (type === 'all') {
				updateUserData(
					user.name,
					user,
					'name',
					activeCompanyId,
					user.id,
				)
				updateUserData(
					user.actions,
					user,
					'actions',
					activeCompanyId,
					user.id,
				)
				updateUserData(
					user.notification,
					user,
					'notification',
					activeCompanyId,
					user.id,
				)
				updateUserData(
					user.download,
					user,
					'download',
					activeCompanyId,
					user.id,
				)
				updateUserData(
					user.upload,
					user,
					'upload',
					activeCompanyId,
					user.id,
				)
				updateUserData(user.log, user, 'log', activeCompanyId, user.id)
				updateUserData(
					user.status,
					user,
					'status',
					activeCompanyId,
					user.id,
				)
			} else {
				if (
					(type === 'status' && user.status === true) ||
					(type === 'actions' && user.actions === true) ||
					(type === 'notification' && user.notification === true) ||
					(type === 'download' && user.download === true) ||
					(type === 'upload' && user.upload === true) ||
					(type === 'log' && user.log === true)
				) {
					updateUserData(false, user, type, activeCompanyId, author)
				} else if (
					(type === 'status' && user.status === false) ||
					(type === 'actions' && user.actions === false) ||
					(type === 'notification' && user.notification === false) ||
					(type === 'download' && user.download === false) ||
					(type === 'upload' && user.upload === false) ||
					(type === 'log' && user.log === false)
				) {
					updateUserData(true, user, type, activeCompanyId, author)
				}
			}
		}

		this.handleOpenModal()
		requestUserCompany(
			activeCompanyId,
			setUsersInReducer,
			setPermissionOrder,
		)

		this.setState({
			openModal: null,
			reloadUsers: true,
		})
	}

	handleChangeUpData = e => {
		this.setState({
			upDate: e.target.value,
		})
	}

	handleChangeName = e => {
		this.setState({
			newName: e.target.value,
			errorName: false,
		})

		this.handleOpenModal()
	}

	sendEmail = email => {
		doPasswordReset(email)
		this.toggleDrawer(false, null)
		this.openModalResetPass()
	}

	openModalResetPass = () => {
		this.setState({
			modalResetPass: true,
		})
	}

	closeModalResetPass = () => {
		this.setState({
			modalResetPass: false,
		})
	}

	validateName = (user, type) => {
		const { name } = this.state

		if (name.length < 2 || name.length > 25) {
			this.setState({
				errorName: true,
			})
		} else {
			this.editUserInFirebase(user, type)
			this.editExitentUser(user)
			this.handleOpenModal()
			this.toggleDrawer(false, null)
		}
	}

	list = anchor => {
		const {
			classes,
			emailValidation,
			pluginUser,
			alertAlteration,
			isLoadingMenu,
		} = this.props
		const {
			errorEmail,
			errorName,
			notification,
			actions,
			status,
			log,
			download,
			upload,
			name,
			email,
			idSelectedUser,
			columns,
			loginType,
		} = this.state

		return (
			<div
				className={clsx(classes.list, {
					[classes.fullList]: anchor === 'top' || anchor === 'bottom',
				})}
				role="presentation">
				{!!isLoadingMenu && !pluginUser ? (
					<Loader internal />
				) : (
					<div>
						<div>
							<div
								style={{
									alignItems: 'center',
									justifyContent: 'space-between',
									display: 'flex',
									margin: '5px',
								}}>
								<IconButton
									onClick={() =>
										this.toggleDrawer(false, null)
									}>
									<ArrowRight />
								</IconButton>
								<Button
									className={classes.buttonResetPass}
									onClick={() => this.sendEmail(email)}>
									Resetar senha
								</Button>
							</div>
						</div>
						<Divider />
						<List>
							<Card className={classes.form}>
								<div style={{ display: 'grid' }}>
									<FormControl
										className={classes.formControl}>
										<TextField
											onChange={this.changeName}
											required
											id="standard-required"
											label="Nome"
											defaultValue={name}
										/>
										{!!errorName ? (
											<Typography
												className={classes.alert}
												variant="caption"
												display="block"
												gutterBottom>
												O campo 'nome' deve ter ente 2 e
												25 caracteres
											</Typography>
										) : (
											<div />
										)}
									</FormControl>
									<FormControl
										className={classes.formControl}>
										<TextField
											disabled
											onChange={this.changeEmail}
											required
											id="standard-required"
											label="Email"
											defaultValue={email}
										/>
										{!!emailValidation ? (
											<Typography
												className={classes.alert}
												variant="caption"
												display="block"
												gutterBottom>
												O endereço de e-mail já está
												sendo usado por outra conta.
											</Typography>
										) : (
											<div />
										)}
										{!!errorEmail ? (
											<Typography
												className={classes.alert}
												variant="caption"
												display="block"
												gutterBottom>
												Check o email digitado
											</Typography>
										) : (
											<div />
										)}
									</FormControl>
									<FormControl
										className={classes.formControl}>
										<TextField
											disabled
											id="standard-required"
											required
											label="Tipo de login"
											defaultValue={loginType}
										/>
									</FormControl>
									<FormControl className={classes.switch}>
										<FormControl
											className={classes.itemsSwitch}>
											<Typography
												variant="caption"
												display="block"
												gutterBottom>
												Status
											</Typography>
											<Switch
												checked={status}
												onClick={() =>
													this.alterStatusVisualization(
														'status',
													)
												}
												color="primary"
												name="viewAction"
												inputProps={{
													'aria-label':
														'primary checkbox',
												}}
											/>
										</FormControl>
										<FormControl
											className={classes.itemsSwitch}>
											<Typography
												variant="caption"
												display="block"
												gutterBottom>
												Ações
											</Typography>
											<Switch
												checked={actions}
												onClick={() =>
													this.alterStatusVisualization(
														'actions',
													)
												}
												color="primary"
												name="viewAction"
												inputProps={{
													'aria-label':
														'primary checkbox',
												}}
											/>
										</FormControl>
										<FormControl
											className={classes.itemsSwitch}>
											<Typography
												variant="caption"
												display="block"
												gutterBottom>
												Notificações
											</Typography>
											<Switch
												checked={notification}
												onClick={() =>
													this.alterStatusVisualization(
														'notification',
													)
												}
												color="primary"
												name="viewNotification"
												inputProps={{
													'aria-label':
														'primary checkbox',
												}}
											/>
										</FormControl>
										{!!columns &&
											columns.map(component => {
												return (
													<FormControl>
														{component.id ===
														'download' ? (
															<FormControl
																className={
																	classes.itemsSwitch
																}>
																<Typography
																	variant="caption"
																	display="block"
																	gutterBottom>
																	Download
																</Typography>
																<Switch
																	checked={
																		download
																	}
																	onClick={
																		this
																			.onClickDownload
																	}
																	color="primary"
																	name="viewDownload"
																	inputProps={{
																		'aria-label':
																			'primary checkbox',
																	}}
																/>
															</FormControl>
														) : component.id ===
														  'upload' ? (
															<FormControl
																className={
																	classes.itemsSwitch
																}>
																<Typography
																	variant="caption"
																	display="block"
																	gutterBottom>
																	Upload
																</Typography>
																<Switch
																	checked={
																		upload
																	}
																	onClick={
																		this
																			.onClickUpload
																	}
																	color="primary"
																	name="viewUpload"
																	inputProps={{
																		'aria-label':
																			'primary checkbox',
																	}}
																/>
															</FormControl>
														) : component.id ===
														  'log' ? (
															<FormControl
																className={
																	classes.itemsSwitch
																}>
																<Typography
																	variant="caption"
																	display="block"
																	gutterBottom>
																	Log
																</Typography>
																<Switch
																	checked={
																		log
																	}
																	onClick={
																		this
																			.onClickLog
																	}
																	color="primary"
																	name="viewLog"
																	inputProps={{
																		'aria-label':
																			'primary checkbox',
																	}}
																/>
															</FormControl>
														) : (
															<div />
														)}
													</FormControl>
												)
											})}
									</FormControl>
								</div>
								{!!pluginUser && (
									<List className={classes.rootForm}>
										{!!pluginUser &&
											pluginUser.map(value => {
												const labelId = `checkbox-list-label-${value.name}`

												return (
													<Card
														className={
															classes.pluginsCard
														}>
														<ListItem
															className={
																classes.rootForm
															}
															key={value.name}
															role={undefined}
															dense>
															<ListItemIcon>
																<Checkbox
																	edge="start"
																	checked={
																		value.status
																	}
																	tabIndex={
																		-1
																	}
																	disableRipple
																	inputProps={{
																		'aria-labelledby':
																			labelId,
																	}}
																	onChange={() =>
																		this.handleChangePlugin(
																			value,
																		)
																	}
																/>
															</ListItemIcon>
															<ListItemText
																id={labelId}
																primary={`${value.name}`}
															/>
															<IconButton
																onClick={() =>
																	this.handleChangePluginExpand(
																		value,
																	)
																}
																className={
																	classes.iconButton
																}
																aria-label="downIcon">
																{!!value.statusPlugin ? (
																	<ArrowDropUp
																		button
																	/>
																) : (
																	<ArrowDropDown
																		button
																	/>
																)}
															</IconButton>
														</ListItem>
														{!!value.dashSets &&
															!!value.statusPlugin &&
															value.dashSets.map(
																dashSet => {
																	return (
																		<Card
																			className={
																				classes.dashSets
																			}>
																			<ListItem
																				className={
																					classes.rootForm
																				}
																				key={
																					dashSet.name
																				}
																				role={
																					undefined
																				}
																				dense
																				style={{
																					marginLeft:
																						'50px',
																				}}>
																				<ListItemIcon>
																					<Checkbox
																						edge="start"
																						checked={
																							dashSet.active
																						}
																						tabIndex={
																							-1
																						}
																						disableRipple
																						inputProps={{
																							'aria-labelledby':
																								labelId,
																						}}
																						onChange={() =>
																							this.handleChangeDashSet(
																								value,
																								dashSet,
																							)
																						}
																					/>
																				</ListItemIcon>
																				<ListItemText
																					id={
																						labelId
																					}
																					primary={`${dashSet.name}`}
																				/>
																				<IconButton
																					onClick={() =>
																						this.handleChangeDashSetExpand(
																							value,
																							dashSet,
																						)
																					}
																					className={
																						classes.iconButton
																					}
																					aria-label="downIcon">
																					{!!dashSet.statusDashSet ? (
																						<ArrowDropUp
																							button
																						/>
																					) : (
																						<ArrowDropDown
																							button
																						/>
																					)}
																				</IconButton>
																			</ListItem>
																			{!!dashSet.dashItems &&
																				!!dashSet.statusDashSet &&
																				dashSet.dashItems.map(
																					dashItem => {
																						return (
																							<ListItem
																								className={
																									classes.rootForm
																								}
																								key={
																									dashItem.name
																								}
																								role={
																									undefined
																								}
																								dense
																								style={{
																									marginLeft:
																										'100px',
																								}}>
																								<ListItemIcon>
																									<Checkbox
																										edge="start"
																										checked={
																											dashItem.active
																										}
																										tabIndex={
																											-1
																										}
																										disableRipple
																										inputProps={{
																											'aria-labelledby':
																												labelId,
																										}}
																										onChange={() =>
																											this.handleChangeDashItem(
																												value,
																												dashSet,
																												dashItem,
																											)
																										}
																									/>
																								</ListItemIcon>
																								<ListItemText
																									id={
																										labelId
																									}
																									primary={`${dashItem.name}`}
																								/>
																							</ListItem>
																						)
																					},
																				)}
																		</Card>
																	)
																},
															)}
													</Card>
												)
											})}
									</List>
								)}
								<div
									style={{
										alignItems: 'center',
										justifyContent: 'center',
										display: 'flex',
										margin: '5px',
									}}>
									<Button
										onClick={() =>
											this.validateName(
												{
													status: status,
													log: log,
													download: download,
													upload: upload,
													notification: notification,
													actions: actions,
													id: idSelectedUser,
													name: name,
												},
												'all',
											)
										}
										className={classes.buttonAlteration}>
										Salvar alterações
									</Button>
								</div>
								{!!alertAlteration && (
									<Card className={classes.alertCreatedUser}>
										<ListItem>
											<ListItemText primary="Usuário atualizado" />
										</ListItem>
									</Card>
								)}
							</Card>
						</List>
					</div>
				)}
			</div>
		)
	}

	onClickDownload = () => {
		const { download } = this.state

		this.setState({
			download: !download,
		})
	}

	onClickUpload = () => {
		const { upload } = this.state

		this.setState({
			upload: !upload,
		})
	}

	onClickLog = () => {
		const { log } = this.state

		this.setState({
			log: !log,
		})
	}

	editExitentUser = user => {
		const { activeCompanyId, pluginUser, setAlertAlteration } = this.props
		const {
			log,
			notification,
			actions,
			download,
			upload,
			status,
			name,
			userCurrent,
			idSelectedUser,
			isAdmin,
		} = this.state

		if (!!isAdmin) {
			doCreatePermissions(
				{
					log: log,
					notification: notification,
					actions: actions,
					download: download,
					upload: upload,
					date: new Date(),
					active: status,
					status: status,
					author: userCurrent,
					plugins: pluginUser,
					setAlertAlteration: setAlertAlteration,
				},
				idSelectedUser,
				activeCompanyId,
			)

			doAlterConfigUser({
				name: name,
				active: status,
				user: idSelectedUser,
			})
		}
	}

	handleChangePlugin = plugin => {
		const { setAlertAlteration, pluginUser, setPluginUser } = this.props
		let newPlugins = []

		pluginUser.forEach(item => {
			let allDashSets = []

			item.dashSets.forEach(dashSet => {
				let allDashItems = []

				dashSet.dashItems.forEach(dashItem => {
					if (plugin.id === item.id) {
						if (!!plugin.status) {
							allDashItems.push(
								Object.assign(dashItem, {
									active: false,
								}),
							)
						} else {
							allDashItems.push(
								Object.assign(dashItem, {
									active: true,
								}),
							)
						}
					}
				})

				if (plugin.id === item.id) {
					if (!!plugin.status) {
						allDashSets.push(
							Object.assign(dashSet, {
								active: false,
								dashItems: allDashItems,
							}),
						)
					} else {
						allDashSets.push(
							Object.assign(dashSet, {
								active: true,
								dashItems: allDashItems,
							}),
						)
					}
				}
			})
			if (plugin.id === item.id) {
				if (!!plugin.status) {
					newPlugins.push(
						Object.assign(plugin, {
							status: false,
							dashSets: allDashSets,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(plugin, {
							status: true,
							dashSets: allDashSets,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		setAlertAlteration(false)
		this.setState({
			plugins: newPlugins,
		})
		setPluginUser(newPlugins)
	}

	handleChangePluginExpand = plugin => {
		const { pluginUser, setPluginUser } = this.props
		let newPlugins = []

		pluginUser.forEach(item => {
			if (plugin.id === item.id) {
				if (!!plugin.statusPlugin) {
					newPlugins.push(
						Object.assign(item, {
							statusPlugin: false,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(item, {
							statusPlugin: true,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		setPluginUser(newPlugins)
		this.setState({
			plugins: newPlugins,
		})
	}

	handleChangeDashSet = (plugin, dashSet) => {
		const { setAlertAlteration } = this.props
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			let allDashSets = [],
				status = false

			if (plugin.id === item.id) {
				item.dashSets.forEach(it => {
					let allDashItems = []

					if (it.idDashSet === dashSet.idDashSet) {
						it.dashItems.forEach(card => {
							if (!!dashSet.active) {
								allDashItems.push(
									Object.assign(card, {
										active: false,
									}),
								)
							} else {
								allDashItems.push(
									Object.assign(card, {
										active: true,
									}),
								)
							}
						})

						if (!!dashSet.active) {
							allDashSets.push(
								Object.assign(it, {
									dashItems: allDashItems,
									active: false,
								}),
							)
						} else {
							status = true
							allDashSets.push(
								Object.assign(it, {
									dashItems: allDashItems,
									active: true,
								}),
							)
						}
					} else {
						allDashSets.push(it)
					}
				})

				if (!!status) {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
							status: status,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		setAlertAlteration(false)
		this.setState({
			plugins: newPlugins,
		})
	}

	handleChangeDashSetExpand = (plugin, dashSet) => {
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			let allDashSets = []

			if (plugin.id === item.id) {
				item.dashSets.forEach(it => {
					if (it.idDashSet === dashSet.idDashSet) {
						if (!!dashSet.statusDashSet) {
							allDashSets.push(
								Object.assign(dashSet, {
									statusDashSet: false,
								}),
							)
						} else {
							allDashSets.push(
								Object.assign(dashSet, {
									statusDashSet: true,
								}),
							)
						}
					} else {
						allDashSets.push(it)
					}
				})

				newPlugins.push(Object.assign(item, { dashSets: allDashSets }))
			} else {
				newPlugins.push(item)
			}
		})

		this.setState({
			plugin: newPlugins,
		})
	}

	handleChangeDashItem = (plugin, dashSet, dashItem) => {
		const { setAlertAlteration } = this.props
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			let allDashSets = [],
				hasAlteration = false,
				status = false

			if (plugin.id === item.id) {
				item.dashSets.forEach(it => {
					let allDashItems = []

					it.dashItems.forEach(dashIt => {
						if (
							it.idDashSet === dashSet.idDashSet &&
							dashIt.idDashItem === dashItem.idDashItem
						) {
							hasAlteration = true
							if (!!dashIt.active) {
								allDashItems.push(
									Object.assign(dashIt, {
										active: false,
									}),
								)
							} else {
								status = true
								allDashItems.push(
									Object.assign(dashIt, {
										active: true,
									}),
								)
							}
						} else {
							allDashItems.push(dashIt)
						}
					})

					if (
						!!hasAlteration &&
						!!status &&
						it.idDashSet === dashSet.idDashSet
					) {
						allDashSets.push(
							Object.assign(it, {
								dashItems: allDashItems,
								active: status,
							}),
						)
					} else if (!!hasAlteration && !status) {
						allDashSets.push(
							Object.assign(it, {
								dashItems: allDashItems,
							}),
						)
					} else {
						allDashSets.push(it)
					}
				})

				if (!!status) {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
							status: status,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		setAlertAlteration(false)
		this.setState({
			plugins: newPlugins,
		})
	}

	toggleDrawer = (status, acess) => {
		const {
			setStatusModal,
			activeCompanyId,
			setPluginUser,
			setAlertAlteration,
			setLoaderMenu,
		} = this.props
		const { plugins } = this.state

		setPluginUser(null)

		if (!!acess) {
			takeAcess(
				plugins,
				{
					idUser: acess.id,
					activeCompanyId: activeCompanyId,
				},
				setPluginUser,
			)
		}

		if (!!status) {
			this.setState({
				actions: acess.actions,
				notification: acess.notification,
				log: acess.log,
				download: acess.download,
				upload: acess.upload,
				name: acess.name,
				email: acess.email,
				status: acess.status,
				idSelectedUser: acess.id,
				loginType: acess.loginType,
			})
		}

		setLoaderMenu(true)
		setStatusModal(status)
		setAlertAlteration(false)
	}

	alterStatusVisualization = type => {
		const { setAlertAlteration } = this.props
		const { actions, notification, log, download, upload, status } =
			this.state

		switch (type) {
			case 'actions':
				this.setState({
					actions: !actions,
				})
				break
			case 'notification':
				this.setState({
					notification: !notification,
				})
				break
			case 'log':
				this.setState({
					log: !log,
				})
				break
			case 'download':
				this.setState({
					download: !download,
				})
				break
			case 'upload':
				this.setState({
					upload: !upload,
				})
				break
			case 'status':
				this.setState({
					status: !status,
				})
				break
			default:
				break
		}

		setAlertAlteration(false)
	}

	changeName = e => {
		const { setAlertAlteration } = this.props
		this.setState({
			name: e.target.value,
			errorName: false,
		})

		setAlertAlteration(false)
	}

	changeEmail = e => {
		this.setState({
			email: e.target.value,
		})
	}

	handleCloseModal = () => {
		this.setState({
			modalUpdade: false,
		})
	}

	handleOpenModal = () => {
		this.setState({
			modalUpdade: true,
		})
	}

	render() {
		const { classes, userFilter, modal, isLoading } = this.props
		const {
			page,
			rowsPerPage,
			columns,
			openModal,
			errorName,
			modalUpdade,
			modalResetPass,
		} = this.state

		return (
			<Card className={classes.containerTable}>
				{!!isLoading ? (
					<Loader internal />
				) : (
					<Paper className={classes.root}>
						<Card className={classes.container}>
							<Paper
								component="form"
								className={classes.rootSearch}>
								<IconButton
									className={classes.iconButton}
									aria-label="search">
									<Search />
								</IconButton>
								<InputBase
									onChange={this.handleChange}
									className={classes.input}
									placeholder="Procurar usuário"
									inputProps={{
										'aria-label': 'Procurar usuário',
									}}
								/>
								<Button
									onClick={() => {
										window.location.href =
											'#/form-create-user'
									}}
									className={classes.buttonSearch}>
									<PersonAdd
										style={{ marginRight: '5px' }}
									/>
									Criar Usuário
								</Button>
							</Paper>
							<Paper className={classes.root}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{columns.map(column => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{
														minWidth:
															column.minWidth,
														padding: '5px',
													}}
													sortDirection={
														column.order
													}>
													<div
														style={{
															display: 'flex',
															justifyContent:
																'center',
															alignItems:
																'center',
														}}>
														<Typography>
															{column.label}
														</Typography>
														{column.order ===
														'asc' ? (
															<IconButton
																onClick={() =>
																	this.alterOrder(
																		column,
																	)
																}
																className={
																	classes.iconButton
																}
																aria-label="upIcon">
																<ArrowDropUp />
															</IconButton>
														) : (
															<IconButton
																onClick={() =>
																	this.alterOrder(
																		column,
																	)
																}
																className={
																	classes.iconButton
																}
																aria-label="downIcon">
																<ArrowDropDown />
															</IconButton>
														)}
													</div>
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<Drawer anchor={'right'} open={modal}>
										{this.list('right')}
									</Drawer>
									<Modal
										open={modalUpdade}
										aria-labelledby="simple-modal-title"
										aria-describedby="simple-modal-description"
										className={classes.modalUpdade}>
										<Card
											className={
												classes.itemsModalUpdate
											}>
											<Typography>
												Usuário atualizado
											</Typography>
										</Card>
									</Modal>
									<Modal
										open={modalResetPass}
										aria-labelledby="simple-modal-title"
										aria-describedby="simple-modal-description"
										className={classes.modalUpdade}>
										<Card
											className={
												classes.itemsModalUpdate
											}>
											<Typography>
												Email para reset de senha
												enviado!!
											</Typography>
										</Card>
									</Modal>
									<TableBody>
										{userFilter
											.slice(
												page * rowsPerPage,
												page * rowsPerPage +
													rowsPerPage,
											)
											.map((row, index) => {
												return (
													<TableRow
														hover
														role="checkbox"
														tabIndex={-1}
														key={row.code}>
														{columns.map(column => {
															const value =
																row[column.id]
															return (
																<TableCell
																	key={
																		column.id
																	}
																	align={
																		column.align
																	}>
																	{column.id ===
																	'loginType' ? (
																		<TextField
																			disabled
																			id="standard-disabled"
																			value={
																				value
																			}
																		/>
																	) : column.id ===
																	  'name' ? (
																		<div>
																			<div>
																				<IconButton
																					onClick={() =>
																						this.toggleDrawer(
																							true,
																							row,
																						)
																					}
																					aria-label="downIcon">
																					<Card
																						className={
																							classes.buttonModal
																						}>
																						<Edit
																							className={
																								classes.iconButtonModal
																							}
																						/>
																						<Typography
																							style={{
																								color: 'white',
																							}}>
																							Editar
																						</Typography>
																					</Card>
																				</IconButton>
																				{
																					value
																				}
																			</div>
																			<div>
																				{!!openModal &&
																					openModal.index ===
																						index &&
																					openModal.type ===
																						column.id && (
																						<div>
																							<TextField
																								id="standard-required"
																								label={
																									'Novo nome: '
																								}
																								defaultValue={
																									value
																								}
																								onChange={
																									this
																										.handleChangeName
																								}
																							/>
																							<IconButton
																								onClick={() =>
																									this.validateName(
																										row,
																										column.id,
																									)
																								}
																								aria-label="edit-button">
																								<Save
																									style={{
																										paddingBottom:
																											'5px',
																									}}
																								/>
																							</IconButton>
																							{!!errorName ? (
																								<Typography
																									className={
																										classes.alert
																									}
																									variant="caption"
																									display="block"
																									gutterBottom>
																									O
																									campo
																									'nome'
																									deve
																									ter
																									ente
																									2
																									e
																									25
																									caracteres
																								</Typography>
																							) : (
																								<div />
																							)}
																						</div>
																					)}
																			</div>
																		</div>
																	) : column.id ===
																	  'status' ? (
																		<div>
																			<IconButton
																				onClick={() =>
																					this.editUserInFirebase(
																						row,
																						column.id,
																					)
																				}
																				aria-label="edit-button">
																				{!!value ? (
																					<Visibility
																						style={{
																							color: '#00FF80',
																						}}
																					/>
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</div>
																	) : column.id ===
																	  'actions' ? (
																		<div>
																			<IconButton
																				onClick={() =>
																					this.editUserInFirebase(
																						row,
																						column.id,
																					)
																				}
																				aria-label="edit-button">
																				{!!value ? (
																					<Visibility
																						style={{
																							color: '#00FF80',
																						}}
																					/>
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</div>
																	) : column.id ===
																	  'notification' ? (
																		<div>
																			<IconButton
																				onClick={() =>
																					this.editUserInFirebase(
																						row,
																						column.id,
																					)
																				}
																				aria-label="edit-button">
																				{!!value ? (
																					<Visibility
																						style={{
																							color: '#00FF80',
																						}}
																					/>
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</div>
																	) : column.id ===
																	  'log' ? (
																		<div>
																			<IconButton
																				onClick={() =>
																					this.editUserInFirebase(
																						row,
																						column.id,
																					)
																				}
																				aria-label="edit-button">
																				{!!value ? (
																					<Visibility
																						style={{
																							color: '#00FF80',
																						}}
																					/>
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</div>
																	) : column.id ===
																	  'download' ? (
																		<div>
																			<IconButton
																				onClick={() =>
																					this.editUserInFirebase(
																						row,
																						column.id,
																					)
																				}
																				aria-label="edit-button">
																				{!!value ? (
																					<Visibility
																						style={{
																							color: '#00FF80',
																						}}
																					/>
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</div>
																	) : column.id ===
																	  'upload' ? (
																		<div>
																			<IconButton
																				onClick={() =>
																					this.editUserInFirebase(
																						row,
																						column.id,
																					)
																				}
																				aria-label="edit-button">
																				{!!value ? (
																					<Visibility
																						style={{
																							color: '#00FF80',
																						}}
																					/>
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</div>
																	) : (
																		<div
																			style={{
																				display:
																					'flex',
																				justifyContent:
																					'center',
																			}}>
																			<div>
																				{
																					value
																				}
																			</div>
																			<div>
																				{!!openModal &&
																					openModal.index ===
																						index &&
																					openModal.type ===
																						column.id && (
																						<div>
																							<TextField
																								id="standard-required"
																								label={
																									'Novo email: '
																								}
																								defaultValue={
																									value
																								}
																								onChange={
																									this
																										.handleChangeUpData
																								}
																							/>
																							<IconButton
																								onClick={() =>
																									this.editUserInFirebase(
																										row,
																										column.id,
																									)
																								}
																								aria-label="edit-button">
																								<Save
																									style={{
																										paddingBottom:
																											'5px',
																									}}
																								/>
																							</IconButton>
																						</div>
																					)}
																			</div>
																		</div>
																	)}
																</TableCell>
															)
														})}
													</TableRow>
												)
											})}
									</TableBody>
								</Table>
							</Paper>
						</Card>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={userFilter.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={this.handleChangePage}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
					</Paper>
				)}
			</Card>
		)
	}
}

const mapStateToProps = state => ({
	usersProps: state.createUserState.usersProps,
	userFilter: state.createUserState.userFilter,
	wordSearch: state.createUserState.wordSearch,
	modal: state.createUserState.modal,
	emailValidation: state.createUserState.emailValidation,
	pluginUser: state.createUserState.pluginUser,
	alertAlteration: state.createUserState.alertAlteration,
	orderPermission: state.createUserState.orderPermission,
	componentsPermission: state.createUserState.componentsPermission,
	isLoading: state.createUserState.isLoading,
	isLoadingMenu: state.createUserState.isLoadingMenu,
})

const mapDispatchToProps = dispatch => ({
	setUsersInReducer: usersProps =>
		dispatch({ type: 'SET_USERS_IN_REDUCER', usersProps }),
	setUserReducer: userFilter =>
		dispatch({ type: 'SET_USER_FILTER_REDUCER', userFilter }),
	setWordSearch: wordSearch =>
		dispatch({ type: 'SET_WORD_SEARCH', wordSearch }),
	setStatusModal: modal => dispatch({ type: 'SET_STATUS_MODAL', modal }),
	setPluginUser: pluginUser =>
		dispatch({ type: 'SET_PLUGIN_USER', pluginUser }),
	setAlertAlteration: alertAlteration =>
		dispatch({ type: 'SET_ALERT_ALTERATION', alertAlteration }),
	setPermissionOrder: orderPermission =>
		dispatch({ type: 'SET_PERMISSION_ORDER', orderPermission }),
	setComponentsPermissions: componentsPermission =>
		dispatch({ type: 'SET_COMPONENTS_PERMISSIONS', componentsPermission }),
	setLoader: isLoading => dispatch({ type: 'SET_LOADER', isLoading }),
	setLoaderMenu: isLoadingMenu =>
		dispatch({ type: 'SET_LOADER_MENU', isLoadingMenu }),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
)(UsersCompany)
