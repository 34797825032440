import PropTypes from 'prop-types'

/**
 * Defaultizes system charts based on CommonChart component
 *
 * @description Commonised propTypes for more modular reusability
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const CommonChartPropTypes = {
	autoWidth: PropTypes.bool,
	data: PropTypes.object.isRequired,
	options: PropTypes.object.isRequired,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
