import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import appConfigReducer from './appConfig'
import clientReducer from './client'
import dashSetReducer from './dashSet'
import dashItemReducer from './dashItem'
import snackbarReducer from './snackbar'
import processReducer from './process'
import sessionReducer from './session'
import userReducer from './user'
import serviceOrderReducer from './serviceOrder'
import signInFormReducer from './signInForm'
import dateReducer from './dateConfig'
import workBookReducer from './workBook'

// Filter state-layer management
import filterModalReducer from './filter/modal'

// General from app
import listPaginationReducer from './list/pagination'
import buttonSwitchReducer from './button/switch'

// Company context
import companyReducer from './company'
import companyFormReducer from './company/form'
import companyFiltersReducer from './company/filter/index'
import companyListReducer from './company/list'
import companyUserReducer from './company/user'
import companyPluginReducer from './company/plugin'
import companyDashItemReducer from './company/dashItem'
import companyDashItemStepReducer from './company/dashItem/step'

// Filters reducer
import globalFiltersReducer from './filter/global'
import globalFiltersSearchReducer from './filter/search'
import globalFiltersDataReducer from './filter/data'

// Filter-bar reducer
import filterBarReducer from './filter/filterbar'

// actions
import actionFilterReducer from './action/filter'
import actionFormReducer from './action/form'
import actionsReducer from './action/index'
import groupReducer from './group'
import groupUserReducer from './group/user'
import cardReducer from './card'
import actionsReducerByAuthor from './action/author/index'

// Form state managers
import userFormReducer from './user/form'
import userEditFormReducer from './user/form/editForm'
import userConfigurationFormReducer from './user/configuration/form'
import groupFormReducer from './group/form'
import groupUserFormReducer from './group/user/form'
import pluginFormReducer from './plugin/form'
import teamDetailReducer from './team/detail/page'
import groupPermissionReducer from './group/permission'
import groupPermissionFormReducer from './group/permission/form'
import companyFilterFormReducer from './company/filter/form'
import groupFormEditReducer from './group/edit/form'
import dashItemFormReducer from './dashItem/form'

// Navigation state managers
import notificationNavigationReducer from './notifications/navigation/index'
import notificationReducer from './user/notification'
import responsesReducer from './notifications/response/index'

// Permission context
import permissionFormReducer from './permission/form'
import defaultPermissionReducer from './permission'
import companyPermissionReducer from './company/permission'
import permissionFilterReducer from './permission/filter'
import permissionFiltersFormReducer from './permission/filter/form'

// Productivity context
import productivityLossReducer from './productivity/loss'
import productivityReducer from './productivity'
import productivityCycleReducer from './productivity/cycle'

// Contract
import contractReducer from './contract'
import controlPanelUserReducer from './controlPanelUserReducer'

// Plugin context
import pluginReducer from './plugin'
import dashBoardRedux from './dashBoard'
import pluginListReducer from './plugin/list'
import pluginTypeReducer from './plugin/type'

// Teams context
import teamListReducer from './team/list'
import teamListFilterReducer from './team/list/status'

// Operational performance context
import operationalPerformanceReducer from './performance'

// Users context
import userListReducer from './user/list'
import userCompanyReducer from './user/company'
import { forgotPasswordReducer } from './user/account'

// dialogs and system messages
import dialogReducer from './dialog'
import dialogNotificationReducer from './dialog/notification'

// work context reducers
import { workDetailReducer, workListReducer } from './work'

// appAudit context reducers
import rankingReducer from './ranking'
import rankingListReducer from './ranking/list'
import rankingContentReducer from './ranking/content'

// evaluation context
import { evaluationUserActionsReducer, evaluationDialogReducer, evaluationPopupReducer, evaluationReasonsReducer } from './systemEvaluation'
import outlayReducer from './outlay'
import auditedObjectsReducer from './auditedObject'

// Form state management reducer
import formStateReducer from './form'
import dashItemStepReducer from './dashItem/step'
import performedAuditsReducer from './performedAudits'
import trendForecastReducer from './trendForecast'
import checklistCompanyReducer from './checklistCompany'
import checkByLocationReducer from './checkByLocation'
import checkByLocationCovidReducer from './checkByLocationCovid'
import performedCovidReducer from './performedCovid'
import timeDownNcReducer from './timeDownNc'
import painelChecklistReducer from './painelChecklist'
import panelChecklistReducer from './panelChecklist'
import performedAuditCovidReducer from './performedCovid'

// selectable form state reducer
import selectableDataSetReducer from './selectableDataset'

// action plan state management reducer
import actionPlanReducer from './actionPlan'
import actionPlanCovidReducer from './actionPlanCovid'

// outdated non compliance
import outdatedNonComplianceReducer from './outdatedNonCompliance'

// graph management state reducer
import graphContainerReducer from './graphContainer'

// nc audits state reducer
import ncAuditsReducer from './ncAudits'

// object nc state reducer
import objectNcReducer from './objectNc'

// Avaliations my account
import avaliationsReducer from './myAccount'

//
import safetyRatiosReducer from './safety/ratio'
import dashItemViewReducer from './dashItem/view'
import bottomNavigationReducer from './bottomNavigation'
import checkListsReducer from './performedChecklists'
import contributorListReducer from './contributorList'
import collaboratorNcReducer from './collaboratorNc'

import accountConfig from './accountConfig'
import rankingAuditsReducer from './rankingAudits'
import rankingNcClientReducer from './rankingNcClient'
import ncStageReducer from './ncStage'
import rankingQuestionReducer from './rankingQuestion'
import rankingChecklistReducer from './rankingChecklist'
import followUpReducer from './followUp'
import questionTrakingReducer from './questionTraking'
import evoluterNcReducer from './evoluterNc'
import performanceEvoluterReducer from './performanceEvoluter'
import topNcRankingReducer from './topNcRanking'
import foreseenChecklistReducer from './foreseenChecklist'
import autocheckReducer from './autocheck'
import podiumRankingAuditsReducer from './podiumRankingAudits'
import mapAuditReducer from './mapAudit'
import mapCovidReducer from './mapCovid'
import trainingReducer from './invalidTraining'
import wireChargeReducer from './wireCharge'
import historyWireChargeReducer from './historyWireCharge'
import historyOwnLoadReducer from './historyOwnLoad'
import levelEquipmentReducer from './levelEquipment'
import minTemperatureReducer from './minTemperature'
import medTemperatureReducer from './medTemperature'
import maxTemperatureReducer from './maxTemperature'
import historyDailyLoadWireChargeReducer from './historyDailyLoadWireCharge'
import historyDailyLoadOwnLoadReducer from './historyDailyLoadOwnLoad'
import energyConsumptionReducer from './energyConsumption'
import ownLoadReducer from './ownLoad'
import monitoringReducer from './monitoring'
import newMonitoringReducer from './newMonitoring'
import newCollaboratorReducer from './collaborators'
import solarPowerReducer from './solarPower'
import responsibleNcReducer from './responsibleNc'
import resultCheckListReducer from './resultCheckList'
import resultChecklistPlanReducer from './resultChecklistPlan'
import measurementReducer from './measurement'
import goalTrackingReducer from './goalTracking'
import accumulatedVolumeReducer from './accumulatedVolume'
import productivityClientsReducer from './productivityClients'
import onboardingReducer from './onboarding'
import measurerReadsReducer from './read'
import consumptionHistoryReducer from './consumption'
import consumptionDataReducer from './consumption/consumption'
import portalReducer from './portal'
import portalDownloadReducer from './portalDownload'
import portalDevelopReducer from './portalDevelop'
// import { DINAMIC_REDUCERS } from './utils'
import lightAuditReducer from './lightCard'
import lightAuditMonitoringReducer from './lightCardMonitoring'
import closeReportReducer from './closeReport'
import accountReducer from './account'
import createUserReducer from './createUsers'
import logReducer from './logs'
import { logHandlerReducer } from './logHandler'
import blockVoltageReducer from './blockVoltage'
import cardifpReducer from './cardifp'
import cardivReducer from './cardiv'
import cardpqReducer from './cardpq'

const reducers = {
	actionFilterState: actionFilterReducer,
	actionFormState: actionFormReducer,
	actionPlanState: actionPlanReducer,
	actionPlanCovidState: actionPlanCovidReducer,
	actionsState: actionsReducer,
	actionsStateByAuthor: actionsReducerByAuthor,
	autocheckState: autocheckReducer,
	appConfigState: appConfigReducer,
	auditedObjectState: auditedObjectsReducer,
	bottomNavigationState: bottomNavigationReducer,
	buttonSwitchState: buttonSwitchReducer,
	blockVoltageState: blockVoltageReducer,
	cardState: cardReducer,
	cardifpState: cardifpReducer,
	cardivState: cardivReducer,
	cardpqState: cardpqReducer,
	clientState: clientReducer,
	companyDashItemState: companyDashItemReducer,
	companyDashItemStepState: companyDashItemStepReducer,
	companyFilterFormState: companyFilterFormReducer,
	companyFilterState: companyFiltersReducer,
	companyFormState: companyFormReducer,
	companyListState: companyListReducer,
	companyPermissionState: companyPermissionReducer,
	companyPluginState: companyPluginReducer,
	companyState: companyReducer,
	companyUserState: companyUserReducer,
	contractState: contractReducer,
	controlPanelUserState: controlPanelUserReducer,
	productivityCycleState: productivityCycleReducer,
	dashBoardState: dashBoardRedux,
	dashItemFormState: dashItemFormReducer,
	dashItemState: dashItemReducer,
	dashItemStepState: dashItemStepReducer,
	dashItemViewState: dashItemViewReducer,
	dashSetState: dashSetReducer,
	dateConfigState: dateReducer,
	defaultPermissionState: defaultPermissionReducer,
	dialogAlertState: dialogReducer,
	dialogNotificationState: dialogNotificationReducer,
	evaluationDialogState: evaluationDialogReducer,
	evaluationUserActionsState: evaluationUserActionsReducer,
	evaluationPopupState: evaluationPopupReducer,
	evaluationReasonsState: evaluationReasonsReducer,
	evoluterNcState: evoluterNcReducer,
	filterBarState: filterBarReducer,
	filterModalState: filterModalReducer,
	followUpState: followUpReducer,
	forgotPasswordState: forgotPasswordReducer,
	formState: formStateReducer,
	globalFiltersDataState: globalFiltersDataReducer,
	globalFiltersSearchState: globalFiltersSearchReducer,
	globalFiltersState: globalFiltersReducer,
	groupEditFormState: groupFormEditReducer,
	graphState: graphContainerReducer,
	groupFormState: groupFormReducer,
	groupPermissionFormState: groupPermissionFormReducer,
	groupPermissionState: groupPermissionReducer,
	groupState: groupReducer,
	groupUserFormState: groupUserFormReducer,
	groupUserState: groupUserReducer,
	listPaginationState: listPaginationReducer,
	ncAudits: ncAuditsReducer,
	notificationNavigationState: notificationNavigationReducer,
	notificationState: notificationReducer,
	objectNcState: objectNcReducer,
	operationalPerformanceState: operationalPerformanceReducer,
	outdatedNCState: outdatedNonComplianceReducer,
	outlayState: outlayReducer,
	performedAuditsState: performedAuditsReducer,
	trendForecastState: trendForecastReducer,
	checklistCompanyState: checklistCompanyReducer,
	checkByLocationState: checkByLocationReducer,
	checkByLocationCovidState: checkByLocationCovidReducer,
	performedCovidState: performedCovidReducer,
	timeDownNcState: timeDownNcReducer,
	painelChecklistState: painelChecklistReducer,
	panelChecklistState: panelChecklistReducer,
	performedAuditCovidState: performedAuditCovidReducer,
	rankingAuditsState: rankingAuditsReducer,
	rankingNcClientState: rankingNcClientReducer,
	ncStageState: ncStageReducer,
	rankingQuestionState: rankingQuestionReducer,
	rankingChecklistState: rankingChecklistReducer,
	topNcRankingState: topNcRankingReducer,
	foreseenChecklistState: foreseenChecklistReducer,
	mapAuditState: mapAuditReducer,
	mapCovidState: mapCovidReducer,
	trainingState: trainingReducer,
	productivityClientsState: productivityClientsReducer,
	wireChargeState: wireChargeReducer,
	accumulatedVolumeState: accumulatedVolumeReducer,
	historyWireChargeState: historyWireChargeReducer,
	historyOwnLoadState: historyOwnLoadReducer,
	levelEquipmentState: levelEquipmentReducer,
	minTemperatureState: minTemperatureReducer,
	medTemperatureState: medTemperatureReducer,
	maxTemperatureState: maxTemperatureReducer,
	historyDailyLoadWireChargeState: historyDailyLoadWireChargeReducer,
	historyDailyLoadOwnLoadState: historyDailyLoadOwnLoadReducer,
	energyConsumptionState: energyConsumptionReducer,
	ownLoadState: ownLoadReducer,
	contributorListState: contributorListReducer,
	collaboratorNcState: collaboratorNcReducer,
	monitoringState: monitoringReducer,
	newMonitoringState: newMonitoringReducer,
	newCollaboratorState: newCollaboratorReducer,
	solarPowerState: solarPowerReducer,
	responsibleNcState: responsibleNcReducer,
	resultCheckListState: resultCheckListReducer,
	resultChecklistPlanState: resultChecklistPlanReducer,
	measurementState: measurementReducer,
	goalTrackingState: goalTrackingReducer,
	podiumRankingAuditsState: podiumRankingAuditsReducer,
	performanceEvoluterState: performanceEvoluterReducer,
	permissionFilterFormState: permissionFiltersFormReducer,
	permissionFilterState: permissionFilterReducer,
	permissionFormState: permissionFormReducer,
	pluginFormState: pluginFormReducer,
	pluginListState: pluginListReducer,
	pluginState: pluginReducer,
	pluginTypeState: pluginTypeReducer,
	processState: processReducer,
	productivityLossState: productivityLossReducer,
	productivityState: productivityReducer,
	questionTrakingState: questionTrakingReducer,
	rankingContentState: rankingContentReducer,
	rankingListState: rankingListReducer,
	rankingState: rankingReducer,
	responsesState: responsesReducer,
	safetyRatiosState: safetyRatiosReducer,
	selectableDataSetState: selectableDataSetReducer,
	serviceOrderState: serviceOrderReducer,
	sessionState: sessionReducer,
	signInFormState: signInFormReducer,
	snackbarState: snackbarReducer,
	teamDetailState: teamDetailReducer,
	teamListState: teamListReducer,
	teamListFilterState: teamListFilterReducer,
	userCompanyState: userCompanyReducer,
	userConfigurationFormState: userConfigurationFormReducer,
	userEditFormState: userEditFormReducer,
	userFormState: userFormReducer,
	userListState: userListReducer,
	userState: userReducer,
	workBookState: workBookReducer,
	workDetailState: workDetailReducer,
	workListState: workListReducer,
	checkListState: checkListsReducer,
	accountConfigState: accountConfig,
	avaliationsMyAccountState: avaliationsReducer,
	onboardingState: onboardingReducer,
	measurerReadsState: measurerReadsReducer,
	consumptionHistoryState: consumptionHistoryReducer,
	consumptionDataState: consumptionDataReducer,
	portalState: portalReducer,
	portalDownloadState: portalDownloadReducer,
	portalDevelopState: portalDevelopReducer,
	lightState: lightAuditReducer,
	lightStateMonitoring: lightAuditMonitoringReducer,
	closeReportState: closeReportReducer,
	currentUserState: accountReducer,
	createUserState: createUserReducer,
	logsState: logReducer,
	logHandler: logHandlerReducer,
}
const appReducer = combineReducers({
	...reducers,
})
const rootReducer = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		storage.removeItem('persist:root')
		state = undefined
	}

	return appReducer(state, action)
}
export default rootReducer
