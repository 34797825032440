const SET_RANKING_DATE_CONFIG = 'SET_RANKING_DATE_CONFIG',
	PREPARE_RANKING_REQUEST = 'PREPARE_RANKING_REQUEST',
	RECEIVE_RANKING_ITEMS = 'RECEIVE_RANKING_ITEMS',
	SET_RANKING_LIST_LOADING_STATE = 'SET_RANKING_LIST_LOADING_STATE'

const INITIAL_STATE = {
	items: null,
	isLoading: null,
	dateConfig: {}
}

const rankingListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RECEIVE_RANKING_ITEMS: {
			return Object.assign({}, state, {
				items: action.items,
				isLoading: false
			})
		}
		case SET_RANKING_DATE_CONFIG: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				}
			})
		}
		case SET_RANKING_LIST_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
				...(!!action.isLoading ? { items: null } : null)
			})
		}
		default:
			return state
	}
}

export default rankingListReducer

export {
	SET_RANKING_DATE_CONFIG,
	PREPARE_RANKING_REQUEST,
	RECEIVE_RANKING_ITEMS,
	SET_RANKING_LIST_LOADING_STATE
}
