import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { Button, Icon, withStyles, Tooltip } from '@material-ui/core'
import styles from './styles'
import { makeGetBackRoute } from '../../../../selectors/step'
import { translate } from '../../../../utils/lang'
import { deviceIsIos } from '../../../../utils/env'

/**
 *
 *
 * @description Created the component
 * @author lopes
 * @version 1.0
 */
class ReturnButton extends Component {
	state = {
		ios: false
	}
	componentDidMount() {
		const ios = deviceIsIos()
		this.setState({ ios })
	}

	returnPage = () => {
		const { history } = this.props

		history.goBack()
	}

	render() {
		return this.state.ios ? (
			<Button onClick={this.returnPage}>
				<Tooltip title={translate('label/return')}>
					<Icon>keyboard_arrow_left</Icon>
				</Tooltip>
			</Button>
		) : null
	}
}

const makeStateToProps = () => {
	// const getStepManager = makeStepManager()
	const getBackRoute = makeGetBackRoute()
	const mapStateToProps = (state, ownProps) => {
		return {
			route: getBackRoute(state, ownProps)
		}
	}

	return mapStateToProps
}

export default compose(
	withRouter,
	connect(makeStateToProps()),
	withStyles(styles)
)(ReturnButton)
