import { generateRandom } from '../../string'

/**
 * Returns an { active: true } object.
 * You can determine an object is inactive by falsing the ${active} param
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {boolean} active default is true. Sets whether the object is enabled or not
 */
const generateActive = (active = true) => {
	return { active: active }
}

/**
 * Generated a random-8-char-length-ID, based on generateRandom (from utils/string)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const generateId = () => {
	return generateRandom(8)
}

export { generateActive, generateId }
