import { fs } from '../firebase'

/**
 * [Firestore] Gets a single dashSet based on an ID
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} dashSetId The dashset to be queried
 * @param {Function} thenFunc [Optional] The success-function
 */
export const get = (dashSetId) => {
	let ref = fs.collection('dashSets').doc(dashSetId)

	// return db.ref(`dashSets/${dashSetId}`).on('value', thenFunc)
	return ref.get()
	//return thenFunc == null ? ref.get() : ref.get().then(thenFunc)
}
