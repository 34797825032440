import { ON_NOTIFICATION, CLEAR_NOTIFICATION } from '../../actions/snackbar'

const INITIAL_STATE = {
	hasNotification: false,
	notification: null
}

/**
 * Will reset the snackbar state layer
 *
 * @param {Object} state The current app state
 */
const snackbarCleared = (state) => ({
	...state,
	...INITIAL_STATE
})

/**
 * Will set the snackbar state layer to the action passed
 *
 * @param {Object} state The current app state
 * @param {Object} action The current action with the "notification" prop
 */
const snackbarThrown = (state, action) => ({
	...state,
	notification: action.notification,
	hasNotification: true
})

/**
 * Manages the state of the bottom snackbar of the system
 *
 * @param {Object} state The current app state
 * @param {Object} action The action object with "type" prop and the desired data
 */
function snackbarReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ON_NOTIFICATION: {
			return snackbarThrown(state, action)
		}
		case CLEAR_NOTIFICATION: {
			return snackbarCleared(state)
		}
		default:
			return state
	}
}

export default snackbarReducer
