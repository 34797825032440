/**
 * TODO: FIXME: This is provisory!
 * Filters fetched from external data src are declared in this file.
 * The keys are used for filter user data.
 */

export const ALL = 'all'
export const CLIENTS = 'clients'
export const BASES = 'bases'
export const LOCALITIES = 'localities'
export const PROCESSES = 'processes'
export const SUPERVISORS = 'supervisors'
export const ORIGINS = 'origins'

// List of declared filters
export const ALL_FILTERS = [
	CLIENTS,
	BASES,
	LOCALITIES,
	PROCESSES,
	SUPERVISORS,
	ORIGINS
]
