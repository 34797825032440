const styles = (theme) => ({
    ComponentsForm: {
        display: 'flex'  
    },
	labelStyle: {
		color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 'smaller',
        display: 'flex'
	},
	pStyle: {
		marginBottom: '10px',
        marginTop: '5px',
        display: 'flex'
	},
	container: {
		maxHeight: 'calc(98vh - 48px)',
        overflowY: 'auto',
        padding: '8px'
	},
	containerIos: {
		// marginTop: 'env(safe-area-inset-top)',
		// marginBottom: 'env(safe-area-inset-bottom)',
		// marginLeft: 'env(safe-area-inset-left)',
		// marginRight: 'env(safe-area-inset-right)'
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column'
	},
	actions: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: `${theme.spacing.unit / 2}px 0`,
		'& button': {
			margin: `0 ${theme.spacing.unit / 4}px`
		}
	},
	typography: {
		margin: theme.spacing.unit
	}
})

export default styles
