export const PLUGIN_LIST_LOADED = 'PLUGIN_LIST_LOADED',
	CLEAR_LOADED_PLUGIN_LIST = 'CLEAR_LOADED_PLUGIN_LIST',
	PLUGIN_LIST_LOADING = 'PLUGIN_LIST_LOADING'

/**
 * [action-creator] Dispatches that plugins **are now loading**, to the state tree
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const setPluginListLoading = () => ({
	type: PLUGIN_LIST_LOADING
})

/**
 * [action-creator] Dispatches a set of plugins, as loaded to the state tree
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const clearPluginList = () => ({
	type: CLEAR_LOADED_PLUGIN_LIST
})

/**
 * [action-creator] Dispatches a set of plugins, as loaded to the state tree
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} plugins The plugin-set to be defined at state tree
 */
export const setPluginList = (plugins) => ({
	type: PLUGIN_LIST_LOADED,
	plugins
})
