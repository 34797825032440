const INITIAL_STATE = {
	isLoading: null,
	data: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		instalation: '',
		segment: '',
		report: '',
		priority: '',
		apointment: '',
		// date: '',
		email: '',
		finished: '',
		typeOs: '',
		result: '',
		detail: '',		
	},
	tracks: [],
	inspection: '',
	closeInspection: false,
	selectorFilters: '',
	showModalForm: false,
	openForm: false,
	atualizaPage: false
}
/**
 * ranking audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */

const lightAuditReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_OPEN_FORM': {
			return Object.assign({}, state, {
				openForm: action.openForm
			})
		}
		case 'SET_SHOW_MODAL_FORM': {
			return Object.assign({}, state, {
				showModalForm: action.showModalForm
			})
		}
		case 'SET_IS_ACTION': {
			return Object.assign({}, state, {
				isAction: action.isAction
			})
		}
		case 'SET_CLOSE_INSPECTION_AUDIT': {
			return Object.assign({}, state, {
				closeInspection: action.closeInspection
			})
		}
		case 'SET_ATUALIZA_PAGE': {
			return Object.assign({}, state, {
				atualizaPage: action.atualizaPage
			})
		}
		case 'SET_INSPECTION_AUDIT': {
			return Object.assign({}, state, {
				inspection: action.inspection
			})
		}
		case 'MANAGE_LIGHT_AUDIT_LOADING_STATE': {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case 'SET_FILTERS_SELECTOR': {
			return Object.assign({}, state, {
				selectorFilters: action.selectorFilters
			})
		}
		case 'LOAD_LIGHT_AUDIT_DATA': {
			return Object.assign({}, state, {
				data: action.data,
				isLoading: false
			})
		}
		case 'SET_IS_LIGHT' : {
			return Object.assign({}, state, {isLight: action.value})
		}

		case 'LOAD_LIGHT_TRACK_AUDIT_DATA':
			// console.log(action.data)
			return Object.assign({}, state, { tracks: action.data })
		case 'SET_LIGHT_AUDIT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_LIGHT_AUDIT_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_LIGHT_AUDIT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case 'SET_LIGHT_AUDIT_PERIOD': {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default lightAuditReducer
