const styles = theme => ({
	root: {
		flexGrow: 1,
		paddingTop: '114px',
		paddingLeft: '10px',
		paddingRight: '10px',
		backgroundColor: 'rgb(220 222 232)',
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '10px',
	},
	btnSeeDetails: {
		'&:hover': {
			backgroundColor: '#9575cd',
			color: '#FFF',
		},
	},
	detailIcon: {
		marginRight: theme.spacing.unit,
	},
	parametro: {
		margin: '30px',
	},
})

export default styles
