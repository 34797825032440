export const MANAGE_RANKING_QUESTION_LOADING_STATE = 'MANAGE_RANKING_QUESTION_LOADING_STATE',
    LOAD_RANKING_QUESTION_DATA = 'LOAD_RANKING_QUESTION_DATA',
    SET_RANKING_QUESTION_PERIOD = 'SET_RANKING_QUESTION_PERIOD'

export const setRankingQuestionPeriod = (dateConfig, value, formType) => ({
    type: SET_RANKING_QUESTION_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadRankingQuestionData = (data) => ({
    type: LOAD_RANKING_QUESTION_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_RANKING_QUESTION_LOADING_STATE,
    isLoading
})
