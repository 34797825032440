import {
	MANAGE_MED_TEMPERATURE_LOADING_STATE,
	LOAD_MED_TEMPERATURE_DATA,
	SET_MED_TEMPERATURE_PERIOD
} from '../../actions/medTemperature'

const INITIAL_STATE = {
	isLoading: null,
	medTemperature: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {}
}
/**
 * medTemperature state reducer manager
 * @param {*} state
 * @param {*} action
 */

const medTemperatureReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_MED_TEMPERATURE_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_MED_TEMPERATURE_DATA: {
			return Object.assign({}, state, {
				medTemperature: action.medTemperature,
				isLoading: false
			})
		}
		case 'SET_MED_TEMPERATURE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_MED_TEMPERATURE_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_MED_TEMPERATURE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_MED_TEMPERATURE_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default medTemperatureReducer
