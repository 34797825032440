const styles = (theme) => ({
	root: {
		flexGrow: 1,
		width: '100%'
	},
	appBar: {
		top: 0,
		position: 'fixed',
		zIndex: 1100,
		//backgroundColor: 'transparent',
		background:
			'linear-gradient(to bottom, ' +
			theme.palette.primary.dark +
			', ' +
			theme.palette.primary.main +
			')',
		//color: 'rgba(0, 0, 0, 1.0)',
		color: 'rgba(255, 255, 255, 1.0)',
		boxShadow: 'unset'
	},
	appBarIos: {
		// paddingTop: 0,
		paddingTop: 'env(safe-area-inset-top)',
		// paddingLeft: 0,
		paddingLeft: 'env(safe-area-inset-left)',
		// paddingRight: 0,
		paddingRight: 'env(safe-area-inset-right)'
		// paddingBottom: 'env(safe-area-inset-bottom)',
		// paddingLeft: 'env(safe-area-inset-left)',
		// paddingRight: 'env(safe-area-inset-right)'
	},
	flex: {
		flex: 1,
		alignItems: 'flex-start'
	},
	leftButton: {
		marginLeft: -12,
		marginRight: 20
	},
	rightButton: {
		marginLeft: 5,
		marginRight: -12
	}
})

const leftBarIcon = (theme) => ({
	button: {
		marginLeft: -12,
		marginRight: 20
	}
})

const rightBarIcon = (theme) => ({
	button: {
		marginLeft: 5,
		marginRight: -12
	}
})

export { leftBarIcon, rightBarIcon }

export default styles
