export const MANAGE_CONSUMPTION_HISTORY_LOADING_STATE =
		'MANAGE_PERFORMED_AUDIT_LOADING_STATE',
	LOAD_CONSUMPTION_HISTORY_DATA = 'LOAD_CONSUMPTION_HISTORY_DATA',
	SET_CONSUMPTION_HISTORY_PERIOD = 'SET_CONSUMPTION_HISTORY_PERIOD'

export const setConsumptionHistoryPeriod = (
	dateConfig,
	value,
	formType,
	isLoading
) => ({
	type: SET_CONSUMPTION_HISTORY_PERIOD,
	dateConfig,
	value,
	formType,
	isLoading
})

export const loadConsumptionHistoryData = (consumptionHistory) => ({
	type: LOAD_CONSUMPTION_HISTORY_DATA,
	consumptionHistory
})

export const setLoading = (isLoading) => ({
	type: MANAGE_CONSUMPTION_HISTORY_LOADING_STATE,
	isLoading
})
