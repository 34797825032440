import { auth, fs } from '../../firebase'
import * as Author from '../../../utils/security/author'

/**
 * Get all company filters
 *
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Changed to verify whether it is active/inactive
 * @author brunoteixeirasilva
 * @version 1.1
 */
export const getAll = (companyId, includeInactive = false) => {
	let r = fs
		.collection('companies')
		.doc(companyId)
		.collection('filters')

	if (!includeInactive) {
		r.where('active', '==', true)
	}

	return r.get()
}

/**
 * Gets a single company-filter by its ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} activeCompanyId The current activeCompanyId ID
 * @param {string} filterId The current searching filter ID
 */
export const get = (activeCompanyId, filterId) => {
	return fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('filters')
		.doc(filterId)
		.get()
}

/**
 * Function to create Company Filter
 * @author Davi Wegner
 * @version 1.0
 */
export const doCreateFilter = (companyId, filterInfo) => {
	const filterObject = Object.assign(
		{},
		filterInfo,
		Author.generateObject(auth.currentUser.uid)
	)
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('filters')
		.doc()
	ref.set(filterObject)
	return ref.id
}

/**
 * Function to update Company Filter
 * @author Davi Wegner
 * @version 1.0
 */
export const doUpdate = (companyId, filterId, filterInfo) => {
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('filters')
		.doc(filterId)
	return ref.update(filterInfo)
}

/**
 * Get company keys for filters
 * @author Davi Wegner
 * @version 1.0
 */
export const getCompanyKeys = (companyId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('keys')
		.get()
}
/**
 * Get company key options for filters
 * @author Davi Wegner
 * @version 1.0
 */
export const getKeyOptions = (companyId, key) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('keys')
		.doc(key)
		.collection('options')
		.get()
}

// /**
//  * Get filter by Id
//  * @author Davi Wegner
//  * @version 1.0
//  */
// export const getFilter = (companyId, filterId) => {
// 	return fs
// 		.collection('companies')
// 		.doc(companyId)
// 		.collection('filters')
// 		.doc(filterId)
// 		.get()
// }
