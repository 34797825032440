import { translate } from '../../../utils/lang'

/**
 * Applies a user active company **loading-state** for the next state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "activeCompanyLoading" property with the next active company
 */
export const applySetUserActiveCompanyLoading = (state, action) =>
	Object.assign({}, state, {
		activeCompanyLoading: action.activeCompanyLoading
	})

/**
 * Sets a user active company for the next state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added new param initialState
 * @author davispindola
 * @version 1.1
 *
 * @description Changed naming conventions
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "activeCompanyId" property with the next active company
 */
export const applyUserActiveCompany = (state, action, initialState) => {
	if (undefined === action.activeCompanyId || !action.activeCompanyId) {
		throw new Error(
			translate('validation/property-is-required', {
				propertyName: 'activeCompanyId'
			})
		)
	}

	return Object.assign({}, state, {
		activeCompanyId: action.activeCompanyId,
		//requireCompanyActivation: initialState.requireCompanyActivation,
		activeCompanyLoading: initialState.activeCompanyLoading
	})
}

/**
 * Clears a user active company for the next state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added new param initialState
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state the current app state
 */
export const applyClearUserActiveCompany = (state, initialState) =>
	Object.assign({}, state, {
		activeCompanyId: initialState['activeCompanyId']
	})

/**
 * Apply user info to the current state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "user" property with user data
 */
export const applySetUserCompanies = (state, action) =>
	Object.assign({}, state, { companies: action.companies })

/**
 * Apply reset state by prop
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0.0
 *
 * @param {Object} state the current app state
 * @param {String} stateProp the current app state prop to reset
 * @param {Any} initialState the current app state prop initial state
 */
export const applySetInitialState = (state, stateProp, initialState) =>
	Object.assign({}, state, { [stateProp]: initialState })

/**
 * Will set the "requireCompanyActivation" flag
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and desired props
 */
export const applyRequireCompanyActivation = (state, action) =>
	Object.assign({}, state, {
		requireCompanyActivation: action.requireCompanyActivation
	})
