const styles = (theme) => ({
	horizontalList: {
		display: 'flex'
	},
	item: {
		flexDirection: 'column',
		padding: 0,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	icon: {
		marginRight: 0,
		color: '#D4AF37' // old gold
	},
	label: {
		padding: 0,
		'& > span': {
			fontSize: theme.spacing.unit + 3
		}
	}
})

export default styles
