export const MANAGE_OWN_LOAD_LOADING_STATE = 'MANAGE_OWN_LOAD_LOADING_STATE',
	LOAD_OWN_LOAD_DATA = 'LOAD_OWN_LOAD_DATA',
	SET_OWN_LOAD_PERIOD = 'SET_OWN_LOAD_PERIOD'

export const setOwnLoadPeriod = (dateConfig, value, formType) => ({
	type: SET_OWN_LOAD_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadOwnLoadData = (ownLoad) => ({
	type: LOAD_OWN_LOAD_DATA,
	ownLoad
})

export const setLoading = (isLoading) => ({
	type: MANAGE_OWN_LOAD_LOADING_STATE,
	isLoading
})
