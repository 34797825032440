import React from 'react'
import PropTypes from 'prop-types'
import PluginListItemDense from './dense/PluginListItemDense'
import PluginListItemExpanded from './expanded/PluginListItemExpanded'
import { withStyles, Paper, List } from '@material-ui/core'
/**
 * Will expose the list-item beautifully
 */
class PluginListFragment extends React.Component {
	render() {
		const { classes, plugins, handleConfig, viewAction, dense } = this.props

		return !!dense ? (
			<Paper>
				<List disablePadding className={classes.denseList}>
					{Object.keys(plugins).map(key => {
						return <PluginListItemDense key={key} item={{ [key]: plugins[key] }} handleConfig={handleConfig} />
					})}
				</List>
			</Paper>
		) : (
			<List className={classes.expandedList}>
				{Object.keys(plugins).map(key => {
					if (!!plugins[key].active)
						return (
							<PluginListItemExpanded
								key={key}
								pluginKey={key}
								item={{ [key]: plugins[key] }}
								{...plugins[key]}
								handleConfig={handleConfig}
								viewAction={viewAction}
							/>
						)
				})}
			</List>
		)
	}
}

const styles = theme => ({
	denseList: {
		width: '100%',
	},
	expandedList: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		paddingRight: '10px',
	},
})

PluginListFragment.propTypes = {
	dense: PropTypes.bool,
	plugins: PropTypes.object.isRequired,
	handleConfig: PropTypes.func.isRequired,
	viewAction: PropTypes.func.isRequired,
}

export default withStyles(styles)(PluginListFragment)
