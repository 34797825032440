import React, { Component } from 'react'
import ActionStatusOverview from './actionStats/ActionStatusOverview'
import ActionStatusCardOverview from './actionStats/ActionStatusCardOverview'
import classNames from 'classnames'
import { deviceIsIos } from '../../../utils/env/index'
import { Grid, withStyles } from '@material-ui/core'

/**
 * Action statistics container, the action stats related card must be placed here
 * @description Created the class component
 * @author Wegner
 * @version 1.0
 */
class ActionStatisticsContainer extends Component {
	render() {
		const { classes } = this.props
		return (
			<React.Fragment>
				<Grid
					className={classNames(classes.containerStatsCard, {
						[classes.containerIos]: deviceIsIos()
					})}
					spacing={16}
					container
				>
					<Grid item xs={12} lg={6}>
						<div className={classes.statsCard}>
							<ActionStatusOverview {...this.props} />
						</div>
					</Grid>
					<Grid item xs={12} lg={6}>
						<div className={classes.statsCard}>
							<ActionStatusCardOverview {...this.props} />
						</div>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

export default withStyles((theme) => ({
	containerStatsCard: {
		paddingBottom: theme.spacing.unit * 6
	},
	containerIos: {
		paddingBottom: theme.spacing.unit * 5
	},
	statsCard: {
		backgroundColor: 'rgb(255,255,255)',
		borderRadius: '15px',
		textAlign: 'center'
	}
}))(ActionStatisticsContainer)
