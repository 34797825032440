import React from 'react'
import { connect } from 'react-redux'

const withAuth = (Component) => {
	class WithAuth extends React.Component {
		render() {
			const { authUser, ...otherProps } = this.props
			return <Component isAuth={!!authUser} {...otherProps} />
		}
	}

	const mapStateToProps = (state) => ({
		authUser: state.sessionState.authUser
	})

	const mapDispatchToProps = (dispatch) => ({})
	return connect(mapStateToProps, mapDispatchToProps)(WithAuth)
}
export default withAuth
