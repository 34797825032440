import randomColor from 'randomcolor'

/**
 * Object which maps custom system-wide colours
 *
 * @description Created the object
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const colours = {
	disabled: 'rgb(191, 191, 191)'
}

/**
 * Generates an array of colours based on a scheme an randomcolor lib
 *
 * **More info: https://github.com/davidmerfield/randomColor**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {number} quantity The number of colours being generated
 * @param {string} luminosity The luminosity scheme to be used
 * @param {string} format The luminosity scheme to be used
 * @param {number} alpha The transparency/opacity to be applied on rgba color
 */
export const getColourSet = (
	quantity = 1,
	luminosity = 'light',
	format = 'rgba',
	alpha = 0.8
) =>
	randomColor({
		count: quantity,
		luminosity: luminosity,
		format: format,
		alpha: alpha
	})
