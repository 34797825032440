import {
    MANAGE_LEVEL_EQUIPMENT_LOADING_STATE,
    LOAD_LEVEL_EQUIPMENT_DATA,
    SET_LEVEL_EQUIPMENT_PERIOD
} from '../../actions/levelEquipment'

const INITIAL_STATE = {
	isLoading: null,
    localFilters: null,
	data: null,
	dateConfig: {},
	// ownFilters: {},
    // searchText: {},
    // idLocalFilter: {}
}

// const clearAllLocalFilters = (state) => {
//     let keys = Object.keys(state.idLocalFilter),
//         clearFilters = {}

//     keys.forEach((key) => {
//         clearFilters[key] = null
//     })

//     return clearFilters
// }

/**
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const levelEquipmentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_LEVEL_EQUIPMENT_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_LEVEL_EQUIPMENT_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case SET_LEVEL_EQUIPMENT_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        // case 'SET_LEVEL_EQUIPMENT_FILTER_VALUE': {
		// 	return Object.assign({}, state, {
		// 		ownFilters: Object.assign({}, state.ownFilters, action.value),
		// 		searchText: Object.assign({}, state.searchText, {
		// 			[action.key]: ''
		// 		})
		// 	})
		// }
		// case 'SET_LEVEL_EQUIPMENT_SEARCH_FILTER_VALUE': {
		// 	return Object.assign({}, state, {
		// 		searchText: Object.assign({}, state.searchText, action.value)
		// 	})
		// }
		// case 'CLEAN_LEVEL_EQUIPMENT_FILTER_VALUE': {
            
		// 	return Object.assign({}, state, {
		// 		ownFilters: {},
        //         // idLocalFilter: clearAllLocalFilters(state)
		// 	})
		// }
        // case 'SET_LOCAL_FILTER_LEVEL_EQUIPMENT': {
		// 	return Object.assign({}, state, {
		// 		localFilters: action.localFilters
		// 	})
		// }
        // case 'SET_SEARCH_TEXT_LEVEL_EQUIPMENT': {
        //     let searchText = {}
        //     action.objectKeys.forEach((item) => {
        //         searchText[item] = ''
        //     })
        //     return Object.assign({}, state, {
        //         searchText: searchText
        //     })
        // }
        // case 'SET_ID_LOCAL_FILTERS_LEVEL_EQUIPMENT': {
        //     return Object.assign({}, state, {
        //         idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter)
        //     })
        // }
        default:
            return state
    }
}

export default levelEquipmentReducer
