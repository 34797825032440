import React from 'react'
import { connect } from 'react-redux'
import {
	clearGlobalSearchText,
	setGlobalSearchText
} from '../../../actions/filter/global'
import { makeGetGlobalSearchText } from '../../../selectors/filter'

/**
 * Injects a ".searchText" and some search-text management methods to a Components props
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {React.Component|React.PureComponent} Component The component to have the props injected
 */
const withSearchText = (Component) => {
	class WithSearchText extends Component {
		render() {
			return <Component {...this.props} />
		}
	}

	const makeMapStateToProps = () => {
		const getSearchText = makeGetGlobalSearchText()

		const mapStateToProps = (state) => {
			return {
				searchText: getSearchText(state)
			}
		}

		return mapStateToProps
	}

	const mapDispatchToProps = (dispatch) => ({
		clearSearchText: () => dispatch(clearGlobalSearchText()),
		setSearchText: (text) => dispatch(setGlobalSearchText(text))
	})

	return connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)(WithSearchText)
}

export default withSearchText
