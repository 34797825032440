import sourceEngine from '../sourceEngine'

/**
 * @description Created the function
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 *
 * @param {Object} params The sourceEngine params to be passed on to the query
 * @param {string} dataSourceId The dataSource from which data-access config will be required
 * @param {string} dataSetId The dataSet from which data will be queried
 */
const defaultLocalFilterGet = (
	params = {},
	dataSourceId = 'appAudit',
	dataSetId = 'clients'
) => sourceEngine().postProvisory(dataSourceId, dataSetId, params)

export default defaultLocalFilterGet
