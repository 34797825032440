import {
	MANAGE_TREND_FORECAST_LOADING_STATE,
	LOAD_TREND_FORECAST_DATA,
	SET_TREND_FORECAST_PERIOD,
} from '../../actions/trendForecast'

const INITIAL_STATE = {
	isLoading: null,
	trendForecast: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		cliente: '',
		base: '',
		colaborador: '',
		objeto: '',
		tipo_objeto: '',
	},
	cardId: '',
}
/**
 * trend forecast state reducer manager
 * @param {*} state
 * @param {*} action
 */
const trendForecastReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_TREND_FORECAST_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case LOAD_TREND_FORECAST_DATA: {
			return Object.assign({}, state, {
				trendForecast: action.trendForecast,
				isLoading: false,
			})
		}

		case SET_TREND_FORECAST_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value,
						},
					),
				},
				isLoading: true,
			})
		}
		case 'SET_CARD_ID': {
			return Object.assign({}, state, {
				cardId: action.cardId,
			})
		}
		case 'SET_TREND_FORECAST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_TREND_FORECAST_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_TREND_FORECAST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		default:
			return state
	}
}

export default trendForecastReducer
