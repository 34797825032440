import {
	MANAGE_NEW_COLLABORATOR_LOADING_STATE,
	LOAD_NEW_COLLABORATOR_DATA,
	SET_NEW_COLLABORATOR_PERIOD,
} from '../../actions/collaborators'

const INITIAL_STATE = {
	isLoading: null,
	newCollaborator: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		tipoCliente: '',
		status: '',
		descricaoCargo: ''	
	},
}
/**
* collaborator noises state reducer manager
* @author Ray
* @version 1.0
* @param {*} state
* @param {*} action
*/

const newCollaboratorReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
			case MANAGE_NEW_COLLABORATOR_LOADING_STATE: {
					return Object.assign({}, state, {
							isLoading: action.isLoading
					})
			}		
			case LOAD_NEW_COLLABORATOR_DATA: {
					return Object.assign({}, state, {
						newCollaborator: action.newCollaborator
					})
			}
			case SET_NEW_COLLABORATOR_PERIOD: {
					return Object.assign({}, state, {
							dateConfig: {
									...action.dateConfig,
									selectedValues: Object.assign(
											{},
											state.dateConfig.selectedValues,
											{
													[action.formType]: action.value
											}
									)
							},
							isLoading: true
					})
			}
			case 'SET_NEW_COLLABORATOR_FILTER_VALUE': {
				return Object.assign({}, state, {
					ownFilters: Object.assign({}, state.ownFilters, action.value),
					searchText: Object.assign({}, state.searchText, {
						[action.key]: ''
					})
				})
			}
			case 'SET_NEW_COLLABORATOR_SEARCH_FILTER_VALUE': {
				return Object.assign({}, state, {
					searchText: Object.assign({}, state.searchText, action.value)
				})
			}
			case 'CLEAN_NEW_COLLABORATOR_FILTER_VALUE': {
				return Object.assign({}, state, {
					ownFilters: {}
				})
			}
			default:
					return state
	}
}

export default newCollaboratorReducer
