import {
	MANAGE_WIRE_CHARGE_LOADING_STATE,
	LOAD_WIRE_CHARGE_DATA,
	SET_WIRE_CHARGE_PERIOD
} from '../../actions/wireCharge'

const INITIAL_STATE = {
	isLoading: null,
	wireCharge: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		origem: ''
	}
}
/**
 * wireCharge state reducer manager
 * @param {*} state
 * @param {*} action
 */

const wireChargeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_WIRE_CHARGE_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_WIRE_CHARGE_DATA: {
			return Object.assign({}, state, {
				wireCharge: action.wireCharge,
				isLoading: false
			})
		}
		case 'SET_WIRE_CHARGE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_WIRE_CHARGE_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_WIRE_CHARGE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_WIRE_CHARGE_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default wireChargeReducer
