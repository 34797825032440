export const MANAGE_NC_STAGE_LOADING_STATE = 'MANAGE_NC_STAGE_LOADING_STATE',
    LOAD_NC_STAGE_DATA = 'LOAD_NC_STAGE_DATA',
    SET_NC_STAGE_PERIOD = 'SET_NC_STAGE_PERIOD'

export const setNcStagePeriod = (dateConfig, value, formType) => ({
    type: SET_NC_STAGE_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadNcStageData = (ncStage) => ({
    type: LOAD_NC_STAGE_DATA,
    ncStage
})

export const setLoading = (isLoading) => ({
    type: MANAGE_NC_STAGE_LOADING_STATE,
    isLoading
})
