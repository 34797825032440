/**
 * Prepares a breakpoint object for JSS objects. **You should spread the resultant object**
 *
 * @description Created the function
 * @author lopes
 * @version 1.0
 *
 * @param {import("@material-ui/core/styles/MuiThemeProvider").MuiThemeProviderProps} theme The theme object
 * @param {Object} props The final props at the breakpoint object
 */
export const getDesktopBreakpoint = (theme, props) => ({
	[theme.breakpoints.up('sm')]: {
		...props
	}
})
