import React from 'react'
import PropTypes from 'prop-types'
import FilterItem from '../filterItem/FilterItem'
import SlickList from './SlickList'
import { withStyles } from '@material-ui/core'

/**
 * Responsible for managing display of filter list.
 *
 * @description Created the new component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class FilterList extends React.Component {
	render() {
		const {
			items,
			classes,
			keyPropName,
			noMargin,
			valuePropName
		} = this.props

		return (
			!!items &&
			items.length > 0 && (
				<div
					className={!!noMargin ? classes.rootNoMargin : classes.root}
				>
					<SlickList
						settings={{
							infinite: false,
							slidesToShow: 2,
							slidesToScroll: 2,
							speed: 500
						}}
					>
						{items.map((item, index) => (
							<div key={item[keyPropName]}>
								<FilterItem
									// key={index}
									label={`${item[valuePropName]}`}
								/>
							</div>
						))}
					</SlickList>
				</div>
			)
		)
	}
}

const styles = (theme) => ({
	root: {
		marginTop: theme.spacing.unit / 2,
		marginBottom: theme.spacing.unit,
		marginLeft: theme.spacing.unit / 2,
		marginRight: theme.spacing.unit / 2
	},
	rootNoMargin: {
		marginTop: theme.spacing.unit / 2,
		marginBottom: theme.spacing.unit,
		marginLeft: 0,
		marginRight: 0
	}
})

//TODO: CLEANUP THIS AND DESCENDANT CODE
// const makeMapStateToProps = () => {
// 	const getFilterBarFilters = makeGetFilterBarFilters()

// 	const mapStateToProps = (state, props) => {
// 		const onReceiveFilterBarFilter = (response) =>
// 			store.dispatch(setFilterBarUnit(response))

// 		const options = searchForOptions(key, filters[key], (res) => {
// 			// set received filter in state layer
// 			return onReceiveFilterBarFilter(res)
// 		})

// 		return {
// 			items: [] // FIXME: apply new format here! -> getFilterBarFilters(state, props)
// 		}
// 	}

// 	return mapStateToProps
// }

FilterList.defaultProps = {
	keyPropName: 'key',
	items: [],
	noMargin: false,
	valuePropName: 'label'
}

FilterList.propTypes = {
	allowedFilters: PropTypes.array,
	items: PropTypes.array,
	keyPropName: PropTypes.string,
	noMargin: PropTypes.bool,
	valuePropName: PropTypes.string
}

export default withStyles(styles)(FilterList)
