import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, withStyles, ListItemText } from '@material-ui/core'

/**
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 */
class OptionList extends React.Component {
	state = {
		options: []
	}

	onSelect = (value) => {
		const { onSelect } = this.props

		this.handleSelect(value).then(() => onSelect(this.state.options))
	}

	handleSelect = async (value) => {
		const { options } = this.state
		const { active } = this.props

		if (this._find(value))
			this.setState({
				options: options.filter(
					({ classification }) =>
						classification !== value.classification
				)
			})

		if (!this._find(value) && options.length < 2 && active)
			this.setState({ options: [...options, value] })
	}

	renderOptions = (options) => {
		if (options) {
			if (options instanceof Array) return options
			if (options instanceof Object)
				return Object.keys(options).map((key) => options[key])
		}

		return []
	}

	_find = (object) => {
		const { options } = this.state
		return options.find(
			(obj) =>
				obj.classification === object.classification ||
				obj.classification === object.key
		)
	}

	render() {
		const { classes, options, optionType } = this.props

		return (
			<div className={classes.root}>
				<List className={classes.list}>
					{this.renderOptions(options).map((option, index) => (
						<ListItem
							key={option.key}
							onClick={() =>
								this.onSelect({
									type: optionType,
									classification: option.key
								})
							}
							className={`${classes.item} ${
								this._find(option) ? classes.selectedItem : ''
							}`}
						>
							<ListItemText>{option.label}</ListItemText>
						</ListItem>
					))}
				</List>
			</div>
		)
	}
}

const styles = (theme) => ({
	root: {},
	list: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center'
	},
	item: {
		border: `1px solid ${theme.palette.primary.light}`,
		width: 'fit-content',
		margin: 2,
		'& > div span': {
			fontSize: 12
		},
		'& > div': {
			padding: 0
		},
		'&:hover': {
			cursor: 'pointer'
		}
	},
	selectedItem: {
		background: theme.palette.primary.light,
		'& > div span': {
			color: theme.palette.primary.contrastText
		}
	}
})

OptionList.propTypes = {
	limitTo: PropTypes.number,
	options: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				label: PropTypes.string
			})
		)
	]),
	onSelect: PropTypes.func.isRequired
}

OptionList.defaultProps = {
	options: [],
	active: true
}

export default withStyles(styles)(OptionList)
