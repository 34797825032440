import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles, AppBar, Typography, Toolbar } from '@material-ui/core'
import styles from './styles'
import withAuthentication from '../withAuthentication'
import LeftBarIcon from './LeftBarIcon'
import RightBarIcon from './RightBarIcon'
import RightDashboard from './RightDashboard'
import classNames from 'classnames'
import { deviceIsIos } from '../../../utils/env'
import { checkLogin } from '../../../utils/security/auth/verifyAuth'

/**
 * System's top app-bar
 *
 * @description Creation of the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added support to dynamic right-button definition
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added tooltip support to right-icon
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @description Added iOS top padding for escaping screen notch (sensors' area)
 * @author brunoteixeirasilva
 * @version 1.3
 */
class MenuAppBar extends React.Component {
	/**
	 * Manages to execute the specified left-icon-action
	 * located at redux-state-layer
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 */
	leftIconAction = (event) => {
		const { barLeftIconAction } = this.props

		//Action available?
		if (!!barLeftIconAction) barLeftIconAction(event)
		else if (!barLeftIconAction && process.env.REACT_APP_NODE_ENV.trim() === 'development')
			console.error('left-icon-no-action-specified')
	}

	/**
	 * Manages to execute the specified right-icon-action
	 * located at redux-state-layer
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 */
	rightIconAction = (event) => {
		const { barRightIconAction } = this.props

		//Action available?
		if (!!barRightIconAction) barRightIconAction(event)
		else if (!barRightIconAction && process.env.REACT_APP_NODE_ENV.trim() === 'development')
			console.error('right-icon-no-action-specified')
	}

	render() {
		const { classes, authUser, title, id } = this.props

		return (
			!checkLogin(authUser) && (
				<div className={classes.root} id={id}>
					<AppBar
						position="fixed"
						className={classNames(classes.appBar, {
							[classes.appBarIos]: deviceIsIos()
						})}
					>
						<Toolbar>
							<LeftBarIcon />
							<Typography
								variant="h6"
								color="inherit"
								className={classes.flex}
							>
								{title}
							</Typography>
							<RightDashboard />
							<RightBarIcon />
						</Toolbar>
					</AppBar>
				</div>
			)
		)
	}
}

MenuAppBar.propTypes = {
	classes: PropTypes.object.isRequired
	//title: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
	barLeftIcon: state.appConfigState.app.barLeftIcon,
	barLeftIconAction: state.appConfigState.app.barLeftIconAction,
	barLeftIconAriaLabel: state.appConfigState.app.barLeftIconAriaLabel,
	barRightIcon: state.appConfigState.app.barRightIcon,
	barRightIconAction: state.appConfigState.app.barRightIconAction,
	barRightIconAriaLabel: state.appConfigState.app.barRightIconAriaLabel,
	barRightIconTooltip: state.appConfigState.app.barRightIconTooltip
})

export default compose(
	withAuthentication,
	connect(mapStateToProps),
	withStyles(styles)
)(MenuAppBar)
