export const styles = (theme) => ({
	root: {
		flex: '0 0 auto',
		marginLeft: 'auto',
		// justifyContent: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		float: 'right',
		marginRight: theme.spacing.unit,
		width: 'auto'
	}

	// formControl: {
	// 	margin: theme.spacing.unit,
	// 	minWidth: 120,
	// 	maxWidth: 300
	// },
	// chips: {
	// 	display: 'flex',
	// 	flexWrap: 'wrap'
	// },
	// chip: {
	// 	margin: theme.spacing.unit / 4
	// },
	// noLabel: {
	// 	marginTop: theme.spacing.unit * 3
	// }
})

export const selectFilterForm = (theme) => ({
	select: { whiteSpace: 'pre-wrap' }
})

export const actionFilterDialog = (theme) => ({
	form: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing.unit
	},
	dialogContent: {
		width: '100%'
	},
	dialogPaper: {
		marginRight: theme.spacing.unit,
		marginLeft: theme.spacing.unit,
		maxHeight: '100vh'
	}
})
