import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import TabContainer from '../../agilTabs/container/TabContainer'
import TeamCard from './item/TeamCard'
import { translate } from '../../../../utils/lang'
import ActionButton from '../../../reusable/button/action/ActionButton'
import EmptyListItem from '../../list/empty/item/EmptyListItem'
import FilterBarContainer from '../../filterBar'
import BuzzList from '../../list/buzz'
import ListHeader from '../../list/header/ListHeader'
import { Portal, Grid } from '@material-ui/core'
import { InformerIconButton } from '../../dashItem/DashItem'
import { openActionModal } from '../../../../reducers/action/navigation'
import withMappedDom from '../../withMappedDom'
import DialogModal from '../../../app/userActions/components/DialogModal'

/**
 * Manages team-list functionalities
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description Changed to add more functionalities
 * @author wegner
 * @version 1.1
 *
 * @description Changed the class, added a bit more reusability.
 * 				Rewritten sakura invokes to include the lib name.
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @description Changed to add logged and unlogged filter buttons, to not need routing
 * 				Rewritten sakura request url.
 * @author wegner
 * @version 1.3
 *
 * @description Code clean-up + prop renaming.
 * @author brunoteixeirasilva
 * @version 1.4
 *
 * @description Team counter now respects applied filters, but not status
 * @author brunoteixeirasilva
 * @version 1.5
 *
 * @description Removed container responsibilities to a container itself
 * @author brunoteixeirasilva
 * @version 1.6
 *
 * @description Removed withStyles and styles implementation,
 * 				added component for ListHeader standardization,
 * 				added noTopSpacing to TabContainer
 * @author brunoteixeirasilva
 * @version 1.7
 */
class TeamList extends React.Component {
	handleAction = () => {
		const { getJSON, getDomSnapshot, handleActionModal, data } = this.props
		handleActionModal()
		const self = this

		// getDomSnapshot(ReactDOM.findDOMNode(self))
		// getDomSnapshot(ReactDOM.findDOMNode(self).parentElement)
		getJSON(data)

		getDomSnapshot(ReactDOM.findDOMNode(self))
	}
	render() {
		const {
			teams,
			totalTeamsLength,
			onItemClick,
			draggableContainer,
			mobile,
			handleDialog,
			onToggleModal,
		} = this.props

		return (
			<TabContainer noSpacing>
				<ListHeader
					filterPlaceholder={translate('label/type-in-a-team')}
					partialLength={teams.length}
					totalLength={totalTeamsLength}
				/>
				<FilterBarContainer />
				{!teams ||
					(teams.length === 0 && (
						<EmptyListItem
							icon="phonelink_off"
							customTranslateRef="placeholder/no-logged-teams"
						/>
					))}
				<BuzzList
					items={teams}
					mapper={(value, index) => {
						return (
							<TeamCard
								key={index}
								team={value}
								handleClick={() => {
									return onItemClick(value.equipe_id)
								}}
							/>
						)
					}}
				/>
				{mobile && (
					<React.Fragment>
						<Portal container={draggableContainer.current}>
							<Grid container>
								<Grid item xs={4}>
									<InformerIconButton
										onClick={handleDialog}
										icon={'playlist_add'}
										label={translate('label/more-filters')}
									/>
								</Grid>
								<Grid item xs={4}>
									<InformerIconButton
										onClick={() => onToggleModal()}
										icon={'filter_list'}
										label={translate('label/filters')}
									/>
								</Grid>
								<Grid item xs={4}>
									<InformerIconButton
										onClick={() => this.handleAction()}
										icon={'camera_alt'}
										label={translate(
											'label/generate-action',
										)}
									/>
								</Grid>
							</Grid>
						</Portal>
						<DialogModal />
					</React.Fragment>
				)}
				{!mobile && (
					<ActionButton
						node={this}
						json={{ teams, totalTeamsLength, date: new Date() }}
					/>
				)}
			</TabContainer>
		)
	}
}
const mapDispatchToProps = dispatch => ({
	handleActionModal: () => dispatch(openActionModal()),
})

export default compose(
	withMappedDom,
	connect(null, mapDispatchToProps),
	withRouter,
)(TeamList)
