import React from 'react'
import * as routes from '../../../constants/routes'
import { auth } from '../../../firebase'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import withAuthorization from '../withAuthorization'

import { Icon, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { compose } from 'recompose'
import { translate } from '../../../utils/lang'
import { AUTH_DESTROYED } from '../../../reducers/session'
import { clearUserActiveCompany } from '../../../actions/user/company'
import { clearPluginList } from '../../../actions/plugin/list'

class SignOutButton extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			history: routes.SIGN_IN
		}
	}

	handleLogOut = (event) => {
		const {
			history,
			notifyOnUserLogOut,
			clearLoadedPlugins,
			onClearActiveCompany,
			userLogout
		} = this.props

		auth.doSignOut().then(() => {
			userLogout()
			//Notifies the store
			notifyOnUserLogOut()
				.then(() => clearLoadedPlugins())
				.then(() => onClearActiveCompany())
				.then(() => history.push(routes.SIGN_IN))
		})

		if (!!event.preventDefault) event.preventDefault()
	}

	render() {
		const items = [
			{
				icon: 'exit_to_app',
				text: translate('label/sign-out'),
				route: this.handleLogOut
			}
		]

		return items.map((item, index) => (
			<ListItem key={index} button onClick={item.route}>
				<ListItemIcon>
					<Icon>{item.icon}</Icon>
				</ListItemIcon>
				<ListItemText primary={item.text} />
			</ListItem>
		))
	}
}

const mapDispatchToProps = (dispatch) => ({
	notifyOnUserLogOut: async () => dispatch({ type: AUTH_DESTROYED }),
	clearLoadedPlugins: async () => dispatch(clearPluginList()),
	onClearActiveCompany: async () => dispatch(clearUserActiveCompany()),
	userLogout: () => dispatch({ type: 'USER_LOGOUT' })
})

const authCondition = (authUser) => !!authUser

export default compose(
	connect(null, mapDispatchToProps),
	withRouter,
	withAuthorization(authCondition)
)(SignOutButton)
