export const MANAGE_PANEL_CHECKLIST_LOADING_STATE = 'MANAGE_PANEL_CHECKLIST_LOADING_STATE',
	LOAD_PANEL_CHECKLIST_DATA = 'LOAD_PANEL_CHECKLIST_DATA',
	SET_PANEL_CHECKLIST_PERIOD = 'SET_PANEL_CHECKLIST_PERIOD',
	LOAD_PANEL_CHECKLIST_DATA_FILTERS = 'LOAD_PANEL_CHECKLIST_DATA_FILTERS'

export const setPanelChecklistPeriod = (dateConfig, value, formType) => ({
	type: SET_PANEL_CHECKLIST_PERIOD,
	dateConfig,
	value,
	formType,
})

export const loadPanelChecklistData = data => ({
	type: LOAD_PANEL_CHECKLIST_DATA,
	data,
})

export const setLoading = isLoading => ({
	type: MANAGE_PANEL_CHECKLIST_LOADING_STATE,
	isLoading,
})

export const loadPanelChecklistDataFilters = panelChecklistFilters => ({
	type: LOAD_PANEL_CHECKLIST_DATA_FILTERS,
	panelChecklistFilters,
})
