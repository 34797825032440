import {
	applyUserActiveCompany,
	applySetUserCompanies,
	applySetInitialState,
	applyRequireCompanyActivation,
	applySetUserActiveCompanyLoading
} from './actions'
import {
	SET_USER_ACTIVE_COMPANY,
	REQUIRE_COMPANY_ACTIVATION,
	CLEAR_USER_COMPANIES,
	SET_USER_COMPANIES,
	CLEAR_USER_ACTIVE_COMPANY,
	SET_USER_ACTIVE_COMPANY_LOADING
} from '../../../actions/user/company'

const INITIAL_STATE = {
	companies: [],
	activeCompanyId: null,
	activeCompanyLoading: false,
	activeCompanyAbbrev: null,
	requireCompanyActivation: false
}

/**
 * Manages the user-company information portion at the redux state-tree
 *
 * @description Created the reducer
 * @author davispindola
 * @version 1.0
 *
 * @description Changed constants' location
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} state The current app state-tree portion for this reducer
 * @param {Object} action The action with "type" and desired props
 */
const userCompanyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_USER_ACTIVE_COMPANY_LOADING: {
			// console.log('Got')
			return applySetUserActiveCompanyLoading(state, action)
		}
		case SET_USER_ACTIVE_COMPANY: {
			// console.log('Got')
			return applyUserActiveCompany(state, action, INITIAL_STATE)
		}
		case CLEAR_USER_ACTIVE_COMPANY: {
			return applySetInitialState(
				state,
				'activeCompanyId',
				INITIAL_STATE.activeCompanyId
			)
		}
		case SET_USER_COMPANIES: {
			return applySetUserCompanies(state, action)
		}
		case CLEAR_USER_COMPANIES: {
			return applySetInitialState(
				state,
				'companies',
				INITIAL_STATE.companies
			)
		}
		case REQUIRE_COMPANY_ACTIVATION: {
			return applyRequireCompanyActivation(state, action)
		}
		default:
			return state
	}
}

export default userCompanyReducer
