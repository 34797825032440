import React from 'react'
import { Typography, Icon, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import DialogTypeAlert from '../../reusable/dialog/type/alert'
import ButtonRedirect from './ButtonRedirect'
import { translate } from '../../../utils/lang'
import { compose } from 'recompose'

/**
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @param {*} param0
 */
const ErrorPage = ({ /*history, match, location,*/ classes }) => {
	return (
		<div className={classes.root}>
			<div className={classes.message}>
				<div className={classes.alert}>
					<Icon>directions</Icon>
					<Typography variant="h5">
						{translate('label/alert')}
					</Typography>
				</div>
				<Typography component="p">
					{translate('description/a-wild-error-appear')}
				</Typography>
			</div>
			<ButtonRedirect />
			<DialogTypeAlert />
		</div>
	)
}

const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	message: {
		padding: theme.spacing.unit * 2,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	alert: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
})

export default compose(
	withRouter,
	withStyles(styles)
)(ErrorPage)
