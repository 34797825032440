import React from 'react'
import { compose } from 'recompose'
import { Modal, Typography, withStyles, Icon, Tooltip } from '@material-ui/core'
import _ from 'lodash'

/**
 * @description create modal dashSet
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
class ModalDashSet extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			animation: false,
		}
	}

	componentDidUpdate(prevProps) {

		const { openModal } = this.props

		if (!!prevProps.openModal && !openModal) {
			this.setState({
				animation: false
			})
		}
	}

	closeWithAnimation = () => {
		const { onCloseModal } = this.props

		this.setState({
			animation: true
		})
		setTimeout(() => {
			onCloseModal()
		}, 900)
	}

	render() {
		const {
			classes,
			openModal,
			arrayData,
			group,
			selectCard,
			handleFavoriteCard,
			setGroup
		} = this.props

		const { animation } = this.state

		return (
			<Modal
				className={classes.modal}
				open={openModal}
				onClose={(() => this.closeWithAnimation())}
			>
				<div className={animation ? classes.contentExit : classes.content}>
					<div className={classes.head} id='head'>
						<Typography className={classes.title} variant='h6'>
							Visão Mosaico
						</Typography>
						<div className={classes.dashSets} id='dashSets'>
							{
								!!group && group.map((dashSet) => (
									<div
										placement='bottom'
										onClick={(() => setGroup(dashSet))}
										style={{ background: dashSet.dashSetColor }}
										className={classes.dashSetTitle} >
										<Typography
											style={{ textDecoration: !!dashSet.active ? 'auto' : 'line-through' }}
											className={classes.title} variant='h6'>
											{dashSet.name}
										</Typography>
									</div>
								))
							}
						</div>
						<div />
					</div>
					<div className={classes.body} id='body'>
						{
							!!arrayData && arrayData.map((data, index) => {

								let param = group.map((item) => item.id).indexOf(data.dashSet)

								if (!!group[param] && !!group[param].active) {

									return (
										<div
											style={{ borderTop: `10px solid ${!!group[param] ? group[param].dashSetColor : 'white'}` }}
											className={classes.cards}
											id={index}
										>
											<div className={classes.icon} id='icon'>
												<div className={classes.infoIcon} id='infoIcon'>
													<span className={classes.tooltiptext} id='tooltiptext'>
														{
															!!data.detail
															?
															<div>
																{
																	<Typography style={{ padding: '10px' }}>
																		{data.detail[0].substring(0, 60).concat('...')}
																	</Typography>
																}
															</div>
															:
															<div>
																<Typography>
																	{
																		`
																			• Distribuição geográfica dos checklists realizados
																			• Mapa de calor das não conformidades encontradas nos checklists
																			• Análise percentual das ações resolvidas decorrentes das não conformidades identificadas
																			• Verificação do desempenho dos itens específicos do checklist que apresentaram não conformidades.
																		`.substring(0, 60).concat('...')
																	}
																</Typography>
															</div>
														}
													</span>
													<Tooltip
														id='iconIndo'
														enterDelay={0}
														leaveDelay={300}
														enterTouchDelay={100}
														leaveTouchDelay={300}
														placement='bottom'
														open={false}
														title={''}
													>
														<Icon style={{ color: 'white' }} className={classes.iconInfo}
															id='iconInfo'
															onClick={(() => selectCard(data, index, true))}
														>
															query_stats
														</Icon>
													</Tooltip>
												</div>
												<Tooltip
													id='tooltip-controlled'
													enterDelay={0}
													leaveDelay={300}
													enterTouchDelay={100}
													leaveTouchDelay={300}
													placement='bottom'
													title={'Favorito'}
												>
													{data.favorite ? (
														<Icon style={{ color: 'gold' }} className={classes.iconInfo}
															onClick={(() => handleFavoriteCard({
																dashItemId: data.dashItemId,
																dashSet: data.dashSet
															}))}>
															star
														</Icon>
													) : (
														<Icon style={{ color: 'white' }} className={classes.iconInfo}
															onClick={(() => handleFavoriteCard({
																dashItemId: data.dashItemId,
																dashSet: data.dashSet
															}))}>
															star_border
														</Icon>
													)}
												</Tooltip>
											</div>
											<div className={classes.bottom} id='bottom' onClick={(() => selectCard(data, index))}>
												<Typography className={classes.Titledescription}>
													{data.title}
												</Typography>
											</div>
										</div>
									)
								}
							})
						}
					</div>
				</div>
			</Modal>
		)
	}
}

export const styles = theme => ({
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	'@keyframes animate': {
		'0%': {
			transform: 'translateY(-200%)'
		},
		'100%': {
			transform: 'translateY(0%)'
		}
	},
	'@keyframes animateExit': {
		'0%': {
			transform: 'translateY(0%)'
		},
		'100%': {
			transform: 'translateY(-200%)'
		}
	},
	content: {
		animation: 'animate 1s',

		width: '90%',
		maxHeight: '90%',
		overflowY: 'scroll',
		'& > #head': {

			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			backgroundColor: '#5731AC',

			'& > #dashSets, h1': {
				padding: '2px',
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				'& > *': {
					flex: '0 0 auto'
				},
				'& > span': {
					cursor: 'pointer'
				},
				borderRadius: '6px 6px 0px 0px'
			},
		},
		'& > #body, h1': {
			padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
			backgroundColor: '#FFFFFF',
			display: 'flex',
			flexWrap: 'wrap',
			borderRadius: '6px',
			display: 'grid',
			overflowX: 'hidden'
		}
	},
	contentExit: {
		animation: 'animateExit 1s',

		width: '90%',
		maxHeight: '90%',
		overflowY: 'scroll',
		'& > #head': {

			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			backgroundColor: '#5731AC',

			'& > #dashSets, h1': {
				padding: '2px',
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				'& > *': {
					flex: '0 0 auto'
				},
				'& > span': {
					cursor: 'pointer'
				},
				borderRadius: '6px 6px 0px 0px'
			},
		},
		'& > #body, h1': {
			padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
			backgroundColor: '#FFFFFF',
			display: 'flex',
			flexWrap: 'wrap',
			borderRadius: '6px',
			display: 'grid',
			overflowX: 'hidden'
		}
	},
	'@media screen and (max-width: 3000px)': {
		'body': {
			gridTemplateColumns: '1fr 1fr 1fr 1fr',
		}
	},
	'@media screen and (max-width: 1600px)': {
		'body': {
			gridTemplateColumns: '1fr 1fr 1fr',
		}
	},
	'@media screen and (max-width: 800px)': {
		'body': {
			gridTemplateColumns: '1fr 1fr',
		}
	},
	dashSetTitle: {
		padding: '3px 10px',
		margin: '5px',
		borderRadius: '6px',
		'&:hover': {
			color: theme.palette.primary.light,
			cursor: 'pointer',
		},
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		fontFamily: 'roboto',
		fontSize: '15px'
	},
	cards: {
		margin: '5px',
		padding: '5px',
		display: 'grid',
		justifyItems: 'center',
		background: '#5731AC',
		borderRadius: '6px',
		minHeight: '90px',
		minWidth: '350px',

		'&:hover': {
			color: theme.palette.primary.light,
			transform: 'scale(1.1)',
			cursor: 'pointer',
		},
	},
	headColor: {
		width: '100%',
		borderRadius: '6px 6px 0px 0px'
	},
	icon: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	infoIcon: {
		'& > #tooltiptext': {
			visibility: 'hidden',
			width: '290px',
			backgroundColor: 'white',
			top: '30px',
			right: '10px',
			color: 'black',
			textAlign: 'center',
			borderRadius: '6px',
			padding: '5px 0',
			position: 'absolute',
			border: '2px solid #4F0CE4',
			maxHeight: '100px',

			'&:hover': {
				'& > #tooltiptext': {
					visibility: 'visible'
				}
			},
		},

		'&:hover': {
			'& > #tooltiptext': {
				visibility: 'visible'
			}
		},
	},
	tooltiptext: {
		width: '290px',
	},
	iconInfo: {
		paddingRight: theme.spacing.unit,
		fontSize: '16px',
		paddingRight: '20px',
		'&:hover': {
			color: theme.palette.primary.light,
			transform: 'scale(1.3)',
			cursor: 'pointer',
		},
	},
	Titledescription: {
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '16px',
	},
	bottom: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	description: {
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	}
})

export default compose(
	withStyles(styles)
)(ModalDashSet)
