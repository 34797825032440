import { sourceEngine } from '.'

/**
 * Requests (POST) to the data-source engine at Cloud Functions
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSourceId The data-source ID
 * @param {string} dataSetId The data-set ID
 * @param {Object} params [optional] Query parameters to be passed on
 */
export const getFrom = (dataSourceId, dataSetId, params = null) => {
	const engine = sourceEngine()

	return engine.postProvisory(dataSourceId, dataSetId, params)
}
