import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	ListItem,
	ListItemIcon,
	Icon,
	ListItemText,
	Select,
	MenuItem,
	FormControl
	// FormHelperText
	// Tooltip
} from '@material-ui/core'
import CountryFlag from '../../icon/flag/CountryFlag'
import { onSetUserLanguage } from '../../../../firebase/account'
// import { translate } from '../../../../utils/lang'

class LanguageContainer extends Component {
	render() {
		const { languages, language, setUserLanguage, listItem } = this.props
		return listItem ? (
			<ListItem
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
				}}
				button
			>
				<ListItemIcon>
					<Icon>language</Icon>
				</ListItemIcon>
				<ListItemText>
					<Select
						value={language}
						onChange={setUserLanguage}
						renderValue={(selected) => {
							return (
								<CountryFlag country={selected.split('_')[1]} />
							)
						}}
					>
						{languages.map((item) => (
							<MenuItem
								key={item.key}
								value={item.key}
								disabled={item.disabled}
							>
								<CountryFlag country={item.icon} />
								{item.label}
							</MenuItem>
						))}
					</Select>
				</ListItemText>
			</ListItem>
		) : (
			<FormControl>
				<Select
					value={language}
					// outl
					onClick={(e) => e.stopPropagation()}
					onChange={setUserLanguage}
					renderValue={(selected) => {
						return <CountryFlag country={selected.split('_')[1]} />
					}}
				>
					{languages.map((item) => (
						<MenuItem
							key={item.key}
							value={item.key}
							disabled={item.disabled}
						>
							<CountryFlag country={item.icon} />
							{item.label}
							{/* <Tooltip title={item.label}> */}
							{/* </Tooltip> */}
						</MenuItem>
					))}
				</Select>
				{/* <FormHelperText>{translate('label/language')}</FormHelperText> */}
			</FormControl>
		)
	}
}

const mapStateToProps = (state) => {
	// let languageCollection = state.accountConfigState.languages
	let languages = Object.values(state.accountConfigState.languages).map(
		(item) => item
	)

	return {
		languages,
		language: state.accountConfigState.language || 'pt_br'
	}
}

const mapDispatchToProps = (dispatch) => ({
	setUserLanguage: (event) => {
		onSetUserLanguage(event.target.value)
		return dispatch({
			type: 'SET_APP_LANGUAGE',
			language: event.target.value
		})
	}
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LanguageContainer)
