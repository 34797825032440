import axios from 'axios'
import baseURL from './baseURL'

const userCreateFunctionName = 'userCreate'

/**
 * Creates an user at the remote Cloud Functions
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} userInfo object containing the structure
 * 							{ name: 'string', email: 'string', password: 'string' }
 */
const userCreate = (userInfo) => {
	return axios.post(`${baseURL}/${userCreateFunctionName}`, userInfo)
}

export default userCreate
