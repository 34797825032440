import * as auth from './auth'
import * as dashItem from './dashItem'
import * as firebase from './firebase'
import * as user from './user'
import * as storage from './storage'

/**
 * This means that firebase should be all-set
 * And authentication done.
 */
export {
	auth,
	firebase,
	//Other lists
	// client,
	dashItem,
	user,
	storage,
}
