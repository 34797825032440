export const SET_LOADED_CHECKLISTS = 'SET_LOADED_CHECKLISTS',
	SET_CHECKLIST_LOADING_STATE = 'SET_CHECKLIST_LOADING_STATE',
	SET_LOADED_SERVICE_ORDER = 'SET_LOADED_SERVICE_ORDER'

const INITIAL_STATE = {
	isLoading: false,
	checkLists: null,
	serviceOrder: null
}

const checkListsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_LOADED_CHECKLISTS: {
			return Object.assign({}, state, { checkLists: action.checkLists })
		}
		case SET_CHECKLIST_LOADING_STATE: {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		case SET_LOADED_SERVICE_ORDER: {
			return Object.assign({}, state, {
				serviceOrder: action.serviceOrder
			})
		}
		default:
			return state
	}
}

export default checkListsReducer
