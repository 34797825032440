export const MANAGE_NEW_COLLABORATOR_LOADING_STATE = 'MANAGE_NEW_COLLABORATOR_LOADING_STATE',
    LOAD_NEW_COLLABORATOR_DATA = 'LOAD_NEW_COLLABORATOR_DATA',
    SET_NEW_COLLABORATOR_PERIOD = 'SET_NEW_COLLABORATOR_PERIOD',
    SET_NEW_COLLABORATOR_VALUE = 'SET_NEW_COLLABORATOR_VALUE'

export const setNewCollaboratorPeriod = (dateConfig, value, formType) => ({
    type: SET_NEW_COLLABORATOR_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadNewCollaboratorData = (newCollaborator) => ({
    type: LOAD_NEW_COLLABORATOR_DATA,
    newCollaborator
})

export const setLoading = (isLoading) => ({
    type: MANAGE_NEW_COLLABORATOR_LOADING_STATE,
    isLoading
})

export const newCollaboratorValue = (text) => ({
    type: SET_NEW_COLLABORATOR_VALUE,
    text
})