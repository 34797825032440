const TOGGLE_PRODUCTIVITY_CONTRACT_DIALOG =
		'TOGGLE_PRODUCTIVITY_CONTRACT_DIALOG',
	CLEAR_PRODUCTIVITY_CONTRACTS = 'CLEAR_PRODUCTIVITY_CONTRACTS',
	PRODUCTIVITY_CONTRACTS_LOADED = 'PRODUCTIVITY_CONTRACTS_LOADED'

const INITIAL_STATE = {
	contracts: null,
	open: false
}

/**
 * Contract reducer manager on productivity context
 * @author Davi Wegner
 * @param {*} state
 * @param {*} action
 */
function contractReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case PRODUCTIVITY_CONTRACTS_LOADED:
			return Object.assign({}, state, {
				contracts: Object.assign({}, state.contracts, action.contract)
			})
		case CLEAR_PRODUCTIVITY_CONTRACTS:
			return Object.assign({}, state, INITIAL_STATE)
		case TOGGLE_PRODUCTIVITY_CONTRACT_DIALOG:
			return Object.assign({}, state, { open: action.open })
		default:
			return state
	}
}

export default contractReducer

export {
	TOGGLE_PRODUCTIVITY_CONTRACT_DIALOG,
	CLEAR_PRODUCTIVITY_CONTRACTS,
	PRODUCTIVITY_CONTRACTS_LOADED
}
