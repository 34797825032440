import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import UsersCompany from './UsersCompany'
import { firebase } from '../../../firebase'

class CreateUser extends React.Component {

    state = ({
        validRequest: false,
        activeCompanyId: null,
        users: null,
        userCurrent: null
    })

    componentDidMount() {
        firebase.auth.onAuthStateChanged((authUser) => {
            if (!!authUser)
                this.setState({
                    userCurrent: authUser.uid
                })
        })
    }

    componentDidUpdate() {
        const { activeCompanyId } = this.props

        if (!!activeCompanyId && !this.state.validRequest && !this.state.activeCompanyId) {
            this.setState({
                validRequest: true
            })
        }
    }

    render () {

        const { activeCompanyId, usersProps } = this.props
        const { userCurrent } = this.state
        
        return (
            !!activeCompanyId && !!usersProps ?
            <UsersCompany
                activeCompanyId={activeCompanyId}
                usersProps={usersProps}
                author={userCurrent}
            /> :
            <div />
        )
    }
}

const mapStateToProps = (state) => ({
    usersProps: state.createUserState.usersProps,
    userFilter: state.createUserState.userFilter,
    activeCompanyId: state.currentUserState.activeCompany
})

const mapDispatchToProps = dispatch => ({
    setUsersInReducer: usersProps => dispatch({ type: "SET_USERS_IN_REDUCER", usersProps }),
    setUserReducer: userFilter => dispatch({ type: "SET_USER_FILTER_REDUCER", userFilter })
})

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CreateUser)