const INITIAL_STATE = {
	index: null
}

const bottomNavigationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'HANDLE_BOTTOM_NAVIGATION_CHANGE':
			return Object.assign({}, state, { index: action.value })
		default:
			return state
	}
}

export default bottomNavigationReducer
