export const MANAGE_EVOLUTER_NC_LOADING_STATE = 'MANAGE_EVOLUTER_NC_LOADING_STATE',
    LOAD_EVOLUTER_NC_DATA = 'LOAD_EVOLUTER_NC_DATA',
    SET_EVOLUTER_NC_PERIOD = 'SET_EVOLUTER_NC_PERIOD'

export const setEvoluterNcPeriod = (dateConfig, value, formType) => ({
    type: SET_EVOLUTER_NC_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadEvoluterNcData = (data) => ({
    type: LOAD_EVOLUTER_NC_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_EVOLUTER_NC_LOADING_STATE,
    isLoading
})
