export const MANAGE_RESULT_CHECK_LIST_LOADING_STATE =
    'MANAGE_RESULT_CHECK_LIST_LOADING_STATE',
    LOAD_RESULT_CHECK_LIST_DATA = 'LOAD_RESULT_CHECK_LIST_DATA',
    SET_RESULT_CHECK_LIST_PERIOD = 'SET_RESULT_CHECK_LIST_PERIOD'

export const setResultCheckListPeriod = (dateConfig, value, formType) => ({
    type: SET_RESULT_CHECK_LIST_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadResultCheckListData = (data) => ({
    type: LOAD_RESULT_CHECK_LIST_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_RESULT_CHECK_LIST_LOADING_STATE,
    isLoading
})
