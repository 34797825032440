const TEAM_LOAD_STATUS_FINISHED = 'TEAM_LOAD_STATUS_FINISHED',
	CLEAR_TEAM_DETAIL_INFO = 'CLEAR_TEAM_DETAIL_INFO',
	TEAM_DETAIL_INFO_LOADED = 'TEAM_DETAIL_INFO_LOADED',
	SET_CURRENT_TEAM = 'SET_CURRENT_TEAM',
	CLEAR_CURRENT_TEAM = 'CLEAR_CURRENT_TEAM'

const INITIAL_STATE = {
	team: null,
	value: 0,
	info: null,
	checked: false,
	loading: false,
	dateRange: {},
	dayCount: 30
}

/**
 * Sets the current active team
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The current action with "type" and desired prop
 */
const setCurrentTeam = (state, action) =>
	Object.assign({}, state, { team: action.team })

/**
 * Clears the current active team
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 */
const clearCurrentTeam = (state) =>
	Object.assign({}, state, { team: INITIAL_STATE.team })

/**
 * Clears state data
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const teamDetailCleared = (state) => ({
	...state,
	...INITIAL_STATE
})

/**
 * 	Loads the Team Detail Page Cards, based on its cards properties
 *	Manages team-detail state layer *
 *
 * @author Wegner
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and specific props
 */
function teamDetailReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CLEAR_CURRENT_TEAM: {
			return clearCurrentTeam(state, action)
		}
		case SET_CURRENT_TEAM: {
			return setCurrentTeam(state, action)
		}
		case TEAM_DETAIL_INFO_LOADED: {
			return Object.assign({}, state, action.info)
		}
		case CLEAR_TEAM_DETAIL_INFO: {
			return teamDetailCleared(state)
		}
		case TEAM_LOAD_STATUS_FINISHED: {
			return Object.assign({}, state, { loading: action.loading })
		}
		default:
			return state
	}
}

export default teamDetailReducer

export {
	TEAM_LOAD_STATUS_FINISHED,
	CLEAR_TEAM_DETAIL_INFO,
	TEAM_DETAIL_INFO_LOADED,
	CLEAR_CURRENT_TEAM,
	SET_CURRENT_TEAM
}
