import {
	SET_OUTDATED_NON_COMPLIANCES_DATA,
	SET_OUTDATED_NON_COMPLIANCES_LOADING_STATE,
	SET_OUTDATED_NON_COMPLIANCES_SELECTED_OBJECT,
	SET_OUTDATED_NON_COMPLIANCES_FILTER_DATA,
	SET_OUTDATED_NON_COMPLIANCES_FILTER_VALUE,
	SET_OUTDATED_NON_COMPLIANCES_SEARCH_TEXT
} from '../../actions/outdatedNonCompliance'

const INITIAL_STATE = {
	isLoading: false,
	outdatedNonCompliances: null,
	selectedObject: null,
	filterData: {},
	selectedValues: {},
	update: false,
	searchText: null
}

/**
 * Outdated non compliances state manager
 * @description created the reducer
 * @author Davi Wegner
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */
const outdatedNonComplianceReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_OUTDATED_NON_COMPLIANCES_DATA:
			return Object.assign({}, state, {
				outdatedNonCompliances: action.data,
				update: false
			})
		case SET_OUTDATED_NON_COMPLIANCES_LOADING_STATE: {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		case SET_OUTDATED_NON_COMPLIANCES_SELECTED_OBJECT: {
			return Object.assign({}, state, { selectedObject: action.id })
		}
		case SET_OUTDATED_NON_COMPLIANCES_FILTER_DATA: {
			return Object.assign({}, state, {
				filterData: Object.assign({}, state.filterData, {
					[action.key]: action.filterData
				})
			})
		}
		case SET_OUTDATED_NON_COMPLIANCES_FILTER_VALUE: {
			return Object.assign({}, state, {
				selectedValues: Object.assign({}, state.selectedValues, {
					[action.key]: action.value
				}),
				update: true
			})
		}
		case SET_OUTDATED_NON_COMPLIANCES_SEARCH_TEXT: {
			return Object.assign({}, state, { searchText: action.value })
		}
		default:
			return state
	}
}

export default outdatedNonComplianceReducer
