import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { FormControl, InputLabel } from '@material-ui/core'

const styles = {
	formControl: {
		width: '100%'
	}
}

/**
 * Will design a select-box
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @param {Object} props The component props' set
 */
const SelectWithChildren = (props) => {
	const { classes } = props

	const childrenWithProps = React.cloneElement(props.children, {
		...props.children.props
	})

	return (
		<FormControl className={classes.formControl}>
			<InputLabel>{props.label}</InputLabel>
			{childrenWithProps}
		</FormControl>
	)
}

SelectWithChildren.propTypes = {
	label: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SelectWithChildren)
