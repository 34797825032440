import React from 'react'
import { withStyles, Chip, Typography, List, Divider } from '@material-ui/core'
import * as User from '../../../../../firebase/user'
import { translate } from '../../../../../utils/lang'

/**
 * Responsible for listing user receivers
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added translate support
 * @author brunoteixeirasilva
 * @version 1.1
 */
class ListOfReceivers extends React.Component {
	state = {
		receiver: null,
		receivers: []
	}

	componentDidMount() {
		const { action } = this.props

		User.get(action.receiver.key).then((snapshot) => {
			this.setState({ receiver: snapshot.data() })
		})

		//Receivers were included
		if (null !== action.ccReceivers) {
			action.ccReceivers.map((uid) => {
				User.get(uid).then((snapshot) => {
					this.setState({
						receivers: [
							...this.state.receivers,
							snapshot.data().name
						]
					})
				})
			})
		}
	}

	render() {
		const { receiver, receivers } = this.state
		const { classes } = this.props

		return (
			<div className={classes.root}>
				<Divider className={classes.divider} />
				{receiver !== null && (
					<div className={classes.flex}>
						<Typography>{translate('label/to')}: </Typography>
						<Chip
							className={classes.personChip}
							// style={{ fontSize: '0.6125rem' }}
							label={receiver.name}
						/>
					</div>
				)}
				<Divider className={classes.divider} />
				<div>
					{receivers.length > 0 && (
						<div>
							<List className={classes.flex}>
								<Typography>
									{translate('label/carbon-copy')}:
								</Typography>
								{receivers.map((name, i) => {
									return (
										<Chip
											key={i}
											className={classes.personChip}
											// style={{ fontSize: '0.6125rem' }}
											label={name}
										/>
									)
								})}
							</List>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const styles = (theme) => ({
	flex: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: 0,
		'& > p': {
			padding: 5
		}
	},
	divider: {
		margin: `${theme.spacing.unit}px 0`
	},
	personChip: {
		fontSize: '0.6125rem'
	}
})

export default withStyles(styles)(ListOfReceivers)
