import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import * as Action from '../../../../../firebase/actions'
import withActiveCompany from '../../../../reusable/withActiveCompany'
import withAuthentication from '../../../../reusable/withAuthentication'
import {
	setActionResponses,
	setActionResponsesToInitialState
} from '../../actions'
import View from './View'

/**
 * Manages to display a response container
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description Added a key to the list
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added withActiveCompany
 * @author Davi Wegner
 * @version 1.2
 */
class Responses extends React.Component {
	componentDidMount() {
		const { activeCompanyId, actionId, setActionResponses } = this.props
		// console.log(activeCompanyId, actionId)
		Action.loadActionResponses(activeCompanyId, actionId)
			.then((snapshot) => {
				let responses = {}

				snapshot.forEach((response) => {
					responses[response.id] = response.data()
				})

				return setActionResponses(responses)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	componentWillUnmount() {
		this.props.setActionResponsesToInitialState()
	}

	render() {
		return <View {...this.props} />
	}
}

const mapStateToProps = (state) => ({
	// actionId: state.actionsState.actionId
})

const mapDispatchToProps = (dispatch) => ({
	setActionResponses: (responses) => dispatch(setActionResponses(responses)),
	setActionResponsesToInitialState: () =>
		dispatch(setActionResponsesToInitialState())
})

export default compose(
	withActiveCompany,
	withAuthentication,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Responses)
