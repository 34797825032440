import React from 'react'
import { compose } from 'recompose'
import { withStyles, Typography, Chip } from '@material-ui/core'

import Loader from '../../../../reusable/loader'
import * as Style from './Style'
import * as Utils from './utils'
import { translate } from '../../../../../utils/lang'

const getStyle = (author, uid) => {
	return author === uid
		? {
				borderRight: '2px solid blue'
		  }
		: {
				borderLeft: '2px solid red'
		  }
}

const Action = ({ classes, action, label }) => (
	<div className={classes.item}>
		<Typography>{label}</Typography>
		<Chip label={translate(action)} />
	</div>
)

const ActionChip = withStyles(Style.response)(Action)

/**
 * Manages to display a response-list-item
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description Added translate support
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description fixed memory leak, and now does not load any info on mount
 * @author Davi Wegner
 * @version 1.2
 */
class Response extends React.Component {
	render() {
		const {
			classes,
			authorName,
			authUser,
			actionDone,
			actionDoneIn,
			actionObservation,
			author,
			date
		} = this.props
		return this.props.authorName ? (
			<div
				className={classes.root}
				style={getStyle(author, authUser.uid)}
			>
				{' '}
				<div className={classes.title}>
					<Typography variant="body1">
						{translate('label/from')}: {authorName}
					</Typography>
					<Typography
						style={{ color: '#828282de' }}
						className={classes.date}
					>
						{date.toDate().toLocaleString('pt-br')}
					</Typography>
				</div>
				{actionDone !== 0 && (
					<ActionChip
						action={Utils.actionDone[actionDone]}
						label={`${translate('label/applied-action-type')}:`}
					/>
				)}
				{actionDoneIn !== 0 && (
					<ActionChip
						action={Utils.actionDoneIn[actionDoneIn]}
						label={`${translate('label/action-applied-at')}:`}
					/>
				)}
				{actionObservation && (
					<Typography className={classes.observation}>
						{actionObservation}
					</Typography>
				)}
			</div>
		) : (
			<Loader internal />
		)
	}
}

export default compose(withStyles(Style.response))(Response)
