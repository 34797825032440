import React from 'react'
import DoughnutGraph from '../doughnutGraph'

const PieChart = ({ perc, display, callbacks }) => (
	<DoughnutGraph
		items={perc}
		callbacks={callbacks}
		display={display}
		cutoutPercentage={0}
	/>
)

export default PieChart
