export const SET_ROOT_DASH_ITEM = 'SET_ROOT_DASH_ITEM',
	SET_ROOT_DASH_ITEM_LOADING = 'SET_ROOT_DASH_ITEM_LOADING'

/**
 * [action-creator] for **root** dashItem data loading state **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The current loaded dashItemId
 * @param {boolean} isLoading The current is-loading state
 */
export const setRootDashItemLoading = (dashItemId, isLoading) => ({
	type: SET_ROOT_DASH_ITEM_LOADING,
	dashItemId,
	isLoading
})

/**
 * [action-creator] for **root** dashItem info loaded dispatch
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The current loaded dashItemId
 * @param {Object} dashItem The current loaded dashItem info
 */
export const setRootDashItem = (dashItemId, dashItem) => ({
	type: SET_ROOT_DASH_ITEM,
	dashItemId,
	dashItem
})
