import axios from 'axios'
import baseURL from './baseURL'

/**
 * Function for sending request to cloud functions that sends email
 * TODO: generalize function, now is only used for bugReporting
 *
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Changed the reference to the service and file location
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {*} transporterType the transporter type, Ex:. bugReporter
 * @param {*} text the text to send on message
 */
const sendMail = (transporterType, text) => {
	axios.post(`${baseURL}/sendMail`, {
		transporterType: transporterType,
		text: text
	})
}

export default sendMail
