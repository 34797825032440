const styles = (theme) => ({
	saveButton: {
		flex: '0 0 auto'
	},
	saveButtonIcon: {
		fontSize: 32
	}
})

export default styles
