import React from 'react'
import PropTypes from 'prop-types'
import { translate } from '../../../../../utils/lang'
import {
	FormControl,
	InputLabel,
	Select,
	Typography,
	MenuItem,
	withStyles
} from '@material-ui/core'
import Loader from '../../../loader'
import styles from './styles'

/**
 * Company selector form.
 * Will display companies list based on the current logged in user.
 * Only provides access to companies which user has account at.
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 */
const CompanySelectForm = (props) => {
	const {
		activeCompanyId,
		classes,
		companies,
		companyKeys,
		companiesLoading,
		fullWidth,
		handleChange
	} = props

	return (
		<div className={!!fullWidth ? classes.rootFullWidth : classes.root}>
			{!!companiesLoading && <Loader internal noPaper />}
			{!companiesLoading && !!companies && companyKeys.length === 1 && (
				<Typography variant="body1" className={classes.activatingText}>
					{translate('label/activating-your-unique-company', {
						companyName: companies[companyKeys[0]].abbrev
					})}
				</Typography>
			)}
			{!companiesLoading && !!companies && companyKeys.length > 1 && (
				<FormControl fullWidth className={classes.formControl}>
					<InputLabel htmlFor="select-company-id">
						{translate('label/company')}
					</InputLabel>
					<Select
						value={!!activeCompanyId ? activeCompanyId : ''}
						onChange={handleChange('select-company-id')}
						fullWidth
						inputProps={{
							name: 'select-company-id',
							id: 'select-company-id'
						}}
					>
						<MenuItem value="" />
						{!!companies &&
							companyKeys.map((key, index) => {
								return (
									<MenuItem key={key} value={key}>
										{companies[key].abbrev}
									</MenuItem>
								)
							})}
					</Select>
				</FormControl>
			)}
		</div>
	)
}

/**
 * In this form we need to lift state up -
 * So when the company has changed,
 * Someone else (parent) might be notified somehow
 */
CompanySelectForm.propTypes = {
	activeCompanyId: PropTypes.string,
	classes: PropTypes.object,
	companies: PropTypes.object,
	companyKeys: PropTypes.array,
	companiesLoading: PropTypes.bool,
	fullWidth: PropTypes.bool
}

export default withStyles(styles)(CompanySelectForm)
