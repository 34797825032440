import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'

/**
 * Manages to _organise elements_ within tabs, for working along with **Tabs system**
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {{classes: Object, children: React.Node, noSpacing: Boolean}} props The props acceptable by this component
 */
const TabContainer = (props) => {
	const { classes, children, noSpacing } = props

	return (
		<Typography
			component="div"
			className={!!noSpacing ? classes.rootNoSpacing : classes.root}
		>
			{children}
		</Typography>
	)
}

TabContainer.defaultProps = {
	noSpacing: false
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
	noSpacing: PropTypes.bool
}

export default withStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit
	},
	rootNoSpacing: {
		paddingBottom: theme.spacing.unit
	}
}))(TabContainer)
