export const MANAGE_NEW_MONITORING_LOADING_STATE = 'MANAGE_NEW_MONITORING_LOADING_STATE',
    LOAD_NEW_MONITORING_DATA = 'LOAD_NEW_MONITORING_DATA',
    SET_NEW_MONITORING_PERIOD = 'SET_NEW_MONITORING_PERIOD',
    SET_NEW_MONITORING_VALUE = 'SET_NEW_MONITORING_VALUE'

export const setNewMonitoringPeriod = (dateConfig, value, formType) => ({
    type: SET_NEW_MONITORING_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadNewMonitoringData = (newMonitoring) => ({
    type: LOAD_NEW_MONITORING_DATA,
    newMonitoring
})

export const setLoading = (isLoading) => ({
    type: MANAGE_NEW_MONITORING_LOADING_STATE,
    isLoading
})

export const newMonitoringValue = (text) => ({
    type: SET_NEW_MONITORING_VALUE,
    text
})