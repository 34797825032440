import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Icon, Fab } from '@material-ui/core'
import styles from './styles'
import { translate } from '../../../../utils/lang'
import ButtonContainer from '../ButtonContainer'

/**
 * Standard system more button
 *
 * @description default more button
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Removed previous button and implemented Fab component
 * @author brunoteixeirasilva
 * @version 1.1
 */
class MoreButton extends React.Component {
	state = {
		variant: 'round',
		color: 'secondary',
		ariaLabelDefault: translate('label/more'),
		icon: 'more_vert'
	}

	handleClick = (event) => {
		const { action } = this.props

		//Stops event propagation
		event.preventDefault()

		return action(event)
	}
	render() {
		const { variant, color, ariaLabelDefault, icon } = this.state
		const { classes, disabled, ariaLabel } = this.props

		return (
			<ButtonContainer>
				<Fab
					variant={variant}
					disabled={disabled}
					className={classes.moreButton}
					color={color}
					size="small"
					aria-label={!!ariaLabel ? ariaLabel : ariaLabelDefault}
					onClick={this.handleClick}
				>
					<Icon className={classes.moreButtonIcon}>{icon}</Icon>
				</Fab>
			</ButtonContainer>
		)
	}
}
MoreButton.propTypes = {
	ariaLabel: PropTypes.string,
	action: PropTypes.func.isRequired
}
export default withStyles(styles)(MoreButton)
