import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { withStyles } from '@material-ui/core'

import { beforeDraw } from './functions'

import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'

const styles = (theme) => ({
	graph: {
		marginTop: theme.spacing.unit,
		minHeight: '200px',
		maxHeight: '500px',
		...getDesktopBreakpoint(theme, {
			minHeight: '300px'
		})
	}
})

class DoughnutGraph extends React.Component {
	getLabel = (tooltipItems, data) => {
		if (!this.props.callbacks.label) return
		return this.props.callbacks.label(tooltipItems, data)
	}

	getAfterLabel = (tooltipItems, data) => {
		if (!this.props.callbacks.afterLabel) return

		return this.props.callbacks.afterLabel(
			tooltipItems,
			data,
			this.props.items
		)
	}
	getCenterText = (chart) => {
		const { hasCenter, items, prefix, sufix } = this.props
		if (!hasCenter) return
		beforeDraw(chart, items.map((item) => item.value), prefix, sufix)
	}
	render() {
		const { items, colors, callbacks, title, cutoutPercentage } = this.props

		return (
			<Doughnut
				data={{
					labels: items.map((item) => item.label),
					datasets: [
						{
							backgroundColor: !!colors
								? colors
								: ['#fe7502', '#3373ca', '#da6161'],
							data: items.map((item) => item.perc)
						}
					]
				}}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					elements: {
						center: {
							fontStyle: 'Helvetica', //Default Arial
							sidePadding: 15 //Default 20 (as a percentage)
						},
						arc: {
							borderWidth: 0.5
						}
					},
					legend: {
						display: !!this.props.display,
						position: 'bottom'
					},
					title: {
						text: title,
						position: 'top',
						display: !!title,
						fontStyle: 'normal'
					},
					tooltips: !!callbacks
						? {
								enabled: true,
								callbacks: {
									label: !!callbacks.label
										? (tooltipItems, data) => {
												return this.getLabel(
													tooltipItems,
													data
												)
										  }
										: null,
									afterLabel: !!callbacks.afterLabel
										? (tooltipItems, data) => {
												return this.getAfterLabel(
													tooltipItems,
													data
												)
										  }
										: null
								}
						  }
						: null,
					cutoutPercentage:
						!!cutoutPercentage || cutoutPercentage === 0
							? cutoutPercentage
							: 65,
					plugins: {
						datalabels: {
							color: '#FFFFFF',
							backgroundColor: 'rgba(115, 128, 138, 0.7)',
							display: true,
							formatter: (value, context) => {
								let percent = value + '%',
									total = items[context.dataIndex].value + '\n'

								return total + percent
							}

						}
					}
				}}
				plugins={[{ beforeDraw: (chart) => this.getCenterText(chart) }]}
			/>
		)
	}
}

export default withStyles(styles)(DoughnutGraph)
