import { fs } from '../firebase'
import { lastSuccessUpdateDate } from '../../services/cloud/functions/sigs/lastSuccessUpdateDate'
import moment from 'moment'

function getLastSuccessUpdateDate(dataSource) {
	return new Promise((resolve, reject) => {
		lastSuccessUpdateDate({}, dataSource, 'lastSuccessUpdateDate')
			.then(resultSet => {
				if (!!resultSet.data[0].lastSuccessDate) {
					let test = resultSet.data[0].lastSuccessDate
					resolve(test)
				}
			})
			.catch(e => {
				console.log('Error founded at searching in database: ', e)
			})
	})
}

/**
 * [Firestore] Gets an array of plugins based on their push IDs
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Array} plugins Array/Collection of company-plugins
 */
export const getPlugins = plugins => {
	//let r = db.ref(`/plugins`)
	let r = fs.collection(`plugins`)
	//TODO: Queries for only { active: true } plugins
	// .orderByChild('active')
	// .equalTo(true)

	const promises = plugins.map(pluginItem => r.doc(pluginItem.id).get())

	return Promise.all(promises)
}

/**
 * [Firestore] Will get (active) companyPlugin IDs based on a passed company
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The company ID to look for plugins
 * @param {bool} includeInactive If the query shoud look for inactive plugins also
 */
export const getAllByCompany = (companyId, uid, includeInactive = false) => {
	let companyPlugins = fs
		.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.collection('plugins')

	if (process.env.REACT_APP_NODE_ENV === 'production')
		if (!includeInactive) {
			//Shouldn't include inactive ones
			companyPlugins = companyPlugins.where('active', '==', true)
		}

	return companyPlugins
		.get()
		.then(dataSnapshot => getPlugins(dataSnapshot.docs))
}

export const updateDatesOnMainPlugins = (
	companyId,
	pluginId,
	lastUpdateDate,
) => {
	fs.collection('companies')
		.doc(companyId)
		.collection('plugins')
		.doc(pluginId)
		.set(
			{
				lastUpdate: lastUpdateDate,
				companyId: companyId,
			},
			{ merge: true },
		)
}

export const updateDatesOnOtherPlugins = (
	companyId,
	uid,
	pluginId,
	lastUpdateDate,
) => {
	fs.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.collection('plugins')
		.doc(pluginId)
		.set(
			{
				lastUpdate: lastUpdateDate,
				companyId: companyId,
			},
			{ merge: true },
		)
}

export const updateCompanyIdOnPlugins = (companyId, uid, pluginId) => {
	fs.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.collection('plugins')
		.doc(pluginId)
		.set(
			{
				companyId: companyId,
			},
			{ merge: true },
		)
}

export const onGetAllByCompany = (companyId, uid, thenFunc) => {
	let pluginList = {},
		pluginSubscription = {
			pluginList: null,
			byPlugin: {},
		}

	pluginSubscription.pluginList = fs
		.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.collection('plugins')
		.onSnapshot(querySnapshot => {
			querySnapshot.docChanges().forEach(docChange => {
				let pluginId = docChange.doc.id
				let dados = docChange.doc.data()

				if (
					(dados && dados.dataSource === 'appAudit') ||
					dados.dataSource === 'sigs'
				) {
					getLastSuccessUpdateDate(dados.dataSource).then(function (
						res,
					) {
						if (!!dados.lastUpdate && !!res) {
							let lastUpdateFromFirebase = moment(
								dados.lastUpdate,
							).format('DD/MM/YYYY HH:mm')
							let lastUpdateFromDatabase =
								moment(res).format('DD/MM/YYYY HH:mm')

							if (
								lastUpdateFromDatabase !==
								lastUpdateFromFirebase
							) {
								updateDatesOnOtherPlugins(
									companyId,
									uid,
									pluginId,
									res,
								)
								updateDatesOnMainPlugins(
									companyId,
									pluginId,
									res,
								)
							}
						} else if (!!res && !dados.lastUpdate) {
							updateDatesOnOtherPlugins(
								companyId,
								uid,
								pluginId,
								res,
							)
							updateDatesOnMainPlugins(companyId, pluginId, res)
						}
					})
				}

				if (dados && !dados.companyId) {
					updateCompanyIdOnPlugins(companyId, uid, pluginId)
				}

				switch (docChange.type) {
					case 'added':
						pluginList = Object.assign({}, pluginList, {
							[docChange.doc.id]: dados,
						})

						break
					case 'modified':
						pluginList[docChange.doc.id] = Object.assign(
							{},
							pluginList[docChange.doc.id],
							{ ...dados },
						)

						break
					case 'removed':
						break
					default:
						break
				}
			})
			thenFunc(pluginList)
		})
}

/**
 * [Firestore] Will get (active) companyPlugin IDs based on a passed company
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The company ID to look for plugins
 */
export const getByCompany = companyId => {
	return getAllByCompany(companyId, false)
}

/**
 * [Firestore] Effectively updates a plugin (name, only) based on a plugin Id
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} pluginId The current plugin Id
 * @param {Object} data The data being added/updated
 */
export const doUpdate = (pluginId, data) => {
	return fs
		.collection('plugins')
		.doc(pluginId)
		.update({ active: data.active, name: data.name })
}

/**
 * [Firestore] Effectively **set** a (new/existent) plugin object, based on its plugin-Id
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} pluginId The current plugin Id
 * @param {Object} data The data being added/updated
 */
export const doSet = (pluginId, data) => {
	const docRef =
		undefined === pluginId || pluginId === null
			? fs.collection('plugins').doc()
			: fs.collection('plugins').doc(pluginId)

	return docRef.set({ active: data.active, name: data.name, type: data.type })
}
