import { createSelector } from 'reselect'

/**
 * Gets an active-plugin from the state-tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current store state
 */
export const getActivePlugin = (state) =>
	!!state.pluginState.activePlugin ? state.pluginState.activePlugin : null

/**
 * [memoised] Gets an active-plugin from the state-tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current store state
 */
export const makeGetActivePlugin = () =>
	createSelector(
		getActivePlugin,
		(activePlugin) => activePlugin
	)

/**
 * Gets an active-plugin-key from the state-tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current store state
 */
export const getActivePluginId = (state) => {
	const { activePlugin } = state.pluginState
	const pluginIds = !!activePlugin && Object.keys(activePlugin)

	return !!activePlugin && pluginIds.length > 0 ? pluginIds[0] : null
}

/**
 * [memoised] Gets an active-plugin ID from the state-tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current store state
 */
export const makeGetActivePluginId = () =>
	createSelector(
		getActivePluginId,
		(activePluginId) => activePluginId
	)
