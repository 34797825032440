import React from 'react'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
} from '@material-ui/core'
import { translate } from '../../../utils/lang'

/**
 * ??
 *
 * @description Created the component
 * @author ??
 * @version 1.0
 *
 * @param {Object} props
 */
const CardInfo = props => {
	const { description, handleClose, open, title } = props
	return (
		<Dialog
			open={open}
			PaperProps={{ style: { marginRight: '16px', marginLeft: '16px' } }}>
			<DialogTitle id="simple-dialog-title">{title}</DialogTitle>
			<DialogContent style={{ fontSize: '14px' }}>
				{description}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose('info')
					}}
					color="primary">
					{translate('label/close')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CardInfo
