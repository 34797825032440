import { createSelector } from 'reselect'

/**
 * Gets an _user-active-company_ **ID** from an state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current store state
 */
export const getUserActiveCompanyId = state => {
	const companyId = state.userCompanyState.activeCompanyId

	return companyId || null
}
/**
 * [memoised] Selects an _user-active-company_ **ID** from an state
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetUserActiveCompanyId = () =>
	createSelector(getUserActiveCompanyId, activeCompanyId => activeCompanyId)

/**
 * Gets an _user-active-company_ **object** from an state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current store state
 */
export const getUserActiveCompany = state =>
	state.userCompanyState.companies ? state.userCompanyState.companies : null

/**
 * [memoised] Selects an _user-active-company_ **object** from an state
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetUserActiveCompany = () =>
	createSelector(
		getUserActiveCompanyId,
		getUserActiveCompany,
		(activeCompanyId, companies) => {
			let company

			if (!activeCompanyId || !companies || companies.length === 0)
				return null

			// Filtering companies list by ID
			company = companies.filter(
				company => company.id === activeCompanyId,
			)

			return company.length > 0 ? company[0] : null
		},
	)
