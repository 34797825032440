/**
 * Will retrieve the radial-gradient color based on a percent
 *
 * @param {Number} value Starting from 0 to N%
 */
export const getColor = (value) => {
	if (value > 200) {
		return 'radial-gradient(#e28441, #e86919)'
	} else if (value >= 100 && value < 200) {
		return 'radial-gradient(#37a761, #459e20)'
	} else if (value < 100 && value >= 90) {
		return 'radial-gradient(#d3b42a, #fcda6c)'
	} else if (value < 90) {
		return 'radial-gradient(#e54e55, #e5373f)'
	}
}

/**
 * Will retrieve the color based on a percent
 *
 * @param {Number} value Starting from 0 to N%
 */
export const getSimpleColor = (value) => {
	if (value > 200) {
		return 'rgba(172, 3, 198, 1.0)'
	} else if (value >= 100 && value < 200) {
		return 'rgba(69, 158, 32, 1.0)'
	} else if (value < 100 && value >= 90) {
		return 'rgba(209, 175, 41, 1.0)'
	} else if (value < 90) {
		return 'rgba(255, 35, 25, 1.0)'
	}
}
