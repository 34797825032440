import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { translate } from '../../../../../utils/lang'
import withActiveCompany from '../../../withActiveCompany'
import ExtendedDialog from '../../../dialog/extended'
import * as UserActions from '../../../../../firebase/actions/userActions'
import { setEvaluationDialogClose } from '../../../../../reducers/systemEvaluation/dialog'
import makeGetActionWasEvaluated from '../../../../../selectors/action/evaluation/action'

/**
 * Holds the evaluation components structure
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added support for fullscreen at the extended dialog
 * @author brunoteixeirasilva
 * @version 1.1
 */
class EvaluationDialogContainer extends React.Component {
	componentDidMount() {
		this.handleOpenEvaluation()
	}

	handleOpenEvaluation = () => {
		const { actionId, setActiveAction, activeCompanyId } = this.props

		UserActions.loadActionData(activeCompanyId, actionId)
			.then((data) => setActiveAction(data))
			.catch(() => {
				console.lor('erro') // FIXME: does something here
			})
	}

	render() {
		const {
			isOpen,
			handleClose,
			children,
			wasEvaluated,
			withScroll
		} = this.props

		return (
			<ExtendedDialog
				fullScreen={false}
				withScroll={withScroll}
				title={
					wasEvaluated
						? translate('label/action-rating')
						: translate('label/evaluate-context', {
								context: 'ação'
						  })
				}
				parentOpen={isOpen}
				parentClose={handleClose}
			>
				{children}
			</ExtendedDialog>
		)
	}
}

const makeMapStateToProps = () => {
	const getWasEvaluated = makeGetActionWasEvaluated()

	const mapStateToProps = (state, ownProps) => ({
		isOpen: state.evaluationDialogState.isOpen,
		// actionId: state.actionsState.actionId,
		wasEvaluated: getWasEvaluated(state, ownProps)
	})

	return mapStateToProps
}
const mapDispatchToProps = (dispatch, ownProps) => ({
	handleClose: () => {
		ownProps.setActiveActionId('list')
		return dispatch(setEvaluationDialogClose())
	},
	setActiveAction: async (activeAction) =>
		dispatch({ type: 'SET_ACTIVE_ACTION', activeAction })
})

export default compose(
	withActiveCompany,
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(EvaluationDialogContainer)
