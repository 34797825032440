import { createSelector } from 'reselect'

/**
 * Gets the **global** filters-search _text_ collections from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getGlobalFiltersSearchText = (state) =>
	state.globalFiltersSearchState.text

/**
 * Gets the **global** filters-search _text_ collections from state, filtering for specific dataSetId
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const getGlobalFiltersDataSetSearchText = (state, dataSetId) => {
	const textValues = getGlobalFiltersSearchText(state),
		text = !!textValues[dataSetId] ? textValues[dataSetId] : ''

	return text
}

/**
 * [Memoised] Gets the **global** filters-search _text_ collections from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFiltersSearchText = () =>
	createSelector(
		getGlobalFiltersDataSetSearchText,
		(text) => text
	)
