import React from 'react'
import { connect } from 'react-redux'
import * as filters from '../../../constants/filters'
import * as globalFilter from '../../../firebase/globalFilter'
import withActiveCompany from '../withActiveCompany'
import { compose } from 'recompose'
import {
	makeGetGlobalFilterIsLoading,
	makeGetGlobalFilterKeys,
	makeGetGlobalFilterValues
} from '../../../selectors/filter'
import {
	setGlobalFiltersLoading,
	setGlobalSelectiveFilters
} from '../../../actions/filter/global'

/**
 * @description Wrapper for all components that use global filters
 * @author wegner
 * @version 1.0
 *
 * @description Added support for specific filters
 * @author spindola
 * @version 1.1
 *
 * @description Changed selective-filters prop-name to match more of a sense
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @description Removed useless code and memoised mapStateToProps + chained promises for data-treatment
 * @author brunoteixeirasilva
 * @version 1.3
 *
 * @description Resolved an issue with memory leak by means of tips from
 * 				**https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html**
 * @author brunoteixeirasilva
 * @version 1.4
 *
 * @param {React.Component|React.PureComponent} Component The component using filters
 * @param {Array} selectiveFilters [Optional] The filters which should be enabled. Defaults to *All
 */
const withFilters = (selectiveFilters = [...filters.ALL_FILTERS]) => (
	Component
) => {
	class WithFilters extends React.Component {
		_isMounted = false

		state = {
			snapshot: null,
			shouldUpdate: false
		}

		componentDidMount() {
			const { setGlobalSelectiveFilters, isLoading } = this.props
			//Will set the list of global active filters
			setGlobalSelectiveFilters(selectiveFilters)

			//Sets component officially mounted
			this._isMounted = true

			if (!isLoading) {
				//Will load system labels
				this.loadGlobalLabels()
			}
		}

		// Will fetch labels for global filters then will set the prop in global reducer
		loadGlobalLabels = () => {
			const {
					activeCompanyId,
					filterValues,
					setLoading
				} = this.props,
				self = this

			//Sets it to loading and gets all filter labels
			//Manages promise resolving or failure
			setLoading(true)
				.then(() => globalFilter.getAll(activeCompanyId))
				.then((snapshot) => {
					//Iterates at each company filter for keeping their
					//Names and keys at redux-state portion

					return Promise.resolve(snapshot)
				})
				.then((snapshot) => {
					//Reference is still mounted
					return !!self._isMounted
						? //Generates a snapshot for the filter values collection
						  self.setState({
								snapshot: JSON.stringify(filterValues)
						  })
						: Promise.reject('unmounted component')
				})
				.then(() => setLoading(false))
				.catch(() => setLoading(false))
		}

		getSnapshotBeforeUpdate(prevProps, prevState) {
			const jsonFilterValues = JSON.stringify(this.props.filterValues)

			//Filter data was changed, then, snapshot is generated
			if (jsonFilterValues !== prevState.snapshot) {
				return jsonFilterValues
			}

			return null
		}

		componentDidUpdate(prevProps, prevState, snapshot) {
			const self = this

			//When a snapshot is generated, filters were changed, and still mounted
			if (!!snapshot && !!self._isMounted) {
				self.setState({ snapshot, shouldUpdate: true })
			} else if (
				!snapshot &&
				!!self.state.shouldUpdate &&
				!!self._isMounted
			) {
				//When no snapshot is generated, though it is still set to "shouldUpdate" => true, and still mounted
				//Resets this state
				self.setState({ shouldUpdate: false })
			}
		}

		componentWillUnmount() {
			//Necessary for tracking when states should not be set and leaked
			this._isMounted = false
		}

		render() {
			const { shouldUpdate } = this.state

			return <Component shouldUpdate={shouldUpdate} {...this.props} />
		}
	}

	const makeMapStateToProps = () => {
		const getFilterIsLoading = makeGetGlobalFilterIsLoading(),
			getFilterValues = makeGetGlobalFilterValues(),
			getFilterKeys = makeGetGlobalFilterKeys()

		const mapStateToProps = (state) => ({
			filterValues: getFilterValues(state),
			filterKeys: getFilterKeys(state),
			isLoading: getFilterIsLoading(state)
		})

		return mapStateToProps
	}

	const mapDispacthToProps = (dispatch) => ({
		setGlobalSelectiveFilters: (selectiveFilters) => dispatch(setGlobalSelectiveFilters(selectiveFilters)),
		setLoading: async (isLoading) => dispatch(setGlobalFiltersLoading(isLoading))
	})

	return compose(
		withActiveCompany,
		connect(
			makeMapStateToProps(),
			mapDispacthToProps
		)
	)(WithFilters)
}

export default withFilters
