export const MANAGE_MEASUREMENT_LOADING_STATE =
		'MANAGE_MEASUREMENT_LOADING_STATE',
	LOAD_MEASUREMENT_DATA = 'LOAD_MEASUREMENT_DATA',
	SET_MEASUREMENT_PERIOD = 'SET_MEASUREMENT_PERIOD'

export const setMeasurementPeriod = (dateConfig, value, formType) => ({
	type: SET_MEASUREMENT_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadMeasurementData = (measurement) => ({
	type: LOAD_MEASUREMENT_DATA,
	measurement
})

export const setLoading = (isLoading) => ({
	type: MANAGE_MEASUREMENT_LOADING_STATE,
	isLoading
})
