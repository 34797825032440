import {
	SET_PRODUCTIVITY_CYCLES,
	SET_PRODUCTIVITY_CYCLES_LOADING,
	CLEAR_PRODUCTIVITY_CYCLES
} from '../../../actions/productivity/cycle'

const INITIAL_STATE = {
	items: null,
	isLoading: false
}

/**
 * Provides access to cycle data
 *
 * @author brunoteixerasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and specific props
 */
function productivityCycleReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_PRODUCTIVITY_CYCLES_LOADING: {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		case SET_PRODUCTIVITY_CYCLES: {
			return Object.assign({}, state, {
				items: action.productivityCycles,
				isLoading: INITIAL_STATE.isLoading
			})
		}
		case CLEAR_PRODUCTIVITY_CYCLES: {
			return Object.assign({}, state, {
				items: INITIAL_STATE.items
			})
		}
		default:
			return state
	}
}

export default productivityCycleReducer
