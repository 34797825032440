const INITIAL_STATE = {
	formData: {
		email: '',
	},
}

function signInFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'STORE_SIGN_IN_DATA': {
			return { ...state, formData: action.formData }
		}
		case 'CLEAR_SIGN_IN_DATA': {
			return { ...state, ...INITIAL_STATE }
		}
		default:
			return state
	}
}

export default signInFormReducer
