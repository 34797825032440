export const MANAGE_WORK_LIST_LOADING_STATE =
	'MANAGE_WORK_LIST_LOADING_STATE',
	LOAD_WORK_LIST_DATA = 'LOAD_WORK_LIST_DATA',
	SET_WORK_LIST_PERIOD = 'SET_WORK_LIST_PERIOD'

export const setWorkListPeriod = (dateConfig, value, formType) => ({
	type: SET_WORK_LIST_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadWorkListData = (workList) => ({
	type: LOAD_WORK_LIST_DATA,
	workList
})

export const setLoading = (isLoading) => ({
	type: MANAGE_WORK_LIST_LOADING_STATE,
	isLoading
})