import React from 'react'
import PropTypes from 'prop-types'
import MoreButton from '../MoreButton'
import { ContextMenu } from '../../../menu/context'

/**
 * Component for managing a button which has more options (defineable)
 *
 * @description Created the component
 * @author wegner
 * @version 1.0
 */
class ExpandableButton extends React.Component {
	state = {
		elementOpeningMenu: null
	}

	openContextMenu = (event) => {
		this.setState({
			elementOpeningMenu: event.currentTarget
		})
	}

	closeContextMenu = (event) => {
		this.setState({
			elementOpeningMenu: null
		})
	}

	render() {
		const { elementOpeningMenu } = this.state
		const { options } = this.props
		return (
			<div>
				<MoreButton action={this.openContextMenu} />
				<ContextMenu
					id="group-user-menu"
					anchorElement={elementOpeningMenu}
					open={!!elementOpeningMenu}
					options={options}
					onClose={this.closeContextMenu}
				/>
			</div>
		)
	}
}

ExpandableButton.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			disabled: PropTypes.bool,
			key: PropTypes.string,
			label: PropTypes.string,
			action: PropTypes.func
		})
	).isRequired
}

export default ExpandableButton
