import {
	MANAGE_MAP_COVID_LOADING_STATE,
	LOAD_MAP_COVID_DATA,
	LOAD_MAP_COVID_DATA_FILTERS,
	SET_MAP_COVID_PERIOD,
} from '../../actions/mapCovid'

const INITIAL_STATE = {
	isLoading: null,
	mapCovid: null,
	mapCovidFilters: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		object: '',

		stats: '',
		form: '',
		colaborator: '',
		audit_id: '',
		col_status: '',
		col_is_auditor: '',
		question_has_nc: '',
		subGrupo: '',
		cargo: '',
		area: '',
		vip: '',
		diretoriaExecutiva: '',
		diretoria: '',
		gerenciaSenior: '',
		gerencia: '',
		coordenacao: '',
		supervisao: '',
		filial: '',
	},
}
/**
 * ranking audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */

const mapCovidReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_MAP_COVID_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case LOAD_MAP_COVID_DATA: {
			return Object.assign({}, state, {
				mapCovid: action.mapCovid,
				isLoading: false,
			})
		}
		case LOAD_MAP_COVID_DATA_FILTERS: {
			return Object.assign({}, state, {
				mapCovidFilters: action.mapCovidFilters,
				isLoading: false,
			})
		}
		case 'SET_MAP_COVID_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_MAP_COVID_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_MAP_COVID_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		case SET_MAP_COVID_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value,
						},
					),
				},
				isLoading: true,
			})
		}
		default:
			return state
	}
}

export default mapCovidReducer
