class Action {
	constructor(
		company = null,
		receiver = null,
		ccReceivers = null,
		content = null,
		expectedDate = null
	) {
		this.companyId = company
		this.receiver = receiver
		this.ccReceivers = ccReceivers
		this.content = {
			type: content.type,
			classification: content.classification,
			observation: content.observation
		}
		this.responsesCount = 0
		this.active = true
		this.expectedDate = expectedDate
	}
}

export default Action
