import {
	PERFORMANCE_DATA_LOADED,
	PERFORMANCE_SUMMARY_DATA_LOADED,
	SET_PERFORMANCE_DATA_LOADING,
} from '../../actions/operationalPerformance'

const SET_PERFORMANCE_DATA_DATE_CONFIG = 'SET_PERFORMANCE_DATA_DATE_CONFIG',
	CLEAR_PERFORMANCE_DATA = 'CLEAR_PERFORMANCE_DATA'

const INITIAL_STATE = {
	summary: null,
	data: null,
	dateConfig: {},
	isLoading: false,
	ownFilters: {},
	searchText: {
		equipeTipo: '',
		equipeNome: '',
		statusEquipe: '',
		contrato: '',
		nomeCiclo: '',
	},
}

/**
 * Applies a data to the reducer state portion
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree portion for this reducer
 * @param {Object} action The action with "type" and desired props
 */
const applyOperationalPerformanceLoading = (state, action) =>
	Object.assign({}, state, {
		isLoading: action.isLoading,
	})

/**
 * Applies a data to the reducer state portion
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree portion for this reducer
 * @param {Object} action The action with "type" and desired props
 */
const applyOperationalPerformanceData = (state, action) =>
	Object.assign({}, state, {
		data: action.data,
	})

/**
 * Applies a data **summary** to the reducer state portion
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree portion for this reducer
 * @param {Object} action The action with "type" and desired props
 */
const applyOperationalPerformanceSummaryData = (state, action) =>
	Object.assign({}, state, {
		summary: action.summary,
	})

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @description Changed to use DateConfig instance on empty initial state
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Changed to use is-loading state, for controlling view loader
 * @author brunoteixeirasilva
 * @version 1.2
 *
 * @description Changed to separate functions, for better readability
 * @author brunoteixeirasilva
 * @version 1.2
 */
const operationalPerformanceReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PERFORMANCE_DATA_LOADING: {
			return applyOperationalPerformanceLoading(state, action)
		}
		case PERFORMANCE_SUMMARY_DATA_LOADED: {
			return applyOperationalPerformanceSummaryData(state, action)
		}
		case PERFORMANCE_DATA_LOADED: {
			return applyOperationalPerformanceData(state, action)
		}
		case SET_PERFORMANCE_DATA_DATE_CONFIG:
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value,
						},
					),
				},
			})
		case 'SET_PERFORMANCE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_PERFORMANCE_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign(
					{},
					state.searchText,
					action.value.toString(),
				),
			})
		}
		case 'CLEAN_PERFORMANCE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		case CLEAR_PERFORMANCE_DATA:
			return Object.assign({}, state, {
				data: INITIAL_STATE.data,
			})
		default:
			return state
	}
}

export default operationalPerformanceReducer

export { SET_PERFORMANCE_DATA_DATE_CONFIG, CLEAR_PERFORMANCE_DATA }
