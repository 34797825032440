import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
	withStyles,
	FormLabel,
	Chip
} from '@material-ui/core'
import styles from './styles'
import { translate } from '../../../../utils/lang'

/**
 * Manages displaying a standard component and rather fragmentation
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added appendable text to tooltip
 * @author brunoteixeirasilva
 * @version 1.1
 */
class SortButton extends React.Component {
	handleClick = (event) => {
		const { onClick, sortList, order } = this.props

		//Changes global list orderer
		sortList(!(order === 'ASC'))

		return !!onClick ? onClick(event) : false
	}

	render() {
		const { classes, label, order } = this.props
		return (
			<React.Fragment>
				<FormLabel>{label}</FormLabel>
				<div className={classes.root}>
						<Chip
							className={classes.chip}
							onClick={this.handleClick}
							color={
								order === 'ASC' ? 'primary' : 'default'
							}
							label={translate('label/crescent')}
						/>
					<Chip
						className={classes.chip}
						onClick={this.handleClick}
						color={!!order && order !== 'ASC' ? 'primary' : 'default'}
						label={translate('label/descending')}
					/>
				</div>
			</React.Fragment>
		)
	}
}

SortButton.defaultProps = {
	tooltipPrepend: null
}

SortButton.propTypes = {
	tooltipPrepend: PropTypes.string
}

const mapDispatchToProps = (dispatch) => ({
	sortList: (trueIfAsc) =>
		dispatch({
			type: 'LIST_PAGINATION_ORDER_BY',
			order: !!trueIfAsc ? 'ASC' : 'DESC'
		})
})

const mapStateToProps = (state, props) => {
	const { tooltipPrepend } = props,
		order = state.listPaginationState.order
	let tooltipText

	//Builds the tooltip text
	tooltipText =
		(!!tooltipPrepend ? tooltipPrepend + ' ' : '') +
		(order === 'ASC'
			? translate('label/ascending')
			: translate('label/descending'))

	return {
		tooltipText: tooltipText,
		order: order
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)(SortButton)
