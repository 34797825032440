import {
		MANAGE_WORK_LIST_LOADING_STATE,
		LOAD_WORK_LIST_DATA,
		SET_WORK_LIST_PERIOD
	} from '../../../actions/workList'
	
const REVERSE_WORK_LIST = 'REVERSE_WORK_LIST',
	RECEIVE_WORK_LIST_WORKS = 'RECEIVE_WORK_LIST_WORKS',
	SET_APPLIED_FILTERS = 'SET_APPLIED_FILTERS'	


const INITIAL_STATE = {
	works: null,
	isLoading: null,
	appliedFilters: null,
	dateConfig: {},
	workList: null,
	ownFilters: {},
	total: 0,
	reverser: false,
	searchText: {
		cliente_nome: '',
		equipe_nome: '',
		obra_nome: '',		
		data_prox_atividade: '',
		data_execucao: '',
	}
}

/**
 * Manages the work-list state portion
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @description Added list reverser
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Removed unused setLength method
 * @author brunoteixeirasilva
 * @version 1.1
 * 
 * @description Added news filters
 * @author lucaslopes
 * @version 2.0
 *
 * @param {*} state
 * @param {*} action
 */
const workListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_WORK_LIST_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_WORK_LIST_DATA: {
			return Object.assign({}, state, {
				workList: action.workList,
				total: action.workList.length,
				isLoading: false
			})
		}
		case 'SET_WORK_LIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_WORK_LIST_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
	
		case 'CLEAN_WORK_LIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case REVERSE_WORK_LIST: {		
			return Object.assign({}, state, {				
				reverser: !state.reverser
			})
		}
		case RECEIVE_WORK_LIST_WORKS: {
			return Object.assign({}, state, {
				works: action.workList,
				isLoading: false,
				
			})
		}
		case SET_APPLIED_FILTERS: {
			return { ...state, appliedFilters: action.appliedFilters }
		}
		case 'CLEAR_WORK_LIST': {
			return INITIAL_STATE
		}
		case SET_WORK_LIST_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default workListReducer

export { SET_APPLIED_FILTERS,
	 RECEIVE_WORK_LIST_WORKS,
	 REVERSE_WORK_LIST,
	 MANAGE_WORK_LIST_LOADING_STATE,
	 LOAD_WORK_LIST_DATA,
	 SET_WORK_LIST_PERIOD
	}
