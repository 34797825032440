import { SET_ACTIVE_PLUGIN } from '../../plugin'

export const SET_ACTIVE_SWIPER = 'SET_ACTIVE_SWIPER',
	HANDLE_DASH_ITEM_RELOAD = 'HANDLE_DASH_ITEM_RELOAD',
	SET_ACTIVE_DASH_SET = 'SET_ACTIVE_DASH_SET',
	SET_ACTIVE_DASH_ITEM = 'SET_ACTIVE_DASH_ITEM',
	SET_DISABLE_TUTORIAL = 'SET_DISABLE_TUTORIAL'

const INITIAL_STATE = {
	showSwipe: false,
	remount: {},
	activeDashSet: null,
	activeDashItem: null,
	showTutorial: true
}

/**
 * dashItem form reducer manager
 * @description Created the function
 * @author lopes
 * @version 1.0
 */

const dashItemViewReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_ACTIVE_SWIPER: {
			return Object.assign({}, state, { showSwipe: action.swipe })
		}
		case SET_DISABLE_TUTORIAL: {
			return Object.assign({}, state, {
				showTutorial: action.showTutorial
			})
		}
		case HANDLE_DASH_ITEM_RELOAD: {
			return Object.assign({}, state, {
				remount: Object.assign({}, state.remount, {
					[action.dashItemKey]: action.value
				})
			})
		}
		case SET_ACTIVE_DASH_SET: {
			return Object.assign({}, state, {
				activeDashSet: action.activeDashSet
			})
		}
		case SET_ACTIVE_DASH_ITEM: {
			return Object.assign({}, state, {
				activeDashItem: Object.assign({}, state.activeDashItem, {
					[action.dashSetKey]: action.activeDashItem
				})
			})
		}
		case SET_ACTIVE_PLUGIN: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default dashItemViewReducer
