import {
	MANAGE_PODIUM_RANKING_AUDITS_LOADING_STATE,
	LOAD_PODIUM_RANKING_AUDITS_DATA,
	SET_PODIUM_RANKING_AUDITS_PERIOD
} from '../../actions/podiumRankingAudits'

const INITIAL_STATE = {
	isLoading: null,
	podiumRankingAudits: null,
	colAudits: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		COL_NOME: '',
		STATUS_ATIVACAO: '',
		Status_Auditoria: '',
		ALOCAÇÃO: ''
	}
}
/**
 * ranking audtis state reducer manager
 * @param {*} state
 * @param {*} action
 */

const podiumRankingAuditsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_PODIUM_RANKING_AUDITS_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_PODIUM_RANKING_AUDITS_DATA: {
			return Object.assign({}, state, {
				podiumRankingAudits: action.podiumRankingAudits
			})
		}
		case 'SET_COL_AUDITS': {
			return Object.assign({}, state, {
				colAudits: action.colAudits,
				isLoading: false
			})
		}
		case 'SET_PODIUM_RANKING_AUDIT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_PODIUM_RANKING_AUDIT_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_PODIUM_RANKING_AUDIT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_PODIUM_RANKING_AUDITS_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default podiumRankingAuditsReducer
