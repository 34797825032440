const INITIAL_STATE = {
	openModal: false,
	infoModal: false
}

/**
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
function dashBoardRedux(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_MODAL_DASH_SET': {
			return Object.assign({}, state, { openModal: action.openModal })
		}
		case 'SET_INFO_MODAL': {
			return Object.assign({}, state, { infoModal: action.infoModal })
		}
		default:
			return state
	}
}

export default dashBoardRedux
