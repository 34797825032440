import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { pickSelectedFilterItem } from '../actions/filter'
import { fetchDataFrom } from './functions/options'
import FilterSelectBox from '../components/FilterSelectBox'
import LoadingFilter from '../components/LoadingFilter'
import {
	makeGetGlobalFiltersDataList,
	makeGetGlobalFiltersDataLoading
} from '../../../../selectors/filter/data'
import { makeGetGlobalFiltersSearchText } from '../../../../selectors/filter/search'
import { makeGetGlobalFilterDataSetValuesNullSafe } from '../../../../selectors/filter'
import { setGlobalFilterSearch } from '../../../../actions/filter/search'
import { filterDataLoading } from '../../../../actions/filter/data'

/**
 * Exposes an optionsSet, which actually is container
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Rewritten selectors access
 * @author brunoteixeirasilva
 * @version 1.1
 */
class OptionsSetContainer extends React.PureComponent {
	componentDidMount() {
		const { filter, fetchFilters, setFilterDataLoading } = this.props

		//Sets the data-list loading
		//Loads the dataSourceId
		//Then finds its filters' data
		setFilterDataLoading(true).then(() => fetchFilters(filter.dataSet))
	}

	onTypeChange = (text) => {
		const { setFilterSearchText } = this.props

		setFilterSearchText(text)
	}

	render() {
		const {
			data,
			filter,
			filterKey,
			isLoading,
			searchText,
			selectedValues,
			updateSelectedFilters
		} = this.props

		return isLoading ? (
			<LoadingFilter />
		) : (
			<FilterSelectBox
				filterKey={filterKey}
				dataSetId={filter.dataSet}
				onSelectItem={updateSelectedFilters}
				onTypeChange={this.onTypeChange}
				options={data}
				optionKey="key"
				optionValue="label"
				searchFieldValue={searchText}
				selectedValues={selectedValues}
			/>
		)
	}
}

OptionsSetContainer.propTypes = {
	filter: PropTypes.shape({
		dataSet: PropTypes.string,
		label: PropTypes.string
	})
}

const makeMapStateToProps = () => {
	const getDataSetSelectedValues = makeGetGlobalFilterDataSetValuesNullSafe(),
		getSearchText = makeGetGlobalFiltersSearchText(),
		getVisibleItems = makeGetGlobalFiltersDataList(),
		getIsLoading = makeGetGlobalFiltersDataLoading()

	const mapStateToProps = (state, { filter }) => {
		const dataSetId = filter.dataSet,
			data = getVisibleItems(state, dataSetId),
			isLoading = getIsLoading(state, dataSetId),
			searchText = getSearchText(state, dataSetId),
			selectedValues = getDataSetSelectedValues(state, dataSetId)

		return {
			isLoading: isLoading,
			searchText: searchText,
			data: data,
			selectedValues: selectedValues
		}
	}
	return mapStateToProps
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchFilters: (dataset) =>
		fetchDataFrom(ownProps.filterKey, dataset).then((action) =>
			dispatch(action)
		),
	updateSelectedFilters: (filterData) =>
		dispatch(
			pickSelectedFilterItem(
				ownProps.filter.dataSet,
				ownProps.filterKey,
				filterData
			)
		),
	setFilterDataLoading: async (isLoading) =>
		dispatch(filterDataLoading(ownProps.filter.dataSet, isLoading)),
	setFilterSearchText: (searchText) =>
		dispatch(setGlobalFilterSearch(ownProps.filter.dataSet, searchText))
})

export default compose(
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(OptionsSetContainer)
