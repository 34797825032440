import _ from 'lodash'
import { auth2, fs } from '../../../firebase/firebase'

/**
 * this functions take id, name, email, status active user, status active notifications and status active action.
 * Get users in firebase.
 *
 * @description take all user in activeCompany
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const requestUserCompany = async (activeCompanyId, setUsersInReducer, setPermissionOrder) => {
	let dataUsers = [],
		users = [],
		docs

	await fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('users')
		.get()
		.then(snapshot => {
			snapshot.forEach(item => {
				docs = item.data()
				dataUsers.push({
					id: item.id,
					status: !!docs.active ? true : false,
					actions: !!docs.actions ? true : false,
					download: !!docs.download ? true : false,
					upload: !!docs.upload ? true : false,
					log: !!docs.log ? true : false,
					notification: !!docs.notification ? true : false,
				})
			})
		})

	requestUser().then(async response => {
		response.forEach(item => {
			dataUsers.forEach(it => {
				if (item.id === it.id) {
					users.push(
						Object.assign({}, item, {
							status: item.status,
							actions: it.actions,
							download: it.download,
							upload: it.upload,
							log: it.log,
							notification: it.notification,
						})
					)
				}
			})
		})

		takeAcessLog(users, setUsersInReducer)
		setPermissionOrder(true)
	})
}

export const requestUser = async () => {
	let users = [],
		docs

	await fs
		.collection('users')
		.get()
		.then(snap => {
			snap.forEach(it => {
				docs = it.data()

				users.push({
					id: it.id,
					status: docs.active,
					name: docs.name,
					email: docs.email,
					loginType: docs.signInType,
				})
			})
		})

	return users
}

/**
 * @description alter user name or email according typeData
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const updateUserData = (upDate, user, typeData, activeCompanyId, author) => {
	switch (typeData) {
		case 'name':
			return fs
				.collection('users')
				.doc(user.id)
				.update({
					name: upDate,
				})
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})
		case 'email':
			return fs
				.collection('users')
				.doc(user.id)
				.update({
					email: upDate,
				})
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})
		case 'status':
			return fs
				.collection('users')
				.doc(user.id)
				.update({
					active: upDate,
				})
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})
		case 'actions':
			return fs
				.collection('companies')
				.doc(activeCompanyId)
				.collection('users')
				.doc(user.id)
				.set(
					{
						actions: upDate,
					},
					{ merge: true }
				)
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})
		case 'notification':
			return fs
				.collection('companies')
				.doc(activeCompanyId)
				.collection('users')
				.doc(user.id)
				.set(
					{
						notification: upDate,
					},
					{ merge: true }
				)
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})
		case 'download':
			return fs
				.collection('companies')
				.doc(activeCompanyId)
				.collection('users')
				.doc(user.id)
				.set(
					{
						download: upDate,
					},
					{ merge: true }
				)
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})
		case 'upload':
			return fs
				.collection('companies')
				.doc(activeCompanyId)
				.collection('users')
				.doc(user.id)
				.set(
					{
						upload: upDate,
					},
					{ merge: true }
				)
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})
		case 'log':
			return fs
				.collection('companies')
				.doc(activeCompanyId)
				.collection('users')
				.doc(user.id)
				.set(
					{
						log: upDate,
					},
					{ merge: true }
				)
				.then(() => {
					addUserAlteration(author, upDate, user.id, activeCompanyId, typeData)
				})
				.catch(error => {
					console.error('Error updating document: ', error)
				})

			default:
				return
	}
}

/**
 * @description create document to expecify add user in collection userAlteration
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const addUserAlteration = (author, update, userId, activeCompanyId, type) => {
	switch (type) {
		case 'name':
			return fs
				.collection('logs')
				.doc()
				.set({
					newName: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: type,
				})
				.catch(error => {
					console.error('Error to adding name document: ', error)
				})
		case 'email':
			return fs
				.collection('logs')
				.doc()
				.set({
					newEmail: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: type,
				})
				.catch(error => {
					console.error('Error to adding email document: ', error)
				})
		case 'status':
			return fs
				.collection('logs')
				.doc()
				.set({
					newStatus: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: type,
				})
				.catch(error => {
					console.error('Error to adding status document: ', error)
				})
		case 'actions':
			return fs
				.collection('logs')
				.doc()
				.set({
					newAction: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: type,
				})
				.catch(error => {
					console.error('Error to adding actions document: ', error)
				})
		case 'notification':
			return fs
				.collection('logs')
				.doc()
				.set({
					newNotification: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: type,
				})
				.catch(error => {
					console.error('Error to adding notification document: ', error)
				})
		case 'download':
			return fs
				.collection('logs')
				.doc()
				.set({
					statusDownload: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: 'allowDownload',
				})
				.catch(error => {
					console.error('Error to adding notification document: ', error)
				})
		case 'upload':
			return fs
				.collection('logs')
				.doc()
				.set({
					statusUpload: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: 'allowUpload',
				})
				.catch(error => {
					console.error('Error to adding notification document: ', error)
				})
		case 'log':
			return fs
				.collection('logs')
				.doc()
				.set({
					statusLog: update,
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: type,
				})
				.catch(error => {
					console.error('Error to adding notification document: ', error)
				})
		case 'createUser':
			return fs
				.collection('logs')
				.doc()
				.set({
					author: author,
					time: new Date(),
					uid: userId,
					companyId: activeCompanyId,
					type: type,
				})
				.catch(error => {
					console.error('Error to adding created user in collection log: ', error)
				})
		default:
			break 
	}
}

/**
 * @description create function to take plugin in active company id
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const takePlugins = async (activeCompanyId, setPluginsInReducer) => {
	let companies = fs.collection('companies').doc(activeCompanyId).collection('plugins'),
		plugins = [],
		allDashSets = [],
		allDashItems = [],
		docs,
		docsIt,
		docsCard,
		docAllDashSet,
		docAllDashItem

	await fs
		.collection('dashSets')
		.get()
		.then(snap => {
			snap.forEach(dashSet => {
				docAllDashSet = dashSet.data()

				allDashSets.push({
					id: dashSet.id,
					name: docAllDashSet.name,
				})
			})
		})

	await fs
		.collection('dashItems')
		.get()
		.then(snap => {
			snap.forEach(dashItem => {
				docAllDashItem = dashItem.data()

				allDashItems.push({
					id: dashItem.id,
					name: docAllDashItem.title,
				})
			})
		})

	await companies
		.get()
		.then(snapShot => {
			snapShot.forEach(item => {
				docs = item.data()
				let dashSets = []

				companies
					.doc(item.id)
					.collection('dashSets')
					.get()
					.then(snap => {
						snap.forEach(it => {
							docsIt = it.data()
							let dashItems = []

							companies
								.doc(item.id)
								.collection('dashSets')
								.doc(it.id)
								.collection('cards')
								.get()
								.then(dashItemSnap => {
									dashItemSnap.forEach(itemCard => {
										docsCard = itemCard.data()
										let resultDashItem = allDashItems.find(element => element.id == itemCard.id)

										dashItems.push({
											idDashItem: itemCard.id,
											name: resultDashItem.name,
											active: docsCard.active,
										})
									})
								})
								.catch(error => {
									console.error('Error reading document: ', error)
								})

							let resultDashSet = allDashSets.find(element => element.id == it.id)

							dashSets.push({
								idDashSet: it.id,
								name: resultDashSet.name,
								active: docsIt.active,
								order: docsIt.order,
								statusDashSet: false,
								dashItems: dashItems,
							})
						})
					})
					.catch(error => {
						console.error('Error reading document: ', error)
					})

				plugins.push({
					id: item.id,
					name: docs.name,
					status: docs.active,
					statusPlugin: false,
					dataSource: docs.dataSource,
					type: docs.type,
					dashSets: dashSets,
				})
				if (!!setPluginsInReducer) setPluginsInReducer(plugins)
			})
		})
		.catch(error => {
			console.error('Error reading document: ', error)
		})

	return plugins
}

/**
 * @description Includes new user in firebase RTDB
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const createUserFirebase = (datas, activeCompanyId, setEmailValidation, setAlertCreateUser) => {
	//Create authentication user and added in firebase RTDB
	doCreateUserAuth(datas, activeCompanyId, setEmailValidation, setAlertCreateUser)
}

/**
 * @description Create new user authentication with e-mail and password in auth
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const doCreateUserAuth = (datas, activeCompanyId, setEmailValidation, setAlertCreateUser) => {
	let user = null

	// doCreateUserWithEmailAndPassword(datas.email, '$PassWord#123$').then((resp) => {
	auth2
		.createUserWithEmailAndPassword(datas.email, '$PassWord#123$')
		.then(resp => {
			// user = resp.user.uid

			fs.collection('users').doc(resp.user.uid).set({
				active: datas.status,
				name: datas.name,
				email: datas.email,
				signInType: datas.signInType,
				date: datas.date,
				author: datas.author,
			})

			console.log('entrou no auth')

			doPasswordResetOnboarding(datas.email)
			setAlertCreateUser(true)
			doCreatePermissions(datas, resp.user.uid, activeCompanyId)
			addUserAlteration(datas.author, resp.user, resp.user.uid, activeCompanyId, 'createUser')
		})
		.catch(err => {
			console.error('err => ', err)
			if (err.message != 'The email address is badly formatted.') setEmailValidation(true)
		})
}

/**
 * @description set a reset pass token in firebase
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const doPasswordResetOnboarding = email => {
	fs.collection('resetPassToken').doc().set({
		onboarding: true,
		email: email,
		date: new Date(),
	})
}

/**
 * @description Add permissions plugins to user in firebase RTDB
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const doCreatePermissions = (datas, userId, activeCompanyId) => {
	let companies = fs.collection('companies').doc(activeCompanyId)

	companies
		.collection('users')
		.doc(userId)
		.set({
			active: datas.status,
			author: datas.author,
			date: datas.date,
			actions: datas.actions,
			notification: datas.notification,
			log: datas.log,
			download: datas.download,
			upload: datas.upload,
		})
		.catch(error => {
			console.error('Error writing document: ', error)
		})

	// Add plugins of params to user created
	datas.plugins.forEach(plugin => {
		companies
			.collection('users')
			.doc(userId)
			.collection('plugins')
			.doc(plugin.id)
			.set(
				Object.assign(
					{},
					{
						active: plugin.status,
						author: datas.author,
						dataSource: plugin.dataSource,
						date: datas.date,
						name: plugin.name,
						type: plugin.type,
					}
				)
			)
			.catch(error => {
				console.error('Error writing document: ', error)
			})

		// Add dashSets of params to user created
		plugin.dashSets.forEach(dashSet => {
			companies
				.collection('users')
				.doc(userId)
				.collection('plugins')
				.doc(plugin.id)
				.collection('dashSets')
				.doc(dashSet.idDashSet)
				.set(
					Object.assign(
						{},
						{
							active: dashSet.active,
							order: dashSet.order,
						}
					)
				)
				.catch(error => {
					console.error('Error writing document: ', error)
				})

			// Add cards of param to user created
			dashSet.dashItems.forEach(card => {
				companies
					.collection('users')
					.doc(userId)
					.collection('plugins')
					.doc(plugin.id)
					.collection('dashSets')
					.doc(dashSet.idDashSet)
					.collection('cards')
					.doc(card.idDashItem)
					.set(
						Object.assign(
							{},
							{
								active: card.active,
								date: datas.date,
							}
						)
					)
			})
		})
	})

	if (!!datas.setAlertAlteration) {
		datas.setAlertAlteration(true)
	}
}

/**
 * @description Alter config to specify user (name or status)
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const doAlterConfigUser = datas => {
	fs.collection('users').doc(datas.user).set(
		{
			name: datas.name,
			active: datas.active,
		},
		{ merge: true }
	)
}

/**
 * @description Add card permissions to user in company
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const addPermissionsAllUsers = async activeCompanyId => {
	let docs, docsIt, docsCard

	// let allCompanies = [
	// 	'-UeOuO700DIllEivPPdG',
	// 	'4ydHc5bfNqeFMpXIEK4T',
	// 	'5xsI6NOKsexRYuHxLPr3',
	// 	'8qWzMuf5Ln5phJfC369F',
	// 	'DO2S60J2r1kdwkumDiMD',
	// 	'DylbcsK7xpRiM2KkZTLn',
	// 	'HCOZRYsDgbOtC1OtGaKZ',
	// 	'Qd5NvUllPWu53ibt4mFY',
	// 	'QfrJdyXbB2dSsh7pJdvp',
	// 	'RRCoE8Mj55I4ygOEdTQU',
	// 	'RlFIpdtK2LwDyAvl8T67',
	// 	'cM5LUzMRz4Kbf5mGzpPY',
	// 	'cZKKbxlhUvX1SJpE4SXH',
	// 	'fwNek79gd6SlGtl1qP09',
	// 	'jHxW9uNVnP2UT7giIye9',
	// 	'pwt0Wn4odVoE0mlI13uB',
	// 	'wEKXq4rC1S9Fio3HZj6Z',
	// 	'zp32LUD5JW1yLWHTiBhr',
	// ]

	let company = 'zp32LUD5JW1yLWHTiBhr'
	// allCompanies.forEach(async (company) => {
	let companies = fs.collection('companies').doc(company).collection('plugins'),
		plugins = []

	await companies
		.get()
		.then(snapShot => {
			snapShot.forEach(item => {
				docs = item.data()
				let dashSets = []

				companies
					.doc(item.id)
					.collection('dashSets')
					.get()
					.then(snap => {
						snap.forEach(it => {
							docsIt = it.data()
							let dashItems = []

							companies
								.doc(item.id)
								.collection('dashSets')
								.doc(it.id)
								.collection('cards')
								.get()
								.then(dashItemSnap => {
									dashItemSnap.forEach(itemCard => {
										docsCard = itemCard.data()

										dashItems.push({
											idDashItem: itemCard.id,
											active: docsCard.active,
										})
									})
								})
								.catch(error => {
									console.error('Error reading document: ', error)
								})

							dashSets.push({
								idDashSet: it.id,
								active: docsIt.active,
								order: docsIt.order,
								dashItems: dashItems,
							})
						})
					})
					.catch(error => {
						console.error('Error reading document: ', error)
					})

				plugins.push({
					id: item.id,
					name: docs.name,
					status: docs.active,
					dataSource: docs.dataSource,
					type: docs.type,
					dashSets: dashSets,
				})
			})
		})
		.catch(error => {
			console.error('Error reading document: ', error)
		})

	let userCompanies = fs.collection('companies').doc(company).collection('users'),
		usersPlugins = [],
		userDocs,
		userDocsIt,
		userDash

	await userCompanies
		.get()
		.then(snapShot => {
			snapShot.forEach(userSnap => {
				userDocs = userSnap.data()
				let userPlugins = []

				userCompanies
					.doc(userSnap.id)
					.collection('plugins')
					.get()
					.then(snap => {
						snap.forEach(pluginSnap => {
							userDocsIt = pluginSnap.data()
							let dashSets = []

							userCompanies
								.doc(userSnap.id)
								.collection('plugins')
								.doc(pluginSnap.id)
								.collection('dashSets')
								.get()
								.then(dashSnap => {
									dashSnap.forEach(dashSetSnap => {
										userDash = dashSetSnap.data()
										let userCards = []

										plugins.forEach(plugs => {
											plugs.dashSets.forEach(dashs => {
												if (plugs.id == pluginSnap.id && dashs.idDashSet == dashSetSnap.id) {
													dashs.dashItems.map(cards => {
														userCompanies
															.doc(userSnap.id)
															.collection('plugins')
															.doc(pluginSnap.id)
															.collection('dashSets')
															.doc(dashSetSnap.id)
															.collection('cards')
															.doc(cards.idDashItem)
															.set({
																active: cards.active,
																date: new Date(),
															})
															.catch(err => {
																console.error('n deu em ', err)
															})
													})
												}
											})
										})
										dashSets.push({
											idDashSet: dashSetSnap.id,
											active: userDash.active,
											cards: userCards,
										})
									})
								})
								.catch(error => {
									console.error('Error reading document: ', error)
								})

							userPlugins.push({
								idPlugin: pluginSnap.id,
								active: userDocsIt.active,
								dashSets: dashSets,
							})
						})
					})
					.catch(error => {
						console.error('Error reading document: ', error)
					})

				usersPlugins.push({
					id: userSnap.id,
					active: userDocs.active,
					plugins: userPlugins,
				})
			})
		})
		.catch(error => {
			console.error('Error reading document: ', error)
		})
	// })
}

/**
 * Don't have vinculation with users, just companies and cards
 *
 * @description Add card in dashSet In plugin to companies
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const addCardCompany = async activeCompanyId => {
	let collectionsDashSets = fs.collection('dashSets')

	// let companies = [
	// 	'-UeOuO700DIllEivPPdG',
	// 	'4ydHc5bfNqeFMpXIEK4T',
	// 	'5xsI6NOKsexRYuHxLPr3',
	// 	'8qWzMuf5Ln5phJfC369F',
	// 	'DO2S60J2r1kdwkumDiMD',
	// 	'DylbcsK7xpRiM2KkZTLn',
	// 	'HCOZRYsDgbOtC1OtGaKZ',
	// 	'Qd5NvUllPWu53ibt4mFY',
	// 	'QfrJdyXbB2dSsh7pJdvp',
	// 	'RRCoE8Mj55I4ygOEdTQU',
	// 	'RlFIpdtK2LwDyAvl8T67',
	// 	'cM5LUzMRz4Kbf5mGzpPY',
	// 	'cZKKbxlhUvX1SJpE4SXH',
	// 	'fwNek79gd6SlGtl1qP09',
	// 	'jHxW9uNVnP2UT7giIye9',
	// 	'pwt0Wn4odVoE0mlI13uB',
	// 	'wEKXq4rC1S9Fio3HZj6Z',
	// 	'zp32LUD5JW1yLWHTiBhr',
	// ]

	// companies.forEach(async (item) => {
	let collectionsCompany = fs.collection('companies').doc(activeCompanyId).collection('plugins'),
		dashSets = []

	await collectionsDashSets.get().then(snapShot => {
		snapShot.forEach(async dashSet => {
			let cards = []

			await collectionsDashSets
				.doc(dashSet.id)
				.collection('cards')
				.get()
				.then(snap => {
					snap.forEach(card => {
						cards.push({
							idCard: card.id,
						})
					})
				})

			dashSets.push({
				idDashSet: dashSet.id,
				cards: cards,
			})
		})
	})

	await collectionsCompany.get().then(snapShot => {
		snapShot.forEach(async plugin => {
			await collectionsCompany
				.doc(plugin.id)
				.collection('dashSets')
				.get()
				.then(snap => {
					snap.forEach(dashSet => {
						// let docs = dashSet.data()

						dashSets.forEach(dash => {
							if (dash.idDashSet === dashSet.id) {
								dash.cards.forEach(cards => {
									collectionsCompany
										.doc(plugin.id)
										.collection('dashSets')
										.doc(dashSet.id)
										.collection('cards')
										.doc(cards.idCard)
										.set({
											active: true,
											date: new Date(),
										})
								})
							}
						})
					})
				})
		})
	})
	// })
}

/**
 * @description Implements acess to log, download, upload, notification and actions to all users
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const implementsAcessMenu = async activeCompanyId => {
	// let allCompanies = [
	// 	'-UeOuO700DIllEivPPdG',
	// 	'4ydHc5bfNqeFMpXIEK4T',
	// 	'5xsI6NOKsexRYuHxLPr3',
	// 	'8qWzMuf5Ln5phJfC369F',
	// 	'DO2S60J2r1kdwkumDiMD',
	// 	'DylbcsK7xpRiM2KkZTLn',
	// 	'HCOZRYsDgbOtC1OtGaKZ',
	// 	'Qd5NvUllPWu53ibt4mFY',
	// 	'QfrJdyXbB2dSsh7pJdvp',
	// 	'RRCoE8Mj55I4ygOEdTQU',
	// 	'RlFIpdtK2LwDyAvl8T67',
	// 	'cM5LUzMRz4Kbf5mGzpPY',
	// 	'cZKKbxlhUvX1SJpE4SXH',
	// 	'fwNek79gd6SlGtl1qP09',
	// 	'jHxW9uNVnP2UT7giIye9',
	// 	'pwt0Wn4odVoE0mlI13uB',
	// 	'wEKXq4rC1S9Fio3HZj6Z',
	// 	'zp32LUD5JW1yLWHTiBhr',
	// ]

	let companyUser = fs.collection('companies').doc('zp32LUD5JW1yLWHTiBhr').collection('users'),
		allUsers = []

	await companyUser.get().then(snapShot => {
		snapShot.forEach(user => {
			allUsers.push(user.id)
		})
	})

	// let id = 'mUC33jVUJRRTemn1hhM6SFSGtw22'
	if (!!allUsers) {
		allUsers.forEach(async id => {
			await companyUser.doc(id).set(
				{
					actions: true,
					download: false,
					upload: false,
					log: false,
					notification: true,
				},
				{ merge: true }
			)
		})
	}
}

/**
 * @description merge plugins companie with plugins user to visualizable acess
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const takeAllPlugins = async (datas, setPluginUser, otherPlugins) => {
	let acessPlugin = fs.collection('companies').doc(datas.activeCompanyId).collection('plugins'),
		plugins = [],
		nameDashSets = [],
		nameDashItems = [],
		namePlugins = []

	await fs
		.collection('dashSets')
		.get()
		.then(snap => {
			snap.forEach(dashSet => {
				nameDashSets.push({
					id: dashSet.id,
					name: dashSet.data().name,
				})
			})
		})
		.then(() => {
			nameDashSets = _.groupBy(nameDashSets, 'id')
		})

	await fs
		.collection('dashItems')
		.get()
		.then(snap => {
			snap.forEach(dashItem => {
				nameDashItems.push({
					id: dashItem.id,
					name: dashItem.data().title,
				})
			})
		})
		.then(() => {
			nameDashItems = _.groupBy(nameDashItems, 'id')
		})

	await fs
		.collection('plugins')
		.get()
		.then(snap => {
			snap.forEach(dashItem => {
				namePlugins.push({
					id: dashItem.id,
					name: dashItem.data().name,
					type: dashItem.data().type,
				})
			})
		})
		.then(() => {
			namePlugins = _.groupBy(namePlugins, 'id')
		})

	otherPlugins = !!otherPlugins ? _.groupBy(otherPlugins, 'id') : null

	acessPlugin.get().then(snapShot => {
		snapShot.forEach(snap => {
			let dashSets = [],
				groupDashSet = !!otherPlugins && !!otherPlugins[snap.id] ? _.groupBy(otherPlugins[snap.id][0].dashSets, 'idDashSet') : null

			acessPlugin
				.doc(snap.id)
				.collection('dashSets')
				.get()
				.then(dashSet => {
					dashSet.forEach(snapDashSet => {
						let dashItems = [],
							groupDashItem =
								!!groupDashSet && !!groupDashSet[snapDashSet.id]
									? _.groupBy(groupDashSet[snapDashSet.id][0].dashItems, 'idDashItem')
									: null

						acessPlugin
							.doc(snap.id)
							.collection('dashSets')
							.doc(snapDashSet.id)
							.collection('cards')
							.get()
							.then(snapCard => {
								snapCard.forEach(card => {
									if (!nameDashItems[card.id]) return
									if (!!groupDashItem && groupDashItem[card.id]) {
										dashItems.push(
											Object.assign(card.data(), {
												idDashItem: card.id,
												name: nameDashItems[card.id][0].name,
												active: groupDashItem[card.id][0].active,
											})
										)
									} else {
										dashItems.push(
											Object.assign(card.data(), {
												idDashItem: card.id,
												active: false,
												name: nameDashItems[card.id][0].name,
											})
										)
									}
								})
							})

						if (!!groupDashSet && groupDashSet[snapDashSet.id]) {
							dashSets.push(
								Object.assign(snapDashSet.data(), {
									idDashSet: snapDashSet.id,
									dashItems: dashItems,
									// statusDashSet: false,
									name: nameDashSets[snapDashSet.id][0].name,
									active: groupDashSet[snapDashSet.id][0].active,
									statusDashSet: groupDashSet[snapDashSet.id][0].statusDashSet,
								})
							)
						} else {
							dashSets.push(
								Object.assign(snapDashSet.data(), {
									idDashSet: snapDashSet.id,
									dashItems: dashItems,
									active: false,
									name: nameDashSets[snapDashSet.id][0].name,
								})
							)
						}
					})
				})

			if (!!otherPlugins && otherPlugins[snap.id]) {
				plugins.push(
					Object.assign(snap.data(), {
						id: snap.id,
						dashSets: dashSets,
						statusPlugin: false,
						status: otherPlugins[snap.id][0].status,
						active: otherPlugins[snap.id][0].active,
						name: namePlugins[snap.id][0].name,
					})
				)
			} else {
				plugins.push(
					Object.assign(snap.data(), {
						id: snap.id,
						dashSets: dashSets,
						statusPlugin: false,
						status: false,
						active: false,
						name: namePlugins[snap.id][0].name,
					})
				)
			}
		})

		setPluginUser(plugins)
	})
}

export const takeAcess = async (plugins, datas, setPluginUser) => {
	let userCompany = fs.collection('companies').doc(datas.activeCompanyId).collection('users').doc(datas.idUser).collection('plugins'),
		newPlugins = [],
		docsPlugin,
		docsDashSet,
		docsDashItem

	await userCompany.get().then(snapShot => {
		snapShot.forEach(snapPlugin => {
			let allDashSets = []

			docsPlugin = snapPlugin.data()

			userCompany
				.doc(snapPlugin.id)
				.collection('dashSets')
				.get()
				.then(snapDashSet => {
					snapDashSet.forEach(dashSet => {
						let allDashItems = []

						docsDashSet = dashSet.data()

						userCompany
							.doc(snapPlugin.id)
							.collection('dashSets')
							.doc(dashSet.id)
							.collection('cards')
							.get()
							.then(snapDashItem => {
								snapDashItem.forEach(card => {
									docsDashItem = card.data()

									allDashItems.push(
										Object.assign(docsDashItem, {
											idDashItem: card.id,
										})
									)
								})
							})

						allDashSets.push(
							Object.assign(docsDashSet, {
								idDashSet: dashSet.id,
								dashItems: allDashItems,
								statusDashSet: false,
							})
						)
					})
				})

			newPlugins.push(
				Object.assign(docsPlugin, {
					id: snapPlugin.id,
					dashSets: allDashSets,
					statusPlugin: false,
					status: docsPlugin.active,
				})
			)
		})
	})

	takeAllPlugins(datas, setPluginUser, newPlugins)
}

/**
 *
 * @description Active plugins to same users in company
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const ActivePluginsToSomeUsers = async () => {
	// let users = [
	// 	'n2xrlX72ikgCFmKHpzWFBvHfvjk1',
	// 	'Iy7VLsXVznX2ndpcLxCehGqmzW83',
	// 	'iZhr2wxqNceEa5adol9aBpup5ij2',
	// 	'iUKRh1hksSgm9V0rlJl8XFrANL52',
	// 	'iDoHbdR0OeQdj8dMOuMtR6DjGEC2',
	// 	'b8t0uoWjw1eMYMm0AMGl98OZIIf2',
	// 	'9ZnjE0u1Amd12BjxohswpXp0Od63',
	// 	'zXD1yTkRpDbtPkfbFMXllnBDG5d2',
	// ]

	let allUsers = []

	await fs
		.collection('companies')
		.doc('Qd5NvUllPWu53ibt4mFY')
		.collection('users')
		.get()
		.then(snap => {
			snap.forEach(user => {
				allUsers.push(user.id)
			})
		})

	allUsers.forEach(async user => {
		await fs
			.collection('companies')
			.doc('Qd5NvUllPWu53ibt4mFY')
			.collection('users')
			.doc(user)
			.collection('plugins')
			.doc('HgHBTIv8YP6VyaRC6Rqj')
			.set(
				{
					active: true,
				},
				{ merge: true }
			)
	})
}

/**
 * @description Active status menu to same users in company
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const ActiveMenuToSomeUsers = () => {
	let users = [
		'n2xrlX72ikgCFmKHpzWFBvHfvjk1',
		'Iy7VLsXVznX2ndpcLxCehGqmzW83',
		'iZhr2wxqNceEa5adol9aBpup5ij2',
		'iUKRh1hksSgm9V0rlJl8XFrANL52',
		'iDoHbdR0OeQdj8dMOuMtR6DjGEC2',
		'b8t0uoWjw1eMYMm0AMGl98OZIIf2',
		'9ZnjE0u1Amd12BjxohswpXp0Od63',
		'zXD1yTkRpDbtPkfbFMXllnBDG5d2',
	]

	users.forEach(user => {
		fs.collection('companies').doc('Qd5NvUllPWu53ibt4mFY').collection('users').doc(user).set(
			{
				log: true,
				download: true,
				upload: true,
			},
			{ merge: true }
		)
	})
}

/**
 * @description identify if existe log, download and upload in company
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const identifyComponentsInCompany = async (activeCompany, setComponentsPermissions) => {
	let docs

	await fs
		.collection('companies')
		.doc(activeCompany)
		.collection('configs')
		.get()
		.then(snapShot => {
			let componentsPermission = []

			snapShot.forEach(snap => {
				docs = snap.data()

				if (!!docs.download) {
					componentsPermission.push({
						id: 'download',
						label: 'Download',
						minWidth: 'auto',
						align: 'right',
						order: '',
					})
				}

				if (!!docs.upload) {
					componentsPermission.push({
						id: 'upload',
						label: 'Upload',
						minWidth: 'auto',
						align: 'right',
						order: '',
					})
				}

				if (!!docs.log) {
					componentsPermission.push({
						id: 'log',
						label: 'Log',
						minWidth: 'auto',
						align: 'right',
						order: '',
					})
				}
			})

			setComponentsPermissions(componentsPermission)
		})
		.catch(err => {
			console.error('Error - ', err)
		})
}

/**
 * @description take user's last log in
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const takeAcessLog = async (users, setUsersInReducer) => {
	let lastDateToUser = [],
		groupLogs,
		logs = [],
		docs

	await fs
		.collection('logs')
		.where('type', '==', 'acessed')
		.get()
		.then(snapShot => {
			snapShot.forEach(snap => {
				docs = snap.data()

				logs.push(docs)
			})
		})

	groupLogs = _.groupBy(logs, 'uid')

	users.forEach(user => {
		let lastDate = null

		if (!!groupLogs[user.id]) {
			groupLogs[user.id].forEach(item => {
				if (lastDate == null || item.time.seconds > lastDate) {
					lastDate = item.time.seconds
				}
			})

			let date = new Date(lastDate * 1000),
				fullDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(),
				transformDate = date + ''

			let hour = transformDate.split(' ')[4]

			lastDateToUser.push(
				Object.assign(user, {
					id: user.id,
					lastDate: fullDate + ' às ' + hour,
					date: date,
				})
			)
		} else {
			lastDateToUser.push(
				Object.assign(user, {
					lastDate: 'Sem Login',
				})
			)
		}
	})

	setUsersInReducer(lastDateToUser)
}

/**
 * @description delete especific card in all on your company
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const deleteCardInUser = async () => {
	// let allCompanies = [
	// 	'-UeOuO700DIllEivPPdG',
	// 	'4ydHc5bfNqeFMpXIEK4T',
	// 	'5xsI6NOKsexRYuHxLPr3',
	// 	'8qWzMuf5Ln5phJfC369F',
	// 	'DO2S60J2r1kdwkumDiMD',
	// 	'DylbcsK7xpRiM2KkZTLn',
	// 	'HCOZRYsDgbOtC1OtGaKZ',
	// 	'Qd5NvUllPWu53ibt4mFY',
	// 	'QfrJdyXbB2dSsh7pJdvp',
	// 	'RRCoE8Mj55I4ygOEdTQU',
	// 	'RlFIpdtK2LwDyAvl8T67',
	// 	'cM5LUzMRz4Kbf5mGzpPY',
	// 	'cZKKbxlhUvX1SJpE4SXH',
	// 	'fwNek79gd6SlGtl1qP09',
	// 	'jHxW9uNVnP2UT7giIye9',
	// 	'pwt0Wn4odVoE0mlI13uB',
	// 	'wEKXq4rC1S9Fio3HZj6Z',
	// 	'zp32LUD5JW1yLWHTiBhr',
	// ]

	await fs
		.collection('companies')
		.doc('zp32LUD5JW1yLWHTiBhr')
		.collection('users')
		.get()
		.then(resp => {
			resp.forEach(user => {
				// allUsersCompany.push(user.id)

				fs.collection('companies')
					.doc('cM5LUzMRz4Kbf5mGzpPY')
					.collection('users')
					.doc(user.id)
					.collection('plugins')
					.doc('-JhLeOlGIEjaIOFHR0xd')
					.collection('dashSets')
					.doc('udzfHUNsISBE7nEPBfRf')
					.collection('cards')
					.doc('wlpXiJZen39cVl4eR57b')
					.delete()
					.then(() => {
						console.log('deletado')
					})
					.catch(err => {
						console.log('erro ao apagar documento - ', err)
					})
			})
		})
}

/**
 * @description delete especific card in company
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const deleteCardIncompany = () => {
	let allCompanies = [
		'-UeOuO700DIllEivPPdG',
		'4ydHc5bfNqeFMpXIEK4T',
		'5xsI6NOKsexRYuHxLPr3',
		'8qWzMuf5Ln5phJfC369F',
		'DO2S60J2r1kdwkumDiMD',
		'DylbcsK7xpRiM2KkZTLn',
		'HCOZRYsDgbOtC1OtGaKZ',
		'Qd5NvUllPWu53ibt4mFY',
		'QfrJdyXbB2dSsh7pJdvp',
		'RRCoE8Mj55I4ygOEdTQU',
		'RlFIpdtK2LwDyAvl8T67',
		'cM5LUzMRz4Kbf5mGzpPY',
		'cZKKbxlhUvX1SJpE4SXH',
		'fwNek79gd6SlGtl1qP09',
		'jHxW9uNVnP2UT7giIye9',
		'pwt0Wn4odVoE0mlI13uB',
		'wEKXq4rC1S9Fio3HZj6Z',
		'zp32LUD5JW1yLWHTiBhr',
	]

	allCompanies.forEach(async company => {
		await fs
			.collection('companies')
			.doc(company)
			.collection('plugins')
			.doc('-JhLeOlGIEjaIOFHR0xd')
			.collection('dashSets')
			.doc('udzfHUNsISBE7nEPBfRf')
			.collection('cards')
			.doc('wlpXiJZen39cVl4eR57b')
			.delete()
			.then(() => {
				console.log('deletado')
			})
			.catch(err => {
				console.log('erro ao apagar documento - ', err)
			})
	})
}

/**
 * @description delete especific card in all on your company
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const AddNewCardInUser = async () => {
	// id from all companies
	// let allCompanies = [
	// 	'-UeOuO700DIllEivPPdG',
	// 	'4ydHc5bfNqeFMpXIEK4T',
	// 	'5xsI6NOKsexRYuHxLPr3',
	// 	'8qWzMuf5Ln5phJfC369F',
	// 	'DO2S60J2r1kdwkumDiMD',
	// 	'DylbcsK7xpRiM2KkZTLn',
	// 	'HCOZRYsDgbOtC1OtGaKZ',
	// 	'Qd5NvUllPWu53ibt4mFY',
	// 	'QfrJdyXbB2dSsh7pJdvp',
	// 	'RRCoE8Mj55I4ygOEdTQU',
	// 	'RlFIpdtK2LwDyAvl8T67',
	// 	'cM5LUzMRz4Kbf5mGzpPY',
	// 	'cZKKbxlhUvX1SJpE4SXH',
	// 	'fwNek79gd6SlGtl1qP09',
	// 	'jHxW9uNVnP2UT7giIye9',
	// 	'pwt0Wn4odVoE0mlI13uB',
	// 	'wEKXq4rC1S9Fio3HZj6Z',
	// 	'zp32LUD5JW1yLWHTiBhr',
	// ]

	const company = 'cM5LUzMRz4Kbf5mGzpPY',
		plugin = '-JhLeOlGIEjaIOFHR0xd',
		dashSet = 'udzfHUNsISBE7nEPBfRf',
		card = 'j1ppnzz8rMPt7h2og7Gl'

	await fs
		.collection('companies')
		.doc(company)
		.collection('users')
		.get()
		.then(resp => {
			resp.forEach(user => {
				fs.collection('companies')
					.doc(company)
					.collection('users')
					.doc(user.id)
					.collection('plugins')
					.doc(plugin)
					.collection('dashSets')
					.doc(dashSet)
					.collection('cards')
					.doc(card)
					.set(
						{
							active: true,
							date: new Date(),
						},
						{ merge: true }
					)
					.catch(err => {
						console.log('erro ao apagar documento - ', err)
					})
			})
		})
}

/**
 * @description Add new card in all companies
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const AddNewCardInAllCompanies = () => {
	// id from all companies
	const allCompaniesAppAudit = [
		'-UeOuO700DIllEivPPdG', // endicon
		'0zRxqpGTUIr2Xy4Rb2hy', // biocnpc
		'4ydHc5bfNqeFMpXIEK4T', // arvut
		'5xsI6NOKsexRYuHxLPr3', // control
		'8qWzMuf5Ln5phJfC369F', // start engenharia
		'DO2S60J2r1kdwkumDiMD', // haka pub
		'DylbcsK7xpRiM2KkZTLn', // light appaudit
		'HCOZRYsDgbOtC1OtGaKZ', // lider construção
		'Qd5NvUllPWu53ibt4mFY', // light
		'QfrJdyXbB2dSsh7pJdvp', // abg
		'RRCoE8Mj55I4ygOEdTQU', // conecta construções
		'RlFIpdtK2LwDyAvl8T67', // gefco
		'Wqw6g9Jgq1sXgnEh1pLb', // scs
		'XG2sbUbjqOpZLCMxvJNg', // energimp
		'cM5LUzMRz4Kbf5mGzpPY', // arquia
		'cZKKbxlhUvX1SJpE4SXH', // telefonica
		'd9MmZxqi7XzL1NEv6qSC', // tencel
		'fwNek79gd6SlGtl1qP09', // v2
		'gmm1XDhIcHvwcPTmknTH', // evoltz
		'jHxW9uNVnP2UT7giIye9', // ideal
		'ppt9ySHnTckJCdJAR0GM', // scala
		'pwt0Wn4odVoE0mlI13uB', // phama ----
		'rywrgk8QbpMzify1jGfd', // scala qualidade
		'tvoHbxCN7Ul9f7ThTxuw', // enecol
		'vFZO1K2xWVZvYW9V97Tb', // foton
		'wEKXq4rC1S9Fio3HZj6Z', // 3elos
		'zp32LUD5JW1yLWHTiBhr', // r2
		'OEQjkWPphk2RQEOSjOIt', // vervit
	]

	const datas = {
		plugin: '-JhLeOlGIEjaIOFHR0xd',
		dashSet: 'udzfHUNsISBE7nEPBfRf',
		card: 'hOQ9p1bB6qxtbel4wQaB',
		// steps: 'O7ayKP2Mobln7oQaixh4',
		// title: 'Resultado de checklists por plano de ação',
		// description: 'Permitir analisar planos de ação com base em diferentes agrupadores.',
		// version: 1,
		// dataSets: {
		// 	resultChecklistByAudit: {
		// 		dataSet: 'resultChecklistByAudit',
		// 		label: 'label/audits',
		// 	},
		// 	resultChecklistByBase: {
		// 		dataSet: 'resultChecklistByBase',
		// 		label: 'label/base',
		// 	},
		// 	resultChecklistByClient: {
		// 		dataSet: 'resultChecklistByClient',
		// 		label: 'label/client',
		// 		default: true,
		// 	},
		// 	resultChecklistByCollaborator: {
		// 		dataSet: 'resultChecklistByCollaborator',
		// 		label: 'label/collaborator',
		// 	},
		// 	resultChecklistByForm: {
		// 		dataSet: 'resultChecklistByForm',
		// 		label: 'label/form',
		// 	},
		// 	resultChecklistByLocality: {
		// 		dataSet: 'resultChecklistByLocality',
		// 		label: 'label/locality',
		// 	},
		// 	resultChecklistByObject: {
		// 		dataSet: 'resultChecklistByObject',
		// 		label: 'label/object',
		// 	},
		// 	resultChecklistByObjectType: {
		// 		dataSet: 'resultChecklistByObjectType',
		// 		label: 'label/object-type',
		// 	},
		// },
		localFilters: [
			'Lmn2a80m0cJ7y7VIYXox',
			'fY35qqMqvxOZGcgCPCcH',
			'ouJEYNaEWdYbjCgiKuIc',
			'pak4yxxkcTsu1IQkxhOr',
			'qE3Mm2Bg2u2WVx04CcI9',
			'sxFHu47kBitFOnUNRME7',
			'yZozBXd1kmyyafWZgX3m'
		],
	}

	allCompaniesAppAudit.forEach(async company => {
		//add card in companies/dashItems
		// await fs
		// 	.collection('companies')
		// 	.doc(company)
		// 	.collection('dashItems')
		// 	.doc(datas.card)
		// 	.set(
		// 		{
		// 			active: true,
		// 			title: datas.title,
		// 			description: datas.description,
		// 			version: datas.version,
		// 		},
		// 		{ merge: true }
		// 	)
		// 	.catch(err => {
		// 		console.log('erro ao adicionar documento - ', err)
		// 	})

		// //add card in companies/plugins/dashSets/card
		// await fs
		// 	.collection('companies')
		// 	.doc(company)
		// 	.collection('plugins')
		// 	.doc(datas.plugin)
		// 	.collection('dashSets')
		// 	.doc(datas.dashSet)
		// 	.collection('cards')
		// 	.doc(datas.card)
		// 	.set(
		// 		{
		// 			active: true,
		// 			date: new Date(),
		// 		},
		// 		{ merge: true }
		// 	)
		// 	.catch(err => {
		// 		console.log('erro ao adicionar documento - ', err)
		// 	})

		// //add steps to query on card in companies/dashItems/steps
		// await fs
		// 	.collection('companies')
		// 	.doc(company)
		// 	.collection('dashItems')
		// 	.doc(datas.card)
		// 	.collection('steps')
		// 	.doc(datas.steps)
		// 	.set(
		// 		{
		// 			active: true,
		// 			dataSets: datas.dataSets,
		// 		},
		// 		{ merge: true }
		// 	)
		// 	.catch(err => {
		// 		console.log('erro ao adicionar documento - ', err)
		// 	})

		datas.localFilters.forEach(async filter => {
			await fs
				.collection('companies')
				.doc(company)
				.collection('dashItems')
				.doc(datas.card)
				.collection('localFilters')
				.doc(filter)
				.set(
					{
						active: true,
					},
					{ merge: true }
				)
				.catch(err => {
					console.error(err)
				})
		})
	})
}
