const INITIAL_STATE = {
	serviceOrders: null,
	currentServiceOrder: null,
}

/**
 * Clears any loaded service orders @ the next state
 * @param {*} state the current state of the app
 */
const clearServiceOrders = (state) => 
	Object.assign({}, state, { serviceOrders: INITIAL_STATE.serviceOrders })

/**
 * Maps loaded service orders to the next state
 * @param {*} state the current state of the app
 * @param {*} action the action containing a "serviceOrders" array-prop
 */
const serviceOrdersLoaded = (state, action) => 
	Object.assign({}, state, { serviceOrders: action.serviceOrders })

function serviceOrderReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CLEAR_SERVICE_ORDERS': {
			return clearServiceOrders(state)
		}
		case 'SERVICE_ORDERS_LOADED': {
			return serviceOrdersLoaded(state, action)
		}
		default: return state
	}
}

export default serviceOrderReducer