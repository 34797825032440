import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	makeGetCompaniesListLoading,
	makeGetCompaniesListKeys
} from '../../../../selectors/company/list'
import CompanySelect from './CompanySelect'
import * as Company from '../../../../firebase/company'
import { compose } from 'recompose'
import withAuthentication from '../../withAuthentication'
import changeActiveCompany from './functions/changeActiveCompany'

/**
 * Manages to select props used within a screen dialog for user-company-activation
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class CompanySelectContainer extends React.Component {
	componentDidMount() {
		const { authUser, companiesLoading } = this.props

		//Authuser loaded successfully
		if (!!authUser && authUser.uid && !companiesLoading) {
			this.queryCompanies()
		}
	}

	queryCompanies() {
		const {
			authUser,
			onCompaniesLoaded,
			onSetCompaniesLoading
		} = this.props

		//Sets the loading flag
		onSetCompaniesLoading()
			.then(() =>
				/**
				 * Looks for companies where the user
				 * has access ONLY
				 */
				Company.getUserAllowed(authUser.uid)
			)
			.then((dataSnapshots) => {
				let companies = {},
					companyKeys = []

				//Mapping each response to the key array + object
				dataSnapshots.forEach((company) => {
					companyKeys.push(company.id)
					companies[company.id] = company.data()
				})

				//Notifies companies were all loaded
				onCompaniesLoaded(companies).then(() => {
					//If the user contains a single company
					//And the current active company is different from this one
					if (companyKeys.length === 1) {
						//Will change the active company forcedly
						changeActiveCompany(companyKeys[0])
					}
				})
			})
	}

	render() {
		return !!this.props.authUser && <CompanySelect {...this.props} />
	}
}

CompanySelectContainer.defaultProps = {
	fullWidth: false
}

CompanySelectContainer.propTypes = {
	fullWidth: PropTypes.bool
}

const makeMapStateToProps = () => {
	const getCompaniesLoading = makeGetCompaniesListLoading(),
		getCompaniesKeys = makeGetCompaniesListKeys(),
		mapStateToProps = (state, ownProps) => {
			const companiesKeys = getCompaniesKeys(state),
				isLoading = getCompaniesLoading(state)

			return {
				companyKeys: companiesKeys,
				companiesLoading: isLoading,
				fullWidth: ownProps.fullWidth
			}
		}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	/**
	 * Notifies that system companies were loaded
	 */
	onCompaniesLoaded: async (companies) =>
		dispatch({ type: 'COMPANY_LIST_LOADED', companies }),
	onSetCompaniesLoading: async () =>
		dispatch({ type: 'COMPANY_LIST_LOADING' })
})

export default compose(
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	),
	withAuthentication
)(CompanySelectContainer)
