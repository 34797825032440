const SET_GLOBAL_FILTER_DATA = 'SET_GLOBAL_FILTER_DATA',
	CLEAR_GLOBAL_FILTER_DATA = 'CLEAR_GLOBAL_FILTER_DATA',
	SET_GLOBAL_FILTER_DATA_LOADING = 'SET_GLOBAL_FILTER_DATA_LOADING',
	SET_FILTER_LOCAL_DATA = 'SET_FILTER_LOCAL_DATA',
	CLEAR_FILTER_LOCAL_DATA = 'CLEAR_FILTER_LOCAL_DATA',
	SET_CARD_FILTER = 'SET_CARD_FILTER',
	RESET_INITIAL_STATE = 'RESET_INITIAL_STATE'

const INITIAL_STATE = {
	isLoading: {},
	data: {},
	localData: {},
	cardFilter: null
}

/**
 * use this function to reset initial state then trade company
 * @description reset initial state
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
const resetInitialState = (state) => {
	return state
}

/**
 * **Sets** a filter data loading-state for a specific dataSet id
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const setLoading = (state, action) => {
	return Object.assign({}, state, {
		isLoading: Object.assign({}, state.isLoading, {
			[action.dataSetId]: action.isLoading
		})
	})
}

/**
 * @description save local filters in especify card
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
const setCardFilter = (state, action) => {
	return Object.assign({}, state.cardFilter, {
		cardFilter: Object.assign({}, state.cardFilter, action.cardFilter)
	})
}

/**
 * **Clears** a filter data for a specific dataSet id
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const clearFilterData = (state, action) => {
	return Object.assign({}, state, {
		data: Object.assign({}, state.data, { [action.dataSetId]: undefined })
	})
}

/**
 * **Sets** a filter data value for a specific dataSet id
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const setFilterData = (state, action) => {
	return Object.assign({}, state, {
		data: Object.assign({}, state.data, {
			[action.dataSetId]: action.data
		}),
		isLoading: Object.assign({}, state.isLoading, {
			[action.dataSetId]: false
		})
	})
}

/**
 * **Sets** a filter localData value for a specific dataSet id
 *
 * @description Created the function
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const setFilterLocalData = (state, action) => {
	return Object.assign({}, state, {
		localData: Object.assign({}, state.localData, {
			[action.key]: action.filterLocalData
		})
	})
}

/**
 * **Clears** a filter localData for a specific dataSet id
 *
 * @description Created the function
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const clearFilterLocalData = (state) => {
	return Object.assign({}, state, {
		localData: {}
	})
}

/**
 * Manages state-portion for global-filters-search-text
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and desired props
 */
function globalFiltersDataReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_GLOBAL_FILTER_DATA_LOADING: {
			return setLoading(state, action)
		}
		case CLEAR_GLOBAL_FILTER_DATA: {
			return clearFilterData(state, action)
		}
		case SET_GLOBAL_FILTER_DATA: {
			return setFilterData(state, action)
		}
		case CLEAR_FILTER_LOCAL_DATA: {
			return clearFilterLocalData()
		}
		case SET_FILTER_LOCAL_DATA: {
			return setFilterLocalData(state, action)
		}
		case SET_CARD_FILTER: {
			return setCardFilter(state, action)
		}
		case RESET_INITIAL_STATE: {
			return resetInitialState(state)
		}
		default:
			return state
	}
}

export default globalFiltersDataReducer

export {
	SET_GLOBAL_FILTER_DATA,
	CLEAR_GLOBAL_FILTER_DATA,
	SET_GLOBAL_FILTER_DATA_LOADING,
	CLEAR_FILTER_LOCAL_DATA,
	SET_FILTER_LOCAL_DATA
}
