/**
 * Verifies that the user is logged into the system
 *
 * @description Created the function
 * @author lopes
 * @version 1.0
 *
 *
 *
 */
export const checkLogin = (authUser) => {
	if (!authUser || (!!authUser && !authUser.uid)) return true
}
