const INITIAL_STATE = {
	rankingType: 1
}

const rankingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_RANKING_TYPE':
			return Object.assign({}, state, {
				rankingType: action.rankingType
			})
		default:
			return state
	}
}

export default rankingReducer
