import React from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import DailyTeamSummaryContainer from '../../../reusable/team/summary/daily'

/**
 * Manages to handle which team-list-set should be presented,
 * based on route params
 *
 * @description Created the component
 * @author wegner
 * @version 1.0
 *
 * @description updated component for new team status management
 * @author wegner
 * @version 1.1
 *
 * @description Code clean-up + unused references removed
 * @author brunoteixeirasilva
 * @version 1.2
 */
class TeamListPage extends React.Component {
	render() {
		return <DailyTeamSummaryContainer {...this.props} />
	}
}

export default withStyles(styles)(TeamListPage)
