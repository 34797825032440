/**
 * @author Wegner
 * @email wegner@arquia.com.br
 * @created 07-02-2020
 */
export const INITIAL_STATE = {},
	accountReducer = (state = INITIAL_STATE, action) => {
		switch (action.type) {
			case 'SET_CURRENT_USER_DATA':
				return { ...action.value }
			default:
				return state
		}
	}
export default accountReducer
