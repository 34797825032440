const SET_CONFIGURING_PLUGIN = 'SET_CONFIGURING_PLUGIN'

const INITIAL_STATE = {
	formData: {
		name: '',
		type: '',
		active: false
		//TODO: companyLevelActive: false
	},
	dirty: false,
	saving: false,
	plugin: null,
	isLoading: true
}

/**
 * Manages to set a form dirty or not
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action Action with "type" and desired props
 */
const setDirty = (state, action) =>
	Object.assign({}, state, { dirty: action.dirty })

/**
 * Manages to set a form saving or not
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action Action with "type" and desired props
 */
const setSaving = (state, action) =>
	Object.assign({}, state, { saving: action.saving })

/**
 * Manages to clear a plugin-form-data
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 */
const clearPluginFormData = (state) =>
	Object.assign({}, state, { formData: INITIAL_STATE.formData })

/**
 * Will store the formData which is relevant in this action.formData
 * ! When dealing with form state management, props in-depth have to be maintained.
 *
 * @param {Object} state The current state of the app
 * @param {Object} action The action with a "formData" props,
 * 							which contains props expected by this reducer
 */
const storePluginFormData = (state, action) =>
	Object.assign({}, state, {
		formData: Object.assign({}, state.formData, action.formData)
	})

/**
 * Will set a plugin as a configuring current
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and specific props
 */
const setConfiguringPlugin = (state, action) =>
	Object.assign({}, state, { plugin: action.plugin })

/**
 * Will reset configuring plugin to initial state
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 */
const clearConfiguringPlugin = (state) =>
	Object.assign({}, state, {
		plugin: INITIAL_STATE.plugin
	})

/**
 * Manages plugin-form state layer
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state of the app
 * @param {Object} action The action with "type" and specific props
 */
function pluginFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_PLUGIN_FORM_SAVING': {
			return setSaving(state, action)
		}
		case 'SET_PLUGIN_FORM_DIRTY': {
			return setDirty(state, action)
		}
		case 'SET_CONFIGURING_PLUGIN': {
			return setConfiguringPlugin(state, action)
		}
		case 'CLEAR_CONFIGURING_PLUGIN': {
			return clearConfiguringPlugin(state)
		}
		case 'STORE_PLUGIN_FORM_DATA': {
			return storePluginFormData(state, action)
		}
		case 'CLEAR_PLUGIN_FORM_DATA': {
			return clearPluginFormData(state, action)
		}
		case 'SET_LOADER': {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		default:
			return state
	}
}

export default pluginFormReducer

export { SET_CONFIGURING_PLUGIN }
