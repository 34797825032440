import React from 'react'
import { Snackbar, Icon, IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { hideSnackBar } from '../../../actions/snackbar'
import { translate } from '../../../utils/lang'

/**
 * Manages to detect when there are notifications
 * at the system runtime
 *
 * @description Created the first version of the snackbar
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed emitter/hider function, button structure.
 * @author brunoteixeirasilva
 * @version 1.1
 */
class SnackbarContainer extends React.Component {
	handleCloseMessage = (event) => {
		const { clearNotification } = this.props
		// As of Sentry report
		// At: https://sentry.io/druddr-media/arquia-dashboard/issues/541939384
		// Goal: Preventing unaware triggerers from exploding the application
		if (!!event && !!event.preventDefault) event.preventDefault()

		clearNotification()
	}

	render() {
		const { hasNotification, notification } = this.props

		return !!hasNotification ? (
			<Snackbar
				open={hasNotification}
				autoHideDuration={5000}
				onClose={this.handleCloseMessage}
				message={
					!notification
						? 'Critical: Message is undefined!'
						: notification
				}
				action={
					<IconButton
						key="close"
						aria-label={translate('label/close')}
						color="inherit"
						onClick={this.handleCloseMessage}
					>
						<Icon>close</Icon>
					</IconButton>
				}
			/>
		) : null
	}
}

const mapDispatchToProps = (dispatch) => ({
	clearNotification: () => dispatch(hideSnackBar())
})

const makeMapStateToProps = () => {
	const mapStateToProps = (state) => {
		return {
			hasNotification: state.snackbarState.hasNotification,
			notification: state.snackbarState.notification
		}
	}

	return mapStateToProps
}

export default connect(
	makeMapStateToProps,
	mapDispatchToProps
)(SnackbarContainer)
