import {
	MANAGE_NEW_MONITORING_LOADING_STATE,
	LOAD_NEW_MONITORING_DATA,
	SET_NEW_MONITORING_PERIOD,
} from '../../actions/newMonitoring'

const INITIAL_STATE = {
	isLoading: null,
	newMonitoring: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		leitura: '',
		momento: '',
		info: ''	
	},
}
/**
* monitoring noises state reducer manager
* @author Lucas Lopes
* @version 1.0
* @param {*} state
* @param {*} action
*/

const newMonitoringReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
			case MANAGE_NEW_MONITORING_LOADING_STATE: {
					return Object.assign({}, state, {
							isLoading: action.isLoading
					})
			}		
			case LOAD_NEW_MONITORING_DATA: {
					return Object.assign({}, state, {
						newMonitoring: action.newMonitoring,
							isSelect: false
					})
			}
			case SET_NEW_MONITORING_PERIOD: {
					return Object.assign({}, state, {
							dateConfig: {
									...action.dateConfig,
									selectedValues: Object.assign(
											{},
											state.dateConfig.selectedValues,
											{
													[action.formType]: action.value
											}
									)
							},
							isLoading: true
					})
			}
			case 'SET_NEW_MONITORING_FILTER_VALUE': {
				return Object.assign({}, state, {
					ownFilters: Object.assign({}, state.ownFilters, action.value),
					searchText: Object.assign({}, state.searchText, {
						[action.key]: ''
					})
				})
			}
			case 'SET_NEW_MONITORING_SEARCH_FILTER_VALUE': {
				return Object.assign({}, state, {
					searchText: Object.assign({}, state.searchText, action.value)
				})
			}
			case 'CLEAN_NEW_MONITORING_FILTER_VALUE': {
				return Object.assign({}, state, {
					ownFilters: {}
				})
			}
			default:
					return state
	}
}

export default newMonitoringReducer
