export const SET_SAFETY_RATIOS_LOADING = 'SET_SAFETY_RATIOS_LOADING',
	SET_SAFETY_RATIOS_REGIONALS = 'SET_SAFETY_RATIOS_REGIONALS',
	SET_SAFETY_RATIOS_SELECTED_REGIONAL = 'SET_SAFETY_RATIOS_SELECTED_REGIONAL',
	SET_SAFETY_RATIOS_ITEMS = 'SET_SAFETY_RATIOS_ITEMS',
	SET_SAFETY_RATIOS_TYPES = 'SET_SAFETY_RATIOS_TYPES',
	SET_SAFETY_RATIOS_PERIODS = 'SET_SAFETY_RATIOS_PERIODS',
	SET_SAFETY_RATIOS_SELECTED_TYPE_PRIMARY =
		'SET_SAFETY_RATIOS_SELECTED_TYPE_PRIMARY',
	SET_SAFETY_RATIOS_SELECTED_TYPE_SECONDARY =
		'SET_SAFETY_RATIOS_SELECTED_TYPE_SECONDARY',
	SET_SAFETY_RATIOS_SELECTED_PERIOD = 'SET_SAFETY_RATIOS_SELECTED_PERIOD'

/**
 * [action-creator] Provides a standard manner for dispatching **loading state** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} isLoading Whether there is a loading process or not
 */
export const setSafetyRatiosLoading = (isLoading) => ({
	type: SET_SAFETY_RATIOS_LOADING,
	isLoading
})

/**
 * [action-creator] Provides a standard manner for dispatching **ratio items** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} ratioItems Collection of possible ratio items
 */
export const setSafetyRatioItems = (ratioItems) => ({
	type: SET_SAFETY_RATIOS_ITEMS,
	ratioItems
})

/**
 * [action-creator] Provides a standard manner for dispatching **ratio regional** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} ratioRegionals Collection of possible ratio regionals
 */
export const setSafetyRatioRegionals = (ratioRegionals) => ({
	type: SET_SAFETY_RATIOS_REGIONALS,
	ratioRegionals
})

/**
 * [action-creator] Provides a standard manner for dispatching **ratio periods** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} ratioPeriods Collection of possible ratio periods
 */
export const setSafetyRatioPeriods = (ratioPeriods) => ({
	type: SET_SAFETY_RATIOS_PERIODS,
	ratioPeriods
})

/**
 * [action-creator] Provides a standard manner for dispatching **ratio type** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} ratioTypes Collection of possible ratio types
 */
export const setSafetyRatioTypes = (ratioTypes) => ({
	type: SET_SAFETY_RATIOS_TYPES,
	ratioTypes
})

/**
 * [action-creator] Provides a standard manner for dispatching **selected ratio regional** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} selectedRatioRegional The current ratio regional
 */
export const setSelectedSafetyRatioRegional = (selectedRatioRegional) => ({
	type: SET_SAFETY_RATIOS_SELECTED_REGIONAL,
	selectedRatioRegional
})

/**
 * [action-creator] Provides a standard manner for dispatching **selected ratio type** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} selectedRatioTypePrimary The current ratio type
 */
export const setSelectedSafetyRatioTypePrimary = (
	selectedRatioTypePrimary
) => ({
	type: SET_SAFETY_RATIOS_SELECTED_TYPE_PRIMARY,
	selectedRatioTypePrimary
})

/**
 * [action-creator] Provides a standard manner for dispatching **selected ratio type** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} selectedRatioTypeSecondary The current ratio type
 */
export const setSelectedSafetyRatioTypeSecondary = (
	selectedRatioTypeSecondary
) => ({
	type: SET_SAFETY_RATIOS_SELECTED_TYPE_SECONDARY,
	selectedRatioTypeSecondary
})

/**
 * [action-creator] Provides a standard manner for dispatching **selected ratio period** changes
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} selectedRatioPeriod The current ratio period
 */
export const setSelectedSafetyRatioPeriod = (selectedRatioPeriod) => ({
	type: SET_SAFETY_RATIOS_SELECTED_PERIOD,
	selectedRatioPeriod
})
