/**
 * Begin: Default routes
 */

export const CONTROL_PANEL_USER = '/control-panel-user'
export const TESTING = '/develop-testing'
export const ONBOARDING = '/onboarding'
export const ONBOARDING_STEP = `${ONBOARDING}/:step`
export const ABOUT_ARQUIA = '/about'
export const ACCOUNT = '/account'
export const CREATE_USER = '/users'
export const FORM_CREATE_USER = '/form-create-user'
export const ALL = '*'
export const CLIENT_DETAIL = '/client-detail'
export const COMPANY_DETAIL = '/company-detail'
export const DASHBOARD = '/dashboard/p/:pluginId'
export const GRAPH_DETAIL = '/graph-detail'
export const LANDING = '/'
export const PASSWORD_RESET = '/password-reset'
export const PLUGIN = '/plugin'
export const PROCESS_LIST = '/process-list'
export const PROCESS_DETAIL = '/process-detail'
export const PROCESS_TEAM_LIST = '/process-team-list'
export const SIGN_OUT = '/signout'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const DEFAULT = PLUGIN
export const STEP_ROUTE = `${DASHBOARD}/di/:dashItemId/s/:stepId`
export const DOCS = '/docs'
export const PORTAL = '/upload'
export const PORTAL_DEVELOP = '/upload-develop'
export const PORTAL_DOWNLOAD = '/download'
export const PRIVACY_POLICY = `${DOCS}/privacy-policy`
export const PASSWORD_RESET_LINK = '/password-reset/:requireId'
/**
 * End: Default routes
 */

/**
 * Begin: Team management routes
 */

export const TEAM_CONTEXT = '/team'
export const TEAM_LIST = `${TEAM_CONTEXT}/list`
export const TEAM_DETAIL = '/team-detail'
export const TEAM_DETAIL_PAGE = `${STEP_ROUTE}/id/:teamId`

/**
 * End: Team management routes
 */

/**
 * Begin: User context
 */

export const USER_MANAGEMENT = '/user/management'
export const USER_NOTIFICATIONS = '/user/notifications'
export const USER_ACTIONS = '/user/:companyId/action/:actionId'

/**
 * End: User context
 */

/**
 * Begin: Security context routes
 */

export const SECURITY = '/security'
export const SECURITY_USER_CONTEXT = `${SECURITY}/user`
export const SECURITY_ADD_USER = `${SECURITY_USER_CONTEXT}/add`
export const SECURITY_EDIT_USER = `${SECURITY_USER_CONTEXT}/edit/:userKey`
export const SECURITY_LIST_USER = `${SECURITY_USER_CONTEXT}/list`
// export const SECURITY_CONFIGURE_USER = `${SECURITY_USER_CONTEXT}/configure`
export const SECURITY_CONFIGURE_NEW_USER = `${SECURITY_USER_CONTEXT}/configure-new`
export const SECURITY_CORPORATE_CONTEXT = `${SECURITY}/corporate`
export const SECURITY_CORPORATE_COMPANY_LIST = `${SECURITY_CORPORATE_CONTEXT}/company/list`
export const SECURITY_CORPORATE_COMPANY_FILTER_LIST = `${SECURITY_CORPORATE_CONTEXT}/:activeCompanyId/filters`
export const SECURITY_CORPORATE_COMPANY_FORM = `${SECURITY_CORPORATE_CONTEXT}/company/form/:companyId`
export const SECURITY_CORPORATE_COMPANY_FILTER_FORM = `${SECURITY_CORPORATE_CONTEXT}/:activeCompanyId/filters/form/:filterId`
export const SECURITY_GROUP_CONTEXT = `${SECURITY}/group`
export const SECURITY_GROUP_LIST = `${SECURITY_GROUP_CONTEXT}/list`
export const SECURITY_GROUP_FORM = `${SECURITY_GROUP_CONTEXT}/form`
export const SECURITY_GROUP_USERS_FORM = `${SECURITY_GROUP_CONTEXT}/user/form/:groupId`
export const SECURITY_GROUP_PERMISSIONS = `${SECURITY_GROUP_CONTEXT}/permissions/form`
export const SECURITY_PLUGIN_CONTEXT = `${SECURITY}/plugin`
export const SECURITY_PLUGIN_LIST = `${SECURITY_PLUGIN_CONTEXT}/list`
export const SECURITY_PLUGIN_FORM = `${SECURITY_PLUGIN_CONTEXT}/form/:pluginId`
export const SECURITY_PERMISSION_CONTEXT = `${SECURITY}/permission`
export const SECURITY_PERMISSION_LIST = `${SECURITY_PERMISSION_CONTEXT}/list`
export const SECURITY_PERMISSION_FORM = `${SECURITY_PERMISSION_CONTEXT}/form/:permissionId`
export const SECURITY_PERMISSION_FILTERS_LIST = `${SECURITY_PERMISSION_CONTEXT}/:permissionId/filter`
export const SECURITY_INDICATORS_CONTEXT = `${SECURITY}/indicators`
export const SECURITY_INDICATORS_FORM = `${SECURITY_INDICATORS_CONTEXT}/form/:type`
/**
 * End: Security context routes
 */

/**
 * Begin: Url for work context
 */

export const WORK = '/work'
export const WORK_DETAIL = `${WORK}/detail/:workId`

/**
 * End: Url for work context
 */

/**
 * Begin: Url for error or orientation screens
 */

export const ERROR_PAGE = '/unexpected-error'

/**
 * End: Url for error or orientation screens
 */
