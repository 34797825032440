/**
 * Function responsible search for an action in actionList inside actionState and retrive the founded action.
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state app state
 */
export const getActionById = (state, ownProps) => {
	// console.log(ownProps)
	const actionId = ownProps.actionId

	return state.actionsState.actionList[actionId]
}
