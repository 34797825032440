import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, Typography } from '@material-ui/core'
import DashItem from '../dashItem'
import Loader from '../loader'
import { compose } from 'recompose'
import { deviceIsIos } from '../../../utils/env'
import DashItemView from './view/DashItemView'
import DashSetView from './view/DashSetView'
import DialogModal from '../../app/userActions/components/DialogModal'
import { getDesktopBreakpoint } from '../../../utils/object/theme/mobile'
import { onGetLocalFilters } from './controllers/controllers'
import { connect } from 'react-redux'
import { makeGetActivePlugin } from '../../../selectors/plugin'
import defaultLocalFilterGet from '../../../services/cloud/functions/localFilters'

/**
 * Will create an instance of each dashSet by their types
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Major refactor on behaviour of this component. Less re-renders
 * @author brunoteixeirasilva
 * @version 2.0
 *
 * @description added cleaning dashSet on component unmount
 * @author Davi Wegner
 * @version 2.1
 *
 * @description Added redirect to plugin selector route, when company-plugin was not found
 * @author brunoteixeirasilva
 * @version 2.2
 *
 * @description Added withPullDownToRefresh to the list
 * @author daviwegner
 * @version 2.3
 *
 * @description Added iOS bottom padding for escaping virtual nav keyboard
 * @author brunoteixeirasilva
 * @version 2.4
 *
 * @description Deleted card holder and implemented dinamic load of components in dashItem
 * @author Wegner
 * @version 2.5
 *
 * @description The load function of this component has been removed,
 *  thus leaving this component only with the rendering function.
 * @author lopes
 * @version 2.6
 */
class DashSetMobile extends React.Component {

	componentDidMount() {
		const { onSetLocalFilters, activeCompanyId, pluginId } = this.props

		onGetLocalFilters(onSetLocalFilters, defaultLocalFilterGet, {
			pluginId: pluginId,
			companyId: activeCompanyId,
			process: process.env.REACT_APP_NODE_ENV.trim(),
		})
	}

	/**
	 * Will keep a filters listener
	 */
	filterListener = null
	handleRemount = dashItemKey => {
		const { remount, onSetComponentRemount } = this.props
		onSetComponentRemount(dashItemKey, !remount[dashItemKey])
	}

	render() {
		const {
			classes,
			dashSets,
			dashItems,
			onToggleModal,
			dashSetsLoading,
			showSwipe,
			dashSetKeys,
			remount,
			draggableContainer,
			onSwitchDashSet,
			activeDashSet,
			appTitle,
			onSwitchDashItem,
			activeDashItem
		} = this.props

		return (
			<div
				className={classNames(classes.root, {
					[classes.rootIos]: deviceIsIos(),
				})}>
				{dashItems &&
					dashSets &&
					activeDashSet &&
					dashSets[activeDashSet] && (
						<div className={classes.location}>
							<Typography
								className={classes.locationText}
								variant="caption">
								{`${appTitle}/${dashSets[activeDashSet].name}`}
							</Typography>
						</div>
					)}
				{!dashSets && !dashSetsLoading && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							textAlign: 'center',
							width: '100%',
						}}>
						<p
							style={{
								fontSize: '25px',
								marginBottom: '20px',
								marginTop: '20px',
							}}>
							Carregando Cards ...
						</p>

						<Loader internal />
					</div>
				)}
				{!dashSets && !!dashSetsLoading && <Loader internal />}
				{!!dashSetKeys && (
					<DashSetView
						dashSetKeys={dashSetKeys}
						onSwitchDashSet={onSwitchDashSet}
						showSwipe={showSwipe}
						activeDashSet={activeDashSet}>
						{!!dashItems &&
							dashSetKeys.map((dashSetKey, i) => (
								<DashItemView
									key={i}
									activeDashSet={activeDashSet}
									currentIndex={'wip'}
									dashItemKeys={
										!!dashItems &&
										!!dashItems[dashSetKey] &&
										Object.keys(dashItems[dashSetKey])
									}
									onSwitchDashItem={onSwitchDashItem}
									activeDashItem={activeDashItem}>
									{!!dashItems[dashSetKey] &&
										Object.keys(dashItems[dashSetKey]).map(
											dashItemKey => {
												let dashItem =
													dashItems[dashSetKey][
														dashItemKey
													]

												return (
													<DashItem
														key={
															dashItemKey +
															remount[dashItemKey]
														}
														mobile
														handleRemount={
															this.handleRemount
														}
														dashItemId={dashItemKey}
														borderLess={
															!!dashItem.borderLess
														}
														dashItem={dashItem}
														draggableContainer={
															draggableContainer
														}
														onToggleModal={
															onToggleModal
														}
													/>
												)
											},
										)}
								</DashItemView>
							))}
					</DashSetView>
				)}
				<DialogModal cardId={activeDashItem} />
			</div>
		)
	}
}

export const styles = theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		...getDesktopBreakpoint(theme, {
			alignItems: 'flex-start',
			flexDirection: 'row',
			justifyContent: 'space-between',
		}),
		[theme.breakpoints.up('lg')]: {
		},
	},
	gridItem: {
		height: '50vh',
		[theme.breakpoints.down('xl')]: {
			height: '75vh',
		},
		[theme.breakpoints.down('md')]: {
			height: '70vh',
		},
	},
	rootIos: {
		paddingBottom: 0,
		// paddingBottom: 'env(safe-area-inset-bottom)',
	},
	dashItemsContainer: {
		margin: '0px',
		padding: '6px',
	},
	dashSetFullWidth: {
		flex: '0 0 auto',
		width: '100%',
	},
	dashSet: {
		flex: '0 0 auto',
		width: '100%',
		...getDesktopBreakpoint(theme, {
			width: '49.5%',
		}),
		[theme.breakpoints.up('lg')]: {
			width: '49.5%',
			'&:nth-last-child()': {
				marginRight: 0,
			},
		},
	},

	headline: {
		color: 'rgba(74, 36, 135, 0.56)',
	},
	cardList: {
	},
	headlineDivider: {
		marginBottom: '.8em',
		marginLeft: '-' + theme.spacing.unit * 2 + 'px',
		marginRight: '-' + theme.spacing.unit * 2 + 'px',
		...getDesktopBreakpoint(theme, {
			marginLeft: 0,
			marginRight: 0,
		}),
	},
	parametersForm: {
	},
	paper: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
	},
	row: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
	bolderText: {
		fontWeight: '500 !important',
	},
	location: {
		textAlign: 'left',
		width: '100%',
		paddingTop: '5px',
	},
	locationText: {
		color: 'rgba(149, 117, 205,0.63)'
	},
})

DashSetMobile.propTypes = {
	classes: PropTypes.object.isRequired,
}

const makeMapStateToProps = () => {
	const getActivePlugin = makeGetActivePlugin()

	const mapStateToProps = state => ({
		activePlugin: getActivePlugin(state),
		pluginId: Object.keys(state.pluginState.activePlugin)[0],
	})

	return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
	onSetLocalFilters: async (key, filterLocalData) =>
		dispatch({ type: 'SET_FILTER_LOCAL_DATA', key, filterLocalData }),
})

export default compose(
	connect(makeMapStateToProps(), mapDispatchToProps),
	withStyles(styles),
)(DashSetMobile)
