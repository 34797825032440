import { MANAGE_RANKING_QUESTION_LOADING_STATE, LOAD_RANKING_QUESTION_DATA, SET_RANKING_QUESTION_PERIOD } from '../../actions/rankingQuestion'

const INITIAL_STATE = {
	isLoading: null,
	localFilters: null,
	data: null,
	dateConfig: {},
	update: false,
	doQuery: false,
	ownFilters: {},
	searchText: {},
	idLocalFilter: {
		forms: null,
		localClients: null,
		localLocalities: null,
		objects: null,
		localTypeObjects: null,
		questions: null,
		steps: null,
		statusRespAudit: null,
		autochecksRespAudit: null,
		localBases: null,
		collaborators: null,
	},
}

const clearAllLocalFilters = state => {
	let keys = Object.keys(state.idLocalFilter),
		clearFilters = {}

	keys.forEach(key => {
		clearFilters[key] = null
	})

	return clearFilters
}

/**
 * NC stage state reducer manage
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const rankingQuestionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_RANKING_QUESTION_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case LOAD_RANKING_QUESTION_DATA: {
			return Object.assign({}, state, {
				data: action.data,
				isLoading: false,
			})
		}
		case 'SET_RANKING_QUESTION_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_RANKING_QUESTION_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_RANKING_QUESTION_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
				idLocalFilter: clearAllLocalFilters(state),
			})
		}
		case 'SET_LOCAL_FILTER': {
			return Object.assign({}, state, {
				localFilters: action.localFilters,
			})
		}
		case 'SET_SEARCH_TEXT': {
			let searchText = {}
			action.objectKeys.forEach(item => {
				searchText[item] = ''
			})
			return Object.assign({}, state, {
				searchText: searchText,
			})
		}
		case 'SET_ID_LOCAL_FILTERS': {
			return Object.assign({}, state, {
				idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter),
			})
		}
		case 'SET_DO_QUERY': {
			return Object.assign({}, state, {
				doQuery: !state.doQuery,
			})
		}
		case SET_RANKING_QUESTION_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign({}, state.dateConfig.selectedValues, {
						[action.formType]: action.value,
					}),
				},
				isLoading: true,
			})
		}
		default:
			return state
	}
}

export default rankingQuestionReducer
