export const SET_EMAIL = 'SET_EMAIL'
export const SET_NAME = 'SET_NAME'
export const SET_ID_USER = 'SET_ID_USER'
export const SET_STAR = 'SET_STAR'
export const SET_ACOES = 'SET_ACOES'
export const SET_ASSERTVIVENESS = 'SET_ASSERTIVENESS'
export const SET_CREATIVENESS = 'SET_CREATIVENESS'
export const SET_GOOD_COMMUNICATION = 'SET_GOOD_COMMUNICATION'
export const SET_IMPROVED_OUT_COME = 'SET_IMPROVED_OUT_COME'
export const SET_QUICKNESS = 'SET_QUICKNESS'
export const SET_DELAYED_SOLUTION = 'SET_DELAYED_SOLUTION'
export const SET_IMPROVE_COMMUNICATIONS = 'SET_IMPROVE_COMMUNICATIONS'
export const SET_RESULTS_NOT_IMPROVED = 'SET_RESULTS_NOT_IMPROVED'
export const SET_ELOGIOS = 'SET_ELOGIOS'
export const SET_MELHORIAS = 'SET_MELHORIAS'
export const SET_COMENTARIOS = 'SET_COMENTARIOS'
export const SET_DATA_FINAL = 'SET_DATA_FINAL'

const INITIAL_STATE = {
	email: '',
	nome: '',
	idUser: '',
	star: 0,
	acoes: null,
	assertiveness: null,
	creativeness: null,
	goodCommunication: null,
	improvedOutCome: null,
	quickness: null,
	delayedSolution: null,
	improveCommunications: null,
	resultsNotImproved: null,
	elogios: null,
	melhorias: null,
	comentarios: '',
	dataFinal: '',
}

const avaliationsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_EMAIL:
			return { ...state, email: action.text }
		case SET_NAME:
			return { ...state, nome: action.text }
		case SET_ID_USER:
			return { ...state, idUser: action.number }
		case SET_STAR:
			return { ...state, star: action.number }
		case SET_ACOES:
			return { ...state, acoes: action.number }
		case SET_ASSERTVIVENESS:
			return { ...state, assertiveness: action.number }
		case SET_CREATIVENESS:
			return { ...state, creativeness: action.number }
		case SET_GOOD_COMMUNICATION:
			return { ...state, goodCommunication: action.number }
		case SET_IMPROVED_OUT_COME:
			return { ...state, improvedOutCome: action.number }
		case SET_QUICKNESS:
			return { ...state, quickness: action.number }
		case SET_DELAYED_SOLUTION:
			return { ...state, delayedSolution: action.number }
		case SET_IMPROVE_COMMUNICATIONS:
			return { ...state, improveCommunications: action.number }
		case SET_RESULTS_NOT_IMPROVED:
			return { ...state, resultsNotImproved: action.number }
		case SET_ELOGIOS:
			return { ...state, elogios: action.number }
		case SET_MELHORIAS:
			return { ...state, melhorias: action.number }
		case SET_COMENTARIOS:
			return { ...state, comentarios: action.text }
		case SET_DATA_FINAL:
			return { ...state, dataFinal: action.data }
		default:
			return state
	}
}

export default avaliationsReducer
