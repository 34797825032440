import { auth, fs } from '../firebase'
import * as Author from '../../utils/security/author'
import { store } from '../../store'
import { clearUserActiveCompany } from '../../actions/user/company'

/**
 * [Firestore] Gets an user list (from root /users)
 *
 * @description Gets Firebase RTDB entries at /users in a batch.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now gets Firebase Firestore /users.
 * 				Name changed from [onGetUsers] to [getAll]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @author Ray
 * @version 1.3
 */
export const getAll = () => {
	return fs.collection('users').orderBy('name', 'asc').get()
	// return db.ref(`users`).on('value', thenFunc)
}

export const createLog = logData => {
	fs.collection('logs')
		.doc()
		.set({ ...logData, time: new Date() })
}

export const getLogs = companyId => {
	return fs
		.collection('logs')
		.where('companyId', '==', companyId)
		.orderBy('time', 'desc')
		.get()
}

export const getDashItemByID = async id => {
	let dashTitle = 'Dash não encontrado'
	await fs
		.collection('dashItems')
		.doc(id)
		.get()
		.then(fields => {
			if (fields.data()) {
				dashTitle = fields.data().title
			}
		})

	return dashTitle
}

export const getUserIsDev = async userId => {
	let isDev = false
	await fs
		.collection('users')
		.doc(userId)
		.get()
		.then(fields => {
			if (fields.data()) {
				isDev = !!fields.data().isDev ? fields.data().isDev : false
			}
		})

	return isDev
}

export const getEmailUserByUID = async userId => {
	let email = 'Usuário(a) não encontrado(a)'
	await fs
		.collection('users')
		.doc(userId)
		.get()
		.then(fields => {
			if (fields.data()) {
				email = fields.data().email
			}
		})

	return email
}

export const getNameUserByUID = async userId => {
	let name = 'Nome não encontrado'
	await fs
		.collection('users')
		.doc(userId)
		.get()
		.then(fields => {
			if (fields.data()) {
				name = fields.data().name
			}
		})

	return name
}

export const getPluginNameByPID = async pluginId => {
	let pluginName = 'Plugin não encontrado'
	if (pluginId !== null && pluginId !== undefined) {
		await fs
			.collection('plugins')
			.doc(pluginId)
			.get()
			.then(fields => {
				if (fields.data()) {
					pluginName = fields.data().name
				}
			})
	}
	return pluginName
}

export const getFileNameByProfileID = async profileId => {
	let fileName = 'null'
	if (profileId !== null && profileId !== undefined) {
		await fs
			.collection('uploadProfiles')
			.doc(profileId)
			.get()
			.then(fields => {
				if (fields.data()) {
					fileName = fields.data().title
				}
			})
	}
	return fileName
}
/**
 * [Firestore] Gets an user based on an Firebase Auth UID
 *
 * @description Gets Firebase RTDB entry at /users by UID individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now gets Firebase Firestore /users.
 * 				Name changed from [onGetUser] to [get]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid Firebase Auth user uid
 */
export const get = uid => fs.collection(`users`).doc(uid).get()

/**
 * [Auth] Does the creation of an user at Firebase Auth
 *
 * @param {Object} user user information object containing props "email" and "password"
 */
export const doCreateUser = user =>
	auth.doCreateUserWithEmailAndPassword(user.email, user.password)

/**
 * [Firestore] Does the creation of an user entry at Firebase Realtime Database "/users"
 *
 * @description Sets Firebase RTDB entry at /users by UID individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now sets Firebase Firestore /users.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {firebase.User} createdUserFromCredential
 */
export const doCreateUserEntry = createdUserFromCredential => {
	const { uid } = createdUserFromCredential
	// Structures a "/users" entry information object
	// And also defines a responsible for it
	const userInfo = {
		email: createdUserFromCredential.email,
		name: createdUserFromCredential.name,
		...Author.generateObject(auth.currentUser.uid),
	}

	return fs.collection('users').doc(uid).set(userInfo, { merge: true })
	// return db.ref(`users/${uid}`).set({ ...userInfo })
}

/**
 * [Firestore] Updates an user information, based on a Firebase Auth uid
 *
 * @description Updates Firebase RTDB entry at /users by UID individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now updates Firebase Firestore /users.
 * 				Name changed from [onceGetUsers] to [getUsers]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid A Firebase Auth user uid
 * @param {Object} user The user object to be set at the graph
 */
export const doUpdateUser = (uid, user) => {
	const ref = fs.collection('users').doc(uid)
	// db.ref(`users/${uid}`).update({ ...user })
	return ref.update(user)
}

/**
 * Will query for the user companies ids, at accounts on the /accounts entry.
 * Note that if "thenFunc" is passed null, it will return a Promise
 *
 * @param {string} uid The user having the company added to its account
 * @param {Function} thenFunc The resolve function (will be invoked within the method)
 */
// export const onceGetCompany = (uid, thenFunc = null) => {
// 	if (!!thenFunc && 'function' !== typeof thenFunc) {
// 		return onceGetCompanyIds(uid).then(thenFunc)
// 	} else {
// 		return onceGetCompanyIds(uid).then(dataSnapshot => {
// 		})
// 	}
// }

/**
 * Will query for the user companies ids, at accounts on the /accounts entry.
 * Note that if "thenFunc" is passed null, it will return a Promise
 *
 * @param {string} uid The user having the company added to its account
 * @param {Function} thenFunc The resolve function (will be invoked within the method)
 */
export const onceGetCompanyIds = uid => {
	throw new Error('method-not-implemented')
}

/**
 * [Firestore] Will set the passed UID an "activeCompanyId" prop at => /users/${uid} entry
 *
 * @description Updates Firebase RTDB entry at /users by UID individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now updates Firebase Firestore /users.
 * 				Name changed from [onceGetUsers] to [getUsers]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid The user having the prop set
 * @param {string} companyId The company being set as "active"
 */
export const doSetActiveCompany = (uid, companyId) => {
	return fs
		.collection('users')
		.doc(uid)
		.set({ activeCompany: companyId }, { merge: true })
}

/**
 * [Firestore] Will query for the user active company at the /users entry.
 * Note that if "thenFunc" is passed null, it will return a Promise
 *
 * @description Queries Firebase RTDB entry at /users by UID individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now queries Firebase Firestore /users.
 * 				Name changed from [onceGetActiveCompany] to [getActiveCompany]
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid The user having the company added to its account
 */
export const findActiveCompanyId = uid => {
	return fs
		.collection('users')
		.doc(uid)
		.get()
		.then(doc => (doc.exists ? doc.data().activeCompany : null))
}

/**
 * Firestore observable for active company changes.
 *
 * Return an object with two complementary methods:
 * * subscribe - to subscribe for changes
 * * unsubscribe - to cancel subscription
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 */
export const observeUserCompany = function (uid) {
	// referece for session user document in firestore
	const ref = fs.collection(`users`).doc(uid)

	/**
	 * Subscribe client for user company updates.
	 * Ex: user is fired and your active company is setted as null
	 *
	 * @param {String} company Current active company
	 */
	const subscribe = company =>
		ref.onSnapshot({ includeMetadataChanges: true }, doc => {
			const { activeCompany } = doc.data()
			// dispatch action if active company has change
			if (
				(company !== activeCompany && activeCompany === undefined) ||
				activeCompany === null
			)
				store.dispatch(clearUserActiveCompany())
		})

	// /**
	//  * Unsubscribe client for updates.
	//  */
	// const unsubscribe = () => subscribe(company)

	return {
		subscribe,
	}
}

/**
 * @description Created the function
 * @author davispindola
 * @version 1.0
 */
export const getUsersFromActiveCompany = activeCompanyId =>
	fs.collection('companies').doc(activeCompanyId).collection('users').get()

/**
 * Gets an user from firebase specific company / user-uid
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} activeCompanyId The current active company
 * @param {string} uid The currently in question user
 */
export const getUserFromActiveCompany = (activeCompanyId, uid) =>
	fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('users')
		.doc(uid)
		.get()
