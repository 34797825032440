import { fs, auth } from '../../firebase'
import * as Author from '../../../utils/security/author'
import * as ObjectState from '../../../utils/object/state'

/**
 * Queries for a [COMPANY-OWN] permission specifically
 * ! Only useful for [COMPANY-OWN] permissions
 *
 * @param {string} permissionId The permission being queried
 */
export const get = (companyId, permissionId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('permissions')
		.doc(permissionId)
		.get()
}

/**
 * Will query all allowed [COMPANY-own] permissions
 * ! Only useful for [COMPANY-OWN] permissions
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The company Id to get allowed permissions
 */
export const getAll = (companyId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('permissions')
		.get()
}

/**
 * Creates a company permission object
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active company ID
 * @param {Object} data The data to be saved
 */
export const doCreate = (companyId, data) => {
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('permissions')
		.doc()
	const permissionObject = Object.assign(
		{},
		Object.assign(
			{},
			ObjectState.generateActive(data.active),
			Author.generateObject(auth.currentUser.uid)
		),
		data
	)

	return ref.set(permissionObject)
}

/**
 * Updates a company permission object
 * ! Works only for COMPANY-SPECIFIC permissions
 * TODO: Review the set behaviour (replace authority)
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active company ID
 * @param {string} permissionId The current permission ID
 * @param {Object} data The data to be saved
 */
export const doUpdate = (companyId, permissionId, data) => {
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('permissions')
		.doc(permissionId)
	const permissionObject = Object.assign(
		{},
		Object.assign(
			{},
			ObjectState.generateActive(),
			Author.generateObject(auth.currentUser.uid)
		),
		data
	)

	return ref.set(permissionObject, { merge: true })
}
/**
 * Make filter  relation
 * @author Davi Wegner
 * @version 1.0
 */
export const makeFilterRelation = (companyId, permissionId, filterId) => {
	let filterObject = Object.assign(
		{},
		ObjectState.generateActive(),
		Author.generateObject(auth.currentUser.uid)
	)
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('permissions')
		.doc(permissionId)
		.update(`filters.${filterId}`, filterObject)
}
/**
 * Unmake filter relation
 * @author Davi Wegner
 * @version 1.0
 */
export const unmakeFilterRelation = (companyId, permissionId, filterId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('permissions')
		.doc(permissionId)
		.update(`filters.${filterId}.active`, false)
}
