import {
	SET_OBJECT_NCS_DATA,
	SET_OBJECT_NCS_STATE_LOADING
} from '../../actions/objectNcs'

const INITIAL_STATE = {
	isLoading: false,
	data: null
}

const objectNcReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_OBJECT_NCS_DATA: {
			return Object.assign({}, state, { data: action.data })
		}

		case SET_OBJECT_NCS_STATE_LOADING: {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		default:
			return state
	}
}
export default objectNcReducer
