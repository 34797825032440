const INITIAL_STATE = {
	groupUsers: null,
	isLoading: false
}

/**
 * Clears group users listed previously
 *
 * @param {Object} state The current system state
 */
const clearGroupUsers = (state) => {
	return Object.assign({}, state, {
		groupUsers: INITIAL_STATE.groupUsers
	})
}

/**
 * Adds a group user singularly to the group
 *
 * @param {Object} state The current system state
 * @param {Object} action The current action object with a prop "groupUser"
 */
const groupUserLoaded = (state, action) => {
	return Object.assign({}, state, {
		groupUsers: Object.assign({}, state.groupUsers, action.groupUser)
	})
}

const removeGroupUser = (state, action) => {
	return Object.assign({}, state, {
		groupUsers: Object.keys(state.groupUsers).reduce((acc, val) => {
			if (action.uid !== val) {
				acc[val] = state.groupUsers[val]
			}
			return acc
		}, {})
	})
}

/**
 * Manages a group users' state
 *
 * @param {Object} state
 * @param {Object} action
 */
function groupUserReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CLEAR_GROUP_USERS': {
			return clearGroupUsers(state)
		}
		case 'GROUP_USER_LOADED': {
			return groupUserLoaded(state, action)
		}
		case 'REMOVE_GROUP_USER': {
			return removeGroupUser(state, action)
		}
		case 'SET_GROUP_USER_IS_LOADING':
			return { ...state, isLoading: action.isLoading }
		default:
			return state
	}
}

export default groupUserReducer
