export const MANAGE_MAX_TEMPERATURE_LOADING_STATE =
		'MANAGE_MAX_TEMPERATURE_LOADING_STATE',
	LOAD_MAX_TEMPERATURE_DATA = 'LOAD_MAX_TEMPERATURE_DATA',
	SET_MAX_TEMPERATURE_PERIOD = 'SET_MAX_TEMPERATURE_PERIOD'

export const setMaxTemperaturePeriod = (dateConfig, value, formType) => ({
	type: SET_MAX_TEMPERATURE_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadMaxTemperatureData = (maxTemperature) => ({
	type: LOAD_MAX_TEMPERATURE_DATA,
	maxTemperature
})

export const setLoading = (isLoading) => ({
	type: MANAGE_MAX_TEMPERATURE_LOADING_STATE,
	isLoading
})
