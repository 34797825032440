import React from 'react'
import { createLog } from '../../../firebase/user'
import { makeGetUserActiveCompanyId } from '../../../selectors/user/company'
import { connect } from 'react-redux'
import { getAuthUser, getAuthUserID } from '../../../selectors/session'
import { fs } from '../../../firebase/firebase'

/**
 * @author Wegner
 * @email wegner@arquia.com.br
 * @created 28-06-2020
 */
const withLogger = (type, onEnter, props = []) => Component => {
	class WithLogger extends React.Component {
		componentDidMount() {
			if (onEnter) this.createLog(type, {}, props)
		}
		getProps = () => {
			let compProps = this.props,
				propsToPass = {}
			if (props && props.length > 0) {
				propsToPass = props.reduce((acc, crr) => {
					let value
					if (crr.name)
						value = crr.path.split('_').reduce((ac, cr) => {
							ac = ac[cr]
							return ac
						}, compProps)
					acc[crr.name ? crr.name : crr] = value
					return acc
				}, {})
			}
			return propsToPass
		}
		createLog = async (type = type, otherProps, propsToPass) => {
			const { authUser, authUserData, crrCompanyId } = this.props.compInfo
			const uid = authUser
			let isDev = false

			await fs
				.collection('users')
				.doc(uid)
				.get()
				.then(doc => {
					isDev = !!doc.data().isDev ? doc.data().isDev : false
				})

			let device = this.getDeviceInfo()

			createLog({
				...device,
				type,
				isDev,
				uid: authUser,
				email: authUserData.email,
				...otherProps,
				...this.getProps(propsToPass),
				companyId: crrCompanyId,
				env: process.env.REACT_APP_NODE_ENV,
			})
		}
		getDeviceInfo = () => {
			let device = {}
			if (window.cordova) {
				return {
					platform: window.device.platform,
					model: window.device.model,
				}
			} else if (
				window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1
			)
				device.platform = 'Windows 10'
			if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1)
				device.platform = 'Windows 8'
			if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1)
				device.platform = 'Windows 7'
			if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1)
				device.platform = 'Windows Vista'
			if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1)
				device.platform = 'Windows XP'
			if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1)
				device.platform = 'Windows 2000'
			if (window.navigator.userAgent.indexOf('Mac') !== -1)
				device.platform = 'Mac/iOS'
			if (window.navigator.userAgent.indexOf('X11') !== -1)
				device.platform = 'UNIX'
			if (window.navigator.userAgent.indexOf('Linux') !== -1)
				device.platform = 'Linux'
			device.model = navigator.vendor
			return device
		}
		render() {
			const { compInfo, ...otherProps } = this.props
			// console.log(this.props)
			return (
				<>
					<Component
						{...otherProps}
						{...(!onEnter ? { createLog: this.createLog } : {})}
					/>
				</>
			)
		}
	}
	const getActiveCompany = makeGetUserActiveCompanyId(),
		mapStateToProps = state => {
			return {
				compInfo: {
					crrCompanyId: getActiveCompany(state),
					authUser: getAuthUserID(state),
					authUserData: getAuthUser(state),
				},
			}
		},
		mapDispatchToProps = dispatch => ({})
	return connect(mapStateToProps, mapDispatchToProps)(WithLogger)
}

export default withLogger
