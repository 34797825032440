import {
	SET_COMPANY_DASH_ITEM,
	SET_COMPANY_DASH_ITEM_LOADING,
	SET_COMPANY_DASH_ITEM_DATA
} from '../../../actions/company/dashItem'
import { CLEAN_ON_COMPANY_CHANGE } from '../../../actions/company/index.js'

const INITIAL_STATE = {
	data: null,
	dashItems: null,
	isLoading: null
}

/**
 * Sets dashItems loading state to the redux-state layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current redux-state for this reducer
 * @param {Object} action The action with "type" and desired props
 */
const setLoading = (state, action) =>
	Object.assign({}, state, {
		isLoading: Object.assign({}, state.isLoading, {
			[action.dashItemId]: action.isLoading
		})
	})

/**
 * Sets a dash item **data** with its data to the redux-state layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current redux-state for this reducer
 * @param {Object} action The action with "type" and desired props
 */
const setDashItemData = (state, action) =>
	Object.assign({}, state, {
		data: Object.assign({}, state.data, {
			[action.dashItemId]: { ...action.data }
		}),
		isLoading: Object.assign({}, state.isLoading, {
			[action.dashItemId]: false
		})
	})

/**
 * Sets a dash item with its data to the redux-state layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current redux-state for this reducer
 * @param {Object} action The action with "type" and desired props
 */
const setDashItem = (state, action) =>
	Object.assign({}, state, {
		dashItems: Object.assign({}, state.dashItems, {
			[action.dashItemId]: { ...action.dashItem }
		}),
		isLoading: Object.assign({}, state.isLoading, {
			[action.dashItemId]: false
		})
	})

/**
 * Dash Items reducer responsible for configuration properties. Each companie in system have your own dash item especific rules.
 * Example: dash item 'A' of company 'B' can only watch for 'client' filters. This definition come from firebase.
 *
 * @description Created the reducer
 * @author davispindola
 * @version 1.0.0
 *
 * @description Changed reducer functions to separate function for maintainability
 * @author brunoteixeirasilva
 * @version 1.1.0
 */
const companyDashItemReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_COMPANY_DASH_ITEM_LOADING: {
			return setLoading(state, action)
		}
		case SET_COMPANY_DASH_ITEM: {
			return setDashItem(state, action)
		}
		case SET_COMPANY_DASH_ITEM_DATA: {
			return setDashItemData(state, action)
		}
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default companyDashItemReducer
