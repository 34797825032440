export const MANAGE_PODIUM_RANKING_AUDITS_LOADING_STATE =
		'MANAGE_PODIUM_RANKING_AUDITS_LOADING_STATE',
	LOAD_PODIUM_RANKING_AUDITS_DATA = 'LOAD_PODIUM_RANKING_AUDITS_DATA',
	SET_PODIUM_RANKING_AUDITS_PERIOD = 'SET_PODIUM_RANKING_AUDITS_PERIOD'

export const setPodiumRankingAuditsPeriod = (dateConfig, value, formType) => ({
	type: SET_PODIUM_RANKING_AUDITS_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadPodiumRankingAuditsData = (podiumRankingAudits) => ({
	type: LOAD_PODIUM_RANKING_AUDITS_DATA,
	podiumRankingAudits
})

export const setLoading = (isLoading) => ({
	type: MANAGE_PODIUM_RANKING_AUDITS_LOADING_STATE,
	isLoading
})
