import { translate } from '../lang'

/**
 * Calculates the **days** count from **_epoch_**
 * and returns a number (count)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {number} mili The time, in miliseconds, for convertion
 */
const fromMiliTimeToDays = (mili) => {
	return mili / 1000 / 60 / 60 / 24
}

/**
 * Calculates between two times/miliseconds date
 * and returns the day count difference
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {number} fromMili The initial date of the range
 * @param {number} toMili The final date of the range
 */
const getDaysDifferenceFromMiliseconds = (fromMili, toMili) => {
	return fromMiliTimeToDays(toMili) - fromMiliTimeToDays(fromMili)
}

/**
 * Calculates a past date with the quantity of days and [optional] max bound date
 *
 * @description Created the function
 * @author daviwegner
 * @version 1.0
 *
 * @description Edited the function
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Edited the function, when days > than 0 then we must decrease 1
 * , because we count the difference of days, not the number of days
 * @author Davi Wegner
 * @version 1.2
 *
 * @param {number} days Days to discount from today
 * @param {Date} maxDate Max range of the date, from where to discount days
 * @param {Boolean} endOfDay [optional] in case true, will be set to 23:59h
 */
const getPastDate = (days, maxDate = new Date(), endOfDay = false) => {
	const pastDate = new Date()
	let pastDateString,
		validDays = days > 0 ? days - 1 : days
	//Subtracts the specified amount of the date, in days
	pastDate.setDate(maxDate.getDate() - validDays)

	//Builds the ISO string
	pastDateString = getISODate(pastDate, endOfDay)

	//return date iso string in ${days} behind
	return pastDateString
}

/**
 * Get an ISO formatted date-time, considering only Date values. Time will be zero
 *
 * @description Created the function
 * @author daviwegner
 * @version 1.0
 *
 * @description Edited the function
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Date} date [optional] The reference date to get the ISO string from
 * @param {Boolean} endOfDay [optional] in case true, will be set to 23:59h
 */
const getISODate = (date = new Date(), endOfDay = false) => {
	//When it is the end of the day,
	//Sets latest second of the day
	// console.log(date)
	if (!!endOfDay) date.setHours(23, 59, 59, 999)
	//Else, sets first second of the day
	else date.setHours(0, 0, 0, 0)

	//Resolves the common date
	return date.toISOString()
}

/**
 * Will apply a date correction and return a valid
 * date object, with the careful of applying year-diff.
 * It means: If you have a january (current) month though you
 * need a december (past) month set, the year is subtracted
 *
 * @description
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} date The Date object to be corrected
 * @param {Number} newMonth Number reprensenting 1-to-12 month reference
 * @param {Number} newDay Number representing the 1-to-28/29/30/31 day in the month
 */
const applyCorrection = (date, newMonth, newDay) => {
	let correctedDate = new Date(date.getTime())

	//FIXME: In the case we are in a new-year,
	//meaning the current month is minor than
	//last year final months, then
	//an year correction should be applied
	// if (correctedDate.getMonth() > newMonth) {
	// 	debugger
	// 	correctedDate.setFullYear(date.getFullYear() - 1)
	// }

	//Corrects the month and day
	//to the correct ones
	correctedDate.setDate(newDay)
	correctedDate.setMonth(newMonth - 1)

	return correctedDate
}

/**
 * Will provide you with the short formatted month
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} fullDate The full-date to be formatted, or null (new Date)
 */
const getShortMonth = (fullDate = new Date()) =>
	translate(`date/month/short/${fullDate.getMonth()}`)

/**
 * Will provide you with the long formatted month
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} fullDate The full-date to be formatted, or null (new Date)
 */
const getLongMonth = (fullDate = new Date()) =>
	translate(`date/month/long/${fullDate.getMonth()}`)

/**
 * Will provide you with the day-of-month (int)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} fullDate The full-date to have a day extracted, or null (new Date)
 */
const getCurrentDayOfMonth = (fullDate = new Date()) => {
	let d = !(fullDate instanceof Date) ? new Date(fullDate) : fullDate

	return getStandardDate(d).getDate()
}

/**
 * Will provide you with the month (int)
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Date} fullDate The full-date to have a month extracted, or null (new Date)
 */
const getCurrentMonth = (fullDate = new Date()) => {
	let m = !(fullDate instanceof Date) ? new Date(fullDate) : fullDate

	return getStandardDate(m).getMonth()
}

/**
 * Will provide you with the current formatted date
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {String} locale Defaults to the system one. Can be passed on to be based on it
 */
const getCurrentDate = (locale = 'pt-BR') =>
	getFormattedFullYear(new Date(), false, locale)

/**
 * Will provide you with the long formatted day-of-week
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} fullDate The full-date to be formatted, or null (new Date)
 */
const getLongDayOfWeek = (day) => translate(`date/dayOfWeek/long/${day}`)

/**
 * Will provide you with the medium formatted day-of-week
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} fullDate The full-date to be formatted, or null (new Date)
 */
const getMediumDayOfWeek = (day) => translate(`date/dayOfWeek/medium/${day}`)

/**
 * Will provide you with the short formatted day-of-week
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} fullDate The full-date to be formatted, or null (new Date)
 */
const getShortDayOfWeek = (day) => translate(`date/dayOfWeek/short/${day}`)

/**
 * Will provide a SQL query formatted date (no time with it)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} uglyDate The ugly date to be formatted
 * @param {String} locale Defaults to the system one. Can be passed on to be based on it
 */
const getANSI = (uglyDate = null, locale = 'pt-BR') => {
	let finalDate = !!uglyDate
			? !(uglyDate instanceof Date)
				? new Date(uglyDate)
				: uglyDate
			: new Date(),
		day = finalDate.getDate().toLocaleString(locale),
		month = (finalDate.getMonth() + 1).toLocaleString(locale),
		year = finalDate.getFullYear()

	return `${year}-${month}-${day}`
}

/**
 * Will provide an UI formatted date (full-year)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} uglyDate The ugly date to be formatted
 * @param {boolean} applyTimezoneCorrection Applying correction or not
 * @param {String} locale Defaults to the system one. Can be passed on to be based on it
 */
const getFormattedFullYear = (
	uglyDate = null,
	applyTimezoneCorrection = true,
	locale = 'pt-BR'
) => {
	//Tries to get a valid date from uglyDate, or, creates a new one
	let finalDate = !!uglyDate
		? !(uglyDate instanceof Date)
			? new Date(uglyDate)
			: uglyDate
		: new Date()

	return !!applyTimezoneCorrection
		? getStandardDate(finalDate).toLocaleDateString(locale)
		: finalDate.toLocaleDateString(locale)
}

/**
 * Will provide an UI formatted date (short-day-and-month)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} uglyDate The ugly date to be formatted
 * @param {String} locale Defaults to the system one. Can be passed on to be based on it
 */
const getFormattedShortDayMonth = (uglyDate = null, locale = 'pt-BR') => {
	//Tries to get a valid date from uglyDate, or, creates a new one
	let finalDate = !!uglyDate
		? !(uglyDate instanceof Date)
			? new Date(uglyDate)
			: uglyDate
		: new Date()

	finalDate = getStandardDate(finalDate)

	return `${finalDate
		.getDate()
		.toString()
		.padStart(2, '0')}-${(finalDate.getMonth() + 1)
		.toString()
		.padStart(2, '0')}`
}

/**
 * Will provide an UI formatted date (short-year)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} uglyDate The ugly date to be formatted
 * @param {String} locale Defaults to the system one. Can be passed on to be based on it
 */
const getFormattedShortYear = (uglyDate = null, locale = 'pt-BR') => {
	//Tries to get a valid date from uglyDate, or, creates a new one
	let finalDate = !!uglyDate
		? !(uglyDate instanceof Date)
			? new Date(uglyDate)
			: uglyDate
		: new Date()

	return getStandardDate(finalDate).toLocaleDateString(locale, {
		month: '2-digit',
		day: '2-digit',
		year: '2-digit'
	})
}

/**
 * Will get a timezone-standard date, normalize calculations
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} date Date to base the timezone offset getter
 */
const getStandardDate = (date) => {
	let offset = getTimeZoneOffset(date)

	//debugger

	//FIXME: Correct this calculation
	//Timezone correction has to be applied?
	// if (getTimeZoneOffset(new Date()) === offset) {
	// 	//debugger
	// 	return date
	// }

	return new Date(offset + date.getTime())
}
/**
 * Gets a timezone offset, in order to standardize calculations
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Date} date Date to base the timezone offset getter
 */
const getTimeZoneOffset = (date) => date.getTimezoneOffset() * 60000

export {
	getDaysDifferenceFromMiliseconds,
	getCurrentDate,
	getCurrentDayOfMonth,
	getLongMonth,
	getShortMonth,
	getLongDayOfWeek,
	getMediumDayOfWeek,
	getShortDayOfWeek,
	getStandardDate,
	fromMiliTimeToDays,
	getANSI,
	getPastDate,
	getISODate,
	getFormattedFullYear,
	getFormattedShortYear,
	getFormattedShortDayMonth,
	applyCorrection,
	getCurrentMonth
}
