const styles = (theme) => ({
	button: {
		padding: 0,
		marginTop: theme.spacing.unit * 1.5,
		color: 'rgb(255,0,0)'
	},
	buttonEvaluated: {
		padding: 0,
		marginTop: theme.spacing.unit * 1.5
	}
})

export default styles
