import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import EvaluationButton from '../components/button'
import { setEvaluationDialogOpen } from '../../../../reducers/systemEvaluation/dialog'
import makeGetEvaluationButtonState from '../../../../selectors/action/evaluation/button'
import { compose } from 'recompose'

/**
 * Container responsible for manage the state for evaluation button.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 */
class EvaluationButtonContainer extends React.PureComponent {
	handleEvaluationDialog = () => {
		const { setActionId, handleOpen, handleClick, actionId } = this.props
		handleClick(actionId)
		setActionId().then(() => handleOpen())
	}

	render() {
		const { wasEvaluated } = this.props

		return (
			<EvaluationButton
				isVisible={Boolean(wasEvaluated)}
				handleClick={this.handleEvaluationDialog}
			/>
		)
	}
}

EvaluationButtonContainer.propTypes = {
	wasEvaluated: PropTypes.any.isRequired
}

EvaluationButtonContainer.defaultProps = {
	wasEvaluated: false
}

const makeMapStateToProps = () => {
	const getEvaluation = makeGetEvaluationButtonState()

	const mapStateToProps = (state, props) => ({
		wasEvaluated: getEvaluation(state, props)
	})

	return mapStateToProps
}

const mapDispatchToProps = (dispatch, props) => {
	const { actionId } = props

	return {
		handleOpen: () => dispatch(setEvaluationDialogOpen()),
		setActionId: async () => dispatch({ type: 'SET_ACTION_ID', actionId })
	}
}

export default compose(
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(EvaluationButtonContainer)
