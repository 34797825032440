export const MANAGE_LEVEL_EQUIPMENT_LOADING_STATE = 'MANAGE_LEVEL_EQUIPMENT_LOADING_STATE',
    LOAD_LEVEL_EQUIPMENT_DATA = 'LOAD_LEVEL_EQUIPMENT_DATA',
    SET_LEVEL_EQUIPMENT_PERIOD = 'SET_LEVEL_EQUIPMENT_PERIOD',
    SET_DO_QUERY = 'SET_DO_QUERY'

export const setLevelEquipmentPeriod = (dateConfig, value, formType) => ({
    type: SET_LEVEL_EQUIPMENT_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadLevelEquipmentData = (data) => ({
    type: LOAD_LEVEL_EQUIPMENT_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_LEVEL_EQUIPMENT_LOADING_STATE,
    isLoading
})