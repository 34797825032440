export const MANAGE_FORESEEN_CHECKLIST_LOADING_STATE = 'MANAGE_FORESEEN_CHECKLIST_LOADING_STATE',
    LOAD_FORESEEN_CHECKLIST_DATA = 'LOAD_FORESEEN_CHECKLIST_DATA',
    SET_FORESEEN_CHECKLIST_PERIOD = 'SET_FORESEEN_CHECKLIST_PERIOD'

export const setForeseenChecklistPeriod = (dateConfig, value, formType) => ({
    type: SET_FORESEEN_CHECKLIST_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadForeseenChecklistData = (data) => ({
    type: LOAD_FORESEEN_CHECKLIST_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_FORESEEN_CHECKLIST_LOADING_STATE,
    isLoading
})
