export const MANAGE_WIRE_CHARGE_LOADING_STATE =
		'MANAGE_WIRE_CHARGE_LOADING_STATE',
	LOAD_WIRE_CHARGE_DATA = 'LOAD_WIRE_CHARGE_DATA',
	SET_WIRE_CHARGE_PERIOD = 'SET_WIRE_CHARGE_PERIOD'

export const setWireChargePeriod = (dateConfig, value, formType) => ({
	type: SET_WIRE_CHARGE_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadWireChargeData = (wireCharge) => ({
	type: LOAD_WIRE_CHARGE_DATA,
	wireCharge
})

export const setLoading = (isLoading) => ({
	type: MANAGE_WIRE_CHARGE_LOADING_STATE,
	isLoading
})
