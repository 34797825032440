import {
	SET_PRODUCTIVITY_CYCLE,
	CLEAR_PRODUCTIVITY_CYCLE
} from '../../actions/productivity/cycle'
import { PRODUCTIVITY_CONTRACTS_LIST_LOADED } from '../../actions/productivity'

const PRODUCTIVITY_ITEM_LIST_LOADED = 'PRODUCTIVITY_ITEM_LIST_LOADED',
	SET_PRODUCTIVITY_ITEM = 'SET_PRODUCTIVITY_ITEM',
	SET_PRODUCTIVITY_ITEM_LIST_LOADING = 'SET_PRODUCTIVITY_ITEM_LIST_LOADING',
	SET_PRODUCTIVITY_PROCESS = 'SET_PRODUCTIVITY_PROCESS',
	CLEAR_PRODUCTIVITY_PROCESS = 'CLEAR_PRODUCTIVITY_PROCESS',
	CLEAR_PRODUCTIVITY_BASE = 'CLEAR_PRODUCTIVITY_BASE',
	SET_PRODUCTIVITY_BASE = 'SET_PRODUCTIVITY_BASE',
	CLEAR_PRODUCTIVITY_TEAM = 'CLEAR_PRODUCTIVITY_TEAM',
	SET_PRODUCTIVITY_TEAM = 'SET_PRODUCTIVITY_TEAM',
	SET_PRODUCTIVITY_CLIENT = 'SET_PRODUCTIVITY_CLIENT',
	CLEAR_PRODUCTIVITY_CLIENT = 'CLEAR_PRODUCTIVITY_CLIENT',
	RESET_PRODUCTIVITY_FILTERS = 'RESET_PRODUCTIVITY_FILTERS'

const INITIAL_STATE = {
	isLoading: false,
	items: null,
	contracts: null,
	currentCycle: null,
	currentClient: null,
	currentBase: null,
	currentProcess: null,
	currentTeam: null,
	ownFilters: {},
	searchText: {
		ciclo_nome: ''
	}
}

/**
 * Applies a list of received contracts
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree for the reducer
 * @param {Object} action The action with "type" and desired props
 */
const applyReceivedContracts = (state, action) => {
	return Object.assign({}, state, {
		contracts: action.contracts
	})
}

function productivityReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CLEAR_PRODUCTIVITY_CYCLE: {
			return Object.assign({}, state, {
				currentCycle: INITIAL_STATE.currentCycle
			})
		}
		case SET_PRODUCTIVITY_CYCLE: {
			return Object.assign({}, state, {
				currentCycle: action.currentCycle
			})
		}
		case PRODUCTIVITY_ITEM_LIST_LOADED: {
			return Object.assign({}, state, {
				items: action.items
			})
		}
		case 'SET_PRODUCTIVITY_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_PRODUCTIVITY_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_PRODUCTIVITY_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case PRODUCTIVITY_CONTRACTS_LIST_LOADED: {
			return applyReceivedContracts(state, action)
		}
		case SET_PRODUCTIVITY_ITEM: {
			return Object.assign({}, state, {
				item: action.item
			})
		}
		case SET_PRODUCTIVITY_CLIENT: {
			return Object.assign({}, state, { currentClient: action.item })
		}
		case CLEAR_PRODUCTIVITY_CLIENT: {
			return Object.assign({}, state, {
				currentClient: INITIAL_STATE.currentClient
			})
		}
		case SET_PRODUCTIVITY_BASE: {
			return Object.assign({}, state, { currentBase: action.item })
		}
		case CLEAR_PRODUCTIVITY_BASE: {
			return Object.assign({}, state, {
				currentBase: INITIAL_STATE.currentBase
			})
		}
		case SET_PRODUCTIVITY_PROCESS: {
			return Object.assign({}, state, { currentProcess: action.item })
		}
		case CLEAR_PRODUCTIVITY_PROCESS: {
			return Object.assign({}, state, {
				currentProcess: INITIAL_STATE.currentProcess
			})
		}
		case SET_PRODUCTIVITY_TEAM: {
			return Object.assign({}, state, { currentTeam: action.item })
		}
		case CLEAR_PRODUCTIVITY_TEAM: {
			return Object.assign({}, state, {
				currentTeam: INITIAL_STATE.currentTeam
			})
		}
		case RESET_PRODUCTIVITY_FILTERS: {
			return Object.assign({}, state, { ...INITIAL_STATE })
		}
		default:
			return state
	}
}

export default productivityReducer

export {
	PRODUCTIVITY_ITEM_LIST_LOADED,
	SET_PRODUCTIVITY_ITEM,
	SET_PRODUCTIVITY_ITEM_LIST_LOADING,
	CLEAR_PRODUCTIVITY_BASE,
	SET_PRODUCTIVITY_BASE,
	CLEAR_PRODUCTIVITY_PROCESS,
	SET_PRODUCTIVITY_PROCESS,
	CLEAR_PRODUCTIVITY_CLIENT,
	SET_PRODUCTIVITY_CLIENT,
	CLEAR_PRODUCTIVITY_TEAM,
	SET_PRODUCTIVITY_TEAM,
	RESET_PRODUCTIVITY_FILTERS
}
