import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import DialogTypeNotification from '../../dialog/type/notification/DialogTypeNotification'
import { setDialogNotificationClose } from '../../dialog/type/notification/actions'

class AfterRatingNotification extends React.Component {
	render() {
		const { isOpen, title, message, handleClose } = this.props

		return (
			<div>
				<DialogTypeNotification
					isOpen={Boolean(isOpen)}
					notificationTitle={title}
					notificationContentText={message}
					closeDialog={handleClose}
					btnFunc={handleClose}
				/>
			</div>
		)
	}
}

const makeMapStateToProps = () => {
	const mapStateToProps = (state) => ({
		isOpen: state.dialogNotificationState.isOpen,
		message: state.dialogNotificationState.message,
		title: state.dialogNotificationState.title
	})

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	handleClose: () => dispatch(setDialogNotificationClose())
})

export default compose(
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	)
)(AfterRatingNotification)
