import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
	withStyles,
	InputLabel,
	FormControl,
	Select,
	MenuItem,
	TextField,
	Button,
} from '@material-ui/core'
import classNames from 'classnames'
import { auth } from '../../../../firebase/firebase'
import { SuggestionSearchBox } from '../../../reusable/suggestion/searchBox'
import Action from '../model/Action'
import { closeActionModal } from '../../../../reducers/action/navigation'

import * as Author from '../../../../utils/security/author'
import * as Users from '../../../../firebase/user'
import * as Actions from '../../../../firebase/actions'
import * as ActionSnapshot from '../../../../firebase/actions/action'
import * as Functions from '../functions'
import * as Storage from '../../../../firebase/storage'
import { translate } from '../../../../utils/lang'
import Loader from '../../../reusable/loader'
import withActiveCompany from '../../../reusable/withActiveCompany'
import {
	SET_ACTION_FORM_IS_SENDING,
	CLEAR_ACTION_FORM,
	SET_ACTION_FORM_FETCHED_USERS,
	SET_ACTION_FORM_CC_RECEIVERS,
	SET_ACTION_FORM_RECEIVER,
	SET_ACTION_FORM_TYPE,
	SET_ACTION_FORM_CLASSIFICATION,
	SET_ACTION_FORM_OBSERVATION,
	SET_ACTION_FORM_USERS_FETCHING,
	SET_ACTION_FORM_EXPECTED_DATE,
	CLEAR_ELEMENTS_FORM,
} from '../../../../reducers/action/form'
import styles from './shared/styles'
import { showSnackBar } from '../../../../actions/snackbar'
import { makeGetActionFormSnapshot } from '../../../../selectors/action/form'
import { deviceIsIos } from '../../../../utils/env'
import withLogger from '../../../reusable/withLogger'
import { makeGetActivePluginId } from '../../../../selectors/plugin'

/**
 * Manages action-generation parts: form, data, requests, dispatches, etc.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0.0
 *
 * @description Removed state from component,
 * 				now Redux manages its state-portion at a separate layer
 * @author brunoteixeirasilva
 * @version 1.1.0
 */

class ActionForm extends React.PureComponent {
	fetchUsers = async () => {
		const { setFetchedUsers, setUsersFetching, activeCompanyId } =
			this.props
		let foundUsers = []
		let users = []
		//Defines query will run
		setUsersFetching(true)

		Users.getUsersFromActiveCompany(activeCompanyId).then(snapshot => {
			snapshot.forEach(item => {
				users.push(Users.get(item.id))
			})
			Promise.all(users).then(items => {
				foundUsers = items.map(user => ({
					key: user.id,
					label: user.data() ? user.data().name : '',
					secondaryLabel: user.data() ? user.data().email : '',
				}))
				setFetchedUsers(foundUsers)
				setUsersFetching(false)
			})
		})

		//Stops the loader, sets users fetching to false
		// setFetchedUsers(foundUsers)
	}

	list = {
		adviceTypes: [
			{ disabled: true, label: translate('label/select') },
			{ label: translate('label/alert') },
			{ label: translate('label/risk') },
			{ label: translate('label/compliment') },
		],
		potentials: [
			{ disabled: true, label: translate('label/select') },
			{ label: translate('label/risk-high') },
			{ label: translate('label/risk-medium') },
			{ label: translate('label/risk-low') },
			{ label: translate('label/improv-opportunity') },
		],
	}

	componentDidMount() {
		const { setLoader } = this.props

		//Users are not fetching yet
		// if (!isFetching && isAction) {
		this.fetchUsers()
		// }
		setLoader(true)
	}

	componentDidUpdate(prevProps) {
		const { setLoader, fetchedUsers } = this.props

		if (
			prevProps.fetchedUsers.length === 0 &&
			!!fetchedUsers &&
			fetchedUsers.length > 0
		) {
			setLoader(false)
		}
	}

	/**
	 * When the action snapshot has been saved
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 *
	 * @param {string} fileUrl The download URL of the uploaded snapshot
	 * @param {string} activeCompanyId The current active-company ID
	 * @param {string} actionId The action-id to refer to
	 */
	onSnapshotSaved = (fileUrl, activeCompanyId, actionId) =>
		ActionSnapshot.setActionSnapshotUrl(activeCompanyId, actionId, fileUrl)

	/**
	 * When the action JSON has been saved
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 *
	 * @param {string} fileUrl The download URL of the uploaded JSON
	 */
	onJsonSaved = fileUrl => {
		const { onClose, clearForm, setSending } = this.props

		//Will emit a success message
		this.notifyUserSuccess()

		//Closes the form
		onClose()

		//Stops sending state => false
		setSending(false)

		//Clears the form state
		return Promise.resolve(clearForm())
	}

	notifyUserSuccess = () => {
		const { onSnackbar } = this.props

		return onSnackbar(translate('success/action-successfully-generated'))
	}

	handleChangeClassification = event => {
		const { setFormClassification } = this.props

		setFormClassification(event.target.value)
	}

	handleChangeObservation = event => {
		const { setFormObservation } = this.props

		setFormObservation(event.target.value)
	}

	handleChangeType = event => {
		const { setFormType } = this.props

		setFormType(event.target.value)
	}

	handleChangeDate = event => {
		const { setFormExpectedDate } = this.props
		setFormExpectedDate(event.target.value)
	}

	handleAction = () => {
		const {
			type,
			classification,
			observation,
			receiver,
			ccReceivers,
			activeCompanyId,
			cardId,
			expectedDate,
			snapshot,
			pluginId,
			createLog,
		} = this.props

		let actionId
		const action = new Action(
				activeCompanyId,
				receiver,
				ccReceivers,
				{
					type,
					classification,
					observation,
				},
				new Date(expectedDate),
			),
			ls = window.localStorage,
			newAction = Object.assign({}, action, {
				...Author.generateObject(auth.currentUser.uid),
				cardId: cardId ? cardId : ls.getItem('url-param'),
				receiverOpen: false,
				authorOpen: true,
			})

		//Creates the action itself
		return (
			//Firstly creates the action
			Actions.create(newAction, activeCompanyId)
				.then(id => {
					//Sets the ID for reusage
					actionId = id

					//At the success of created action,
					//Stores the image using action ID
					return Storage.saveImage(
						snapshot,
						activeCompanyId,
						actionId,
					)
				})
				//Executes image URL saving at the action item
				.then(response => {
					this.onSnapshotSaved(response, activeCompanyId, actionId)
				})

				//Executes data JSON saving at the action item storage location
				.then(() =>
					Storage.saveActionJSONData(activeCompanyId, actionId),
				)
				.catch(err => {
					console.log('err', err)
				})

				//Relates the json action-data to the action-item data
				.then(this.onJsonSaved)
				//Executes notification creations itself
				.then(() => {
					createLog('generatedAction', {
						dashItemId: cardId,
						pluginId,
					})
					Promise.resolve(
						Functions.createNotifications(
							newAction,
							actionId,
							activeCompanyId,
						),
					)
				})
		)
	}

	validateForm = () => {
		const { onSnackbar, type, classification, receiver, expectedDate } =
			this.props

		if (!type) {
			onSnackbar(translate('validation/warning-type-is-required'))
			return false
		}

		if (!classification) {
			onSnackbar(translate('validation/classification-is-required'))
			return false
		}

		if (!receiver) {
			onSnackbar(translate('validation/receiver-is-required'))
			return false
		}

		if (new Date(expectedDate).toString() === 'Invalid Date') {
			onSnackbar(translate('validation/expected-date-is-required'))
			return false
		}

		if (new Date(expectedDate).getTime() < new Date().getTime()) {
			onSnackbar(translate('validation/invalid-date'))
			return false
		}
		return true
	}

	onAddCcReceiver = ccReceiverKey => {
		const { ccReceivers } = this.props,
			newCcReceivers = []

		//Tries spreading current items, if available
		if (!!ccReceivers) {
			newCcReceivers.push(...ccReceivers)
		}

		//Adds the new item
		newCcReceivers.push(ccReceiverKey)

		return this.onSetCcReceivers(newCcReceivers)
	}

	onSetCcReceivers = ccReceivers => {
		const { setCcReceivers } = this.props

		return setCcReceivers(ccReceivers)
	}

	submit = async e => {
		const { setSending, onSnackbar } = this.props

		//When the form is valid
		if (!this.validateForm()) {
			return false
		}

		//Prevents default behaviour of the submit
		//When function is available
		if (e && typeof e.preventDefault === 'function') e.preventDefault()
		//Will set the form to a sending state => true

		return setSending(true)
			.then(() => this.handleAction())
			.then(() => {
				//Will set the form to a sending state => true
				setSending(false)
				this.closeInspection()
			})
			.catch(error => {
				//Will set the form to a sending state => true
				setSending(false)

				//When under development
				if (process.env.REACT_APP_NODE_ENV.trim() === 'development') {
					console.error(translate('error/creating-action'))
					console.error(error)
				}

				//Notifies the user
				onSnackbar(translate('error/creating-action'))

				//Rethrows the error
				return Promise.reject(error)
			})
	}

	onSelect = (item, selectedItem) => {
		const { setReceiver } = this.props

		setReceiver(selectedItem[0])
	}

	closeInspection = () => {
		const {
			onClose,
			onSetShowModalForm,
			onSetOpenForm,
			clearElementsForm,
			onSetIsAction,
		} = this.props

		onSetOpenForm(false)
		onClose()
		onSetShowModalForm(false)
		clearElementsForm()
		onSetIsAction(false)
		// setCloseInspection(true)
	}

	render() {
		const {
			ccReceivers,
			classification,
			observation,
			classes,
			// onClose,
			isSending,
			isFetching,
			fetchedUsers,
			type,
			onSnackbar,
			expectedDate,
			isLoading,
		} = this.props
		return (
			<div
				className={classNames(classes.container, {
					[classes.containerIos]: deviceIsIos(),
				})}>
				{isLoading ? (
					<Loader internal />
				) : (
					<div>
						{isSending ? (
							<Loader internal />
						) : (
							<form
								className={classes.flexColumn}
								onSubmit={this.submit}>
								<FormControl margin="normal" required>
									<InputLabel>
										{translate('label/advice-type')}
									</InputLabel>
									<Select
										onChange={this.handleChangeType}
										value={type}>
										{this.list.adviceTypes.map(
											(item, index) => (
												<MenuItem
													key={index}
													value={index}
													disabled={item.disabled}>
													{item.label}
												</MenuItem>
											),
										)}
									</Select>
								</FormControl>
								<FormControl margin="normal" required>
									<InputLabel>
										{translate('label/advice-potential')}
									</InputLabel>
									<Select
										onChange={
											this.handleChangeClassification
										}
										value={classification}>
										{this.list.potentials.map(
											(item, index) => (
												<MenuItem
													key={index}
													value={index}
													disabled={item.disabled}>
													{item.label}
												</MenuItem>
											),
										)}
									</Select>
								</FormControl>
								<FormControl margin="normal" required>
									<TextField
										label={translate('label/solve-until')}
										onChange={this.handleChangeDate}
										type="datetime-local"
										value={
											!!expectedDate ? expectedDate : ''
										}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</FormControl>
								<FormControl margin="normal" required>
									<TextField
										label={translate(
											'label/action-comments',
										)}
										onChange={this.handleChangeObservation}
										value={observation}
										multiline
									/>
								</FormControl>
								{!isFetching && fetchedUsers != null && (
									<div className={classes.flexColumn}>
										<FormControl margin="normal" required>
											<SuggestionSearchBox
												oneOnly
												errCallback={err =>
													onSnackbar(translate(err))
												}
												dropUp
												name="to"
												label={translate('label/to')}
												placeholder={translate(
													'placeholder/type-in-to-user',
												)}
												listItems={fetchedUsers}
												onSelect={this.onSelect}
											/>
										</FormControl>
										<FormControl margin="normal">
											<SuggestionSearchBox
												dropUp
												name="copies"
												label={translate(
													'label/carbon-copy',
												)}
												placeholder={translate(
													'placeholder/type-in-carbon-copy-users',
												)}
												listItems={fetchedUsers}
												onSelect={item => {
													this.onAddCcReceiver(
														item.key,
													)
												}}
												onDelete={item => {
													//Will remove the current item from the list
													this.onSetCcReceivers(
														ccReceivers.filter(
															value =>
																value !==
																item.key,
														),
													)
												}}
											/>
										</FormControl>
									</div>
								)}
								<div className={classes.actions}>
									<Button
										variant="outlined"
										// disabled={!condition}
										onClick={this.submit}>
										{translate('label/generate-action')}
									</Button>
									<Button
										variant="text"
										onClick={this.closeInspection}>
										{translate('label/cancel')}
									</Button>
								</div>
							</form>
						)}
					</div>
				)}
			</div>
		)
	}
}
const getPluginId = makeGetActivePluginId()
const makeMapStateToProps = () => {
	const getSnapshot = makeGetActionFormSnapshot(),
		mapStateToProps = state => ({
			ccReceivers: state.actionFormState.ccReceivers,
			classification: state.actionFormState.classification,
			fetchedUsers: state.actionFormState.fetchedUsers,
			isFetching: state.actionFormState.isFetching,
			isSending: state.actionFormState.isSending,
			observation: state.actionFormState.observation,
			receiver: state.actionFormState.receiver,
			expectedDate: state.actionFormState.expectedDate,
			session: state.sessionState,
			type: state.actionFormState.type,
			snapshot: getSnapshot(state),
			pluginId: getPluginId(state),
			isLoading: state.actionFormState.isLoading,
		})

	return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
	// handleOpen: () => dispatch(openActionModal()),
	onSetIsAction: async isAction =>
		dispatch({ type: 'SET_IS_ACTION', isAction }),
	onSetShowModalForm: async showModalForm =>
		dispatch({ type: 'SET_SHOW_MODAL_FORM', showModalForm }),
	clearForm: () => dispatch({ type: CLEAR_ACTION_FORM }),
	setUsersFetching: isFetching =>
		dispatch({ type: SET_ACTION_FORM_USERS_FETCHING, isFetching }),
	onSetOpenForm: async openForm =>
		dispatch({ type: 'SET_OPEN_FORM', openForm }),
	clearElementsForm: () => dispatch({ type: CLEAR_ELEMENTS_FORM }),
	setReceiver: receiver =>
		dispatch({ type: SET_ACTION_FORM_RECEIVER, receiver }),
	setCcReceivers: ccReceivers =>
		dispatch({ type: SET_ACTION_FORM_CC_RECEIVERS, ccReceivers }),
	setFetchedUsers: fetchedUsers =>
		dispatch({ type: SET_ACTION_FORM_FETCHED_USERS, fetchedUsers }),
	setSending: async isSending =>
		dispatch({ type: SET_ACTION_FORM_IS_SENDING, isSending }),
	setFormObservation: observation =>
		dispatch({ type: SET_ACTION_FORM_OBSERVATION, observation }),
	setFormClassification: classification =>
		dispatch({ type: SET_ACTION_FORM_CLASSIFICATION, classification }),
	setFormType: formType => dispatch({ type: SET_ACTION_FORM_TYPE, formType }),
	setFormExpectedDate: date =>
		dispatch({ type: SET_ACTION_FORM_EXPECTED_DATE, date }),
	onClose: () => dispatch(closeActionModal()),
	onSnackbar: notification => dispatch(showSnackBar(notification)),
	setLoader: isLoading => dispatch({ type: 'SET_LOADER', isLoading }),
})

export default compose(
	withActiveCompany,
	connect(makeMapStateToProps(), mapDispatchToProps),
	withStyles(styles),
	withLogger('generatedAction', false),
)(ActionForm)
