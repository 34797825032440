/**
 * Creates a layer to hide an object when it's in production.
 * It's safer this way, you know it.
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} object The object being isolated
 */
export const modeCheckLayer = (object) =>
	process.env.REACT_APP_NODE_ENV !== 'production' ? object : undefined
