import {
	MANAGE_RANKING_CHECKLIST_LOADING_STATE,
	LOAD_RANKING_CHECKLIST_DATA,
	SET_RANKING_CHECKLIST_PERIOD,
} from '../../actions/rankingChecklist/index'

const INITIAL_STATE = {
	isLoading: null,
	localFilters: null,
	data: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {},
	idLocalFilter: {
		localClients: null,
		localLocalities: null,
		objects: null,
		localTypeObjects: null,
		autochecksRespAudit: null,
		localBases: null,
		collaborators: null,
		forms: null,
		hasNc: null,
	},
}

const clearAllLocalFilters = state => {
	let keys = Object.keys(state.idLocalFilter),
		clearFilters = {}

	keys.forEach(key => {
		clearFilters[key] = null
	})

	return clearFilters
}

/**
 * Ranking checklist
 * @description created the component
 * @author Lucas Lopes
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const rankingChecklistReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_RANKING_CHECKLIST_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case LOAD_RANKING_CHECKLIST_DATA: {
			return Object.assign({}, state, {
				data: action.data,
				isLoading: false,
			})
		}
		case 'SET_RANKING_CHECKLIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_RANKING_CHECKLIST_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_RANKING_CHECKLIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
				idLocalFilter: clearAllLocalFilters(state),
			})
		}
		case 'SET_LOCAL_FILTER_RANKING_CHECKLIST': {
			return Object.assign({}, state, {
				localFilters: action.localFilters,
			})
		}
		case 'SET_SEARCH_TEXT_RANKING_CHECKLIST': {
			let searchText = {}
			action.objectKeys.forEach(item => {
				searchText[item] = ''
			})
			return Object.assign({}, state, {
				searchText: searchText,
			})
		}
		case 'SET_ID_LOCAL_FILTERS_RANKING_CHECKLIST': {
			return Object.assign({}, state, {
				idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter),
			})
		}
		case SET_RANKING_CHECKLIST_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign({}, state.dateConfig.selectedValues, {
						[action.formType]: action.value,
					}),
				},
				isLoading: true,
			})
		}
		default:
			return state
	}
}

export default rankingChecklistReducer
