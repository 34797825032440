import React from 'react'
import { Button } from '@material-ui/core'

import * as routes from '../../../../constants/routes'
import { translate } from '../../../../utils/lang'
import { compose } from 'recompose'
import withRedirect from '../../withRedirect'

/**
 *
 */
const ResetLink = ({ onClick }) => {
	return (
		<Button
			variant="text"
			onClick={onClick}
			color="primary"
			children={translate('label/forgot-my-password')}
		/>
	)
}

export default compose(withRedirect(routes.PASSWORD_RESET))(ResetLink)
