const SET_EVALUATION_POPUP_IS_OPEN = 'SET_EVALUATION_POPUP_IS_OPEN'

const INITIAL_STATE = {
	isOpen: false
}

const openPopup = (state, action) =>
	Object.assign({}, state, {
		isOpen: action.isOpen
	})

export const actionOpenPopup = (isOpen) => ({
	type: SET_EVALUATION_POPUP_IS_OPEN,
	isOpen
})

const evaluationPopupReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_EVALUATION_POPUP_IS_OPEN: {
			return openPopup(state, action)
		}
		default: {
			return state
		}
	}
}

export default evaluationPopupReducer
