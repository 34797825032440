import React from 'react'
import { compose } from 'recompose'
import withActiveCompany from '../../reusable/withActiveCompany'
import { connect } from 'react-redux'
import { SET_LOGS_PAGE_LOADING } from '../../../reducers/logHandler'
import { SET_LOG_DATA } from '../../../reducers/logs'
import Loader from '../../reusable/loader'
import getLogsList from '../../../selectors/logsPage'
import {
	getLogs,
	getEmailUserByUID,
	getPluginNameByPID,
	getFileNameByProfileID,
	getDashItemByID,
	getUserIsDev,
} from '../../../firebase/user'
import {
	withStyles,
	Paper,
	List,
	Divider,
	ListItem,
	ListItemText,
	Button,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Switch,
	FormControlLabel,
} from '@material-ui/core'
import ExpansionItem from '../../reusable/expandable/ExpansionItem'
import { translate } from '../../../utils/lang'

/**
 * @author Ray
 * @email mauricio@arquia.com.br
 * @updated 07-12-2020
 */

const FILTER_USER_LOGS = [
	'name',
	'email',
	'status',
	'actions',
	'notification',
	'createUser',
	'allowUpload',
	'allowDownload',
]

const logTypes = {
	acessed:
		'${email} acessou a aplicação, em um dispositivo ${platform}, modelo/navegador ${model}',
	plugin: '${email} acessou o plugin ${pluginName}',
	generatedAction:
		'${email} gerou uma ação no card ${dashItemValue}, do plugin ${pluginName}',
	upload: '${email} fez upload de arquivo ${fileName}',
	download: 'O usuário ${email} realizou o download dos dados de ${fileName}',
	name:
		'${authorEmail} editou o nome do(a) usuário(a) ${email} para ${newName}',
	email: '${authorEmail} editou o e-mail do(a) usuário(a) ${email}',
	status:
		'${authorEmail} alterou o acesso do(a) ${email} ao Agildash para ${newStatus}',
	actions:
		'${authorEmail} alterou o acesso do(a) ${email} ao módulo de Ações para ${newAction}',
	notification:
		'${authorEmail} alterou o acesso do(a) ${email} ao módulo de Notificações para ${newNotification}',
	createUser: '${authorEmail} cadastrou um(a) novo(a) usuário(a) ${email}',
	allowUpload:
		'${authorEmail} alterou o acesso ao módulo de Upload de Arquivos do(a) ${email} para o ${statusUpload}',
	allowDownload:
		'${authorEmail} alterou o acesso ao módulo de Download de Arquivos do(a) ${email} para o ${statusDownload}',
}

const verifyLogType = params => {
	switch (params.type) {
		case 'acessed':
			existEmailAndUidOnParams(params)
			break
		case 'plugin':
			existEmailAndUidOnParams(params)
			existPluginNameAndPidOnParams(params)
			break
		case 'generatedAction':
			existEmailAndUidOnParams(params)
			existPluginNameAndPidOnParams(params)
			existDashItemAndDashItemIdOnParams(params)
			break
		case 'upload':
			existEmailAndUidOnParams(params)
			existFileNameOnParams(params)
			break
		case 'download':
			existEmailAndUidOnParams(params)
			break
		case 'name':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		case 'email':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		case 'status':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		case 'actions':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		case 'notification':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		case 'createUser':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		case 'allowUpload':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		case 'allowDownload':
			existEmailAndUidOnParams(params)
			existAuthorEmailOnParams(params)
			break
		default:
			break
	}
}

const existDashItemAndDashItemIdOnParams = params => {
	if (!params.dashItem || params.dashItem === null) {
		getAndSetDashItemValueOnLogs(params)
	}
}

const getAndSetDashItemValueOnLogs = params => {
	const { onSetDashItemValue } = params
	getDashItemByID(params.dashItemId).then(res => {
		if (res && res !== null && res !== undefined) {
			onSetDashItemValue(res)
		} else {
			onSetDashItemValue('Card não encontrado')
		}
	})
}

const existEmailAndUidOnParams = params => {
	if (!params.email || params.email === null) {
		getAndSetEmailValueOnLogs(params)
	}
}

const getAndSetEmailValueOnLogs = params => {
	const { onSetEmailValue } = params

	getEmailUserByUID(params.uid).then(res => {
		if (res && res !== null && res !== undefined) {
			onSetEmailValue(res)
		} else {
			onSetEmailValue('Usuário(a) não encontrado(a)')
		}
	})
}

const existAuthorEmailOnParams = params => {
	if (!params.authorEmail || params.authorEmail === null) {
		getAndSetAuthorEmailValueOnLogs(params)
	}
}

const getAndSetAuthorEmailValueOnLogs = params => {
	const { onSetAuthorEmailValue } = params
	getEmailUserByUID(params.author).then(res => {
		if (res && res !== null && res !== undefined) {
			onSetAuthorEmailValue(res)
		} else {
			onSetAuthorEmailValue('Usuário(a) não encontrado(a)')
		}
	})
}

const existPluginNameAndPidOnParams = params => {
	if (!params.pluginName || params.pluginName === null) {
		getAndSetPluginNameValueOnLogs(params)
	}
}

const getAndSetPluginNameValueOnLogs = params => {
	const { onSetPluginNameValue } = params
	if (params.pluginId) {
		getPluginNameByPID(params.pluginId).then(res => {
			if (res && res !== null && res !== undefined) {
				onSetPluginNameValue(res)
			} else {
				onSetPluginNameValue('Plugin não encontrado')
			}
		})
	}
}

const existFileNameOnParams = params => {
	if (!params.fileName || params.fileName === null) {
		getAndSetFileNameValueOnLogs(params)
	}
}

const getAndSetFileNameValueOnLogs = params => {
	const { onSetFileNameValue } = params
	getFileNameByProfileID(params.profileId).then(res => {
		if (res && res !== null && res !== undefined) {
			onSetFileNameValue(res)
		} else {
			onSetFileNameValue('Arquivo não encontrado')
		}
	})
}

const replaceBooleansToBRPattern = str => {
	if (str.indexOf('false') > -1) str = str.replace('false', 'Inativo')
	if (str.indexOf('true') > -1) str = str.replace('true', 'Ativo')
	return str
}

const replaceFileNameNullToBRPattern = str => {
	if (str.indexOf('null') > -1) str = str.replace('null', '')
	return str
}

const LogItem = props => {
	const { classes } = props

	verifyLogType(props)

	let log = 'Carregando...'

	if (props) {
		log = Object.keys(props).reduce((acc, crr) => {
			if (acc && acc !== undefined && acc !== null) {
				acc = acc.replace('${' + crr + '}', props[crr])
				acc = replaceBooleansToBRPattern(acc)
				acc = replaceFileNameNullToBRPattern(acc)
			} else {
				acc = 'Carregando...'
			}

			return acc
		}, logTypes[props.type])
	}

	return (
		<>
			<ListItem button>
				<ListItemText>
					<span className={classes.time}>
						{new Date(props.time.seconds * 1000).toLocaleString(
							'pt-Br',
						)}
						:
					</span>
					{log}
				</ListItemText>
			</ListItem>
		</>
	)
}

class LogsPage extends React.Component {
	state = {
		isDev: false,
		logs: [],
		isLoading: false,
	}
	componentDidMount() {
		const { isDev, logs } = this.props

		this.setState({ isDev })
		if (!!logs.results) {
			this.setState({ logs: logs.results })
		}
		this.fetchCompanyLogs()
	}

	componentDidUpdate(prevProps, prevState) {
		const { logs } = this.state
		const { isDev, authUser, onSetUserIsDev } = this.props

		if (authUser.uid) {
			getUserIsDev(authUser.uid).then(res => {
				if (res && res !== null && res !== undefined)
					onSetUserIsDev(res)
			})
		}

		if (prevProps.isDev !== isDev) this.setState({ isDev: isDev })

		if (
			!!this.props.logs.results &&
			this.props.logs.results.length !== logs.length
		) {
			this.setState({ logs: this.props.logs.results })
			this.setState({ isLoading: true })
			setTimeout(() => {
				this.setState({ isLoading: false })
			}, 5000)
		}
	}

	checkActiveCompany = logs => {
		const { activeCompanyId } = this.props
		if (logs && logs.length > 0) {
			logs.forEach(logItem => {
				if (logItem.companyId !== activeCompanyId) {
					Location.reload(true)
				}
			})
		}
	}

	fetchCompanyLogs = () => {
		const { activeCompanyId, setLogData } = this.props
		getLogs(activeCompanyId).then(queryData => {
			let logs = {}
			queryData.forEach(logItem => {
				logs[logItem.id] = logItem.data()
			})
			setLogData(logs)
		})
	}

	clearFilters = () => {
		this.props.onSetFilterValue({ type: null })
		let date = new Date()
		date.setDate(date.getDate() - 1)
		this.props.onSetTimeValue({
			from: date.toISOString().split('.')[0],
			to: new Date().toISOString().split('.')[0],
		})
	}

	setFilterValue = key => event => {
		const { onSetFilterValue } = this.props
		if (event.target.value[0] === 'cadUser') {
			onSetFilterValue({ [key]: FILTER_USER_LOGS })
		} else {
			onSetFilterValue({ [key]: event.target.value })
		}
	}

	setDate = key => event => {
		let { onSetTimeValue, time } = this.props
		time[key] = event.target.value
		onSetTimeValue(time)
	}

	handleChange = () => {
		const { isDev } = this.state
		const { onSetFilterWithDev } = this.props
		this.setState({ isDev: !isDev })

		if (isDev) {
			onSetFilterWithDev(true)
		}
	}

	render() {
		const {
			classes,
			logs,
			filterValues,
			time,
			onSetEmailValue,
			onSetAuthorEmailValue,
			onSetPluginNameValue,
			onSetFileNameValue,
			onSetDashItemValue,
			email,
			authorEmail,
			pluginName,
			fileName,
			dashItemValue,
			isDev,
		} = this.props

		const { isLoading } = this.state

		const { isDev: devStage } = this.state

		if (logs.results && logs.results.length > 0) {
			this.checkActiveCompany(logs.results)
		}

		return (
			<>
				<div className={classes.container}>
					<Paper square className={classes.content}>
						<ExpansionItem
							square
							closeIcon={'keyboard_arrow_down'}
							openIcon={'filter_list'}
							actions={
								<Button
									onClick={this.clearFilters}
									size="small">
									{translate('label/clear-filters')}
								</Button>
							}
							title={
								<Typography>
									{`${translate('label/filters')}
										 - Quantidade de Registro(s) Encontrado(s):
										${logs.qtdLogs ?? 0}`}
								</Typography>
							}>
							{isDev && (
								<FormControlLabel
									control={<Switch />}
									label="Dev"
									checked={devStage}
									onChange={this.handleChange}
								/>
							)}
							<div className={classes.filterContainer}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-simple-select-label">
										Tipo
									</InputLabel>
									<Select
										multiple
										value={filterValues.type || []}
										onChange={this.setFilterValue('type')}>
										<MenuItem value={'acessed'}>
											Acesso
										</MenuItem>
										<MenuItem value={'cadUser'}>
											Cadastro de Usuário
										</MenuItem>
										<MenuItem value={'download'}>
											Download
										</MenuItem>
										<MenuItem value={'generatedAction'}>
											Gerou Ação
										</MenuItem>
										<MenuItem value={'plugin'}>
											Plugin
										</MenuItem>
										<MenuItem value={'upload'}>
											Upload
										</MenuItem>
									</Select>
								</FormControl>
								<div>
									<TextField
										type="datetime-local"
										label="De"
										size="small"
										value={time.from}
										onChange={this.setDate('from')}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										type="datetime-local"
										label="Até"
										size="small"
										value={time.to}
										onChange={this.setDate('to')}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</div>
							</div>
						</ExpansionItem>
						<List className={classes.list}>
							{isLoading && <Loader internal />}
							{logs.results && logs.haveLogs ? (
								logs.results.map(({ logId, ...otherProps }) => (
									<>
										<LogItem
											key={logId}
											classes={classes}
											onSetEmailValue={onSetEmailValue}
											onSetAuthorEmailValue={
												onSetAuthorEmailValue
											}
											onSetPluginNameValue={
												onSetPluginNameValue
											}
											onSetFileNameValue={
												onSetFileNameValue
											}
											onSetDashItemValue={
												onSetDashItemValue
											}
											email={email}
											authorEmail={authorEmail}
											pluginName={pluginName}
											fileName={fileName}
											dashItemValue={dashItemValue}
											{...otherProps}
										/>
										<Divider component="li" />
									</>
								))
							) : !logs.haveLogs ? (
								<div className={classes.grid}>
									<div>
										Sem registro(s) para o(s) filtro(s)
										aplicado(s).
									</div>
									<div>
										<Button
											onClick={this.clearFilters}
											size="small">
											Clique aqui para limpar o(s)
											filtro(s).
										</Button>
									</div>
								</div>
							) : (
								<div className={classes.grid}>
									<div>
										Carregando... Por favor aguarde...
									</div>
								</div>
							)}
						</List>
					</Paper>
				</div>
			</>
		)
	}
}

const getLogFilterValues = state => state.logHandler.filters

const mapStateToProps = state => ({
	logs: getLogsList(state),
	filterValues: getLogFilterValues(state),
	time: state.logHandler.time,
	email: state.logHandler.email,
	authorEmail: state.logHandler.authorEmail,
	pluginName: state.logHandler.pluginName,
	fileName: state.logHandler.fileName,
	dashItemValue: state.logHandler.dashItemValue,
	isDev: state.logHandler.isDev,
	filterWithDev: state.logHandler.filterWithDev,
})

const mapDispatchToProps = dispatch => ({
	setLogData: data => dispatch({ type: SET_LOG_DATA, data }),
	setLoadingState: value => dispatch({ type: SET_LOGS_PAGE_LOADING, value }),
	onSetFilterValue: value =>
		dispatch({ type: 'SET_LOGS_PAGE_FILTER_VALUE', value }),
	onSetTimeValue: time =>
		dispatch({ type: 'SET_LOGS_PAGE_FILTER_DATE', time }),
	onSetEmailValue: email => dispatch({ type: 'SET_EMAIL_NAME', email }),
	onSetAuthorEmailValue: authorEmail =>
		dispatch({ type: 'SET_AUTHOR_EMAIL_NAME', authorEmail }),
	onSetUserIsDev: isDev => dispatch({ type: 'SET_ISDEV', isDev }),
	onSetFilterWithDev: filterWithDev =>
		dispatch({ type: 'SET_FILTER_WITH_DEV', filterWithDev }),
	onSetPluginNameValue: pluginName =>
		dispatch({ type: 'SET_PLUGIN_NAME', pluginName }),
	onSetFileNameValue: fileName =>
		dispatch({ type: 'SET_FILE_NAME', fileName }),
	onSetDashItemValue: dashItemValue =>
		dispatch({ type: 'SET_DASH_ITEM_VALUE', dashItemValue }),
})

export default compose(
	withActiveCompany,
	withStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			alignContent: 'center',
			height: '100%',
			width: '100%',
			justifyContent: 'center',
		},
		content: {
			height: '75%',
			width: '90%',
			display: 'flex',
			flexDirection: 'column',
		},
		time: {
			fontWeight: 100,
			marginRight: '4px',
		},
		list: {
			overflow: 'auto',
			height: '100%',
		},
		filterContainer: {
			display: 'flex',
			alignItems: 'center',
			alignContent: 'center',
			justifyContent: 'space-between',
		},
		grid: {
			display: 'grid',
			alignItems: 'center',
			alignContent: 'center',
			height: '100%',
			width: '100%',
			justifyContent: 'center',
		},
	}),
	connect(mapStateToProps, mapDispatchToProps),
)(LogsPage)
