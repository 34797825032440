import {
	SET_AUDITED_OBJECTS_DATA_LOADING,
	SET_AUDITED_OBJECTS_DATA,
	CLEAR_AUDITED_DATA
} from '../../actions/auditedObject'

const INITIAL_STATE = {
	items: null,
	isLoading: null,
	ownFilters: {},
	searchText: {
		nameObject: '',
		statusObject: '',
	}
}

/**
 * Audited Objects card reducer
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 */
const auditedObjectsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_AUDITED_OBJECTS_DATA_LOADING: {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		case SET_AUDITED_OBJECTS_DATA: {
			return Object.assign({}, state, { items: action.items })
		}
		case CLEAR_AUDITED_DATA: {
			return Object.assign({}, state, INITIAL_STATE)
		}
		case 'SET_AUDIT_INFO_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_AUDIT_INDO_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_AUDIT_INFO_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		default:
			return state
	}
}

export default auditedObjectsReducer
