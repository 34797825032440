import React from 'react'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import classNames from 'classnames'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SignOutButton } from '../../reusable/signOut'
import NavigationAvatar from './avatar'
import * as routes from '../../../constants/routes'
import withNotification from '../withNotification'
import withAuthentication from '../withAuthentication'
import withWidth from '@material-ui/core/withWidth'
import { modeCheckLayer } from '../../../utils/maintenance'
import { fs } from '../../../firebase/firebase'

import {
	Drawer,
	Divider,
	Icon,
	withStyles,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import { translate } from '../../../utils/lang'
import SwitchCompany from '../switchCompany'
import AgilBadge from '../agilBadge/AgilBadge'
import { deviceIsIos } from '../../../utils/env'
import {
	makeGetActivePlugin,
	makeGetActivePluginId,
} from '../../../selectors/plugin'
import { makeGetAppDrawerOpen } from '../../../selectors/app/config'
import { setAppDrawerOpen } from '../../../actions/app/config'

import {
	makeGetUserActiveCompanyId,
	makeGetUserActiveCompany,
} from '../../../selectors/user/company'
import { applySuperUserLayer } from '../../../utils/security/author'

import withActiveCompany from '../withActiveCompany'
/**
 * Maps the system lateral menu (app-drawer) class.
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added support for iOS device inset/notches left (orientation landscape)
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added function that makes the verification if the user is logged in the system
 * @author lopes
 * @version 1.2
 */
class Navigation extends React.Component {
	/**
	 * Manages routing for each list-item
	 *
	 * @param {DOMEvent} event The event object (for stop propagation purposes)
	 * @param {string} route Route to redirect the user to
	 */
	state = {
		isAdmin: false,
		activeLog: false,
		activeNotification: false,
		activeActions: false,
		activeDownload: false,
		activeUpload: false,
		isLoading: true,
		activeCompany: null,
		once: false,
	}

	componentDidMount() {
		const { activeCompanyId, authUser } = this.props

		const { uid } = authUser

		activeCompanyId && this.checkUserIsAdmin(activeCompanyId, uid)
	}

	checkUserIsAdmin = (activeCompanyId, uid) => {
		let docs
		fs.collection('companies')
			.doc(activeCompanyId)
			.collection('usersAdmins')
			.doc(uid)
			.onSnapshot(snapShot => {
				docs = snapShot.data()

				if (!!docs)
					this.setState({
						isAdmin: docs.active,
					})
			})
	}

	// checkUserAdmin = (activeCompanyId, uid) => {
	// 	let userStatus
	// 	let docs
	// 	fs.collection('companies')
	// 		.doc(activeCompanyId)
	// 		.collection('users')
	// 		.doc(uid)
	// 		.onSnapshot(doc => {
	// 			if (doc.exists) {
	// 				docs = doc.data()
	// 				if (!!docs.actions) {
	// 					this.setState({
	// 						activeActions: true,
	// 					})
	// 				}

	// 				if (!!docs.notification) {
	// 					this.setState({
	// 						activeNotification: true,
	// 					})
	// 				}

	// 				if (!!docs.download) {
	// 					this.setState({
	// 						activeDownload: true,
	// 					})
	// 				}

	// 				if (!!docs.upload) {
	// 					this.setState({
	// 						activeUpload: true,
	// 					})
	// 				}

	// 				if (!!docs.log) {
	// 					this.setState({
	// 						activeLog: true,
	// 					})
	// 				}
	// 			}
	// 		})
	// }

	componentDidUpdate(prevProps, prevState) {
		const { activeCompanyId, authUser } = this.props
		const { once } = this.state
		const { uid } = authUser

		if (!once && activeCompanyId) {
			this.checkUserIsAdmin(activeCompanyId, uid)
			this.setState({ once: true })
		}
	}

	handleRoute = (event, route) => {
		const { history } = this.props
		event.preventDefault()

		//TODO: dispatch route is changing

		//Route has definitely changed
		if (history.location.pathname !== route) history.push(route)

		//TODO: dispatch route has changed
	}

	checkAllowedUsersUploads = () => {
		const { authUser, activeCompany } = this.props
		return activeCompany &&
			activeCompany.uploadAllowed &&
			activeCompany.uploadAllowed.includes(authUser.uid)
			? [
					{
						icon: 'cloud_upload',
						text: translate('label/upload'),
						route: routes.PORTAL,
					},
			  ]
			: []
	}

	// checkStatusMenu = (item, type) => {
	// 	const {
	// 		activeNotification,
	// 		activeLog,
	// 		activeActions,
	// 		activeDownload,
	// 		activeUpload,
	// 	} = this.state

	// 	switch (type) {
	// 		case 'notification':
	// 			if (!!activeNotification) {
	// 				return [item]
	// 			} else {
	// 				return []
	// 			}
	// 		case 'log':
	// 			if (!!activeLog) {
	// 				return [item]
	// 			} else {
	// 				return []
	// 			}
	// 		case 'actions':
	// 			if (!!activeActions) {
	// 				return [item]
	// 			} else {
	// 				return []
	// 			}
	// 		case 'download':
	// 			if (!!activeDownload) {
	// 				return [item]
	// 			} else {
	// 				return []
	// 			}
	// 		case 'upload':
	// 			if (!!activeUpload) {
	// 				return [item]
	// 			} else {
	// 				return []
	// 			}
	// 		default:
	// 			return []
	// 	}
	// }

	checkPermissionsNotification = item => {
		const { activeNotification } = this.state

		return !!activeNotification ? [item] : []
	}

	// checkPermissionsActions = item => {
	// 	const { activeActions } = this.state

	// 	return !!activeActions ? [item] : []
	// }

	checkAllowedUsersDownloads = () => {
		const { authUser, activeCompany } = this.props

		return activeCompany &&
			activeCompany.downloadAllowed &&
			activeCompany.downloadAllowed.includes(authUser.uid)
			? {
					icon: 'cloud_download',
					text: translate('label/download'),
					route: routes.PORTAL_DOWNLOAD,
			  }
			: null
	}

	render() {
		const {
			classes,
			activePluginId,
			drawerOpen,
			onDrawerState,
			mobile,
			activeCompanyId,
			notificationCount,
		} = this.props
		const { isAdmin } = this.state

		const sideList = (
			<div className={classes.list}>
				<NavigationAvatar />
				<List
					component="nav"
					className={classNames({
						[classes.listItemsIos]: deviceIsIos(),
					})}>
					{[
						{
							component: key => <SwitchCompany key={key} />,
						},
						{
							icon: 'account_circle',
							text: translate('title/my-account'),
							route: routes.ACCOUNT,
						},
						// isAdmin &&
						// 	!mobile && {
						// 		icon: <GroupAddIcon />,
						// 		text: translate('title/users-list'),
						// 		route: routes.CREATE_USER,
						// 	},
						isAdmin && {
							icon: <Icon>format_list_bulleted</Icon>,
							text: 'Logs',
							route: '/logs',
						},
						isAdmin &&
							!mobile && {
								icon: <GroupAddIcon />,
								text: 'Painel de controle',
								route: routes.CONTROL_PANEL_USER,
							},
						{
							icon: 'settings_input_component',
							text: translate('label/sources'),
							route: routes.PLUGIN,
						},
						{
							icon: 'dashboard',
							text: translate('title/dashboard'),
							route: `${
								!!activePluginId
									? routes.DASHBOARD.replace(
											':pluginId',
											activePluginId,
									  )
									: routes.PLUGIN
							}`,
						},
						...[
							applySuperUserLayer({
								icon: 'developer_board',
								text: translate('title/onboarding'),
								badge: 'Alpha',
								isNotAlert: true,
								route: routes.ONBOARDING_STEP.replace(
									':step',
									'introduction',
								),
							}),
						],
						{
							icon: 'send',
							text: translate('title/my-actions'),
							route: routes.USER_ACTIONS.replace(
								':actionId',
								'list',
							).replace(':companyId', activeCompanyId),
						},

						{
							icon: 'email',
							text: translate('title/notifications'),
							route: routes.USER_NOTIFICATIONS,
							badge: notificationCount,
						},

						...this.checkAllowedUsersUploads(),
						...[this.checkAllowedUsersDownloads()],
						{
							icon: 'help_outline',
							text: translate('title/about'),
							route: routes.ABOUT_ARQUIA,
						},
						...[
							modeCheckLayer({
								icon: <Icon color="primary">code</Icon>,
								text: (
									<Typography color="primary">
										{translate('title/test-page')}
									</Typography>
								),
								route: routes.TESTING,
							}),
						],
						...[
							modeCheckLayer({
								icon: <Icon color="primary">code</Icon>,
								text: (
									<Typography color="primary">
										Testes de upload
									</Typography>
								),
								route: routes.PORTAL_DEVELOP,
							}),
						],
						// ...this.checkStatusMenu(
						// 	{
						// 		icon: 'cloud_upload',
						// 		text: translate('label/upload'),
						// 		route: routes.PORTAL,
						// 	},
						// 	'upload',
						// ),

						// ...this.checkStatusMenu(
						// 	{
						// 		icon: <Icon>format_list_bulleted</Icon>,
						// 		text: 'Logs',
						// 		route: '/logs',
						// 	},
						// 	'log',
						// ),

						// ...this.checkStatusMenu(
						// 	{
						// 		icon: 'cloud_download',
						// 		text: translate('label/download'),
						// 		route: routes.PORTAL_DOWNLOAD,
						// 	},
						// 	'download',
						// ),

						//Performs a user account verification, if it is development, if it renders the test page in the side menu

						// {'icon': 'people', 'text': 'Permissões', 'route': routes.USER_MANAGEMENT}
					].map(
						(item, index) =>
							(undefined !== item &&
								!!item &&
								!item.component && (
									<ListItem
										key={index}
										button
										onClick={event =>
											this.handleRoute(event, item.route)
										}>
										<ListItemIcon>
											{item.badge ? (
												<AgilBadge
													badgeContent={
														notificationCount
													}
													isAlert={!item.isNotAlert}>
													<Icon>{item.icon}</Icon>
												</AgilBadge>
											) : (
												<Icon>{item.icon}</Icon>
											)}
										</ListItemIcon>
										<ListItemText primary={item.text} />
									</ListItem>
								)) ||
							(!!item &&
								!!item.component &&
								item.component(index)),
					)}
				</List>
				<Divider />
				<List>
					<SignOutButton />
				</List>
			</div>
		)

		return (
			<Drawer
				open={Boolean(drawerOpen)}
				onClose={() => onDrawerState(false)}
				anchor="left">
				<div
					tabIndex={0}
					role="button"
					onClick={() => onDrawerState(false)}
					onKeyDown={() => onDrawerState(false)}>
					{sideList}
				</div>
			</Drawer>
		)
	}
}

const styles = theme => ({
	list: {
		width: 250,
	},
	listItemsIos: {
		paddingLeft: 0,
		// paddingLeft: 'env(safe-area-inset-left)',
		// paddingRight: 0,
		// paddingRight: 'env(safe-area-inset-right)'
	},
})

Navigation.propTypes = {
	// toggleDrawer: PropTypes.func.isRequired
}

const makeMapStateToProps = () => {
	const getActivePlugin = makeGetActivePlugin(),
		getActivePluginId = makeGetActivePluginId(),
		getActiveCompanyId = makeGetUserActiveCompanyId(),
		getAppDrawerOpen = makeGetAppDrawerOpen(),
		getActiveCompany = makeGetUserActiveCompany(),
		mapStateToProps = state => {
			const activePlugin = getActivePlugin(state),
				activePluginId = getActivePluginId(state),
				drawerOpen = getAppDrawerOpen(state),
				activeCompanyId = getActiveCompanyId(state),
				activeCompany = getActiveCompany(state),
				states = state

			return {
				getActiveCompany,
				states,
				activePlugin,
				activePluginId,
				drawerOpen,
				activeCompanyId,
				activeCompany,
				mobile: window.cordova ? true : false,
				isLoading: state.pluginFormState.isLoading,
				dataUser: activeCompany
					? activeCompany.name
						? activeCompanyId
							? true
							: false
						: false
					: false,
			}
		}

	return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
	onDrawerState: drawerOpen => dispatch(setAppDrawerOpen(drawerOpen)),
	setLoader: isLoading => dispatch({ type: 'SET_LOADER', isLoading }),
})

export default compose(
	withNotification,
	withRouter,
	withAuthentication,
	withActiveCompany,
	connect(makeMapStateToProps(), mapDispatchToProps),
	withStyles(styles),
	withWidth(),
)(Navigation)
