export const SET_FILTERBAR_IS_LOADING = 'SET_FILTERBAR_IS_LOADING',
	RECEIVE_FILTERBAR_UNIT = 'RECEIVE_FILTERBAR_UNIT'

/**
 * [action-creator] Will be used to dispatch _state_ for **filter-bar loading**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {boolean} isLoading The loading state to be set at the filterbar general
 */
export const setFilterBarIsLoading = (isLoading) => ({
	type: SET_FILTERBAR_IS_LOADING,
	isLoading
})

/**
 * [action-creator] Will be used to dispatch _setting_ for **filter-bar unit**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} data The data to be set at the filterbar unit
 */
export const setFilterBarUnit = (data) => ({
	type: RECEIVE_FILTERBAR_UNIT,
	data
})
