import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { generatePath } from 'react-router'
import { Typography, withStyles, Tab, Button, Icon } from '@material-ui/core'
import * as routes from '../../../constants/routes'
// import FilterMenu from './components/FilterMenu'
// import CloseActionButton from './components/action/CloseActionButton'
import DetailsContainer from './components/action/DetailsContainer'
import { translate } from '../../../utils/lang'
import styles from './styles'
import * as DashItem from '../../../firebase/dashItem'
import * as UserActions from '../../../firebase/user/action'
import * as User from '../../../firebase/user'
import withAuthentication from '../../reusable/withAuthentication'
// import withActiveCompany from '../../reusable/withActiveCompany'
import { makeGetVisibleActions } from '../../../selectors/action'
import AgilTabs from '../../reusable/agilTabs/AgilTabs'
import ActionFilterContainer from './components/filter'
import ActionListContainer from './components/action/ActionListContainer'
import ActionStatisticsContainer from '../../reusable/actionStatistics'
import { setUserActiveCompany } from '../../../actions/user/company'
import { makeGetUserActiveCompanyId } from '../../../selectors/user/company'
import { openActionModal } from '../../../reducers/action/navigation'

/**
 * Manages to handle route to /user/actions
 * ! Under maintenance (remove withMaintenance when deployed to users)
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Changed the title
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description loading card info from all actions on component mount, instead of each time a action was rendered
 * @author Davi Wegner
 * @version 1.2
 *
 * @description added tabs
 * @author Davi Wegner
 * @version 1.3
 */
class UserActionsPage extends React.Component {
	componentDidMount() {
		this.handleRequest(true)
		this.checkChanges()

		// if (
		// 	this.props.match.params.activeCompanyId !==
		// 	this.props.activeCompanyId
		// )
		// 	this.props.onSetUserActiveCompany(this.props.match.params.companyId)
		// if (this.props.match.params.actionId !== 'list') {
		// 	this.props.setActionId(this.props.match.params.actionId)
		// 	this.props.onOpenActionModal()
		// }
	}

	componentWillUnmount() {
		const { unsubscribe } = this.props
		if (unsubscribe) {
			unsubscribe.forEach(value => value())
		}
	}
	componentDidUpdate(prevProps) {
		const {
			activeCompanyId,
			history,
			match,
			onSetUserActiveCompany,
			// actionId
		} = this.props
		if (
			activeCompanyId !== prevProps.activeCompanyId &&
			activeCompanyId &&
			match.params.companyId !== activeCompanyId
		) {
			history.push(
				generatePath(routes.USER_ACTIONS, {
					companyId: activeCompanyId,
					actionId: 'list',
				}),
			)
			// this.handleRequest()
		}
		if (
			match.params.companyId !== activeCompanyId &&
			prevProps.match.params.actionId !== 'list' &&
			match.params.actionId !== 'list' &&
			match.params.actionId !== prevProps.match.params.actionI
		)
			history.push(
				generatePath(routes.USER_ACTIONS, {
					companyId: activeCompanyId,
					actionId: 'list',
				}),
			)

		if (
			match.params.companyId !== prevProps.match.params.companyId &&
			match.params.companyId
		) {
			onSetUserActiveCompany(match.params.companyId)
			// this.handleRequest()
		}
		// if (match.params.actionId !== 'list' && prevProps.match.params.actionId = {})
		if (activeCompanyId !== prevProps.activeCompanyId && activeCompanyId)
			this.handleRequest()
	}
	handleRequest = firstMount => {
		let {
			activeCompanyId,
			authUser,
			receiveList,
			setLoading,
			setCardInfo,
			setAuthorinfo,
			match,
			onSetUserActiveCompany,
		} = this.props
		setLoading(true)
		if (match.params.companyId !== activeCompanyId && firstMount) {
			activeCompanyId = match.params.companyId
			onSetUserActiveCompany(activeCompanyId)
		}
		UserActions.loadUserReceiverActions(authUser.uid, activeCompanyId)
			.then(actions => {
				return actions
			})
			.then(actions => {
				return UserActions.loadUserActions(
					authUser.uid,
					activeCompanyId,
				).then(ownActions => Object.assign({}, actions, ownActions))
			})
			.then(allActions => {
				//set get all unique cardId values from array returned in the map function, ecmas 6 function,
				// returns a array with those unique values

				new Set(
					Object.values(allActions).map(action => action.cardId),
				).forEach(cardId => {
					DashItem.get(cardId).then(snapshot =>
						//using the cardIds for loading card info, by cardId not by action
						setCardInfo({
							[cardId]: !!snapshot.data()
								? snapshot.data().title
								: 'Dashboard',
						}),
					)
				})
				const personIds = []
				Object.values(allActions).forEach(action => {
					personIds.push(action.author)
					if (
						action.receiver !== null &&
						action.receiver !== undefined
					)
						personIds.push(action.receiver.key)
				})

				new Set(personIds).forEach(authorId =>
					User.get(authorId).then(user =>
						setAuthorinfo({ [authorId]: user.data() }),
					),
				)
				return receiveList(allActions)
			})
			.then(() => setLoading(false))
	}
	onChange = newActions => {
		const {
			updateList,
			setNewActions,
			setCardInfo,
			setAuthorinfo,
			actionList,
		} = this.props
		if (actionList != null && newActions != null) {
			updateList(newActions)
			setNewActions(Object.keys(newActions))

			new Set(
				Object.values(actionList).map(action => action.cardId),
			).forEach(cardId => {
				DashItem.get(cardId).then(snapshot =>
					//using the cardIds for loading card info, by cardId not by action
					setCardInfo({
						[cardId]: !!snapshot.data()
							? snapshot.data().title
							: 'Dashboard',
					}),
				)
			})
			const personIds = []
			Object.values(actionList).forEach(action => {
				personIds.push(action.author)
				if (action.receiver !== null && action.receiver !== undefined)
					personIds.push(action.receiver.key)
			})

			new Set(personIds).forEach(authorId =>
				User.get(authorId).then(user => {
					setAuthorinfo({ [authorId]: user.data() })
				}),
			)
		}
	}
	checkChanges = () => {
		const { activeCompanyId, authUser, setUnsubscribe } = this.props
		setUnsubscribe(
			UserActions.checkChanges(
				activeCompanyId,
				authUser.uid,
				this.onChange,
			),
		)
	}
	handleChange = (event, value) => this.props.handleFilterAuthor(value)

	render() {
		const {
			classes,
			actionListInfo,
			byAuthor,
			newActions,
			isLoading,
			actionId,
			evaluationWillRender,
			match,
			history,
		} = this.props

		return (
			<div>
				<div className={classes.root}>
					<AgilTabs
						activeTabIndex={byAuthor}
						handleChange={this.handleChange}
						tabs={[
							<Tab
								value={0}
								label={`${translate('label/generated')}  ${
									actionListInfo !== undefined
										? `(${actionListInfo.generated.count})`
										: ''
								}`}
							/>,
							<Tab
								value={1}
								label={`${translate('label/received')}  ${
									actionListInfo !== undefined
										? `(${actionListInfo.received.count})`
										: ''
								}`}
							/>,
						]}
						buttons={[
							<ActionFilterContainer
								actionListInfo={actionListInfo}
							/>,
						]}
					/>
					{byAuthor !== 2 && (
						<div className={classes.titleContainer}>
							<Typography variant="h6" gutterBottom>
								{translate('title/my-actions')}
							</Typography>
							<Button
								className={classes.btnSeeDetails}
								onClick={event => this.handleChange(event, 2)}
								variant="outlined">
								<Icon className={classes.detailIcon}>
									trending_up
								</Icon>
								{translate('label/see-details')}
							</Button>
						</div>
					)}
					{actionListInfo && !isLoading && (
						<ActionListContainer
							actionId={actionId}
							actionList={actionListInfo.actionList}
							byAuthor={byAuthor}
							newActions={newActions}
							history={history}
							match={match}
							evaluationWillRender={evaluationWillRender}
						/>
					)}
					{actionId && (
						<DetailsContainer
							history={history}
							match={match}
							actionId={actionId}
						/>
					)}
					{byAuthor === 2 && actionListInfo && !isLoading && (
						<React.Fragment>
							<Typography variant="h6" gutterBottom>
								{translate('label/statistics')}
							</Typography>
							<ActionStatisticsContainer
								actionListInfo={actionListInfo}
							/>
						</React.Fragment>
					)}
				</div>
				<div className={classes.parametro}></div>
			</div>
		)
	}
}

UserActionsPage.propTypes = {
	classes: PropTypes.object.isRequired,
}

const makeStateToPropsMapper = () => {
	const getActions = makeGetVisibleActions()

	const mapStateToProps = (state, ownProps) => ({
		actionListInfo: getActions(state),
		actionId: ownProps.match.params.actionId,
		actionList: state.actionsState.actionList,
		unsubscribe: state.actionsState.unsubscribe,
		newActions: state.actionsState.newActionIds,
		isLoading: state.actionsState.isLoading,
		byAuthor: state.actionsStateByAuthor.byAuthor,
		evaluationWillRender: state.evaluationDialogState.isOpen,
		activeCompanyId: makeGetUserActiveCompanyId()(state),
	})
	return mapStateToProps
}
const mapDispatchToProps = dispatch => ({
	handleFilterAuthor: value =>
		dispatch({ type: 'FILTER_BY_AUTHOR_TYPE_ACTIONS', value }),
	receiveList: list => dispatch({ type: 'SET_ACTION_LIST', list }),
	updateList: newAction =>
		dispatch({ type: 'UPDATE_ACTION_LIST', newAction }),
	setNewActions: actionIds =>
		dispatch({ type: 'SET_NEW_ACTION_IDS', actionIds }),
	setLoading: isLoading =>
		dispatch({ type: 'SET_ACTION_LOADING_STATUS', isLoading }),
	setCardInfo: cardInfo =>
		dispatch({ type: 'SET_CARD_INFO_ON_ACTION', cardInfo }),
	setUnsubscribe: unsubscribe =>
		dispatch({ type: 'SET_UNSUBSCRIBE_FUNC', unsubscribe }),
	setAuthorinfo: authorInfo =>
		dispatch({ type: 'SET_AUTHOR_INFO_ON_FILTER', authorInfo }),
	onSetUserActiveCompany: activeCompanyId =>
		dispatch(setUserActiveCompany(activeCompanyId)),
	setActionId: actionId => dispatch({ type: 'SET_ACTION_ID', actionId }),
	onOpenActionModal: () => dispatch(openActionModal()),
})

export default compose(
	withRouter,
	withAuthentication,
	connect(makeStateToPropsMapper, mapDispatchToProps),
	// withActiveCompany,
	withStyles(styles),
)(UserActionsPage)
