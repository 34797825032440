export const SET_OBJECT_NCS_STATE_LOADING = 'SET_OBJECT_NCS_STATE_LOADING',
	SET_OBJECT_NCS_DATA = 'SET_OBJECT_NCS_DATA'

export const setLoading = (isLoading) => ({
	type: SET_OBJECT_NCS_STATE_LOADING,
	isLoading
})

export const setData = (data) => ({
	type: SET_OBJECT_NCS_DATA,
	data: data
})
