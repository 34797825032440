export const MANAGE_HISTORY_DAILY_LOAD_OWN_LOAD_LOADING_STATE =
		'MANAGE_HISTORY_DAILY_LOAD_OWN_LOAD_LOADING_STATE',
	LOAD_HISTORY_DAILY_LOAD_OWN_LOAD_DATA =
		'LOAD_HISTORY_DAILY_LOAD_OWN_LOAD_DATA',
	SET_HISTORY_DAILY_LOAD_OWN_LOAD_PERIOD =
		'SET_HISTORY_DAILY_LOAD_OWN_LOAD_PERIOD'

export const setHistoryDailyLoadOwnLoadPeriod = (
	dateConfig,
	value,
	formType
) => ({
	type: SET_HISTORY_DAILY_LOAD_OWN_LOAD_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadHistoryDailyLoadOwnLoadData = (historyDailyLoadOwnLoad) => ({
	type: LOAD_HISTORY_DAILY_LOAD_OWN_LOAD_DATA,
	historyDailyLoadOwnLoad
})

export const setLoading = (isLoading) => ({
	type: MANAGE_HISTORY_DAILY_LOAD_OWN_LOAD_LOADING_STATE,
	isLoading
})
