const INITIAL_STATE = {
	permissions: null,
	currentPermission: null
}

/**
 * Will CLEAR the active permission
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 */
const clearCurrentPermission = (state) => {
	return Object.assign({}, state, {
		currentPermission: INITIAL_STATE.currentPermission
	})
}

/**
 * Will set an active permission listed
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @author Wegner
 * @version 1.0
 * @description changed object assign parameters, now will always assign in currentPermission
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" property and other desired
 */
const setCurrentPermission = (state, action) => {
	return Object.assign({}, state, {
		currentPermission: action.currentPermission
	})
}

/**
 * Will clear the permissions list
 *
 * @param {Object} state The current app state
 */
const clearPermissions = (state) =>
	Object.assign({}, state, { permissions: INITIAL_STATE.permissions })

/**
 * Will add items to the permissions list
 *
 * @param {Object} state The current app state
 * @param {Object} action The data being set at the current state
 */
const permissionsLoaded = (state, action) =>
	Object.assign({}, state, action.permissions)

/**
 * Handles system default permissions list
 *
 * @param {Object} state The current state of the app
 * @param {Object} action The action object
 */
function defaultPermissionReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CLEAR_CURRENT_PERMISSION': {
			return clearCurrentPermission(state)
		}
		case 'SET_CURRENT_PERMISSION': {
			return setCurrentPermission(state, action)
		}
		case 'CLEAR_DEFAULT_PERMISSIONS': {
			return clearPermissions(state)
		}
		case 'DEFAULT_PERMISSIONS_LOADED': {
			return permissionsLoaded(state, action)
		}
		default:
			return state
	}
}

export default defaultPermissionReducer
