import {
	PLUGIN_LIST_LOADED,
	CLEAR_LOADED_PLUGIN_LIST,
	PLUGIN_LIST_LOADING
} from '../../../actions/plugin/list'

const RESET_PLUGIN_LIST_LOADING = 'RESET_PLUGIN_LIST_LOADING'

const INITIAL_STATE = {
	plugins: null,
	pluginsLoading: false
}

/**
 * Sets the loading flag to false
 *
 * @description
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state
 */
const resetPluginsLoading = (state) =>
	Object.assign({}, state, { pluginsLoading: INITIAL_STATE.pluginsLoading })

/**
 * Will set plugins loading (and also clear plugins set)
 *
 * @param {Object} state The current app state
 * @param {*} action The action with "type" and the desired props to propagate
 */
const pluginsBeginLoad = (state) => {
	let nextState = clearLoadedPlugins(state)

	return Object.assign({}, nextState, { pluginsLoading: true })
}

/**
 * Will clear loaded plugins set
 *
 * @param {Object} state The current app state
 */
const clearLoadedPlugins = (state) =>
	Object.assign({}, state, {
		plugins: INITIAL_STATE.plugins
	})

/**
 * Will set plugins loaded
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {*} action The action with "type" and the desired props to propagate
 */
const pluginsLoaded = (state, action) =>
	Object.assign({}, state, {
		plugins: action.plugins,
		pluginsLoading: INITIAL_STATE.pluginsLoading
	})

/**
 * Manages plugin list, form & else states in a separate layer
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
function pluginListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case RESET_PLUGIN_LIST_LOADING: {
			return resetPluginsLoading(state)
		}
		case PLUGIN_LIST_LOADING: {
			return pluginsBeginLoad(state)
		}
		case CLEAR_LOADED_PLUGIN_LIST: {
			return clearLoadedPlugins(state)
		}
		case PLUGIN_LIST_LOADED: {
			return pluginsLoaded(state, action)
		}
		default:
			return state
	}
}

export default pluginListReducer

export { RESET_PLUGIN_LIST_LOADING }
