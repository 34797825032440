export const SET_DASH_ITEM_FORM_VALUE = 'SET_DASH_ITEM_FORM_VALUE',
	CLEAR_DASH_ITEM_FORM = 'CLEAR_DASH_ITEM_FORM'

export const setDashItemValue = (value) => ({
	type: SET_DASH_ITEM_FORM_VALUE,
	value
})

export const clearDashItemForm = () => ({
	type: CLEAR_DASH_ITEM_FORM
})
