const INITIAL_STATE = {
	isLoading: null,
	closeReport: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		origin: ''
	}
}
/**
 * closeReport state reducer manager
 * @param {*} state
 * @param {*} action
 */

const closeReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'MANAGE_CLOSE_REPORT_LOADING_STATE': {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case 'LOAD_CLOSE_REPORT_DATA': {
			return Object.assign({}, state, {
				closeReport: action.data,
				isLoading: false
			})
		}
		case 'SET_CLOSE_REPORT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_CLOSE_REPORT_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_CLOSE_REPORT_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case 'SET_CLOSE_REPORT_PERIOD': {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default closeReportReducer
