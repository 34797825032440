export const SET_AUTHOR_INFO_ON_NOTIFICATION = 'SET_AUTHOR_INFO_ON_NOTIFICATION'

/**
 * [action-creator] Dispatches changes to **authorInfo** prop on notifications
 *
 * @description Created the action cretor
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} authorInfo The current author info object
 */
export const setNotificationAuthorInfo = (authorInfo) => ({
	type: SET_AUTHOR_INFO_ON_NOTIFICATION,
	authorInfo
})
