import React, { Component } from 'react'
import { withStyles, Icon, IconButton, Button, Typography } from '@material-ui/core'
import classNames from 'classnames'
import Swiper from '../../swiperViews/Swiper'

/**
 * @description adjustment in filter explanded
 * @author jeffersonguilhermemachadobarreto
 * @version 1.1
 */
class DashSetView extends Component {
	state = {
		index: 0,
		showUp: true,
		showDown: true,
	}
	componentDidMount() {
		// console.count('Dashsetview Mounted')
		const { activeDashSet, onSwitchDashSet, dashSetKeys } = this.props
		const { index } = this.state

		if (!activeDashSet) {
			onSwitchDashSet(dashSetKeys[index])
			this.setState({ showUp: false })
		} else if (dashSetKeys.indexOf(activeDashSet) !== index)
			this.setState({
				showUp: !(dashSetKeys.indexOf(activeDashSet) === 0),
			})
		else {
			this.setState({
				showUp: !(dashSetKeys.indexOf(activeDashSet) === 0 || dashSetKeys.indexOf(activeDashSet) === -1),
			})
		}
	}
	swicthingDashSet = index => {
		const { dashSetKeys, onSwitchDashSet, children } = this.props
		onSwitchDashSet(dashSetKeys[index])
		this.setState({
			index,
			showUp: !(index === 0),
			showDown: index === 0 || !(index === React.Children.count(children) - 1),
		})
	}
	componentDidUpdate() {
		const { dashSetKeys, activeDashSet } = this.props
		const { index } = this.state

		if (activeDashSet && dashSetKeys.includes(activeDashSet) && index !== dashSetKeys.indexOf(activeDashSet)) {
			this.setState({
				index: dashSetKeys.indexOf(activeDashSet),
			})
		}
	}
	nextCard = () => {
		const { index } = this.state
		const { children, onSwitchDashSet, dashSetKeys } = this.props

		if (index < React.Children.count(children) - 1) {
			onSwitchDashSet(dashSetKeys[index + 1])
			this.setState({ index: index + 1, showUp: true })
		}

		if (index === React.Children.count(children) - 2) {
			this.setState({
				showDown: false,
			})
		}

		if (React.Children.count(children) <= 1) {
			this.setState({ showDown: false })
		}

		if (index > 0) {
			this.setState({
				showUp: true,
			})
		}
	}

	prevCard = () => {
		const { index } = this.state
		const { onSwitchDashSet, dashSetKeys } = this.props

		let nextIndex = index - 1

		onSwitchDashSet(dashSetKeys[nextIndex])

		this.setState({ index: nextIndex, showUp: !(nextIndex === 0) })
		this.setState({ showDown: true })
	}
	render() {
		const { showSwipe, children, classes, dashSetKeys } = this.props
		const { index, showUp, showDown } = this.state
		const arrayChildren = React.Children.toArray(children)

		return (
			<React.Fragment>
				<div className={showSwipe ? classes.containerActive : classes.container}>
					{showSwipe && (
						<IconButton index={index} onClick={this.prevCard} disabled={!showUp} className={classes.arrowContainer} key={Math.random()}>
							<Icon
								className={classNames(classes.arrowSwiper, classes.arrowUp, {
									arrowAnimation: showUp,
									[classes.toVanish]: !showUp,
								})}>
								keyboard_arrow_up
							</Icon>
						</IconButton>
					)}
					<Swiper disabled={!showSwipe} indexSwipe={index} onChangeIndex={this.swicthingDashSet} axis={'y'}>
						{React.Children.toArray(children).map((dashSet, i) =>
							React.cloneElement(dashSet, {
								activeDashSetIndex: index,
								handleDashSetRelation: this.handleDashSetRelation,
								dashSetKey: dashSetKeys[i],
							})
						)}
					</Swiper>
					{showSwipe && (
						<IconButton
							index={index}
							onClick={this.nextCard}
							disabled={!showDown}
							key={Math.random()}
							className={classNames(classes.arrowContainer, classes.arrowContainerDown)}>
							<Icon
								className={classNames(classes.arrowSwiper, classes.arrowDown, {
									arrowAnimation: showDown && arrayChildren.length > 1,
									[classes.toVanish]: !showDown || !(arrayChildren.length > 1),
								})}>
								keyboard_arrow_down
							</Icon>
						</IconButton>
					)}
				</div>
			</React.Fragment>
		)
	}
}

const styles = theme => ({
	slide: {
		flex: 1,
		marginTop: 0,
		marginBottom: 0,
		// marginLeft: '5%',
		// marginRight: '5%',
		// padding: '0 5px',
		transform: 'scale(0.9)',
		border: '1px solid #9575cd',
		borderRadius: '20px',
		overflow: 'hidden',
		minHeight: '100%',
		maxHeight: '100%',
		animation: 'scaleDown 0.2s',
		textAlign: 'center',
	},
	slideActive: {
		transform: 'scale(1)',
		animation: 'grow 0.2s',
		overflow: 'hidden',
		textAlign: 'center',
		borderRadius: 0,
		border: 'none',
		marginLeft: '0px',
		marginRight: '0px',
		height: '100%',
		paddingLeft: 0,
	},
	app: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		height: '100%',
	},
	arrowContainer: {
		width: '50px',
		height: '50px',
	},
	arrowContainerDown: {
		position: 'relative',
		bottom: '20px',
	},
	arrowSwiper: {
		color: theme.palette.primary.light,
		fontSize: '50px',
		fontWeight: 'bolder',

		// position: 'fixed',
		// zIndex: '1'
		// top: 'calc(50% - (48px/2))'
	},

	arrowRight: {
		right: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	arrowLeft: {
		left: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	arrowDown: {
		left: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	arrowUp: {
		left: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	toVanish: {
		color: 'transparent',
	},
	/*Animação das setas do swipe */
	'@keyframes pulse': {
		from: {
			color: theme.palette.primary.light,
		},
		to: {
			color: 'transparent',
		},
	},

	/*Animação de encolhimento de card , quando clicar para selecionar outros cards */
	'@keyframes grow': {
		'0%': {
			// transform: 'scale(0.9)',
			borderRadius: '20px',
			border: '1px solid #9575cd',
		},
		'25%': {
			transform: 'scale(0.925)',
			borderRadius: '20px',
		},
		'50%': {
			transform: 'scale(0.95)',
			borderRadius: '20px',
		},
		'75%': {
			transform: 'scale(0.975)',
			borderRadius: '20px',
		},
		'100%': {
			transform: 'scale(1)',
			borderRadius: '20px',
		},
	},
	/*Animação de crescimento de card , quando clicar ele seleciona o card */
	'@keyframes scaleDown': {
		'0%': {
			transform: 'scale(1)',
		},
		'25%': {
			transform: 'scale(0.975)',
		},
		'50%': {
			transform: 'scale(0.95)',
		},
		'75%': {
			transform: 'scale(0.925)',
		},
		'100%': {
			transform: 'scale(0.9)',
		},
	},
	container: {
		width: '100%',
		// height: 'calc(100vh - 115px)'
		height: 'calc(100vh - 70px)',
	},
	containerActive: {
		width: '100%',
		height: 'calc(100vh - 115px)',
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dashItemViewContainer: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		alignContent: 'center',
	},
	dashItemViewContainerActive: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		alignContent: 'center',
	},
})

export default withStyles(styles)(DashSetView)
