import * as UserPermission from '../../../../user/permission'
import * as CompanyFilter from '../../../filter'

/**
 * Manages to search for all user-level-filters
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} uid The current logged-in user ID
 * @param {string} activeCompanyId The active company ID
 */
export const getByUser = (uid, activeCompanyId) => {
	let companyFilters = []

	//Search for all user-level-permissions
	return UserPermission.get(uid, activeCompanyId)
		.then((snapShots) => {
			//For each permission,
			//Gets a filter list and search for
			//Company-level filter, by their ID
			snapShots.forEach((permission) => {
				//The current permission exists
				if (permission.exists) {
					Object.keys(permission.data().filters).forEach(
						(filterId) => {
							companyFilters.push(
								CompanyFilter.get(activeCompanyId, filterId)
							)
						}
					)
				}
			})

			return companyFilters
		})
		.then((promises) => {
			return Promise.all(promises)
		})
}
