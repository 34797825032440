export const TEAM_LIST_LOADED = 'TEAM_LIST_LOADED',
	CLEAR_TEAM_LIST = 'CLEAR_TEAM_LIST',
	SET_TEAM_LIST_LOADING = 'SET_TEAM_LIST_LOADING',
	REVERSE_TEAM_LIST = 'REVERSE_TEAM_LIST',
	CHANGE_TEAM_LIST_ACTIVE_STATE = 'CHANGE_TEAM_LIST_ACTIVE_STATE',
	LOAD_TEAM_LIST_FILTER_VALUE = 'LOAD_TEAM_LIST_FILTER_VALUE',
	CHANGE_TEAM_LIST_COMPARATOR = 'CHANGE_TEAM_LIST_COMPARATOR'

const INITIAL_STATE = {
	teams: [],
	teamsLoading: false,
	// status: [0, 1],
	filters: {},
	selectedValues: {
		status: [1]
	},
	comparers: {
		perc_reach: [''],
		perc_allocated: ['']
	},
	update: false
}

/**
 * Manages team-list state-part-layer
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and specific props
 */
const teamListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_TEAM_LIST_LOADING: {
			return Object.assign({}, state, {
				teamsLoading: action.teamsLoading
			})
		}
		case TEAM_LIST_LOADED: {
			return Object.assign({}, state, {
				teams: action.teams,
				teamsLoading: INITIAL_STATE.teamsLoading,
				update: INITIAL_STATE.update
			})
		}
		case CLEAR_TEAM_LIST: {
			return Object.assign({}, state, { teams: INITIAL_STATE.teams })
		}
		case REVERSE_TEAM_LIST: {
			return Object.assign({}, state, {
				teams: [...state.teams].reverse()
			})
		}
		case CHANGE_TEAM_LIST_ACTIVE_STATE: {
			return Object.assign({}, state, {
				selectedValues: Object.assign({}, state.selectedValues, {
					[action.key]: action.values
				}),
				update: action.update
			})
		}
		case LOAD_TEAM_LIST_FILTER_VALUE: {
			return Object.assign({}, state, {
				filters: Object.assign({}, state.filters, {
					[action.filterKey]: Object.assign(
						{},
						state.filters[action.filterKey],
						action.value
					)
				})
			})
		}
		case CHANGE_TEAM_LIST_COMPARATOR: {
			return Object.assign({}, state, {
				comparers: Object.assign({}, state.comparers, {
					[action.key]: action.values
				})
			})
		}
		default:
			return state
	}
}

export default teamListReducer
