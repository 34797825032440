import React from 'react'
import Draggable from 'react-draggable'
import { withStyles, Icon } from '@material-ui/core'
// import { connect } from 'react-redux'
// import classNames from 'classnames'
// import favicon from './favicon.ico'

class AssistiveTouchButton extends React.Component {
	state = {
		extend: null
	}
	componentDidMount() {}

	componentWillUnmount() {
		this.removeClickListener()
	}
	removeClickListener = () => {
		document.removeEventListener('click', this.handleClose)
	}
	constructor(props) {
		super(props)
		this.draggableContainer = React.createRef()
		this.draggableButton = React.createRef()
		this.handleClose = this.handleClose.bind(this)
	}
	handleClick = (event) => {
		const { extend } = this.state

		if (!extend) document.addEventListener('click', this.handleClose)
		if (extend) this.removeClickListener()

		this.setState({ extend: !extend })
	}
	// handleClose = () => {
	// 	this.setState({ anchorEl: null })
	// }
	handleClose = (event) => {
		// event.preventDefault()
		if (
			this.draggableButton &&
			this.draggableButton.current !== event.target
		) {
			this.removeClickListener()
			this.setState({ extend: false })
			return false
		}
		// event.preventDefault()
		// return event
	}
	render() {
		const { children } = this.props
		const { extend } = this.state
		return (
			<React.Fragment>
				<DraggableButton
					extend={extend}
					handleClick={this.handleClick}
					handleClose={this.handleClose}
					ref={{
						draggableContainer: this.draggableContainer,
						draggableButton: this.draggableButton
					}}
				/>
				{!!children &&
					React.cloneElement(children, {
						draggableContainer: this.draggableContainer
					})}
			</React.Fragment>
		)
	}
}

const styles = (theme) => ({
	container: {
		// backgroundColor: '#000',
		position: 'fixed',
		height: '36px',
		width: '36px',
		backgroundColor: 'rgb(255,255,255)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2px',
		paddingBottom: '3px',
		borderRadius: '50%',
		zIndex: '1050',
		'-webkit-box-shadow': '0px 0px 5px 0px rgba(77,77,77,1)',
		'-moz-box-shadow': '0px 0px 5px 0px rgba(77,77,77,1)',
		boxShadow: '0px 0px 5px 0px rgba(77,77,77,1)'
	},
	icon: {
		animation: 'rotateIn 3s cubic-bezier(0.5,0.2,0.2, 1)'
	},

	extensionContainer: {
		position: 'fixed',
		height: 'calc(100vh - 56px)',
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing.unit * 2,
		top: '0',
		left: '0',
		right: '0',
		zIndex: '1050'
	},
	extensionOut: {
		// position: 'absolute',
		opacity: '0',
		animation: 'fadeOut 0.2s',
		display: 'none'
	},
	extension: {
		// position: 'absolute',
		display: 'none'
	},
	extensionActive: {
		// top: '-20px',
		// position: '',
		// left: '12.5%',
		// top: '30%',
		// right: '30px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		// animation:'animation'
		animation: 'fadeIn 0.4s'
	},
	extensionIcon: {
		// marginTop: theme.spacing.unit / 2,
		// marginLeft: theme.spacing.unit * 1.5,
		// animation: 'expanding 0.2s'
		// opacity: 0,
		animation: 'rotate 0.5s'
	},
	'@keyframes expanding': {
		'0%': {
			marginTop: theme.spacing.unit / 8,
			marginLeft: theme.spacing.unit * 0.5
		},
		'50%': {
			marginTop: theme.spacing.unit / 4,
			marginLeft: theme.spacing.unit * 1
		},
		'100%': {
			marginTop: theme.spacing.unit / 2,
			marginLeft: theme.spacing.unit * 1.5
		}
	},
	'@keyframes rotate': {
		'0%': {
			transform: 'rotate(0deg)'
		},
		'50%': {
			transform: 'rotate(45deg)'
		},
		'100%': {
			transform: 'rotate(90deg)'
		}
	},
	'@keyframes rotateBack': {
		'0%': {
			transform: 'rotate(-90deg)'
		},
		'50%': {
			transform: 'rotate(-45deg)'
		},
		'100%': {
			transform: 'rotate(0deg)'
		}
	},
	teste: {
		animation: 'rotateBack 0.5s'
	},
	'@keyframes comingBack': {
		'0%': {
			marginTop: theme.spacing.unit / 2,
			marginLeft: theme.spacing.unit * 1.5
		},
		'50%': {
			marginTop: theme.spacing.unit / 4,
			marginLeft: theme.spacing.unit * 1
		},
		'75%': {
			marginTop: theme.spacing.unit / 8,
			marginLeft: theme.spacing.unit * 0.5
		},
		'100%': {
			marginTop: 0,
			marginLeft: 0
		}
	},
	'@keyframes fadeIn': {
		'0%': {
			opacity: '0.2',
			transform: 'scaleX(0.0) scaleY(0.0)'
		},
		'100%': {
			opacity: '1',
			transform: 'scaleX(1.00) scaleY(1.00)'
		}
	},
	'@keyframes fadeOut': {
		'0%': {
			opacity: '1',
			transform: 'scaleX(1.0) scaleY(1.0)'
		},
		'100%': {
			opacity: '0',
			transform: 'scaleX(0.00) scaleY(0.00)'
		}
	},
	'@keyframes shake': {
		'10%, 90%': {
			margin: '-2px'
		},

		'20%, 80%': {
			margin: '2px'
		},

		'30%, 50%, 70%': {
			margin: '-2px'
		},
		'40%, 60%': {
			margin: '2px'
		}
	},
	commandContainer: {
		borderRadius: '20px',
		backgroundColor: 'rgb(255, 255, 255)',
		boxShadow: '0px 0px 2.5px',
		width: '100%',
		padding: '10px'
	}
	// hideHandler:{
	// 	animation: F
	// }
})
const DraggableButton = React.forwardRef((props, ref) => (
	<StyledDraggableButton {...props} {...ref} />
))

const StyledDraggableButton = withStyles(styles)((props) => {
	const {
		classes,
		draggableContainer,
		draggableButton,
		handleClick,
		extend
	} = props

	return (
		<React.Fragment>
			<div
				className={
					extend ? classes.extensionContainer : classes.extension
				}
			>
				<div
					className={
						extend ? classes.extensionActive : classes.extension
					}
				>
					<div
						className={classes.commandContainer}
						ref={draggableContainer}
					/>
				</div>
			</div>
			<Draggable
				handle={'#AssistiveButton'}
				onDrag={(e) => e.preventDefault()}
				defaultPosition={{ x: 250, y: 300 }}
			>
				<div className={classes.container}>
					<Icon
						id={'AssistiveButton'}
						alt="agildashIcon"
						// height="32px"
						// width="32px"
						ref={draggableButton}
						className={
							!!extend ? classes.extensionIcon : classes.teste
						}
						// src={favicon}
						onClick={handleClick}
					>
						settings
					</Icon>
				</div>
			</Draggable>
		</React.Fragment>
	)
})

export default AssistiveTouchButton
