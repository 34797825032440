import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { translate } from '../../../../../utils/lang'

/**
 * Manages to display a friendly developer message
 * explaining que programming mistake prior to display errors
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} props Container of styles (classes) item
 */
const WidthTip = (props) => {
	const { classes } = props
	return (
		<div className={classes.root}>
			<Typography className={classes.content} variant="h6">
				{translate('description/chart-width-tip')}
			</Typography>
		</div>
	)
}

const styles = (theme) => ({
	root: {
		// height: height,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	content: {
		flex: '0 0 auto',
		textAlign: 'center',
		wordWrap: 'normal'
	}
})

export default withStyles(styles)(WidthTip)
