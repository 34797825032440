export const MANAGE_CHECK_BY_LOCATION_COVID_LOADING_STATE =
		'MANAGE_CHECK_BY_LOCATION_COVID_LOADING_STATE',
	LOAD_CHECK_BY_LOCATION_COVID_DATA = 'LOAD_CHECK_BY_LOCATION_COVID_DATA',
	SET_CHECK_BY_LOCATION_COVID_PERIOD = 'SET_CHECK_BY_LOCATION_COVID_PERIOD',
	LOAD_COVID_DATA_FILTERS = 'LOAD_COVID_DATA_FILTERS'

export const setCheckByLocationCovidPeriod = (dateConfig, value, formType) => ({
	type: SET_CHECK_BY_LOCATION_COVID_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadCovidDataFiltersCovid = (mapCovidFilters) => ({
	type: LOAD_COVID_DATA_FILTERS,
	mapCovidFilters
})

export const loadCheckByLocationCovidData = (autoCheckCovid) => ({
	type: LOAD_CHECK_BY_LOCATION_COVID_DATA,
	autoCheckCovid
})

export const setLoading = (isLoading) => ({
	type: MANAGE_CHECK_BY_LOCATION_COVID_LOADING_STATE,
	isLoading
})
