import { fs } from '../../firebase'

/**
 * [Firestore] Will get (active or dev-active) plugin dash-sets from a Plugin ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description dev-active support added to this kind of objects
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} pluginIn The plugin ID
 */
export const getByPlugin = async (companyId, pluginId, uid) => {

	let ref = fs
			.collection('companies')
			.doc(companyId)
			.collection('users')
			.doc(uid)
			.collection('plugins')
			.doc(pluginId)
			.collection('dashSets')
			.orderBy('order', 'asc'),
		active = ref.where('active', '==', true),
		secondary,
		collection = []

	//TODO: CHECK, BECAUSE THIS IS PROVISORY
	// if (process.env.REACT_APP_NODE_ENV.trim() === 'production') {
	// 	 = ref.where('active', '==', true)
	// }
	if (process.env.REACT_APP_NODE_ENV === 'stage') {
		active.where('activeStage', '==', false)

		secondary = ref
			.where('activeStage', '==', true)
			.where('active', '==', false)
	}
	if (process.env.REACT_APP_NODE_ENV.trim() === 'development') {

		active.where('activeDev', '==', false)
		secondary = ref
			.where('activeDev', '==', true)
			.where('active', '==', false)
	}
	await Promise.all([
		active.get(),
		...(process.env.REACT_APP_NODE_ENV !== 'production'
			? [secondary.get()]
			: [])
	]).then((value) => {
		value.forEach((querySnapshot) => {
			querySnapshot.forEach((item) => collection.push(item))
		})
	})
	return collection
}
