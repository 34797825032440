import {
	Card,
	CardActions,
	CardContent,
	Divider,
	Icon,
	IconButton,
	Modal,
	Typography,
	withStyles,
} from '@material-ui/core'
import b64toBlob from 'b64-to-blob'
import PropTypes from 'prop-types'
import React from 'react'
import FileBase64 from 'react-file-base64'
import Moment from 'react-moment'
import { newStorage } from '../../../../../../firebase/firebase'
import { translate } from '../../../../../../utils/lang'

/**
 * * Will expose the list-item beautifully
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added support for whole-card-clicking primary action
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Remove button config
 * @author lucas lopes
 * @version 1.2
 *
 * @description Added support for lastUpdateDate from database and firebase
 * @author Mauricio Ray
 * @version 1.3
 *
 * @description Ajust layout modal, image in plugin and implement componentDidMount
 * @author Jeffersonguilhermemachadobarreto
 * @version 1.4
 */
class PluginListItemExpanded extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			openModal: false,
			files: [],
			pluginKey: props.pluginKey,
			companyId: props.companyId,
			showSuccessMessage: false,
			imgUrl: null,
		}

		this.handleImageChange = this.handleImageChange.bind(this)
	}

	componentDidMount() {
		const { companyId, pluginKey } = this.state

		this.getImageUrlPath(companyId, pluginKey).then(response => {
			this.setState({ imgUrl: response })
		})
	}

	formatToBlob(b64) {
		const formattedB64 =
			undefined !== b64 && null !== b64 ? b64.split(',')[1] : ''

		return formattedB64
	}

	defineImagePath = (companyId, pluginKey) => {
		let rootReference = newStorage.ref()
		let fileReference = rootReference.child(
			`companies/${companyId}/plugins/${pluginKey}/logo/logo.png`,
		)

		return fileReference
	}

	getImageUrlPath = async (companyId, pluginKey) => {
		let fileReference = newStorage.refFromURL(
			`gs://agildash-app-db.appspot.com/companies/${companyId}/plugins/${pluginKey}/logo/logo.png`,
		)
		try {
			let result = await fileReference.getDownloadURL()

			return result
		} catch (e) {
			console.error(e)
		}
	}

	saveImage = (fileB64, companyId, pluginKey) => {
		let fileBlob = b64toBlob(this.formatToBlob(fileB64), 'image/png')

		this.defineImagePath(companyId, pluginKey)
			.put(fileBlob)
			.then(response => {
				if (response.state === 'success') {
					this.setState({
						showSuccessMessage: true,
						files: [],
					})
				}
			})
			.catch(error => {
				console.error(error)
			})
	}

	handleOpen = () => this.setState({ openModal: true })

	handleClose = () => {
		this.setState({
			openModal: false,
			files: [],
			showSuccessMessage: false,
		})
	}

	handleImageChange(files) {
		this.setState({
			files: files,
		})
	}

	getModalStyle = () => {
		return {
			top: '35%',
			left: '40%',
			background: 'white',
		}
	}

	setSaveImage = () => {
		let imagem = null
		let companyId = null
		let pluginKey = null
		if (this.state.files.length > 0) imagem = this.state.files[0].base64
		if (this.state.companyId !== null) companyId = this.state.companyId
		if (this.state.pluginKey !== null) pluginKey = this.state.pluginKey

		return this.saveImage(imagem, companyId, pluginKey)
	}

	render() {
		const { classes, item, viewAction, name, type, lastUpdate, pluginKey } =
			this.props

		let lastUpdateFormated = null

		if (!!lastUpdate) lastUpdateFormated = lastUpdate

		return (
			pluginKey && (
				<Card key={pluginKey} className={classes.card}>
					<CardContent className={classes.pluginContent}>
						<div className={classes.pluginContentLeft}>
							<Typography variant="subtitle1" component="h4">
								{name}
							</Typography>
							<Typography
								variant="body1"
								component="p"
								className={classes.cardItemType}>
								{type}
							</Typography>
							{!!lastUpdateFormated ? (
								<div className={classes.grid}>
									<Typography
										variant="body2"
										component="p"
										className={classes.cardItemType}>
										<Moment
											add={{ hours: 3 }}
											format="DD/MM/YYYY HH:mm">
											{lastUpdateFormated}
										</Moment>
									</Typography>
								</div>
							) : (
								<div></div>
							)}
						</div>
						<div className={classes.pluginContentRight}>
							<img
								alt=''
								src={this.state.imgUrl}
								className={classes.pluginContentImg}
							/>
						</div>
						<Modal
							open={this.state.openModal}
							onClose={this.handleClose}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description">
							<Card className={classes.paper}>
								<div className={classes.titleModalRound}>
									<div className={classes.titleModal}>
										<Typography
											className={classes.modalTitle}
											component="div"
											variant="h6">
											Selecione uma logo
										</Typography>
										<IconButton
											color="primary"
											onClick={this.handleClose}>
											<Icon>clear</Icon>
										</IconButton>
									</div>
									<Divider />
								</div>
								{this.state.showSuccessMessage !== true ? (
									<form>
										<FileBase64
											multiple={true}
											onDone={this.handleImageChange.bind(
												this,
											)}
										/>
									</form>
								) : (
									<div></div>
								)}
								{!!this.state.showSuccessMessage ? (
									<Typography
										className={classes.modalSuccessTitle}
										component="div"
										variant="h7">
										Imagem carregada com sucesso!
									</Typography>
								) : (
									<div></div>
								)}
								{!!this.state.files.length > 0 ? (
									<div className={classes.imageLogo}>
										<img
											alt=''
											src={this.state.files[0].base64}
											className={classes.imageLogoPreview}
										/>
									</div>
								) : (
									<div></div>
								)}
								{!!this.state.files.length > 0 && (
									<div>
										<IconButton
											color="primary"
											onClick={this.setSaveImage}>
											<Icon>upload</Icon>
										</IconButton>
									</div>
								)}
							</Card>
						</Modal>
					</CardContent>
					<CardActions>
						<IconButton
							color="primary"
							aria-label={translate('label/open')}
							onClick={event => viewAction(event, item)}>
							<Icon>timeline</Icon>
						</IconButton>
						<IconButton
							color="primary"
							aria-label={translate('label/open')}
							onClick={this.handleOpen}>
							<Icon>upload</Icon>
						</IconButton>
					</CardActions>
				</Card>
			)
		)
	}
}

const styles = theme => ({
	large: {
		width: '70px',
		height: '70px',
		objectFit: 'contain',
	},
	titleModalRound: {
		marginBottom: '15px',
	},
	titleModal: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	pluginContent: {
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: 0,
	},
	pluginContentLeft: {
		display: 'block',
		alignItems: 'center',
	},
	pluginContentRight: {
		display: 'flex',
		width: '100px',
		height: '35px',
	},
	pluginContentImg: {
		borderRadius: '10%',
	},
	modalSuccessTitle: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '0.5em',
	},
	modalTitle: {
		display: 'flex',
		justifyContent: 'center',
		padding: '0.2em',
	},
	inputFile: {
		display: 'flex',
		padding: '0.5em',
	},
	imageLogo: {
		display: 'flex',
		justifyContent: 'center',
		padding: '0.7em',
	},
	imageLogoPreview: {
		width: '100px',
		height: '100px',
		borderRadius: '50%',
	},
	paper: {
		position: 'absolute',
		width: '400',
		left: '40%',
		top: '40%',
		padding: '1em',
	},
	card: {
		flex: '0 0 auto',
		marginLeft: '5px',
		width: '49%',
		marginBottom: theme.spacing.unit,
		cursor: 'pointer',
		'&:hover': {
			transform: 'scale(0.955)',
		},
		transition: 'transform 1s',
		//maxWidth: ''
		//borderRadius: theme.shape.borderRadius,
		borderLeft: 'solid 5px ' + theme.palette.secondary.dark,
		//margin: theme.spacing.unit,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	cardItemType: {
		color: 'rgba(0, 0, 0, 0.7)',
	},
})

PluginListItemExpanded.propTypes = {
	item: PropTypes.object,
	handleConfig: PropTypes.func.isRequired,
	viewAction: PropTypes.func.isRequired,
}

export default withStyles(styles)(PluginListItemExpanded)
