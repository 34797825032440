import {
	SET_LOADED_OUTLAY_DATA,
	SET_OUTLAY_IS_LOADING_STATE,
	SET_OUTLAY_DATE_CONFIG
} from '../../actions/outlayAvoided'

const INITIAL_STATE = {
	outlayData: null,
	isLoading: false,
	dateConfig: {}
}

/**
 * outlay card reducer
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 */
const outlayReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_OUTLAY_DATE_CONFIG: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				}
			})
		}
		case SET_OUTLAY_IS_LOADING_STATE: {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		case SET_LOADED_OUTLAY_DATA: {
			return Object.assign({}, state, { outlayData: action.data })
		}
		default:
			return state
	}
}

export default outlayReducer
