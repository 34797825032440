import {
    MANAGE_PERFORMANCE_EVOLUTER_LOADING_STATE,
    LOAD_PERFORMANCE_EVOLUTER_DATA,
    SET_PERFORMANCE_EVOLUTER_PERIOD
} from '../../actions/performanceEvoluter'

const INITIAL_STATE = {
    isLoading: null,
    localFilters: null,
    data: null,
    dateConfig: {},
    update: false,
    ownFilters: {},
    searchText: {},
    idLocalFilter: {
        objects: null,
        collaborators: null,
        localBases: null,
        localTypeObjects: null,
        localLocalities: null,
        localClients: null,
        questions: null,
        forms: null,
        bondNc: null,
        autochecksNc: null
    }
}

const clearAllLocalFilters = (state) => {
    let keys = Object.keys(state.idLocalFilter),
        clearFilters = {}

    keys.forEach((key) => {
        clearFilters[key] = null
    })

    return clearFilters
}

/**
 * state reducer manage
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */

const performanceEvoluterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_PERFORMANCE_EVOLUTER_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_PERFORMANCE_EVOLUTER_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case 'SET_PERFORMANCE_EVOLUTER_FILTER_VALUE': {
            return Object.assign({}, state, {
                ownFilters: Object.assign({}, state.ownFilters, action.value),
                searchText: Object.assign({}, state.searchText, {
                    [action.key]: ''
                })
            })
        }
        case 'SET_PERFORMANCE_EVOLUTER_SEARCH_FILTER_VALUE': {
            return Object.assign({}, state, {
                searchText: Object.assign({}, state.searchText, action.value)
            })
        }
        case 'CLEAN_PERFORMANCE_EVOLUTER_FILTER_VALUE': {

            return Object.assign({}, state, {
                ownFilters: {},
                idLocalFilter: clearAllLocalFilters(state)
            })
        }
        case 'SET_LOCAL_FILTER_PERFORMANCE_EVOLUTER': {
            return Object.assign({}, state, {
                localFilters: action.localFilters
            })
        }
        case 'SET_SEARCH_TEXT_PERFORMANCE_EVOLUTER': {
            let searchText = {}
            action.objectKeys.forEach((item) => {
                searchText[item] = ''
            })
            return Object.assign({}, state, {
                searchText: searchText
            })
        }
        case 'SET_ID_LOCAL_FILTERS_PERFORMANCE_EVOLUTER': {
            return Object.assign({}, state, {
                idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter)
            })
        }
        case 'SET_ALL_IDS': {
            let idLocalFilter = {}
            action.allIds.forEach((item) => {
                idLocalFilter[item] = null
            })
            return Object.assign({}, state, {
                idLocalFilter: idLocalFilter
            })
        }
        case SET_PERFORMANCE_EVOLUTER_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        default:
            return state
    }
}

export default performanceEvoluterReducer
