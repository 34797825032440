import { createSelector } from 'reselect'
import { getActionById } from '../actions'
import { wasEvaluated } from '../wasEvaluated'
import hadReasons from '../hadReasons'

const makeGetActionCompliments = () => {
	return createSelector(
		[getActionById],
		(action) => {
			if (wasEvaluated(action, 'evaluate')) {
				return hadReasons(action.evaluate.compliments)
			}
			return null
		}
	)
}

export default makeGetActionCompliments
