import React from 'react'
import { compose } from 'recompose'
import * as User from '../../../firebase/user'
import { connect } from 'react-redux'
import withAuthorization from '../withAuthorization'
import { TextField, withStyles } from '@material-ui/core'
import { SaveButton } from '../button/save'
import { showSnackBar } from '../../../actions/snackbar'

/**
 * Will manage to load and display logged user information
 */
class AccountForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			dirty: false,
			error: null
		}
	}

	componentDidMount() {
		const { authUser, editUser, setEditUser } = this.props
		if (!editUser && !!authUser && authUser.uid !== null) {
			User.get(authUser.uid).then((dataSnapshot) => {
				return setEditUser({
					editUser: { [authUser.uid]: dataSnapshot.data() },
					userKey: authUser.uid
				})
			})
		}
	}
	componentWillUnmount() {
		this.props.clearSelectedUser()
	}
	handleChange = (name) => (event) => {
		//Event should be cancelled
		this.props.updateEditUser({ [name]: event.target.value })
		if (!!event && 'function' === typeof event.preventDefault)
			event.preventDefault()

		this.setState({ dirty: true })
	}

	handleSave = (event) => {
		const { editUser, userKey, emitMessage } = this.props
		//Event should be cancelled
		if (!!event && 'function' === typeof event.preventDefault)
			event.preventDefault()

		//Nothing changed?
		if (!this.state.dirty) return

		// TODO: Define whether using this standard might easier or not...
		let updatedUser = {
			name: editUser[userKey].name,
			email: editUser[userKey].email
		}
		//Releases update to firebase
		User.doUpdateUser(userKey, updatedUser).then(() => {
			this.setState({ dirty: false })
			emitMessage('Usuario atualizado com sucesso')
		}) // TODO: Emits success message
	}

	render() {
		const { classes, editUser, userKey } = this.props
		return (
			<form className={classes.root}>
				<TextField
					id="email"
					label="E-mail"
					value={editUser[userKey].email}
					type="email"
					fullWidth
					disabled
					className={classes.textField}
					margin="normal"
				/>
				<TextField
					id="name"
					label="Nome"
					value={editUser[userKey].name}
					type="text"
					onChange={this.handleChange('name')}
					fullWidth
					className={classes.textField}
					margin="normal"
				/>
				<SaveButton
					disabled={!this.state.dirty}
					action={this.handleSave}
				/>
			</form>
		)
	}
}

const authCondition = (authUser) => !!authUser
const mapDispatchToProps = (dispatch) => ({
	emitMessage: (notification) => dispatch(showSnackBar(notification)),
	clearSelectedUser: () => dispatch({ type: 'CLEAR_SELECTED_EDIT_USER' }),
	setEditUser: (user) => dispatch({ type: 'SET_SELECTED_EDIT_USER', user }),
	updateEditUser: (info) =>
		dispatch({ type: 'UPDATE_SELECTED_EDIT_USER', info })
})
const mapStateToProps = (state) => ({
	email: state.userState.user.email,
	editUser: state.userEditFormState.editUser,
	userKey: state.userEditFormState.userKey
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withAuthorization(authCondition),
	withStyles({
		root: {
			height: '100%'
		},
		textField: {
			width: '100%'
		}
	})
)(AccountForm)
