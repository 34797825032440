export const MANAGE_SOLAR_POWER_LOADING_STATE = 'MANAGE_SOLAR_POWER_LOADING_STATE',
    LOAD_SOLAR_POWER_DATA = 'LOAD_SOLAR_POWER_DATA',
    SET_SOLAR_POWER_PERIOD = 'SET_SOLAR_POWER_PERIOD',
    SET_SOLAR_POWER_VALUE = 'SET_SOLAR_POWER_VALUE'

export const setSolarPowerPeriod = (dateConfig, value, formType) => ({
    type: SET_SOLAR_POWER_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadSolarPowerData = (solarPower) => ({
    type: LOAD_SOLAR_POWER_DATA,
    solarPower
})

export const setLoading = (isLoading) => ({
    type: MANAGE_SOLAR_POWER_LOADING_STATE,
    isLoading
})

export const solarPowerValue = (text) => ({
    type: SET_SOLAR_POWER_VALUE,
    text
})