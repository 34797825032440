import { fs } from '../../../firebase'

/**
 * Builds a company-plugin-filters ref by a given company and plugin combination
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active company ID
 * @param {string} pluginId The active plugin ID
 */
const getRef = (companyId, pluginId) => {
	const ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('plugins')
		.doc(pluginId)
		.collection('filters')
		.where('active', '==', true)
		.orderBy('order', 'asc')

	return ref
}

/**
 * Creates a listener which:
 * Looks for filters inside a plugin configuration at the passed company key
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active company key
 * @param {string} pluginId The active company plugin key
 *
 * @returns the unsubscriber of this listener
 */
export const get = (companyId, pluginId) => getRef(companyId, pluginId).get()

/**
 * Creates a listener which:
 * Looks for filters inside a plugin configuration at the passed company key
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The active company key
 * @param {string} pluginId The active company plugin key
 * @param {Function} thenFunc Function to be executed after a refresh
 * @param {Function} errorFunc Function to be executed when an error occurs
 *
 * @returns the unsubscriber of this listener
 */
export const onGet = (companyId, pluginId, thenFunc, errorFunc) =>
	getRef(companyId, pluginId).onSnapshot(
		(snapshot) => thenFunc(!!snapshot.empty ? [] : snapshot.docs),
		errorFunc
	)
