export const MANAGE_PERFORMANCE_EVOLUTER_LOADING_STATE = 'MANAGE_PERFORMANCE_EVOLUTER_LOADING_STATE',
    LOAD_PERFORMANCE_EVOLUTER_DATA = 'LOAD_PERFORMANCE_EVOLUTER_DATA',
    SET_PERFORMANCE_EVOLUTER_PERIOD = 'SET_PERFORMANCE_EVOLUTER_PERIOD'

export const setPerformanceEvoluterPeriod = (dateConfig, value, formType) => ({
    type: SET_PERFORMANCE_EVOLUTER_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadPerformanceEvoluterData = (data) => ({
    type: LOAD_PERFORMANCE_EVOLUTER_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_PERFORMANCE_EVOLUTER_LOADING_STATE,
    isLoading
})
