import React from 'react'
import SwiperViews from 'react-swipeable-views'
import { withStyles, MobileStepper, Button, Icon } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'

/**
 *
 * Created a more fluid way of showing the cards
 *
 * @description Created the component
 * @author lopes
 * @version 1.0
 */
class Swiper extends React.PureComponent {
	state = {
		indexState: 0,
	}
	componentDidMount() {
		const { exportHandler } = this.props

		if (exportHandler instanceof Function) exportHandler(this.onChangeIndex)
	}

	onChangeIndex = index => {
		const { unControlled, onChangeIndex } = this.props
		if (!unControlled) {
			onChangeIndex(index)
		} else {
			this.setState({ indexState: index })
		}
	}
	render() {
		const {
			classes,
			children,
			indexSwipe,
			disabled,
			axis,
			withDots,
			withControls,
			endAdornment,
			unControlled,
		} = this.props
		const { indexState } = this.state
		return (
			<React.Fragment>
				<SwiperViews
					axis={axis}
					index={unControlled ? indexState : indexSwipe}
					hysteresis={0.4}
					className={classes.slideContainer}
					slideClassName={classes.slideClassName}
					containerStyle={{ height: '100%' }}
					disabled={disabled}
					onChangeIndex={this.onChangeIndex}>
					{children}
				</SwiperViews>
				{withDots && (
					<MobileStepper
						className={classes.stepper}
						position="static"
						variant="dots"
						steps={React.Children.count(children)}
						activeStep={unControlled ? indexState : indexSwipe}
						{...(withControls
							? {
									nextButton: (
										<Button
											size="small"
											disabled={
												unControlled
													? indexState ===
													  React.Children.count(
															children,
													  ) -
															1
													: indexSwipe ===
													  React.Children.count(
															children,
													  ) -
															1
											}
											onClick={() =>
												this.onChangeIndex(
													(unControlled
														? indexState
														: indexSwipe) + 1,
												)
											}>
											<Icon>keyboard_arrow_right</Icon>
										</Button>
									),
									backButton: (
										<Button
											size="small"
											disabled={
												unControlled
													? indexState === 0
													: indexSwipe === 0
											}
											onClick={() =>
												this.onChangeIndex(
													(unControlled
														? indexState
														: indexSwipe) - 1,
												)
											}>
											<Icon>keyboard_arrow_left</Icon>
										</Button>
									),
							  }
							: {})}
					/>
				)}
				{endAdornment && endAdornment}
			</React.Fragment>
		)
	}
}

Swiper.propTypes = {
	classes: PropTypes.object.isRequired,
}

Swiper.defaultProps = {
	axis: 'x',
}
export default withStyles(styles)(Swiper)
