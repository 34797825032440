const INITIAL_STATE = {
	isLoading: false,
	selectedFile: null,
	uploadState: null,
	registrosState: null,
	file: null,
	dashItems: null,
	selectedDashItem: null
}

export const SET_PORTAL_DEVELOP_LOADING_STATE = 'SET_PORTAL_DEVELOP_LOADING_STATE',
	SET_PORTAL_DEVELOP_DASHITEMS_LOADED = 'SET_PORTAL_DEVELOP_DASHITEMS_LOADED',
	SET_PORTAL_DEVELOP_SELECTED_FILE = 'SET_PORTAL_DEVELOP_SELECTED_FILE',
	SET_PORTAL_DEVELOP_REGISTROS_STATE = 'SET_PORTAL_DEVELOP_REGISTROS_STATE',
	SET_PORTAL_DEVELOP_UPLOAD_STATE = 'SET_PORTAL_DEVELOP_UPLOAD_STATE',
	SET_PORTAL_DEVELOP_SELECTED_DASH_ITEM = 'SET_PORTAL_DEVELOP_SELECTED_DASH_ITEM',
	CLEAN_PORTAL_DEVELOP_DATA = 'CLEAN_PORTAL_DEVELOP_DATA'

const portalDevelopReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PORTAL_DEVELOP_LOADING_STATE:
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		case SET_PORTAL_DEVELOP_DASHITEMS_LOADED:
			return Object.assign({}, state, {
				dashItems: action.dashItems
			})
		case SET_PORTAL_DEVELOP_SELECTED_FILE:	
			return Object.assign({}, state, {				
				selectedFile: action.selectedFile
			})

		case SET_PORTAL_DEVELOP_REGISTROS_STATE:	
			return Object.assign({}, state, {				
				registrosState: action.registrosState
			})
		case SET_PORTAL_DEVELOP_UPLOAD_STATE:
			return Object.assign({}, state, {
				uploadState: action.uploadState
			})
		case SET_PORTAL_DEVELOP_SELECTED_DASH_ITEM:
			return Object.assign({}, state, {
				selectedDashItem: action.selectedDashItem
			})
		case CLEAN_PORTAL_DEVELOP_DATA: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default portalDevelopReducer