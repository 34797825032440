import { createSelector } from 'reselect'

/**
 * Picks a **companies-list** from the main listing of the state tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree object
 */
const getCompaniesList = (state) => state.companyListState.companies

/**
 * [memoised] Factory/Selector a **companies-list** from the main listing of the state tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompaniesList = () =>
	createSelector(
		getCompaniesList,
		(companies) => companies
	)

/**
 * [memoised] Factory/Selector a **company-list-keys** from the main listing of the state tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompaniesListKeys = () =>
	createSelector(
		makeGetCompaniesList(),
		(companies) => (!!companies ? Object.keys(companies) : [])
	)

/**
 * Picks a _companies-list_ **loading-state** from the main listing of the state tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree object
 */
const getCompaniesListLoading = (state) =>
	state.companyListState.companiesLoading

/**
 * [memoised] Factory/Selector a _companies-list_ **loading-state** from the main listing of the state tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompaniesListLoading = () =>
	createSelector(
		getCompaniesListLoading,
		(isLoading) => isLoading
	)
