export const MANAGE_TIME_DOWN_NC_LOADING_STATE =
		'MANAGE_TIME_DOWN_NC_LOADING_STATE',
	LOAD_TIME_DOWN_NC_DATA = 'LOAD_TIME_DOWN_NC_DATA',
	SET_TIME_DOWN_NC_PERIOD = 'SET_TIME_DOWN_NC_PERIOD',
	LOAD_TIME_DOWN_NC_DATA_FILTERS = 'LOAD_TIME_DOWN_NC_DATA_FILTERS'

export const setTimeDownNcPeriod = (dateConfig, value, formType) => ({
	type: SET_TIME_DOWN_NC_PERIOD,
	dateConfig,
	value,
	formType,
})

export const loadTimeDownNcData = timeDownNc => ({
	type: LOAD_TIME_DOWN_NC_DATA,
	timeDownNc,
})

export const setLoading = isLoading => ({
	type: MANAGE_TIME_DOWN_NC_LOADING_STATE,
	isLoading,
})

export const loadTimeDownNcDataFilters = timeDownNcFilters => ({
	type: LOAD_TIME_DOWN_NC_DATA_FILTERS,
	timeDownNcFilters,
})
