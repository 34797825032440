import {Button, Card, Checkbox, FormControl, IconButton, Input,InputLabel, List, ListItem, ListItemIcon,
	 ListItemText, Switch, Typography} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {ArrowDropDown,Close, ArrowDropUp } from '@material-ui/icons'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import * as routes from '../../../constants/routes'
import { firebase } from '../../../firebase'
import Loader from '../../reusable/loader'
import {
	createUserFirebase,
	identifyComponentsInCompany,
	takePlugins,
	AddNewCardInAllCompanies
} from './ControllerCreateUser'

const styles = theme => ({
	root: {
		width: 'auto',
		overFlow: 'auto',
	},
	component: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		width: '100%',
		height: '100%',
		borderColor: '#5530ab9d',
		overFlow: 'auto',
	},
	form: {
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		width: '590px',
		maxHeight: '700px',
		border: '1px solid #c6c6d8',
		overflow: 'auto',
	},
	formPlugins: {
		alignItems: 'center',
		justifyContent: 'center',
		overFlow: 'auto',
	},
	selectPlugins: {
		alignItems: 'center',
		justifyContent: 'center',
		overFlow: 'auto',
	},
	container: {
		padding: '50px',
		width: '550px',
		borderStyle: 'outset',
		overFlow: 'auto',
		borderColor: '#5530ab9d',
	},
	dashSets: {
		borderStyle: 'outset',
		margin: '5px',
	},
	pluginsCard: {
		width: 'auto',
		padding: '5px',
		margin: '5px',
		borderStyle: 'outset',
	},
	formControl: {
		margin: '5px',
	},
	button: {
		marginTop: '20px',
		background: '#4b289ec0',
		padding: '10px',
		width: '300px',
		color: 'white',
		'&:hover': {
			color: 'black',
			background: '#8d72ceC0',
		},
	},
	alert: {
		color: 'red',
	},
	alertCreatedUser: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'grid',
		background: '#4CAF50',
		margin: '5px',
		color: 'white',
	},
	switch: {
		display: '-webkit-inline-box',
	},
	itemsSwitch: {
		margin: '20px',
		alignItems: 'center',
	},
	plugins: {
		display: 'flex',
	},
	plugin: {
		display: 'inline-block',
		margin: '10px',
	},
	iconButton: {
		padding: 10,
	},
	list: {
		width: '250px',
		zindex: 9999,
	},
	fullList: {
		width: 'auto',
	},
	rootAlert: {
		width: '100%',
	},
})

class FormCreateUser extends React.Component {
	state = {
		email: '',
		name: '',
		errorEmail: false,
		errorName: false,
		status: true,
		actions: true,
		notification: true,
		log: true,
		download: true,
		upload: true,
		plugins: null,
		alert: true,
		userCurrent: null,
		components: null,
	}

	componentDidMount() {
		const {
			activeCompanyId,
			setComponentsPermissions,
			setLoader,
		} = this.props

		firebase.auth.onAuthStateChanged(authUser => {
			if (!!authUser)
				this.setState({
					userCurrent: authUser.uid,
				})
		})

		// AddNewCardInAllCompanies()

		setLoader(true)

		identifyComponentsInCompany(activeCompanyId, setComponentsPermissions)
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			activeCompanyId,
			alertCreatedUser,
			setEmailValidation,
			setPluginsInReducer,
			componentsPermission,
			setLoader,
		} = this.props
		const { plugins, alert } = this.state

		if (!!activeCompanyId && !plugins) {
			takePlugins(activeCompanyId, setPluginsInReducer).then(resp => {
				this.setState({
					plugins: resp,
				})
			})
		}

		if (!!prevState.plugins && plugins) {
			setLoader(false)
		}

		if (!!alertCreatedUser && alertCreatedUser !== alert) {
			setEmailValidation(false)
			this.setState({
				alert: true,
				userCreated: true,
				name: ' ',
				email: ' ',
				actions: true,
				notification: true,
				log: true,
				download: true,
				upload: true,
			})
			setTimeout(this.alterAlertCreateUser, 3000)
			takePlugins(activeCompanyId, setPluginsInReducer).then(resp => {
				this.setState({
					plugins: resp,
				})
			})
		} else if (!alertCreatedUser && alertCreatedUser !== alert) {
			setEmailValidation(false)
			setTimeout(this.alterAlertCreateUser, 3000)
			this.setState({
				alert: false,
				userCreated: true,
				name: '',
				email: '',
				actions: true,
				notification: true,
				log: true,
				download: true,
				upload: true,
			})
			takePlugins(activeCompanyId, setPluginsInReducer).then(resp => {
				this.setState({
					plugins: resp,
				})
			})
		}

		if (
			!!componentsPermission &&
			prevProps.componentsPermission !== componentsPermission
		) {
			this.setState({
				components: componentsPermission,
			})
		}
	}

	alterAlertCreateUser = () => {
		const { setAlertCreateUser } = this.props

		setAlertCreateUser(false)
	}

	handleChangeName = e => {
		const { setEmailValidation, setAlertCreateUser } = this.props

		this.setState({
			name: e.target.value,
			errorName: false,
		})

		setEmailValidation(false)
		setAlertCreateUser(false)
	}

	handleChangeEmail = e => {
		const { setEmailValidation, setAlertCreateUser } = this.props

		this.setState({
			email: e.target.value,
			errorEmail: false,
		})

		setEmailValidation(false)
		setAlertCreateUser(false)
	}

	validateName = () => {
		const { name } = this.state

		if (name.length < 2 || name.length > 25) {
			this.setState({
				errorName: true,
			})
		}
	}

	validateEmail = () => {
		const { email } = this.state

		if (!email.split('@')[1]) {
			this.setState({
				errorEmail: true,
			})
		}
	}

	onClickActions = () => {
		const { actions } = this.state

		if (!!actions) {
			this.setState({
				actions: false,
			})
		} else {
			this.setState({
				actions: true,
			})
		}
	}

	onClickNotification = () => {
		const { notification } = this.state

		if (!!notification) {
			this.setState({
				notification: false,
			})
		} else {
			this.setState({
				notification: true,
			})
		}
	}

	onClickLog = () => {
		const { log } = this.state

		if (!!log) {
			this.setState({
				log: false,
			})
		} else {
			this.setState({
				log: true,
			})
		}
	}

	onClickDownload = () => {
		const { download } = this.state

		if (!!download) {
			this.setState({
				download: false,
			})
		} else {
			this.setState({
				download: true,
			})
		}
	}

	onClickUpload = () => {
		const { upload } = this.state

		if (!!upload) {
			this.setState({
				upload: false,
			})
		} else {
			this.setState({
				upload: true,
			})
		}
	}

	handleChangePlugin = plugin => {
		const { setAlertCreateUser } = this.props
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			let allDashSets = []

			item.dashSets.forEach(dashSet => {
				let allDashItems = []

				dashSet.dashItems.forEach(dashItem => {
					if (plugin.id === item.id) {
						if (!!plugin.status) {
							allDashItems.push(
								Object.assign(dashItem, {
									active: false,
								}),
							)
						} else {
							allDashItems.push(
								Object.assign(dashItem, {
									active: true,
								}),
							)
						}
					}
				})

				if (plugin.id === item.id) {
					if (!!plugin.status) {
						allDashSets.push(
							Object.assign(dashSet, {
								active: false,
								dashItems: allDashItems,
							}),
						)
					} else {
						allDashSets.push(
							Object.assign(dashSet, {
								active: true,
								dashItems: allDashItems,
							}),
						)
					}
				}
			})
			if (plugin.id === item.id) {
				if (!!plugin.status) {
					newPlugins.push(
						Object.assign(plugin, {
							status: false,
							dashSets: allDashSets,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(plugin, {
							status: true,
							dashSets: allDashSets,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		setAlertCreateUser(false)
		this.setState({
			plugins: newPlugins,
		})
	}

	handleChangePluginExpand = plugin => {
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			if (plugin.id === item.id) {
				if (!!plugin.statusPlugin) {
					newPlugins.push(
						Object.assign(item, {
							statusPlugin: false,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(item, {
							statusPlugin: true,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		this.setState({
			plugins: newPlugins,
		})
	}

	handleChangeDashSet = (plugin, dashSet) => {
		const { setAlertCreateUser } = this.props
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			let allDashSets = [],
				status = false

			if (plugin.id === item.id) {
				item.dashSets.forEach(it => {
					let allDashItems = []

					if (it.idDashSet === dashSet.idDashSet) {
						it.dashItems.forEach(card => {
							if (!!dashSet.active) {
								allDashItems.push(
									Object.assign(card, {
										active: false,
									}),
								)
							} else {
								allDashItems.push(
									Object.assign(card, {
										active: true,
									}),
								)
							}
						})

						if (!!dashSet.active) {
							allDashSets.push(
								Object.assign(it, {
									dashItems: allDashItems,
									active: false,
								}),
							)
						} else {
							status = true
							allDashSets.push(
								Object.assign(it, {
									dashItems: allDashItems,
									active: true,
								}),
							)
						}
					} else {
						allDashSets.push(it)
					}
				})

				if (!!status) {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
							status: status,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		setAlertCreateUser(false)
		this.setState({
			plugins: newPlugins,
		})
	}

	handleChangeDashSetExpand = (plugin, dashSet) => {
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			let allDashSets = []

			if (plugin.id === item.id) {
				item.dashSets.forEach(it => {
					if (it.idDashSet === dashSet.idDashSet) {
						if (!!dashSet.statusDashSet) {
							allDashSets.push(
								Object.assign(dashSet, {
									statusDashSet: false,
								}),
							)
						} else {
							allDashSets.push(
								Object.assign(dashSet, {
									statusDashSet: true,
								}),
							)
						}
					} else {
						allDashSets.push(it)
					}
				})

				newPlugins.push(Object.assign(item, { dashSets: allDashSets }))
			} else {
				newPlugins.push(item)
			}
		})

		this.setState({
			plugin: newPlugins,
		})
	}

	handleChangeDashItem = (plugin, dashSet, dashItem) => {
		const { setAlertCreateUser } = this.props
		const { plugins } = this.state
		let newPlugins = []

		plugins.forEach(item => {
			let allDashSets = [],
				hasAlteration = false,
				status = false

			if (plugin.id === item.id) {
				item.dashSets.forEach(it => {
					let allDashItems = []

					it.dashItems.forEach(dashIt => {
						if (
							it.idDashSet === dashSet.idDashSet &&
							dashIt.idDashItem === dashItem.idDashItem
						) {
							hasAlteration = true
							if (!!dashIt.active) {
								allDashItems.push(
									Object.assign(dashIt, {
										active: false,
									}),
								)
							} else {
								status = true
								allDashItems.push(
									Object.assign(dashIt, {
										active: true,
									}),
								)
							}
						} else {
							allDashItems.push(dashIt)
						}
					})

					if (
						!!hasAlteration &&
						!!status &&
						it.idDashSet === dashSet.idDashSet
					) {
						allDashSets.push(
							Object.assign(it, {
								dashItems: allDashItems,
								active: status,
							}),
						)
					} else if (!!hasAlteration && !status) {
						allDashSets.push(
							Object.assign(it, {
								dashItems: allDashItems,
							}),
						)
					} else {
						allDashSets.push(it)
					}
				})

				if (!!status) {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
							status: status,
						}),
					)
				} else {
					newPlugins.push(
						Object.assign(item, {
							dashSets: allDashSets,
						}),
					)
				}
			} else {
				newPlugins.push(item)
			}
		})

		setAlertCreateUser(false)
		this.setState({
			plugins: newPlugins,
		})
	}

	addUser = async () => {
		const {
			errorName,
			errorEmail,
			name,
			email,
			actions,
			notification,
			log,
			download,
			upload,
			plugins,
			userCurrent,
			status,
		} = this.state
		const { activeCompanyId, setEmailValidation, setAlertCreateUser } =
			this.props

		this.validateName()
		this.validateEmail()

		if (!errorEmail && !errorName) {
			createUserFirebase(
				{
					name: name,
					email: email,
					status: status,
					actions: actions,
					notification: notification,
					log: log,
					download: download,
					upload: upload,
					plugins: plugins,
					signInType: 'EmailAndPassword',
					author: userCurrent,
					activeCompany: activeCompanyId,
					date: new Date(),
				},
				activeCompanyId,
				setEmailValidation,
				setAlertCreateUser,
			)
		}
	}

	render() {
		const {
			errorEmail,
			errorName,
			actions,
			notification,
			log,
			download,
			upload,
			plugins,
			alert,
			components,
			name,
			email,
		} = this.state
		const { classes, emailValidation, isLoading, history } = this.props

		return (
			<Card className={classes.component}>
				{!!isLoading ? (
					<Loader internal />
				) : (
					<Card className={classes.form}>
						<div
							style={{
								justifyContent: 'flex-end',
								display: 'flex',
							}}>
							<IconButton
								onClick={() => {
									!!alert
										? history.push(routes.PLUGIN)
										: history.push(
												routes.CONTROL_PANEL_USER,
										  )
								}}>
								<Close />
							</IconButton>
						</div>
						{/* <Divider /> */}
						{!!alert && (
							<Card className={classes.alertCreatedUser}>
								<ListItem>
									<ListItemText
										color="white"
										primary="Usuário criado com sucesso!"
									/>
								</ListItem>
							</Card>
						)}
						<div style={{ display: 'grid' }}>
							<FormControl className={classes.formControl}>
								<InputLabel>Nome:*</InputLabel>
								<Input
									onChange={this.handleChangeName}
									id={'input-name'}
									value={name}
								/>
								{!!errorName ? (
									<Typography
										className={classes.alert}
										variant="caption"
										display="block"
										gutterBottom>
										O campo "nome" deve ter ente 2 e 25
										caracteres
									</Typography>
								) : (
									<div />
								)}
							</FormControl>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="input-email">
									Email:*
								</InputLabel>
								<Input
									onChange={this.handleChangeEmail}
									id="input-email"
									value={email}
								/>
								{!!emailValidation ? (
									<Typography
										className={classes.alert}
										variant="caption"
										display="block"
										gutterBottom>
										O endereço de e-mail já está sendo usado
										por outra conta.
									</Typography>
								) : (
									<div />
								)}
								{!!errorEmail ? (
									<Typography
										className={classes.alert}
										variant="caption"
										display="block"
										gutterBottom>
										Check o email digitado
									</Typography>
								) : (
									<div />
								)}
							</FormControl>
							<FormControl className={classes.switch}>
								<FormControl className={classes.itemsSwitch}>
									<Typography
										variant="caption"
										display="block"
										gutterBottom>
										Ações
									</Typography>
									<Switch
										checked={actions}
										onClick={this.onClickActions}
										color="primary"
										name="viewAction"
										inputProps={{
											'aria-label': 'primary checkbox',
										}}
									/>
								</FormControl>
								<FormControl className={classes.itemsSwitch}>
									<Typography
										variant="caption"
										display="block"
										gutterBottom>
										Notificações
									</Typography>
									<Switch
										checked={notification}
										onClick={this.onClickNotification}
										color="primary"
										name="viewNotification"
										inputProps={{
											'aria-label': 'primary checkbox',
										}}
									/>
								</FormControl>
								{!!components &&
									components.map(component => {
										return (
											<FormControl>
												{component.id === 'download' ? (
													<FormControl
														className={
															classes.itemsSwitch
														}>
														<Typography
															variant="caption"
															display="block"
															gutterBottom>
															Download
														</Typography>
														<Switch
															checked={download}
															onClick={
																this
																	.onClickDownload
															}
															color="primary"
															name="viewDownload"
															inputProps={{
																'aria-label':
																	'primary checkbox',
															}}
														/>
													</FormControl>
												) : component.id === 'upload' ? (
													<FormControl
														className={
															classes.itemsSwitch
														}>
														<Typography
															variant="caption"
															display="block"
															gutterBottom>
															Upload
														</Typography>
														<Switch
															checked={upload}
															onClick={
																this
																	.onClickUpload
															}
															color="primary"
															name="viewUpload"
															inputProps={{
																'aria-label':
																	'primary checkbox',
															}}
														/>
													</FormControl>
												) : component.id === 'log' ? (
													<FormControl
														className={
															classes.itemsSwitch
														}>
														<Typography
															variant="caption"
															display="block"
															gutterBottom>
															Log
														</Typography>
														<Switch
															checked={log}
															onClick={
																this.onClickLog
															}
															color="primary"
															name="viewLog"
															inputProps={{
																'aria-label':
																	'primary checkbox',
															}}
														/>
													</FormControl>
												) : (
													<div />
												)}
											</FormControl>
										)
									})}
							</FormControl>
						</div>
						<div className={classes.selectPlugins}>
							{!!plugins && (
								<List className={classes.root}>
									{!!plugins &&
										plugins.map(value => {
											const labelId = `checkbox-list-label-${value.name}`
											return (
												<Card
													className={
														classes.pluginsCard
													}>
													<ListItem
														className={classes.root}
														key={value.name}
														role={undefined}
														dense>
														<ListItemIcon>
															<Checkbox
																edge="start"
																checked={
																	value.status
																}
																tabIndex={-1}
																disableRipple
																inputProps={{
																	'aria-labelledby':
																		labelId,
																}}
																onChange={() =>
																	this.handleChangePlugin(
																		value,
																	)
																}
															/>
														</ListItemIcon>
														<ListItemText
															id={labelId}
															primary={`${value.name}`}
														/>
														<IconButton
															onClick={() =>
																this.handleChangePluginExpand(
																	value,
																)
															}
															className={
																classes.iconButton
															}
															aria-label="downIcon">
															{!!value.statusPlugin ? (
																<ArrowDropUp
																	button
																/>
															) : (
																<ArrowDropDown
																	button
																/>
															)}
														</IconButton>
													</ListItem>
													{!!value.dashSets &&
														!!value.statusPlugin &&
														value.dashSets.map(
															dashSet => {
																return (
																	<Card
																		className={
																			classes.dashSets
																		}>
																		<ListItem
																			className={
																				classes.root
																			}
																			key={
																				dashSet.name
																			}
																			role={
																				undefined
																			}
																			dense
																			style={{
																				marginLeft:
																					'50px',
																			}}>
																			<ListItemIcon>
																				<Checkbox
																					edge="start"
																					checked={
																						dashSet.active
																					}
																					tabIndex={
																						-1
																					}
																					disableRipple
																					inputProps={{
																						'aria-labelledby':
																							labelId,
																					}}
																					onChange={() =>
																						this.handleChangeDashSet(
																							value,
																							dashSet,
																						)
																					}
																				/>
																			</ListItemIcon>
																			<ListItemText
																				id={
																					labelId
																				}
																				primary={`${dashSet.name}`}
																			/>
																			<IconButton
																				onClick={() =>
																					this.handleChangeDashSetExpand(
																						value,
																						dashSet,
																					)
																				}
																				className={
																					classes.iconButton
																				}
																				aria-label="downIcon">
																				{!!dashSet.statusDashSet ? (
																					<ArrowDropUp
																						button
																					/>
																				) : (
																					<ArrowDropDown
																						button
																					/>
																				)}
																			</IconButton>
																		</ListItem>
																		{!!dashSet.dashItems &&
																			!!dashSet.statusDashSet &&
																			dashSet.dashItems.map(
																				dashItem => {
																					return (
																						<ListItem
																							className={
																								classes.root
																							}
																							key={
																								dashItem.name
																							}
																							role={
																								undefined
																							}
																							dense
																							style={{
																								marginLeft:
																									'100px',
																							}}>
																							<ListItemIcon>
																								<Checkbox
																									edge="start"
																									checked={
																										dashItem.active
																									}
																									tabIndex={
																										-1
																									}
																									disableRipple
																									inputProps={{
																										'aria-labelledby':
																											labelId,
																									}}
																									onChange={() =>
																										this.handleChangeDashItem(
																											value,
																											dashSet,
																											dashItem,
																										)
																									}
																								/>
																							</ListItemIcon>
																							<ListItemText
																								id={
																									labelId
																								}
																								primary={`${dashItem.name}`}
																							/>
																						</ListItem>
																					)
																				},
																			)}
																	</Card>
																)
															},
														)}
												</Card>
											)
										})}
								</List>
							)}
						</div>
						<div
							style={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
							}}>
							<Button
								onClick={this.addUser}
								className={classes.button}>
								Criar usuário
							</Button>
						</div>
					</Card>
				)}
			</Card>
		)
	}
}

const mapStateToProps = state => ({
	activeCompanyId: state.currentUserState.activeCompany,
	emailValidation: state.createUserState.emailValidation,
	alertCreatedUser: state.createUserState.alertCreatedUser,
	plugins: state.createUserState.plugins,
	componentsPermission: state.createUserState.componentsPermission,
	isLoading: state.createUserState.isLoading,
})

const mapDispatchToProps = dispatch => ({
	// setPluginReducer: setPlugin => dispatch({ type: 'SET_PLUGINS_REDUCER', setPlugin }),
	setPluginsInReducer: plugins => dispatch({ type: 'SET_PLUGINS', plugins }),
	setEmailValidation: emailValidation =>
		dispatch({ type: 'SET_EMAIL_VALIDATION', emailValidation }),
	setAlertCreateUser: alertCreatedUser =>
		dispatch({ type: 'SET_ALERT_CREATE_USER', alertCreatedUser }),
	setComponentsPermissions: componentsPermission =>
		dispatch({ type: 'SET_COMPONENTS_PERMISSIONS', componentsPermission }),
	setLoader: isLoading => dispatch({ type: 'SET_LOADER', isLoading }),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	withStyles(styles),
)(FormCreateUser)
