import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
// import withAuthorization from '../../reusable/withAuthorization'
// import DashSet from '../../reusable/dashSet/DashSet'
import DashSetContainer from '../../reusable/dashSet/container/DashSetContainer'
import Loader from '../../reusable/loader'
import { firebase } from '../../../firebase'
//import * as routes from '../constants/routes'
import styles from './styles'
// import withActivePlugin from '../../reusable/withActivePlugin'
import { setAppTitle } from '../../../actions/app/config'
// import Draggable from 'react-draggable'
import {
	makeGetActivePluginId,
	makeGetActivePlugin,
} from '../../../selectors/plugin'
import withLogger from '../../reusable/withLogger'

// const Model = () => ({
// 	name: '',
// 	type: '',
// 	connection: {},
// 	lastUpdate: '',
// })

/**
 * Manages dashboard Page building
 */
class DashboardPage extends React.PureComponent {
	state = {
		userCrr: null,
	}

	componentDidMount() {
		const { onSetTitle, activePlugin, appTitle } = this.props

		let plugin = null,
			newTitle = null

		if (!!activePlugin) {
			plugin = activePlugin
			newTitle = `${plugin.name} (${plugin.type})`

			if (newTitle !== appTitle) onSetTitle(newTitle)
		}

		firebase.auth.onAuthStateChanged(authUser => {
			if (!!authUser)
				this.setState({
					userCrr: authUser.uid,
				})
		})
	}

	render() {
		const { activePlugin } = this.props
		const { classes, ...otherProps } = this.props
		const { userCrr } = this.state

		return (
			<div className={classes.root}>
				{/* <Portal container={this.props.draggableContainer}>
					<React.Fragment>
						<div style={{ position: 'absolute' }}>odadadadadi </div>
						<div>odadadadadi </div>
					</React.Fragment>
				</Portal> */}
				{/* <Draggable>
					<div ref={this.assistiveTouch}>Teste</div>
				</Draggable> */}
				{!activePlugin && <Loader />}
				{!!activePlugin && !!userCrr && (
					<DashSetContainer userCrr={userCrr} {...otherProps} />
				)}
			</div>
		)
	}
}

// DashboardPage.propTypes = {
// 	userCrr: PropTypes.bool.isRequired
// }

const mapDispatchToProps = dispatch => ({
	onSetTitle: title => dispatch(setAppTitle(title)),
})

const getActivePlugin = makeGetActivePlugin(),
	getActivePluginId = makeGetActivePluginId(),
	mapStateToProps = state => {
		let activePluginId = getActivePluginId(state)
		return {
			appTitle: state.appConfigState.app.title,
			activePluginId,
			activePlugin: getActivePlugin(state)[activePluginId],
		}
	}

export default compose(
	// withAuthorization(authCondition),
	// withActivePlugin,
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
	// withWidth()
	withLogger('plugin', true, [
		{ name: 'pluginId', path: 'activePluginId' },
		{ name: 'pluginName', path: 'activePlugin_name' },
	]),
)(DashboardPage)
