import React from 'react'
import PropTypes from 'prop-types'
import {
	Icon,
	withStyles,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	ExpansionPanelActions,
} from '@material-ui/core'
import classNames from 'classnames'
/**
 * @author Wegner
 * @email wegner@arquia.com.br
 * @created 12-11-2019
 */
class ExpansionItem extends React.PureComponent {
	state = {
		expanded: false,
	}
	onChange = () => {
		const { onChange } = this.props
		const { expanded } = this.state
		if (onChange) onChange(!expanded)
		this.setState({ expanded: !expanded })
	}
	render() {
		const {
			title,
			square,
			classes,
			actions,
			children,
			className,
			closeIcon,
			openIcon,
		} = this.props
		const { expanded } = this.state
		return (
			<div className={classes.container}>
				<ExpansionPanel
					onChange={this.onChange}
					square={square}
					className={classNames(className, classes.filterPanel)}>
					<ExpansionPanelSummary
						classes={{
							root: classes.panelSummary,
							expandIcon: classes.panelIcon,
							expanded: classes.panelSummaryExpanded,
						}}
						expandIcon={
							<Icon>{expanded ? closeIcon : openIcon}</Icon>
						}>
						{title}
					</ExpansionPanelSummary>
					<ExpansionPanelDetails
						className={classNames(classes.panelDetails, {
							noActions: !actions,
						})}>
						{children}
					</ExpansionPanelDetails>
					{actions && (
						<ExpansionPanelActions
							classes={{ root: classes.panelActions }}>
							{actions}
						</ExpansionPanelActions>
					)}
				</ExpansionPanel>
			</div>
		)
	}
}
ExpansionItem.propTypes = {
	square: PropTypes.bool,
	title: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.element.isRequired,
	]),
	openIcon: PropTypes.string,
	closeIcon: PropTypes.string,
}
ExpansionItem.defaultProps = {
	openIcon: 'keyboard_arrow_down',
	closeIcon: 'keyboard_arrow_down',
}

export default withStyles({
	panelSummary: {
		padding: '0px 16px',
	},
	panelDetails: {
		display: 'flex',
		flexDirection: 'column',
		padding: '0px 16px',
		'&.noActions': {
			paddingBottom: '8px',
		},
	},
	panelActions: {
		padding: '8px 10px',
	},
	panelIcon: {
		paddingRight: '6px',
		paddingLeft: '6px',
	},
	panelSummaryExpanded: {},
	onExpandAbsolute: {
		position: 'absolute',
	},
	container: {
		minHeight: '58px',
		position: 'relative',
		width: '100%',
	},
	filterPanel: {
		position: 'absolute',
		width: '100%',
		zIndex: '5',
	},
})(ExpansionItem)
