import React from 'react'
import classNames from 'classnames'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
	Dialog,
	AppBar,
	Toolbar,
	Icon,
	Typography,
	withStyles,
	IconButton,
	DialogContent
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Transition from './Transition'
import Title from './Title'
import { deviceIsIos } from '../../../../utils/env'
import ModalInfo from '../../dashSet/modalInfo'

/**
 * A fullscreen dialog, used especific in extended cards.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added support for iOS environment specifics
 * @author brunoteixeirasilva
 * @version 1.1
 */
class ExtendedDialog extends React.Component {

	state = {
		openModal: false
	}

	componentDidUpdate() {
		const { infoModal, onSetInfoModal } = this.props

		if (infoModal) {
      this.setState({
				openModal: true
			})
			onSetInfoModal(false)
    }
	}

	handleClose = () => {
		const { parentClose } = this.props
		parentClose('extend')
	}

	openMosaico = () => {
		const { onSetActiveModal } = this.props
		
        onSetActiveModal(true)
	}
	
	onCloseModal = () => {
		this.setState({
			openModal: false
		})
	}

	openModal = () => {
		this.setState({
			openModal: true
		})
	}

	render() {
		const {
			classes,
			children,
			parentOpen,
			title,
			fullScreen,
			withScroll,
			dashItem
		} = this.props

		const { openModal } = this.state
		
		return (
			<React.Fragment>
				<Dialog
					fullScreen={fullScreen}
					fullWidth
					TransitionComponent={Transition}
					open={parentOpen}
					onClose={this.handleClose}
				>
					<ModalInfo
						dashItem={dashItem}
						openModal={openModal}
						onCloseModal={(() => this.onCloseModal())}
					/>
					<Title>
						<AppBar
							position="static"
							className={classNames(classes.appBar, {
								[classes.appBarIos]: deviceIsIos()
							})}
						>
							<Toolbar className={classes.toolbar}>
								<Typography
									variant="button"
									style={{ fontSize: '.9em' }}
									color="primary"
								>
									{title}
								</Typography>
								<div className={classes.buttons}>
									<div className={classes.menu}>
										<IconButton
											aria-label="Resumo"
											onClick={this.openModal}
										>
											<Icon>query_stats</Icon>
										</IconButton>
									</div>
									<div className={classes.menu}>
										<IconButton
											aria-label="Visão Mosaico"
											onClick={this.openMosaico}
										>
											<Icon>dashboard</Icon>
										</IconButton>
									</div>
									<div className={classes.menu}>
										<IconButton
											aria-label="Close"
											onClick={this.handleClose}
										>
											<Icon>close</Icon>
										</IconButton>
									</div>
								</div>
							</Toolbar>
						</AppBar>
					</Title>
					<DialogContent
						className={classNames(classes.content, {
							[classes.withScroll]: withScroll
						})}
					>
						<div className={classNames(classes.children)}>
							{children}
						</div>
					</DialogContent>
				</Dialog>
			</React.Fragment>
		)
	}
}

const style = (theme) => ({
	appBar: {
		// position: 'relative',
		height: 'auto',
		color: theme.palette.primary.light,
		backgroundColor: 'transparent'
	},
	appBarIos: {
		paddingTop: 'env(safe-area-inset-top)',
		paddingLeft: 'env(safe-area-inset-left)',
		paddingRight: 'env(safe-area-inset-right)'
	},
	children: {
		padding: theme.spacing.unit,
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	withScroll: {
		overflowY: 'scroll !important'
	},
	content: {
		flex: '1 1 auto',
		padding: '0px',
		height: '100%',
		overflowY: 'hidden',
		'&.withScroll': {
			overflowY: 'scroll'
		}
	},
	grow: {
		flexGrow: 1
	},
	toolbar: {
		justifyContent: 'space-between',
		paddingRight: 0
		// backgroundColor
	},
	menu: {
		'& > button': {
			color: theme.palette.primary.light
			// paddingRight: 0
		}
	},
	buttons: {
		display: 'flex'
	}
})

ExtendedDialog.defaultProps = {
	fullScreen: true
}

ExtendedDialog.propTypes = {
	fullScreen: PropTypes.bool
}

const mapDispatchToProps = dispatch => ({
	onSetActiveModal: async openModal => dispatch({ type: 'SET_MODAL_DASH_SET', openModal }),
	onSetInfoModal: async infoModal => dispatch({ type: 'SET_INFO_MODAL', infoModal })
})

const mapStateToProps = (state) => ({
	icon: state.appConfigState.app.barRightIcon,
	infoModal: state.dashBoardState.infoModal
})

export default compose(
	withStyles(style),
	connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ExtendedDialog)