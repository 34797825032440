import {
	SET_DASH_ITEM_FORM_VALUE,
	CLEAR_DASH_ITEM_FORM
} from '../../../actions/dashItem/form'

const INITIAL_STATE = {
	title: null,
	description: null,
	steps: null,
	isSaving: null,
	active: null
}

/**
 * dashItem form reducer manager
 * @description Created the function
 * @author Wegner
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */
const dashItemFormReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_DASH_ITEM_FORM_VALUE:
			return Object.assign({}, state, { ...action.value })
		case CLEAR_DASH_ITEM_FORM:
			return Object.assign({}, state, INITIAL_STATE)
		default:
			return state
	}
}

export default dashItemFormReducer
