import * as Notifications from './../../../../firebase/notifications'

/**
 * Manages to generate Firebase Notifications
 *
 * @description Isolated the function at this file
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} newAction The created action object
 * @param {string} actionId The generated action ID
 */
export const createNotifications = (newAction, actionId, activeCompanyId) => {
	const notificationObj = {
		type: 'action-sent',
		actionId: actionId,
		date: newAction.date,
		author: newAction.author,
		receiver: newAction.receiver.key,
		active: true,
		cardId: newAction.cardId,
		companyId: activeCompanyId
	}

	//When CC Receivers were specified
	if (!!newAction.ccReceivers && newAction.ccReceivers.length > 0) {
		newAction.ccReceivers.map((uid) =>
			uid !== undefined
				? Notifications.createNewNotification(
						uid,
						Object.assign({}, notificationObj, { receiver: uid })
				  )
				: null
		)
	}

	Notifications.createNewNotification(newAction.receiver.key, notificationObj)
}
