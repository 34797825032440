const FILTERS_PRELOAD_STARTED = 'FILTERS_PRELOAD_STARTED'
const FILTERS_PRELOAD_FINISHED = 'FILTERS_PRELOAD_FINISHED'
const AUTH_USER_SET = 'AUTH_USER_SET'
const AUTH_DESTROYED = 'AUTH_DESTROYED'

const INITIAL_STATE = {
	authUser: null,
	filterPreloadStarted: false,
	preloaded: {
		filters: null,
	},
}

/**
 * Applies authUser info to the current app state
 * @param {Object} state current state of the app
 * @param {Object} action containing "authUser" property with authUser info
 */
const applySetAuthUser = (state, action) => ({
	...state,
	authUser: action.authUser,
})

/**
 * Will create a nextState with "filterPreloadStarted => true"
 *
 * @description
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 */
const setFiltersPreloading = state => ({ ...state, filterPreloadStarted: true })

/**
 * Will create a nextState with "filterPreloadStarted => INITIAL_STATE.filterPreloadStarted"
 * and "preloaded.filters" populated
 *
 * @description
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action Action object with "type" and
 * 						desired "filters" prop with filters inside
 */
const setFiltersPreloaded = (state, action) => ({
	...state,
	preloaded: { ...state.preloaded, filters: action.filters },
	filterPreloadStarted: INITIAL_STATE.filterPreloadStarted,
})

/**
 * Manages session-data state layer
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {*} action The action with "type" and specific props
 */
function sessionReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FILTERS_PRELOAD_STARTED: {
			return setFiltersPreloading(state)
		}
		case FILTERS_PRELOAD_FINISHED: {
			return setFiltersPreloaded(state, action)
		}
		case AUTH_USER_SET: {
			return applySetAuthUser(state, action)
		}
		case AUTH_DESTROYED: {
			return applySetAuthUser(state, INITIAL_STATE)
		}
		default:
			return state
	}
}

export default sessionReducer

export {
	FILTERS_PRELOAD_STARTED,
	FILTERS_PRELOAD_FINISHED,
	AUTH_USER_SET,
	AUTH_DESTROYED,
}
