import { createSelector } from 'reselect'

/**
 * Selector for action list filters, here the filter values will be updated based on list in later updates. disabled now
 * @author Davi Wegner
 * @description Created the function
 * @version 1.0
 */
const makeGetVisibleActionFilter = () => {
	return createSelector(
		[getList, getActions, getAuthor, getAuthUserId],
		(actionListInfo, initialActionList, byAuthor, uid) => {
			if (actionListInfo) {
				let person = [],
					cardIds = [],
					classifications = [],
					types = [],
					active = []

				let keys = Object.keys(initialActionList)

				keys.filter((actionId) => {
					let isAuthor = initialActionList[actionId].author === uid

					return (byAuthor === 0) === isAuthor
				}).forEach((actionId, index) => {

					if (initialActionList[actionId].receiver !== undefined &&
						initialActionList[actionId].receiver !== null) {
						if (byAuthor === 0) {
							if (
								!person.some((value) => {
									return (
										value.key ===
										initialActionList[actionId].receiver.key
									)
								})
							) {
								person.push(initialActionList[actionId].receiver)
							}
						} else {
							if (
								!person.includes(initialActionList[actionId].author)
							) {
								person.push(initialActionList[actionId].author)
							}
						}
					}
				})

				keys.forEach((actionId) => {
					if (!cardIds.includes(initialActionList[actionId].cardId)) {
						cardIds.push(initialActionList[actionId].cardId)
					}

					if (
						!classifications.includes(
							initialActionList[actionId].content.classification
						)
					) {
						classifications.push(
							initialActionList[actionId].content.classification
						)
					}

					if (
						!types.includes(
							initialActionList[actionId].content.type
						)
					) {
						types.push(initialActionList[actionId].content.type)
					}

					if (!active.includes(initialActionList[actionId].active)) {
						active.push(initialActionList[actionId].active)
					}
				})
				return {
					[byAuthor === 0 ? 'receiver' : 'author']: person,
					cardId: cardIds,
					type: types,
					classification: classifications,
					active: active
				}
			}
		}
	)
}

const getActions = (state) => state.actionsState.actionList
const getAuthUserId = (state) => state.sessionState.authUser.uid
const getAuthor = (state) => state.actionsStateByAuthor.byAuthor
const getList = (state, actionListInfo) => actionListInfo
export { makeGetVisibleActionFilter }
