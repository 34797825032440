

/**
 * Generates a security author identification object
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} uid The Firebase or any Auth provider UID
 */
const generateObject = uid => {
	return { author: uid, date: new Date() }
}

// /**
//  * Will hide/show an object based on a super-user access layer.
//  * Will check it based on an user-uid
//  *
//  * @description Created the function
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {Object} object The object being mode-checked and layered
//  * @param {string} uid [Optional] The user-UID being mode-checked and
//  * 						layered or it will pick from logged in one
//  */
const applySuperUserLayer = (object, uid = null) => {
	// uid = !!uid ? uid : auth.currentUser.uid

	return false //! !isSuper ? object : null
}

export { generateObject, applySuperUserLayer }
