export const MANAGE_QUESTION_TRAKING_LOADING_STATE = 'MANAGE_QUESTION_TRAKING_LOADING_STATE',
    LOAD_QUESTION_TRAKING_DATA = 'LOAD_QUESTION_TRAKING_DATA',
    SET_QUESTION_TRAKING_PERIOD = 'SET_QUESTION_TRAKING_PERIOD'

export const setQuestionTrakingPeriod = (dateConfig, value, formType) => ({
    type: SET_QUESTION_TRAKING_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadQuestionTrakingData = (data) => ({
    type: LOAD_QUESTION_TRAKING_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_QUESTION_TRAKING_LOADING_STATE,
    isLoading
})
