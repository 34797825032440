import { createSelector } from 'reselect'

/**
 * Selects only the snapshot from the action-form state portion
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 */
const getActionFormSnapshot = (state) => {
	const base64 = state.actionFormState.snapshot

	return base64
}

/**
 * [memoised] Selects only the snapshot from the action-form state portion
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 */
export const makeGetActionFormSnapshot = () =>
	createSelector(
		getActionFormSnapshot,
		(base64) => base64
	)
