export const SET_ROOT_DASH_ITEM_STEP = 'SET_ROOT_DASH_ITEM_STEP',
	SET_ROOT_DASH_ITEM_STEPS_LOADING = 'SET_ROOT_DASH_ITEM_STEPS_LOADING'

/**
 * [action-creator] for **root** dash-item-step data loading state **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The current loaded dashItemId
 * @param {boolean} isLoading The current is-loading state
 */
export const setRootDashItemStepsLoading = (dashItemId, isLoading) => ({
	type: SET_ROOT_DASH_ITEM_STEPS_LOADING,
	dashItemId,
	isLoading
})

/**
 * [action-creator] for **root** dash-item-steps info loaded dispatch
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dashItemId The current loaded dashItemId
 * @param {Object} steps The root steps dash-item
 */
export const setRootDashItemSteps = (dashItemId, steps) => ({
	type: SET_ROOT_DASH_ITEM_STEP,
	dashItemId,
	steps
})
