import { call, put, takeEvery } from 'redux-saga/effects'
import { PICK_SELECTED_FILTER_ITEM } from '../component/reusable/filter/actions/filter'
import { CLEAR_SELECTED_FILTER_VALUES_BY_DATASET } from '../actions/filter/global'
import { fetchFreshData } from './filter'
import { CLEAN_ON_COMPANY_CHANGE } from '../actions/company'
import { resetAppTitle } from './app/config'
import { SET_USER_ACTIVE_COMPANY } from '../actions/user/company'
import { showSnackBar } from '../actions/snackbar'
import { getByUser } from '../firebase/company/group/permission/filter'
import { store } from '../store'
import { makeGetAuthUserID } from '../selectors/session'

/**
 * Monitors specific dispatches, performing any necessary side-effects
 * in this layer.
 *
 * @description Created the generator function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added company change tracker for preloading filters
 * @author brunoteixeirasilva
 * @version 1.1
 */
function* generalAppSaga() {
	yield takeEvery(PICK_SELECTED_FILTER_ITEM, fetchFreshData)
	yield takeEvery(CLEAR_SELECTED_FILTER_VALUES_BY_DATASET, fetchFreshData)

	//Whenever a company is get cleaned from store
	yield takeEvery(CLEAN_ON_COMPANY_CHANGE, resetAppTitle)

	//Whenever a company is selected/activated by the user
	yield takeEvery(SET_USER_ACTIVE_COMPANY, preloadFilters)
}

function* onSagaError(ex) {
	//In case of error, will throw the message at the snackbar
	yield put(showSnackBar(ex.message))

	//When the environment is local
	//Throws the error up
	if (process.env.REACT_APP_NODE_ENV.trim() === 'development') {
		throw ex
	}
}

//TODO: continue from here
function* preloadFilters(action) {
	try {
		// console.log('action.activeCompanyId')
		// console.log(action.activeCompanyId)

		//TODO: implement state selector
		//Getting state from store (has the default)
		yield call(beginFilterSaga, store.getState(), action)
	} catch (ex) {
		yield onSagaError(ex)
	}
}

//TODO: continue from here
async function beginFilterSaga(appState, action) {
	const getUserId = makeGetAuthUserID()

	//permission/filter map request
	return await getByUser(getUserId(appState), action.activeCompanyId)
		.then(companyFilters => {
			//TODO: implement filter map treat
		})
		.then(() => {
			//TODO: implement view blockage in case filter map has something
		})
		.then(() => {
			//TODO: implement filter setup (data and values)
		})
		.then(() => {
			//TODO: implement filter dispatching
		})
		.then(() => {
			//Restoring app's default title
			return
		})
}

export default generalAppSaga

export { onSagaError }
