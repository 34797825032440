import React from 'react'
import PropTypes from 'prop-types'
import ContextMenuOption from './option/ContextMenuOption'
import { Menu } from '@material-ui/core'

const ITEM_HEIGHT = 48

/**
 * Renders a context menu wrapper
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed to support min-max widths
 * @author brunoteixeirasilva
 * @version 1.1
 */
const ContextMenu = (props) => {
	const { id, open, onClose, options, anchorElement } = props

	return (
		<Menu
			id={id}
			anchorEl={anchorElement}
			open={!!open}
			onClose={onClose}
			PaperProps={{
				style: {
					maxHeight: ITEM_HEIGHT * 4.5,
					minWidth: 200,
					maxWidth: 240
				}
			}}
		>
			{options.map((Option, key) =>
				React.isValidElement(Option)
					? React.cloneElement(Option, {
							onClose: onClose,
							key: Option.key
					  })
					: Option.hide || (
							<ContextMenuOption
								key={Option.key}
								selected={false}
								option={Option}
								onClose={onClose}
							/>
					  )
			)}
		</Menu>
	)
}

ContextMenu.propTypes = {
	onClose: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	open: PropTypes.bool,
	anchorElement: PropTypes.object
	// options: PropTypes.oneOfType([
	// 	PropTypes.arrayOf(React.Component),
	// 	PropTypes.arrayOf(
	// 		PropTypes.shape({
	// 			disabled: PropTypes.bool,
	// 			hide: PropTypes.bool,
	// 			key: PropTypes.string.isRequired,
	// 			label: PropTypes.string.isRequired,
	// 			action: PropTypes.func.isRequired
	// 		})
	// 	)
	// ])
}

export default ContextMenu
