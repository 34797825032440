export const CLEAR_SELECTED_FILTER_VALUES_BY_DATASET =
		'CLEAR_SELECTED_FILTER_VALUES_BY_DATASET',
	SET_PLUGIN_FILTERS = 'SET_PLUGIN_FILTERS',
	CLEAR_GLOBAL_SEARCH_TEXT = 'CLEAR_GLOBAL_SEARCH_TEXT',
	SET_GLOBAL_SEARCH_TEXT = 'SET_GLOBAL_SEARCH_TEXT',
	SET_FILTERS_IS_LOADING = 'SET_FILTERS_IS_LOADING',
	SET_GLOBAL_LABELS = 'SET_GLOBAL_LABELS',
	SET_GLOBAL_SELECTIVE_FILTERS = 'SET_GLOBAL_SELECTIVE_FILTERS',
	CLEAR_ALL_GLOBAL_FILTERS = 'CLEAR_ALL_GLOBAL_FILTERS'

/**
 * [action-creator] for _filter-global_ selected-values **clearing**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 */
export const clearGlobalFilterValues = (filterKey, dataSetId) => ({
	type: CLEAR_SELECTED_FILTER_VALUES_BY_DATASET,
	dataSetId,
	filterKey
})

/**
 * [action-creator] for _filter-global_ plugin-filters **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 */
export const setGlobalPluginFilters = (pluginFilters) => ({
	type: SET_PLUGIN_FILTERS,
	pluginFilters
})

/**
 * [action-creator] for _filter-global_ search-text **clearing**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 */
export const clearGlobalSearchText = () => ({
	type: CLEAR_GLOBAL_SEARCH_TEXT
})

/**
 * [action-creator] for _filter-global_ search-text **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 */
export const setGlobalSearchText = (text) => ({
	type: SET_GLOBAL_SEARCH_TEXT,
	text
})

/**
 * [action-creator] for _filter-global_ is-loading **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 */
export const setGlobalFiltersLoading = (isLoading) => ({
	type: SET_FILTERS_IS_LOADING,
	isLoading
})

/**
 * [action-creator] for _filter-global_ labels **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {{ key: string|int, label: string }} data The data-set id to be rooted with the text
 */
export const setGlobalFilterLabels = (data) => ({
	type: SET_GLOBAL_LABELS,
	data
})

/**
 * [action-creator] for _filter-global_ selective-filters **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} selectiveFilters The selective-filters string list
 */
export const setGlobalSelectiveFilters = (selectiveFilters) => ({
	type: SET_GLOBAL_SELECTIVE_FILTERS,
	selectiveFilters
})

/**
 * [action-creator] For _filter-global_ **clearing**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const clearAllGlobalFilters = () => ({
	type: CLEAR_ALL_GLOBAL_FILTERS
})
