import { auth, fs } from '../../firebase'
import * as Author from '../../../utils/security/author'
import * as ObjectState from '../../../utils/object/state'

/**
 * [Firestore] Creates a new [company] group at the /companies/{companyId}/groups collection
 * ! If you are looking for default system groups, look at firebase/group location!
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Group} data The creation group data
 */
export const doCreate = (companyId, data) => {
	let groupObject = Object.assign(
		{},
		data,
		Object.assign(
			{},
			ObjectState.generateActive(),
			Author.generateObject(auth.currentUser.uid)
		)
	)

	//Gets the reference and the generated push ID
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc()

	//Sets the object
	ref.set(groupObject)

	return ref.id
}

/**
 * [Firestore] Updates a [company] group at the /companies/{companyId}/groups collection
 * ! If you are looking for default system groups, look at firebase/group location!
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Group} data The updating group data
 */
export const doUpdate = (companyId, groupId, data) => {
	let groupObject = Object.assign(
		{},
		Object.assign(
			{},
			ObjectState.generateActive(),
			Author.generateObject(auth.currentUser.uid)
		),
		data
	)

	//Gets the reference and the generated push ID
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)

	//Sets the object
	return ref.update(groupObject)
}

/**
 * [Firestore] Will get a company + group users' list
 * ! If you are looking for default system groups, look at firebase/group location!
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId The (active) company ID
 * @param {*} groupId The group to get users from
 */
export const getUsers = (companyId, groupId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('users')
		.where('active', '==', true)
		.get()
}

/**
 * [Firestore] Get group by id
 *
 * @description Created the function
 * @author lopes
 * @version 1.0
 *
 * @param {string} companyId Currently active company ID
 * @param {string} groupId The current group ID
 */
export const get = (companyId, groupId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.get()
}

/**
 * [Firestore] Will try and find the availability of an UID at a company-group
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} companyId Currently active company ID
 * @param {string} groupId The current group ID
 * @param {string} uid The logged-in user
 * @param {boolean} includeInactive If true, will include inactive users too
 */
export const containsUser = async (
	companyId,
	groupId,
	uid,
	includeInactive = false
) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('users')
		.doc(uid)
		.get()
		.then((doc) => doc.exists && (includeInactive || doc.data().active))
}
