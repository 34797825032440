const INITIAL_STATE = {
	responses: null
}

function responsesReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_LOADED_RESPONSES':
			return Object.assign({}, state, {
				responses: action.responses
			})
		default:
			return state
	}
}

export default responsesReducer
