import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Portal, Icon, IconButton, Typography, Grid } from '@material-ui/core'
import ReactDOM from 'react-dom'
import DashboardCard from './DashboardCard'
import ExtendedCard from './ExtendedCard'
import CardInfo from './cardInfo'
import withActiveCompany from '../withActiveCompany'
import FilterBarContainer from '../filterBar'
import * as DashItemSteps from '../../../firebase/step'
import * as CompanyDashItem from '../../../firebase/company/dashItem'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import { setCompanyDashItem, setCompanyDashItemLoading, setCompanyDashItemData } from '../../../actions/company/dashItem'
import {
	makeGetCompanyDashItem,
	makeGetCompanyDashItemsLoading,
	makeGetCompanyDashItemData,
	makeGetCompanyDashItemFilterValues,
} from '../../../selectors/company/dashItem'
import { processDataSetsByStep } from './functions/dataSetGet'
import { reduceDataSets } from './functions/dataSetResolver'
import { solveUsefulSteps, pickUsefulSteps } from './functions/stepSolver'
import { makeGetCompanyPluginDataSourceById } from '../../../selectors/company/plugin'
import { makeGetActivePluginId } from '../../../selectors/plugin'
import { makeGetRootDashItemSteps } from '../../../selectors/dashItem/step'
import { makeGetCompanyDashItemStepAllowedFilters } from '../../../selectors/company/dashItem/step'
import Placeholder from '../card/Placeholder'
import memoize from '../../../utils/memoize'
import { getComponent } from '../../../lazyComponentImporter'
import { makeGetComponentPath } from '../../../selectors/step'
import { translate } from '../../../utils/lang'
import withMappedDom from '../withMappedDom'
import { openActionModal } from '../../../reducers/action/navigation'
import { fs } from '../../../firebase/firebase'

//TODO: EXTERNALIZE THIS
const informerStyles = theme => ({
	iconButton: {
		color: theme.palette.primary.dark,
		padding: '0',
		textAlign: 'center',
	},
	iconButtonContainer: {
		textAlign: 'center',
	},
	informText: {
		color: theme.palette.primary.dark,
	},
})
export const InformerIconButton = withStyles(informerStyles)(props => {
	return (
		<div className={props.classes.iconButtonContainer}>
			<IconButton onClick={props.onClick} className={props.classes.iconButton}>
				<Icon>{props.icon}</Icon>
			</IconButton>
			<Typography onClick={props.onClick} className={props.classes.informText}>
				{props.label}
			</Typography>
		</div>
	)
})

/**
 * Handles default dashboard item functions
 *
 * @description ???
 * @author wegner
 * @version 1.0.0
 *
 * @description ???
 * @author davispindola
 * @version 1.1.0
 *
 * @description New version of dashItem management
 * @author brunoteixeirasilva
 * @version 2.0
 *
 * @description Added dinamic load of components
 * @author Wegner
 * @version 2.1
 */
class DashItem extends React.PureComponent {
	state = {
		extendTooltip: false,
		infoTooltip: false,
		extend: false,
		info: false,
		isLight: false,
	}

	constructor(props) {
		super(props)
		this.cardRef = React.createRef()
		this.extendedRef = React.createRef()
	}

	componentDidMount() {
		const { isLoading } = this.props

		if (!isLoading) {
			this.processTasks()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { openForm, handleActionModal, extend, dashItemId, beforeExtend } = this.props
		
		if (openForm === true && this.state.isLight === false) handleActionModal()

		if(!!extend && !prevState.extend) this.handleOpen('extend')

		if(!!beforeExtend && beforeExtend === dashItemId) this.handleClose('extend')
	}

	processTasks = () => {
		const {
			activeCompanyId,
			onSetCompanyDashItem,
			onSetCompanyDashItemData,
			onSetCompanyDashItemLoading,
			dashItemId,
			beforeIsExpanded,
			onSetBeforeIsExpanded
		} = this.props

		if (dashItemId === 'CNuKIbm75PTqkcLZfWSF' && beforeIsExpanded === true) {
			this.handleTooltipOpen('extendTooltip')
			this.handleOpen('extend')
			onSetBeforeIsExpanded(false)
		}

		// TODO: Fix this functionality
		// TODO: Add loading state
		onSetCompanyDashItemLoading(true)
			//Company dashItem Filter info loaded
			.then(() => CompanyDashItem.getById(activeCompanyId, dashItemId))
			//Company dashItem Filter info loaded
			.then(snapshot => onSetCompanyDashItem(snapshot.data()))
			//Root dashItem steps info has to be loaded
			//TODO: @daviwegner Remove from here when dash-set loads it
			.then(dashItemDispatch => DashItemSteps.getSteps(dashItemId))
			//Company-dash-item info has to be loaded
			.then(stepsSnapshot => pickUsefulSteps(stepsSnapshot, dashItemId, 0, activeCompanyId))
			//Mapping useful from the replies over last request
			.then(usefulStep => solveUsefulSteps(dashItemId, usefulStep))
			//Effectives the getFrom datasource-dataset-with-params
			.then(stepAndDataSets => processDataSetsByStep(stepAndDataSets, dashItemId))
			//Queries resolved, now, maps to companyDashItem data redux layer
			.then(resolvedDataSets => reduceDataSets(resolvedDataSets))
			//Setting at redux state-layer
			.then(data => onSetCompanyDashItemData(data))
			.catch(error => {
				//Forces loading to false
				onSetCompanyDashItemLoading(false)

				if (process.env.REACT_APP_NODE_ENV.trim() === 'development') {
					console.error(error)
				}

				//FIXME: This might throw an error screen
				// throw error
			})
	}

	handleOpen = (name, validate) => {
		const { resetState } = this.props

		if(!!validate) resetState()
		this.setState({ [name]: true })
	}

	handleTooltipOpen = name => {
		this.setState({ [name]: true })
	}

	handleTooltipClose = name => {
		this.setState({ [name]: false })
	}

	handleClose = name => {
		const { resetState } = this.props
		resetState()
		this.setState({ [name]: false })
	}

	handleRoute = route => {
		const { history } = this.props
		history.push(route)
	}

	filterBar = (noMargin = false) => {
		const { allowedFilters } = this.props

		return <FilterBarContainer noMargin={noMargin} allowedFilters={allowedFilters} />
	}

	async downloadImage() {
		htmlToImage.toPng(ReactDOM.findDOMNode(this)).then(dataUrl => {
			download(dataUrl, 'image.png')	
		})
	}

	handleAction = () => {
		const { getJSON, getDomSnapshot, handleActionModal, data, onSetIsAction } = this.props
		// const self = this
		onSetIsAction(true)
		getJSON(data)
		getDomSnapshot(ReactDOM.findDOMNode(this.cardRef.current))
		handleActionModal()
	}

	handleFavoriteCard = () => {
		const { activeCompanyId, dashItemId, authUser, activePluginId, dashSet } = this.props

		const cardRef = fs
			.collection('companies')
			.doc(activeCompanyId)
			.collection('users')
			.doc(authUser.uid)
			.collection('plugins')
			.doc(activePluginId)
			.collection('dashSets')
			.doc(dashSet)
			.collection('cards')
			.doc(dashItemId)

		cardRef.get().then(card => {
			if (card.exists) {
				if (!!card.data().favorite) {
					cardRef.set(
						{
							favorite: false,
						},
						{ merge: true }
					)
				} else {
					cardRef.set(
						{
							favorite: true,
						},
						{ merge: true }
					)
				}
			}
		})
	}

	urltoFile(url, filename, mimeType) {
		mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
		return fetch(url)
			.then(res => {
				return res.arrayBuffer()
			})
			.then(buf => {
				return new File([buf], filename, { type: mimeType })
			})
	}

	shareImage = () => {
		htmlToImage
			.toPng(ReactDOM.findDOMNode(this))
			.then(dataUrl => {
				return this.urltoFile(dataUrl, 'image.png')
			})
			.then(async image => {
				const shareData = {
					title: 'Agildash',
					text: 'Agildash',
					files: [image],
				}

				try {
					await navigator.share(shareData)
				} catch (err) {
					console.error('Error to send image', err)
				}
			})
	}

	render() {
		const {
			dashItem,
			data,
			classes,
			children,
			borderLess,
			dashItemId,
			stepId,
			nextStepId,
			dataSourceId,
			filterValues,
			isLoading,
			mobile,
			draggableContainer,
			activeDashItem,
			isDashSetSelected,
			handleRemount,
			onToggleModal,
			activeCompanyId,
			authUser,
			activePluginId,
			dashSet,
			companyDashItem,
			reloadDashItemData,
		} = this.props
		const { info, extend, extendTooltip, infoTooltip } = this.state

		const comp = (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
				}}
				ref={this.cardRef}>
				<Suspense fallback={<Placeholder />}>
					{!isLoading && !!data && children ? (
						React.cloneElement(children, {
							key: dashItemId,
							dashItemId: dashItemId,
							data: data,
							// dataSet: dashItem.dataSet,
							dataSourceId: dataSourceId,
							filterValues: filterValues,
							stepId: stepId,
							nextStepId: nextStepId,
							mobile: mobile,
							activeCompanyId: activeCompanyId,
							extend: !!extend,
							filterList: this.filterBar(),
							goBack: this.handleClose,
							reloadDashItemData: reloadDashItemData,
						})
					) : (
						<Placeholder />
					)}
				</Suspense>
			</div>
		)

		return (
			<React.Fragment>
				{mobile && dashItemId === activeDashItem && isDashSetSelected && !!draggableContainer && (
					<Portal container={draggableContainer.current}>
						<Grid container>
							<Grid item xs={4}>
								<InformerIconButton onClick={() => handleRemount(dashItemId)} icon={'refresh'} label={translate('label/refresh')} />
							</Grid>
							<Grid item xs={4}>
								<InformerIconButton
									onClick={() => this.handleOpen('info')}
									icon={'help_outline'}
									label={translate('label/what-am-i-seeing')}
								/>
							</Grid>
							<Grid item xs={4}>
								<InformerIconButton onClick={() => onToggleModal()} icon={'filter_list'} label={translate('label/filters')} />
							</Grid>
							<Grid item xs={4}>
								<InformerIconButton
									onClick={() => this.handleAction()}
									icon={'camera_alt'}
									label={translate('label/generate-action')}
								/>
							</Grid>
							<Grid item xs={4}>
								<InformerIconButton onClick={() => this.downloadImage()} icon={'image'} label={translate('label/download-image')} />
							</Grid>
						</Grid>
					</Portal>
				)}

				<DashboardCard
					//TODO: remove dashItemId from here or make use of it
					// dashItemId={dashItemId}
					classes={classes}
					title={dashItem.title}
					handleOpen={this.handleOpen}
					handleTooltipOpen={this.handleTooltipOpen}
					handleTooltipClose={this.handleTooltipClose}
					handleRoute={this.handleRoute}
					extendTooltip={extendTooltip}
					infoTooltip={infoTooltip}
					borderLess={borderLess}
					filterList={this.filterBar()}
					handleFavoriteCard={this.handleFavoriteCard}
					activeCompanyId={activeCompanyId}
					dashItemId={dashItemId}
					authUser={authUser.uid}
					activePluginId={activePluginId}
					dashSet={dashSet}
					mobile={mobile}
					isSelected={dashItemId === activeDashItem && isDashSetSelected}>
					{!extend && comp}
				</DashboardCard>
				{!!extend && !mobile && (
					<ExtendedCard
						cardId={dashItemId}
						dashItem={dashItem}
						open={extend}
						data={data}
						handleClose={this.handleClose}
						filterList={this.filterBar(true)}
						title={dashItem.title}>
						{extend && comp}
					</ExtendedCard>
				)}
				{!!info && <CardInfo open={info} handleClose={this.handleClose} description={dashItem.description} title={dashItem.title} />}
			</React.Fragment>
		)
	}
}

const styles = theme => ({
	filterArea: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		backgroundColor: 'rgba(80,80,80,0.8)',
	},
	card: {
		overflowY: 'auto',
		maxHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		'&.mobile': {
			backgroundColor: 'transparent',
		},
		'&.ios': {
			// marginTop: 'calc((8px) + var(--ios-inset-top))'
			// marginBottom: 'calc((80px) + var(--ios-inset-top))'
		},
	},
	tooltipIcon: {
		paddingRight: theme.spacing.unit,
		fontSize: '24px',
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.light,
			transform: 'scale(1.3)',
			cursor: 'pointer',
		},
	},
	extendedIcon: {
		paddingRight: '5px',
		fontSize: '24px',
		color: 'rgba(120,120,120,0.8)',
		'&:hover': {
			color: 'rgba(0,0,0,1)',
			transform: 'scale(1.3)',
		},
	},
	cardHeader: {
		// padding: '8px 10px',
		backgroundColor: '#FFF',
	},
	cardContent: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '4px 10px',
		overflowX: 'hidden',
		overflowY: 'hidden',
		'&:last-child': {
			padding: '4px 10px',
		},
		'&.mobile': {
			padding: '2px 2px',
			backgroundColor: '#FFF',
		},
		'&.ios': {
			// marginTop: 'calc((8px) + var(--ios-inset-top))'
			marginBottom: 'calc((20px) + var(--ios-inset-top))',
		},
	},
	cardFooter: {
		marginRight: theme.spacing.unit / 2,
		textAlign: 'right',
	},
	cardContentMobile: {
		textAlign: 'center',
		flexGrow: 1,
		width: '100%',
		// height: "280px",
		overflowX: 'hidden',
		overflowY: 'hidden',
		paddingLeft: '0px', //Added internal spacing - left
		paddingRight: '0px', //Added internal spacing - right
		paddingTop: 0,
		paddingBottom: '0 !;',
		marginLeft: 0,
		marginRight: 0,
		height: '100%',
	},
	cardContentSpacer: {
		'&.mobile': {
			height: '100%',
		},
		// paddingTop: theme.spacing.unit // removed this => theme.spacing.unit
	},
	tooltipOpener: {
		marginTop: 0,
		marginRight: 0,
		alignSelf: 'center',
	},
	tooltip: {},

	chip: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
		marginLeft: theme.spacing.unit / 2,
		marginRight: theme.spacing.unit / 2,
	},
	title: {
		// textTransform: 'uppercase'
		//marginBottom: theme.spacing.unit,
		//paddingBottom: theme.spacing.unit
		//color: theme.palette.primary.main + ' !important'
		// background: deepPurple[300]
	},
	pos: {
		marginBottom: theme.spacing.unit * 2,
	},
	paper: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: 'transparent',
		// backgroundColor: theme.palette.background.paper,
		boxShadow: '', //theme.shadows[5],
		padding: theme.spacing.unit * 4,
	},
	dialog: {
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	stepperPaper: {
		width: '100%',
		paddingBottom: theme.spacing.unit * 2,
	},
	commandContainer: {
		// position: 'relative',
		// top: '0',
		// left: '0',
		borderRadius: '20px',
		backgroundColor: 'rgb(254, 254, 254,1)',
		boxShadow: '0px 0px 2.5px',
		width: '100%',
		padding: '10px',
	},
})

DashItem.propTypes = {
	// children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired,
	dashItemId: PropTypes.string.isRequired,
	dashItem: PropTypes.object.isRequired,
}

const makeMapStateToProps = () => {
	const getDashItem = makeGetCompanyDashItem(),
		getDashItemIsLoading = makeGetCompanyDashItemsLoading(),
		getDashItemData = makeGetCompanyDashItemData(),
		getStepAllowedFilters = makeGetCompanyDashItemStepAllowedFilters(),
		getFilterValues = makeGetCompanyDashItemFilterValues(),
		getActivePluginId = makeGetActivePluginId(),
		getDataSourceById = makeGetCompanyPluginDataSourceById(),
		getDashItemSteps = makeGetRootDashItemSteps(),
		getComponentPath = makeGetComponentPath(),
		getComp = memoize(path => getComponent(path))
	const mapStateToProps = (state, ownProps) => {
		const companyDashItem = getDashItem(state, ownProps.dashItemId),
			isLoading = getDashItemIsLoading(state, ownProps.dashItemId),
			data = getDashItemData(state, ownProps.dashItemId),
			activePluginId = getActivePluginId(state),
			dataSourceId = getDataSourceById(state, activePluginId),
			steps = getDashItemSteps(state, ownProps.dashItemId),
			stepKeys = !!steps && Object.keys(steps),
			currentStepId = !!stepKeys && stepKeys.length > 0 ? stepKeys[0] : null,
			allowedFilters = getStepAllowedFilters(state, ownProps.dashItemId, currentStepId),
			filterValues = getFilterValues(state, ownProps.dashItemId, currentStepId),
			nextStepId = !!stepKeys && stepKeys.length > 1 ? stepKeys[1] : null,
			componentPath = getComponentPath(state, ownProps.dashItemId, currentStepId)

		return {
			beforeIsExpanded: state.dashItemState.beforeIsExpanded,
			companyDashItem,
			actionModalState: state.actionsState.showActionModal,
			reloadDashItemData: state.dashItemState.reloadDashItemData,
			actionState: state.actionsState,
			openForm: state.lightState.openForm,
			steps: steps,
			stepId: currentStepId,
			nextStepId: nextStepId,
			dataSourceId: dataSourceId,
			isLoading: isLoading,
			filtersUpdated: !!companyDashItem ? companyDashItem.filters : null,
			allowedFilters: allowedFilters,
			filterValues: filterValues,
			children: !!ownProps.children ? ownProps.children : !componentPath ? null : getComp(componentPath),
			data: data,
		}
	}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	// setRootDashItemLoading: async (isLoading) =>
	// 	dispatch(setRootDashItemLoading(ownProps.dashItemId, isLoading)),
	onSetCompanyDashItemLoading: async isLoading => dispatch(setCompanyDashItemLoading(ownProps.dashItemId, isLoading)),
	// setRootDashItem: async (dashItem) =>
	// 	dispatch(setRootDashItemLoading(ownProps.dashItemId, dashItem)),
	onSetCompanyDashItemData: async data => dispatch(setCompanyDashItemData(ownProps.dashItemId, data)),
	onSetCompanyDashItem: async dashItem => dispatch(setCompanyDashItem(ownProps.dashItemId, dashItem)),
	handleActionModal: () => dispatch(openActionModal()),
	onSetIsAction: async isAction => dispatch({ type: 'SET_IS_ACTION', isAction }),
	onSetBeforeIsExpanded: beforeIsExpanded => dispatch({ type: 'BEFORE_IS_EXPANDED', beforeIsExpanded })
})

export default compose(withActiveCompany, connect(makeMapStateToProps(), mapDispatchToProps), withStyles(styles), withRouter, withMappedDom)(DashItem)
