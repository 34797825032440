import React from 'react'
import withRedirect from '../withRedirect'
import { Button } from '@material-ui/core'
import { translate } from '../../../utils/lang'

/**
 * Redirect user for plugin page when clicked
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0.0
 *
 */
class ButtonRedirect extends React.Component {
	render() {
		const { onClick } = this.props
		return (
			<Button variant="outlined" onClick={onClick}>
				{translate('label/redirect-to-page', {
					prefix: translate('prefix/of', { page: 'plugin' })
				})}
			</Button>
		)
	}
}

export default withRedirect('/plugin')(ButtonRedirect)
