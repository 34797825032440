import { auth, fs } from '../firebase'
import * as Group from '../group'
import * as Author from '../../utils/security/author'
import * as ObjectState from '../../utils/object/state'

/**
 * @author Wegner
 * @email wegner@arquia.com.br
 * @created 07-02-2020
 */
export const fetchCurrentUser = (uid, setData) => {
	return fs
		.collection('users')
		.doc(uid)
		.onSnapshot((docSnapshot) => {
			// console.log('Got in')
			// console.log(docSnapshot.data())
			setData(docSnapshot.data())
		})
}

/**
 * [Firestore] Checks whether the user-company relation (account) exists
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed the method to use Firebase Firestore mechanism.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid the user ID to check an account
 * @param {string} companyId the current company Id
 */
export const checkExists = async (uid, companyId) => {
	let exists = false

	//Will expect for the registry being found
	//And asks the snapshot if it exists or not
	exists = await fs
		.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.get()
		.then((userDoc) => userDoc.exists)

	return exists
}

/**
 * [Firestore] Checks whether the user-company-group (account group access) exists
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed the method to use Firebase Firestore mechanism.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid the user ID to check an account
 * @param {string} companyId the current company Id
 * @param {string} groupId the group Id to check relation
 */
export const checkGroupRelationExists = async (uid, companyId, groupId) => {
	let exists = false

	//Will expect for the registry being found
	//And asks the snapshot if it exists or not
	exists = await fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('users')
		.get()
		.then((dataSnapshots) => {
			//If the path is existent and valid
			if (!dataSnapshots.empty) {
				dataSnapshots.forEach((groupUser) => {
					if (groupUser.id === uid) {
						exists = true
					}
				})
			}
		})

	return exists
}

/**
 * Accesses user account information
 *
 * @description Created using RTDB.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now uses Firebase Firestore.
 * 				Name changed from [onGetAccount] to [get].
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {*} uid The users being queried
 */
export const getAccount = (uid, companyId) =>
	fs
		.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.get()

/**
 * [Firestore] Accesses user account groups IDs
 * Note: If no groups are found, it returns an empty object (return {})
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed the method to use Firebase Firestore mechanism.
 * 				Changed name from [onGetAccountGroupIds] to [getGroups].
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {*} uid
 */
export const getGroups = (uid, companyId) => {
	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')

	return ref.get().then((dataSnapshot) => {
		let userGroups = []

		//Tries to find users-account-groups
		dataSnapshot.forEach((group) => {
			ref.doc(group.id)
				.collection('users')
				.doc(uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						userGroups.push(group)
					}
				})
		})

		return userGroups
	})

	// onGetAccount(uid, companyId).then((dataSnapshot) => {
	// 	//Account's not created -> error
	// 	if (!dataSnapshot.exists)
	// 		throw new Error('validation/user-account-invalid')
	// 	//TODO: Review => Tries to find account groups
	// 	return thenFunc(
	// 		!dataSnapshot.val().groups ? {} : dataSnapshot.val().groups
	// 	)
	// })
}

// Account creation API

/**
 * [Firestore] Adds an account to the "/account" collection, user-company relationship.
 * Will check whether the "/account" registry already exists, prior to changing it
 *
 * @description Created the component by using Firebase RTDB.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed the method to use Firebase Firestore mechanism.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid The user having an account created
 * @param {string} companyId The active company
 * @param {Object} data [optional] The data to be added to the object
 */
export const doCreateAccount = async (uid, companyId, data = null) => {
	//Generates the data object
	let exists = await checkExists(uid, companyId),
		dataObject = null

	//If the account already exists
	//stop the process
	if (exists) return

	//Builds the object to store
	dataObject = Object.assign(
		{},
		data,
		Object.assign(
			{},
			ObjectState.generateActive(),
			Author.generateObject(auth.currentUser.uid)
		)
	)

	//Adds the company-user relation
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('users')
		.doc(uid)
		.set(dataObject, { merge: false })
}

/**
 * [Firestore] Adds a group to an "/companies/{companyId}/groups/{groupId}/users/{uid}",
 * user and company registry
 * ! It doesn't work for [DEFAULT/root] groups
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed the method to use Firebase Firestore mechanism.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} uid The user being added to the group
 * @param {string} companyId The active company
 * @param {string} groupId The group that the user is being added
 */
export const doAddGroupRelation = async (uid, companyId, groupId) => {
	let relationExists = await checkGroupRelationExists(
			uid,
			companyId,
			groupId
		),
		relationObject = null

	//try {
	//If the group relation already exists
	//Will stop the set process
	if (relationExists) return

	//Writes the relation object
	relationObject = Object.assign(
		{},
		ObjectState.generateActive(),
		Author.generateObject(auth.currentUser.uid)
	)

	return fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('users')
		.doc(uid)
		.set(relationObject, { merge: false })
}

/**
 * Will check if UID (specified) is a super-user
 *
 * @param {string} uid The user UID to be checked
 */
export const isSuperUser = (uid) => {
	//Firstly, capture all super groups
	let superGroups = Group.getSuper()
	let isSuperUser = false

	//For each super group, tries to find super users
	return superGroups.then((superGroups) => {
		let groupRef = fs.collection('groups')
		let promises = []

		//Iterates each super-user group
		superGroups.forEach((group) => {
			//Until now, user wasn't found as a super-user
			if (!isSuperUser) {
				//Gets the user from the [DEFAULT] group
				promises.push(
					groupRef
						.doc(group.id)
						.collection('users')
						.doc(uid)
						.get()
						.then(
							(superUser) => {
								//In case the user was found inside a group
								if (superUser.exists && superUser.data().active)
									isSuperUser = true

								return isSuperUser
							},
							(err) => {
								throw new Error(err.code)
							}
						)
				)
			}
		})

		return Promise.all(promises).then((result) => {
			return isSuperUser
		})
	})
}

/**
 * Creates an user registry at /groups/{groupId}/users collection
 * on a specific super group
 *
 * @param {string} groupId The supergroup ID
 * @param {string} uid The user being promoted
 */
export const doPromoteSuperUser = (groupId, uid) => {
	let docRef = fs
		.collection('groups')
		.doc(groupId)
		.collection('users')
		.doc(uid)

	//Sets the user a super user, promoting it to the specific super user group
	return docRef.set(
		Object.assign(
			{},
			ObjectState.generateActive(),
			Author.generateObject(auth.currentUser.uid)
		),
		{ merge: false }
	)
}

export const onFetchLanguage = (uid, thenFunc) => {
	let ref = fs.collection('users').doc(uid)
	ref.onSnapshot((snapshot) => {
		thenFunc(snapshot.data().language)
	})
}

export const onFetchAvailableLanguages = (thenFunc) => {
	let languageList = {}
	return fs
		.collection('app')
		.doc('config')
		.collection('languages')
		.onSnapshot((snapshot) => {
			snapshot.docChanges().forEach((docChange) => {
				let doc = docChange.doc
				switch (docChange.type) {
					case 'added':
						languageList = Object.assign({}, languageList, {
							[doc.id]: doc.data()
						})
						break
					// case 'modified':

					// case 'removed':
					default:
						break
				}
			})
			thenFunc(languageList)
		})
}

export const onSetUserLanguage = (language) => {
	return fs
		.collection('users')
		.doc(auth.currentUser.uid)
		.update({ language: language })
}
