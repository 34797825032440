const INITIAL_STATE = {
	editUser: null,
	userKey: null
}
/**
 * Manages the account edit form state
 * @author Davi Wegner
 * @version 1.0
 */
function userEditFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_SELECTED_EDIT_USER': {
			return Object.assign({}, state, action.user)
		}
		case 'UPDATE_SELECTED_EDIT_USER': {
			return Object.assign({}, state, {
				editUser: {
					[state.userKey]: Object.assign(
						{},
						state.editUser[state.userKey],
						action.info
					)
				}
			})
		}
		case 'CLEAR_SELECTED_EDIT_USER': {
			return Object.assign({}, state, { editUser: action.user })
		}
		default:
			return state
	}
}

export default userEditFormReducer
