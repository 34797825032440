import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Tab, Button } from '@material-ui/core'
import AgilTabs from '../../../agilTabs/AgilTabs'
import styles from './styles'
import withFilterButton from '../../../withFilterButton'
import { translate } from '../../../../../utils/lang'
import TeamListContainer from '../../list/TeamListContainer'
import { REVERSE_TEAM_LIST } from '../../../../../reducers/team/list'
import { getTeamListStatus } from '../../../../../selectors/team'
import { CHANGE_TEAM_LIST_TURN } from '../../../../../reducers/team/list/status'
import {
	CHANGE_TEAM_LIST_COMPARATOR,
	CHANGE_TEAM_LIST_ACTIVE_STATE,
	LOAD_TEAM_LIST_FILTER_VALUE
} from '../../../../../reducers/team/list'
import OwnFiltersDialog from '../ownFiltersDialog'

/**
 * Manages each part of the team
 *
 * @description Created the component
 * @author wegner|spindola
 * @version 1.0
 *
 * @description Renaming & remaking the component to make it more literal, reusable and organised
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @author brunoteixeirasilva
 * @description Removing filter button, adding withFilterButton HOC.
 * @version 1.2
 *
 * @author Davi Wegner
 * @description added tabs funtionalities, for logged and unlogged teams
 * @version 1.3
 *
 * @author Davi Wegner
 * @description added componentDidMount block for cleaning team detail page data, for securing no data error
 * @version 1.4
 *
 * @author brunoteixeirasilva
 * @description Removed usage of old version filters
 * @version 1.5
 */
class DailyTeamSummaryContainer extends React.Component {
	handleChange = (event, value) => {
		const { changeTurn } = this.props

		changeTurn(!Boolean(value))
	}
	state = {
		moreFilters: null
	}

	openMoreFilters = () => {
		this.setState({ moreFilters: true })
	}

	handleDialog = () => {
		//TODO: remove local state management if possible
		this.setState({ moreFilters: !Boolean(this.state.moreFilters) })
	}
	componentDidMount() {
		// console.log(this.props)
	}

	render() {
		const {
			classes,
			tabIndex,
			selectedValues,
			ownFilters,
			loadFilter,
			stepManager,
			filters,
			setActiveState,
			reverseTeams,
			dataSets,
			dataSourceId,
			comparers,
			setComparators,
			mobile,
			draggableContainer,
			onToggleModal,
			children
		} = this.props
		const { moreFilters } = this.state
		return (
			<div className={classes.root}>
				<AgilTabs
					activeTabIndex={tabIndex}
					handleChange={this.handleChange}
					tabs={[
						<Tab value={0} label={translate('label/open-today')} />,
						<Tab value={1} label={translate('label/not-open')} />
					]}
					{...!mobile && {
						buttons: [
							<Button
								className={classes.filters}
								onClick={this.openMoreFilters}
							>
								{translate('label/more-filters')}
							</Button>
						]
					}}
				/>
				{React.cloneElement(children)}
				<TeamListContainer
					dataSourceId={dataSourceId}
					dataSets={dataSets}
					stepManager={stepManager}
					draggableContainer={draggableContainer}
					mobile={mobile}
					selectedValues={selectedValues}
					classes={classes}
					onToggleModal={onToggleModal}
					handleDialog={this.handleDialog}
				/>
				<OwnFiltersDialog
					teamList
					open={!!moreFilters}
					handleClose={this.handleDialog}
					selectedValues={selectedValues}
					filters={filters}
					setSelected={setActiveState}
					ownFilters={ownFilters}
					loadData={loadFilter}
					comparers={comparers}
					setComparators={setComparators}
					reverseTeams={reverseTeams}
					dataSourceId={dataSourceId}
				/>
			</div>
		)
	}
}

const makeMapStateToProps = () => {
	const mapStateToProps = (state, { ownFilters }) => {
		const tabIndex = !!getTeamListStatus(state) ? 0 : 1
		// let ownFiltersUpdated = Object.assign({}, ownFilters, {})
		return {
			//order: state.listPaginationState.order,
			filters: state.teamListState.filters,
			selectedValues: state.teamListState.selectedValues,
			comparers: state.teamListState.comparers,
			tabIndex: tabIndex
		}
	}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	reverseTeams: () =>
		dispatch({
			type: REVERSE_TEAM_LIST
		}),
	changeTurn: (logged) => dispatch({ type: CHANGE_TEAM_LIST_TURN, logged }),
	setActiveState: (key, values) =>
		dispatch({
			type: CHANGE_TEAM_LIST_ACTIVE_STATE,
			key,
			values,
			update: true
		}),
	setComparators: (key, values) =>
		dispatch({
			type: CHANGE_TEAM_LIST_COMPARATOR,
			key,
			values
		}),

	loadFilter: (filterKey, value) =>
		dispatch({ type: LOAD_TEAM_LIST_FILTER_VALUE, filterKey, value })
})

export default compose(
	withFilterButton,
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	),
	withRouter,
	withStyles(styles)
)(DailyTeamSummaryContainer)
