import React from 'react'
import { Button, withStyles } from '@material-ui/core'
import { translate } from '../../../../../utils/lang'
import styles from './styles'
/**
 * This functional component only displays the button of evaluation in user UI. Controlled by his container.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 */
const EvaluationButton = (props) => {
	const { isVisible, handleClick, classes } = props

	return (
		<Button
			className={isVisible ? classes.buttonEvaluated : classes.button}
			size="small"
			color={isVisible ? 'secondary' : 'primary'}
			// disabled={isVisible}
			onClick={handleClick}
			children={
				isVisible
					? translate('label/evaluated')
					: translate('label/evaluate')
			}
		/>
	)
}

export default withStyles(styles)(EvaluationButton)
