import { fs } from '../firebase'
/**
 *
 * @description Add datas on collection 'logs' from download page
 * @author Ray
 * @version 1.3
 */
export const addDownloadLogs = (fileName, userId, activeCompanyId) => {
	return fs.collection('logs').doc().set({
        fileName: fileName,
        time: new Date(),
        uid: userId,
        companyId: activeCompanyId,
        type: 'download'
    }).catch((error) => {
        console.error('Error to adding download log: ', error)
    })
}
