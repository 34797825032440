import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { CommonChartPropTypes } from './propTypes'
import WidthTip from './widthTip'

/**
 * Manages to display a system-default chart (based on a component you specify)
 *
 * @description Created this reusable chart component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Changed to accept auto-width
 * @author brunoteixeirasilva
 * @version 1.1
 */
class CommonChart extends React.PureComponent {
	//Creates the default state
	state = {
		width: 0,
		height: 0,
		aspectRatio: 1
	}

	constructor(props) {
		super(props)

		//Creates the chart and chart-wrapper ref
		this.chartWrapper = React.createRef()
		this.chart = React.createRef()
	}

	componentDidMount() {
		//Runs the first size check
		this.resize(null)

		//window.addEventListener('load', this.resize)
		window.addEventListener('resize', this.resize)
	}

	componentWillUnmount() {
		//window.removeEventListener('load', this.resize)
		window.removeEventListener('resize', this.resize)
	}

	resize = (event) => {
		const { autoWidth, height, width } = this.props,
			wrapper = this.chartWrapper.current,
			node = ReactDOM.findDOMNode(wrapper),
			clientWidth = node.clientWidth

		let newWidth = !!autoWidth && clientWidth > 0 ? clientWidth : width,
			// let newWidth = !!autoWidth ? clientWidth : width,
			newHeight = height,
			newAspectRatio = newWidth / newHeight

		//Will apply a state-based correction
		this.setState({
			width: newWidth,
			height: newHeight,
			aspectRatio: newAspectRatio
		})

		//When the instance is available, invokes the resizer
		if (!!this.chart.current) this.chart.current.chartInstance.resize()
	}

	render() {
		const { Component, classes, data, options, autoWidth } = this.props
		const { height, width, aspectRatio } = this.state
		const opt = { ...options, aspectRatio: aspectRatio }

		return (
			<div
				ref={this.chartWrapper}
				className={!autoWidth ? classes.chart : classes.chartFullWidth}
				style={{
					width: `${!autoWidth && width > 0 ? width + 'px' : '100%'}`,
					height: `${height > 0 ? height + 'px' : '100%'}`
				}}
			>
				{width === 0 && !autoWidth && <WidthTip />}
				{width > 0 && (
					<Component ref={this.chart} data={data} options={opt} />
				)}
			</div>
		)
	}
}

const styles = (theme) => ({
	chart: {
		overflowX: 'auto',
		direction: 'rtl'
	},
	chartFullWidth: {
		overflowX: 'hidden',
		direction: 'rtl',
		// width: '100%',
		position: 'relative'
	}
})

//Keeps code DRYer
CommonChart.defaultProps = {
	autoWidth: true,
	height: 350,
	width: 350
}

//Associates the propTypes with the modularized one
CommonChart.propTypes = {
	...CommonChartPropTypes,
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CommonChart)
