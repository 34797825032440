/**
 * Applies the collection of plugin types to the state tree
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {*} state The current whole app state tree
 * @param {*} action The action with "type" and desired props
 */
export const PLUGIN_TYPES_LOADED = 'PLUGIN_TYPES_LOADED'

const INITIAL_STATE = {
	pluginTypes: null
}
/**
 * Applies the collection of plugin types to the state tree
 *
 * @description Created the applier
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {*} state The current whole app state tree
 * @param {*} action The action with "type" and desired props
 */
const pluginTypeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PLUGIN_TYPES_LOADED:
			return Object.assign({}, state, { pluginTypes: action.pluginTypes })
		default:
			return state
	}
}

export default pluginTypeReducer
