export const MANAGE_MIN_TEMPERATURE_LOADING_STATE =
		'MANAGE_MIN_TEMPERATURE_LOADING_STATE',
	LOAD_MIN_TEMPERATURE_DATA = 'LOAD_MIN_TEMPERATURE_DATA',
	SET_MIN_TEMPERATURE_PERIOD = 'SET_MIN_TEMPERATURE_PERIOD'

export const setMinTemperaturePeriod = (dateConfig, value, formType) => ({
	type: SET_MIN_TEMPERATURE_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadMinTemperatureData = (minTemperature) => ({
	type: LOAD_MIN_TEMPERATURE_DATA,
	minTemperature
})

export const setLoading = (isLoading) => ({
	type: MANAGE_MIN_TEMPERATURE_LOADING_STATE,
	isLoading
})
