import {
	CLOSE_NOTIFICATION_MODAL,
	OPEN_NOTIFICATION_MODAL
} from './../../../component/app/userNotifications/actions'

const INITIAL_STATE = {
	showNotificationModal: false,
	notificationRef: '',
	authorInfo: null,
	notifications: null,
	unsubscribe: null
}

/**
 * reducer responsible for showing content in application
 * @param {*} state
 * @param {*} action
 */
function noitificationNavigationReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case OPEN_NOTIFICATION_MODAL:
			return Object.assign({}, state, {
				showNotificationModal: action.showNotificationModal,
				notificationRef: action.notificationRef
			})
		case CLOSE_NOTIFICATION_MODAL:
			return Object.assign({}, state, {
				showNotificationModal: INITIAL_STATE.showNotificationModal
			})
		case 'NAVIGATION_NOTIFICATION_ON_CHANGE':
			return Object.assign({}, state, {
				notifications: Object.assign(
					{},
					state.notifications,
					action.notification
				)
			})
		case 'NAVIGATION_NOTIFICATION_ON_SUBSCRIBE':
			return Object.assign({}, state, {
				unsubscribe: action.subscribe
			})
		default:
			return state
	}
}

export default noitificationNavigationReducer
