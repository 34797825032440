const INITIAL_STATE = {
	abbrev: null,
	cnpj: null,
	name: null,
	author: null,
	companyId: null,
	saving: false,
	owners: null,
	selectedItems: [],
	users: null,
	logo: null
}

/**
 * Manages company form state
 * @author Davi Wegner
 * @param {Object} state
 * @param {Object} action
 * @version 1.0
 */
function companyFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_COMPANY_FORM_SAVING': {
			return Object.assign({}, state, { saving: action.saving })
		}
		case 'SET_COMPANY_FORM_VALUE': {
			return Object.assign({}, state, { ...action.value })
		}
		case 'SET_COMPANY_FORM_USERS': {
			return Object.assign({}, state, {
				users: Object.assign({}, state.users, action.user)
			})
		}
		case 'SET_SELECTED_COMPANY': {
			return Object.assign({}, state, { companyId: action.companyId })
		}
		case 'CLEAN_COMPANY_FORM': {
			return Object.assign({}, state, INITIAL_STATE)
		}
		default:
			return state
	}
}

export default companyFormReducer
