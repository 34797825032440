import { createSelector } from 'reselect'

const makeNotificationCounter = () => {
	return createSelector(
		[getNotifications],
		(notifications) => {
			if (!notifications) return null
			let count = 0
			Object.values(notifications).forEach((notification) => {
				if (notification.active) count += 1
			})
			if (count === 0) return null
			if (count > 9) return '9+'
			return count
		}
	)
}

const getNotifications = (state) => state.notificationState.notifications

export { makeNotificationCounter }
