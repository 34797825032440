import React from 'react'
import { ListItem, ListItemIcon, Icon, ListItemText } from '@material-ui/core'
import FakeBlock from './FakeBlock'

/**
 * Created an empty fake list-item
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Removed fake block to another file
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} param0 Definitions of the faker list item
 */
const FakeListItem = ({ primary = true, secondary = true, icon = false }) => (
	<ListItem
		style={{
			// background: 'rgba(242, 242, 242, 0.7)',
			filter: 'blur(1px)'
		}}
	>
		{!!icon && (
			<ListItemIcon>
				<Icon>
					<FakeBlock type="icon" />
				</Icon>
			</ListItemIcon>
		)}
		<ListItemText
			primary={!!primary ? <FakeBlock /> : undefined}
			//Secondary typography error solved:
			//Required to be a div for stopping an error occuring
			secondaryTypographyProps={{ component: 'div' }}
			secondary={!!secondary ? <FakeBlock type="secondary" /> : undefined}
		/>
	</ListItem>
)

export default FakeListItem
