export const MANAGE_TREND_FORECAST_LOADING_STATE =
		'MANAGE_TREND_FORECAST_LOADING_STATE',
	LOAD_TREND_FORECAST_DATA = 'LOAD_TREND_FORECAST_DATA',
	SET_TREND_FORECAST_PERIOD = 'SET_TREND_FORECAST_PERIOD'


export const setTrendForecastPeriod = (dateConfig, value, formType) => ({
	type: SET_TREND_FORECAST_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadTrendForecastData = (trendForecast) => ({
	type: LOAD_TREND_FORECAST_DATA,
	trendForecast
})

export const setLoading = (isLoading) => ({
	type: MANAGE_TREND_FORECAST_LOADING_STATE,
	isLoading
})
