import React, { Component } from 'react'
import { connect } from 'react-redux'
import PieChart from '../../graphs'
import GraphContainer from '../../graphContainer'
import { makeActionOverviewFormatter } from '../../../../selectors/action/stats'
import { Typography } from '@material-ui/core'
import { translate } from '../../../../utils/lang'
/**
 * Action status overview card container
 * @description created the class component
 * @author Wegner
 * @version 1.0
 */
class ActionStatusOverview extends Component {
	render() {
		const { dashItemId, stepId, perc, callbacks } = this.props
		return (
			<React.Fragment>
				<Typography variant="h6">
					{translate('label/generated-and-received-actions')}
				</Typography>
				<GraphContainer
					defaultHidden
					dashItemId={dashItemId}
					stepId={stepId}
				>
					<PieChart perc={perc} callbacks={callbacks} />
				</GraphContainer>
			</React.Fragment>
		)
	}
}

ActionStatusOverview.defaultProps = {
	dashItemId: 'actionOverview',
	stepId: 'actionOverview'
}
const makeMapStateToProps = () => {
	const getActionOverviewData = makeActionOverviewFormatter()
	const mapStateToProps = (state, ownProps) => ({
		...getActionOverviewData(state, ownProps)
	})
	return mapStateToProps
}
export default connect(makeMapStateToProps())(ActionStatusOverview)
