import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '..'

export function openActionModal() {

	return {
		type: OPEN_ACTION_MODAL
	}
}

export function closeActionModal() {
	return {
		type: CLOSE_ACTION_MODAL
	}
}
