import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { Icon, Menu, MenuItem, withStyles, Fab } from '@material-ui/core'
import DialogModal from './../../../app/userActions/components/DialogModal'
import withMappedDom from '../../withMappedDom'
import { translate } from '../../../../utils/lang'
import styles from './styles'
import { openActionModal } from '../../../../reducers/action/navigation'
import { deviceIsIos } from '../../../../utils/env'

/**
 * Manages to display an action-generator button
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Changed component location and naming convention
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Changed component usage to Fab
 * @author brunoteixeirasilva
 * @version 1.2
 */
class ActionButton extends React.Component {
	state = {
		anchorEl: null
	}

	componentWillUnmount() {
		localStorage.setItem('url-param', '')
	}

	handleClick = (event) => this.setState({ anchorEl: event.currentTarget })

	handleClose = () => this.setState({ anchorEl: null })

	render() {
		const { openActionModal, json, node, classes } = this.props
		const { anchorEl } = this.state

		return (
			<div>
				<Fab
					onClick={this.handleClick}
					aria-haspopup="true"
					variant="round"
					aria-label="action"
					className={classNames(classes.button, {
						ios: deviceIsIos()
					})}
				>
					<Icon>add</Icon>
				</Fab>
				{/* <Button
					onClick={this.handleClick}
					aria-haspopup="true"
					variant="fab"
					aria-label="action"
					className={classes.button}
				>
					<Icon>add</Icon>
				</Button> */}
				<Menu
					id="context-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
				>
					<MenuItem
						onClick={() => {
							openActionModal()
							this.props.getJSON(json)
							this.props.getDomSnapshot(node)
						}}
					>
						{translate('label/open-action')}
					</MenuItem>
					<DialogModal />
				</Menu>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	openActionModal: () => dispatch(openActionModal())
})

export default compose(
	connect(
		null,
		mapDispatchToProps
	),
	withRouter,
	withStyles(styles),
	withMappedDom
)(ActionButton)
