import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContentText,
	DialogContent
} from '@material-ui/core'

import { translate } from './../../../../../utils/lang/index'
import * as NotificationResponses from '../../../../../firebase/notifications'
import withAuthentication from '../../../../reusable/withAuthentication'
import { closeActionModal } from '../../../../../reducers/action/navigation'
import withActiveCompany from '../../../../reusable/withActiveCompany'
import { actionOpenPopup } from '../../../../../reducers/systemEvaluation/popup'

const CloseActionDialog = ({
	onClose,
	handleCloseAction,
	closeActionModal,
	title,
	message,
	actionId,
	handleActive,
	...other
}) => (
	<Dialog onClose={onClose} {...other}>
		<DialogTitle id="generic-dialog">{translate(title)}</DialogTitle>
		<DialogContent>
			<DialogContentText>{translate(message)}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>{translate('label/cancel')}</Button>
			<Button
				variant="outlined"
				onClick={() => {
					handleCloseAction()
					handleActive(actionId)
					closeActionModal()
				}}
			>
				{translate('label/confirm')}
			</Button>
		</DialogActions>
	</Dialog>
)

/**
 * CloseActionButton
 *
 * @author Davi Spindola
 * @version 0.0.1
 *
 * Responsible for close especified actions/notifications
 *
 */
class CloseActionButton extends React.PureComponent {
	state = {
		open: false,
		isAuthor: false
	}

	componentDidMount() {
		const { activeAction, authUser } = this.props
		if (!!authUser && authUser.uid !== undefined) {
			if (activeAction.author === authUser.uid)
				this.setState({ isAuthor: true })
		}
	}

	closeActionIssue = () => {
		const { actionId, activeCompanyId, authUser } = this.props
		if (!!authUser && undefined !== authUser.uid) {
			NotificationResponses.closeNotificationResponses(
				activeCompanyId,
				actionId
			)
			this.setState({ open: false })
		}
	}

	openDialog = () => this.setState({ open: true })

	closeDialog = () => this.setState({ open: false })

	handleActionActiveToFalse = (actionId) => {
		const { setActionActiveToFalse, openEvaluationPopup } = this.props

		setActionActiveToFalse(actionId).then(() => {
			openEvaluationPopup(true)
		})
	}

	render() {
		const { classes, closeActionModal, actionId } = this.props

		const { open, isAuthor } = this.state

		return (
			<div style={{ padding: 10 }}>
				{isAuthor && (
					<CloseActionDialog
						handleCloseAction={this.closeActionIssue}
						actionId={actionId}
						handleActive={this.handleActionActiveToFalse}
						open={open}
						onClose={this.closeDialog}
						title="title/close-action"
						message="label/are-you-sure-you-want-to-close-this-action"
						closeActionModal={closeActionModal}
					/>
				)}
				<Button
					variant="outlined"
					fullWidth
					className={classes.button}
					onClick={this.openDialog}
					disabled={!isAuthor}
				>
					{translate('label/finish-action')}
				</Button>
			</div>
		)
	}
}

const styles = {
	button: {
		margin: 0
	}
}

CloseActionButton.propType = {
	classes: PropTypes.object.isRequired
}

// TODO: inject state props in component
const mapStateToProps = (state) => ({
	// actionId: state.actionsState.actionId
})

const mapDispatchToProps = (dispatch) => ({
	closeActionModal: () => dispatch(closeActionModal()),
	setActionActiveToFalse: async (actionId) =>
		dispatch({ type: 'SET_ACTION_ACTIVE_TO_FALSE', actionId }),
	openEvaluationPopup: (isOpen) => dispatch(actionOpenPopup(isOpen))
})

export default compose(
	withAuthentication,
	withActiveCompany,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)(CloseActionButton)
