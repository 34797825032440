const INITIAL_STATE = {
	permissions: null,
	selectablePermissions: null
}

/**
 * Manages group permissions form state
 *
 * @param {Object} state
 * @param {Object} action
 */
function groupPermissionFormReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_GROUP_PERMISSION_FORM_PERMISSIONS': {
			//Creates a new version of the state - adding an item to the array
			return Object.assign({}, state, {
				permissions: action.permissions
			})
		}
		case 'GROUP_PERMISSION_FORM_SELECTABLE_PERMISSIONS_LOADED': {
			//Creates a new version of the state - adding an item to the array
			return Object.assign({}, state, {
				selectablePermissions: Object.assign(
					{},
					state.selectablePermissions,
					action.selectablePermissions
				)
			})
		}
		case 'REMOVE_GROUP_PERMISSION': {
			//Creates a new version of the state - adding an item to the array
			throw Error('Not implemented')
			// return Object.assign({}, state, {
			// 	users: Object.assign({}, state.users, action.user)
			// })
		}
		case 'ADD_GROUP_PERMISSION': {
			//Creates a new version of the state - adding an item to the array
			return Object.assign({}, state, {
				permissions: Object.assign(
					{},
					state.permissions,
					action.permission
				)
			})
		}
		default:
			return state
	}
}

export default groupPermissionFormReducer
