const styles = (theme) => ({
	root: {
		flexGrow: 1,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		// minWidth: 320,
		// [theme.breakpoints.down('sm')]: {
		// 	minWidth: '50%'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	minWidth: '70%'
		// }
		width: '100%'
	},
	rootFullWidth: {
		flexGrow: 1,
		paddingLeft: theme.spacing.unit * 3,
		paddingRight: theme.spacing.unit * 3
	},
	activatingText: {
		textAlign: 'center',
		marginBottom: theme.spacing.unit
	},
	formControl: {
		//margin: theme.spacing.unit,
		marginBottom: theme.spacing.unit * 2
	}
})

export default styles
