export const MANAGE_BLOCK_VOLTAGE_LOADING_STATE = 'MANAGE_BLOCK_VOLTAGE_LOADING_STATE',
    LOAD_BLOCK_VOLTAGE_DATA = 'LOAD_BLOCK_VOLTAGE_DATA',
    SET_BLOCK_VOLTAGE_PERIOD = 'SET_BLOCK_VOLTAGE_PERIOD'

export const setBlockVoltagePeriod = (dateConfig, value, formType) => ({
    type: SET_BLOCK_VOLTAGE_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadBlockVoltageData = (data) => ({
    type: LOAD_BLOCK_VOLTAGE_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_BLOCK_VOLTAGE_LOADING_STATE,
    isLoading
})