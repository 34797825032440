import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Modal, Typography, Icon } from '@material-ui/core'
import withMappedDom from '../../../reusable/withMappedDom'
import ActionForm from './ActionForm'
import ActionFormLight from './ActionFormLight'
import { CLEAR_ELEMENTS_FORM } from '../../../../reducers/action/form'
import { closeActionModal } from '../../../../reducers/action/navigation'
import { translate } from '../../../../utils/lang'
import { makeGetWidths } from '../../../../utils/object/theme/modal'

const getWidths = makeGetWidths()

const styles = (theme) => ({
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	content: {
		background: 'white',
		...getWidths(theme),
		maxHeight: '98vh',
		borderRadius: theme.shape.borderRadius,
		'& > #header, h1': {
			padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
			background: theme.palette.primary.light,
			color: 'white',
			borderRadius: `${theme.spacing.unit}px ${theme.spacing.unit}px 0 0`,
			flexDirection: 'row',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			'& > *': {
				flex: '0 0 auto'
			},
			'& > span': {
				cursor: 'pointer'
			}
		},
		'& > div ': {
			padding: theme.spacing.unit
		}
	},
	header: {
		height: 48
	},
	title: {
		color: 'white'
	}
})

/**
 * Modal dialog container
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0.0
 *
 * @description Remapped access to localStorage
 * @author brunoteixeirasilva
 * @version 1.1.0
 * 
 * @description added conditions from light form
 * @author jeffersonguilhermemachadobarreto
 * @version 1.2
 */
class DialogModal extends React.Component {
	state = {
		isLight: false
	}
	componentDidMount() {
		const { getURLPath, match } = this.props
		getURLPath(match.params.dashItemId)
		// getDomSnapshot(node)
	}

	componentWillUnmount() {
		window.localStorage.removeItem('url-param')
		//setItem('url-param', '')
	}

	closeInspection = () => {
		const {
			onClose,
			onSetShowModalForm,
			onSetOpenForm,
			clearElementsForm,
			onSetIsAction,
			onSetIsLight
		} = this.props

		onClose()
		clearElementsForm()
		onSetIsLight(false)
		onSetOpenForm(false)
		onSetShowModalForm(false)
		onSetIsAction(false)
	}

	render() {
		const { 
			classes,
			modalState,
			onClose,
			cardId,
			inspection,
			isAction,
			isLight
		} = this.props
		return (
			<React.Fragment>
				{!!isLight && isAction === false ?
				<Modal
					className={classes.modal}
					open={modalState}
					onClose={onClose}
				>
					<div className={classes.content}>
						<div className={classes.header} id="header">
							<Typography className={classes.title} variant="h6">
								{`${translate('label/finish-inspection')}: ${inspection.instalation}`}
							</Typography>
							<Icon onClick={this.closeInspection}>close</Icon>
						</div>
						<ActionFormLight cardId={cardId} />
					</div>
				</Modal>
				:
				!isLight && isAction === true ?
				<Modal
					className={classes.modal}
					open={modalState}
					onClose={onClose}
				>
					<div className={classes.content}>
						<div className={classes.header} id="header">
							<Typography className={classes.title} variant="h6">
								{translate('label/opening-action')}
							</Typography>
							<Icon onClick={this.closeInspection}>close</Icon>
						</div>
						<ActionForm isAction={isAction} cardId={cardId} />
					</div>
				</Modal>
				:
				<div />
				}
			</React.Fragment>
		)
	}
}

DialogModal.defaultProps = {
	modalState: false
}

DialogModal.propTypes = {
	classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
	modalState: state.actionsState.showActionModal,
	showModalForm: state.lightState.showModalForm,
	isLight: state.lightState.isLight,
	inspection: state.lightState.inspection,
	openForm: state.lightState.openForm,
	isAction: state.lightState.isAction
})

const mapDispatchToProps = (dispatch) => ({
	//TODO: @brunoteixeirasilva finish implementation of this
	//onClearCardId: () => dispatch(clearActionCardId()),
	onClose: () => dispatch(closeActionModal()),
	onSetIsLight: (value) => dispatch({type:"SET_IS_LIGHT", value}),
	onSetShowModalForm: async (showModalForm) =>
		dispatch({ type: 'SET_SHOW_MODAL_FORM',  showModalForm}),
	onSetOpenForm: async (openForm) =>
		dispatch({ type: 'SET_OPEN_FORM',  openForm}),
	clearElementsForm: () => dispatch({ type: CLEAR_ELEMENTS_FORM }),
	onSetIsAction: async (isAction) =>
		dispatch({ type: 'SET_IS_ACTION',  isAction}),
})

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withMappedDom,
	withRouter,
	withStyles(styles)
)(DialogModal)
