import React from 'react'
import { compose } from 'recompose'
import {
    Modal,
    withStyles,
    Typography
} from '@material-ui/core'

class ModalInfo extends React.Component {
    render() {

        const { classes, openModal, onCloseModal, dashItem } = this.props

        return(
            <Modal
                className={classes.modal}
                open={openModal}
                onClose={(() => onCloseModal())}
            >
                <div className={classes.content}>
                    <div className={classes.head} id='head'>
                        Resumo
                    </div>
                    <div className={classes.body} id='body'>
                        {
                            !!dashItem.detail
                            ?
                            dashItem.detail.map((desc) => {
                                return (
                                    <Typography className={classes.description} id='description'>
                                        {desc}
                                    </Typography>
                                )
                            })
                            :
                            <div>
                                <Typography className={classes.description} id='description'>
                                    • Distribuição geográfica dos checklists realizados
                                </Typography>
                                <Typography className={classes.description} id='description'>
                                    • Mapa de calor das não conformidades encontradas nos checklists
                                </Typography>
                                <Typography className={classes.description} id='description'>
                                    • Análise percentual das ações resolvidas decorrentes das não conformidades identificadas
                                </Typography>
                                <Typography className={classes.description} id='description'>
                                    • Verificação do desempenho dos itens específicos do checklist que apresentaram não conformidades.
                                </Typography>
                            </div>
                        }
                        {/* <Typography className={classes.description} id='description'>
                            • Distribuição geográfica dos checklists realizados
                        </Typography>
                        <Typography className={classes.description} id='description'>
                            • Mapa de calor das não conformidades encontradas nos checklists
                        </Typography>
                        <Typography className={classes.description} id='description'>
                            • Análise percentual das ações resolvidas decorrentes das não conformidades identificadas
                        </Typography>
                        <Typography className={classes.description} id='description'>
                            • Verificação do desempenho dos itens específicos do checklist que apresentaram não conformidades.
                        </Typography> */}
                    </div>
                </div>
            </Modal>
        )
    }
}

const style = (theme) => ({
    modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',		
	},
    content: {
		width: '90%',
		maxHeight: '80%',
		overflowY: 'scroll',
		'& > #head': {
			display: 'flex',
			alignItems: 'center',
    		justifyContent: 'space-around',
			backgroundColor: '#5731AC',
            padding: '10px',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '6px 6px 0px 0px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '1.25rem',
            fontWeight: '500',
            color: '#FFF',
            '& > *': {
                flex: '0 0 auto'
            },
            '& > span': {
                cursor: 'pointer'
            },
		},
		'& > #body, h1': {
			padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
			backgroundColor: '#FFF',
			display: 'grid',
			overflowX: 'hidden',
            borderRadius: '0px 0px 6px 6px',
            '& > #description': {
                fontSize: '1rem',
                padding: '10px'
            }
		}
	},
})

export default compose(
	withStyles(style)
)(ModalInfo)