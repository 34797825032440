export const MANAGE_PERFORMED_AUDITS_LOADING_STATE =
		'MANAGE_PERFORMED_AUDIT_LOADING_STATE',
	LOAD_PERFORMED_AUDITS_DATA = 'LOAD_PERFORMED_AUDITS_DATA',
	SET_PERFORMED_AUDITS_PERIOD = 'SET_PERFORMED_AUDITS_PERIOD'


export const setPerformedAuditsPeriod = (dateConfig, value, formType) => ({
	type: SET_PERFORMED_AUDITS_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadPerformedAuditsData = (performedAudits) => ({
	type: LOAD_PERFORMED_AUDITS_DATA,
	performedAudits
})

export const setLoading = (isLoading) => ({
	type: MANAGE_PERFORMED_AUDITS_LOADING_STATE,
	isLoading
})
