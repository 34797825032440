import {
    MANAGE_SOLAR_POWER_LOADING_STATE,
    LOAD_SOLAR_POWER_DATA,
    SET_SOLAR_POWER_PERIOD,
    SET_SOLAR_POWER_VALUE
} from '../../actions/solarPower'

const INITIAL_STATE = {
    isLoading: null,
    solarPower: null,
    dateConfig: {},
    update: false,
    value: null,
    dateType: {}
}
/**
 * solar power state reducer manager
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const solarPowerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_SOLAR_POWER_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case SET_SOLAR_POWER_VALUE: {
            return Object.assign({}, state, {
                value: action.text
            })
        }
        case LOAD_SOLAR_POWER_DATA: {
            return Object.assign({}, state, {
                solarPower: action.solarPower,
                isSelect: false
            })
        }
        case SET_SOLAR_POWER_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        case 'SET_PERIOD_TYPE': {
			return Object.assign({}, state, {
				dateType: Object.assign({}, state.dateType, {
					[action.stepId]: action.value
				})
			})
		}
        default:
            return state
    }
}

export default solarPowerReducer
