import React from 'react'
import classNames from 'classnames'
import { isWidthUp } from '@material-ui/core/withWidth'
import { withRouter } from 'react-router-dom'
import { SnackbarContainer } from '../reusable/snackbar'
import { Navigation } from '../reusable/navigation'
import { deviceIsIos } from '../../utils/env'
import Pages from '../../constants/Pages'
import { connect } from 'react-redux'
import '../css/app.css'
import '../css/base.css'
import CompanySelectContainer from '../reusable/company/select/CompanySelectContainer'
// import PinchToZoom from "react-pinch-and-zoom";
// import { doSignOut } from "../../firebase/auth";
import { fs } from '../../firebase/firebase'
// import withTracking from './tracking'
import { compose } from 'recompose'
import withLogger from '../reusable/withLogger'
// import { fs } from '../../firebase/firebase'
const getCurrentPosition = auth => {
	return setTimeout(() => {
		navigator.geolocation.getCurrentPosition(position => {
			fs.collection('userPosition')
				.doc()
				.set({ coords: position.coords, auth: auth.uid })
		})
		getCurrentPosition(auth)
	}, 300000)
}
const App = ({
	width,
	renderMenu,
	requireCompanyActivation,
	history,
	authUser,
	userLogout,
	currentUserStatus,
	activeCompanyId,
	location,
	...otherProps
}) => {
	if (window.cordova) {
		window.FirebasePlugin.onMessageReceived(notification => {
			// alert(JSON.stringify(notification))
			// alert(Object.keys(notification).toString())
			if (notification.tap === 'background') {
				if (notification.redirectTo)
					history.push(notification.redirectTo)
				if (notification.redirectToExternal)
					window.open(notification.redirectToExternal, '_blank')
			}
		})

		// window.cordova.plugins.backgroundMode.moveToBackgroud()

		// window.cordova.plugins.backgroundMode.overrideBackButton()

		// .subscribe()
	}

	return (
		<div className="base">
			{authUser && <Navigation />}
			{renderMenu()}
			{/* {<div>{!!window.FCMPlugin}</div>} */}
			{/* <Slide direction="up" in={!loading} mountOnEnter unmountOnExit> */}
			<div
				className={classNames('app', {
					ios: deviceIsIos(),
					iosLandscape: isWidthUp('sm', width),
				})}>
				<Pages
					location={location}
					authUser={authUser}
					activeCompanyId={activeCompanyId}
				/>
			</div>
			{/* </Slide> */}
			{requireCompanyActivation && <CompanySelectContainer fullwidth />}
			<SnackbarContainer />
		</div>
	)
}

const mapStateToProps = state => ({
	activeCompanyId: state.currentUserState.activeCompany,
	location: window.location,
})

export default compose(
	withRouter,
	// withTracking,
	withLogger('acessed', true),
	connect(mapStateToProps),
)(App)
