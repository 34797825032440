import { createSelector } from 'reselect'

/**
 * Gets the company dash-item _steps_ **collection**, by dash-item-ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 */
export const getCompanyDashItemStepsCollection = (state) =>
	state.companyDashItemStepState.steps

/**
 * Gets the company dash-item _steps_ **collection**, by dash-item-ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 * @param {string} dashItemId The dash-item-ID
 */
export const getCompanyDashItemSteps = (state, dashItemId) => {
	const allDashItemsSteps = getCompanyDashItemStepsCollection(state)

	return !!dashItemId &&
		!!allDashItemsSteps &&
		!!allDashItemsSteps.hasOwnProperty(dashItemId)
		? allDashItemsSteps[dashItemId]
		: null
}

/**
 * Gets the company dash-item **step-unit**, by dash-item-ID and step-ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 * @param {string} dashItemId The dash-item-ID
 * @param {string} stepId The specific step-ID
 */
const getCompanyDashItemStepById = (state, dashItemId, stepId) => {
	const dashItemsSteps = getCompanyDashItemSteps(state, dashItemId)

	return !!dashItemId &&
		!!stepId &&
		!!dashItemsSteps &&
		dashItemsSteps.hasOwnProperty(stepId)
		? dashItemsSteps[stepId]
		: null
}

/**
 * [memoised] Gets the company dash-item **step-unit**, by dash-item-ID and step-ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 * @param {string} dashItemId The dash-item-ID
 * @param {string} stepId The specific step-ID
 */
export const makeGetCompanyDashItemStepById = () =>
	createSelector(
		getCompanyDashItemStepById,
		(step) => step
	)

/**
 * Gets the company dash-item _step-level_ **allowed-filters**, by dash item ID and step ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 * @param {string} dashItemId The specific dash-item-ID
 * @param {string} stepId The specific step-ID
 */
export const getCompanyDashItemStepAllowedFilters = (
	state,
	dashItemId,
	stepId
) => {
	const dashItemStep = getCompanyDashItemStepById(state, dashItemId, stepId)

	return !!dashItemStep ? dashItemStep.allowedFilters : null
}

/**
 * [memoised] Selects the _step-level_ specific **allowed-filters** collection
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompanyDashItemStepAllowedFilters = () =>
	createSelector(
		getCompanyDashItemStepAllowedFilters,
		(allowedFilters) => allowedFilters
	)
