import React from 'react'

// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core'
//import { SvgIcon } from '@material-ui/core';

const styles = (theme) => ({
	logoContainer: {
		fontSize: 10,
		height: '10em',
		width: 'auto',
		marginTop: '0.1em',
		marginBottom: '0.1em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	logoImage: {
		height: '100%',
		flex: '0 0 auto',
		color: 'rgba(255,255,255,1)',
		animation: 'app-logo-spin 1.0s ease-in-out'
	}
})

// const INITIAL_CONFIG = {
// 	src: ''
// }

// state: {
// 		src: "",
// 		alt: "",
// 		title: ""
// 	}
// }
// 	constructor(props) {
// 		super(props);
// 		//Remove from here
// 		console.log("props: ");
// 		console.log(props);
// 		this.state.src = !props.src ? "" : props.src;
// 		this.state.alt = !props.alt ? "" : props.alt;
// 		this.state.title = !props.title ? "" : props.title;
// 	}
// 	render() {
// 		const { classes } = this.props;
// 		const { src, alt, title } = this.state;

const CompanyLogo = (props) => (
	<div className={props.classes.logoContainer}>
		{props.src.search(/(jpeg|jpg|png|gif|svg)/gi) > -1 && (
			<img
				className={props.classes.logoImage}
				src={props.src}
				alt={props.alt}
				title={props.title}
			/>
		)}
	</div>
)

// CompanyLogo.contextTypes = {
// 	src: PropTypes.string.isRequired
// };

export default withStyles(styles)(CompanyLogo)
