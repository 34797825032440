import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'

/**
 * default tooltip component, it manages tooltip rendering
 * @description created the component
 * @author Davi Wegner
 * @version 1.0
 */
const Tip = ({
	tooltip,
	children,
	enterDelay,
	leaveDelay,
	enterTouchDelay,
	leaveTouchDelay,
	placement
}) => {
	return tooltip ? (
		<Tooltip
			enterDelay={enterDelay}
			leaveDelay={leaveDelay}
			enterTouchDelay={enterTouchDelay}
			leaveTouchDelay={leaveTouchDelay}
			placement={placement}
			title={tooltip}
		>
			{children}
		</Tooltip>
	) : (
		children
	)
}
Tip.defaultProps = {
	enterDelay: 0,
	leaveDelay: 300,
	enterTouchDelay: 100,
	leaveTouchDelay: 300,
	placement: 'bottom',
	tooltip: null
}

Tip.propTypes = {
	tooltip: PropTypes.string,
	placement: PropTypes.string,
	enterDelay: PropTypes.number,
	leaveDelay: PropTypes.number,
	enterTouchDelay: PropTypes.number,
	leaveTouchDelay: PropTypes.number
}
export default Tip
