import { fs } from '../../firebase'

/**
 * Responsible for get dash item properties in firebase according active company. This case is especific for filters.
 *
 * @author davispindola
 * @version 1.0.0
 * @description Created the method
 *
 * @author brunoteixeirasilva
 * @version 1.1.0
 * @description Changed the method name
 *
 * @param {String} activeCompanyId User active company id
 * @param {String} dashItemId The given dash item id
 */
export const getById = (activeCompanyId, dashItemId) =>
	fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('dashItems')
		.doc(dashItemId)
		.get()
