const SET_ACTIVE_PLUGIN = 'SET_ACTIVE_PLUGIN'

const INITIAL_STATE = {
	activePlugin: null
	// pluginStatus: null
}

/**
 * Will set an active plugin
 *
 * @param {Object} state The current app state
 * @param {*} action The action with "type" and the desired props to propagate
 */
const setActivePlugin = (state, action) =>
	Object.assign({}, state, { activePlugin: action.plugin })

/**
 * Manages plugin list, form & else states in a separate layer
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
function pluginReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_ACTIVE_PLUGIN': {
			return setActivePlugin(state, action)
		}
		// case 'PLUGIN_STATUS_UPDATED': {
		// 	return Object.assign({}, state, {
		// 		pluginStatus: Object.assign(
		// 			{},
		// 			state.pluginStatus,
		// 			action.status
		// 		)
		// 	})
		// }
		default:
			return state
	}
}

export default pluginReducer

export { SET_ACTIVE_PLUGIN }
