export const MANAGE_HISTORY_OWN_LOAD_LOADING_STATE =
		'MANAGE_HISTORY_OWN_LOAD_LOADING_STATE',
	LOAD_HISTORY_OWN_LOAD_DATA = 'LOAD_HISTORY_OWN_LOAD_DATA',
	SET_HISTORY_OWN_LOAD_PERIOD = 'SET_HISTORY_OWN_LOAD_PERIOD'

export const setHistoryOwnLoadPeriod = (dateConfig, value, formType) => ({
	type: SET_HISTORY_OWN_LOAD_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadHistoryOwnLoadData = (historyOwnLoad) => ({
	type: LOAD_HISTORY_OWN_LOAD_DATA,
	historyOwnLoad
})

export const setLoading = (isLoading) => ({
	type: MANAGE_HISTORY_OWN_LOAD_LOADING_STATE,
	isLoading
})
