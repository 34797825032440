const INITIAL_STATE = {
	isLoading: false,
	downloadState: null,
	isReady: null
}

export const SET_PORTAL_DOWNLOAD_LOADING_STATE = 'SET_PORTAL_DOWNLOAD_LOADING_STATE',
	SET_PORTAL_DOWNLOAD_STATE = 'SET_PORTAL_DOWNLOAD_STATE',
	CLEAN_PORTAL_DOWNLOAD_DATA = 'CLEAN_PORTAL_DOWNLOAD_DATA',
	SET_ISREADY_STATE = 'SET_ISREADY_STATE'

const portalDownloadReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PORTAL_DOWNLOAD_LOADING_STATE:
			return Object.assign({}, state, { isLoading: action.isLoading })
	
		case SET_PORTAL_DOWNLOAD_STATE:
			return Object.assign({}, state, { downloadState: action.downloadState })

		case SET_ISREADY_STATE:
			return Object.assign({}, state, { isReady: action.isReady })
	
		case CLEAN_PORTAL_DOWNLOAD_DATA: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default portalDownloadReducer
