import React from 'react'
import { HashRouter as Router } from 'react-router-dom'
// import * as Sentry from 'sentry-cordova'
import { compose } from 'recompose'
import { connect } from 'react-redux'
// import classNames from 'classnames'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import BottomMenuNavigation from '../reusable/navigation/bottom/BottomMenuNavigation'
import withAuthentication from '../reusable/withAuthentication'
// import { Navigation } from '../reusable/navigation'
import { MenuAppBar } from '../reusable/appBar'
// import Pages from '../../constants/Pages'
import '../css/base.css'
import '../css/app.css'
// import { SnackbarContainer } from '../reusable/snackbar'
import ErrorBoundary from '../reusable/error/ErrorBoundary'
// import { deviceIsIos } from '../../utils/env'
import {
	setAppBarLeftIcon,
	setAppBarLeftIconAction,
	setAppBarLeftIconAriaLabel,
	setAppTitle,
	setAppDrawerOpen,
	setAppDebugMode,
	setAppDefaultTitle,
} from '../../actions/app/config'
import { SET_ACTIVE_SWIPER } from '../../reducers/dashItem/view'
import { getActivePluginId } from '../../selectors/plugin'
import { getUserActiveCompanyId } from '../../selectors/user/company'
import { PLUGIN_LIST_LOADED } from '../../actions/plugin/list'
import { onGetAllByCompany } from '../../firebase/plugin'
import { onGetSuperUserAcess } from '../../firebase/permission'
import { onFetchLanguage, onFetchAvailableLanguages, fetchCurrentUser } from '../../firebase/account'
import App from './App'
import { doUpdateUser } from '../../firebase/user'
import { doSignOut } from '../../firebase/auth'
// import withTracking from './tracking'
import withValidation from '../reusable/withValidation'

import packageJson from '../../../package.json'
import { fs } from '../../firebase/firebase'

// import Tracking from './tracking'
// import { fs } from '../../firebase/firebase'
// import { fs } from '../../firebase/firebase'
/**
 * The main app instance manager class
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description A check has been added before rendering the layout to know which layout version you have to show, mobile or desktop
 * @author lopes
 * @version 1.1
 */
class AppContainer extends React.Component {
	state = {
		environment: null,
		appBar: {
			leftIconAction: this.handleToggleDrawer,
		},
		// navigation: {
		// 	open: Boolean(!!this.props.drawerOpen ? props.drawerOpen : false) //Drawer starts closed
		// }
	}

	handleRefreshClick = () => {
		// 	navigator.serviceWorker.getRegistrations().then(registrations => {
		// 		registrations.forEach(registration => {
		// 			registration.unregister()
		// 		})
		// 	})
		// 	caches.keys().then(keyList => {
		// 		return Promise.all(
		// 			keyList.map(key => {
		// 				return caches.delete(key)
		// 			})
		// 		)
		// 	})
	}

	async componentDidMount() {
		const { debug } = this.props

		// Fica analizando mudança de versao no agildash
		fs.collection('app')
			.doc('versions')
			.onSnapshot(doc => {
				this.setState({
					environment: doc.data().current,
				})
			})

		// this.loadLanguage()
		// if(window.location.hostname== 'local')
		// process.env.NODE_ENV = 'development'
		// process.env.NODE_ENV = 'production'
		//If debugging is enabled
		// if (window.cordova) alert(window.device.uuid)
		//And its only allowed under dev environment

		//Clear caches
		// this.handleRefreshClick()

		if (debug && process.env.REACT_APP_NODE_ENV.trim() === 'development') console.log('Debugging ON & <App /> did mount')

		this.configureApp()
	}
	// loadDefaultLanguage = () => {

	// }
	// loadLanguage = () => {
	// 	const { language, setLanguageTexts } = this.props
	// 	onFetchLanguageText(language).then((docSnapshot) => {
	// 		setLanguageTexts(docSnapshot.data())
	// 	})
	// }
	configureApp = async () => {
		const {
			app,
			config,
			onSetTitle,
			onSetDefaultTitle,
			onSetDebugMode,
			onSetLeftIcon,
			onSetLeftIconAction,
			onSetLeftIconAriaLabel,
			activeCompanyId,
			pluginsLoaded,
			authUser,
			setSuperUserAccess,
			setUserLanguage,
			onSetAvailableLanguage,
			onFetchingLanguage,
			setCurrentUser,
		} = this.props
		//Activates/deactivates debug mode at redux-app-state
		onFetchingLanguage(true)
		if (undefined !== config.debug && '' !== config.debug && 'boolean' === typeof config.debug) onSetDebugMode(config.debug)

		//Configures each app-root configurable element
		if (!!config.title && '' !== config.title) {
			if (process.env.REACT_APP_NODE_ENV === 'development' && !!app.debug) {
				console.log('Debugging: Will set app title => (Redux)state.appConfigState.app.title')
			}

			//Sets default and current app-title
			onSetDefaultTitle(config.title)
			onSetTitle(config.title)
		}

		//Default config was found
		if (!!config && !!app) {
			//Default config was found & no redux-state-layer left-icon defined
			if (!!config.barLeftIcon && !app.barLeftIcon) {
				onSetLeftIcon(config.barLeftIcon)
			}

			//No redux-state-layer bar-left-icon-action defined
			//Assumes the drawer is the desired action
			if (!app.barLeftIconAction) {
				onSetLeftIconAction(this.handleToggleDrawer)
			} else if (config.barLeftIconAction) {
				//Here we have no redux-state-layer bar-left-icon-action defined
				//Though we have default config
				onSetLeftIconAction(config.barLeftIconAction)
			}

			if (!!config.barLeftIconAriaLabel && !app.barLeftIconAriaLabel) {
				onSetLeftIconAriaLabel(config.barLeftIconAriaLabel)
			}
		}
		if (authUser) {
			this.generateToken()
			onGetSuperUserAcess(authUser.uid, setSuperUserAccess)
			onFetchLanguage(authUser.uid, setUserLanguage)
			onFetchAvailableLanguages(onSetAvailableLanguage)
			fetchCurrentUser(authUser.uid, setCurrentUser)
			if (activeCompanyId) onGetAllByCompany(activeCompanyId, authUser.uid, pluginsLoaded)
		}
	}

	/**
	 * Will manage to close the error message by also cleaning the redux state-layer
	 * for error management
	 *
	 * @description Created the function
	 * @author brunoteixeirasilva
	 * @version 1.0
	 *
	 * @description Changed the function
	 * @author brunoteixeirasilva
	 * @version 1.1
	 *
	 * @description Addedd ErrorBoundary component
	 * @author davispindola
	 * @version 1.2
	 *
	 * @param {Event} event The event object which invoked this method
	 */
	handleCloseMessage = event => {
		const { clearMessage } = this.props
		// As of Sentry report
		// At: https://sentry.io/druddr-media/arquia-dashboard/issues/541939384
		// Goal: Preventing unaware triggerers from exploding the application
		if (!!event && !!event.preventDefault) event.preventDefault()

		clearMessage()
	}

	handleToggleDrawer = () => {
		const { drawerOpen, onSetDrawerOpen } = this.props

		//Inverts the state of the drawer
		onSetDrawerOpen(!drawerOpen)
	}

	handleDashboard = () => {
		const { onSetSwipe } = this.props

		onSetSwipe(true)
	}
	componentDidUpdate(prevProps) {
		const { activeCompanyId, pluginsLoaded, authUser, setSuperUserAccess, onSetAvailableLanguage, userLogout } = this.props

		const { environment } = this.state

		const currentVersionInPackage = packageJson.version

		if (!!environment) {
			if (process.env.REACT_APP_NODE_ENV === 'development') {
				if (currentVersionInPackage !== environment.development) {
					console.log('Environment development =>', currentVersionInPackage, 'Versao atual no firebase =>', environment.development)

					setTimeout(() => {
						window.location.reload()
					}, 1000)
				}
			} else if (process.env.REACT_APP_NODE_ENV === 'production') {
				if (currentVersionInPackage !== environment.production) {
					{
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					}
				}
			} else {
				if (currentVersionInPackage !== environment.stage) {
					console.log('Environment stage =>', currentVersionInPackage, 'Versao atual no firebase =>', environment.stage)
					setTimeout(() => {
						window.location.reload()
					}, 1000)
				}
			}
		}

		if (!prevProps.authUser && this.props.authUser) {
			onGetSuperUserAcess(authUser.uid, setSuperUserAccess)
			onFetchAvailableLanguages(onSetAvailableLanguage)
			this.generateToken()
		}

		if (!prevProps.activeCompanyId && this.props.activeCompanyId) onGetAllByCompany(activeCompanyId, authUser.uid, pluginsLoaded)

		if (this.props.currentUserStatus === false) {
			doSignOut().then(() => userLogout())
			// history.push('/signin')
		}
	}
	// pushNotificationInApp = () => {

	// }
	generateToken = () => {
		const { authUser } = this.props
		if (window.cordova) {
			window.FirebasePlugin.onTokenRefresh(
				token => {
					doUpdateUser(authUser.uid, { token: `${token}` })
				},
				error => {
					console.error(error)
				}
			)
		}
	}
	//render with conditional to check which version of layout we have to show for the user
	renderMenu = () => {
		const self = this,
			{ width, title, activePluginId } = self.props

		if (isWidthUp('sm', width)) {
			return (
				<React.Fragment>
					<MenuAppBar leftIconAction={self.handleToggleDrawer} title={title} id="menuAppBar" />
				</React.Fragment>
			)
		} else {
			return <BottomMenuNavigation activePluginId={activePluginId} menuNavigation={self.handleToggleDrawer} menuCard={this.handleDashboard} />
		}
	}
	// componentWillUnmount() {
	// 	if (window.cordova && !deviceIsIos()) {
	// 		window.cordova.plugins.backgroundMode.moveToBackground()
	// 	}
	// }

	render() {
		return (
			// isLoading ? (
			// 	<Loader />
			// ) : (
			<Router>
				<ErrorBoundary>
					{/* <Tracking> */}
					<App {...this.props} renderMenu={this.renderMenu} />
					{/* </Tracking> */}
				</ErrorBoundary>
			</Router>
		)
	}
}

const mapStateToProps = state => ({
	app: state.appConfigState.app,
	debug: state.appConfigState.app.debug,
	title: state.appConfigState.app.title,
	activePluginId: getActivePluginId(state),
	activeCompanyId: getUserActiveCompanyId(state),
	requireCompanyActivation: state.userCompanyState.requireCompanyActivation,
	language: state.accountConfigState.language,
	isLoading: state.accountConfigState.isLoading,
	currentUserStatus: state.currentUserState.active,
	location: window.location,
})

const mapDispatchToProps = dispatch => ({
	onSetLeftIcon: icon => dispatch(setAppBarLeftIcon(icon)),
	onSetLeftIconAction: action => dispatch(setAppBarLeftIconAction(action)),
	onSetLeftIconAriaLabel: label => dispatch(setAppBarLeftIconAriaLabel(label)),
	onSetDefaultTitle: title => dispatch(setAppDefaultTitle(title)),
	onSetTitle: title => dispatch(setAppTitle(title)),
	onSetDrawerOpen: drawerOpen => dispatch(setAppDrawerOpen(drawerOpen)),
	onSetDebugMode: debug => dispatch(setAppDebugMode(debug)),
	onSetSwipe: swipe => dispatch({ type: SET_ACTIVE_SWIPER, swipe }),
	pluginsLoaded: plugins => dispatch({ type: PLUGIN_LIST_LOADED, plugins }),
	setSuperUserAccess: hasSuperUserAccess => dispatch({ type: 'SET_SUPER_USER_ACCESS', hasSuperUserAccess }),
	setUserLanguage: language => dispatch({ type: 'SET_APP_LANGUAGE', language }),
	setLanguageTexts: text => dispatch({ type: 'SET_APP_LANGUAGE_TEXT', text }),
	onSetAvailableLanguage: languages => dispatch({ type: 'SET_AVAILABLE_LANGUAGES', languages }),
	onFetchingLanguage: isLoading => dispatch({ type: 'SET_LANGUAGE_LOADING', isLoading }),
	setCurrentUser: value => dispatch({ type: 'SET_CURRENT_USER_DATA', value }),
	userLogout: () => dispatch({ type: 'USER_LOGOUT' }),
})

export default compose(
	withValidation,
	withAuthentication,
	// withConnectionCheck,
	withWidth(),
	connect(mapStateToProps, mapDispatchToProps)
	// withTracking
)(AppContainer)
