import React from 'react'

/**
 * function for lazy loading components
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 * @param {*} path
 */
const getComponent = (path, key) => {
	const Module = React.lazy(() =>
		import(`./${path || 'component/reusable/card/Placeholder'}.js`),
	)
	return <Module {...(key ? { key } : {})} />
}

/**
 * function for lazy loading components, but returns the promise with the imported, instead of the <component/> itself
 * @description Created the function
 * @author Davi Wegner
 * @version 1.0
 * @param {*} path
 */
const getComponentAsync = async (path) =>
	React.lazy(() =>
		import(`./${path || 'component/reusable/card/Placeholder'}.js`),
	)

export { getComponent, getComponentAsync }
