import React from 'react'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hint from '../Hint'
import { translate } from '../../../../utils/lang'
import withActiveCompany from '../../withActiveCompany'
import FakeListItem from '../../list/item/fake'
import {
	makeGetUserActiveCompany,
} from '../../../../selectors/user/company'
import { fs } from '../../../../firebase/firebase'

/**
 * Displays a company hint list-item (menuMode={true}) OR a tooltipped-form-input
 * with current active-company information
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added support for a menu-list-item version of this feature
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Renamed component
 * @author brunoteixeirasilva
 * @version 2.0
 *
 * @description Added gutters support for **non-menu mode** only
 * @author brunoteixeirasilva
 * @version 2.1
 * 
 * @description Add log to user acess
 * @author jeffersonguilhermemachadobarreto
 * @version 2.2
 */
class CompanyHint extends React.Component {

	componentDidMount() {
		this.logAcessUser()
	}

	logAcessUser = () => {

		const { activeCompanyId, authUser } = this.props

		fs.collection("companies").doc(activeCompanyId).collection('users').doc(authUser.uid).update({
			date: new Date()
		})
		.catch((error) => {
			console.error("Error updating document: ", error);
		});
	}

	render() {
		const { menuMode, gutters, company, icon, label } = this.props

		return (
			<div>
				{!!menuMode &&
					(company ? (
						// <HintMenuItem
						// 	icon="business"
						// 	primary={translate('label/active-company')}
						// 	secondary={company.abbrev}
						// />
						<ListItemIcon
							style={{
								display: 'flex',
								alignItems: 'center',
							}}>
							{/* {icon.length > 50 ? ( */}
							<img
								style={{
									width: '40px',
									height: '40px',
									marginLeft: '15px',
									marginTop: '10px',
									marginBottom: '10px',
									marginRight: '15px',
								}}
								src={icon}
								alt="logo da empresa"
							/>
							<ListItemText>{label}</ListItemText>
						</ListItemIcon>
					) : (
						<FakeListItem icon />
					))}
				{!menuMode && !!company && (
					<Tooltip
						title={translate('label/switch-company-hint')}
						placement="top">
						<Hint
							gutters={gutters}
							primary={translate('label/you-are-at')}
							secondary={company.abbrev}
						/>
					</Tooltip>
				)}
			</div>
		)
	}
}

CompanyHint.defaultProps = {
	gutters: false,
	menuMode: false,
}

CompanyHint.propTypes = {
	gutters: PropTypes.bool,
	menuMode: PropTypes.bool,
}

const makeMapStateToProps = () => {
	const getUserCompany = makeGetUserActiveCompany()
	const mapStateToProps = state => {
		const company = getUserCompany(state)

		return {
			company,
		}
	}

	return mapStateToProps
}

export default compose(
	connect(makeMapStateToProps()),
	withActiveCompany,
)(CompanyHint)
