import {
	MANAGE_OWN_LOAD_LOADING_STATE,
	LOAD_OWN_LOAD_DATA,
	SET_OWN_LOAD_PERIOD
} from '../../actions/ownLoad'

const INITIAL_STATE = {
	isLoading: null,
	ownLoad: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		origem: ''
	}
}
/**
 * ownLoad state reducer manager
 * @param {*} state
 * @param {*} action
 */

const ownLoadReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_OWN_LOAD_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_OWN_LOAD_DATA: {
			return Object.assign({}, state, {
				ownLoad: action.ownLoad,
				isLoading: false
			})
		}
		case 'SET_OWN_LOAD_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_OWN_LOAD_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_OWN_LOAD_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_OWN_LOAD_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default ownLoadReducer
