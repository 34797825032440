export const CLEAR_GLOBAL_FILTER_SEARCH_TEXT =
		'CLEAR_GLOBAL_FILTER_SEARCH_TEXT',
	SET_GLOBAL_FILTER_SEARCH_TEXT = 'SET_GLOBAL_FILTER_SEARCH_TEXT'

/**
 * Standardizes **emitting** snackbars at the system
 *
 * ? tags: snackbar, message, notification, emit
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} messageText The snackbar message text to be shown
 */
export const setGlobalFilterSearch = (dataSetId, text) => ({
	type: SET_GLOBAL_FILTER_SEARCH_TEXT,
	dataSetId: dataSetId,
	text: text
})

/**
 * [action-creator] for global search-text **clearing**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} dataSetId The data-set id to be rooted with the text
 */
export const clearGlobalFilterSearch = (dataSetId) => ({
	type: CLEAR_GLOBAL_FILTER_SEARCH_TEXT,
	dataSetId
})
