import {
	MANAGE_ENERGY_CONSUMPTION_LOADING_STATE,
	LOAD_ENERGY_CONSUMPTION_DATA,
	SET_ENERGY_CONSUMPTION_PERIOD
} from '../../actions/energyConsumption'

const INITIAL_STATE = {
	isLoading: null,
	energyConsumption: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {}
}
/**
 * energyConsumption state reducer manager
 * @param {*} state
 * @param {*} action
 */

const energyConsumptionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_ENERGY_CONSUMPTION_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_ENERGY_CONSUMPTION_DATA: {
			return Object.assign({}, state, {
				energyConsumption: action.energyConsumption,
				isLoading: false
			})
		}
		case 'SET_ENERGY_CONSUMPTION_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_ENERGY_CONSUMPTION_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_ENERGY_CONSUMPTION_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_ENERGY_CONSUMPTION_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default energyConsumptionReducer
