const PROCESSES_LOADED = 'PROCESSES_LOADED'

const INITIAL_STATE = {
	currentProcess: null,
	processes: null,
	processList: undefined
}

/**
 * Manages process state layer
 *
 * @author wegner
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" with specific props
 */
const processReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_CURRENT_PROCESS': {
			return Object.assign({}, state, { currentProcess: action.process })
		}
		case 'CLEAR_CURRENT_PROCESS': {
			return Object.assign({}, state, {
				currentProcess: INITIAL_STATE.currentProcess
			})
		}
		case PROCESSES_LOADED: {
			return Object.assign({}, state, { processes: action.processes })
		}
		case 'SET_PROCESS_LIST': {
			return Object.assign({}, state, { processList: action.processList })
		}
		case 'CLEAR_PROCESS_LIST': {
			return Object.assign({}, state, { processList: INITIAL_STATE })
		}
		default:
			return state
	}
}

export default processReducer

export { PROCESSES_LOADED }
