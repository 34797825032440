import {
	FILTER_BY_AUTHOR_TYPE,
	CLEAN_FILTER_TYPE,
	SET_SNAPSHOT,
	SET_ACTION_RESPONSES,
	SET_ACTION_RESPONSES_TO_INITITAL_STATE
} from './../../component/app/userActions/actions'

const OPEN_ACTION_MODAL = 'OPEN_ACTION_MODAL',
	CLOSE_ACTION_MODAL = 'CLOSE_ACTION_MODAL',
	CLEAR_ACTIVE_ACTION = 'CLEAR_ACTIVE_ACTION',
	SET_ACTIVE_ACTION = 'SET_ACTIVE_ACTION',
	SET_ACTION_ID = 'SET_ACTION_ID'

const INITIAL_STATE = {
	byAuthor: 0,
	byTitle: '',
	actionList: null,
	isLoading: false,
	snapshot: null,
	showActionModal: false,
	activeAction: null,
	actionId: null,
	responses: null,
	actionsIsLoading: true,
	selfActionsList: null,
	cardInfo: null,
	snapshotPath: null,
	unsubscribe: null
}

function setActionActivity(state, list, id) {
	return Object.assign({}, state, {
		[list]: Object.assign({}, state[list], {
			[id]: Object.assign({}, state[list][id], {
				active: false
			})
		})
	})
}

// function setNewItem(state, list, item) {
// 	return Object.assign({}, state, {
// 		[list]
// 	})
// }

const actionsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FILTER_BY_AUTHOR_TYPE:
			return Object.assign({}, state, {
				byAuthor: action.value
			})
		case CLEAN_FILTER_TYPE:
			return Object.assign({}, state, {
				byAuthor: INITIAL_STATE.byAuthor
			})
		case SET_SNAPSHOT:
			return Object.assign({}, state, {
				snapshot: action.blob
			})
		case OPEN_ACTION_MODAL:
			return Object.assign({}, state, {
				showActionModal: true
			})
		case CLOSE_ACTION_MODAL:
			return Object.assign({}, state, {
				showActionModal: INITIAL_STATE.showActionModal
			})
		case SET_ACTIVE_ACTION:
			return Object.assign({}, state, {
				activeAction: action.activeAction
			})
		case SET_ACTION_ID:
			return Object.assign({}, state, {
				actionId: action.actionId
			})
		case 'SET_ACTION_LIST':
			return Object.assign({}, state, {
				actionList: action.list
			})
		case 'UPDATE_ACTION_LIST':
			return Object.assign({}, state, {
				actionList: Object.assign(
					{},
					state.actionList,
					action.newAction
				)
			})
		case 'SET_UNSUBSCRIBE_FUNC':
			return Object.assign({}, state, { unsubscribe: action.unsubscribe })
		case 'SET_NEW_ACTION_IDS':
			return Object.assign({}, state, {
				newActionIds: action.actionIds
			})
		case 'SET_SELF_ACTIONS_LIST':
			return Object.assign({}, state, {
				selfActionsList: action.list
			})
		case CLEAR_ACTIVE_ACTION:
			return Object.assign({}, state, {
				activeAction: INITIAL_STATE.activeAction
			})
		case SET_ACTION_RESPONSES:
			return Object.assign({}, state, {
				responses: action.responses
			})
		case SET_ACTION_RESPONSES_TO_INITITAL_STATE:
			return Object.assign({}, state, {
				responses: INITIAL_STATE.responses
			})
		case 'SET_ACTIONS_IS_LOADING':
			return Object.assign({}, state, {
				actionsIsLoading: action.actionsIsLoading
			})
		case 'SET_ACTION_LOADING_STATUS':
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		case 'SET_ACTION_ACTIVE_TO_FALSE':
			const list = 'actionList'

			return setActionActivity(state, list, action.actionId)
		case 'SET_CARD_INFO_ON_ACTION':
			return Object.assign({}, state, {
				cardInfo: Object.assign({}, state.cardInfo, action.cardInfo)
			})
		default:
			return state
	}
}

export default actionsReducer

export {
	OPEN_ACTION_MODAL,
	CLOSE_ACTION_MODAL,
	CLEAR_ACTIVE_ACTION,
	SET_ACTION_ID
}
