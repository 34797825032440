import {
    MANAGE_RESULT_CHECK_LIST_PLAN_LOADING_STATE,
    LOAD_RESULT_CHECK_LIST_PLAN_DATA,
    SET_RESULT_CHECK_LIST_PLAN_PERIOD
} from '../../actions/resultChecklistPlan'

const INITIAL_STATE = {
    isLoading: null,
    data: null,
    dateConfig: {},
    update: false,
    localFilters: null,
    ownFilters: {},
	searchText: {},
    idLocalFilter: {
        localClients: null,
        localTypeObjects: null,
        localBases: null,
        localLocalities: null,
        forms: null,
        collaborators: null,
        objects: null,
        auditId: null,
        statusActionPlan: null
    }
}

/**
 * @description clear all local filters
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
 const clearAllLocalFilters = (state) => {
    let keys = Object.keys(state.idLocalFilter),
        clearFilters = {}

    keys.forEach((key) => {
        clearFilters[key] = null
    })

    console.log('clearFilters', clearFilters)

    return clearFilters
}

/**
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
const resultChecklistPlanReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_RESULT_CHECK_LIST_PLAN_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_RESULT_CHECK_LIST_PLAN_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case 'SET_RESULT_CHECK_LIST_PLAN_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_RESULT_CHECK_LIST_PLAN_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
        case 'CLEAN_RESULT_CHECK_LIST_PLAN_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
                idLocalFilter: clearAllLocalFilters(state)
			})
		}
        case 'SET_ID_LOCAL_FILTERS_RESULT_CHECK_LIST_PLAN': {
            console.log('action.idLocalFilter', action.idLocalFilter)
            return Object.assign({}, state, {
                idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter)
            })
        }
        case 'SET_LOCAL_FILTER_RESULT_CHECK_LIST_PLAN': {
			return Object.assign({}, state, {
				localFilters: action.localFilters
			})
		}
        case 'SET_SEARCH_TEXT_RESULT_CHECK_LIST_PLAN': {
            let searchText = {}
            action.objectKeys.forEach((item) => {
                searchText[item] = ''
            })
            return Object.assign({}, state, {
                searchText: searchText
            })
        }
        case SET_RESULT_CHECK_LIST_PLAN_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        default:
            return state
    }
}

export default resultChecklistPlanReducer
