import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

/**
 * [wrapper] Renders markdown documents within an external component
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class DocRendererMarkDown extends Component {
	_mounted = false

	state = {
		text: null
	}

	componentDidMount() {
		const self = this,
			{ path } = self.props
		//If the component has mounted
		//Used for preventing unmounted setState leaks
		self._mounted = true

		//Fetches the privacy content from the doc
		fetch(path)
			.then((response) => response.text())
			.then((text) => {
				//Component still avail
				if (self._mounted) {
					self.setState({ text: text })
				}
			})
	}

	componentWillUnmount() {
		//Component won't be mounted any more
		this._mounted = false
	}

	render() {
		const { text } = this.state

		return <ReactMarkdown source={text} />
	}
}

DocRendererMarkDown.propTypes = {
	path: PropTypes.string.isRequired
}

export default DocRendererMarkDown
