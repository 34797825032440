const INITIAL_STATE = {
	clients: null,
	isLoading: false,
	currentClient: null,
	clientData: []
}

/**
 * Sets "isLoading" prop to the specified value, at the next state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "isLoading" property/key value
 */
const setLoading = (state, action) =>
	Object.assign({}, state, { isLoading: action.isLoading })

/**
 * Apply clients list to the current state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "clients" property/key with clients' list
 */
const clientsLoaded = (state, action) =>
	Object.assign({}, state, {
		clients: action.clients,
		isLoading: INITIAL_STATE.isLoading
	})

/**
 * Apply a client's data list to the current state of the app for usage
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "data" property/key with client's data list (array)
 */
const clientDataLoaded = (state, action) =>
	Object.assign({}, state, {
		clientData: action.data,
		isLoading: INITIAL_STATE.isLoading
	})

/**
 * Clears a client's data list => prepares for new query
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "data" property/key with client's data list (array)
 */
const clearLoadedClients = (state, action) =>
	Object.assign({}, state, { clients: INITIAL_STATE.clients })

/**
 * Apply a current client as active current state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "client" property/key with currently selected client
 */
const setCurrentClient = (state, action) =>
	Object.assign({}, state, { currentClient: action.client })

/**
 * Manages client-list and details state-layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {*} state The current app-state fragment
 * @param {*} action The guide object
 */

function clientReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_CLIENTS_LOADING': {
			return setLoading(state, action)
		}
		case 'SET_CURRENT_CLIENT': {
			return setCurrentClient(state, action)
		}
		case 'CLIENTS_LOADED': {
			return clientsLoaded(state, action)
		}
		case 'CLEAR_LOADED_CLIENTS': {
			return clearLoadedClients(state, action)
		}
		case 'CLIENT_DATA_LOADED': {
			return clientDataLoaded(state, action)
		}
		default:
			return state
	}
}

export default clientReducer
