import React from 'react'
import { TextField, Button, withStyles, Icon } from '@material-ui/core'

import { translate } from '../../../../utils/lang'

/**
 * Forgot password form implementation - for user reset password
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @param {*} param0
 */
const ForgotPasswordForm = ({
	handleSubmit,
	handleReturn,
	email,
	hasError,
	handleInput,
	classes
}) => (
	<form onSubmit={handleSubmit} className={classes.form}>
		<TextField
			type="email"
			label={translate('label/email')}
			value={email}
			onChange={handleInput}
			variant="standard"
			error={hasError}
		/>
		<div>
			<Button
				fullWidth
				type="submit"
				variant="outlined"
				disabled={email === ''}
				children={translate('label/send')}
			/>
		</div>
		<div style={{ marginBottom: 0 }}>
			<Button variant="text" fullWidth onClick={handleReturn}>
				<Icon>keyboard_backspace</Icon>
				{translate('label/go-back-to', {
					to: translate('label/sign-in')
				})}
			</Button>
		</div>
	</form>
)
const styles = (theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		'& > div': {
			marginBottom: theme.spacing.unit * 2
		}
	}
})
export default withStyles(styles)(ForgotPasswordForm)
