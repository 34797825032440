export const SET_USER_ACTIVE_COMPANY = 'SET_USER_ACTIVE_COMPANY'
export const CLEAR_USER_ACTIVE_COMPANY = 'CLEAR_USER_ACTIVE_COMPANY'
export const SET_USER_COMPANIES = 'SET_USER_COMPANIES'
export const CLEAR_USER_COMPANIES = 'CLEAR_USER_COMPANIES'
export const REQUIRE_COMPANY_ACTIVATION = 'REQUIRE_COMPANY_ACTIVATION'
export const SET_USER_ACTIVE_COMPANY_LOADING = 'SET_USER_ACTIVE_COMPANY_LOADING'

/**
 * [action-creator] for dispatching _changes_ to an **user-active-company** info
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} isLoading Will set the current active company loading state
 */
export const setUserActiveCompanyLoading = isLoading => ({
	type: SET_USER_ACTIVE_COMPANY_LOADING,
	activeCompanyLoading: isLoading,
})

/**
 * [action-creator] for dispatching _changes_ to an **user-active-company** info
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} activeCompanyId Will set the current active company ID
 */
export const setUserActiveCompany = activeCompanyId => ({
	type: SET_USER_ACTIVE_COMPANY,
	activeCompanyId,
})

/**
 * [action-creator] for dispatching _clearing_ an **user-active-company** info
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const clearUserActiveCompany = () => ({
	type: CLEAR_USER_ACTIVE_COMPANY,
})

/**
 * [action-creator] for dispatching clearing an **user-company-list**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const clearUserCompanies = () => ({
	type: CLEAR_USER_COMPANIES,
})

/**
 * [action-creator] for dispatching requirement of an **active-company**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Boolean} requireCompanyActivation [optional] Whether the company requirer should be activated
 */
export const setRequireActiveCompany = (requireCompanyActivation = true) => ({
	type: REQUIRE_COMPANY_ACTIVATION,
	requireCompanyActivation,
})

/**
 * [action-creator] for dispatching requirement of an **active-company**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const setUserCompanies = companies => ({
	type: SET_USER_COMPANIES,
	companies,
})
