const INITIAL_STATE = {
	cardInfo: null,
	selectedValues: {
		receiver_key: [],
		cardId: [],
		content_type: [],
		content_classification: [],
		active: [],
		author: [],
		date: []
	},
	isOpen: false
}

/**
 * reducer for action filters on action page
 * @description Created the function
 * @author Davi Wegner
 * @param {*} state
 * @param {*} action
 */
const actionFilterReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'UPDATE_ACTION_FILTER_VALUES':
			return Object.assign({}, state, {
				selectedValues: Object.assign(
					{},
					state.selectedValues,
					action.value
				)
			})
		case 'LOAD_ACTION_FILTER_CARD_ID':
			return Object.assign({}, state, {
				cardInfo: Object.assign({}, state.cardInfo, action.cardInfo)
			})
		case 'SET_AUTHOR_INFO_ON_FILTER':
			return Object.assign({}, state, {
				authorInfo: Object.assign(
					{},
					state.authorInfo,
					action.authorInfo
				)
			})
		case 'HANDLE_ACTION_FILTER_DIALOG': {
			return Object.assign({}, state, {
				isOpen: action.value
			})
		}
		default:
			return state
	}
}

export default actionFilterReducer
