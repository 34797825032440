import { createSelector } from 'reselect'
import { getActionById } from '../actions'
import { wasEvaluated } from '../wasEvaluated'

const makeGetActionWasEvaluated = () =>
	createSelector(
		[getActionById],
		(action) => {
			return wasEvaluated(action, 'evaluate')
		}
	)

export default makeGetActionWasEvaluated
