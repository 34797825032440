import { createSelector } from 'reselect'

/**
 * Selects a an object of company-plugins
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 */
const getCompanyPlugins = (state) => state.companyPluginState.items

/**
 * Selects by trial getting a company-plugin by its ID
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 * @param {Object} pluginId The plugin-id to look for
 */
export const getCompanyPluginById = (state, pluginId) => {
	const companyPlugins = getCompanyPlugins(state)

	return !!companyPlugins && companyPlugins.hasOwnProperty(pluginId)
		? companyPlugins[pluginId]
		: null
}

/**
 * [memoised] Selects by trial, getting a company-plugin by its ID
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompanyPluginById = () =>
	createSelector(
		getCompanyPluginById,
		(companyPlugin) => companyPlugin
	)

/**
 * Selects by trial getting a company-plugin data-source info by its ID
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 * @param {Object} pluginId The plugin-id to look for
 */
export const getCompanyPluginDataSourceById = (state, pluginId) => {
	const companyPlugins = getCompanyPlugins(state)

	return !!companyPlugins && companyPlugins.hasOwnProperty(pluginId)
		? companyPlugins[pluginId].dataSource
		: null
}

/**
 * [memoised] Selects by trial, getting a company-plugin data-source info by its ID
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetCompanyPluginDataSourceById = () =>
	createSelector(
		getCompanyPluginDataSourceById,
		(companyPluginDataSource) => companyPluginDataSource
	)
