import { fs, auth } from '../firebase'
import * as User from '../user'
import * as Author from '../../utils/security/author'
import * as ObjectState from '../../utils/object/state'

/**
 * Gets the collection of super groups in the system
 */
export const getSuper = () => {
	let ref = fs.collection('groups').where('isSuper', '==', true)

	return ref.get()
}

/**
 * [Firestore] Will get the list of [default] groups,
 * or an specific one (by ID)
 * ! If you are looking for company-groups, look under firebase/company/group location!
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} groupId The current group ID
 */
export const getDefault = (groupId) => {
	let ref = fs.collection('groups')

	//Specific ID informed
	if (!!groupId) {
		ref = ref.doc(groupId)
	}

	return ref.where('isDefault', '==', true).get()
}

/**
 * [Firestore] Collects a [default] group key-value object
 * base on the specified id-list -
 * ! But only default groups!
 * ! If you are looking for company-groups, look under firebase/company/group location!
 *
 * @description Queries Firebase RTDB groups by key, individually.
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now queries at Firebase Firestore /groups/${groupId}.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Array} groupIds Array containing the user group IDs
 */
export const getManyById = (groupIds) => {
	let ref = fs.collection(`groups`)

	//Iterates through each passed groupId
	//Finds the reference at Firebase
	const groups = groupIds.map((groupId) => {
		return ref.doc(groupId).get()
	})

	//Returns all promises (still pending)
	//Must be checked with "then" or failure
	return Promise.all(groups)
}

/**
 * [Firetore] Updates a [default] group based on an ID and its object value/data,
 * default because its under /groups
 * ! If you are looking for company-groups, look under firebase/company/group location!
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} groupId The updating group ID
 * @param {Object} value The value to be set at the group
 */
export const doUpdate = (groupId, value) => {
	let ref = fs.collection('groups').doc(groupId)

	return ref.set(value, { merge: true })
	// return db.ref(`groups/${groupId}`).update(value)
}

/**
 * [Firestore] Creates a new [default] group at the /groups collection
 * ! If you want to create a company-group, look at the firebase/company/group location!
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Group} data The creation group data
 */
export const doCreate = (data) => {
	let groupObject = Object.assign(
		{},
		data,
		Author.generateObject(auth.currentUser.uid)
	)

	//Gets the reference and the generated push ID
	let ref = fs.collection('groups').doc()
	let pushId = ref.id

	//Sets the object
	ref.set(groupObject)

	return pushId
}

/**
 * [Firestore] Adds an user entry to a [default] /groups collection, but, this is for DEFAULT groups.
 * ! If you are looking for company-groups, look under firebase/company/group location!
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} groupId The group having the user added
 * @param {string} uid The uid of the user being added
 */
export const doAddUser = (groupId, uid) => {
	let userObject = Object.assign(
		{},
		ObjectState.generateActive(),
		Author.generateObject(auth.currentUser.uid)
	)

	return fs
		.collection(`groups`)
		.doc(groupId)
		.collection('users')
		.doc(uid)
		.set(userObject, { merge: true })
	// return db.ref(`groupUsers/${groupId}/${uid}`).set(userObject)
}

/**
 * Gets [default] group (active) user keys based on a Group ID => Promise
 * ! If you are looking for company-groups, look under firebase/company/group location!
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} groupId The group ID to find users collection
 */
export const getUserKeys = (groupId) => {
	let ref = fs
		.collection('groups')
		.doc(groupId)
		.collection('users')

	return ref.get()
}

/**
 * [Firestore] Seeks a [default] group-users collection
 * ! If you are looking for company-groups, look under firebase/company/group location!
 *
 * @description Created the component by using Firebase RTDB
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Now the component uses Firebase Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} groupId The group which users should be found
 */
export const onGetUsers = (groupId) => {
	let users = []

	//Firstly, finds each user key added to a group (under /groupUsers)
	return getUserKeys(groupId).then((dataSnapshot) => {
		//Foreach user, finds it
		dataSnapshot.forEach((snap) => users.push(User.get(snap.id)))

		// //Secondly, finds the user registry (under /users)
		// return Object.keys(dataSnapshot.val()).map((key) => {
		// 	return User.onGetUser(key, (user) => {
		// 		return new Promise(user.val())
		// 		//return (users[key] = dataSnapshot.val())
		// 	})
		// })
		return Promise.all(users)
	})
}
