import {
	MANAGE_MEASUREMENT_LOADING_STATE,
	LOAD_MEASUREMENT_DATA,
	SET_MEASUREMENT_PERIOD
} from '../../actions/measurement'

const INITIAL_STATE = {
	isLoading: null,
	measurement: null,
	dateConfig: {},
	update: false
}
/**
 * Measurement state reducer manager
 * @param {*} state
 * @param {*} action
 */

const measurementReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_MEASUREMENT_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_MEASUREMENT_DATA: {
			return Object.assign({}, state, {
				measurement: action.measurement,
				isLoading: false
			})
		}
		case SET_MEASUREMENT_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default measurementReducer
