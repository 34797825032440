import React from 'react'
import withAuth from '../reusable/withAuth'
import AppContainer from './AppContainer'
import OutsidePages from './outsidePages'

const Root = props => {
	const { isAuth, config } = props

	return <>{!!isAuth ? <AppContainer config={config} /> : <OutsidePages />}</>
}

export default withAuth(Root)
