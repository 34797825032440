import React from 'react'
import PropTypes from 'prop-types'

import { Chip, withStyles } from '@material-ui/core'

/**
 * Representational componente for represent items in filter lists
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0.0
 *
 */
const FilterItem = ({ key, label, style, classes }) => (
	<Chip key={key} style={style} className={classes.chip} label={label} />
)

const style = (theme) => ({
	loaderBox: {
		margin: `${theme.spacing.unit * 2}px 0 0 0`,
		padding: `0 0 0 0`,
		height: 32,
		width: '100%',
		display: 'flex',
		zIndex: 100,
		justifyContent: 'center',
		alignItems: 'center',
		background: '#FFF',
		borderBottom: '1px solid #a2a2a2'
	},
	select: { whiteSpace: 'unset' },
	searchBox: {
		height: 'auto',
		paddingTop: 5,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	searchBoxInput: {
		'& > *': {
			borderColor: theme.palette.secondary.main
		},
		'&  label': {
			transform: 'translate(14px, 16px) scale(1)'
		},
		'& input': {
			padding: '12.5px 14px'
		}
	}
})

FilterItem.propTypes = {
	label: PropTypes.string,
	style: PropTypes.object,
	key: PropTypes.string,
	className: PropTypes.string
}

FilterItem.defaultProps = {
	key: '',
	style: null,
	className: '',
	label: null
}

export default withStyles(style)(FilterItem)
