import { createSelector } from 'reselect'
import { getActions, getReaderType } from '..'
import {
	classification,
	types
} from '../../../component/app/userActions/components/action/utils'
import { translate } from '../../../utils/lang'
import { getAuthUserID } from '../../session'

const makeActionItemFormater = () =>
	createSelector(
		[
			getCurrentAction,
			getCardTitle,
			getReaderType,
			getAuthorInfo,
			getIsCcReceiver
		],
		(action, title, readerType, authorInfo, isCcReceiver) => {
			if (!action) return null
			if (!action.receiver) return null

			return {
				title: !!title ? title : '...',
				date: action.date
					.toDate()
					.toLocaleString('pt-br')
					.split(' ')[0],
				classification: classification[action.content.classification],
				type: types[action.content.type],
				observation: action.content.observation,
				wasRead:
					readerType === 'receiverOpen' && isCcReceiver
						? action['ccReceiver']
						: action[readerType],
				receiverRead:
					readerType === 'authorOpen'
						? action['receiverOpen']
						: action['authorOpen'],
				hasAnswer: !!action.responsesCount > 0,
				status: action.active,
				userInfo:
					readerType === 'authorOpen'
						? `${translate('label/sent-to')}: ${
								action.receiver.label
						  }`
						: `${translate('label/created-by')}: ${
								authorInfo && authorInfo[action.author]
									? authorInfo[action.author].name
									: '...'
						  }`,
				wasOpen: action[readerType]
			}
		}
	)

const getCurrentAction = (state, ownProps) => {
	const actions = getActions(state),
		actionId = ownProps.actionId
	return !!actions && actionId ? actions[actionId] : null
}
const getCardTitle = (state, ownProps) => {
	const cards = state.actionsState.cardInfo,
		action = getCurrentAction(state, ownProps),
		cardId = !!action ? action.cardId : null

	return !!cards && cardId ? cards[cardId] : null
}

const getIsCcReceiver = (state, ownProps) => {
	const action = getCurrentAction(state, ownProps),
		authUser = getAuthUserID(state)
	return action && action.ccReceivers && action.ccReceivers.includes(authUser)
}

const getAuthorInfo = (state, ownProps) => state.actionFilterState.authorInfo
export default makeActionItemFormater
