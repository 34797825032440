import {
	MANAGE_COLLABORATOR_NC_LOADING_STATE,
	LOAD_COLLABORATOR_NC_DATA,
	SET_COLLABORATOR_NC_PERIOD
} from '../../actions/collaboratorNc'

const INITIAL_STATE = {
	isLoading: null,
	collaboratorNc: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		matricula_colaborador: '',
		nome_colaborador: '',
		cpf_colaborador: '',
		cargo_colaborador: '',
		situação_colaborador: '',
		regional_colaborador: '',
		localidade_colaborador: '',
		cliente_colaborador: '',
		nome_formacao: ''
	}
}
/**
 *
 * contributor list state reducer manager
 * @param {*} state
 * @param {*} action
 */

const collaboratorNcReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_COLLABORATOR_NC_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_COLLABORATOR_NC_DATA: {
			return Object.assign({}, state, {
				collaboratorNc: action.collaboratorNc,
				isLoading: false
			})
		}
		case 'SET_COLLABORATOR_NC_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_COLLABORATOR_NC_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_COLLABORATOR_NC_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_COLLABORATOR_NC_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default collaboratorNcReducer
