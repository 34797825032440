import { auth, fs } from '../../../firebase'
import * as Author from '../../../../utils/security/author'
import * as ObjectState from '../../../../utils/object/state'

/**
 * [Firestore] Will get a collection of company-group-permissions
 * ! Works specifically for COMPANY GROUP PERMISSIONS
 *
 * @description Created the function using Firebase RTDB
 * @author wegner
 * @version 1.0
 *
 * @description Changed the function to use Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The current active company ID
 * @param {string} groupId The current company-group ID
 */
export const getAll = (companyId, groupId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('permissions')
		.where('active', '==', true)
		.get()
	//`groupPermissions/${groupId}`).orderByChild('active').equalTo(true).once("value")
	// return db.ref(`groupPermissions/${groupId}`).orderByChild('active').equalTo(true).once("value")
}

/**
 * [Firestore] Will create a single company-group-permission
 * ! Works specifically for COMPANY GROUP PERMISSIONS
 *
 * @description Created the function using Firebase RTDB
 * @author wegner
 * @version 1.0
 *
 * @description Changed the function to use Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The current active company ID
 * @param {string} groupId The current company-group ID
 * @param {string} permissionId The current permission being added to the group
 * @param {Object} filters Filter list being ADDED
 */
export const doCreate = (companyId, groupId, permissionId, filters) => {
	const permissionObject = Object.assign(
		{},
		ObjectState.generateActive(),
		Object.assign(
			{},
			{ filters: filters },
			Author.generateObject(auth.currentUser.uid)
		)
	)

	let ref = fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('permissions')
		.doc(permissionId)

	return ref.set(permissionObject, { merge: true })
}

/**
 * [Firestore] Will update a single company-group-permission
 * ! Works specifically for COMPANY GROUP PERMISSIONS
 *
 * @description Created the function using Firebase RTDB
 * @author wegner
 * @version 1.0
 *
 * @description Changed the function to use Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The current active company ID
 * @param {string} groupId The current company-group ID
 * @param {string} permissionId The permission being UPDATED
 */
export const doUpdate = (companyId, groupId, permissionId, filters) => {
	const permissionObject = Object.assign(
		{},
		ObjectState.generateActive(),
		Object.assign(
			{},
			{ filters: filters },
			Author.generateObject(auth.currentUser.uid)
		)
	)

	return fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('permissions')
		.doc(permissionId)
		.set(permissionObject, { merge: true })
}

/**
 * [Firestore] Will remove [DISABLE] a single company-group-permissions
 * ! Works specifically for COMPANY GROUP PERMISSIONS
 *
 * @description Created the function using Firebase RTDB
 * @author wegner
 * @version 1.0
 *
 * @description Changed the function to use Firestore
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {string} companyId The current active company ID
 * @param {string} groupId The current company-group ID
 * @param {string} permissionId The permission being DISABLED
 */
export const doRemoveRelation = (companyId, groupId, permissionId) => {
	return fs
		.collection('companies')
		.doc(companyId)
		.collection('groups')
		.doc(groupId)
		.collection('permissions')
		.doc(permissionId)
		.update(ObjectState.generateActive(false))
}
