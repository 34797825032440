import { createSelector } from 'reselect'

/**
 * Gets the **global** _search-text_ data from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getGlobalSearchText = (state) => state.globalFiltersState.searchText

/**
 * [memoised] Selector/Factory for the **global** _search-text_ data from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalSearchText = () =>
	createSelector(
		getGlobalSearchText,
		(searchText) => searchText
	)

/**
 * Gets the **global** _plugin-filters_ (configuration) object from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const getGlobalPluginFilters = (state) =>
	state.globalFiltersState.pluginFilters

/**
 * Gets the **global** _plugin-filter-keys_ (collection) object from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const getGlobalPluginFilterKeys = (state) => {
	const globalFilters = getGlobalPluginFilters(state),
		globalFilterKeys = !!globalFilters ? Object.keys(globalFilters) : null

	return globalFilterKeys
}

/**
 * [memoised] Selects the **global** _plugin-filters_ (configuration) object from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalPluginFilters = () =>
	createSelector(
		getGlobalPluginFilters,
		(pluginFilters) => pluginFilters
	)

/**
 * [memoised] Selects the **global** _plugin-filter-keys_ (collection) object from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalPluginFilterKeys = () =>
	createSelector(
		getGlobalPluginFilterKeys,
		(pluginFilterKeys) => pluginFilterKeys
	)

/**
 * Gets the **global** _filter-values_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const getGlobalFilterValues = (state) => {

	return state.globalFiltersState.values
}

/**
 * [memoised] Selects the **global** _filter-values_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFilterValues = () =>
	createSelector(
		getGlobalFilterValues,
		(values) => values
	)

/**
 * Gets a **global** _filter-values_ specific collection from state, nullable
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 * @param {string} dataSetId The data-set-id of the current desired collection
 */
const getGlobalFilterDataSetValues = (state, dataSetId) => {
	const filterValues = getGlobalFilterValues(state),
		filterKeys = Object.keys(filterValues)

	//TODO: test whether null goes better than empty list
	return filterKeys.includes(dataSetId) ? filterValues[dataSetId] : null
}

/**
 * Gets a **global** _filter-values_ specific collection from state, non-nullable (returns empty array)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 * @param {string} dataSetId The data-set-id of the current desired collection
 */
const getGlobalFilterDataSetValuesNullSafe = (state, dataSetId) => {
	const dataSetValues = getGlobalFilterDataSetValues(state, dataSetId)

	//If a valid value of the data-set is prone, sends it, otherwise an empty array (null-safe)
	return !!dataSetValues ? dataSetValues : []
}

/**
 * [memoised] Gets a **global** _filter-values_ specific collection from state, nullable
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFilterDataSetValues = () =>
	createSelector(
		getGlobalFilterDataSetValues,
		(values) => values
	)

/**
 * [memoised] Gets a **global** _filter-values_ specific collection from state, null-safe
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFilterDataSetValuesNullSafe = () =>
	createSelector(
		getGlobalFilterDataSetValuesNullSafe,
		(values) => values
	)

/**
 * Gets the **global** _filter-keys_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 */
const getGlobalFilterKeys = (state) => state.globalFiltersState.filters

/**
 * [memoised] Selects the **global** _filter-keys_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFilterKeys = () =>
	createSelector(
		getGlobalFilterKeys,
		(filterKeys) => filterKeys
	)

/**
 * Gets the **global** _selective-filters_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 */
export const getGlobalSelectiveFilters = (state) =>
	state.globalFiltersState.selectiveFilters

/**
 * [memoised] Selects the **global** _selective-filters_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalSelectiveFilters = () =>
	createSelector(
		getGlobalSelectiveFilters,
		(selectiveFilters) => selectiveFilters
	)

/**
 * Gets the **global** filter _fixed_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 */
export const getGlobalFixedFilters = (state) => state.globalFiltersState.fixed

/**
 * Gets the **global** _filters-labels_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 */
const getGlobalFilterLabels = (state) => state.globalFiltersState.labels

/**
 * [memoised] Selects the **global** _filters-labels_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFilterLabels = () =>
	createSelector(
		getGlobalFilterLabels,
		(labels) => labels
	)

/**
 * Gets the **global** filter _is-loading_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 */
const getGlobalFilterIsLoading = (state) => state.globalFiltersState.isLoading

/**
 * [memoised] Selects the **global** filter _is-loading_ collection from state
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalFilterIsLoading = () =>
	createSelector(
		getGlobalFilterIsLoading,
		(isLoading) => isLoading
	)

/**
 * Gets the _dataSet-name_ from a specific **global-plugin-filter**, by its ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state tree
 * @param {string} dataSetId The data-set-id of the current desired collection
 */
const getGlobalPluginFilterDataSetById = (state, dataSetId) => {

	const pluginFilters = getGlobalPluginFilters(state)

	const dataSetName = !!pluginFilters[dataSetId] ? pluginFilters[dataSetId].dataSet : null

	return dataSetName
}

/**
 * [memoised] Selects the _dataSet-name_ from a specific **global-plugin-filter**, by its ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetGlobalPluginFilterDataSetById = () =>
	createSelector(
		getGlobalPluginFilterDataSetById,
		(dataSetName) => dataSetName
	)
