import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Redirect } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import {
	makeGetActivePlugin,
	makeGetActivePluginId
} from '../../../selectors/plugin'

/**
 * Prevents those components which depend on activePlugin/activePluginId
 * from crashing, from an undefined/null value set improperly.
 * In case no plugin is currently active, will redirect to a plugin-selector route.
 *
 * **_Will set the following children props:_**
 * * activePlugin
 * * activePluginId
 *
 * @description Created the HOC
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {React.Component|React.PureComponent} Component The component
 * 														which will be protected against
 * 														active-plugin-key failure
 */
const withActivePlugin = (Component) => {
	class WithActivePlugin extends React.PureComponent {
		render() {
			const { activePluginId } = this.props

			//In case the plugin-Key isn't available, instead of crashing
			//Will redirect the user to the plugin screen
			return !activePluginId ? (
				<Redirect to={routes.PLUGIN} />
			) : (
				<Component {...this.props} />
			)
		}
	}

	/**
	 * Memoized mapStateToProps
	 * Will map activePlugin and activePluginId OR false for activePluginId
	 * Which is used onwards for validation
	 */
	const makeMapStateToProps = () => {
		const getActivePlugin = makeGetActivePlugin(),
			getActivePluginId = makeGetActivePluginId()

		const mapStateToProps = (state) => {
			const activePlugin = getActivePlugin(state),
				activePluginId = getActivePluginId(state)

			return {
				activePluginId: activePluginId,
				activePlugin: activePlugin
			}
		}

		return mapStateToProps
	}

	return compose(connect(makeMapStateToProps()))(WithActivePlugin)
}

export default withActivePlugin
