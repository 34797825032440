export const MANAGE_ACCUMULATED_VOLUME_LOADING_STATE =
		'MANAGE_ACCUMULATED_VOLUME_LOADING_STATE',
	LOAD_ACCUMULATED_VOLUME_DATA = 'LOAD_ACCUMULATED_VOLUME_DATA',
	SET_ACCUMULATED_VOLUME_PERIOD = 'SET_ACCUMULATED_VOLUME_PERIOD'

export const setAccumulatedVolumePeriod = (dateConfig, value, formType) => ({
	type: SET_ACCUMULATED_VOLUME_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadAccumulatedVolumeData = (accumulatedVolume) => ({
	type: LOAD_ACCUMULATED_VOLUME_DATA,
	accumulatedVolume
})

export const setLoading = (isLoading) => ({
	type: MANAGE_ACCUMULATED_VOLUME_LOADING_STATE,
	isLoading
})
