const INITIAL_STATE = {
	groups: null,
	group: null,
	groupKey: null
}

/**
 * Takes care of clearing selected group
 *
 * @param {*} state
 * @param {*} action
 */
const clearGroup = (state, action) => ({
	...state,
	...INITIAL_STATE
})

/**
 * Declares the Redux group state manager
 *
 * @param {Object} state
 * @param {Object} action
 */
function groupReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'COMPANY_GROUPS_LOADED': {
			return Object.assign({}, state, { groups: action.groups })
		}
		case 'SET_SELECTED_GROUP': {
			return Object.assign({}, state, { group: action.group })
		}

		case 'CLEAR_GROUP': {
			return clearGroup(state, action)
		}
		case 'SET_GROUP_KEY': {
			return Object.assign({}, state, {
				groupKey: action.key
			})
		}
		default:
			return state
	}
}

export default groupReducer
