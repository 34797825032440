export const MANAGE_ENERGY_CONSUMPTION_LOADING_STATE =
		'MANAGE_ENERGY_CONSUMPTION_LOADING_STATE',
	LOAD_ENERGY_CONSUMPTION_DATA = 'LOAD_ENERGY_CONSUMPTION_DATA',
	SET_ENERGY_CONSUMPTION_PERIOD = 'SET_ENERGY_CONSUMPTION_PERIOD'

export const setEnergyConsumptionPeriod = (dateConfig, value, formType) => ({
	type: SET_ENERGY_CONSUMPTION_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadEnergyConsumptionData = (energyConsumption) => ({
	type: LOAD_ENERGY_CONSUMPTION_DATA,
	energyConsumption
})

export const setLoading = (isLoading) => ({
	type: MANAGE_ENERGY_CONSUMPTION_LOADING_STATE,
	isLoading
})
