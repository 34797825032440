import { auth, fs } from '../firebase'

/**
 * SignUp function
 */
export const doCreateUserWithEmailAndPassword = (email, password) =>
	auth.createUserWithEmailAndPassword(email, password)

/**
 * SignIn function
 */
export const doSignInWithEmailAndPassword = (email, password) =>
	auth.signInWithEmailAndPassword(email, password)

/**
 * SignOut function
 */
export const doSignOut = async () => {
	try {
		let s = await auth.signOut()
		return s
	} catch (e) {
		console.log('App: Logging out errored :/')
		console.log(e)
	}
}

/**
 * PasswordResetPage function
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} email addres to send password reset email
 */
export const doPasswordReset = (email) =>
	fs
		.collection('resetPassToken')
		.doc()
		.set({ active: true, email, generated: new Date() })

export const doPasswordResetOnboarding = (email) =>
	fs
		.collection('resetPassToken')
		.doc()
		.set({ onboarding: true, email, generated: new Date() })

/**
 * PasswordChange function
 */
export const doPasswordChange = (password) =>
	auth.currentUser.updatePassword(password)
