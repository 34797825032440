import { newStorage } from './firebase'
import b64toBlob from 'b64-to-blob'

/**
 *
 * @param {String} b64 base64 string from dom-to-image
 * this method is important because when we upload the image, they body should have only base64 string url
 *
 * Ex:
 * 	the body is something like: <content;base64,~base64 url~>
 *
 * As parameter we just need the base64 url part
 */
function formatToBlob(b64) {
	//TODO: check why iOS is crashing here
	const formattedB64 =
		undefined !== b64 && null !== b64 ? b64.split(',')[1] : ''

	return formattedB64
}

/**
 *
 * @param {*} blob binary data for the snapshot generate from DOM
 * @param  {...any} paths companyId and actionId respectively
 */
export const saveImage = async (b64, ...paths) => {
	let blob = b64toBlob(formatToBlob(b64), 'image/png')

	//Creates the image by a path
	let pathReference = defineImagePath(paths[0], paths[1])

	let response = await pathReference.put(blob)

	return response.ref.getDownloadURL()

	// .then(response => {
	// 	console.log('response', response)

	// 	// //When under development, writes at the console what happened
	// 	// if (process.env.NODE_ENV === 'development')
	// 	// 	console.log('Firebase UploadTaskSnapshot: ', response)

	// 	return response.ref.getDownloadURL()
	// })
	// .catch(error => {
	// 	console.log('error saveImage', error)
	// })
}

/**
 *
 * @param {string} companyId company relationed with created action
 * @param {string} actionId relationed action
 */
export const defineImagePath = (companyId, actionId) =>
	newStorage.refFromURL(
		`gs://agildash-app-db.appspot.com/companies/${companyId}/actions/${actionId}/snapshot.png`,
	)

/**
 * @author davispindola
 * @version 1.0.0
 *
 * @param {String} companyId
 * @param {String} actionId
 */
export const getImageUrlByPath = (companyId, actionId) =>
	newStorage
		.ref(`companies/${companyId}/actions/${actionId}/snapshot.png`)
		.getDownloadURL()

/**
 * @author lopes
 * @version 1.0.0
 * @param {string} companyId company relationed with created action
 */
export const defineImageLogoPath = companyId =>
	newStorage.ref().child(`companies/${companyId}/logo/snapshot.png`)

/**
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible for upload json data file to cloud storage for future usage.
 *
 * @param {String} companyId
 * @param {String} actionId
 */
export const saveActionJSONData = async (companyId, actionId) => {
	const data = JSON.stringify(localStorage.getItem('action-json'))

	// const file = fs.writeFile('data.json', data, 'utf8', (err, data) => {
	// 	if (err) {
	// 		if (!(process.env.NODE_ENV === 'productions')) alert(err)
	// 	} else {
	// 		if (!(process.env.NODE_ENV === 'productions'))
	// 			alert('the file has been saved!')
	// 		return data
	// 	}
	// })

	const file = new File([data], 'data.json', { type: 'application/json' })

	let pathImage = newStorage.refFromURL(
		`gs://agildash-app-db.appspot.com/companies/${companyId}/actions/${actionId}/data.json`,
	)

	let uplodadFileJson = await pathImage.put(file)

	return uplodadFileJson.ref.getDownloadURL()
}
