import React from 'react'
import PropTypes from 'prop-types'
import { Chip, Tooltip, Icon } from '@material-ui/core'

import { translate } from '../../../../../utils/lang'
import { getStyle } from './utils'
import { ActionEvaluateButton } from '../../../../reusable/systemEvaluation/CardAction'

/**
 * @description Created the class
 * @author davispindola
 * @version 1.0
 *
 * @description changed for functional component
 * @author Davi Wegner
 * @version 1.1
 */
const ActionFooter = ({
	classes,
	type,
	classification,
	status,
	hasAnswer,
	receiverRead,
	actionId,
	readerType,
	handleClick
}) => {
	return (
		<React.Fragment>
			<div className={classes.header}>
				<div>
					<Chip
						color="primary"
						classes={{ label: classes.chipLabel }}
						className={classes.chipsMargin}
						variant={'outlined'}
						label={type}
					/>
					<Chip
						color="primary"
						classes={{ label: classes.chipLabel }}
						className={classes.chipsMargin}
						variant={'outlined'}
						label={classification}
					/>
					<Chip
						variant={'outlined'}
						classes={{ label: classes.chipLabel }}
						className={classes.chips}
						style={getStyle(status)}
						//TODO take the validation to container
						label={
							status
								? translate('label/action-open')
								: translate('label/action-closed')
						}
					/>
				</div>
				<div className={classes.iconContainer}>
					{!!hasAnswer && (
						<Tooltip title={translate('label/has-answer')}>
							<Icon className={classes.icons}>
								keyboard_return
							</Icon>
						</Tooltip>
					)}
					{!!receiverRead && (
						<Tooltip title={translate('label/has-view')}>
							<Icon className={classes.icons}>drafts</Icon>
						</Tooltip>
					)}
				</div>
			</div>
			{!status && readerType === 'authorOpen' && (
				<ActionEvaluateButton
					handleClick={handleClick}
					actionId={actionId}
				/>
			)}
		</React.Fragment>
	)
}

ActionFooter.propTypes = {
	classes: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	classification: PropTypes.string.isRequired,
	status: PropTypes.bool.isRequired,
	hasAnswer: PropTypes.bool.isRequired,
	receiverRead: PropTypes.bool.isRequired
}

ActionFooter.defaultProps = {
	type: '',
	classification: '',
	status: true,
	hasAnswer: false,
	receiverRead: false
}

export default ActionFooter
