export const ALL_COMPANY_FILTERS_LOADED = 'ALL_COMPANY_FILTERS_LOADED',
	CLEAR_COMPANY_FILTER_LIST = 'CLEAR_COMPANY_FILTER_LIST',
	COMPANY_FILTERS_IS_LOADING = 'COMPANY_FILTERS_IS_LOADING'

const INITIAL_STATE = {
	companyFilters: null,
	isLoading: false
}

/**
 *
 * @description Created the function
 * @author davispindola
 * @version 1.0
 *
 * @param {Object} state
 * @param {Object} action
 */
const isLoading = (state, action) =>
	Object.assign({}, state, {
		isLoading: action.isLoading
	})

/**
 * Declares the Redux company filters state manager
 *
 * @description Created the reducer
 * @author Davi Wegner
 * @version 1.0
 *
 * @description Implemented isLoading property
 * @author davispindola
 * @version 2.0
 *
 * @param {Object} state
 * @param {Object} action
 */
function companyFiltersReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ALL_COMPANY_FILTERS_LOADED: {
			return Object.assign({}, state, {
				companyFilters: Object.assign(
					{},
					state.companyFilters,
					action.companyFilters
				)
			})
		}
		case CLEAR_COMPANY_FILTER_LIST: {
			return Object.assign({}, state, {
				companyFilters: INITIAL_STATE.companyFilters
			})
		}
		case COMPANY_FILTERS_IS_LOADING: {
			return isLoading(state, action)
		}
		default:
			return state
	}
}

export default companyFiltersReducer
