import React from 'react'
import { withRouter } from 'react-router-dom'
import * as routes from '../../../../constants/routes'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import ForgotPasswordForm from './ForgotPassword'
import {
	SET_FORGOT_PASSWORD_EMAIL,
	SET_FORGOT_PASSWORD_IS_LOADING,
	SET_FORGOT_PASSWORD_WAS_SENT,
	SET_FORGOT_PASSWORD_ERROR
} from '../../../../reducers/user/account/forgotPassword'
import * as Auth from '../../../../firebase/auth'
import { translate } from '../../../../utils/lang'
import { showSnackBar } from '../../../../actions/snackbar'
// import { emailIsValid } from '../../../../utils/validation/email'

/**
 * Component responsible for manage the forgot password manage and valid the context flow
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Changed submit method and some dispatches
 * @author brunoteixeirasilva
 * @version 1.1
 */
class ForgotPasswordContainer extends React.Component {
	handleSubmit = (e) => {
		const {
			email,
			setIsLoading,
			setWasSent,
			setNotification,
			setError
		} = this.props
		// e.preventDefault()
		//Validates email on separate function
		// console.log('a')
		// if (!emailIsValid(email)) {
		// 	return false
		// }
		// console.log('a')
		//Here we have a valid process
		setIsLoading(true)
			.then(() => Auth.doPasswordReset(email))
			.then(() => setWasSent())
			.then(() => setTimeout(() => setIsLoading(false), 1000))
			.catch((e) => {
				setNotification(translate(e.code))
				setIsLoading(false)
				setError(e)
			})

		e.preventDefault()
	}

	handleReturn = (event) => {
		const { history } = this.props

		history.push(routes.SIGN_IN)
	}

	render() {
		const { email, setEmail, error } = this.props

		return (
			<ForgotPasswordForm
				hasError={error}
				email={email}
				handleInput={setEmail}
				handleSubmit={this.handleSubmit}
				handleReturn={this.handleReturn}
			/>
		)
	}
}

const makeMapStateToProps = () => {
	const mapStateToProps = (state) => ({
		email: state.forgotPasswordState.email,
		error: state.forgotPasswordState.error
	})

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	setEmail: ({ target }) =>
		dispatch({ type: SET_FORGOT_PASSWORD_EMAIL, email: target.value }),
	setIsLoading: async (isLoading) =>
		dispatch({ type: SET_FORGOT_PASSWORD_IS_LOADING, isLoading }),
	setWasSent: () => dispatch({ type: SET_FORGOT_PASSWORD_WAS_SENT }),
	setNotification: (notification) => dispatch(showSnackBar(notification)),
	setError: (error) => dispatch({ type: SET_FORGOT_PASSWORD_ERROR, error })
})

export default compose(
	withRouter,
	connect(makeMapStateToProps(), mapDispatchToProps)
)(ForgotPasswordContainer)
