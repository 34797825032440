import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withWidth } from '@material-ui/core'

/**
 * Renders a default document viewer. Thence, this is a wrapper for docs view-port sizing.
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class DocViewPort extends Component {
	render() {
		const { children, theme, width } = this.props

		return (
			<div
				style={{
					maxWidth: 584,
					margin:
						width === 'xs'
							? `${theme.spacing.unit}px ${theme.spacing.unit}px`
							: `${theme.spacing.unit}px auto`
				}}
			>
				{children}
			</div>
		)
	}
}

DocViewPort.propTypes = {
	children: PropTypes.node.isRequired,
	theme: PropTypes.object.isRequired,
	width: PropTypes.string.isRequired
}

export default withWidth({ withTheme: true })(DocViewPort)
