const styles = theme => ({
	root: {
		flexGrow: 1,
		height: '100vh',
		// marginTop: theme.spacing.unit * 2,
		// paddingBottom: '150px',
	},
})

export default styles
