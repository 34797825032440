import { SET_CONFIG } from '../../actions/app/config'

const INITIAL_STATE = {
	app: {
		defaultTitle: null,
		title: null,
		debug: false,
		drawerOpen: false,
		barLeftIcon: null,
		barLeftIconAction: null,
		barLeftIconAriaLabel: null,
		barRightIcon: null,
		barRightIconAction: null,
		barRightIconAriaLabel: null,
		barRightIconTooltip: null
	}
}

/**
 * Sets a single configuration property
 * All config props will be returned withing an "app" property
 * Of the next state
 */
const applySetConfig = (state, action) => {
	let nextState = Object.assign({}, state)

	//No config set yet
	if (!nextState.app) nextState.app = INITIAL_STATE

	Object.keys(action).forEach((key, index) => {
		//Not the action type describer
		if (key === 'type') return

		//Sets the config property
		nextState.app[key] = action[key]
	})

	return nextState
}

/**
 * Will set the app config object entirely
 *
 * @param {Object} state
 * @param {Object} action
 */
const configLoaded = (state, action) =>
	Object.assign({}, state, { app: action.config })

/**
 * Manages to reset a config by its prop name
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {*} action The action with "type" and desired prop "key"
 */
const resetConfig = (state, action) => {
	let nextState = Object.assign({}, state, {
		app: Object.assign({}, state.app, {
			[action.key]: INITIAL_STATE.app[action.key]
		})
	})

	return nextState
}

/**
 * Manages app-config redux-state-layer
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app-config-state
 * @param {*} action The action with "type" and desired prop to be treated
 */
function appConfigReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_CONFIG: {
			return applySetConfig(state, action)
		}
		case 'LOAD_CONFIG': {
			return configLoaded(state, action)
		}
		case 'RESET_CONFIG_BY_KEY': {
			return resetConfig(state, action)
		}
		default:
			return Object.assign({}, state, INITIAL_STATE)
	}
}

export default appConfigReducer
