import * as CompanyDashItemSteps from '../../../../firebase/company/dashItem/step'
import { getUserActiveCompanyId } from '../../../../selectors/user/company'
import { store } from '../../../../store'
import { setRootDashItemSteps } from '../../../../actions/dashItem/step'
import { setCompanyDashItemStep } from '../../../../actions/step/manager'

/**
 * Manages to pick the useful step for the current dash-item-id.
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {firebase.firestore.QuerySnapshot} stepsSnapshot The collection of dashItem steps
 * @param {string} dashItemId The dash-item-id for this case
 * @param {number} stepIndex The desired step-index useful for this case
 * @param {string} activeCompanyId [optional] The current activeCompanyId for this case. If not specified,
 * 									will pick from redux-state
 */
export const pickUsefulSteps = (
	stepsSnapshot,
	dashItemId,
	stepIndex,
	activeCompanyId = null
) => {
	const state = store.getState()
	//If no active company was specified, finds it from the selector
	const usefulActiveCompany = !!activeCompanyId
		? activeCompanyId
		: getUserActiveCompanyId(state)

	// let promises = [],
	let step = null,
		docs = stepsSnapshot.docs,
		docsData = docs.reduce(
			(data, currentDoc) => ({
				...data,
				[currentDoc.id]: currentDoc.data()
			}),
			{}
		)

	//TODO: dispatch steps root config to redux
	//TODO: @daviwegner this should be already ok from dashSet
	store.dispatch(setRootDashItemSteps(dashItemId, docsData))

	//Getting first step
	step = docs.length > 0 ? docs[stepIndex] : null

	return !!step
		? CompanyDashItemSteps.getById(usefulActiveCompany, dashItemId, step.id)
		: Promise.resolve({})
}

/**
 * Manages to solve the useful step for the dash-item screen
 * keeping information for the next call.
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {firebase.firestore.DocumentSnapshot} usefulStep The least useful object for dash-item
 */
export const solveUsefulSteps = (dashItemId, usefulStep) => {
	// const dataSets = []
	let mappedStep = null,
		stepId = null

	//When the step is valid (Firestore "exists")
	if (!!usefulStep.exists) {
		//Mapping the step-data from snapshot
		stepId = usefulStep.id
		mappedStep = { ...usefulStep.data() }

		//Will dispatch this step to the specific dashItem configuration state tree
		store.dispatch(
			setCompanyDashItemStep(dashItemId, { [stepId]: mappedStep })
		)
	}

	return Promise.resolve({
		stepId: stepId,
		step: mappedStep,
		//For simplifying work processing
		dataSetKeys:
			!!mappedStep && !!mappedStep.dataSets
				? Object.keys(mappedStep.dataSets)
				: null
	})
}
