import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import withMappedDom from '../withMappedDom/index'
import DialogModal from '../../app/userActions/components/DialogModal'
import {
	openActionModal,
	closeActionModal,
} from '../../../reducers/action/navigation'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import { translate } from '../../../utils/lang'
import ExtendedDialog from '../dialog/extended'
import ExpandableButton from '../button/more/expandable'
import FilterModal from '../filter/modal/FilterModal'
import { jsPDF } from 'jspdf'

/**
 * A card detailing component with extended vision.
 *
 * @description Created the component
 * @author ?
 * @version 1.0
 *
 * @description Implemented the filter list
 * @author davispindola
 * @version 1.2
 *
 * @description Removed previous filters implementation, changed naming conventions
 * @author brunoteixeirasilva
 * @version 1.3
 *
 * @description Implemented actions from light form
 * @author jeffersonguilhermemachadobarreto
 * @version 1.4
 */
class ExtendedCard extends React.Component {
	state = {
		tooltipOpen: false,
		title: '',
		actions: [],
		anchorEl: null,
		value: null,
		completed: {},
		closeInspection: false,
		responseImage: null,
	}

	constructor(props) {
		super(props)
		this.state.open = this.props.open
		this.state.classes = this.props.classes
	}

	handleMenuOpen = event => {
		this.setState({
			anchorEl: event.currentTarget,
		})
	}

	handleMenuClose = () => {
		this.setState({
			anchorEl: null,
		})
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			closeInspection,
			showModalForm,
			handleActionModal,
			actionModalState,
			handleCloseActionModal,
		} = this.props

		if (closeInspection !== this.state.closeInspection) {
			this.setState({
				closeInspection: closeInspection,
			})
		}

		if (showModalForm === true && actionModalState === false) {
			handleActionModal()
			this.share()
		}

		if (showModalForm === false && actionModalState === true) {
			handleCloseActionModal()
			this.share()
		}
	}

	downloadImage() {
		htmlToImage.toPng(ReactDOM.findDOMNode(this)).then(dataUrl => {
			download(dataUrl, 'image.png')
		})
	}

	downloadPDF() {

		const { title } = this.props

		htmlToImage.toPng(ReactDOM.findDOMNode(this)).then(dataUrl => {

			let date = new Date(),
				month = date.getMonth() + 1,
				name = `${title} - ${date.getDate()}/${month}/${date.toDateString().split(' ')[3]} às ${date.getHours()}:${date.getMinutes()}`

			let doc = new jsPDF()
			doc.setFontSize(20)
			doc.text(10, 10, name)
			doc.addImage(dataUrl, 'JPEG', 15, 40, 180, 120)
			doc.save(name + '.pdf')
		})
	}

	share() {
		htmlToImage.toPng(ReactDOM.findDOMNode(this)).then(dataUrl => {
			this.setState({
				responseImage: dataUrl,
			})
		})
	}

	urltoFile(url, filename, mimeType) {
		mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1]
		return (fetch(url).then((res) => {
			return res.arrayBuffer()
		}).then((buf) => {
			return new File([buf], filename, {type: mimeType})
		}))
	}

	shareImage = () => {

		htmlToImage.toPng(ReactDOM.findDOMNode(this)).then(dataUrl => {
			return this.urltoFile(dataUrl, 'image.png')
		}).then(async (image) => {

			const shareData = {
				title: 'Agildash',
				text: 'Agildash',
				files: [image]
			}

			try {
				await navigator.share(shareData)
			} catch(err) {
				console.log('Error to send image', err)
			}
		})
	}

	generateButtons = () => {
		const {
			handleActionModal,
			handleFilterModal,
			data,
			getJSON,
			getDomSnapshot,
			onSetShowModalForm,
			onSetIsAction,
		} = this.props

		const self = this //maintaining the right reference

		return [
			{
				key: 'action',
				label: translate(`label/generate-action`),
				action: async () => {
					onSetShowModalForm(true)
					onSetIsAction(true)
					getJSON(data)
					getDomSnapshot(ReactDOM.findDOMNode(self))
					handleActionModal()
				},
			},
			{
				key: 'filter',
				label: translate(`label/filters`),
				action: () => handleFilterModal(),
			},
			{
				key: 'downloadImage',
				label: translate('label/download-image'),
				action: () => this.downloadImage(),
			},
			{
				key: 'downloadPDF',
				label: translate('label/download-PDF'),
				action: () => this.downloadPDF(),
			},
			{
				key: 'compartilhar',
				label: 'Compartilhar',
				action: () => this.shareImage()
			},
		]
	}
	render() {
		const {
			open,
			children,
			filterList,
			title,
			cardId,
			filterModalState,
			handleFilterModal,
			dashItem
		} = this.props
		const handleClose = this.props.handleClose

		return (
			<ExtendedDialog
				parentOpen={open}
				parentClose={handleClose}
				dashItem={dashItem}
				title={title}>
				{filterList}
				{children}
				<DialogModal cardId={cardId} />
				<FilterModal
					open={filterModalState}
					onClose={handleFilterModal}
				/>
				<ExpandableButton options={[...this.generateButtons()]} />
			</ExtendedDialog>
		)
	}
}

const extendedStyle = theme => ({
	filterArea: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		backgroundColor: 'rgba(80,80,80,0.8)',
	},
	card: {
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		paddingBottom: 0,
		marginBottom: '10px',
	},
	iconHover: {
		paddingRight: '5px',
		fontSize: '24px',
		color: 'rgba(120,120,120,0.8)',
		'&:hover': {
			color: 'rgba(0,0,0,1)',
			transform: 'scale(1.3)',
		},
	},
	cardContent: {
		textAlign: 'center',
		flexGrow: 1,
		width: '100%',
		height: '280px',
		overflowX: 'hidden',
		overflowY: 'hidden',
		paddingLeft: 0,
		paddingRight: 0,
		paddingTop: 0,
		paddingBottom: '0 !important',
		marginLeft: 0,
		marginRight: theme.spacing.unit,
	},
	chip: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
		marginLeft: theme.spacing.unit / 2,
		marginRight: theme.spacing.unit / 2,
	},
	title: {
		marginBottom: theme.spacing.unit * 3,
		paddingBottom: 0,
	},
	pos: {
		marginBottom: theme.spacing.unit * 2,
	},
	paper: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: 'transparent',
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
	},
	dialog: {
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
	},
	fabButton: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
		backgroundColor: 'red',
		color: 'white',
	},
	fabTop: {
		position: 'fixed',
		backgroundColor: 'white',
		color: 'black',
		top: theme.spacing.unit * 3,
		right: theme.spacing.unit * 2,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	stepperPaper: {
		width: '100%',
		paddingBottom: theme.spacing.unit * 2,
	},
	contentContainer: {
		height: '100%',
	},
})

ExtendedCard.propTypes = {
	classes: PropTypes.object.isRequired,
}

const makeMapStateToProps = () => {
	const mapStateToProps = state => {
		return {
			actionModalState: state.actionsState.showActionModal,
			filterModalState: state.filterModalState.open,
			closeInspection: state.lightState.closeInspection,
			showModalForm: state.lightState.showModalForm,
		}
	}

	return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
	handleFilterModal: () => dispatch({ type: 'TOGGLE_FILTER_MODAL' }),
	handleActionModal: () => dispatch(openActionModal()),
	handleCloseActionModal: () => dispatch(closeActionModal()),
	onSetShowModalForm: async showModalForm =>
		dispatch({ type: 'SET_SHOW_MODAL_FORM', showModalForm }),
	onSetIsAction: async isAction =>
		dispatch({ type: 'SET_IS_ACTION', isAction }),
})

export default compose(
	connect(makeMapStateToProps(), mapDispatchToProps),
	withStyles(extendedStyle),
	withMappedDom,
)(ExtendedCard)
