import React from 'react'
import {
	Dialog,
	DialogContent,
	DialogActions,
	withStyles,
	FormGroup,
	Typography,
	Button
} from '@material-ui/core'
import style from './style'
import { translate } from '../../../../../utils/lang'
import ChipFormSelect from './ChipFormSelect'
import SortButton from '../../../button/sort'

/**
 * own select menu with icon button not input
 * @description created the component
 * @author Davi Wegner
 * @version 1.0
 * TODO: MAKE THIS BETTER
 */
class OwnFiltersDialog extends React.Component {
	handleSelect = (event, value, dataSet, filterId) => {
		let {
			ownFilters,
			setSelected,
			selectedValues = [],
			customSelect
		} = this.props
		if (customSelect instanceof Function)
			return customSelect(dataSet, value)

		let filterInfo = ownFilters[filterId],
			selectedInfo = selectedValues[dataSet]

		if (
			!!filterInfo &&
			filterInfo.required &&
			!!selectedInfo &&
			selectedInfo.length === 1 &&
			selectedInfo.includes(value)
		)
			return
		// 	!!required &&
		// 	!!selectedValues &&
		// 	selectedValues.length === 1 &&
		// 	selectedValues.includes(value)
		// )
		// 	return null

		if (!!selectedInfo)
			selectedInfo.includes(value)
				? selectedInfo.splice(selectedInfo.indexOf(value), 1)
				: selectedInfo.push(value)

		setSelected(dataSet, selectedInfo)
	}
	handleComparator = (event, value, dataSet, filterId, item) => {
		let { setComparators, comparers = {} } = this.props

		let selectedInfo = comparers[filterId]

		if (!!selectedInfo) {
			selectedInfo = [value]
		}
		if (selectedInfo.length === 0) selectedInfo = ['']

		setComparators(filterId, selectedInfo)
	}
	render() {
		const {
			ownFilters,
			selectedValues,
			open,
			handleClose,
			filters,
			loadData,
			reverseTeams,
			classes,
			teamList,
			comparers,
			dataSourceId
		} = this.props

		return (
			<Dialog fullWidth open={open} onClose={handleClose}>
				<DialogContent>
					<Typography variant="h6">
						{translate('label/filter-by')}
					</Typography>
					<div className={classes.container}>
						<FormGroup>
							{!!ownFilters &&
								Object.keys(ownFilters).map((filter) => {
									return (
										<ChipFormSelect
											key={filter}
											dataSourceId={dataSourceId}
											dataSet={ownFilters[filter].dataSet}
											label={translate(
												`label/${
													ownFilters[filter].label
												}`
											)}
											onSelect={this.handleSelect}
											selectedValues={
												selectedValues[
													ownFilters[filter].dataSet
												]
											}
											options={
												filters[
													ownFilters[filter].dataSet
												]
											}
											filterId={filter}
											loadData={loadData}
										/>
									)
								})}
							{!!teamList &&
								[
									{
										label: translate(
											'label/allocated-service-over-goal'
										),
										key: 'perc_allocated',
										options: [
											{
												key: '',
												label: translate('label/all')
											},
											{
												key: '<',
												label: translate(
													'label/not-enough'
												)
											},
											{
												key: '>=',
												label: translate('label/enough')
											}
										]
									},
									{
										label: translate(
											'label/produced-over-goal'
										),
										key: 'perc_reach',
										options: [
											{
												key: '',
												label: translate('label/all')
											},
											{
												key: '<',
												label: translate(
													'label/not-hited'
												)
											},
											{
												key: '>=',
												label: translate('label/hited')
											}
										]
									}
								].map((item) => {
									return (
										<ChipFormSelect
											key={item.key}
											label={item.label}
											options={item.options}
											selectedValues={comparers[item.key]}
											filterId={item.key}
											onSelect={this.handleComparator}
										/>
									)
								})}
						</FormGroup>
					</div>
					<div>
						{teamList && (
							<React.Fragment>
								<div className={classes.container}>
									<Typography variant="h6">
										{translate('label/order-by')}
									</Typography>
									<div className={classes.sortButton}>
										<SortButton
											label={translate(
												'label/goal-reach'
											)}
											onClick={(e) => {
												reverseTeams()
											}}
										/>
									</div>
								</div>
							</React.Fragment>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>
						{translate('label/close')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

OwnFiltersDialog.propTypes = {}

OwnFiltersDialog.defaultProps = {
	icon: 'toggle_on'
}

export default withStyles(style)(OwnFiltersDialog)
