import React from 'react'
import { Icon, withStyles, IconButton, Tooltip } from '@material-ui/core'
import { translate } from '../../../../utils/lang'

/**
 * Manages to display an IconButton for only mobile (down from "sm")
 *
 * @description Created the component
 * @author lopes
 * @version 1.0
 *
 * @param {Object} props
 */
const MobileIcon = (props) => {
	const { classes, onClick } = props

	return (
		<Tooltip title={translate('label/generate-action')}>
			<IconButton
				className={classes.root}
				arialabel={translate(`label/generate-action`)}
				onClick={onClick}
			>
				<Icon>local_see</Icon>
			</IconButton>
		</Tooltip>
	)
}

const styles = (theme) => ({
	root: {
		paddingRight: '5px',
		fontSize: '24px',
		color: 'rgba(120,120,120,0.8)',
		'&:hover': {
			color: 'rgba(0,0,0,1)',
			transform: 'scale(1.3)'
		}
	},
	contentContainer: {
		margin: theme.spacing.unit
	}
})

export default withStyles(styles)(MobileIcon)
