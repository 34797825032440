import React from 'react'
import { withStyles, ListItem } from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withAuthentication from '../../../../reusable/withAuthentication'
import BuzzList from '../../../../reusable/list/buzz'
import Loader from '../../../../reusable/loader'
import NotificationItem from './NotificationItem'
import EmptyListItem from '../../../../reusable/list/empty/item/EmptyListItem'
import * as UserNotification from '../../../../../firebase/user/notification'
import { makeNotificationSorter } from '../../../../../selectors/notification/sort'
import { SET_ACTION_ID } from '../../../../../reducers/action'
import { openActionModal } from '../../../../../reducers/action/navigation'

// style object for both components
const styles = (theme) => ({
	root: {
		display: 'flex'
		// flexGrow: 1,
		// marginTop: theme.spacing.unit * 2
	},
	listItem: {
		padding: 0
	}
})

/**
 * Notification list component
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description major update on component, now is a list
 * @author Davi Wegner
 * @version 2.0
 */
class NotificationList extends React.Component {
	handleNotificationActive = (notificationId) => {
		const { authUser } = this.props
		UserNotification.setNotificationToVisualized(
			authUser.uid,
			notificationId
		)
	}
	render() {
		const {
			notifications,
			notificationKeys,
			classes,
			isLoading,
			authorInfo,
			history,
			setActionId,
			openActionModal
		} = this.props

		return (
			<div className={classes.root}>
				{!notifications && isLoading && <Loader internal />}
				{!notifications && (
					<EmptyListItem
						customTranslateRef="placeholder/no-notification-added"
						icon="inbox"
					/>
				)}
				{!!notifications && authorInfo && (
					<BuzzList
						disablePadding
						grid={false}
						items={notificationKeys}
						mapper={(v) => {
							if(notifications[v].type == 'notification-sent') {
								return (
									<ListItem
										className={classes.listItem}
										key={v}
										aria-label={v}
									>
										<NotificationItem
											notification={notifications[v]}
											notificationId={v}
											authorInfo={
												authorInfo[notifications[v].author]
											}
											history={history}
											setActionId={setActionId}
											openActionModal={openActionModal}
											handleActive={this.handleNotificationActive}
										/>
									</ListItem>
								)
							} else {
								return (
									<ListItem
										className={classes.listItem}
										key={v}
										aria-label={v}
									>
										<NotificationItem
											notification={notifications[v]}
											notificationId={v}
											authorInfo={
												authorInfo[notifications[v].author]
											}
											history={history}
											setActionId={setActionId}
											openActionModal={openActionModal}
											handleActive={this.handleNotificationActive}
										/>
									</ListItem>
								)
							}
						}}
					/>
				)}
			</div>
		)
	}
}

const makeStateToPropsMapper = (state) => {
	let notificationKeys = makeNotificationSorter()
	return {
		notifications: state.notificationState.notifications,
		notificationKeys: notificationKeys(state),
		authorInfo: state.notificationState.authorInfo,
		isLoading: state.notificationState.isLoading,
		user: state.userState.user
	}
}
const mapDispatchToProps = (dispatch) => ({
	openActionModal: () => dispatch(openActionModal()),
	setActionId: (actionId) => dispatch({ type: SET_ACTION_ID, actionId })
})

export default compose(
	withAuthentication,
	withRouter,
	connect(
		makeStateToPropsMapper,
		mapDispatchToProps
	),
	withStyles(styles)
)(NotificationList)
