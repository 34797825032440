export const MANAGE_FOLLOW_UP_LOADING_STATE = 'MANAGE_FOLLOW_UP_LOADING_STATE',
    LOAD_FOLLOW_UP_DATA = 'LOAD_FOLLOW_UP_DATA',
    SET_FOLLOW_UP_PERIOD = 'SET_FOLLOW_UP_PERIOD',
    SET_DO_QUERY = 'SET_DO_QUERY',
    LOAD_FOLLOW_UP_COLLABORATORS = 'LOAD_FOLLOW_UP_COLLABORATORS'

export const setFollowUpPeriod = (dateConfig, value, formType) => ({
    type: SET_FOLLOW_UP_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadFollowUpData = (data) => ({
    type: LOAD_FOLLOW_UP_DATA,
    data
})

export const loadFollowUpCollaborators = (data) => ({
    type: LOAD_FOLLOW_UP_COLLABORATORS,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_FOLLOW_UP_LOADING_STATE,
    isLoading
})

export const setDoQueryRedux = () => ({
    type: SET_DO_QUERY
})