import { fs } from '../firebase'

/**
 * github user
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible for dispatch notifications.
 *
 * @param {String} uid
 * @param {Object} notificationData
 */
export const createNewNotification = (uid, notificationData) => {
	const newNotification = fs.collection(`notifications`).doc()

	return newNotification.set(notificationData, { merge: false })
}

/**
 * github user
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible for load notification data.s
 *
 * @param {String} uid
 * @param {String} notificationId
 */
export const loadNotificationData = (uid, notificationId) => {
	return fs
		.collection(`notifications`)
		.doc(notificationId)
		.where('receiver', '==', uid)
		.get()
}

/**
 * github user
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible
 *
 * @param {String} uid
 * @param {String} notificationId
 */
export const closeNotificationResponses = (activeCompanyId, actionId) => {
	const notification = fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.doc(actionId)

	return notification.update({ active: false })
}
