import React from 'react'
import { connect } from 'react-redux'
import { HorizontalBar } from 'react-chartjs-2'
import GraphContainer from '../../graphContainer'
import { Typography } from '@material-ui/core'
import { translate } from '../../../../utils/lang'
import { makeActionCardOverviewFormatter } from '../../../../selectors/action/stats'
import BarChart from '../../indicators/chart/bar'
/**
 *
 * @description created the class component
 * @author lopes
 * @version 1.0
 */
class ActionStatusCardOverview extends React.Component {
	getLabel = (tooltipItems, data) => {
		const { callbacks } = this.props
		if (!callbacks.label) return
		return callbacks.label(tooltipItems, data)
	}

	render() {
		const { dashItemId, stepId, data, callbacks } = this.props
		return (
			<React.Fragment>
				<Typography variant="h6">
					{translate('label/actions-by-cards')}
				</Typography>
				{!!data && !!data.datasets && (
					<GraphContainer
						dashItemId={dashItemId}
						stepId={stepId}
						defaultHidden
						canSwitchLegends={false}
					>
						<BarChart
							data={data}
							callbacks={callbacks}
							component={HorizontalBar}
							options={{
								maintainAspectRatio: false,
								scales: {
									yAxes: [
										{
											ticks: {
												beginAtZero: true,
												stepSize: 1,
												callback: (
													value,
													index,
													values
												) => {
													if (!value) return
													let label = value.split('')
													label[
														value.lastIndexOf(' ')
													] = '\n'

													return label
														.join('')
														.split('\n')
												}
											},
											barPercentage: 0.5,
											gridLines: {
												display: false
											}
										}
									],
									xAxes: [
										{
											ticks: {
												beginAtZero: true,
												stepSize: 1
											},
											gridLines: {
												display: false
											}
										}
									]
								},
								tooltips: !!callbacks
									? {
											enabled: true,
											callbacks: {
												label: !!callbacks.label
													? (tooltipItems, data) =>
															this.getLabel(
																tooltipItems,
																data
															)
													: null
											}
									  }
									: null
							}}
						/>
					</GraphContainer>
				)}
			</React.Fragment>
		)
	}
}

ActionStatusCardOverview.defaultProps = {
	dashItemId: 'actionOverCardview',
	stepId: 'actionOverCardview'
}

const makeMapStateToProps = () => {
	const getActionOverviewCardData = makeActionCardOverviewFormatter()
	const mapStateToProps = (state, ownProps) => ({
		...getActionOverviewCardData(state, ownProps)
	})
	return mapStateToProps
}

export default connect(makeMapStateToProps())(ActionStatusCardOverview)
