import {
	FILTER_BY_AUTHOR_TYPE_ACTIONS,
	CLEAN_FILTER_TYPE
} from '../../../component/app/userActions/actions'

const INITIAL_STATE = {
	byAuthor: 0
}

const actionsReducerByAuthor = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FILTER_BY_AUTHOR_TYPE_ACTIONS:
			return Object.assign({}, state, {
				byAuthor: action.value
			})
		case CLEAN_FILTER_TYPE:
			return Object.assign({}, state, {
				byAuthor: INITIAL_STATE.byAuthor
			})

		default:
			return state
	}
}

export default actionsReducerByAuthor
