import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'

/**
 * Will resolve which data-source-icon to be shown
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 */
class DataSourceIcon extends React.Component {
	render() {
		const { source } = this.props

		return (
			<Icon>
				{source.toLowerCase() === 'mobile'
					? 'phone_iphone'
					: source.toLowerCase() === 'not informed'
					? 'not_interested'
					: 'cloud_upload'}
			</Icon>
		)
	}
}

DataSourceIcon.propTypes = {
	source: PropTypes.oneOf([
		'mobile',
		'Mobile',
		'interação',
		'Interação',
		'not informed'
	])
}

export default DataSourceIcon
