import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper, CircularProgress } from '@material-ui/core'
import { deepPurple } from '@material-ui/core/colors'

/**
 * Shows a loader in the screen/component.
 * * Some props may change the component behaviour
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class Loader extends React.Component {
	render() {
		const { classes, internal, noPaper } = this.props

		return (
			<div className={!internal ? classes.root : classes.rootInternal}>
				<Paper
					className={!noPaper ? classes.paper : classes.noPaper}
					elevation={!noPaper ? 2 : 0}>
					<CircularProgress
						className={classes.loaderImage}
						thickness={7}
					/>
				</Paper>
			</div>
		)
	}
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		background: 'rgba(0,0,0,.3)',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1089,
	},
	rootInternal: {
		flexGrow: 1,
		position: 'relative',
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
		top: 0,
		left: 0,
		right: 0,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		// height: (theme.spacing.unit),
		height: 56,
		width: 56,
		backgroundColor: 'rgba(255,255,255,1.0)',
		padding: theme.spacing.unit,
		borderRadius: '50%',
		zIndex: 1090,
	},
	noPaper: {
		// height: (theme.spacing.unit),
		height: 56,
		width: 56,
		padding: theme.spacing.unit,
		zIndex: 1090,
	},
	loaderImage: {
		// fontSize: 64,
		// height: 64,
		// width: 64,
		color: deepPurple[500],
		// color: theme.palette.primary.main
		// color: '#00b33c',
		// textShadow: '0px 1px 8px rgba(0,0,0,0.4)',
	},
})

Loader.defaultProps = {
	internal: false,
	noPaper: false,
}

Loader.propTypes = {
	classes: PropTypes.object.isRequired,
	internal: PropTypes.bool,
	noPaper: PropTypes.bool,
}

export default withStyles(styles)(Loader)
