import { fs } from '../../firebase'
import * as Author from '../../../utils/security/author'

/**
 * Function responsible for set an action evaluation merging the evaluation with existed properties.
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @param {String} activeCompanyId user active company
 * @param {String} actionId evaluated action id
 * @param {String} uid evluation author
 * @param {Object} data evluation data from state layer
 */
export const setActionEvaluate = (activeCompanyId, actionId, uid, data) =>
	fs
		.collection('companies')
		.doc(activeCompanyId)
		.collection('actions')
		.doc(actionId)
		.set(
			{
				evaluate: { ...data, ...Author.generateObject(uid) }
			},
			{ merge: true }
		)
