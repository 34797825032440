const INITIAL_STATE = {
	page: 1,
	pageCount: 0,
	totalLength: 0,
	order: 'DESC'
}

/**
 * Manages any listing pagination for the app (state-layer)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with a "type" and desired props
 */
function listPaginationReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'RESET_LIST_PAGINATION': {
			return Object.assign({}, state, INITIAL_STATE)
		}
		case 'LIST_PAGINATION_ORDER_BY': {
			return Object.assign({}, state, { order: action.order })
		}
		case 'LIST_PAGINATION_SET_PAGE_COUNT': {
			return Object.assign({}, state, { pageCount: action.pageCount })
		}
		case 'LIST_PAGINATION_SET_TOTAL_LENGTH': {
			return Object.assign({}, state, { totalLength: action.totalLength })
		}
		case 'LIST_PAGINATION_SET_PAGE': {
			return Object.assign({}, state, { page: action.page })
		}
		default:
			return state
	}
}

export default listPaginationReducer
