import React from 'react'
import { withStyles } from '@material-ui/core'
import BuzzList from '../../../../reusable/list/buzz'
import ActionItem from './ActionItem'

/**
 * Manages to display a list of user-actions
 *
 * @description Created the component
 * @author davispindola
 * @version 1.0
 *
 * @description Added ActionItem key, list-disablePadding, removed chip.
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description rename on component name, from ActionsList to ActionList
 * @author Davi Wegner
 * @version 1.2
 *
 * @description Transformed in functional component, since is not needed any lifecycle methods
 * @author Davi Wegner
 * @version 1.3
 *
 * @description changed from actionItemContainer to actionItem, since container is no longer needed
 * @author Davi Wegner
 * @version 1.4
 */
const ActionList = ({
	handleClick,
	actionKeys,
	classes,
	readerType,
	setActiveActionId
}) => {
	return (
		<div className={classes.root}>
			<BuzzList
				disablePadding
				grid={false}
				items={actionKeys}
				mapper={(key, index) => (
					<ActionItem
						handleClick={handleClick}
						setActiveActionId={setActiveActionId}
						key={key}
						actionId={key}
						readerType={readerType}
					/>
				)}
			/>
		</div>
	)
}

//TODO externalize this in all components, rework on folders flow
const styles = (theme) => ({
	root: {
		// marginTop: theme.spacing.unit
	},
	filter: {
		background: '#194e67',
		padding: 8,
		color: '#f7f7f7',
		borderRadius: 25,
		width: 'max-content'
	}
})

export default withStyles(styles)(ActionList)
