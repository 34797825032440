import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import * as functions from '../../shared/functions'
import styles from './styles'
import { _toLocaleString } from '../../../../../utils/string'

/**
 * An visual progress indicator
 *
 * @description implementing the logic responsible for visual progress
 * @author davispindola
 * @version 1.0.0
 *
 * @param {Number} value progress value
 * @param {Object} classes style object
 */
const ProgressChip = ({ value, classes, roundValue }) => {
	// const roundedValue = Math.round(value * 100)

	return (
		<span
			className={classes.teamReach}
			// style={{
			// 	background: functions.getColor(roundedValue)
			// }}
		>
			<Typography
				variant="body1"
				style={{
					border: `1px solid ${functions.getSimpleColor(value)}`,
					padding: '0 10px',
					borderRadius: 15,
					color: functions.getSimpleColor(value)
				}}
			>
				{_toLocaleString(roundValue ? Math.round(value) : value, {
					locale: 'pt-br'
				})}
				%
			</Typography>
		</span>
	)
}

ProgressChip.propTypes = {
	value: PropTypes.number,
	classes: PropTypes.object,
	roundValue: PropTypes.bool
}

ProgressChip.defaultProps = {
	value: 0,
	roundValue: true
}

export default withStyles(styles)(ProgressChip)
