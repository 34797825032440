const styles = (theme) => ({
	moreButton: {
		flex: '0 0 auto'
	},
	moreButtonIcon: {
		fontSize: 32
	}
})

export default styles
