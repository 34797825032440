import React from 'react'
import { withStyles, Typography, Tooltip, Icon } from '@material-ui/core'
import { translate } from '../../../../../utils/lang'
import { setViewActionState } from '../../../../../firebase/actions/action'

/**
 * Action header, for showing action info in the modal contexts
 * @description Created the component
 * @author Davi Spindola
 * @version 1.0
 * @param {*} param
 *
 * @description externalized the component, and changed validation
 * @author Davi Wegner
 * @version 1.1
 * @param {*} param
 */
const ActionHeader = ({
	author,
	date,
	card,
	expectedDate,
	classes,
	activeAction,
	activeCompanyId,
	actionId,
	readerType
}) => {
	if (!activeAction[readerType]) {
		setViewActionState(activeCompanyId, actionId, readerType, true)
	}
	const expectedDateValidation =
		!!expectedDate &&
		!!expectedDate.seconds &&
		expectedDate.seconds * 1000 > new Date().getTime()
	return author ? (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.headerAuthor}>
					<Typography variant="body1">
						{translate('label/from')}: {author.name}
					</Typography>
					<Typography component="div" style={{ color: '#828282de' }}>
						<div className={classes.dateContainer}>
							<Icon>today</Icon>
							{
								date
									.toDate()
									.toLocaleString('pt-br')
									.split(' ')[0]
							}
						</div>
					</Typography>
				</div>
				<div className={classes.headerCard}>
					{card !== null && (
						<React.Fragment>
							<Typography
								className={classes.nameHeaderCard}
								variant="body1"
							>
								{translate('label/card')}: {card}
							</Typography>
							<Typography
								component="div"
								className={
									expectedDateValidation
										? classes.validDate
										: classes.invalidDate
								}
							>
								<Tooltip title={translate('label/expected')}>
									<div className={classes.dateContainer}>
										<Icon>
											{expectedDateValidation
												? 'event_available'
												: 'event_busy'}
										</Icon>

										{!!expectedDate && !!expectedDate.toDate
											? expectedDate
													.toDate()
													.toLocaleString('pt-br')
													.slice(0, 16)
											: translate(
													'label/not-defined-fem'
											  )}
									</div>
								</Tooltip>
							</Typography>
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	) : null
}

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit *
			2}px 0 ${theme.spacing.unit * 2}px `
	},
	container: {
		'&& > div': {
			marginBottom: '10px'
		}
	},
	chips: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: 10,
		borderTop: '1px solid #e0e0e0',
		'&& > div': {
			color: '#fff',
			background: 'blue',
			margin: 2
		}
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		marginBottom: 10,
		borderBottom: '1px solid #e0e0e0'
	},
	headerAuthor: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerCard: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	nameHeaderCard: {
		width: '100%'
	},
	observation: {
		padding: '10px 5px',
		backgroundColor: '#f1f1f1',
		borderLeft: '2px solid blue'
	},
	validDate: {
		color: '#828282de'
	},
	invalidDate: {
		color: 'rgb(180,0,0)'
	},
	dateContainer: {
		display: 'flex',
		alignItems: 'center'
	}
})
export default withStyles(styles)(ActionHeader)
