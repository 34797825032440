const SET_RANKING_CONTENT_ITEM = 'SET_RANKING_CONTENT_ITEM'

const INITIAL_STATE = {
	item: {},
	position: null
}

const rankingContentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_RANKING_CONTENT_ITEM':
			return Object.assign({}, state, {
				position: action.position
			})
		default:
			return state
	}
}

export default rankingContentReducer

export { SET_RANKING_CONTENT_ITEM }
