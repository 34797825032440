const styles = (theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		padding: 15
	},
	inputs: {
		display: 'inherit',
		flexDirection: 'inherit',
		'& > div': {
			padding: '2px 0'
		},
		padding: '2px 0'
	},
	onFocus: {
		[theme.breakpoints.down('sm')]: {
			display: 'hidden'
		}
	}
})
export default styles
