import { getFrom } from '../../../../services/cloud/functions/generic'
import {
	dataSetShouldFetch,
	makeGetDataSetDependencies
} from './dataSetDependency'
import { store } from '../../../../store'
import { getCompanyPluginDataSourceById } from '../../../../selectors/company/plugin'
import { getActivePluginId } from '../../../../selectors/plugin'
import { getGlobalFilterValues } from '../../../../selectors/filter'
import { mapDependenciesToPlainObject } from '../../filter/options/functions/options'

//TODO: get dependency data and build the object for request
/**
 * Will process each data-set on a given step, based on a dash-item-id
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} stepAndDataSets The object with base information for this method
 * @param {string} dashItemId The dash-item ID this is in function of
 */
export const processDataSetsByStep = (stepAndDataSets, dashItemId) => {
	const state = store.getState(),
		getDataSetDependencies = makeGetDataSetDependencies(),
		activePluginId = getActivePluginId(state),
		dataSourceId = getCompanyPluginDataSourceById(state, activePluginId),
		dataSets = !!stepAndDataSets.step
			? stepAndDataSets.step.dataSets
			: null,
		promises = [],
		filterValues = getGlobalFilterValues(state)
	let paramsObject = {},
		dataSetDependencies = []

	//When datasets are effectively defined
	if (!!dataSets) {
		//TODO: insert params to queries/requests
		//requesting data from the sourceEngine mapped function
		stepAndDataSets.dataSetKeys.forEach((dataSetKey) => {
			//Builds a promise collection from
			//Independent datasets
			if (
				!!dataSets[dataSetKey] &&
				dataSetShouldFetch(
					dashItemId,
					stepAndDataSets.stepId,
					dataSets[dataSetKey]
				)
			) {
				//Will get data-set dependencies and map the params object
				//for pushing to the queries which will be resolved
				dataSetDependencies = getDataSetDependencies(
					dashItemId,
					stepAndDataSets.stepId,
					dataSetKey
				)
				paramsObject = mapDependenciesToPlainObject(
					dataSetDependencies,
					filterValues
				)
				if (!(dataSets[dataSetKey] instanceof Object))
					promises.push(
						getFrom(
							dataSourceId,
							dataSets[dataSetKey],
							paramsObject
						).then((result) =>
							Promise.resolve({
								[dataSetKey]: result.data
							})
						)
					)
			}
		})
	}

	return Promise.all(promises)
}
