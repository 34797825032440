export const MANAGE_CARDIV_LOADING_STATE = 'MANAGE_CARDIV_LOADING_STATE',
    LOAD_CARDIV_DATA = 'LOAD_CARDIV_DATA',
    SET_CARDIV_PERIOD = 'SET_CARDIV_PERIOD'

export const setDefaultPeriod = (dateConfig, value, formType) => ({
    type: SET_CARDIV_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadDefaultData = (data) => ({
    type: LOAD_CARDIV_DATA,
    data
})

export const setLoading = (isLoading) => ({
    type: MANAGE_CARDIV_LOADING_STATE,
    isLoading
})