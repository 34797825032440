const INITIAL_STATE = {
	subcardIndex: 0,
	modal: {
		open: false
	},
	isLoading: false,
	data: null,
	//FIXME: remove this from here as soon as refactor is complete task-699
	workBookData: null,
	graphData: null
}

/**
 * Manages the active work set state-layer-portion
 *
 * @description Created the reducer
 * @author spindola
 * @version 1.0
 *
 * @param {Object} state The current workBookState state-layer-portion
 * @param {Object} action The action with "type" and desired props
 */
const workBookReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'UPDATE_ACTIVE_WORK_BOOK_SUBCARD_INDEX':
			return Object.assign({}, state, {
				subcardIndex: action.subcardIndex
			})
		case 'CLOSE_ACTIVE_WORK_BOOK_MODAL':
			return Object.assign({}, state, {
				modal: Object.assign({}, state.modal, {
					open: false
				})
			})
		case 'OPEN_ACTIVE_WORK_BOOK_MODAL':
			return Object.assign({}, state, {
				modal: Object.assign({}, state.modal, {
					open: true
				})
			})
		case 'SET_FETCHED_WORK_BOOK_DATA':
			return Object.assign({}, state, {
				data: action.data,
				isLoading: false
			})
		case 'SET_FETCHED_WORK_BOOK_DATA_GRAPH': {
			return Object.assign({}, state, {
				graphData: action.graphData
			})
		}
		case 'SET_WORK_BOOK_LOADING_STATUS':
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		default:
			return state
	}
}

export default workBookReducer
