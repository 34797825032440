/**
 * Resolves to reduce all data-sets onto a single object
 * with key-value pairs which maps to { [dataSetId]: [value-collection] }
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array<Object>} resolvedDataSets The data from resolved data-sets
 */
export const reduceDataSets = (resolvedDataSets) => {
	let object = null

	//Building a map of objects with key-value pairs
	object = resolvedDataSets.reduce(
		(result, currentDataSet) => Object.assign({}, result, currentDataSet),
		{}
	)

	return Promise.resolve(object)
}
