import React from 'react'
import {
	withStyles,
	Divider,
	Grid,
	Paper,
	Icon,
	Tooltip
} from '@material-ui/core'
import styles from './styles'
import DataSourceIcon from '../../../data/source/icon/DataSourceIcon'
import * as date from '../../../../../utils/date'
import ProgressChip from './ProgressChip'
import { translate } from '../../../../../utils/lang'

const Header = (props) => {
	const { classes, title, team } = props

	return (
		<header className={classes.cardHeader}>
			{team.origin && (
				<div className={classes.cardHeaderSource}>
					<Tooltip title={translate(`status/${team.status}`)}>
						<Icon
							className={
								!team.status ? classes.inactive : classes.active
							}
						>
							brightness_1
						</Icon>
					</Tooltip>
					<DataSourceIcon source={team.origin} />
				</div>
			)}
			<h3 className={classes.cardHeaderDescription}>{title}</h3>
			{team.perc_reach != null && (
				<ProgressChip value={team.perc_reach} />
			)}
		</header>
	)
}

const Body = (props) => {
	const { team, classes } = props
	return (
		<div className={classes.cardBody}>
			<ul>
				<li>{team.cliente_nome}</li>
				<li>{team.localidade_nome}</li>
			</ul>
			<ul className={classes.alignRight}>
				<li>{team.processo_nome}</li>
				<li>
					{!!team.turno_ultima_data
						? date.getFormattedShortYear(team.turno_ultima_data)
						: translate('label/no-date-specified')}
				</li>
			</ul>
		</div>
	)
}

const TeamCard = (props) => {
	const { classes, team, handleClick } = props

	return (
		<Grid item xs={12} sm={12} md={6} lg={4}>
			<Paper className={classes.cardWrapper} onClick={handleClick}>
				<Header
					classes={classes}
					title={team.equipe_nome}
					team={team}
				/>
				<Divider />
				<Body classes={classes} team={team} />
			</Paper>
		</Grid>
	)
}

export default withStyles(styles)(TeamCard)
