const styles = (theme) => ({
	root: {
		flexGrow: 1,
		marginTop: theme.mixins.toolbar.minHeight,
		backgroundColor: 'transparent'
		// marginLeft: '-.8em',
		// marginRight: '-.8em'
	},
	parametersForm: {
		height: '100%'
	},
	//TODO: remove from here
	cardList: {
		overflow: 'visible'
	},
	card: {
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		paddingBottom: 0,
		border: '1px solid grey'
	},
	title: {
		marginBottom: theme.spacing.unit * 3,
		paddingBottom: 0
	},
	tabs: {
		top: 0,
		position: 'fixed',
		zIndex: 1100,
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrastText
	},
	paper: theme.mixins.gutters({
		marginTop: theme.spacing.unit,
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}),
	cycle: {
		fontWeight: 600
	},
	listHeader: {
		fontSize: '1.1em !important',
		fontWeight: 500
	},
	listFooter: {
		fontSize: '1.1em',
		fontWeight: 500
	},
	success: {
		backgroundColor: 'rgba(30, 170, 61, 1.0)',
		color: 'rgba(255, 255, 255, 1.0)'
	},
	error: {
		color: 'rgba(195, 195, 195, 1.0)'
	},
	filters: {
		flex: '0 0 auto',
		color: theme.palette.primary.contrastText,
		fontSize: '14px',
		fontWeight: 400,
		marginLeft: 'auto',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		float: 'right',
		marginRight: theme.spacing.unit,
		width: 'auto'
	},
	commandContainer: {}
})

export default styles
