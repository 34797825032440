import {
	SET_ROOT_DASH_ITEM_STEP,
	SET_ROOT_DASH_ITEM_STEPS_LOADING
} from '../../../actions/dashItem/step'
import { CLEAN_ON_COMPANY_CHANGE } from '../../../actions/company/index.js'

const INITIAL_STATE = {
	steps: {},
	isLoading: {}
}

/**
 * Manages to include a list of steps for an specific root **dash-item-steps**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 * @param {Object} action The action with "type" and desired props
 */
const rootDashItemStepsLoading = (state, action) =>
	Object.assign({}, state, {
		isLoading: Object.assign({}, state.isLoading, {
			[action.dashItemId]: action.isLoading
		})
	})

/**
 * Manages to include a list of steps for an specific root **dash-item-steps**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state tree
 * @param {Object} action The action with "type" and desired props
 */
const rootDashItemStepsLoaded = (state, action) =>
	Object.assign({}, state, {
		steps: Object.assign({}, state.steps, {
			[action.dashItemId]: action.steps
		}),
		isLoading: Object.assign({}, state.isLoading, {
			[action.dashItemId]: false
		})
	})

// /**
//  * Manages to include a step for an specific root **dashItemId**
//  *
//  * @description Created the function
//  * @author brunoteixeirasilva
//  * @version 1.0
//  *
//  * @param {Object} state The current app state tree
//  * @param {Object} action The action with "type" and desired props
//  */
// const stepLoaded = (state, action) =>
// 	Object.assign({}, state, {
// 		[action.dashItemId]: Object.assign(
// 			{},
// 			state[action.dashItemId],
// 			action.step
// 		)
// 	})

/**
 * Manages the dash-item-steps state-layer from redux
 *
 * @description Created the reducer
 * @author daviwegner
 * @version 1.0
 *
 * @description Changed docs and separated functions
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} state The current app state tree
 * @param {Object} action The action with "type" and desired props
 */
const dashItemStepReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_ROOT_DASH_ITEM_STEPS_LOADING: {
			return rootDashItemStepsLoading(state, action)
		}
		case SET_ROOT_DASH_ITEM_STEP: {
			return rootDashItemStepsLoaded(state, action)
		}
		// case SINGLE_STEP_LOADED: {
		// 	return stepLoaded(state, action)
		// }
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default dashItemStepReducer
