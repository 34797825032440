import { createSelector } from 'reselect'
import { makeGetCompanyDashItemStepById } from '../company/dashItem/step'
import * as routes from '../../constants/routes'
import { getActivePluginId } from '../plugin'
/**
 * Selector for managing steps
 * @description created the function
 * @author Davi Wegner
 * @version 1.0
 */
const makeStepManager = () => {
	return createSelector(
		[getAllSteps, getDashItemId, getStepId],
		(steps, dashItemId, stepId) => {
			let nextStep,
				prevStep,
				actualStep = stepId
			//TODO: fix this, make with selector
			if (!steps) return null
			if (!steps[dashItemId]) return null
			if (!steps[dashItemId][stepId]) return null
			Object.keys(steps[dashItemId]).forEach((step) => {
				if (
					steps[dashItemId][step].order - 1 ===
						steps[dashItemId][actualStep].order &&
					steps[dashItemId][step].active
				) {
					nextStep = step
				}
				if (
					steps[dashItemId][step].order + 1 ===
					steps[dashItemId][actualStep].order
				) {
					prevStep = step
				}
			})
			return { nextStep, prevStep }
		}
	)
}
export const makeGetBackRoute = () =>
	createSelector(
		[makeStepManager(), getAllSteps, getDashItemId, getActivePluginId],
		(stepManager, steps, dashItemId, pluginId) => {
			if (!dashItemId) return
			if (!stepManager.prevStep) return
			if (!pluginId) return
			if (steps[dashItemId][stepManager.prevStep].order === 0)
				return routes.DASHBOARD.replace(':pluginId', pluginId)
			else
				return routes.STEP_ROUTE.replace(':pluginId', pluginId)
					.replace(':dashItemId', dashItemId)
					.replace(':stepId', stepManager.prevStep)
		}
	)
/**
 * ??
 * @author daviwegner
 *
 * @param {*} state
 */
export const getAllSteps = (state) => state.dashItemStepState.steps

/**
 * Gets a step from a specific dash-item, at the state tree
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state tree
 * @param {string} dashItemId The specific dash-item ID
 * @param {string} stepId The specific step ID
 */
export const getStepById = (state, dashItemId, stepId) => {
	const steps = getAllSteps(state),
		dashItemSteps =
			!!steps && steps.hasOwnProperty(dashItemId)
				? steps[dashItemId]
				: null

	return !!dashItemSteps && dashItemSteps.hasOwnProperty(stepId)
		? dashItemSteps[stepId]
		: null
}

/**
 * [memoised] Selects the root loaded steps for a dashItem
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetAllRootSteps = () =>
	createSelector(
		getAllSteps,
		(steps) => steps
	)

/**
 * [memoised] Selects the root loaded **specific step** for a given dashItem ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetRootStepById = () =>
	createSelector(
		getStepById,
		(step) => step
	)

/**
 * [memoised] Selects the root loaded **specific step title** for a given dashItem ID
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetRootStepTitle = () =>
	createSelector(
		getStepById,
		(step) => (!!step && step.hasOwnProperty('title') ? step.title : null)
	)

export const getDashItemId = (state, props) =>
	!!props.dashItemId ? props.dashItemId : props.match.params.dashItemId

export const getStepId = (state, props) =>
	!!props.stepId ? props.stepId : props.match.params.stepId

export const makeGetComponentPath = () =>
	createSelector(
		[makeGetCompanyDashItemStepById(), makeGetRootStepById()],
		(companyStep, rootStep) => {
			if (companyStep && companyStep.componentPath)
				return companyStep.componentPath
			if (rootStep) return rootStep.componentPath
			return null
		}
	)

// export const getCompanyStep = (state, ownProps) =>
// 	makeGetCompanyDashItemStepById()(
// 		state,
// 		getDashItemId(state, ownProps),
// 		getStepId(state, ownProps)
// 	)
export default makeStepManager
