import axios from 'axios'
import { fs } from '../../../firebase/firebase'
import { store } from '../../../store'
import baseURL from './baseURL'

/**
 * Class which creates a (data) source engine request manager instance
 *
 * @description Created the class
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} serviceURL [optional] If informed, will replace the baseURL original one
 */
const sourceEngine = (serviceURL = baseURL) => {
	let instance

	class SourceEngine {
		/**
		 * Keeps the service URL which this instance will connect
		 */
		URL = ''
		/**
		 * Keeps the service name which this instance will use for URL bounding
		 */
		serviceName = !window.cordova
			? process.env.REACT_APP_NODE_ENV === 'production'
				? 'https://sourceengineprod-dot-agildash-app.rj.r.appspot.com/'
				: process.env.REACT_APP_NODE_ENV === 'stage'
				? 'https://sourceenginestage-dot-agildash-app.rj.r.appspot.com/'
				: 'http://localhost:8080/'
			: process.env.REACT_APP_NODE_ENV === 'stage'
			? 'https://sourceenginedev-dot-agildash-app.rj.r.appspot.com/'
			: 'https://sourceenginemobile-dot-agildash-app.rj.r.appspot.com/'
		/**
		 * Keeps the request params names' which this instance will use for POST request
		 */
		params = {}

		/**
		 * Creates a (data) source engine request manager instance
		 *
		 * @description Created the constructor
		 * @author brunoteixeirasilva
		 * @version 1.0
		 *
		 * @param {string} serviceURL [optional] If informed, will replace the baseURL original one
		 */
		constructor(serviceURL = baseURL) {
			this.URL = serviceURL
		}

		/**
		 * Gets the service base (URL) location
		 *
		 * @description Created the function
		 * @author brunoteixeirasilva
		 * @version 1.0
		 */
		getServiceBase = () => `${this.serviceName}`

		/**
		 * Adds an array of objects OR just a single object to the request
		 * params => will be used as request payload.
		 *
		 * **_IMPORTANT: The function returns itself (the invoker instance)_**
		 *
		 * @description Created the function
		 * @author brunoteixeirasilva
		 * @version 1.0
		 *
		 * @param {Object|array<Object>} params The array of objects or just a single object instance
		 * @returns {SourceEngine} the instance which invoked the method
		 */
		params = (...params) => {
			if (!params) return this

			//Props is an array of objects
			if (params instanceof Array) {
				//Iterates and adds each objects' properties
				//To the params istance
				params.forEach(item => {
					this.params = Object.assign({}, this.params, ...item)
				})
			} else {
				//params might just be a single object with props
				this.params = Object.assign({}, this.params, ...params)
			}

			return this
		}

		// get = (dataSetId) => {
		// 	const state = store.getState(),
		// 		companyId = state.userCompanyState.activeCompanyId,
		// 		doc = fs.collection('requestInfo').doc()

		// 	return doc
		// 		.set({
		// 			companyId,
		// 			dataSourceId,
		// 			dataSetId,
		// 			params,
		// 			nodeEnv: process.env.REACT_APP_NODE_ENV,
		// 			date: new Date(),
		// 			...{
		// 				device: window.cordova
		// 					? window.device.platform
		// 					: undefined,
		// 			},
		// 		})
		// 		.then(() => axios.post(`${this.getServiceBase()}`, params))
		// }

		// post = (dataSetId, params) => {
		// 	const state = store.getState(),
		// 		companyId = state.userCompanyState.activeCompanyId,
		// 		doc = fs.collection('requestInfo').doc()

		// 	return doc
		// 		.set({
		// 			companyId,
		// 			dataSourceId,
		// 			dataSetId,
		// 			params,
		// 			nodeEnv: process.env.REACT_APP_NODE_ENV,
		// 			date: new Date(),
		// 			...{
		// 				device: window.cordova
		// 					? window.device.platform
		// 					: undefined,
		// 			},
		// 		})
		// 		.then(() => axios.post(`${this.getServiceBase()}`, params))
		// }

		getProvisory = (dataSourceId, dataSetId) => {
			const state = store.getState(),
				companyId = state.userCompanyState.activeCompanyId,
				doc = fs.collection('requestInfo').doc()
			console.log(this.getServiceBase())
			console.log(doc.id)
			return doc
				.set({
					companyId,
					dataSourceId,
					dataSetId,
					nodeEnv: process.env.REACT_APP_NODE_ENV,
					date: new Date(),
					...{
						device: window.cordova ? window.device.platform : 'undefined',
					},
					isValid: true,
				})
				.then(() => axios.post(`${this.getServiceBase()}`, { reqId: doc.id }))
				.catch(err => console.log(err))
				.then(result => {
					if (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'stage') console.log(result)
					return result
				})
		}

		postProvisory = (dataSourceId, dataSetId, params = {}) => {
			const state = store.getState(),
				companyId = state.userCompanyState.activeCompanyId,
				doc = fs.collection('requestInfo').doc()

			// console.log('--------------------------------------')
			// console.log('dataSourceId', dataSourceId)
			// console.log('dataSetId', dataSetId)
			// console.log('params', params)
			// console.log('--------------------------------------')

			return doc
				.set({
					companyId,
					dataSourceId,
					dataSetId,
					params,
					nodeEnv: process.env.REACT_APP_NODE_ENV,
					date: new Date(),
					...{
						device: window.cordova ? window.device.platform : 'undefined',
					},
					isValid: true,
				})
				.then(() => {
					return axios.post(`${this.getServiceBase()}`, {
						reqId: doc.id,
					})
				})
				.catch(err => console.log(err))
				.then(result => {
					if (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'stage') {
						// console.log('SOURCEENGINE =>', result)
					}
					return result
				})
		}
	}

	//Creates the instance
	instance = new SourceEngine(serviceURL)

	return instance
}

export default sourceEngine
