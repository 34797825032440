import {
	MANAGE_PANEL_CHECKLIST_LOADING_STATE,
	LOAD_PANEL_CHECKLIST_DATA,
	SET_PANEL_CHECKLIST_PERIOD,
} from '../../actions/panelChecklist'

const INITIAL_STATE = {
	isLoading: null,
	isLoadingQuestion: null,
	isLoadingChecklist: null,
	data: null,
	dataPanel: null,
	dataChecklist: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {},
	localFilters: null,
	idLocalFilter: {
		objects: null,
		localClients: null,
		localLocalities: null,
		forms: null,
		collaborators: null,
		localTypeObjects: null,
		autocheckPointAudit: null
	},
}

const clearAllLocalFilters = (state) => {
    let keys = Object.keys(state.idLocalFilter),
        clearFilters = {}

    keys.forEach((key) => {
        clearFilters[key] = null
    })

    return clearFilters
}

/**
 * painel checklists state reducer manager
 * @param {*} state
 * @param {*} action
 */
const panelChecklistReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_PANEL_CHECKLIST_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case 'LOADING_QUESTION': {
			return Object.assign({}, state, {
				isLoadingQuestion: action.isLoadingQuestion,
			})
		}
		case 'LOADING_CHECKLIST': {
			return Object.assign({}, state, {
				isLoadingChecklist: action.isLoadingChecklist,
			})
		}
		case LOAD_PANEL_CHECKLIST_DATA: {
			return Object.assign({}, state, {
				data: action.data,
				isLoading: false,
			})
		}
		case SET_PANEL_CHECKLIST_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value,
						},
					),
				},
				isLoading: true,
				isLoadingQuestion: true,
				isLoadingChecklist: true
			})
		}
		case 'SET_DATA_PANEL': {
			return Object.assign({}, state, {
				dataPanel: action.data
			})
		}
		case 'SET_DATA_CHECKLIST': {
			return Object.assign({}, state, {
				dataChecklist: action.data
			})
		}
        case 'SET_PANEL_CHECKLIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_PANEL_CHECKLIST_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_PANEL_CHECKLIST_FILTER_VALUE': {
            
			return Object.assign({}, state, {
				ownFilters: {},
                idLocalFilter: clearAllLocalFilters(state)
			})
		}
        case 'SET_LOCAL_FILTER_PANEL_CHECKLIST': {
			return Object.assign({}, state, {
				localFilters: action.localFilters
			})
		}
        case 'SET_SEARCH_TEXT_PANEL_CHECKLIST': {
            let searchText = {}
            action.objectKeys.forEach((item) => {
                searchText[item] = ''
            })
            return Object.assign({}, state, {
                searchText: searchText
            })
        }
        case 'SET_ID_LOCAL_FILTERS_PANEL_CHECKLIST': {
            return Object.assign({}, state, {
                idLocalFilter: Object.assign({}, state.idLocalFilter, action.idLocalFilter)
            })
        }
        case 'SET_ALL_IDS': {
            let idLocalFilter = {}
            action.allIds.forEach((item) => {
                idLocalFilter[item] = null
            })
            return Object.assign({}, state, {
                idLocalFilter: idLocalFilter
            })
        }
		case 'SET_CARD_ID': {
			return Object.assign({}, state, {
				cardId: action.cardId,
			})
		}
		case 'SET_PERFORMED_PANEL_CHECKLIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_PERFORMED_PANEL_CHECKLIST_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_PERFORMED_PANEL_CHECKLIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		default:
			return state
	}
}

export default panelChecklistReducer
