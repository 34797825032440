import { SET_AUTHOR_INFO_ON_NOTIFICATION } from '../../../actions/user/notification'

const INITIAL_STATE = {
	activeNotification: null,
	notifications: null,
	notificationsIds: null,
	notificationIsLoading: true,
	authorInfo: null,
	isLoading: false
}

/**
 * Manages a set of user (@ specific company) notifications
 *
 * @description Created the reducer
 * @author daviwegner
 * @version 1.0
 *
 * @param {Object} state Current tree's portion of the state
 * @param {Object} action The action with "type" and desired props
 */
function notificationReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_CURRENT_ACTION':
			return Object.assign({}, state, {
				activeNotification: action.activeNotification
			})
		case 'SET_LOADING_STATE_NOTIFICATION':
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		case 'SET_DEFAULT_VALUE':
			return Object.assign({}, state, {
				activeNotification: INITIAL_STATE.activeNotification
			})
		case 'SET_LOADED_NOTIFICATIONS':
			return Object.assign({}, state, {
				notifications: Object.assign(
					{},
					state.notifications,
					action.notifications
				)
			})
		case SET_AUTHOR_INFO_ON_NOTIFICATION:
			return Object.assign({}, state, {
				authorInfo: Object.assign(
					{},
					state.authorInfo,
					action.authorInfo
				)
			})
		case 'CLEAR_NOTIFICATIONS_ON_COMPANY_CHANGE':
			return Object.assign({}, state, {
				notifications: INITIAL_STATE.notifications
			})
		default:
			return state
	}
}

export default notificationReducer
