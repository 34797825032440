import React from 'react'
import DocRendererMarkDown from '../../reusable/doc/renderer/markdown'
import DocViewPort from '../../reusable/doc/viewPort'
const privacyContentPath = require('../../docs/privacy-policy.md')

/**
 * Displays a react-markdown based privacy policy text
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class PrivacyPolicyPage extends React.Component {
	render() {
		return (
			<DocViewPort>
				<DocRendererMarkDown path={privacyContentPath} />
			</DocViewPort>
		)
	}
}

export default PrivacyPolicyPage
