import { createSelector } from 'reselect'

/**
 * Selects the app configuration: **title**
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The whole app state tree
 */
const getAppTitle = (state) => state.appConfigState.app.title

/**
 * [memoised] Selects the app configuration: **title**
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetAppTitle = () =>
	createSelector(
		getAppTitle,
		(title) => title
	)

/**
 * Selects the app configuration: **default-title**
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The whole app state tree
 */
const getAppDefaultTitle = (state) => state.appConfigState.app.defaultTitle

/**
 * [memoised] Selects the app configuration: **default-title**
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetAppDefaultTitle = () =>
	createSelector(
		getAppDefaultTitle,
		(defaultTitle) => defaultTitle
	)

/**
 * Selects the app configuration: **drawerOpen**
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The whole app state tree
 */
const getAppDrawerOpen = (state) => state.appConfigState.app.drawerOpen

/**
 * [memoised] Selects the app configuration: **drawerOpen**
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetAppDrawerOpen = () =>
	createSelector(
		getAppDrawerOpen,
		(drawerOpen) => drawerOpen
	)
