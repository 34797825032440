import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
	Select,
	InputLabel,
	FormControl,
	MenuItem,
	TextField,
	withStyles,
	Button,
	ListItem,
	ListItemText
} from '@material-ui/core'
import classNames from 'classnames'
import { setViewActionState } from '../../../../../firebase/actions/action'
import { translate } from '../../../../../utils/lang'
import styles from './style'
import * as Author from '../../../../../utils/security/author'
import * as Action from '../../../../../firebase/actions'
import Loader from '../../../../reusable/loader'
import * as Notifications from '../../../../../firebase/notifications'
import { showSnackBar } from '../../../../../actions/snackbar'

/**
 *
 * @description Created the component
 * @author spindola
 * @version 1.0
 *
 * @description exported for using as default select form in selectable dataset
 * @author Davi Wegner
 * @version 1.1
 *
 * @description added classes validation, in case it has any style to be applied
 * @author Davi Wegner
 * @version 1.2
 *
 * ITS USED EXTERNALLY DO NOT REMOVE UNTIL EXTRACTED FROM HERE
 */
export const SelectInput = ({
	margin,
	name,
	label,
	classes,
	htmlFor,
	onSelect,
	options,
	value,
	translated,
	selectClasses,
	itemClass,
	hasSecondary,
	translatedSecondary,
	otherProps,
	isFutureEnabled
	
}) => {

	if (isFutureEnabled && otherProps?.selectedType.includes('Month')) {
			options = [{
				key: 0,
				label: 'label/actual-month',
			},
			{
				key: 2,
				label: 'label/months-annotation',
				params: {
					length: 3,
				},
			},
			{
				key: 5,
				label: 'label/months-annotation',
				params: {
					length: 6,
				},
			},
			{
				key: 11,
				label: 'label/months-annotation',
				params: {
					length: 12,
				},
			},
			{
				key: '21',
				label: 'label/month-annotation-future',
				params: {
					length: 1,
				},
			},
			{
				key: '22',
				label: 'label/month-annotation-future',
				params: {
					length: 2,
				},
			},
			{ key: 'custom', label: 'label/custom' },
		] 
	}

	return (
		<FormControl
			{...(classes ? { classes: { root: classes.inputRoot } } : null)}
			margin={margin}
		>
			{label && <InputLabel htmlFor={htmlFor}>{label}</InputLabel>}
			<Select
				value={value}
				onChange={(event) =>
					onSelect(event.target.name, event.target.value)
				}
				inputProps={{
					name: name,
					id: htmlFor
				}}
				{...(selectClasses ? { classes: selectClasses } : null)}
			>
				{!hasSecondary &&
					options.map((option, i) => (
						<MenuItem
							key={i}
							disabled={!!option.disabled}
							className={
								!!option.key === 'group'
									? itemClass.group
									: null
							}
							value={option.key}
						>
							{!translated
								? translate(option.label, option.params)
								: option.label}
						</MenuItem>
					))}
				{hasSecondary &&
					options.map((option, i) => 

					(
						<ListItem
							key={i}
							disabled={!!option.disabled}
							className={
								!!option.key === 'group'
									? itemClass.group
									: null
							}
							value={option.key}
						>
							<ListItemText
								primary={
									!translated
										? translate(option.label, option.params)
										: option.label
								}
								secondary={
									!translatedSecondary
										? translate(
												option.secondaryLabel,
												option.secondaryParams
										  )
										: option.secondaryLabel
								}
							/>
						</ListItem>
					))}
			</Select>
		</FormControl>
	)
}

const actionDoneOptions = [
	{ key: 0, label: 'label/select' },
	{ key: 1, label: 'label/meeting' },
	{ key: 2, label: 'label/cost-reduction' },
	{ key: 3, label: 'label/communication-sending' },
	{ key: 4, label: 'label/registration-correction' },
	{ key: 5, label: 'label/demission' },
	{
		key: 6,
		label: 'label/advertence'
	},
	{
		key: 7,
		label: 'label/goal-review'
	},
	{
		key: 8,
		label: 'label/correction-of-pointing'
	},
	{
		key: 9,
		label: 'label/email-sending'
	}
]

const actionDoneInOptions = [
	{ key: 0, label: 'label/select' },
	{ key: 1, label: 'label/client' },
	{ key: 2, label: 'label/team' },
	{ key: 3, label: 'label/leader' },
	{ key: 4, label: 'label/administrative' },
	{ key: 5, label: 'label/corporative' },
	{ key: 6, label: 'label/autocheck' }
]

/**
 * @author davispindola
 * @version 1.0.0
 *
 * Action response form.
 *
 * @description updated the form validation
 * , action author dont needs to answer actionDone selectors input
 * @author Davi Wegner
 * @version 1.1
 */
class ResponseForm extends React.Component {
	state = {
		actionDone: 0,
		actionDoneIn: 0,
		actionObservation: '',
		isSending: false
	}

	setToInitialState = () =>
		this.setState({
			actionDone: 0,
			actionDoneIn: 0,
			actionObservation: '',
			isSending: false,
			focus: false
		})

	onSelect = (name, value) => this.setState({ [name]: value })

	validateForm = () => {
		const { activeAction, emitMessage, readerType } = this.props
		const { actionDone, actionDoneIn } = this.state

		if (!activeAction.responsesCount) {
			if (!actionDone && readerType !== 'receiverOpen') {
				emitMessage(
					translate('validation/type-of-applied-action-is-required')
				)
				return false
			}

			if (!actionDoneIn && readerType !== 'receiverOpen') {
				emitMessage(
					translate('validation/applied-action-in-is-required')
				)
				return false
			}

			return true
		}
		return true
	}

	handleSubmit = (event) => {
		const {
			activeCompanyId,
			actionId,
			activeAction,
			authUser,
			emitMessage,
			readerType
		} = this.props
		const { actionDone, actionDoneIn, actionObservation } = this.state

		let newResponse = { actionDone, actionDoneIn, actionObservation }

		if (!!authUser && undefined !== authUser.uid && this.validateForm()) {
			let response = Object.assign(
				{},
				newResponse,
				Author.generateObject(authUser.uid)
			)

			this.setState({ isSending: true })

			Action.setActionResponse(activeCompanyId, actionId, response)
				.then(() => {
					emitMessage(translate('success/new-response-was-sent'))
					this.setToInitialState()
				})
				.catch(() => {
					emitMessage(translate('error/sending-response'))
					this.setState({ isSending: false })
				})
		}
		let notificationObj = {
			type: 'answer-sent',
			actionId: actionId,
			date: new Date(),
			author: authUser.uid,
			receiver:
				activeAction.author !== authUser.uid
					? activeAction.author
					: activeAction.receiver.key,
			active: true,
			cardId: activeAction.cardId,
			companyId: activeCompanyId
		}
		Notifications.createNewNotification(
			notificationObj.receiver,
			notificationObj
		)
		setViewActionState(activeCompanyId, actionId, readerType, false)
		this.setToInitialState()
		event.preventDefault()
	}

	render() {
		const { classes, isAuthor } = this.props
		const {
			actionDone,
			actionDoneIn,
			actionObservation,
			isSending,
			focus
		} = this.state
		const condition = actionObservation.length > 0

		return (
			<div className={classes.root}>
				{isSending ? (
					<Loader internal />
				) : (
					<form className={classes.form} onSubmit={this.handleSubmit}>
						<div className={classes.inputs}>
							{!isAuthor && !focus && (
								<SelectInput
									label={translate('label/taken-action')}
									name="actionDone"
									htmlFor="action-done"
									onSelect={this.onSelect}
									value={actionDone}
									options={actionDoneOptions}
									margin="normal"
								/>
							)}
							{!isAuthor && !focus && (
								<SelectInput
									label={translate('label/action-applied-at')}
									name="actionDoneIn"
									htmlFor="action-done-in"
									onSelect={this.onSelect}
									value={actionDoneIn}
									options={actionDoneInOptions}
									margin="normal"
								/>
							)}

							<TextField
								className={classNames({
									[classes.onFocus]: focus
								})}
								id="action-observation"
								label={translate('label/note')}
								required
								value={actionObservation}
								onChange={(event) =>
									this.setState({
										actionObservation: event.target.value
									})
								}
								margin="normal"
							/>
						</div>
						<Button
							disabled={!condition}
							variant="outlined"
							type="submit"
						>
							{translate('label/to-answer')}
						</Button>
					</form>
				)}
			</div>
		)
	}
}

ResponseForm.propType = {
	classes: PropTypes.object
}

const mapStateToProps = () => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => ({
	emitMessage: (notification) => dispatch(showSnackBar(notification))
})

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)(ResponseForm)
