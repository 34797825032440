import React from 'react'
import ReactDOM from 'react-dom'
import { setUserActionSnapshot } from '../../../actions/action/form'
import { store } from '../../../store'
import { htmlToPng } from '../../../utils/object/png'
// import AppContainer from '../../app/AppContainer'

/**
 * This HOC is responsible for mapping children nodes
 * that were passed as params.
 *
 * @description Created the component
 * @author Davi Spindola
 * @version 1.0.0
 *
 * @description Changed comments and console.log => only under development
 * @author brunoteixeirasilva
 * @version 1.1.0
 *
 * @param {React.Component|React.PureComponent} Component component to be wrapped
 */
const withMappedDom = (Component) => {
	class WithMappedDom extends React.Component {
		// componentDidMount() {
		// 	console.log(this.props)
		// }
		async getJSON(data) {
			let localJson = JSON.stringify(data)
			window.localStorage.setItem('action-json', localJson)

			return localJson
			// this.saveInLocalStorage('action-json', localJson)
		}

		getURLPath = (pathname) => {
			window.localStorage.setItem('url-param', pathname)
			// this.saveInLocalStorage('url-param', pathname)
		}

		async getDomSnapshot(node) {
			const onSetSnapshot = async (snapshot) =>
				store.dispatch(setUserActionSnapshot(snapshot))

			return htmlToPng(ReactDOM.findDOMNode(node))
				.then(
					(b64) =>
						// console.log(b64)
						onSetSnapshot(b64)

					// window.localStorage.setItem(
					// 	`snapshot-base64-reference`,
					// 	b64
					// )
				)
				.catch((e) => {
					console.error(e)
				})
		}

		saveInLocalStorage(key, json) {
			window.localStorage.setItem(key, json)
		}

		render() {
			return this.props.dashItem && this.props.dashItem.version === 1 ? (
				<Component
					{...this.props}
					getJSON={this.getJSON}
					saveInLocalStorage={this.saveInLocalStorage}
					getDomSnapshot={this.getDomSnapshot}
					getURLPath={this.getURLPath}
				/>
			) : (
				// <div>
				<Component
					{...this.props}
					getJSON={this.getJSON}
					saveInLocalStorage={this.saveInLocalStorage}
					getDomSnapshot={this.getDomSnapshot}
					getURLPath={this.getURLPath}
				/>
				// </div>
			)
		}
	}

	// const mapDispatchToProps = (dispatch) => ({
	// 	onSetSnapshot: async (snapshot) =>
	// 		dispatch(setUserActionSnapshot(snapshot))
	// })

	return WithMappedDom
}

export default withMappedDom
