const INITIAL_STATE = {
	searchOn: false,
	users: null,
	isLoading: false
}

/**
 * Clears a user list, setting to initial state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 */
const clearUsers = (state) =>
	Object.assign({}, state, {
		users: INITIAL_STATE.users
	})

/**
 * Adds a user list to the current state of the app
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state the current app state
 * @param {Object} action containing "users" property with user list
 */
const usersLoaded = (state, action) =>
	Object.assign({}, state, {
		users: Object.assign({}, state.users, action.users),
		isLoading: false
	})

/**
 * Sets a prop "isLoading" to true
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {*} action Object with prop "type" and desired props
 */
const setUsersLoading = (state, action) =>
	Object.assign({}, state, { isLoading: action.isLoading })

/**
 * Will set the search-mode at the state layer
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current app state
 * @param {Object} action The action with "type" and desired props
 */
const setUserListSearchMode = (state, action) =>
	Object.assign({}, state, { searchOn: action.searchOn })

/**
 * Manages user-listing state layer
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added user-list responsibility to this reducer
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {Object} state Current app state
 * @param {Object} action The action with "type" and desired props
 */
function userListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_USERS_LOADING': {
			return setUsersLoading(state, action)
		}
		case 'CLEAR_USERS': {
			return clearUsers(state)
		}
		case 'USERS_LOADED': {
			return usersLoaded(state, action)
		}
		case 'SET_USER_LIST_SEARCH_MODE': {
			return setUserListSearchMode(state, action)
		}
		default:
			return state
	}
}

export default userListReducer
