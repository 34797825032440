export const MANAGE_MED_TEMPERATURE_LOADING_STATE =
		'MANAGE_MED_TEMPERATURE_LOADING_STATE',
	LOAD_MED_TEMPERATURE_DATA = 'LOAD_MED_TEMPERATURE_DATA',
	SET_MED_TEMPERATURE_PERIOD = 'SET_MED_TEMPERATURE_PERIOD'

export const setMedTemperaturePeriod = (dateConfig, value, formType) => ({
	type: SET_MED_TEMPERATURE_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadMedTemperatureData = (medTemperature) => ({
	type: LOAD_MED_TEMPERATURE_DATA,
	medTemperature
})

export const setLoading = (isLoading) => ({
	type: MANAGE_MED_TEMPERATURE_LOADING_STATE,
	isLoading
})
