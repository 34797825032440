export const MANAGE_MAP_COVID_LOADING_STATE = 'MANAGE_MAP_COVID_LOADING_STATE',
	LOAD_MAP_COVID_DATA = 'LOAD_MAP_COVID_DATA',
	LOAD_MAP_COVID_DATA_FILTERS = 'LOAD_MAP_COVID_DATA_FILTERS',
	SET_MAP_COVID_PERIOD = 'SET_MAP_COVID_PERIOD'

export const setMapCovidPeriod = (dateConfig, value, formType) => ({
	type: SET_MAP_COVID_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadMapCovidData = (mapCovid) => ({
	type: LOAD_MAP_COVID_DATA,
	mapCovid
})

export const loadMapCovidDataFilters = (mapCovidFilters) => ({
	type: LOAD_MAP_COVID_DATA_FILTERS,
	mapCovidFilters
})

export const setLoading = (isLoading) => ({
	type: MANAGE_MAP_COVID_LOADING_STATE,
	isLoading
})
