import React from 'react'
import { withRouter } from 'react-router-dom'

/**
 * Responsible for inject the redirect logic in nested components.
 * * Example: withRedirect(route)(Component)
 * When component is clicked the onClick method will push the route to navigation history
 *
 * @description Created the HOC
 * @author davispindola
 * @version 1.0.0
 *
 * @param {String} route Destiny route
 */
const withRedirect = (route) => (C) => {
	class WithRedirect extends React.Component {
		onClick = () => {
			const { history } = this.props
			history.push(route)
		}

		render() {
			return <C onClick={this.onClick} {...this.props} />
		}
	}

	return withRouter(WithRedirect)
}

export default withRedirect
