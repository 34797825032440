import {
    MANAGE_TOP_NC_RANKING_LOADING_STATE,
    LOAD_TOP_NC_RANKING_DATA,
    SET_TOP_NC_RANKING_PERIOD
} from '../../actions/topNcRanking'

const INITIAL_STATE = {
	isLoading: null,
	data: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
        client: '',
        form: '',
        locality: '',
        object: '',
        typeObject: '',
        question: '',
        step: '',
        status: '',
        autocheck: ''
    }
}
/**
 * @description created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */

const topNcRankingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANAGE_TOP_NC_RANKING_LOADING_STATE: {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case LOAD_TOP_NC_RANKING_DATA: {
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false
            })
        }
        case 'SET_TOP_NC_RANKING_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_TOP_NC_RANKING_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_TOP_NC_RANKING_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
        case SET_TOP_NC_RANKING_PERIOD: {
            return Object.assign({}, state, {
                dateConfig: {
                    ...action.dateConfig,
                    selectedValues: Object.assign(
                        {},
                        state.dateConfig.selectedValues,
                        {
                            [action.formType]: action.value
                        }
                    )
                },
                isLoading: true
            })
        }
        default:
            return state
    }
}

export default topNcRankingReducer
