export const SET_ACTION_PLAN_LOADING_STATE = 'SET_ACTION_PLAN_LOADING_STATE',
	SET_ACTION_PLAN_DATA = 'SET_ACTION_PLAN_DATA',
	SET_ACTION_PLAN_DATA_BAR = 'SET_ACTION_PLAN_DATA_BAR',
	SET_ACTION_PLAN_DATE_CONFIG = 'SET_ACTION_PLAN_DATE_CONFIG',
	CLEAR_ACTION_PLAN_DATA = 'CLEAR_ACTION_PLAN_DATA',
	SET_ACTION_PLAN_SWIPER = 'SET_ACTION_PLAN_SWIPER'

export const setLoading = (isLoading) => ({
		type: SET_ACTION_PLAN_LOADING_STATE,
		isLoading
	}),
	setActionPlanData = (data) => ({
		type: SET_ACTION_PLAN_DATA,
		data
	}),
	setActionPlanDataBar = (dataBar) => ({
		type: SET_ACTION_PLAN_DATA_BAR,
		dataBar
	}),
	setActionPlanSwiper = (index) => ({
		type: SET_ACTION_PLAN_SWIPER,
		indexSwiper: index
	}),
	setActionPlanPeriod = (dateConfig, value, formType) => ({
		type: SET_ACTION_PLAN_DATE_CONFIG,
		dateConfig,
		value,
		formType
	}),
	cleanActionPlanData = () => ({
		type: CLEAR_ACTION_PLAN_DATA
	})
