const INITIAL_STATE = {}

const consumptionDataReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_LOADED_INSTALATION':
			return Object.assign({}, state, {
				[action.id.toLowerCase()]: action.data
			})

		default:
			return state
	}
}

export default consumptionDataReducer
