import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter, generatePath } from 'react-router-dom'
import {
	withStyles,
	Typography,
	Modal,
	Icon,
	Tabs,
	Tab
} from '@material-ui/core'
import * as routes from '../../../../../constants/routes'
import Details from './Details'
import ResponseForm from './../response'
import withAuthentication from '../../../../reusable/withAuthentication'
import withActiveCompany from '../../../../reusable/withActiveCompany'
import Responses from './../responses'
import { translate } from '../../../../../utils/lang'
import ActionHeader from './ActionHeader'
import CloseActionButton from '../action/CloseActionButton'
// import {
// 	CLOSE_ACTION_MODAL,
// 	CLEAR_ACTIVE_ACTION
// } from '../../../../../reducers/action'
import { makeGetWidths } from '../../../../../utils/object/theme/modal'
import makeActionDetailFormater, {
	getDetailReaderType
} from '../../../../../selectors/action/detail'

const getWidths = makeGetWidths()

/**
 * Manages to detail an action with a layout
 *
 * @description Created the component
 * @author Davi Spindola
 * @version 1.0
 *
 * @description Added translate support
 * @author brunoteixeirasilva
 * @version 1.1
 *
 */
class DetailsContainer extends React.PureComponent {
	state = {
		value: 0
	}

	componentDidUpdate(prevProps) {
		//In case action has changed (another was selected)
		if (prevProps.actionId !== this.props.actionId) {
			this.setState({ value: 0 })
		}
	}

	handleChange = (event, value) => this.setState({ value })
	handleClose = () => {
		const { match } = this.props
		this.props.history.push(
			generatePath(routes.USER_ACTIONS, {
				companyId: match.params.companyId,
				actionId: 'list'
			})
		)
	}
	render() {
		const {
			// modalState,
			activeAction,
			activeCompanyId,
			actionId,
			authUser,
			// onClose,
			card,
			readerType,
			author,
			byAuthor,
			actionActive,
			disabledAnswer
		} = this.props
		const { classes, ...otherProps } = this.props
		const { value } = this.state
		// console.log(activeAction)
		return (
			<div className={classes.root}>
				{actionId && activeAction && (
					<Modal
						className={classes.modal}
						open={!!activeAction}
						onClose={this.handleClose}
					>
						<div className={classes.paper}>
							<header className={classes.header}>
								<Typography
									variant="h6"
									style={{ color: '#fff' }}
								>
									{translate('label/action-details')}
								</Typography>
								<Icon
									style={{ cursor: 'pointer' }}
									onClick={this.handleClose}
								>
									close
								</Icon>
							</header>
							<Tabs value={value} onChange={this.handleChange}>
								<Tab
									label={translate('object/action')}
									disabled={!activeAction}
								/>
								<Tab
									label={translate('label/answers')}
									disabled={!activeAction}
								/>
								<Tab
									label={translate('label/to-answer')}
									disabled={disabledAnswer}
								/>
							</Tabs>
							{activeAction && (
								<ActionHeader
									author={author}
									authUser={authUser}
									card={card}
									date={activeAction.date}
									expectedDate={activeAction.expectedDate}
									activeCompanyId={activeCompanyId}
									readerType={readerType}
									actionId={actionId}
									activeAction={activeAction}
									byAuthor={byAuthor}
								/>
							)}
							{value === 0 && (
								<Details activeAction={activeAction} />
							)}
							{value === 2 && (
								<ResponseForm
									{...otherProps}
									// actionId={actionId}
									// onClose={onClose}
									// readerType={readerType}
									// activeAction={activeAction}
								/>
							)}
							{value === 1 && <Responses actionId={actionId} />}
							{activeAction && actionActive && byAuthor === 0 && (
								<CloseActionButton
									actionId={actionId}
									activeAction={activeAction}
								/>
							)}
						</div>
					</Modal>
				)}
			</div>
			// </div>
		)
	}
}

DetailsContainer.propTypes = {
	classes: PropTypes.object.isRequired
	// onClose: PropTypes.func.isRequired
}

const styles = (theme) => ({
	modal: {
		flex: '0 0 auto',
		maxHeight: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
		color: '#fff',
		background: theme.palette.primary.light,
		'& > *': {
			flex: '0 0 auto'
		}
	},
	content: {
		// flex: '0 0 auto',
		height: 'calc(100% - 48px)',
		// overflow: 'hidden',
		overflow: 'hidden',
		overflowY: 'auto'
		// paddingBottom: `${theme.spacing.unit * 6}px`
	},
	paper: {
		flex: '0 0 auto',
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'center',
		// justifyContent: 'center',
		overflow: 'hidden',
		// overflowY: 'auto',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#fff',
		...getWidths(theme),
		maxHeight: '95%',
		boxShadow: theme.shadows[5],
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit
		// paddingBottom: `${theme.spacing.unit * 6}px`
	}
})
const makeMapStateToProps = () => {
	const getActionDetail = makeActionDetailFormater()
	const mapStateToProps = (state, ownProps) => {
		const actionDetail = getActionDetail(state, ownProps),
			readerType = getDetailReaderType(state, ownProps)
		return {
			actionList: state.actionsState.actionList,
			modalState: state.actionsState.showActionModal,
			byAuthor: state.actionsStateByAuthor.byAuthor,
			readerType,
			...actionDetail
		}
	}
	return mapStateToProps
}
const mapDispatchToProps = (dispatch) => ({
	// onClose: () => {
	// 	dispatch({ type: CLEAR_ACTIVE_ACTION })
	// }
})

export default compose(
	withAuthentication,
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	),
	withRouter,
	withActiveCompany,
	withStyles(styles)
)(DetailsContainer)
