import {
	SET_GLOBAL_FILTER_SEARCH_TEXT,
	CLEAR_GLOBAL_FILTER_SEARCH_TEXT
} from '../../../actions/filter/search'

const INITIAL_STATE = {
	text: {}
}

/**
 * **Clears** a search text value for a specific dataSet id
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const clearSearchText = (state, action) => {
	return Object.assign({}, state, {
		text: Object.assign({}, state.text, { [action.dataSetId]: undefined })
	})
}

/**
 * **Sets** a search text value for a specific dataSet id
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
const setSearchText = (state, action) => {
	return Object.assign({}, state, {
		text: Object.assign({}, state.text, { [action.dataSetId]: action.text })
	})
}

/**
 * Manages state-portion for global-filters-search-text
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
function globalFiltersSearchReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_GLOBAL_FILTER_SEARCH_TEXT: {
			return setSearchText(state, action)
		}
		case CLEAR_GLOBAL_FILTER_SEARCH_TEXT: {
			return clearSearchText(state, action)
		}
		default:
			return state
	}
}

export default globalFiltersSearchReducer
