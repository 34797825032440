import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import classNames from 'classnames'
import { setDisplay, setDisplayType } from '../../../actions/graphContainer'
import { withStyles, Button } from '@material-ui/core'
import { translate } from '../../../utils/lang'
import PinchToZoom from 'react-pinch-and-zoom'

class GraphContainer extends Component {
	componentDidMount() {
		if (!this.props.mobile && this.props.canHideOnDesktop) {
			this.props.onSetDisplayState(!this.props.defaultHidden)
		}
		this.checkDevice()
	}
	checkDevice = () => {
		const { onSetDisplayState, onSetDisplayType } = this.props

		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i)
		) {
			onSetDisplayType(true)
		} else {
			onSetDisplayState(!this.props.defaultHidden)
		}
	}
	handleClick = () => {
		const { onSetDisplayState, display } = this.props
		onSetDisplayState(!Boolean(display))
	}
	render() {
		const {
			display,
			children,
			mobile,
			classes,
			canSwitchLegends,
			canHideOnDesktop,
			withScroll,
			withZoom,
			legend
		} = this.props

		return (
			<React.Fragment>
				{withZoom && mobile ? (
					<PinchToZoom
						className={classNames(classes.container, {
							[classes.withScroll]: withScroll
						})}
					>
						{React.cloneElement(children, { display })}
					</PinchToZoom>
				) : (
					<div
						className={classNames(classes.container, {
							[classes.withScroll]: withScroll
						})}
					>
						{React.cloneElement(children, { display })}
					</div>
				)}
				{(mobile || canHideOnDesktop || !!legend) && canSwitchLegends && (
					<div className={classes.buttonContainer}>
						<Button
							className={classes.button}
							onClick={this.handleClick}
						>
							{!display
								? translate('label/display-legend')
								: translate('label/hide-legend')}
						</Button>
					</div>
				)}
			</React.Fragment>
		)
	}
}

const styles = (theme) => ({
	button: {
		color: theme.palette.primary.light,
		fontSize: '12px',
		fontWeight: '400'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	container: {
		height: '100%',
		'&>.pinch-to-zoom-area': {
			height: '100%'
		}
	},
	withScroll: {
		overflowY: 'scroll',
		overflowX: 'hidden'
	}
})

const mapStateToProps = (state, ownProps) => {
	const dashItem = state.graphState.isHidden[ownProps.dashItemId]
	const mobile = state.graphState.mobile
	const display =
		!mobile && !ownProps.canHideOnDesktop
			? !ownProps.defaultHidden
			: !!dashItem
			? dashItem[ownProps.stepId]
			: false

	return {
		display: display,
		mobile: state.graphState.mobile
	}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSetDisplayState: (value) =>
		dispatch(setDisplay(ownProps.dashItemId, ownProps.stepId, value)),
	onSetDisplayType: (value) => dispatch(setDisplayType(value))
})

GraphContainer.defaultProps = {
	defaultHidden: false,
	canSwitchLegends: true
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)(GraphContainer)
