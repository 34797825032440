import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import * as routes from '../../../../constants/routes'
import * as date from '../../../../utils/date'
import withSearchText from '../../withSearchText'
import Loader from '../../loader'
import {
	makeGetTeamsCount,
	makeGetVisibleTeams,
} from '../../../../selectors/team'
import TeamList from './TeamList'
import withFilters from '../../withFilters'
import {
	TEAM_LIST_LOADED,
	SET_TEAM_LIST_LOADING,
	CLEAR_TEAM_LIST,
} from '../../../../reducers/team/list'
import teamList from '../../../../services/cloud/functions/sigs/team/list'
import withMappedDom from '../../withMappedDom'
import { generatePath } from 'react-router'
import { toQueryString } from '../../../../utils/object'

/**
 * Manages to list all teams data
 *
 * @description Created the class comment
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description added status filter
 * @author Davi Wegner
 * @version 1.1
 * TODO: multiple filter addition
 */
class TeamListContainer extends React.Component {
	componentDidMount() {
		const { teamsLoading } = this.props

		//Teams not loading yet
		if (!teamsLoading) {
			this.fetchData()
		}
	}
	componentDidUpdate() {
		if (!!this.props.update) this.fetchData()
	}
	fetchData = async () => {
		const {
			teamsLoaded,
			selectedValues,
			clearTeams,
			setTeamsLoading,
			dataSourceId,
			dataSets,
		} = this.props

		if (!!dataSets && !!dataSets.teamList) {
			try {
				//Will set the loader on
				setTeamsLoading(true).then(() =>
					//Will clear team-listing prior to querying
					clearTeams(),
				)
				let response = await teamList(
					{
						date: date.getANSI(),
						days: 30,
						...selectedValues,
					},
					dataSourceId,
					dataSets.teamList,
				)

				//Will query for data at Sakura service
				// Sakura.buildRequest(`team/both/${date.getANSI()}`)

				await teamsLoaded(response.data)
			} catch (error) {
				//Sets loading to false
				setTeamsLoading(false)
				console.log('error', error)
				//Rethrows the error
				throw error
			}
		}
	}

	/**
	 * Will manage to drive the user into a specific team (by id)
	 *
	 * @description Created the function
	 * @author spindola
	 * @version 1.0
	 *
	 * @description Changed to use better routing
	 * @author brunoteixeirasilva
	 * @version 1.1
	 *
	 * @description Changed to the container file
	 * @author brunoteixeirasilva
	 * @version 1.2
	 */
	handleTeamDetail = teamId => {
		const { history, match, stepManager } = this.props

		history.push(
			generatePath(routes.STEP_ROUTE, {
				pluginId: match.params.pluginId,
				dashItemId: match.params.dashItemId,
				stepId: stepManager.nextStep,
			}) + toQueryString({ teamId }),
		)
	}

	render() {
		const { teamsLoading } = this.props

		return !!teamsLoading ? (
			<Loader />
		) : (
			<TeamList onItemClick={this.handleTeamDetail} {...this.props} />
		)
	}
}

const makeMapStateToProps = () => {
	//Prepares the selector function
	const getVisibleTeams = makeGetVisibleTeams()
	const getTeamsCount = makeGetTeamsCount()

	const mapStateToProps = state => ({
		teamsLoading: state.teamListState.teamsLoading,
		update: state.teamListState.update,
		totalTeamsLength: getTeamsCount(state),
		teams: getVisibleTeams(state),
	})

	return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
	setTeamsLoading: async teamsLoading =>
		dispatch({ type: SET_TEAM_LIST_LOADING, teamsLoading }),
	clearTeams: async () => dispatch({ type: CLEAR_TEAM_LIST }),
	teamsLoaded: teams => dispatch({ type: TEAM_LIST_LOADED, teams }),
})

export default compose(
	withRouter,
	withFilters(),
	connect(makeMapStateToProps(), mapDispatchToProps),
	withSearchText,
	withMappedDom,
)(TeamListContainer)
