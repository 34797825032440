import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
	withStyles,
	InputLabel,
	FormControl,
	Select,
	MenuItem,
	Button
} from '@material-ui/core'
import classNames from 'classnames'
import { auth } from '../../../../firebase/firebase'
import ActionLight from '../model/ActionLight'
import { closeActionModal } from '../../../../reducers/action/navigation'
import * as Author from '../../../../utils/security/author'
import * as Actions from '../../../../firebase/actions'
import * as ActionSnapshot from '../../../../firebase/actions/action'
import * as Storage from '../../../../firebase/storage'
import { translate } from '../../../../utils/lang'
import Loader from '../../../reusable/loader'
import withActiveCompany from '../../../reusable/withActiveCompany'
import {
	SET_ACTION_FORM_IS_SENDING,
	CLEAR_ACTION_FORM,
	SET_ACTION_FORM_FETCHED_USERS,
	SET_ACTION_FORM_CC_RECEIVERS,
	SET_ACTION_FORM_RECEIVER,
	SET_ACTION_FORM_TYPE,
	SET_ACTION_FORM_CLASSIFICATION,
	SET_ACTION_FORM_OBSERVATION,
	SET_ACTION_FORM_USERS_FETCHING,
	SET_ACTION_FORM_EXPECTED_DATE,
	SET_CLOSE_INSPECTION_FORM,
	CLEAR_ELEMENTS_FORM
} from '../../../../reducers/action/form'
import styles from './shared/stylesLight'
import { showSnackBar } from '../../../../actions/snackbar'
import { makeGetActionFormSnapshot } from '../../../../selectors/action/form'
import { deviceIsIos } from '../../../../utils/env'

/**
 * Manages finish-instalations parts: form, data, requests, dispatches, etc.
 *
 * @description Created the component
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * 
 */

class ActionFormLight extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			indexResultType: 0,
			resultType: null,
			indexDetail: 0,
			detail: null,
			suport: '',
			date: null,
			latitude: null,
			longitude: null,
			accuracy: null,
			resultSelected: null,
			temperature: null,
			errorTemperature: null
		}
	}
	list = {
		resultTypes: [
			{ disabled: true, label: translate('label/select') },
			{ label: 'Fraude' },
			{ label: 'Irregularidade' },
			{ label: 'Nada apurado' },
			{ label: 'Não inspecionado' }
		],
		dateilsFraud: [
			{ disabled: true, label: translate('label/select') },
			{ label: 'Medidor' },
			{ label: 'TC – Transformador de Corrente' },
			{ label: 'Circuito Secundário de Potencial' },
			{ label: 'Desvio na BT' },
			{ label: 'Desvio com dupla alimentação (caso Pexinchete)' },
			{ label: 'Ligação invertida' },
			{ label: 'Equipamento danificado' }
		],
		detailsIrregularity: [
			{ disabled: true, label: translate('label/select') },
			{ label: 'Desvio com dupla alimentação (caso Pexinchete)' }
		],
		detailsNothingCleared: [
			{ disabled: true, label: translate('label/select') },
			{ label: 'Nada apurado' },
			{ label: 'Manutenção' }
		],
		detailsNotInspected: [
			{ disabled: true, label: translate('label/select') },
			{ label: 'Local fechado/Cliente ausente' },
			{ label: 'Local demolido/Desativado' },
			{ label: 'Área de Risco' },
			{ label: 'Decisão judicial' }
		],
		details: [
			{ disabled: true, label: translate('label/select') },
			{ label: 'Medidor' },
			{ label: 'TC – Transformador de Corrente' },
			{ label: 'Circuito Secundário de Potencial' },
			{ label: 'Ligação do secundário até o medidor' },
			{ label: 'Acumulo de Leitura' },
			{ label: 'Desvio na BT' },
			{ label: 'Desvio com dupla alimentação (caso Pexinchete)' },
			{ label: 'Nada apurado' },
			{ label: 'Ligação invertida' },
			{ label: 'Equipamento danificado' },
			{ label: 'Manutenção' },
			{ label: 'Local fechado/Cliente ausente' },
			{ label: 'Local demolido/Desativado' },
			{ label: 'Área de Risco' },
			{ label: 'Decisão judicial' }
		]
	}

	componentDidMount() {
		function error(err) {
			console.warn('ERROR(' + err.code + '): ' + err.message)
		}

		navigator.geolocation.getCurrentPosition(
			(pos) => {
				let latitude = pos.coords.latitude
				let longitude = pos.coords.longitude
				let accuracy = pos.coords.accuracy

				this.setState({
					latitude,
					longitude,
					accuracy
				})
				this.takeTemperature(pos.coords.latitude, pos.coords.longitude)
			},
			error,
			{ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
		)
		
		this.handleChangeDate()		
	}

	takeTemperature = (lat, lon) => {
		fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}
			&lon=${lon}
			&units=metric&lang=pt_br&exclude=hourly,minutely
			&appid=871d06ed43f5e693e7e18f2546171a93`)
		.then(res => res.json())
		.then(
			(result) => {
				this.setState({
					temperature: result
				})
			},
			(error) => {
				this.setState({
					errorTemperature: error,
					temperature: null
				})
			}
		)
	}

	onSnapshotSaved = (fileUrl, activeCompanyId, actionId) =>
		ActionSnapshot.setActionSnapshotUrl(activeCompanyId, actionId, fileUrl)

	onJsonSaved = () => {
		const { onClose, clearForm, setSending } = this.props

		//Will emit a success message
		this.notifyUserSuccess()

		//Closes the form
		onClose()

		//Stops sending state => false
		setSending(false)

		//Clears the form state
		return Promise.resolve(clearForm())
	}

	notifyUserSuccess = () => {
		const { onSnackbar } = this.props

		return onSnackbar('Inspeção finalizada com sucesso!')
	}

	handleChangeClassification = (event) => {
		const { setFormClassification } = this.props

		setFormClassification(event.target.value)

		this.setState({
			detail: this.list.details[event.target.value].label,
			indexDetail: event.target.value
		})
	}

	handleChangeObservation = () => {
		const { setFormObservation } = this.props

		if (this.state.support) {
			setFormObservation(this.state.suport)
		}
	}

	handleChangeType = (event) => {
		const { setFormType } = this.props

		setFormType(event.target.value)

		this.setState({
			resultType: this.list.resultTypes[event.target.value].label,
			indexResultType: event.target.value,
			resultSelected: event.target.value
		})
	}

	handleChangeDate = () => {
		const { setFormExpectedDate } = this.props

		let today = new Date()
		let date =
			today.getDate() +
			'/' +
			(today.getMonth() + 1) +
			'/' +
			today.getFullYear() +
			' ' +
			today.getHours() +
			':' +
			today.getMinutes()

		this.setState({
			date: date
		})

		setFormExpectedDate(date)
	}

	handleAction = () => {
		const {
			type,
			classification,
			observation,
			activeCompanyId,
			cardId,
			snapshot,
			inspection
		} = this.props

		const { latitude, longitude, accuracy } = this.state

		let actionId
		const action = new ActionLight(
				activeCompanyId,
				{
					type,
					classification,
					observation
				}
			), 
			ls = window.localStorage,
			newAction = Object.assign({}, action, {
				...Author.generateObject(auth.currentUser.uid),
				cardId: cardId ? cardId : ls.getItem('url-param'),
				authorOpen: true,
				datasInspection: inspection,
				dateCloseInspection: this.state.date,
				inspection: inspection.instalation,
				resultContent: {
					resultado: this.list.resultTypes[type],
					Detalhes: type === 1 ? this.list.dateilsFraud[classification] :
						type === 2 ? this.list.detailsIrregularity[classification] :
						type === 3 ? this.list.detailsNothingCleared[classification] :
						this.list.detailsNotInspected[classification],
					observacao: this.state.suport
				},
				temperature: this.state.temperature,
				coords: {
					latitude,
					longitude,
					accuracy
				}
			})

		//Creates the action itself
		return (
			//Firstly creates the action
			Actions.create(newAction, activeCompanyId)
				.then((id) => {
					//Sets the ID for reusage
					actionId = id

					//At the success of created action,
					//Stores the image using action ID
					return Storage.saveImage(
						snapshot,
						activeCompanyId,
						actionId
					)
				})
				//Executes image URL saving at the action item
				.then((response) =>
					this.onSnapshotSaved(response, activeCompanyId, actionId)
				)
				//Executes data JSON saving at the action item storage location
				.then(() =>
					Storage.saveActionJSONData(activeCompanyId, actionId)
				)
				//Relates the json action-data to the action-item data
				.then(this.onJsonSaved)
		)
	}

	validateForm = () => {
		const {
			onSnackbar,
			type,
			classification
		} = this.props

		if (!type) {
			onSnackbar(translate('validation/warning-type-is-required'))
			return false
		}

		if (!classification) {
			onSnackbar(translate('validation/classification-is-required'))
			return false
		}
		return true
	}

	submit = async () => {
		const {
			setSending,
			onSnackbar,
			closeInspection,
			onSetCloseInspection,
			onSetOpenForm,
			onClose,
			onSetShowModalForm,
			clearElementsForm,
			setAtualizaPage
		} = this.props

		//When the form is valid
		if (!this.validateForm()) {
			return false
		}

		return setSending(true)
			.then(() => this.handleAction())
			.then(() => {
				//Will set the form to a sending state => true
				if (closeInspection === true) onSetCloseInspection(false)
				setSending(false)
			})
			.then(() => {
				onClose()
				clearElementsForm()
				onSetOpenForm(false)
				onSetShowModalForm(false)
				this.setState({
					suport: ''
				})
				// document.location.reload(true)
				setAtualizaPage(true)
			})
			.catch((error) => {
				//Will set the form to a sending state => true
				setSending(false)

				//When under development
				if (process.env.REACT_APP_NODE_ENV.trim() === 'development') {
					console.error('Erro ao finalizar inspeção')
					console.error(error)
				}

				//Notifies the user
				onSnackbar('Erro ao finalizar inspeção')

				//Rethrows the error
				return Promise.reject(error)
			})
	}

	onSelect = (item, selectedItem) => {
		const { setReceiver } = this.props

		setReceiver(selectedItem[0])
	}

	componentDidUpdate(prevProps, prevState) {
		this.updateSuportMessage(prevState)

		if (prevState.suport !== this.state.suport)
			this.handleChangeObservation(this.state.suport)
	}

	updateSuportMessage = (prevState) => {
		if ( prevState.resultType !== this.state.resultType || prevState.detail !== this.state.detail ) {
			if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[1].label
			) {
				this.setState({
					suport: `No ato da inspeção, foi constatado medidor
						com selo oficial (retirado, violado ou adulterado) e com circuito
						de (corrente ou potencial desativado).`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[2].label
			) {
				this.setState({
					suport: `No ato da inspeção, foi constatado
						ligação (irregular, interrompido e/ou shunt) no secundário do(s)
						TC(s) - Transformadores de Corrente da(s) fase(s) (A, B e/ou C),
						deixando de registrar seu real consumo.`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[3].label
			) {
				this.setState({
					suport: `No ato da inspeção, foi constatada ligação
						(irregular e/ou interrompido) no secundário do circuito potencial
						da (s) fase (s) (A, B e/ou C), deixando de registrar seu real consumo.`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[4].label
			) {
				this.setState({
					suport: `No ato da inspeção, foram constatadas conexões
						(irregulares, desconectada ou queimadas) na (chave de aferição,
						bloco de aferição ou borne do medidor), na(s) fase(s) (A, B e/ou C), 
						deixando de registrar seu consumo real.`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[2].label &&
				this.state.detail === this.list.details[5].label
			) {
				this.setState({
					suport: `No ato da inspeção foi identificado o medidor do cliente
						com leitura xxx registrada na data xyz, divergindo da leitura presente
						no sistema, caracterizando assim, acumulo de leitura no medidor.`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[6].label
			) {
				this.setState({
					suport: `No ato da inspeção, foi constatado desvio de energia
						direto da Rede de Baixa Tensão da LIGHT em (1, 2 ou 3) fases(s),
						sem passar pelo conjunto de medição.
						*vincular a carga utilizada ou as correntes instantâneas`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[7].label
			) {
				this.setState({
					suport: `No ato da inspeção, foi constatado desvio de energia
						direto da Rede de Baixa Tensão da LIGHT em 1, 2 ou 3 fases(s),
						sem passar pelo conjunto de medição. Também há nesta unidade
						consumidora outra ligação em Baixa Tensão ligada pelo medidor xxxxx,
						que também se encontra com ligação irregular.
						*vincular a carga utilizada ou as correntes instantâneas`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[3].label &&
				this.state.detail === this.list.details[8].label
			) {
				this.setState({
					suport: `No ato da inspeção, após todos os testes
						metrológicos executados, não foi identificado irregularidade
						no sistema de medição do cliente.`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[9].label
			) {
				this.setState({
					suport: `No ato da inspeção, foi constatado
						(medidor, chave de aferição, Transformador de Corrente ou Circuito
						Secundário de Potencial) com ligação invertida na(s) fase(s)
						(A, B e/ou C), deixando de registrar seu consumo real.`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[1].label &&
				this.state.detail === this.list.details[10].label
			) {
				this.setState({
					suport: `No ato da inspeção, foi constatada que o equipamento
						(TC - Transformadores de Corrente) da(s) fase(s) (A, B e/ou C)
						encontra(m)-se danificado, deixando de registrar seu consumo real.`
				})
			} else if (
				this.state.resultType === this.list.resultTypes[3].label &&
				this.state.detail === this.list.details[11].label
			) {
				this.setState({
					suport: `No ato da visita foi executada manutenção no sistema
						de telemedição do cliente devido a (telemetria desconectada,
						cabo com falha de comunicação, telemetria com defeito, ...).
						Não interferindo na medição do cliente, tendo sido normalizado
						no ato com a (substituição de remota, substituição ou calibração
						do cabo ótico, instalação de antena de ganho, etc).`
				})
			} else {
				this.setState({
					suport: '-'
				})
			}
			this.handleChangeObservation(this.state.suport)
		}
	}

	closeInspection = () => {
		const {
			onClose,
			onSetShowModalForm,
			onSetOpenForm,
			clearElementsForm
		} = this.props

		this.setState({
			suport: ''
		})

		this.handleChangeObservation()
		onSetOpenForm(false)
		onClose()
		onSetShowModalForm(false)
		clearElementsForm()
	}

	render() {
		const {
			classification,
			classes,
			isSending,
			type
		} = this.props

		return (
			<div
				className={classNames(classes.container, {
					[classes.containerIos]: deviceIsIos()
				})}
			>
				{isSending ? (
					<Loader internal />
				) : (
					<form className={classes.flexColumn} onSubmit={this.submit}>
						<FormControl margin="normal" required>
							<InputLabel>{translate('label/result')}</InputLabel>
							<Select
								onChange={this.handleChangeType}
								value={type}
							>
								{this.list.resultTypes.map((item, index) => (
									<MenuItem
										key={index}
										value={index}
										disabled={item.disabled}
									>
										<p className={classes.ComponentsForm}>
											{item.label}
										</p>
									</MenuItem>
								))}
							</Select>
						</FormControl>
							{this.state.resultSelected !== null ?
							<FormControl margin="normal" required>
								<InputLabel>{translate('label/details')}</InputLabel>
									<Select
										onChange={this.handleChangeClassification}
										value={classification}
									>
										{this.state.resultSelected === 1 ? 
											this.list.dateilsFraud.map((item, index) => (
												<MenuItem
													key={index}
													value={index}
													disabled={item.disabled}
												>
													<p className={classes.ComponentsForm}>
														{item.label}
													</p>
												</MenuItem>
											)) :
										this.state.resultSelected === 2 ? 
											this.list.detailsIrregularity.map((item, index) => (
												<MenuItem
													key={index}
													value={index}
													disabled={item.disabled}
												>
													<p className={classes.ComponentsForm}>
														{item.label}
													</p>
												</MenuItem>
											)) :
											this.state.resultSelected === 3 ? 
										this.list.detailsNothingCleared.map((item, index) => (
												<MenuItem
													key={index}
													value={index}
													disabled={item.disabled}
												>
													<p className={classes.ComponentsForm}>
														{item.label}
													</p>
												</MenuItem>
											)) :
											this.list.detailsNotInspected.map((item, index) => (
												<MenuItem
													key={index}
													value={index}
													disabled={item.disabled}
												>
													<p className={classes.ComponentsForm}>
														{item.label}
													</p>
												</MenuItem>
											))
										}
									</Select>
								</FormControl>
								:
								<div />}
						<FormControl margin="normal" required>
							<div>
								<label className={classes.labelStyle}>
									{`${translate('label/registered-in')}: `}
								</label>
								<p className={classes.pStyle}>
									{this.state.date}
								</p>
								<hr />
							</div>
						</FormControl>
						<FormControl margin="normal" required>
							<div onChange={this.handleChangeObservation}
								value={this.state.suport}>
								<label className={classes.labelStyle}>
									{translate('label/padding-support')}
								</label>
								<p className={classes.pStyle}>
									{this.state.suport}
								</p>
								<hr />
							</div>
						</FormControl>
						<div className={classes.actions}>
							<Button
								variant="outlined"
								onClick={this.submit}
							>
								{translate('label/register')}
							</Button>
							<Button variant="text" onClick={this.closeInspection}>
								{translate('label/cancel')}
							</Button>
						</div>
					</form> 
				 )}
			</div>
		)
	}
}

const makeMapStateToProps = () => {
	const getSnapshot = makeGetActionFormSnapshot(),
		mapStateToProps = (state) => ({
			showModalForm: state.lightState.showModalForm,
			openForm: state.lightState.openForm,
			inspection: state.lightState.inspection,
			closeInspection: state.lightState.closeInspection,
			ccReceivers: state.actionFormState.ccReceivers,
			classification: state.actionFormState.classification,
			fetchedUsers: state.actionFormState.fetchedUsers,
			isFetching: state.actionFormState.isFetching,
			isSending: state.actionFormState.isSending,
			observation: state.actionFormState.observation,
			receiver: state.actionFormState.receiver,
			expectedDate: state.actionFormState.expectedDate,
			session: state.sessionState,
			type: state.actionFormState.type,
			formType: state.actionFormState.formType,
			snapshot: getSnapshot(state)
		})

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	onSetShowModalForm: async (showModalForm) =>
		dispatch({ type: 'SET_SHOW_MODAL_FORM',  showModalForm}),
	onSetOpenForm: async (openForm) =>
		dispatch({ type: 'SET_OPEN_FORM',  openForm}),
	clearForm: () => dispatch({ type: CLEAR_ACTION_FORM }),
	clearElementsForm: () => dispatch({ type: CLEAR_ELEMENTS_FORM }),
	onSetCloseInspection: async (closeInspection) =>
		dispatch({ type: 'SET_CLOSE_INSPECTION_AUDIT',  closeInspection}),
	setUsersFetching: (isFetching) =>
		dispatch({ type: SET_ACTION_FORM_USERS_FETCHING, isFetching }),
	setReceiver: (receiver) =>
		dispatch({ type: SET_ACTION_FORM_RECEIVER, receiver }),
	setCcReceivers: (ccReceivers) =>
		dispatch({ type: SET_ACTION_FORM_CC_RECEIVERS, ccReceivers }),
	setFetchedUsers: (fetchedUsers) =>
		dispatch({ type: SET_ACTION_FORM_FETCHED_USERS, fetchedUsers }),
	setSending: async (isSending) =>
		dispatch({ type: SET_ACTION_FORM_IS_SENDING, isSending }),
	setFormObservation: (observation) =>
		dispatch({ type: SET_ACTION_FORM_OBSERVATION, observation }),
	setFormClassification: (classification) =>
		dispatch({ type: SET_ACTION_FORM_CLASSIFICATION, classification }),
	setFormType: (formType) =>
		dispatch({ type: SET_ACTION_FORM_TYPE, formType }),
	setFormExpectedDate: (date) =>
		dispatch({ type: SET_ACTION_FORM_EXPECTED_DATE, date }),
	onClose: () => dispatch(closeActionModal()),
	onSnackbar: (notification) => dispatch(showSnackBar(notification)),
	setCloseInspection: (closeInspectionForm) => 
		dispatch({ type: SET_CLOSE_INSPECTION_FORM, closeInspectionForm}),
	setAtualizaPage: (atualizaPage) => 
		dispatch({ type: 'SET_ATUALIZA_PAGE', atualizaPage})
})

export default compose(
	withActiveCompany,
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	),
	withStyles(styles)
)(ActionFormLight)
