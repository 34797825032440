export const ON_NOTIFICATION = 'ON_NOTIFICATION',
	CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

/**
 * Standardizes **emitting** snackbars at the system
 *
 * ? tags: snackbar, message, notification, emit
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} messageText The snackbar message text to be shown
 */
export const showSnackBar = (messageText) => ({
	type: ON_NOTIFICATION,
	notification: messageText
})

/**
 * Standardizes **hiding** snackbars at the system
 *
 * ? tags: snackbar, message, notification, hide
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 */
export const hideSnackBar = () => ({
	type: CLEAR_NOTIFICATION
})
