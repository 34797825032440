import React from 'react'

import { withStyles, Typography } from '@material-ui/core'
import * as routes from '../../../constants/routes'

import SignInForm from '../../reusable/signIn/SignInForm'
import { CompanyLogo } from '../../reusable/image'

import { translate } from '../../../utils/lang'
import styles from './styles'
import ResetLink from '../../reusable/account/forgotPassword/ResetLink'

const INITIAL_CONFIG = {
	logo: {
		src: require('../../img/speedometer.svg'),
		alt: 'Arquia Dashboards Logo',
		title: translate('app/title'),
	},
	history: routes.SIGN_IN,
}

/**
 * Declares the system's Sign In page
 *
 * @description Created the component
 * @version 1.0
 * @author brunoteixeirasilva
 *
 * @description Implemented the reset link
 * @version 2.0
 * @author davispindola
 */
class SignInPage extends React.PureComponent {
	constructor(props) {
		super(props)

		this.state = { ...INITIAL_CONFIG }

		if (props.history) this.state.history = props.history
	}

	render() {
		const { classes } = this.props
		const { logo } = this.state
		return (
			<div className={classes.root}>
				<CompanyLogo {...logo} />
				<Typography
					variant="h6"
					align="center"
					className={classes.title}>
					{logo.title}
				</Typography>

				<SignInForm />

				<div className={classes.forgotPassword}>
					<ResetLink />
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(SignInPage)
