import { fs } from '../../firebase'

/**
 * github user
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible for load action data.
 *
 * @param {String} activeCompanyId active company id
 * @param {String} actionId action id
 */
export const loadActionData = (activeCompanyId, actionId) => {
	return fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.doc(actionId)
		.get()
		.then((snapshot) => {
			return snapshot.data()
		})
}
