import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { fs } from '../../../../../firebase/firebase'
import PropTypes from 'prop-types'
import withAuthentication from '../../../withAuthentication'
import {
	makeGetCompaniesList,
	makeGetCompaniesListLoading,
	makeGetCompaniesListKeys
} from '../../../../../selectors/company/list'
import CompanySelectForm from './CompanySelectForm'
import { makeGetUserActiveCompanyId } from '../../../../../selectors/user/company'
import changeActiveCompany from '../functions/changeActiveCompany'
import { cleanCompanyRelatedData } from '../../../../../actions/company'
import { consoleSandbox } from '@sentry/utils'

/**
 * Company selector form.
 * Will load companies based on the current logged in user.
 * Only provides access to companies which user has account at.
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 */
class CompanySelectFormContainer extends React.PureComponent {
	/**
	 * Controls input states at this page
	 */
	handleChange = (name) => (event) => {
		const { value } = event.target
		const { onCleanCompanyRelatedData, authUser, companyKeys } = this.props

		//If the event can have propagation cancelled
		if (event.preventDefault) event.preventDefault()

		this.setCurrentCompanies(authUser, companyKeys)

		//Clear company related data
		onCleanCompanyRelatedData().then(() =>
			//Dispatches the active company change
			changeActiveCompany(value)
		)
		//.then(() => )
		// window.location.href = '/#/plugin'
	}

	setCurrentCompanies = (authUser, companyKeys) => {
		fs.collection('users').doc(authUser.uid).set({
			companies: companyKeys
		}, { merge: true })
	}

	render() {
		const {
			activeCompanyId,
			companies,
			companyKeys,
			companiesLoading,
			fullWidth
		} = this.props

		return (
			<CompanySelectForm
				activeCompanyId={activeCompanyId}
				companies={companies}
				companyKeys={companyKeys}
				companiesLoading={companiesLoading}
				fullWidth={fullWidth}
				handleChange={this.handleChange}
			/>
		)
	}
}

/**
 * In this form we need to lift state up -
 * So when the company has changed,
 * Someone else (parent) might be notified somehow
 */
CompanySelectFormContainer.propTypes = {
	authUser: PropTypes.object,
	classes: PropTypes.object,
	companies: PropTypes.object,
	companyKeys: PropTypes.array,
	companiesLoading: PropTypes.bool,
	fullWidth: PropTypes.bool
}

const makeMapStateToProps = () => {
	const getCompanies = makeGetCompaniesList(),
		getLoadingState = makeGetCompaniesListLoading(),
		getCompaniesKeys = makeGetCompaniesListKeys(),
		getActiveCompany = makeGetUserActiveCompanyId(),
		mapStateToProps = (state) => {
			const activeCompanyId = getActiveCompany(state),
				companies = getCompanies(state),
				isLoading = getLoadingState(state),
				companiesKeys = getCompaniesKeys(state)

			return {
				activeCompanyId: activeCompanyId,
				companyKeys: companiesKeys,
				companies: companies,
				companiesLoading: isLoading
			}
		}

	return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
	onCleanCompanyRelatedData: async () => dispatch(cleanCompanyRelatedData())
})

export default compose(
	connect(
		makeMapStateToProps(),
		mapDispatchToProps
	),
	withAuthentication
)(CompanySelectFormContainer)
