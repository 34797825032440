export const CLEAN_ON_COMPANY_CHANGE = 'CLEAN_ON_COMPANY_CHANGE'

/**
 * [action-creator] Will dispatch a signal of cleanup required at company-related data
 *
 * @description Created the action creator
 * @author daviwegner
 * @version 1.0
 */
export const cleanCompanyRelatedData = () => ({
	type: CLEAN_ON_COMPANY_CHANGE
})
