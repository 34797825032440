import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withAuthentication from '../withAuthentication'

/**
 * Component-level HOC which only renders the child component
 * in case the user is a superadmin, at any superadmin group.
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @description Added the capability for not pre-hiding the element
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @param {React.Component} Component The component to be protected
 * @param {Boolean} preHiddenElement [optional] If the element should be pre-hidden prior to shown, when loading
 */
const withSuperUserAccess = (Component, preHiddenElement = true) => {
	class WithSuperUserAccess extends React.Component {
		state = {
			userGroupIds: null
			//hasSuperUserAcces: false
		}

		/**
		 * At the moment the component mounts
		 */
		componentDidMount() {
			// const {
			// 	authUser,
			// 	setSuperUserResolving,
			// 	setSuperUserAccess,
			// 	superUserResolving
			// } = this.props
			// //Authentication user was loaded
			// if (!!authUser && Boolean(authUser.uid)) {
			// 	//Will define the resolving flag
			// 	setSuperUserResolving()
			// 	//Checks current authUser account -
			// 	//If the user is in any super group, then he is super user
			// 	Account.isSuperUser(authUser.uid)
			// 		.then((result) => {
			// 			//On success
			// 			setSuperUserAccess(result)
			// 		})
			// 		.catch((err) => {
			// 			//On error, cancels super-user rights
			// 			setSuperUserAccess(false)
			// 			throw err
			// 		})
			// }
		}

		render() {
			//Delivers the component
			// ? @author brunoteixeirasilva
			// ? @description Previous behaviour was of Loader showing up
			return !preHiddenElement || !!this.props.hasSuperUserAccess ? (
				<Component
					{...this.props}
					hasSuperUserAccess={this.props.hasSuperUserAccess}
				/>
			) : null
			// <SecurityAccessBlockage />
		}
	}

	/**
	 * Redux state mapper
	 *
	 * @param {Object} state
	 */
	const mapStateToProps = (state) => ({
		hasSuperUserAccess: state.userState.hasSuperUserAccess,
		superUserResolving: state.userState.superUserResolving
	})

	/**
	 * Redux dispatch mapper
	 *
	 * @param {Object} dispatch
	 */
	const mapDispatchToProps = (dispatch) => ({
		setSuperUserResolving: () =>
			dispatch({ type: 'SET_SUPER_USER_RESOLVING' }),
		setSuperUserAccess: (hasSuperUserAccess) =>
			dispatch({ type: 'SET_SUPER_USER_ACCESS', hasSuperUserAccess })
	})

	return compose(
		withAuthentication,
		connect(
			mapStateToProps,
			mapDispatchToProps
		)
	)(WithSuperUserAccess)
}

export default withSuperUserAccess
