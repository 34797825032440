import { SET_SELECTED_DATASET_IN_SELECTABLE } from '../../actions/selectableDataSet'

const INITIAL_STATE = {
	selectedDataSet: {},
}

const selectableDataSetReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_SELECTED_DATASET_IN_SELECTABLE:
			return {
				...state,
				selectedDataSet: {
					...state.selectedDataSet,
					[action.dashItemId]: action.dataSet,
				},
			}
		default:
			return state
	}
}

export default selectableDataSetReducer
