export const SET_DIALOG_TO_OPENED = 'SET_DIALOG_TO_OPENED'
export const SET_DIALOG_TO_CLOSED = 'SET_DIALOG_TO_CLOSED'

// set the dialog state dialogIsOpen to true
export const setDialogToOpened = () => {
	return {
		type: SET_DIALOG_TO_OPENED,
		dialogIsOpen: true
	}
}

// set the dialog state dialogIsOpen to close
export const setDialogToClosed = () => {
	return {
		type: SET_DIALOG_TO_CLOSED
	}
}
