export const SET_COMPANY_DASH_ITEM = 'SET_COMPANY_DASH_ITEM',
	SET_COMPANY_DASH_ITEM_DATA = 'SET_COMPANY_DASH_ITEM_DATA',
	SET_COMPANY_DASH_ITEM_LOADING = 'SET_COMPANY_DASH_ITEM_LOADING'

/**
 * [action-creator] for dashItem filter data loading state **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {dashItemId} dashItemId The current loaded dashItem ID
 * @param {boolean} isLoading The current loading status
 */
export const setCompanyDashItemLoading = (dashItemId, isLoading) => ({
	type: SET_COMPANY_DASH_ITEM_LOADING,
	dashItemId,
	isLoading
})

/**
 * [action-creator] for dashItem filter data attachment **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {dashItemId} dashItemId The current loaded dashItem ID
 * @param {Object} data The current loaded data
 */
export const setCompanyDashItemData = (dashItemId, data) => ({
	type: SET_COMPANY_DASH_ITEM_DATA,
	dashItemId,
	data
	// TODO: Make it work with Immutable Maps => data: new Map(data)
})

/**
 * [action-creator] for dashItem filter data attachment **setting**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {dashItemId} dashItemId The current loaded dashItem ID
 * @param {Object} dashItem The current loaded dashItem
 */
export const setCompanyDashItem = (dashItemId, dashItem) => ({
	type: SET_COMPANY_DASH_ITEM,
	dashItemId,
	dashItem
})
