// import DateConfig from '../../models/date/config'

export const PRODUCTIVITY_LOSS_TOTALIZER_DATA_LOADED = 'PRODUCTIVITY_LOSS_TOTALIZER_DATA_LOADED',
	PRODUCTIVITY_LOSS_DATA_LOADED = 'PRODUCTIVITY_LOSS_DATA_LOADED',
	PRODUCTIVITY_LOSS_LOADING = 'PRODUCTIVITY_LOSS_LOADING',
	PRODUCTIVITY_LOSS_SET_PERIOD = 'PRODUCTIVITY_LOSS_SET_PERIOD',
	CLEAR_PRODUCTIVITY_LOSS_DATA = 'CLEAR_PRODUCTIVITY_LOSS_DATA',
	CLEAR_PRODUCTIVITY_LOSS_TOTALIZER_DATA = 'CLEAR_PRODUCTIVITY_LOSS_TOTALIZER_DATA'

/**
 * [action-creator] Provides a standard way to dispatch productivity loss **totalizer data**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} totalizerData The object with props used for a totalizer
 */
export const setProductivityLossTotalizers = (totalizerData) => ({
	type: PRODUCTIVITY_LOSS_TOTALIZER_DATA_LOADED,
	totalizerData
})

/**
 * [action-creator] Provides a standard way to dispatch productivity loss **graph data**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} data The object with props used for the graph
 */
export const setProductivityLossData = (data) => ({
	type: PRODUCTIVITY_LOSS_DATA_LOADED,
	data
})

/**
 * [action-creator] Provides a standard way to dispatch productivity loss **loading state**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {boolean} isLoading The boolean value to be defined at the loading
 */
export const setProductivityLossLoading = (isLoading) => ({
	type: PRODUCTIVITY_LOSS_LOADING,
	isLoading
})

/**
 * [action-creator] Provides a standard way to dispatch productivity loss **date config**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {DateConfig} dateConfig The date-config object to be set
 */
export const setProductivityLossDatePeriod = (dateConfig, value, formType) => ({
	type: PRODUCTIVITY_LOSS_SET_PERIOD,
	dateConfig,
	value,
	formType
})

/**
 * [action-creator] Provides a standard way to dispatch productivity loss **data clearing**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const clearProductivityLossData = () => ({
	type: CLEAR_PRODUCTIVITY_LOSS_DATA
})

/**
 * [action-creator] Provides a standard way to dispatch productivity loss _totaliser_ **data clearing**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const clearProductivityLossTotaliserData = () => ({
	type: CLEAR_PRODUCTIVITY_LOSS_TOTALIZER_DATA
})
