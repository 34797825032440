export const SET_USER_ACTION_SNAPSHOT = 'SET_USER_ACTION_SNAPSHOT',
	CLEAR_USER_ACTION_CARD_ID = 'CLEAR_USER_ACTION_CARD_ID'

/**
 * [action-creator] For dispatching clearing work to user-action-plans **card-id**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const clearActionCardId = () => ({
	type: CLEAR_USER_ACTION_CARD_ID
})

/**
 * [action-creator] For dispatching changes to user-action-plans **snapshot**
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Blob} snapshot The blob object being dispatched
 */
export const setUserActionSnapshot = (snapshot) => ({
	type: SET_USER_ACTION_SNAPSHOT,
	snapshot
})

// /**
//  * [action-close] For dispatching changes to user-action-plans **snapshot**
//  *
//  * @description Created the form close
//  * @author jeffersonguilhermemachadobarreto
//  * @version 1.0
//  *
//  */
// export const setCloseInspection = (closeInspection) => ({
// 	type: SET_CLOSE_INSPECTION,
// 	closeInspection
// })