import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Card, Typography, Button, withStyles } from '@material-ui/core'
import { translate } from '../../../../utils/lang'
import FiltersContainer from '../'
import { getDesktopBreakpoint } from '../../../../utils/object/theme/mobile'

/**
 * Manages to display a default filter-modal for many components
 * ! This is a state-less component. You may provide open/close functionalities
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 */
class FilterModal extends React.Component {
	render() {
		const { classes, open, onClose, fullWidth } = this.props

		return (
			<Modal className={classes.root} open={open} onClose={onClose}>
				<Card className={classes.modal}>
					<div className={classes.title}>
						<Typography variant="h6" style={{ color: '#f0f0f0' }}>
							{translate('label/apply-filters')}
						</Typography>
					</div>
					<div className={classes.content}>
						<FiltersContainer />
						<Button
							variant="outlined"
							color="primary"
							className={classes.button}
							fullWidth={fullWidth}
							onClick={onClose}
						>
							{translate('label/close')}
						</Button>
					</div>
				</Card>
			</Modal>
		)
	}
}

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modal: {
		backgroundColor: '#fff',
		padding: 0,
		flex: '0 0 auto',
		margin: 'auto',
		width: '95%',
		...getDesktopBreakpoint(theme, {
			width: '450px'
		})
	},
	title: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
		width: '100%',
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
		background: theme.palette.primary.light,
		'& > *': {
			flex: '0 0 auto'
		}
	},
	content: {
		padding: theme.spacing.unit
	},
	button: {
		marginTop: theme.spacing.unit
	}
})

FilterModal.defaultProps = {
	fullWidth: false,
	open: false
}

FilterModal.propTypes = {
	fullWidth: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool
}

export default withStyles(styles)(FilterModal)
