import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withStyles, Icon, Tooltip,BottomNavigation,BottomNavigationAction } from '@material-ui/core'
import { translate } from '../../../../utils/lang'
import * as routes from '../../../../constants/routes'
import withAuthentication from '../../withAuthentication'
import withNotification from '../../withNotification'
import AgilBadge from '../../agilBadge/AgilBadge'
import { checkLogin } from '../../../../utils/security/auth/verifyAuth'
import classNames from 'classnames'
import { deviceIsIos } from '../../../../utils/env'

/**
 * New navigation style at the bottom of the screen
 *
 * @description Created the component
 * @author lopes
 * @version 1.0
 *
 * @description Removed withSuperUserAccess from class
 * @author brunoteixeirasilva
 * @version 1.1
 *
 * @description Added function that makes the verification if the user is logged in the system
 * @author lopes
 * @version 1.2
 */
class BottomMenuNavigation extends React.Component {
	state = {
		value: null
	}

	handleChange = (event, value) => {
		this.props.onHandleChange(value)
	}

	componentDidUpdate() {
		const { location, onHandleChange, index } = this.props

		if (location.pathname.includes('/dashboard/p/') && index !== 0)
			onHandleChange(0)
		// if (location.pathname.includes('/plugin') && index !== 1)
		// 	onHandleChange(1)
	}
	componentDidMount() {
		const { onHandleChange, index, location } = this.props

		if (!index) {
			if (
				!location.pathname.includes('/plugin') &&
				!location.pathname.includes('/dashboard')
			)
				onHandleChange(2)
			if (location.pathname.includes('/plugin')) onHandleChange(1)
			if (location.pathname.includes('/dashboard')) onHandleChange(0)
		}
	}
	getPlugins = () => {
		this.props.history.push(routes.PLUGIN)
	}
	handleDashboard = () => {
		const {
			menuCard,
			activePluginId,
			history,
			onHandleChange,
			index,
			location
		} = this.props
		const dashPath = location.pathname.includes('/di/')

		if (dashPath) {
			history.push(routes.DASHBOARD.replace(':pluginId', activePluginId))
		}

		if (index === 0 && !location.pathname.includes('/dashboard/p')) {
			history.push(routes.DASHBOARD.replace(':pluginId', activePluginId))
		}

		if (index !== 0 && !location.pathname.includes('/dashboard/p')) {
			history.push(
				activePluginId
					? routes.DASHBOARD.replace(':pluginId', activePluginId)
					: routes.PLUGIN
			)
			onHandleChange(0)
		} else menuCard()
	}
	render() {
		const {
			classes,
			menuNavigation,
			notificationCount,
			authUser,
			index
			// showTutorial
		} = this.props

		return (
			!checkLogin(authUser) && (
				<BottomNavigation
					value={index}
					onChange={this.handleChange}
					showLabels
					className={classNames(classes.newMenu, {
						ios: deviceIsIos()
					})}
				>
					<BottomNavigationAction
						label={translate('label/plugin-type/dashboard')}
						classes={{
							selected: classes.selected,
							wrapper: classes.wrapper
						}}
						className={classes.newMenuLabel}
						icon={
							<Tooltip title={translate('label/card')}>
								<Icon>dashboard</Icon>
							</Tooltip>
						}
						onClick={this.handleDashboard}
					/>
					<BottomNavigationAction
						classes={{
							selected: classes.selected,
							wrapper: classes.wrapper
						}}
						className={classes.newMenuLabel}
						icon={
							<Tooltip title={translate('label/sources')}>
								<Icon>settings_input_component</Icon>
							</Tooltip>
						}
						label={translate('label/sources')}
						onClick={this.getPlugins}
					/>

					<BottomNavigationAction
						label={translate('label/menu')}
						classes={{
							selected: classes.selected,
							wrapper: classes.wrapper
						}}
						className={classes.newMenuLabel}
						icon={
							<Tooltip title={translate('label/menu')}>
								<div>
									<AgilBadge
										badgeContent={notificationCount}
										isAlert
									>
										<Icon>menu</Icon>
									</AgilBadge>
								</div>
							</Tooltip>
						}
						onClick={menuNavigation}
					/>
				</BottomNavigation>
			)
		)
	}
}

const styles = (theme) => ({
	newMenu: {
		width: '100%',
		backgroundColor: '#FFF',
		position: 'fixed',
		bottom: '0px',
		left: '0px',
		right: '0px',
		display: 'flex',
		zIndex: '2',
		justifyContent: 'space-between',
		height: '45px',
		'&.ios': {
			height: '56px',
			paddinBottom: `env(safe-area-inset-bottom)`
		}
	},
	newMenuLabel: {
		color: theme.palette.primary.light
		// fontSize: '18px',
		// textTransform: 'uppercase'
	},
	root: {},
	selected: {
		color: theme.palette.primary.dark,
		fontWeight: 500,
		fontSize: '10px !important'
	},
	wrapper: {
		position: 'absolute'
	},
	label: {},
	rowVertical: {
		height: '100%'
	}
})

BottomMenuNavigation.propTypes = {
	classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
	index:
		!state.bottomNavigationState.index &&
		state.bottomNavigationState.index !== 0
			? 1
			: state.bottomNavigationState.index,
	showTutorial: state.dashItemViewState.showTutorial
})

const mapDispatchToProps = (dispatch) => ({
	onHandleChange: (value) =>
		dispatch({ type: 'HANDLE_BOTTOM_NAVIGATION_CHANGE', value })
})

export default compose(
	withNotification,
	withRouter,
	withAuthentication,
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(BottomMenuNavigation)
