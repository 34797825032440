import { fs } from '../../../../firebase/firebase'
import { translate } from '../../../../utils/lang'

/**
 * Acess Firebase RTDB to get active filters in current plugin
 * 
 * @param {func} onSetLocalFilters 
 * @param {func} defaultLocalFilterGet 
 * @param {object} data 
 */
export const onGetLocalFilters = async (onSetLocalFilters, defaultLocalFilterGet, data) => {

    let allLocalFilters = []

    // switch (data.process) {
    //     case 'development':
            await fs.collection('plugins').doc(data.pluginId).collection('localFilters')
                .where('active', '==', true).get().then((snapshot) => {
                    snapshot.forEach(async (item) => {
                        // await fs.collection('localFilters').doc(item.id).get().then((filter) => {
                            let docs = item.data()
                            // if (!!docs.activeDev) {
                                allLocalFilters.push({
                                    id: item.id,
                                    ...docs
                                })
                            // }
                        // })
                    })
                })
    //         break
    //     case 'production':
    //         await fs.collection('plugins').doc(data.pluginId).collection('localFilters')
    //             .get().then((snapshot) => {
    //                 snapshot.forEach(async (item) => {
    //                     await fs.collection('localFilters').doc(item.id).get().then((filter) => {
    //                         let docs = filter.data()
    //                         if (!!docs.active) {
    //                             allLocalFilters.push({
    //                                 id: filter.id,
    //                                 ...docs
    //                             })
    //                         }
    //                     })
    //                 })
    //             })
    //         break
    //     case 'stage':
    //         await fs.collection('plugins').doc(data.pluginId).collection('localFilters')
    //             .get().then((snapshot) => {
    //                 snapshot.forEach(async (item) => {
    //                     await fs.collection('localFilters').doc(item.id).get().then((filter) => {
    //                         let docs = filter.data()
    //                         if (!!docs.activeStage) {
    //                             allLocalFilters.push({
    //                                 id: filter.id,
    //                                 ...docs
    //                             })
    //                         }
    //                     })
    //                 })
    //             })
    //         break
    //     default:
    //         break
    // }

    if (!!allLocalFilters && allLocalFilters.length > 0)
        allLocalFilters.forEach((item) => {

            let dataSource = {
                dataSourceId: item.dataSource
            },
            dataSet = {
                dataSetId: item.dataSet
            }

            defaultLocalFilterGet(null, dataSource.dataSourceId, dataSet.dataSetId).then((resp) => {
                onSetLocalFilters(dataSet.dataSetId, {
                    label: translate(item.name),
                    name: dataSet.dataSetId,
                    id: item.id,
                    options: resp.data.map((item) => {
                        return {
                            name: item.label,
                            label: item.label,
                            id: item.key,
                            disable: false,
                        }
                    })
                })
            }).catch((err) => {
                console.error('Error - ', err)
            })
    })
}

/**
 * @description get and atribute filters in expecify card
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 */
export const onGetFiltersDashItem = async (company, onSetCardLocalFilter) => {
    let getDashItem = await fs.collection('companies').doc(company).collection('dashItems')

    getDashItem.get().then((snapShot) => {
        snapShot.forEach((dashItem) => {

            getDashItem.doc(dashItem.id).collection('localFilters').get().then((snapShot) => {
                let cardFilter = []

                snapShot.forEach((filter) => {
                    cardFilter.push(filter.id)
                })

                if (!!cardFilter && cardFilter.length > 0) {
                    let card = {
                        [dashItem.id]: cardFilter
                    }
                    onSetCardLocalFilter(card)
                }
            })
        })
    })
}