const INITIAL_STATE = {
	language: 'pt_br',
	json: null,
	languages: {},
	isLoading: true
}

const accountConfig = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_APP_LANGUAGE':
			return Object.assign({}, state, { language: action.language })
		case 'SET_APP_LANGUAGE_TEXT': {
			return Object.assign({}, state, { json: action.text })
		}
		case 'SET_AVAILABLE_LANGUAGES': {
			return Object.assign({}, state, { languages: action.languages })
		}
		case 'SET_LANGUAGE_LOADING': {
			return Object.assign({}, state, { isLoading: action.isLoading })
		}
		default:
			return state
	}
}

export default accountConfig
