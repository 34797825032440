/* eslint-disable */
import sourceEngine from '../../sourceEngine'


/**
 * Queries for last update data
 *
 * @description Created the function
 * @author Mauricio Ray
 * @version 1.0
 *
 * @param {object} params The params for querying filter
 * @param {string} dataSourceId The dataSource from which data-access config will be required
 * @param {string} dataSetId The dataSet from which data will be queried
 */
export const lastSuccessUpdateDate = (
	params = {},
	dataSourceId = 'appAudit',
	dataSetId = 'lastSuccessUpdateDate'
) =>
	sourceEngine().postProvisory(
		dataSourceId, dataSetId, params
	)
