import React from 'react'
import Swiper from '../../swiperViews/Swiper'
import classNames from 'classnames'
import { withStyles, Icon, IconButton } from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SET_ACTIVE_SWIPER } from '../../../../reducers/dashItem/view'

class DashItemView extends React.Component {
	state = {
		currentIndex: 0,
		showLeft: true,
		showRight: true,
	}

	componentDidMount() {
		const { currentIndex } = this.state
		const { activeDashItem, onSwitchDashItem, dashItemKeys, activeDashSet, dashSetKey } = this.props

		if (!activeDashItem && activeDashSet === dashSetKey) onSwitchDashItem(activeDashSet, dashItemKeys[currentIndex])
		if (currentIndex === 0) {
			this.setState({
				showLeft: false,
			})
		}
		// if (activeDashItem && activeDashSet === dashSetKey) {
		// 	this.setState({
		// 		currentIndex: dashItemKeys.indexOf(activeDashItem),
		// 		showLeft: !!(dashItemKeys.indexOf(activeDashItem) === 0)
		// 	})
		// }
		if (dashItemKeys === false) {
			this.setState({
				showRight: false,
			})
		}
	}
	componentDidUpdate() {
		const { dashItemKeys, activeDashItem, dashSetKey, activeDashSet, onSwitchDashItem } = this.props
		const { currentIndex } = this.state

		if (
			activeDashSet === dashSetKey &&
			activeDashItem &&
			dashItemKeys.includes(activeDashItem) &&
			currentIndex !== dashItemKeys.indexOf(activeDashItem)
		) {
			this.setState({
				currentIndex: dashItemKeys.indexOf(activeDashItem),
				// showLeft: !!(dashItemKeys.indexOf(activeDashItem) === 0)
			})
		}
		if (!activeDashItem && activeDashSet === dashSetKey && dashItemKeys) onSwitchDashItem(activeDashSet, dashItemKeys[currentIndex])
	}

	selectCard = index => {
		const { setSwipe } = this.props

		setSwipe(false)

		this.setState({
			currentIndex: index,
		})
	}

	nextCard = () => {
		const { currentIndex } = this.state
		const { children, onSwitchDashItem, activeDashSet, dashItemKeys } = this.props

		if (currentIndex < React.Children.count(children) - 1) {
			onSwitchDashItem(activeDashSet, dashItemKeys[currentIndex + 1])
			this.setState({ currentIndex: currentIndex + 1, showLeft: true })
		}

		if (currentIndex === React.Children.count(children) - 2) {
			this.setState({
				showRight: false,
			})
		}

		if (React.Children.count(children) <= 1) {
			this.setState({ showRight: false })
		}

		if (currentIndex > 0) {
			this.setState({
				showLeft: true,
			})
		}
	}

	prevCard = e => {
		const { currentIndex } = this.state
		const { onSwitchDashItem, activeDashSet, dashItemKeys } = this.props
		// e.stopPropagation()
		let nextIndex = currentIndex - 1
		// e.stopPropagation()
		onSwitchDashItem(activeDashSet, dashItemKeys[nextIndex])
		// console.log('Gotin')
		this.setState({
			currentIndex: nextIndex,
			showLeft: nextIndex === 0 ? false : true,
		})
		this.setState({ showRight: true })
	}

	switching = index => {
		const { children, onSwitchDashItem, activeDashSet, dashItemKeys } = this.props
		onSwitchDashItem(activeDashSet, dashItemKeys[index])
		this.setState({
			currentIndex: index,
			showLeft: !(index === 0),
			showRight: index === 0 || !(index === React.Children.count(children) - 1),
		})
	}

	render() {
		const { children, classes, showSwipe, activeDashSet, dashSetKey, activeDashItem } = this.props
		const { currentIndex, showRight, showLeft } = this.state
		const arrayChildren = React.Children.toArray(children)
		return (
			<React.Fragment>
				<div className={showSwipe ? classes.dashItemViewContainer : classes.dashItemViewContainerActive}>
					{showSwipe && (
						<IconButton
							index={currentIndex}
							onClick={this.prevCard}
							disabled={!showLeft && !!(currentIndex === 0)}
							className={classes.arrowContainer}
							key={Math.random()}>
							<Icon
								className={classNames(classes.arrowSwiper, classes.arrowLeft, {
									arrowAnimation: showLeft || !(currentIndex === 0),
									[classes.toVanish]: !showLeft || !!(currentIndex === 0),
								})}>
								keyboard_arrow_left
							</Icon>
						</IconButton>
					)}
					<Swiper disabled={!showSwipe} indexSwipe={currentIndex} onChangeIndex={this.switching}>
						{arrayChildren.map((item, index) => {
							return (
								<div key={index} className={!!showSwipe ? classes.slide : classes.slideActive} onClick={() => this.selectCard(index)}>
									{React.cloneElement(item, {
										activeDashItem: activeDashItem,
										isDashSetSelected: dashSetKey === activeDashSet,
									})}
								</div>
							)
						})}
					</Swiper>

					{showSwipe && (
						<IconButton
							index={currentIndex}
							onClick={this.nextCard}
							disabled={!showRight}
							className={classes.arrowContainer}
							key={Math.random()}>
							<Icon
								className={classNames(classes.arrowSwiper, classes.arrowRight, {
									arrowAnimation: arrayChildren.length > 1 && currentIndex < arrayChildren.length - 1,
									[classes.toVanish]: !(currentIndex < arrayChildren.length - 1) || !(arrayChildren.length > 1),
								})}>
								keyboard_arrow_right
							</Icon>
						</IconButton>
					)}
				</div>
			</React.Fragment>
		)
	}
}

const styles = theme => ({
	slide: {
		flex: 1,
		marginTop: 0,
		marginBottom: 0,
		// marginLeft: '5%',
		// marginRight: '5%',
		// padding: '0 5px',
		transform: 'scale(0.9)',
		border: '1px solid #9575cd',
		borderRadius: '20px',
		overflow: 'hidden',
		// minHeight: '100%',
		// maxHeight: '100%',
		height: '100%',
		animation: 'scaleDown 0.2s',
		textAlign: 'center',
	},
	slideActive: {
		transform: 'scale(1)',
		animation: 'grow 0.2s',
		overflow: 'hidden',
		textAlign: 'center',
		borderRadius: 0,
		border: 'none',
		marginLeft: '0px',
		marginRight: '0px',
		height: '100%',
		paddingLeft: 0,
	},
	app: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		height: '100%',
	},
	arrowContainer: {
		width: '50px',
		height: '50px',
	},
	arrowContainerDown: {
		position: 'relative',
		bottom: '20px',
	},
	arrowSwiper: {
		color: theme.palette.primary.light,
		fontSize: '50px',
		fontWeight: 'bolder',

		// position: 'fixed',
		// zIndex: '1'
		// top: 'calc(50% - (48px/2))'
	},

	arrowRight: {
		right: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	arrowLeft: {
		left: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	arrowDown: {
		left: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	arrowUp: {
		left: 0,
		'&.arrowAnimation': {
			animationName: 'pulse',
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
		},
	},
	toVanish: {
		color: 'transparent',
	},
	/*Animação das setas do swipe */
	'@keyframes pulse': {
		from: {
			color: theme.palette.primary.light,
		},
		to: {
			color: 'transparent',
		},
	},

	/*Animação de encolhimento de card , quando clicar para selecionar outros cards */
	'@keyframes grow': {
		'0%': {
			// transform: 'scale(0.9)',
			borderRadius: '20px',
			border: '1px solid #9575cd',
		},
		'25%': {
			transform: 'scale(0.925)',
			borderRadius: '20px',
		},
		'50%': {
			transform: 'scale(0.95)',
			borderRadius: '20px',
		},
		'75%': {
			transform: 'scale(0.975)',
			borderRadius: '20px',
		},
		'100%': {
			transform: 'scale(1)',
			borderRadius: '20px',
		},
	},
	/*Animação de crescimento de card , quando clicar ele seleciona o card */
	'@keyframes scaleDown': {
		'0%': {
			transform: 'scale(1)',
		},
		'25%': {
			transform: 'scale(0.975)',
		},
		'50%': {
			transform: 'scale(0.95)',
		},
		'75%': {
			transform: 'scale(0.925)',
		},
		'100%': {
			transform: 'scale(0.9)',
		},
	},
	container: {
		width: '100%',
		// height: 'calc(100vh - 115px)'
		height: 'calc(100vh - 70px)',
	},
	containerActive: {
		width: '100%',
		height: 'calc(100vh - 115px)',
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dashItemViewContainer: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		alignContent: 'center',
	},
	dashItemViewContainerActive: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		alignContent: 'center',
	},
})

const mapStateToProps = state => ({
	showSwipe: state.dashItemViewState.showSwipe,
})

const mapDispatchToProps = dispatch => ({
	setSwipe: swipe => dispatch({ type: SET_ACTIVE_SWIPER, swipe }),
})

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(DashItemView)
