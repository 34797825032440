/**
 * ???
 *
 * @description Creatd the function
 * @author davispindola
 * @version 1.0
 *
 * @param {number} value
 * @param {*} param1
 */
const getStateColour = (value = 0, { colors = [] }) => colors[value]

export default getStateColour
