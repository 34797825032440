import React from 'react'
import 'flag-icon-css/css/flag-icon.css'

const CountryFlag = ({ country, squared }) =>
	(!squared && (
		<span
			style={{ margin: '0px 8px' }}
			className={`flag-icon flag-icon-${country}`}
		/>
	)) || (
		<span
			style={{ margin: '0px 8px' }}
			className={`flag-icon flag-icon-${country} flag-icon-squared`}
		/>
	)

export default CountryFlag
