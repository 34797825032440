import { translate } from '../../../../../utils/lang'

export const types = {
	0: 'tipo não definido',
	1: translate('label/alert'),
	2: translate('label/risk'),
	3: translate('label/compliment')
}

export const classification = {
	0: 'não classificada',
	1: translate('label/risk-high'),
	2: translate('label/risk-medium'),
	3: translate('label/risk-low'),
	4: translate('label/improv-opportunity')
}

// function getDate(date) {
// 	return `${new Date(date).toLocaleString('pt-br')}`
// }

// function textPreview(text) {
// 	return `${text.slice(0, 50)}...`
// }
//TODO take all these functions to container
export function getStyle(active) {
	return active
		? {
				borderColor: '#f2bb15'
		  }
		: {
				borderColor: 'gray'
		  }
}
