const INITIAL_STATE = {
	slideIndex: 0,
	id: null,
	isLoading: false,
	detail: null,
	os: null,
	accumulated: null
}

const workDetailReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_WORK_DETAIL_SLIDE_INDEX':
			return { ...state, slideIndex: action.slideIndex }

		case 'SET_WORK_DETAIL_ACCUMULATED':
			return { ...state, accumulated: action.accumulated }

		case 'SET_WORK_DETAIL_LOADING':
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})

		case 'CLEAR_WORK_DETAIL_STATE':
			return { ...state, ...INITIAL_STATE }

		case 'RECEIVE_WORK_DETAIL':
			return Object.assign({}, state, {
				detail: action.detail,
				os: action.os,
				isLoading: INITIAL_STATE.isLoading
			})

		case 'SET_WORK_DETAIL_ID':
			return Object.assign({}, state, {
				id: action.id
			})

		default:
			return state
	}
}

export default workDetailReducer
