import {
	MANAGE_PAINEL_CHECKLIST_LOADING_STATE,
	LOAD_PAINEL_CHECKLIST_DATA,
	SET_PAINEL_CHECKLIST_PERIOD,
} from '../../actions/painelChecklist'

const INITIAL_STATE = {
	isLoading: null,
	painelChecklist: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {
		Auditor: '',
		Data_Baixa_Realizada: '',
		No_Checklist: '',
		Nome_Formulario: '',
		Nome_Objeto: '',
		Responsavel_Objeto: '',
		Resultado: '',
		Status: '',
		Tipo_Checklist: '',
		Tipo_Objeto: '',
	},
}
/**
 * painel checklists state reducer manager
 * @param {*} state
 * @param {*} action
 */
const painelChecklistReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_PAINEL_CHECKLIST_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		}
		case LOAD_PAINEL_CHECKLIST_DATA: {
			return Object.assign({}, state, {
				painelChecklist: action.painelChecklist,
				isLoading: false,
			})
		}
		case SET_PAINEL_CHECKLIST_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value,
						},
					),
				},
				isLoading: true,
			})
		}
		case 'SET_CARD_ID': {
			return Object.assign({}, state, {
				cardId: action.cardId,
			})
		}
		case 'SET_PERFORMED_PAINEL_CHECKLIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: '',
				}),
			})
		}
		case 'SET_PERFORMED_PAINEL_CHECKLIST_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value),
			})
		}
		case 'CLEAN_PERFORMED_PAINEL_CHECKLIST_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {},
			})
		}
		default:
			return state
	}
}

export default painelChecklistReducer
