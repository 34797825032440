const INITIAL_STATE = {
	companies: null,
	companiesLoading: false
}

/**
 * Manages to reset loading state of companies
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state to be reset
 */
const resetCompaniesLoading = (state) =>
	Object.assign({}, state, {
		companiesLoading: INITIAL_STATE.companiesLoading
	})

/**
 * Manages to set loading state of companies = true
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state to be reset
 */
const companiesLoading = (state) =>
	Object.assign({}, state, {
		companiesLoading: true
	})

/**
 * Manages to set loading state of companies = false and define the companies list
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state to be reset
 * @param {Object} action The action with "type" and desired prop
 */
const companyListLoaded = (state, action) =>
	Object.assign({}, state, {
		companies: action.companies,
		companiesLoading: INITIAL_STATE.companiesLoading
	})

/**
 * Separation of company list state layer (non-persisted)
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {*} action The action with "type" and the desired prop
 */
function companyListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'COMPANY_LIST_LOADING': {
			return companiesLoading(state)
		}
		case 'RESET_COMPANY_LIST_LOADING': {
			return resetCompaniesLoading(state)
		}
		case 'COMPANY_LIST_LOADED': {
			return companyListLoaded(state, action)
		}
		default:
			return state
	}
}

export default companyListReducer
