export const CHANGE_TEAM_LIST_TURN = 'CHANGE_TEAM_LIST_TURN'

const INITIAL_STATE = {
	logged: false
}

/**
 * 	Clean Page data
 */
const setLogged = (state, action) =>
	Object.assign({}, state, {
		logged: action.logged
	})

/**
 * Manages team-list-status state-part-layer. Separate file because of redux-persist
 *
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state
 * @param {Object} action The action with "type" and specific props
 */
const teamListFilterReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CHANGE_TEAM_LIST_TURN: {
			return setLogged(state, action)
		}

		default: {
			return state
		}
	}
}

export default teamListFilterReducer
