import {
	SET_COMPANY_PLUGIN,
	SET_COMPANY_PLUGIN_LOADING
} from '../../../actions/company/plugin'
import { CLEAN_ON_COMPANY_CHANGE } from '../../../actions/company'

const INITIAL_STATE = {
	items: null,
	isLoading: null
}

/**
 * Sets a plugin-id-based **isLoading** prop
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state-portion tree
 * @param {Object} action The action with "type" and desired props
 */
const setPluginLoading = (state, action) =>
	Object.assign({}, state, {
		isLoading: Object.assign({}, state.isLoading, {
			[action.pluginId]: action.isLoading
		})
	})

/**
 * Sets a plugin-id-based collection of **items** map
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state-portion tree
 * @param {Object} action The action with "type" and desired props
 */
const setPlugin = (state, action) =>
	Object.assign({}, state, {
		items: Object.assign({}, state.plugin, {
			[action.pluginId]: action.plugin
		}),
		isLoading: Object.assign({}, state.isLoading, {
			[action.pluginId]: false
		})
	})

/**
 * Manages a company-plugin state-portion tree
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current app state-portion tree
 * @param {Object} action The action with "type" and desired props
 */
function companyPluginReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_COMPANY_PLUGIN_LOADING: {
			return setPluginLoading(state, action)
		}
		case SET_COMPANY_PLUGIN: {
			return setPlugin(state, action)
		}
		case CLEAN_ON_COMPANY_CHANGE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}

export default companyPluginReducer
