import { createSelector } from 'reselect'

/**
 * Gets the session authenticated user object (Firebase)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current redux state tree object
 */
export const getAuthUser = (state) => state.sessionState.authUser

/**
 * [memoized] Selects the session authenticated user object (Firebase)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetAuthUser = () =>
	createSelector(
		getAuthUser,
		(authUser) => authUser
	)

/**
 * Gets the session authenticated user object UID (Firebase)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current redux state tree object
 */
export const getAuthUserID = (state) => {
	const authUser = state.sessionState.authUser

	return !!authUser ? authUser.uid : null
}

/**
 * [memoized] Selects the session authenticated user object UID (Firebase)
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetAuthUserID = () =>
	createSelector(
		getAuthUserID,
		(uid) => uid
	)
