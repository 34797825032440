import { createSelector } from 'reselect'
/**
 * Selects _filter-bar_ **loading state** from redux state tree
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state The current state tree portion
 */
const getFilterBarIsLoading = (state) => state.filterBarState.isLoading

/**
 * [memoised] Selects whether _filter-bar_ data is at **loading state**
 *
 * @description Created the selector
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const makeGetFilterBarIsLoading = () =>
	createSelector(
		getFilterBarIsLoading,
		(isLoading) => isLoading
	)
