import {
	MANAGE_MAX_TEMPERATURE_LOADING_STATE,
	LOAD_MAX_TEMPERATURE_DATA,
	SET_MAX_TEMPERATURE_PERIOD
} from '../../actions/maxTemperature'

const INITIAL_STATE = {
	isLoading: null,
	maxTemperature: null,
	dateConfig: {},
	update: false,
	ownFilters: {},
	searchText: {}
}
/**
 * maxTemperature state reducer manager
 * @param {*} state
 * @param {*} action
 */

const maxTemperatureReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MANAGE_MAX_TEMPERATURE_LOADING_STATE: {
			return Object.assign({}, state, {
				isLoading: action.isLoading
			})
		}
		case LOAD_MAX_TEMPERATURE_DATA: {
			return Object.assign({}, state, {
				maxTemperature: action.maxTemperature,
				isLoading: false
			})
		}
		case 'SET_MAX_TEMPERATURE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: Object.assign({}, state.ownFilters, action.value),
				searchText: Object.assign({}, state.searchText, {
					[action.key]: ''
				})
			})
		}
		case 'SET_MAX_TEMPERATURE_SEARCH_FILTER_VALUE': {
			return Object.assign({}, state, {
				searchText: Object.assign({}, state.searchText, action.value)
			})
		}
		case 'CLEAN_MAX_TEMPERATURE_FILTER_VALUE': {
			return Object.assign({}, state, {
				ownFilters: {}
			})
		}
		case SET_MAX_TEMPERATURE_PERIOD: {
			return Object.assign({}, state, {
				dateConfig: {
					...action.dateConfig,
					selectedValues: Object.assign(
						{},
						state.dateConfig.selectedValues,
						{
							[action.formType]: action.value
						}
					)
				},
				isLoading: true
			})
		}
		default:
			return state
	}
}

export default maxTemperatureReducer
