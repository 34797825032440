export const MANAGE_COLLABORATOR_NC_LOADING_STATE =
		'MANAGE_COLLABORATOR_NC_LOADING_STATE',
	LOAD_COLLABORATOR_NC_DATA = 'LOAD_COLLABORATOR_NC_DATA',
	SET_COLLABORATOR_NC_PERIOD = 'SET_COLLABORATOR_NC_PERIOD'

export const setCollaboratorNcPeriod = (dateConfig, value, formType) => ({
	type: SET_COLLABORATOR_NC_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadCollaboratorNcData = (collaboratorNc) => ({
	type: LOAD_COLLABORATOR_NC_DATA,
	collaboratorNc
})

export const setLoading = (isLoading) => ({
	type: MANAGE_COLLABORATOR_NC_LOADING_STATE,
	isLoading
})
