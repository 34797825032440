import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import AppBarIcon from './icon/AppBarIcon'
import { leftBarIcon } from './styles'
import withNotification from '../withNotification'

const mapStateToProps = (state) => ({
	icon: state.appConfigState.app.barLeftIcon,
	action: state.appConfigState.app.barLeftIconAction,
	ariaLabel: state.appConfigState.app.barLeftIconAriaLabel
})
/**
 * left bar icon container, for injecting in app bar icon its props
 * @description created the component
 * @author Davi Wegner
 * @version 1.0
 */
class LeftBarIcon extends Component {
	render() {
		return this.props.icon ? (
			<AppBarIcon {...this.props} badge={this.props.notificationCount} />
		) : null
	}
}

export default compose(
	withNotification,
	withStyles(leftBarIcon),
	connect(mapStateToProps)
)(LeftBarIcon)
