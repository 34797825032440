export const MANAGE_PRODUCTIVITY_CLIENTS_LOADING_STATE =
		'MANAGE_PRODUCTIVITY_CLIENTS_LOADING_STATE',
	LOAD_PRODUCTIVITY_CLIENTS_DATA = 'LOAD_PRODUCTIVITY_CLIENTS_DATA',
	SET_PRODUCTIVITY_CLIENTS_PERIOD = 'SET_PRODUCTIVITY_CLIENTS_PERIOD',
	SET_PRODUCTIVITY_CLIENT = 'SET_PRODUCTIVITY_CLIENT',
	CLEAR_PRODUCTIVITY_CLIENT = 'CLEAR_PRODUCTIVITY_CLIENT',
	SET_PRODUCTIVITY_CYCLE = 'SET_PRODUCTIVITY_CYCLE',
	CLEAR_PRODUCTIVITY_CYCLE = 'CLEAR_PRODUCTIVITY_CYCLE'

export const setProductivityClientsPeriod = (dateConfig, value, formType) => ({
	type: SET_PRODUCTIVITY_CLIENTS_PERIOD,
	dateConfig,
	value,
	formType
})

export const loadProductivityClientsData = (productivityClients) => ({
	type: LOAD_PRODUCTIVITY_CLIENTS_DATA,
	productivityClients
})

export const setLoading = (isLoading) => ({
	type: MANAGE_PRODUCTIVITY_CLIENTS_LOADING_STATE,
	isLoading
})

export const setProductivityClient = (item) => ({
	type: SET_PRODUCTIVITY_CLIENT,
	item
})

export const clearProductivityClient = (productivityClients) => ({
	type: CLEAR_PRODUCTIVITY_CLIENT,
	productivityClients
})

export const setProductivityCurrentCycle = (currentCycle) => ({
	type: SET_PRODUCTIVITY_CYCLE,
	currentCycle
})
