const INITIAL_STATE = {
    usersProps: [],
    userFilter: null,
    wordSearch: '',
    pluguins: [],
    plugins: [],
    emailValidation: false,
    alertCreatedUser: false,
    modal: false,
    pluginUser: [],
    alertAlteration: false,
    orderPermission: false,
    componentsPermission: [],
    isLoading: true,
    isLoadingMenu: true
}
/**
 * Users reducer
 * @description created the reducer to save user collected in firebase
 * @author jeffersonguilhermemachadobarreto
 * @version 1.0
 * @param {*} state
 * @param {*} action
 */

const createUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'SET_USERS_IN_REDUCER': {
			return Object.assign({}, state, {
				usersProps: action.usersProps
			})
        }
        case 'SET_USER_FILTER_REDUCER': {
			return Object.assign({}, state, {
				userFilter: action.userFilter
			})
        }
        case 'SET_WORD_SEARCH': {
            return Object.assign({}, state, {
                wordSearch: action.wordSearch
            })
        }
        case 'SET_PLUGINS_REDUCER': {
            return Object.assign({}, state, {
                pluguins: action.pluguins
            })
        }
        case 'SET_PLUGINS': {
            return Object.assign({}, state, {
                plugins: action.plugins
            })
        }
        case 'SET_EMAIL_VALIDATION': {
            return Object.assign({}, state, {
                emailValidation: action.emailValidation
            })
        }
        case 'SET_ALERT_CREATE_USER': {
            return Object.assign({}, state, {
                alertCreatedUser: action.alertCreatedUser
            })
        }
        case 'SET_STATUS_MODAL': {
            return Object.assign({}, state, {
                modal: action.modal
            })
        }
        case 'SET_PLUGIN_USER': {
            return Object.assign({}, state, {
                pluginUser: action.pluginUser
            })
        }
        case 'SET_ALERT_ALTERATION': {
            return Object.assign({}, state, {
                alertAlteration: action.alertAlteration
            })
        }
        case 'SET_PERMISSION_ORDER': {
            return Object.assign({}, state, {
                orderPermission: action.orderPermission
            })
        }
        case 'SET_COMPONENTS_PERMISSIONS': {
            return Object.assign({}, state, {
                componentsPermission: action.componentsPermission
            })
        }
        case 'SET_LOADER': {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            })
        }
        case 'SET_LOADER_MENU': {
            return Object.assign({}, state, {
                isLoadingMenu: action.isLoadingMenu
            })
        }
        default:
            return state
    }
}

export default createUserReducer
