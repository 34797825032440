const INITIAL_STATE = {
	buttonIndex: {}
}

const SET_BUTTON_INDEX = 'SET_BUTTON_SWITCH_INDEX'

/**
 * Manages to control the buttonSwitch state-portion
 *
 * @description Created the reducer
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Object} state Current state-portion related to the button-switch
 * @param {Object} action The action with "type" and desired props
 */
const buttonSwitchReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_BUTTON_INDEX: {
			return Object.assign({}, state, {
				buttonIndex: Object.assign({}, state.buttonIndex, {
					[action.componentId]: action.buttonIndex
				})
			})
		}
		default:
			return state
	}
}

export default buttonSwitchReducer

export { SET_BUTTON_INDEX }
