import { fs } from '../../firebase'

/**
 * github user
 * @author davispindola
 * @version 1.0.0
 *
 * Responsible for load all actions of an user.
 *
 * @description removed order by on query no need to order by as it is done on selector now
 * @author Davi Wegner
 * @version 1.0.1
 * @param {String} uid user identification
 */
export const loadUserActions = (uid, activeCompanyId) => {
	let actions = {}

	return fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.where(`author`, '==', uid)
		.get()
		.then((snapshot) => {
			if (!snapshot.empty) {
				snapshot.forEach((item) => {
					return (actions[item.id] = item.data())
				})
				return actions
			}
			return null
		})
}

/**
 * Responsible for load actions that the user is receiver in some way.
 *
 * github user
 * @author davispindola
 *
 * !@version 1.0.0
 * @version 1.1.0
 *
 * @param {String} uid
 * @param {String} activeCompanyId
 */
export const loadUserReceiverActions = (uid, activeCompanyId) => {
	let actions = {}

	return fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.orderBy('date', 'desc')
		.get()
		.then((snapshot) => {
			if (!snapshot.empty) {
				snapshot.forEach((item) => {
					const { receiver, ccReceivers } = item.data()
					
					//Action gets added if the logged user is the receiver or copied
					if (item.data().receiver !== null &&
						item.data().receiver !== undefined &&
						receiver.key === uid ||
						(null !== ccReceivers && undefined !== ccReceivers && ccReceivers.includes(uid))
					) {
						return (actions[item.id] = item.data())
					}
				})
				return actions
			}
			return null
		})
}

/**
 * Check for changes on colletion actions on where parameters (in this case is the actual user actions)
 *
 * @author Davi Wegner
 * @description Created The function
 * @version 1.0
 *
 * @param {*} activeCompanyId
 * @param {*} uid
 * @param {*} thenFunc
 */
export const checkChanges = (activeCompanyId, uid, thenFunc) => {
	let isFirst = true
	// the first time the onSnapshot runs, it will
	//see as change every already inserted value, so when first is true we do not check for changes
	let unsubscribe = []
	let refAuthor = fs // set ref for checking where the author is the current user
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.where(`author`, '==', uid)
	let refReceiver = fs //set ref for checking where the receiver is the current user
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		.where(`receiver.key`, '==', uid)

	let authorObj = {}
	unsubscribe.push(
		refAuthor.onSnapshot((querySnapshot) => {
			let hasChange = false

			if (isFirst === false) {
				querySnapshot.docChanges().forEach((change) => {
					if (!!change.type) {
						hasChange = true
						authorObj = Object.assign({}, authorObj, {
							[change.doc.id]: change.doc.data()
						})
					} // if there is any change, doesnt care the type, we must reload
					//reload outside the foreach unless you want to run multiple times
				})
			}

			if (hasChange) thenFunc(authorObj)
		})
	)

	let receiverObj = {}

	unsubscribe.push(
		refReceiver.onSnapshot((querySnapshot) => {
			let hasChange = false
			if (isFirst === false) {
				querySnapshot.docChanges().forEach((change) => {
					if (!!change.type) {
						hasChange = true
						receiverObj = Object.assign({}, receiverObj, {
							[change.doc.id]: change.doc.data()
						})
					}
				})
			}
			if (hasChange) {
				thenFunc(receiverObj)
			}
			isFirst = false
		})
	)
	return unsubscribe
}

/**
 * github user
 * @author davispindola
 * @version 1.0.0
 * @type get
 *
 * Responsible for load actions that user is only assigned to read.
 *
 * @param {String} uid
 * @param {String} activeCompanyId
 */
export const loadUserCopyReceiverActions = (uid, activeCompanyId) => {
	let actions = {}

	const query = fs
		.collection(`companies`)
		.doc(activeCompanyId)
		.collection(`actions`)
		// .where('ccReceivers', 'array-contains', uid)
		.orderBy('date', 'desc')

	return query.get().then((snapshot) => {
		if (!snapshot.empty) {
			snapshot.forEach((item) => {
				const { ccReceivers } = item.data()
				if (ccReceivers.includes(uid)) {
					return (actions[item.id] = item.data())
				}
			})
			return actions
		}
		return null
	})
}
