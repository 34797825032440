import { translate } from '../lang'

/**
 * Generates a random string using a predefined chart-array
 *
 * @description Created the component
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Number} length Length of the random string being generated
 */
export const generateRandom = (length) => {
	var charset =
			'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%¨&*()_+{[}]?/;:',
		returnVal = ''

	for (var i = 0, n = charset.length; i < length; ++i) {
		returnVal += charset.charAt(Math.floor(Math.random() * n))
	}

	return returnVal
}

/**
 * Will print a stamp with not-informed label in tagged format, _i18n language-based_. e.g.: <not informed>.
 * **Used for defaulting stamps.**
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 */
export const getNotInformedStamp = () => `<${translate('label/not-informed')}>`

/**
 * Formats an object to localised format.
 *
 * @description Created the functions
 * @author davispindola
 * @version 1.0.0
 *
 * @description Changed the function description, as well as param types
 * @author davispindola
 * @version 1.1.0
 *
 * @param {String | Number} value value to be formatted
 * @param {{ locale: string|number }} config string localisation configuration object
 */
export const _toLocaleString = (value, { locale }) => {
	if (typeof value === 'string' || typeof value === 'number') {
		return value.toLocaleString(locale)
	}
}

/**
 * Applies a string normalization, replaces diatricts (accent class chars)
 * and also lower-cases it.
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} text The text to be normalised. Will be lower-cased.
 */
export function normaliseForQuery(text) {
	return text
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
}

/**
 * Applies a string-based filtering on an array,
 * based on a prop of each collection-item
 *
 * @description Created the function
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {Array} collection The array to be filtered
 * @param {string} text The query base text
 * @param {string} prop The prop of each collection-item
 */
export function filterByText(collection, text, prop) {
	let normalisedText

	//No text specified, no text-filters applied
	if (!text || '' === text) return collection

	//Reducers the text case
	normalisedText = normaliseForQuery(text)

	//Filters and returns query results
	return collection.filter((item) =>
		item[prop].toLowerCase().includes(normalisedText)
	)
}

/**
 * parses a query string to object
 * , in case the field string value is a valid number,parse it as int, otherwise pass as string, if its null then pass null
 * @description Created the function
 * @author Davi Wegner, Bruno Teixeira Silva
 * @version 1.0
 */
export const parseQueryString = (queryString) => {
	if (!queryString) return {}
	let strings = queryString.replace('?', '').split('&')
	return strings.reduce((result, currentItem) => {
		let keyValue = currentItem.split('=')
		result[keyValue[0]] =
			keyValue.length > 1
				? isNaN(keyValue[1])
					? keyValue[1]
					: parseInt(keyValue[1])
				: null
		return result
	}, {})
}
