export const SET_CONFIG = 'SET_CONFIG'

/**
 * [action-creator] Creates an action
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} configProp The current configuring prop-name
 * @param {*} value The value to be set
 */
export const setConfig = (configProp, value) => ({
	type: SET_CONFIG,
	[configProp]: value
})

/**
 * [action-creator] Creates an **app debug** mode changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} debug The value to be set at **app debug**
 */
export const setAppDebugMode = (debug) => setConfig('debug', debug)

/**
 * [action-creator] Creates an **app barRightIcon** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} barRightIcon The value to be set at **app barRightIcon**
 */
export const setAppBarRightIcon = (barRightIcon) =>
	setConfig('barRightIcon', barRightIcon)

/**
 * [action-creator] Creates an **app barRightIconAction** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} barRightIconAction The value to be set at **app barRightIconAction**
 */
export const setAppBarRightIconAction = (barRightIconAction) =>
	setConfig('barRightIconAction', barRightIconAction)

/**
 * [action-creator] Creates an **app barRightIconAriaLabel** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} barRightIconAriaLabel The value to be set at **app barRightIconAriaLabel**
 */
export const setAppBarRightIconAriaLabel = (barRightIconAriaLabel) =>
	setConfig('barRightIconAriaLabel', barRightIconAriaLabel)

/**
 * [action-creator] Creates an **app barRightIconTooltip** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} barRightIconTooltip The value to be set at **app barRightIconTooltip**
 */
export const setAppBarRightIconTooltip = (barRightIconTooltip) =>
	setConfig('barRightIconTooltip', barRightIconTooltip)

/**
 * [action-creator] Creates an **app barLeftIcon** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} barLeftIcon The value to be set at **app barLeftIcon**
 */
export const setAppBarLeftIcon = (barLeftIcon) =>
	setConfig('barLeftIcon', barLeftIcon)

/**
 * [action-creator] Creates an **app barLeftIconAriaLabel** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} barLeftIconAriaLabel The value to be set at **app barLeftIconAriaLabel**
 */
export const setAppBarLeftIconAriaLabel = (barLeftIconAriaLabel) =>
	setConfig('barLeftIconAriaLabel', barLeftIconAriaLabel)

/**
 * [action-creator] Creates an **app barLeftIconAction** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} barLeftIconAction The value to be set at **app barLeftIconAction**
 */
export const setAppBarLeftIconAction = (barLeftIconAction) =>
	setConfig('barLeftIconAction', barLeftIconAction)

/**
 * [action-creator] Creates an **app default-title** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} value The value to be set at **app default-title**
 */
export const setAppDefaultTitle = (value) => setConfig('defaultTitle', value)

/**
 * [action-creator] Creates an **app title** changer
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {string} value The value to be set at **app title**
 */
export const setAppTitle = (value) => setConfig('title', value)

/**
 * [action-creator] Creates an action
 *
 * @description Created the action creator
 * @author brunoteixeirasilva
 * @version 1.0
 *
 * @param {*} open The current open state (true => open)
 */
export const setAppDrawerOpen = (open = false) => setConfig('drawerOpen', open)
