export const MANAGE_MONITORING_LOADING_STATE = 'MANAGE_MONITORING_LOADING_STATE',
    LOAD_MONITORING_DATA = 'LOAD_MONITORING_DATA',
    SET_MONITORING_PERIOD = 'SET_MONITORING_PERIOD',
    SET_MONITORING_VALUE = 'SET_MONITORING_VALUE'

export const setMonitoringPeriod = (dateConfig, value, formType) => ({
    type: SET_MONITORING_PERIOD,
    dateConfig,
    value,
    formType
})

export const loadMonitoringData = (monitoring) => ({
    type: LOAD_MONITORING_DATA,
    monitoring
})

export const setLoading = (isLoading) => ({
    type: MANAGE_MONITORING_LOADING_STATE,
    isLoading
})

export const monitoringValue = (text) => ({
    type: SET_MONITORING_VALUE,
    text
})